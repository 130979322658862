import React, { useEffect, useState } from 'react'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
//
import axios from '../../../utils/myAxios'
import { globalConstants } from '../../../actions/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import ShareMenue from './ShareMenue'
const Actions = ({ Icon, color, text }) => {
    return (
        <div className='space-x-2 flex items-center'>
            <Icon className={`${color}`} />
            <span className='text-sm'>{text}</span>
        </div>
    )
}
const CommentViewShareState = ({ qid }) => {
    const [states, setstates] = useState({
        likes: 0,
        comments: 0,
        views: 0
    })
    //
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()
    const [liked, setliked] = useState(false)
    const handleDislike = async () => {
        try {
            if (!liked) return;
            setstates({
                ...states,
                likes: (states.likes - 1 !== 0 ? states.likes - 1 : 0)
            })
            setliked(false)
            const obj = {
                user: auth.data.user._id,
                quizzId: qid
            }
            await axios.post('/dislike/quizz', {
                ...obj,
            })
        } catch (error) {
            dispatch({ type: globalConstants.ALERT, payload: { error: error.message } })
        }
    }
    const handleLike = async () => {
        try {
            if (liked) return;
            setstates({
                ...states,
                likes: states.likes + 1
            })
            setliked(true)
            const obj = {
                user: auth.data.user._id,
                quizzId: qid
            }
            await axios.post('/like/quizz', {
                ...obj,
            })
        } catch (error) {
            dispatch({ type: globalConstants.ALERT, payload: { error: error.message } })
        }
    }
    //get likes state
    useEffect(async () => {
        if (qid) {
            try {
                const obj = {
                    user: auth.data.user._id,
                    quizzId: qid
                }
                //console.log('obj==>', obj)
                const res = await axios.post('/get/userlike', {
                    ...obj,
                })
                if (res.data.updated) {
                    if (res.data.updated.liked) {
                        setliked(true)
                    }
                }
            } catch (error) {

            }
        }
    }, [qid])
    //get stats
    useEffect(() => {
        const getStates = async () => {
            try {
                const obj = {
                    qid: qid
                }
                const res = await axios.post('/get/stat/of/quizz', {
                    ...obj
                })
                const { comments, likes, views } = res.data
                setstates({
                    comments: comments,
                    likes: likes,
                    views: views
                })
            } catch (error) {

            }
        }
        if (qid) {
            getStates()
        }
    }, [qid])
    return (
        <div className='flex items-center justify-evenly py-2 border-t border-t-black border-opacity-50' >
            {
                liked ?
                    <div className='space-x-2 flex items-center cursor-pointer' onClick={handleDislike} >
                        <FavoriteIcon className={`text-red-400`} />
                        <span className='text-sm'>{`${states.likes} likes`}</span>
                    </div>
                    :
                    <div className='space-x-2 flex items-center cursor-pointer' onClick={handleLike} >
                        <FavoriteBorderOutlinedIcon className={`text-red-400`} />
                        <span className='text-sm'>{`${states.likes} likes`}</span>
                    </div>

            }
            <Actions
                Icon={ChatOutlinedIcon}
                text={`${states.comments} comments`}
                color='text-yellow-400'
            />
            <Actions
                Icon={RemoveRedEyeOutlinedIcon}
                text={`${states.views} views`}
                color='text-green-400'
            />
            <ShareMenue />
        </div>
    )
}

export default CommentViewShareState