import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants } from '../../actions/appConstants'
import { createSubjectAction } from '../../actions/subjects.actions'
import SubjectItem from './SubjectItem'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { imageUpload } from '../../utils/cloudinary.config'
import { update } from 'lodash'
const Subjects = () => {
  
  const [subjectData, setsubjectData] = useState({
    name: '',
    subjectImage : ''
  })
  const dispatch = useDispatch()
  const { subjects } = useSelector(state => state)
  const handleCreateSubject = async () => {
      const updated = await imageUpload([subjectData.subjectImage])
      if (updated.length === 0) return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "no image uploaded!" }
      })
      setsubjectData({...subjectData ,subjectImage : updated[0].url})
    if (!(subjectData.name).trim()) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: 'Name is required!' }
    })
    
    dispatch(createSubjectAction({...subjectData ,subjectImage : updated[0].url })).then((val) => {
      if (val) {
        setsubjectData({
          ...subjectData,
          name: '', 
          subjectImage : ''
          
        })
      }
    })
  }
  return (
    <Cont className='my__rounded'>
      <Left>
        <h1 className='text-bgblue text-2xl'>Create Subjects</h1>
        <div className='mt-5 lg:w-1/2'>
          <h1>Subject name</h1>
          <input
            value={subjectData.name}
            onChange={(e) => setsubjectData({ ...subjectData, name: e.target.value })}
            placeholder='name...'
            className='border border-black outline-none py-2 px-2 rounded-md w-full'
          />
          <div className='space-y-2 group cursor-pointer col-span-6 lg:col-span-3'>
                        <h1 className='py-2'>Category Image*</h1>
                        <div className={`${subjectData.subjectImage ? '' : 'border-dashed border border-green-700'} w-full relative h-36  `}>
                            {
                                typeof (subjectData.subjectImage) === 'object' ?
                                    <img
                                        src={URL.createObjectURL(subjectData.subjectImage)}
                                        className='w-full h-full object-cover'
                                    />
                                    :
                                    (typeof (subjectData.subjectImage) === 'string' && subjectData.subjectImage.trim()) &&
                                    <img
                                        src={subjectData.subjectImage}
                                        className='w-full h-full object-cover'
                                    />

                            }
                            <div className={` ${subjectData.subjectImage ? 'opacity-0 group-hover:opacity-100' : ''} w-full absolute top-[50%] left-[50%]  -translate-y-[50%]`}>
                                <div className='relative  w-full h-full '>
                                    <input onChange={(e) => {
                                        const file = e.target.files[0]
                                        if (!file) {
                                            return dispatch({
                                                type: globalConstants.ALERT,
                                                payload: { error: "no file selected!" }
                                            })
                                        }
                                        if (file['type'].split('/')[0] !== 'image') {
                                            return dispatch({
                                                type: globalConstants.ALERT,
                                                payload: { error: "you can only select image as you ID image" }
                                            })
                                        }
                                        setsubjectData({
                                            ...subjectData,
                                            subjectImage: file,
                                        })
                                        e.target.value = ''
                                    }} type='file' className='absolute w-full h-full opacity-0' />
                                    <FileUploadIcon  color = "primary" fontSize={'large'} />
                                </div>
                            </div>
                        </div>
                    </div>
          <button onClick={handleCreateSubject} className='mt-5 w-full bg-bgblue text-white outline-none border-none py-2 px-4 text-center rounded-md'>Create</button>
        </div>
      </Left>
      <Right>
        <h1 className='text-bgblue text-2xl mb-5'>Subjects</h1>
        <div className='space-y-2'>
          {
            subjects?.subjects?.length > 0 &&
            subjects.subjects.map((val, index) => (
              <SubjectItem subject={val} />
            ))
          }
        </div>
      </Right>
    </Cont>
  )
}

const Right = styled.div`
${tw`lg:col-span-2 col-span-6 mt-5 lg:mt-0`}
`
const Left = styled.div`
${tw`lg:col-span-4 col-span-6`}
`
const Cont = styled.div`
${tw`bg-white p-3 grid grid-cols-6`}
`
export default Subjects