import { useMediaQuery } from '@mui/material';
import { useTheme} from '@mui/material/styles';
import React from 'react'
import Header from './Header';
import HeaderDrawerResponsive from './HeaderDrawerResponsive';

const NavBar = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            {matches ? <Header /> : <HeaderDrawerResponsive />}
        </>
    )
}

export default NavBar

