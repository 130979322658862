import React from 'react'
import UserManagementC from '../components/UserManagementComponents/UserManagementC'
import Layout from './../components/Layout/Layout'
const UserManagement = () => {
  return (
    <Layout>
      <UserManagementC />
    </Layout>
  )
}

export default UserManagement