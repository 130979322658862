import React, {useState} from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../utils/myAxios'
import { CircularProgress } from '@mui/material';

function FaqQuestionAcordin({_val, setFaqs}) {
    const [edit, setEdit] = useState(false)
    const [question, setQuestion] = useState(_val)
    const [loading, setLoading] = useState(false)

    const handleChange = (e)=>{
        const {value, name} = e.target
        setQuestion({
            ...question,
            [name]: value
        })
    }
    const handleSubmit = ()=>{
        setLoading(true)
        axios.post('/updateQuestion', question).then(res => {
            setFaqs(res.data.faqs)
            setEdit(false)
            setLoading(false)
        })  
    }
    const handleDelete = ()=>{
        setLoading(true)
        axios.post('/deleteQuestion', question).then(res => {
            setFaqs(res.data.faqs)
            setEdit(false)
            setLoading(false)
        })
    }

    return (
        <div className='my-2'>
            {loading ? 
            <div className='w-full flex justify-center'>
                <CircularProgress></CircularProgress>
            </div>
            :  <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {!edit && <Typography>{question.question}</Typography>}
                    {edit && <input type="text" className='w-full h-9 rounded-lg ' name = 'question' value = {question.question} onChange={(e)=>handleChange(e)}/> }
                </AccordionSummary>
                <AccordionDetails>
                    <div className='flex justify-between'>
                        {!edit && <Typography>
                            {question.answer}
                        </Typography>}
                        {edit && <input type="text" className='w-full h-9 rounded-lg '  name = "answer" value = {question.answer} onChange={(e)=>handleChange(e)} /> }

                        {!edit && <div>
                            <EditIcon className='cursor-pointer' onClick={()=>{setEdit(true)}}/>
                            <DeleteIcon className='cursor-pointer' onClick={()=>{handleDelete()} }/>
                        </div> }
                    </div>
                    {edit && <div className='w-full flex justify-center gap-2 mt-3'>
                        <button className='px-2 h-9 rounded-full border bg-red-400 text-white hover:text-red-400 hover:bg-white' onClick={()=>{setEdit(false)}}>Cancel</button>
                        <button className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white' onClick={()=>{handleSubmit()}}>Update</button>
                    </div>}
                </AccordionDetails>
            </Accordion>}
        </div>
    )
}

export default FaqQuestionAcordin