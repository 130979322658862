import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MultipleQuestions from '../MultipleQuestions/MultipleQuestions'
import FillInTheBlanks from '../FillInTheBlanks/FillInTheBlanks'
import MatchThePair from '../MatchThePair/MatchThePair'
import { getAllQuestionCategories } from '../../../actions/question.actions'
import { useDispatch, useSelector } from 'react-redux'
import SequenceQuizz from '../SequenceQuizz/SequenceQuizz'
import ParagraphOrdering from '../paragraphOrdering/ParagraphOrdering'
import IdentifyQuizz from '../identifyImageVideo/IdentifyQuizz'
import TrueAndFalse from '../treAndFalse/TrueAndFalse'
import { questionsCategoryConstants as types } from './../../../actions/appConstants'
import CrossWordQuestions from '../crossWordQuestions/CrossWordQuestions'
import DragDropFillUpTheBlanks from '../dragDropFillUpTheBlanks/DragDropFillUpTheBlanks'
import ClosedEnded from '../closedEnded/ClosedEnded'
import SentenceSequence from '../sentenceSequence/SentenceSequence'
import SortingClassification from '../sortingClassification/SortingClassification'
import DragDropVisualSimulating from '../dragDropVisualSimulating/DragDropVisualSimulating'
import AIQuestions from './AIQuestions'
import axios from '../../../utils/myAxios'

const exclued_cat = ["SPIN_A_WHEEL_QUIZZ", "ELIMINATOR_QUIZZ", "MIX_TYPE_QUIZ", "TIC_TAC_TOE"]

const AddQuestions = ({ setshowAddQuestions, showQuestinData, setshowQuestinData }) => {
    const dispatch = useDispatch()
    const q = useSelector(state => state.question)
    const [qzetoHistory, setQzetoHistory] = useState()
    const { subjects } = useSelector(s => s)
    const { auth } = useSelector(state => state)
    const [additionType, setAdditionType] = useState('')
    const [data , setdata] = useState({})
    const [headerData, setheaderData] = useState({
        subject: "",
        type: "",
    })
    useEffect(() => {
        try {
            const getPercentageCriteria = async () => {
                const res = await axios.get('/get/percentagecriteria')
                if (res.data.data.length > 0) {
                    setdata(res.data.data[0])
                }
            }
            getPercentageCriteria()
        }
        catch(e) {
            console.log(e)
        }

    }, [])
    useEffect(() => {
        axios.get('/get/boughtqzeto/history/' + auth.data.user._id)
            .then(res => {
                console.log(res.data)
                setQzetoHistory(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    const { subject, type } = headerData;
    useEffect(() => {
        if (showQuestinData) {
            setheaderData({
                ...headerData,
                type: showQuestinData.questionCategory.name,
                subject: showQuestinData.subject,
            })
        } else {
            setshowQuestinData(null)
        }
    }, [showQuestinData])
    return (
        <Container className='my__rounded'>
            {
                !showQuestinData &&
                <>
                    <Header>
                        <h1 className='text-bgblue font-bold flex items-center'>
                            <span>Choose Method</span>
                        </h1>
                        <div className='grid grid-cols-1 gap-2 mt-5 lg:mt-0'>

                            <div className='flex flex-col'>
                                <span className='text-bgblue text-sm font-semibold mb-2'>Select Type</span>
                                <select value={additionType} onChange={(e) => setAdditionType(e.target.value)}
                                    className='my__select'>
                                    <option value=''>Select the type</option>
                                    <option value="AI" disabled={(auth?.data?.user.ai_questions > 0) ? false : true} >AI</option>
                                    <option value="Mannual">Mannual</option>
                                </select>
                            </div>
                        </div>

                    </Header>
                    {additionType && <>
                        {additionType === 'AI' ? <div>
                            <AIQuestions />
                        </div> :
                            <Header>
                                <h1 className='text-bgblue font-bold flex items-center'>
                                    <span>Add questions</span>
                                </h1>
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-2 mt-5 lg:mt-0'>
                                    <div className='flex flex-col'>
                                        <span className='text-bgblue text-sm font-semibold mb-2'>Select Subject</span>
                                        <select value={headerData.subject} onChange={(e) => setheaderData({
                                            ...headerData,
                                            subject: e.target.value
                                        })}
                                            className='my__select'>
                                            <option value=''>Select the subject</option>
                                            {
                                                subjects.subjects.map((val, index) => (
                                                    <option value={val.name}>{val.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-bgblue text-sm font-semibold mb-2'>Select Type</span>
                                        <select value={headerData.type} onChange={(e) => setheaderData({
                                            ...headerData, type: e.target.value
                                        })}
                                            className='my__select'>
                                            <option value=''>Select the type</option>
                                            {
                                                q.questionsCategories.length > 0
                                                    ?
                                                    q.questionsCategories.filter(v => !exclued_cat.includes(v.name))?.map((val, index) => (
                                                        <option key={index} value={val.name} >{(val.name).replace(/_/g, ' ')}</option>
                                                    ))
                                                    :
                                                    null
                                            }
                                        </select>
                                    </div>
                                </div>

                            </Header>}
                    </>}
                </>
            }
            {
                headerData.type === types.MULTIPLE_QUESTIONS ?
                    <MultipleQuestions showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.FILL_IN_THE_BLANKS ?
                    <FillInTheBlanks showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.MATCH_THE_PAIR ?
                    <MatchThePair showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.SEQUENCING_QUIZ ?
                    <SequenceQuizz showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.PARAGRAPH_ORDERING_QUIZ ?
                    <ParagraphOrdering showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.IDENTIFY_IMAGE_VIDEO_OR_VOICE ?
                    <IdentifyQuizz showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.TRUE_FALSE ?
                    <TrueAndFalse showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions} />
                    :
                    null
            }
            {
                headerData.type === types.CROSS_WORDS ?
                    <CrossWordQuestions
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
            {
                headerData.type === types.DRAD_DROP_FILL_UP_THE_BLANKS ?
                    <DragDropFillUpTheBlanks
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
            {
                headerData.type === types.CLOSED_ENDED ?
                    <ClosedEnded
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
            {
                headerData.type === types.SENTENCE_SEQUENCE ?
                    <SentenceSequence
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
            {
                headerData.type === types.SORTING_CLASSIFICATION ?
                    <SortingClassification
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
            {
                headerData.type === types.DRAG_DROP_VISUAL_SIMULATING ?
                    <DragDropVisualSimulating
                        showQuestinData={showQuestinData} subject={headerData.subject} type={headerData.type} setshowAddQuestions={setshowAddQuestions}
                    />
                    :
                    null
            }
        </Container >
    )
}
const Header = styled.div`
${tw`grid grid-cols-1 lg:grid-cols-2`}
`
const Container = styled.div`
${tw`bg-white p-3`}
`
export default AddQuestions