import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../utils/myAxios'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import { TrainRounded } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useNavigate } from 'react-router-dom'
import TakeActionsPopOver from './TakeActionsPopOver'
import NotFoundDataUi from '../../uiComponents/NotFoundDataUi'
const QuestionsTable = ({ showQuestinData }) => {
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    const [adminQuestionsState, setadminQuestionsState] = useState('')
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(true)
                const res = await axios(`/getuserquestions?page=${page}&limit=${limit}&type=${adminQuestionsState}`)
                if (res.status === 200) {
                    console.log('questions==>', res.data)
                    setquestions(res.data.questions)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setloading(false)
            } catch (error) {
                setloading(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit, adminQuestionsState])
    const handleApproveAll = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.post('/approve/all/pending/questions')
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: 'All pending questions approved successfully!' }
            })
        } catch (error) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const handleApproveAllUsersQuestionsOnly = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.post('/approve/all/users/pending/questions')
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: 'All users pending questions approved successfully!' }
            })
        } catch (error) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const handleApproveAllAdminsQuestionsOnly = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.post('/approve/all/admin/pending/questions')
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: 'All admins pending questions approved successfully!' }
            })
        } catch (error) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    return (
        <div className=''>
            {
                (auth.data.user.isAdmin ) &&
                < div className='flex items-center flex-wrap gap-3 p-5'>
                    <select value={adminQuestionsState} onChange={(e) => setadminQuestionsState(e.target.value)}
                        className='my__select'>
                        <option className='p-2' value=''>my questions</option>
                        <option className='p-2' value='all'>users questions</option>
                        {/* <option className='p-2' value='all'>Adming Questions</option> */}
                    </select>
                    <TakeActionsPopOver
                        handleApproveAll={handleApproveAll}
                        handleApproveAllAdminsQuestionsOnly={handleApproveAllAdminsQuestionsOnly}
                        handleApproveAllUsersQuestionsOnly={handleApproveAllUsersQuestionsOnly}
                    />

                </div>
            }
            {
                loading ?
                    <div className='flex items-center justify-center w-full'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?
                        <>
                            <MyTable
                                adminQuestionsState={adminQuestionsState}
                                showQuestinData={showQuestinData} questions={questions} setquestions={setquestions} />
                            <div className='flex justify-center px-5 pt-5'>
                                <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                            </div>
                        </>
                        :
                        <NotFoundDataUi
                            text={`No questions available`}
                        />
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No questions available...</div>
            }

        </div >
    )
}

export default QuestionsTable