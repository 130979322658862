import { walletConstants } from '../actions/appConstants'

const initial_state = {
    wallet: []
}


const walletReducer = (state = initial_state, action) => {
    switch (action.type) {
        case walletConstants.GET_WALLET:
            state = {
                ...state,
                wallet: action.payload
            }
            break;
        case walletConstants.DETECT_MONEY:
            state = {
                ...state,
                wallet: state.wallet.map(v => {
                    if (v.walletsType === 'free' && action.payload.type === 'free') {
                        //console.log('free')
                        //console.log(v.credit, action.payload.requiredPointsForFree)
                        const toReturn = { ...v, credit: Number(Number(v.credit) - Number(action.payload.requiredPointsForFree)) }
                        //console.log('toreturn ==>', toReturn)
                        return toReturn;
                    }
                    else if (v.walletsType === 'real' && action.payload.type === 'paid') {
                        //console.log(v.credit, action.payload.price)
                        const toReturn = { ...v, credit: Number(v.credit) - Number(action.payload.price) }
                        //console.log(toReturn)
                        return toReturn
                    }
                    else {
                        //console.log('nothing')
                        return v
                    }
                })
            }
            break;
        case walletConstants.ADD_MONEY:
            state = {
                ...state,
                wallet: state.wallet.map(v => {
                    if (v.walletsType === 'free' && action.payload.type === 'free') {
                        //console.log('free')
                        //console.log(v.credit, action.payload.requiredPointsForFree)
                        const toReturn = { ...v, credit: Number(Number(v.credit) + Number(action.payload.requiredPointsForFree)) }
                        //console.log('toreturn ==>', toReturn)
                        return toReturn;
                    }
                    else if (v.walletsType === 'real' && action.payload.type === 'paid') {
                        //console.log(v.credit, action.payload.price)
                        const toReturn = { ...v, credit: Number(v.credit) + Number(action.payload.price) }
                        //console.log(toReturn)
                        return toReturn
                    }
                    else {
                        //console.log('nothing')
                        return v
                    }
                })
            }
            break;
    }
    return state;
}
export default walletReducer