import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { questionsCategoryConstants } from '../../../../actions/appConstants'

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAnswers } from '../../../playQuizComponents/dragDropFillUpTheBlanks/textConverters';
import axios from '../../../../utils/myAxios'
import { useSelector } from 'react-redux';
import NotFoundDataUi from '../../../uiComponents/NotFoundDataUi';
import { CircularProgress } from '@mui/material';

const Items = ({ index }) => {
    return (
        <div className='border border-black  p-3 rounded-2xl '>
            <h1 className=''>
                <span className='font-semibold'>QNO.{index + 1}:  </span>
                This is the uqwekrj sdhfoidsajfp oihipoah pfdisahof oiashdfpoashdfpasodhf posd
            </h1>
            <h1 className='text-bgblue'>
                <span className='font-semibold'>Answer:  </span>
                this is the answer of the above questioon...
            </h1>
        </div>
    )
}
const Question = ({
    correct,
    incorrect,
    val
}) => {
    return (
        <div className={`relative border ${correct ? "border-green-500 bg-green-100" : "border-red-500 bg-red-100"
            }  p-5 rounded-xl`}>
            {
                correct ?
                    <CheckCircleIcon className='!absolute text-green-500 top-2 right-2' />
                    :
                    <CancelIcon className='!absolute text-red-500 top-2 right-2' />
            }
            <h1 className='font-bold'>
                <span className='mr-3'>Question:</span>{val.statement}
            </h1>
            {
                (val?.questionCategory?.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val?.questionCategory?.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val?.questionCategory?.name === questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ ||
                    val.questionCategory?.name === questionsCategoryConstants.MATCH_THE_PAIR
                )
                &&
                val.userSubmission.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                <div></div>
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                Object.values(val?.forCrossWord?.down).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.answer}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS)
                &&
                val.userSubmission.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c.text}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING)
                &&
                val.userSubmission.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.name ? _c.name : ''}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.SORTING_CLASSIFICATION)
                &&
                val?.userSubmission.map((_c, _i) => (
                    <div>
                        <h1 className='font-bold mt-1'>{_c.name} :</h1>
                        {
                            _c?.values.map((val, index) => (
                                <h1 key={index} className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                                    {val.value}
                                </h1>
                            ))
                        }
                    </div>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.FILL_IN_THE_BLANKS ||
                    val.questionCategory?.name === questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE ||
                    val.questionCategory?.name === questionsCategoryConstants.MULTIPLE_QUESTIONS ||
                    val.questionCategory?.name === questionsCategoryConstants.TRUE_FALSE ||
                    val.questionCategory?.name === questionsCategoryConstants.CLOSED_ENDED
                ) &&
                <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Submitted Answer:</span>
                    {val.userSubmission}     <span className='font-bold mx-3'>Correct Answer : </span> {val.correctAnswer}
                </h1>
            }
            {
                val.questionCategory?.name === questionsCategoryConstants.SENTENCE_SEQUENCE &&
                val.userSubmission.map((val, index) => (
                    <h1 key={index} className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {val?.value}
                    </h1>
                ))
            }
        </div>
    )
}
const KnowledgeBank = ({ quizz: qid  , user:user, isPastQuiz}) => {
    const [loading, setloading] = useState(false)
    const { auth } = useSelector(s => s)
    const [questionsArray, setquestionsArray] = useState([])
    useEffect(async () => {
        if (qid) {
            setloading(true)
            const res = await axios.get(`/get/single/gammer/${user ? user : auth.data.user._id}/${qid}/${isPastQuiz}`)
            setloading(false)
            //console.log('questions==>', [...res.data.gamer.correctQuestions, ...res.data.gamer.incorrectQuestions])
            setquestionsArray([...res.data.gamer.correctQuestions, ...res.data.gamer.incorrectQuestions])
        }
    }, [qid])
    return (
        <Cont>
            <div className='grid grid-cols-1 gap-4'>
                {
                    loading ?
                        <div className='flex items-center justify-center'>
                            <CircularProgress />
                        </div>
                        :
                        questionsArray.length > 0 ?
                            questionsArray.map((val, index) => (
                                <Question
                                    key={index}
                                    val={val}
                                    correct={val.correct === true ? true : false}
                                    incorrect={val.correct === true ? false : true}
                                />
                            ))
                            // <h1>questions available</h1>
                            :
                            <NotFoundDataUi
                                text={`No Questions Available`}
                            />
                    // <h1>No Questions Available...</h1>
                }
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw``}
`
export default KnowledgeBank