import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { deleteQuestion } from '../../../actions/question.actions';
import { createSubscriptionAction, deleteSubscriptionAction, updateSubscription } from '../../../actions/subscription.actions';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function TableModal({ open, setopen }) {
    const initial_state = {
        title: '',
        price: 1,
        numberOfQuiz: 1,
        description: '',
    }
    const [updatedData, setupdatedData] = React.useState(initial_state)
    const dispatch = useDispatch()
    const handleClose = () => {
        setopen(false)
    };
    const handleOnChange = (e) => {
        const { value, name } = e.target
        setupdatedData({
            ...updatedData,
            [name]: value
        })
    }
    const handleCreate = () => {
        dispatch(createSubscriptionAction(updatedData)).then(val=>{
            if(val) setopen(false)
        })
    }
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true}
            maxWidth={'md'}>
            <DialogTitle>Subscription</DialogTitle>

            <div className='p-10 w-full'>
                <div>
                    <h1>Title</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.title}
                        onChange={handleOnChange}
                        name='title'
                    />
                </div>
                <div>
                    <h1>Description</h1>
                    <textarea
                        rows={5}
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.description}
                        onChange={handleOnChange}
                        name='description'
                    />
                </div>
                <div>
                    <h1>Price</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.price}
                        onChange={handleOnChange}
                        name='price'
                        type='number'
                        min={1}

                    />
                </div>
                <div>
                    <h1>Number of Quizs</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.numberOfQuiz}
                        onChange={handleOnChange}
                        name='numberOfQuiz'
                        type='number'
                        min={1}
                    />
                </div>
                <div className='flex items-center justify-end space-x-3 mt-5'>
                    <Button onClick={handleCreate} variant='contained' color='primary'>Create</Button>
                    <Button onClick={handleClose} variant='contained' color='secondary'>Cancel</Button>
                </div>
            </div>

        </Dialog>
    );
}

export default TableModal;