import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants } from '../../actions/appConstants'
import { varifyUser, resendCodeAction, confirmResetPasswordAction } from '../../actions/auth.actions'
import BGImage from '../../assets/bg/result.svg'
import Logo from '../../assets/logo/logo.png'
const ResetPasswordRequest = () => {
    const { token } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const extras = useSelector(state => state.extras)
    const [formData, setformDAta] = useState({
        newPass: '',
        cnf_pass: '',
        resetTocken: '',
    })
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformDAta({
            ...formData,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (formData.newPass !== formData.cnf_pass) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "confirm password does not match!" }
            })
        }
        dispatch(confirmResetPasswordAction(formData, navigate))
        // console.log('form data==>', formData)
    }
    useEffect(() => {
        if (token) {
            setformDAta({
                ...formData,
                resetTocken: token
            })
        }
    }, [token])
    return (
        <Container BGImage={BGImage}>

            <MinCont>
                <div className='flex items-center justify-center my-6'>
                    <img onClick={() => navigate('/')} className='w-[12rem] object-contain cursor-pointer' src={Logo} alt='' />
                </div>
                <h1 className='text-xl !mt-0 text-center capitalize font-semibold'>change your password</h1>
                <form className='space-y-7' onSubmit={handleSubmit}>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <h1 className='font-bold'>new password</h1>
                        <input
                            value={formData.newPass}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your new password...'
                            name='newPass'
                            onChange={onChangeHandler}
                            type='password'
                            required
                        />
                    </div>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <h1 className='font-bold'>confirm password</h1>
                        <input
                            value={formData.cnf_pass}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='confirm password...'
                            name='cnf_pass'
                            onChange={onChangeHandler}
                            type='password'
                            required
                        />
                    </div>
                    <div className='bg-blue-700 text-white border-blue-700 relative  w-[90%] ml-auto mr-auto border p-5 rounded-md cursor-pointer'>
                        <button type='submit' className='absolute top-0 left-0 bottom-0 right-0 font-bold text-center'>change password</button>
                    </div>
                </form>
            </MinCont>
        </Container>
    )
}
const MinCont = styled.div`
${tw`lg:w-[30%] w-[95%] bg-white space-y-10 p-2 rounded-xl`}
`
const Container = styled.div`
${tw`relative h-screen flex items-center justify-center`}
background-image: url(${BGImage}) ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover;
`
export default ResetPasswordRequest