import axios from './../utils/myAxios'
import { authConstants, extrasConstancts, globalConstants } from './appConstants'

import store from './../store'
import { homeModalConstants } from './extras.actions'
export const loginActions = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/signin', {
                ...data
            })
            if (res.status === 200) {
                //
                //token to localstorage
                localStorage.setItem(globalConstants.QUEZETO_AUTH_TOKEN, res.data.accessToken)
                //token to localstorage
                dispatch({
                    type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                    payload: false,
                })
                dispatch({
                    type: authConstants.LOGIN,
                    payload: res.data
                })
                dispatch({
                    type: homeModalConstants.SHOW_HOME_MODAL,
                    payload: res.data.showHomeModal
                })
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { success: 'congrate you logged in' }
                })
            }
            if (res.status === 201 && res.data.isVarifiedUser === false) {
                const _res = await axios.post('/againgenratecode', {
                    email_number: data.email_number
                })
                dispatch({
                    type: authConstants.EXTRAS_LOGIN_DATA,
                    payload: data
                })
                if (_res.status === 200) {
                    
                    const {
                        token,
                        msg,
                    } = _res.data
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { success: msg }
                    })
                    dispatch({
                        type: authConstants.VARIFY_USER_TOKEN,
                        payload: token
                    })
                    return navigate(`/varifyuser`)
                }
                dispatch({
                    type: globalConstants.ALERT,
                    payload: {}
                })
            }
        } catch (error) {
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else if (error?.message) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
            else{
                console.log("error ",error)
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "Having problem with login" }
                })
            }
            
        }
    }
}

export const signUpActions = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/signup', {
                ...data
            })
            //             isVarifiedUser
            // token
            dispatch({
                type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                payload: false,
            })
            if (res.status === 200 && res.data.isVarifiedUser === false) {
                const {
                    msg,
                    token
                } = res.data
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { success: msg }
                })
                dispatch({
                    type: authConstants.VARIFY_USER_TOKEN,
                    payload: token
                })
                const {
                    email_number,
                    password
                } = data
                dispatch({
                    type: authConstants.EXTRAS_LOGIN_DATA,
                    payload: {
                        email_number,
                        password
                    }
                })
                return navigate(`/varifyuser`)
            }
            dispatch({
                type: globalConstants.ALERT,
                payload: {}
            })
        } catch (error) {
            //
            //

            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const varifyUser = (data, navigate) => {
    return async (dispatch) => {
        try {
            const { extras: { extraLoginData } } = store.getState()
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/varifysignup', {
                code: data.code
            })
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "you are varified successfully!" }
            })
            dispatch(loginActions(extraLoginData, navigate))
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const resendCodeAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _res = await axios.post('/againgenratecode', {
                email_number: data.email_number
            })
            if (_res.status === 200) {
                const {
                    token,
                    msg,
                } = _res.data
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { success: msg }
                })
                dispatch({
                    type: authConstants.VARIFY_USER_TOKEN,
                    payload: token
                })
            }
            // dispatch({
            //     type: globalConstants.ALERT,
            //     payload: {}
            // })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const resetPasswordAction = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/resetpassword', {
                ...data,
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const confirmResetPasswordAction = (data, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/resetpasswordconfirm', {
                ...data,
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            return navigate('/login')
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const keepUserLoggedIn = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const token = localStorage.getItem(globalConstants.QUEZETO_AUTH_TOKEN)
            // //
            if (!token) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { loading: false }
                })
                localStorage.removeItem(globalConstants.QUEZETO_AUTH_TOKEN)
                dispatch({
                    type: authConstants.HANDLE_AUTENTICATING,
                    payload: false
                })
                return;
            }
            await dispatch({
                type: authConstants.EXTRAS_KEEP_KEEP_USER_LOGIN_TOKEN,
                payload: token,
            })
            const res = await axios.post('/getuserinformation')
            dispatch({
                type: authConstants.LOGIN,
                payload: res.data
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: {}
            })
            dispatch({
                type: authConstants.HANDLE_AUTENTICATING,
                payload: false
            })
        } catch (error) {
            localStorage.removeItem(globalConstants.QUEZETO_AUTH_TOKEN)
            dispatch({
                type: authConstants.HANDLE_AUTENTICATING,
                payload: false
            })
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const changePasswordAction = (body) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })

            const res = await axios.post('/changepassword', {
                ...body
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            return true;
        } catch (error) {
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const updateProfileAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/updateprofile', {
                ...data
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
        } catch (error) {
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const logoutAction = (data) => {
    return async (dispatch) => {
        const { auth } = store.getState()
        try {
            localStorage.clear()
            dispatch({
                type: authConstants.LOGOUT,
                payload: {}
            })

        } catch (error) {
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}

export const getUserStaticTabData = (uid) => {
    console.log(uid)
    return async (dispatch) => {
        try {
            const res = await axios.get(`/static/tabs/data/${uid}`)
            console.log(res.data)
            dispatch({
                type: authConstants.GET_USER_STATIC_TABS,
                payload: res.data
            })
        } catch (error) {
            return dispatch({
                type: globalConstants.ALERT,
                paylaod: { error: error.message }
            })
        }
    }
}