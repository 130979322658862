import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from './Layout/Layout'
import { Avatar } from '@mui/material';
import StaticCategoriesC from './staticCategoriesC/StaticCategoriesC';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import axios from '../utils/myAxios'
import CircularProgress from '@mui/material/CircularProgress'
import bg from '../assets/staticdata/bg.jpg'
function FollowPage() {
    const { id } = useParams()
    const { auth } = useSelector(s => s)
    // console.log(id)
    const [btnText, setBtnText] = useState('')
    const [user_data, setData] = useState({})
    const getUserData = async () => {
        try {
            let res = await axios.post('/follower/getFollowingData', {
                user: id
            })
            // console.log(res)
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }
    useEffect(async () => {
        try {
            let res = await axios.post('/follower/getFollowingData', {
                user: id
            })
            // console.log(res)
            setData(res.data)
            // console.log("data =>>>>>>->>>", user_data._user.followers.findIndex((i) => i === auth.data.user._id))
            if (res) {
                if (user_data._user.followers.findIndex((i) => i === auth.data.user._id !== -1)) {
                    // console.log("hello from condition")
                    setBtnText("UnFollow")
                }
                else {
                    setBtnText("Follow")
                }

            }
        } catch (error) {
            // console.log(error)
        }
    }, [])
    useEffect(() => {
        if (user_data?._user) {
            if (user_data._user.followers.findIndex((i) => i === auth.data.user._id) !== -1) {

                setBtnText("UnFollow")
            }
            else {
                setBtnText("Follow")
            }
        }

    }, [user_data])
    const handleFollow = async () => {
        if (user_data._user.followers.findIndex((i) => i === auth.data.user._id) !== -1) {
            setBtnText("progress")
            const res2 = await axios.post('/follower/remove', {
                user: auth.data.user._id,
                f_user: id
            })
            if (res2) {
                setBtnText("Follow")
            }
            getUserData()
        }
        else {
            setBtnText("progress")
            const res2 = await axios.post('/follower/add', {
                user: auth.data.user._id,
                f_user: id
            })
            if (res2) {
                setBtnText("UnFollow")
            }
            getUserData()
        }
    }

    return (
        <Layout>

            {(user_data._user && btnText) &&
                <div className='w-full '>
                    <div className='shadow-lg'>
                        <div className='w-full bg-cover h-64 rounded' style={{background : `url(${bg})`}}>
                            <Avatar

                                src={user_data._user.profileImage}
                                sx={{ width: 144, height: 144 }}
                                className='relative top-[70%] left-12 cursor-pointer ring ring-green-400 ring-offset-2'
                            />
                        </div>
                        <div className='w-full pt-24 pb-12 bg-yellow-50'>
                            <div className='ml-10'>
                                <h2>{`${user_data._user.firstName} ${user_data._user.lastName}`}</h2>
                                <span className='text-slate-400'>@{user_data._user.userName}</span>
                            </div>

                            <div className='flex mx-10 mt-5 items-center justify-between'>

                                <ul className='w-3/5 flex lg:flex-row justify-between flex-wrap sm:flex-col'>
                                    <li className='text-xl text-slate-600 pb-2'><span className='font-semibold text-slate-500'>{user_data?._user.followers.length}</span> Followers</li>
                                    <li className='text-xl text-slate-600 pb-2'><span className='font-semibold text-slate-500'>{user_data?._user.following.length}</span> Following</li>
                                    <li className='text-xl text-slate-600 pb-2'><span className='font-semibold text-slate-500'>{user_data?.user_quizzes}</span> Quiz Scheduled</li>
                                </ul>
                                <button className='shadow-lg w-36 h-12 rounded-3xl bg-yellow-400 text-white hover:bg-yellow-500' onClick={() => handleFollow()}>{btnText === 'progress' ? <CircularProgress size="2rem" color='inherit' /> : <><PersonAddAlt1Icon /> {btnText}</>}  </button>
                            </div>
                        </div>

                    </div>
                    {/* testing the branch */}
                    {/* <Quizzes></Quizzes> */}
                    {/* {quizzes by the user} */}
                    <StaticCategoriesC />
                </div>}
        </Layout>
    )
}

export default FollowPage