import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import axios from '../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../actions/appConstants'
import { CircularProgress } from '@mui/material'
import Section from '../components/quizzesWithDifferentSubjectC/Section'
import { useNavigate } from 'react-router-dom'
import quizImg from '../assets/staticdata/3.png'
import { keepUserLoggedIn } from '../actions/auth.actions'
const QuizzesWithDifferentSubjectsPage = ({ quizzType }) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [category, setcategory] = useState('')
    const [quizzes, setquizzes] = useState([])
    const [loading, setloading] = useState(false)
    const { auth } = useSelector(s => s)
    const { cid , isFollower , type} = useParams()
    useEffect(()=>{
        dispatch(keepUserLoggedIn())
        console.log(auth.data.user)
    }, [])
    useEffect(async () => {
        if (cid) {
            try {
                
                setloading(true)
                setcategory(cid)
                let res
                if(type === 'all')
                {
                    res = await axios.post(`/get/quizzes/with/differentsubjects/${cid}?quizzType=${quizzType}`, {
                        type : 'user', 
                        user_id : auth.data.user.following
                    })
                }
                else if(type)
                {
                    res = await axios.post(`/get/quizzes/with/differentsubjects/${cid}?quizzType=${quizzType}`, {
                        type : 'user', 
                        user_id : type
                    })
                }
                else{
                    res = await axios.post(`/get/quizzes/with/differentsubjects/${cid}?quizzType=${quizzType}`,{
                        type: "all", 
                    })
                }
                setquizzes(res.data.data)
                setloading(false)
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }, [cid])
    const handleUpdatedQuizz = (updatedQuizz) => {
        const toUpdateQuizzes = [...quizzes]
        const quizzesArr = toUpdateQuizzes.map(v => {
            if (v.subject === updatedQuizz.subject) {
                return {
                    ...v,
                    quizzes: v.quizzes.map(q => q._id === updatedQuizz._id ? updatedQuizz : q)
                }
            }
            return v;
        })
        setquizzes(quizzesArr)
    }
    const handleArchive = (updatedQuizz) => {
        const toUpdateQuizzes = [...quizzes]
        const quizzesArr = toUpdateQuizzes.map(v => {
            if (v.subject === updatedQuizz.subject) {
                return {
                    ...v,
                    quizzes: v.quizzes.filter(q => q._id !== updatedQuizz._id)
                }
            }
            return v;
        })
        // setquizzes(quizzesArr)
        // console.log('befor archive==>', quizzes)
        // console.log('after archive==>', quizzesArr)
        setquizzes(quizzesArr)
    }
    return (
        
        <div>
            {/* {console.log('quizzes==>', quizzes)} */}
            {
                loading ?
                    <div className='flex items-center justify-center mt-5'>
                        <CircularProgress />
                    </div>
                    :
                    quizzes.length > 0 ?
                    
                        <div>
                            {
                               
                                quizzes.map((val, index) => (
                                    <Section
                                        cid={category}
                                        key={index}
                                        val={val}
                                        handleUpdatedQuizz={handleUpdatedQuizz}
                                        handleArchive={handleArchive}
                                        isFollower = {isFollower}
                                    />
                                ))
                            }
                        </div>
                        :
                        // <NotFoundDataUi text={`No Quizzes Available`} />
                        <div className='w-full flex flex-col items-center'>
                            <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                            <div className='w-full flex flex-col items-center'>
                                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                    There is no live quiz now, Why don't you become our quizmaster and schedule a quiz for others & get rewarded
                                </p>
                                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={()=>navigate('/schedulequiz')}>Schedule Quiz</button>
                            </div>
                        </div>
            }
        </div>
    )
}

export default QuizzesWithDifferentSubjectsPage