import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { tictactoeObject } from './objectCreator'
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CircleIcon from '@mui/icons-material/Circle';
const played = '⭕'
const pending = '⬛'
const TicTacToe = ({
    questions, handleTicTacToeQuestionsIndexes, questionsArray,
    tictactoeAnswersData,
    settictactoeAnswersData
}) => {
    const [currentSelectedIndex, setcurrentSelectedIndex] = useState(0)
    const dispatch = useDispatch()
    const [tomaparray, settomaparray] = useState([])
    const [reset, setreset] = useState(false)
    const [clickedIndexes, setclickedIndexes] = useState([])
    useEffect(() => {
        if (questions) {
            const size = questions.length === 9 ? 3 : 4
            var myArray = Array(size).fill(Array(size).fill("none"));
            myArray.map(v => {
                return tictactoeObject(v)
            })
            settomaparray(myArray.map(v => {
                return tictactoeObject(v)
            }))
        }
    }, [questions, reset])
    const handleClick = async (bigIndex, smallIndex) => {
        //
        //
        if (questionsArray.length > 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "Answer the question first!" } })
        let played = false;
        tomaparray.find((v, index) => {
            if (index === bigIndex) {
                if (v[smallIndex].played) {
                    played = true;
                }
            }
        })
        if (played) {
            return;
        }
        if (!isAnyPlayed()) {
            if (smallIndex !== 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "Select Left Side of The Box!" } })
        }
        if (clickedIndexes.length === 1) {
            if (
                //horizental movement...
                (smallIndex === clickedIndexes[0].smallIndex + 1 && bigIndex === clickedIndexes[0].bigIndex)
                //vertical...
                || (bigIndex === clickedIndexes[0].bigIndex + 1 && smallIndex === clickedIndexes[0].smallIndex)
                || (bigIndex === clickedIndexes[0].bigIndex + 1 && smallIndex === clickedIndexes[0].smallIndex + 1)
            ) {

            } else {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: 'Kindly select in the correct sequence!' }
                })
            }
        }
        if (clickedIndexes.length > 1) {
            if (
                isMovingHorizentally(smallIndex, bigIndex)
                || isMovingVertically(smallIndex, bigIndex)
                || isMovingVerically(smallIndex, bigIndex)
            ) {
            } else {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: 'Kindly select in the correct sequence!' }
                })
            }
        }
        //horizental movement...
        const updated = tomaparray.map((val, index) => {
            return val.map((v, i) => {
                if (index === bigIndex && i === smallIndex) {
                    return { ...v, played: true }
                }
                return v;
            })
        })
        settomaparray(updated)
        if (clickedIndexes.length === tomaparray[0].length - 1) {
            settictactoeAnswersData({ ...tictactoeAnswersData, completedRows: [...tictactoeAnswersData.completedRows, [...clickedIndexes, { bigIndex, smallIndex }]] })
            setclickedIndexes([])
        } else {
            setclickedIndexes([...clickedIndexes, { bigIndex, smallIndex }])
        }
        //
        handleTicTacToeQuestionsIndexes((tomaparray[0].length * bigIndex) + tomaparray[bigIndex][smallIndex].index, tomaparray)
        // setcurrentSelectedIndex({ smallIndex, bigIndex })
    }
    const isMovingHorizentally = (smallIndex, bigIndex) => {
        if (
            (
                clickedIndexes[clickedIndexes.length - 1].smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex + 1
                && smallIndex === clickedIndexes[clickedIndexes.length - 1].smallIndex + 1
                && bigIndex === clickedIndexes[clickedIndexes.length - 1].bigIndex
                && bigIndex === clickedIndexes[0].bigIndex
            )
        ) {
            return true;
        }
        return false;
    }
    const isMovingVertically = (smallIndex, bigIndex) => {
        if (
            (smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex && smallIndex === clickedIndexes[clickedIndexes.length - 1].smallIndex)
        ) {
            return true;
        }
        return false
    }
    const isMovingVerically = (smallIndex, bigIndex) => {
        if (
            (
                (bigIndex === clickedIndexes[clickedIndexes.length - 1].bigIndex + 1 && smallIndex === clickedIndexes[clickedIndexes.length - 1].smallIndex + 1)
                &&
                (clickedIndexes[clickedIndexes.length - 1].bigIndex === clickedIndexes[clickedIndexes.length - 2].bigIndex + 1 && clickedIndexes[clickedIndexes.length - 1].smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex + 1)
            )
        ) {
            return true;
        }
        return false
    }
    const handleEmptyIndexes = (smallIndex, bigIndex) => {
        //

    }
    const isAnyPlayed = () => {
        let played = false;
        tomaparray.map((val, index) => {
            val.map((v, i) => {
                if (v.played) {
                    played = true;
                }
            })
        })
        return played;
    }
    const isPlayedIndex = (smallIndex, bigIndex) => {
        let toReturn = false;
        try {
            if (tomaparray[bigIndex][smallIndex].played) {
                toReturn = true;
            }
        } catch (error) {
            toReturn = true
        }
        return toReturn;
    }
    const caMoveFurther = (smallIndex, bigIndex) => {
        let toReturn = false;
        //for horizental...
        if (
            (
                clickedIndexes[clickedIndexes.length - 1].smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex + 1
                && clickedIndexes[clickedIndexes.length - 1].bigIndex === clickedIndexes[clickedIndexes.length - 2].bigIndex
            )
        ) {
            //check for further move...
            if (
                (
                    clickedIndexes[clickedIndexes.length - 1].smallIndex === tomaparray[0].length - 1
                )
                || isPlayedIndex(Number(clickedIndexes[clickedIndexes.length - 1].smallIndex + 1), Number(clickedIndexes[clickedIndexes.length - 1].bigIndex))
            ) {
                settictactoeAnswersData({ ...tictactoeAnswersData, completedRows: [...tictactoeAnswersData.completedRows, [...clickedIndexes]] })
                setclickedIndexes([])

            }
        }
        //for vertical...
        if (
            (
                clickedIndexes[clickedIndexes.length - 1].smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex
                && clickedIndexes[clickedIndexes.length - 1].bigIndex === clickedIndexes[clickedIndexes.length - 2].bigIndex + 1
            )
        ) {
            if (
                (
                    clickedIndexes[clickedIndexes.length - 1].bigIndex === tomaparray.length - 1
                )
                || isPlayedIndex(Number(clickedIndexes[clickedIndexes.length - 1].smallIndex), Number(clickedIndexes[clickedIndexes.length - 1].bigIndex + 1))
            ) {
                settictactoeAnswersData({ ...tictactoeAnswersData, completedRows: [...tictactoeAnswersData.completedRows, [...clickedIndexes, { bigIndex, smallIndex }]] })
                setclickedIndexes([])
            }
        }
        //for diagonals...
        if (
            (
                clickedIndexes[clickedIndexes.length - 1].smallIndex === clickedIndexes[clickedIndexes.length - 2].smallIndex + 1
                && clickedIndexes[clickedIndexes.length - 1].bigIndex === clickedIndexes[clickedIndexes.length - 2].bigIndex + 1
            )
        ) {
            if (
                (clickedIndexes[clickedIndexes.length - 1].smallIndex + 1 > tomaparray[0].length - 1
                )
                || isPlayedIndex(clickedIndexes[clickedIndexes.length - 1].smallIndex + 1, clickedIndexes[clickedIndexes.length - 1].bigIndex + 1)
            ) {
                settictactoeAnswersData({ ...tictactoeAnswersData, completedRows: [...tictactoeAnswersData.completedRows, [...clickedIndexes, { bigIndex, smallIndex }]] })
                setclickedIndexes([])
            }
        }
    }
    useEffect(() => {
        if (clickedIndexes.length === 2) {
            caMoveFurther(clickedIndexes[clickedIndexes.length - 1].smallIndex, clickedIndexes[clickedIndexes.length - 1].bigIndex)
        }
    }, [clickedIndexes])
    useEffect(() => {
        if (clickedIndexes.length >= 1) {
            if (
                isPlayedIndex(clickedIndexes[clickedIndexes.length - 1].smallIndex + 1, clickedIndexes[clickedIndexes.length - 1].bigIndex)
                && isPlayedIndex(clickedIndexes[clickedIndexes.length - 1].smallIndex, clickedIndexes[clickedIndexes.length - 1].bigIndex + 1)
                && isPlayedIndex(clickedIndexes[clickedIndexes.length - 1].smallIndex + 1, clickedIndexes[clickedIndexes.length - 1].bigIndex + 1)
            ) {
                settictactoeAnswersData(
                    {
                        ...tictactoeAnswersData, completedRows: [...tictactoeAnswersData.completedRows, [...clickedIndexes]]
                    }
                )
                setclickedIndexes([])
            }
        }
    }, [clickedIndexes])
    return (
        <>
            <h1 className='text-center font-extrabold text-2xl mt-5'>Tic Tac Toe</h1>
            <div className='flex items-center justify-center flex-col'>
                {
                    tomaparray?.map((val, index) => (
                        <div className='p-2 flex items-center'>
                            {
                                val.map((v, i) => (
                                    <div onClick={() => handleClick(index, i)} className='w-16 h-16 border border-green-400 shadow-md shadow-green-400 p-2 flex items-center justify-center'>

                                        {
                                            v.played ?
                                                <CircleIcon className='!text-red-500 cursor-pointer' fontSize='large' />
                                                :
                                                <ViewInArIcon className='!text-green-500 cursor-pointer' fontSize='large' />
                                        }

                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default TicTacToe