import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Star from './../../assets/star.png'
import { useDispatch, useSelector } from 'react-redux';
import { homeModalConstants } from '../../actions/extras.actions';
import Countup from 'react-countup'



const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const dispatch = useDispatch()
    const { onClose, selectedValue, open, extras } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className='p-10'>
                <div className='flex items-center justify-center'>
                    <img
                        src={Star}
                        alt=''
                        className='w-28 object-contain mt-auto'
                    />
                </div>
                <div className='mt-5 space-y-3'>



                    <h1 className='text-xl text-center capitalize text-red-500 font-bold'>
                        <Countup end={extras.HomeModalData.earning} />     Qzetos
                    </h1>
                    <h1 className='text-[12px] opacity-60 text-center capitalize'>you have earned {`${extras.HomeModalData.earningtype ? extras.HomeModalData.earningtype : "free"}`} qzetos play more to earn</h1>
                    <div>
                        <button onClick={() => dispatch({
                            type: homeModalConstants.SHOW_HOME_MODAL,
                            payload: {
                                show: false,
                                earning: 0,
                            }
                        })} className='text-blue-600 bg-blue-100 rounded-full w-full py-3'>Continue</button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
    const { extras } = useSelector(s => s)
    const [open, setOpen] = React.useState(extras.HomeModalData.show);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };
    React.useEffect(() => {
        setOpen(extras.HomeModalData.show)
    }, [extras])
    return (
        <div>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                extras={extras}
            />
        </div>
    );
}
