import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

export default function MyBackDrop() {
    const alert = useSelector(state => state.alert)
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 2 }}
            open={alert?.loading ? true : false}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
