import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import tw from 'twin.macro';
import { globalConstants } from '../../../actions/appConstants';
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions';
import DragAbleC from './DragAbleC';
import Option from './Options';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const nextChar = (c) => {
    return String.fromCharCode(c.charCodeAt(0) + 1);
}
const MatchThePair = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const { question, auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const initial_state = {
        statement: '',
        collumns: {
            a: [''],
            b: [''],
            c: ['']
        }
    }
    const [matchThePairData, setmatchThePairData] = useState(initial_state)
    const onChangeHandler = (targetCol, index, value) => {
        const data = {
            ...matchThePairData,
            collumns: {
                ...matchThePairData.collumns,
                [targetCol]: matchThePairData.collumns[targetCol].map((_val, _index) => _index === index ? value : _val)
            }
        }
        setmatchThePairData({
            ...matchThePairData,
            collumns: data.collumns
        })
    }
    const onChangeHandlerForBC = (targetCol, index, value) => {
        const data = {
            ...matchThePairData,
            collumns: {
                ...matchThePairData.collumns,
                b: matchThePairData.collumns[targetCol].map((_val, _index) => _index === index ? value : _val),
                c: matchThePairData.collumns[targetCol].map((_val, _index) => _index === index ? value : _val),
            }
        }
        setmatchThePairData({
            ...matchThePairData,
            collumns: data.collumns
        })
    }
    const handleAddMore = () => {
        setmatchThePairData({
            ...matchThePairData,
            collumns: {
                a: [...matchThePairData.collumns.a, ''],
                b: [...matchThePairData.collumns.b, ''],
                c: [...matchThePairData.collumns.c, ''],
            }
        })
    }
    const handleRemoveLastRow = () => {
        if (matchThePairData.collumns.a.length < 2) return;
        //console.log('in the thing==.')
        matchThePairData.collumns.a.pop()
        matchThePairData.collumns.b.pop()
        matchThePairData.collumns.c.pop()
        setmatchThePairData({ ...matchThePairData })
        //console.log('in the thing==.')
    }
    const handleSubmit = async (arg) => {
        let obj = {
            ...matchThePairData,
            questionCategory: type,
            subject,
        }
        let avail = true
        for (let x = 0; (x < matchThePairData.collumns.a.length && avail); x++) {
            if (!(matchThePairData.collumns.a[x]).trim()) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: `kindly fill the fields` }
                })
                avail = false
                break;
            }
        }
        for (let x = 0; (x < matchThePairData.collumns.b.length && avail); x++) {
            if (!(matchThePairData.collumns.b[x]).trim()) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: `kindly fill the fields` }
                })
                avail = false
                break;
            }
        }
        for (let x = 0; (x < matchThePairData.collumns.c.length && avail); x++) {
            if (!(matchThePairData.collumns.c[x]).trim()) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: `kindly fill the fields` }
                })
                avail = false
                break;
            }
        }
        if (!avail) return;
        if (!(matchThePairData.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill the statement!" }
            })
        }
        const _idFind = question.questionsCategories.find((v) => v.name === obj.questionCategory)
        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id
        }
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question category!" }
            })
        }
        if (!(obj.subject).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill subject!" }
            })
        }
        //console.log('obj==>', obj)
        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        if (arg) {
            setmatchThePairData(initial_state)
        } else {
            setmatchThePairData(initial_state)
            setshowAddQuestions(false)
        }
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(matchThePairData.collumns.c);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        //console.log('new array==>', items)
    }
    //console.log('match the pair data==>', matchThePairData)
    useEffect(() => {
        if (showQuestinData) {
            setmatchThePairData(showQuestinData)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <Container>
            <div className='space-y-2 mt-5'>
                <h1 className='text-bgblue font-bold'>Match the column</h1>
                <input
                    className='border border-black py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={matchThePairData.statement}
                    placeholder='write question statement...'
                    onChange={(e) => setmatchThePairData({ ...matchThePairData, statement: e.target.value })}
                />
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3 mt-10'>
                <div>
                    <h1 className='text-bgblue font-semibold'>Column A</h1>
                    {
                        matchThePairData.collumns.a.map((val, index) => (
                            <Option targetCol='a' key={index} onChangeHandlerForBC={onChangeHandlerForBC} onChangeHandler={onChangeHandler} index={index} value={val} />
                        ))
                    }
                </div>
                <div>
                    <h1 className='text-bgblue font-semibold'>Column B</h1>
                    {
                        matchThePairData.collumns.b.map((val, index) => (
                            <Option targetCol='b' key={index} onChangeHandlerForBC={onChangeHandlerForBC} onChangeHandler={onChangeHandler} index={index} value={val} />
                        )) 
                    }
                </div>
                <div>
                    <h1 className='text-bgblue font-semibold'>Correct Column</h1>
                    <div>
                        <DragAbleC
                            setmatchThePairData={setmatchThePairData}
                            matchThePairData={matchThePairData}
                            c={matchThePairData.collumns.c} onChangeHandler={onChangeHandler} onChangeHandlerForBC={onChangeHandlerForBC}
                        />
                    </div>
                </div>
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <div className=''>
                        {
                            matchThePairData.collumns.a.length > 1 &&
                            <div>
                                <button onClick={handleRemoveLastRow} className='bg-red-500 text-white py-3 px-5 rounded-md text-xs mt-3'>Remove last row</button>
                            </div>
                        }
                        <div>
                            <button className='mt-10 text-white bg-bgblue py-2 px-4 rounded-md' onClick={handleAddMore}>Add more</button>
                        </div>
                    </div>
            }
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <BottomButtons>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </BottomButtons>
            }

        </Container>
    )
}
const BottomButtons = styled.div`
${tw`flex items-center justify-end mt-16 space-x-2`}
`
const Container = styled.div`

`
export default MatchThePair;