import React from 'react'
import styled from 'styled-components'
import PlateformC from '../components/PlateformSettingComponent/PlateformC'
import Layout from './../components/Layout/Layout'
const PlateformSettings = () => {
  return (
    <Layout>
      <PlateformC />
    </Layout>
  )
}
const Cont = styled.div`

`
export default PlateformSettings