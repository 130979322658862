import { Avatar, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import DetailPageTabs from "./DetailsPageTabs";
// import StarRatings from 'react-star-ratings'
import moment from "moment";
import axios from "../../utils/myAxios";

//icons.
import { CircularProgress } from "@mui/material";

//icons.
//iconsright
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import Logo from "./../../assets/logo/logo.png";
//card modal
//iconsright

const RightItems = ({ Icon, text, data }) => {
  return (
    <div className="flex items-center justify-between opacity-50">
      <div className="flex items-center space-x-3">
        <Icon />
        <h1>{text}</h1>
      </div>
      <span>{data}</span>
    </div>
  );
};
const QuizzithonDetailCard = ({ quizzithonDetails }) => {
  const { state } = useLocation();
  const [open, setopen] = useState(false);
  const [btnText, setBtnText] = useState();
  const [user_data, setData] = useState();

  const handleUpdatedQuiz = () => {};
  const navigate = useNavigate();
  //for play & unregister etc...
  const [timer, settimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const cardInit = {
    play: false,
    timesup: false,
    register: false,
    unregister: false,
  };
  const [cardStates, setcardStates] = useState(cardInit);

  //
  const [comment, setcomment] = useState("");
  //
  //rating state

  //rating state
  const dispatch = useDispatch();
  const [dataTosho, setdatatoshow] = useState({
    availTimeToRegister: false,
    numberOfQuestions: 0,
  });
  const { auth, question } = useSelector((state) => state);

  useEffect(() => {
    if (quizzithonDetails.start_time) {
      const str2 = quizzithonDetails.start_time;
      const str1 = new Date();
      var diff = Date.parse(str2) - Date.parse(str1);
      // //console.log('diffrere==>', diff)
      if (diff > 0) {
        setdatatoshow({
          ...dataTosho,
          availTimeToRegister: true,
        });
      } else {
        setdatatoshow({
          ...dataTosho,
          availTimeToRegister: false,
        });
      }
    }
  }, [quizzithonDetails.start_time]);

  //get like state

  //add the views for the quizz

  //follow feature
  const getUserData = async () => {
    try {
      let res = await axios.post("/follower/getFollowingData", {
        user: quizzithonDetails.scheduled_by._id,
      });
      // console.log(res)
      setData(res.data);
    } catch (error) {
      // console.log(error)
    }
  };
  useEffect(async () => {
    try {
      let res = await axios.post("/follower/getFollowingData", {
        user: quizzithonDetails.scheduled_by._id,
      });
      // console.log(res)
      setData(res.data);
      // console.log("data =>>>>>>->>>", user_data._user.followers.findIndex((i) => i === auth.data.user._id))
      if (res) {
        if (
          user_data._user.followers.findIndex(
            (i) => (i === auth.data.user._id) !== -1
          )
        ) {
          // console.log("hello from condition")
          setBtnText("UnFollow");
        } else {
          setBtnText("Follow");
        }
      }
    } catch (error) {
      // console.log(error)
    }
  }, [quizzithonDetails]);
  useEffect(() => {
    if (user_data?._user) {
      if (
        user_data._user.followers.findIndex((i) => i === auth.data.user._id) !==
        -1
      ) {
        setBtnText("UnFollow");
      } else {
        setBtnText("Follow");
      }
    }
  }, [user_data]);

  const handleFollow = async () => {
    if (
      user_data._user.followers.findIndex((i) => i === auth.data.user._id) !==
      -1
    ) {
      setBtnText("progress");
      const res2 = await axios.post("/follower/remove", {
        user: auth.data.user._id,
        f_user: quizzithonDetails.scheduled_by._id,
      });
      if (res2) {
        setBtnText("Follow");
      }
      getUserData();
    } else {
      setBtnText("progress");
      const res2 = await axios.post("/follower/add", {
        user: auth.data.user._id,
        f_user: quizzithonDetails.scheduled_by._id,
      });
      if (res2) {
        setBtnText("UnFollow");
      }
      getUserData();
    }
  };

  //follow feature

  return (
    <div>
      
      <Cont className={""}>
        <Left>
          <Bottom>
            <DetailPageTabs quizzithonDetails={quizzithonDetails} />
          </Bottom>
        </Left>
      </Cont>
    </div>
  );
};

const Right = styled.div`
  ${tw`lg:col-span-3 col-span-8 `}
`;
const Left = styled.div`
  ${tw` col-span-8  bg-white rounded-xl`}
`;
const Bottom = styled.div`
  ${tw``}
`;
const Top = styled.div`
  ${tw`grid lg:grid-cols-3 grid-cols-2 gap-4 p-5`}
`;
const Cont = styled.div`
  ${tw` grid grid-cols-8 gap-4 mt-5 `}
`;
export default QuizzithonDetailCard;
