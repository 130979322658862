import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { extrasConstancts } from '../../actions/appConstants';
import QzetosPlans from '../plansComponents/QzetosPlans';
import PlansC from '../plansComponents/PlansC';
import { IconButton } from '@mui/material';
import { ImCross } from 'react-icons/im'
const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
    const dispatch = useDispatch()
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}
            fullWidth={true}
            maxWidth='md'
        >
            <IconButton
                onClick={() => {
                    dispatch({
                        type: extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION,
                        payload: false
                    })
                }}
                className='!absolute !right-0 !top-0'>
                <ImCross size={18} />
            </IconButton>
            <DialogTitle>Quizmaster-Pro Subscriptions</DialogTitle>
            <div className='p-5'>
                <PlansC showBuySchoolModal={true} />
                {/* <QzetosPlans showBuyQzetosModal={true} /> */}
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({
    // open, setOpen
}) {
    const dispatch = useDispatch()
    const { extras } = useSelector(s => s)
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);
    const [open, setOpen] = React.useState(extras.buySchoolQuizScriptionModal)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false)
        dispatch({
            type: extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION,
            payload: false
        })
    };
    React.useEffect(() => {
        if (extras.buySchoolQuizScriptionModal) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [extras.buySchoolQuizScriptionModal])
    // console.log('open values===>', open)
    return (
        <div>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
