import React, { useEffect, useRef } from 'react'
import MyCrossWord from '@jaredreisinger/react-crossword'
import { useDispatch, useSelector } from 'react-redux'
import { lifeLinesConstants } from '../../../actions/appConstants'
const CrossWordGame = ({
    question,
    handleLeaveQuestion,
    handleNextQuestion,
    isLastQuestion
}) => {
    //
    const dispatch = useDispatch()
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines
    //
    const crossWardReff = useRef()

    const handleValidation = () => {
        let correct = crossWardReff.current.isCrosswordCorrect()
        //console.log('final answer==>', correct)

        // if (!(selectedAnswer).trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly write your answer !" } })
        // let correct = false;
        // if (question.correctAnswer === selectedAnswer) {
        //     correct = true
        // }

        handleNextQuestion({ ...question, correct })
        // setselectedAnswer(null)
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...question, correct: false })
    }
    useEffect(() => {
        localStorage.removeItem('guesses')
    }, [])
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //show correct answers
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            crossWardReff.current.fillAllAnswers()
        }
    }, [clickedShowCorrectAnswer])
    //show correct answers
    return (
        <div>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10'>
                <div className=''>
                    <MyCrossWord ref={crossWardReff} data={question.forCrossWord} />
                </div>
                <div className=''>
                    <div>
                        <h1 className='text-center font-semibold'>Across</h1>
                        {
                            Object.entries(question.forCrossWord.across).map(([keys, val], index) => (
                                <div className='space-y-2'>
                                    <h1>{keys}</h1>
                                    <h1><span className='text-bgblue'>Clue:</span> <span>{val.clue}</span></h1>
                                    {
                                        val.url &&
                                            val.url.includes('/image/') ?
                                            <img className='w-[140px] h-[140px] object-contain' src={val.url} />
                                            :
                                            val.url.includes('/video/') ?
                                                <audio src={val.url} controls />
                                                :
                                                null
                                    }
                                </div>
                            ))
                        }
                    </div>
                    <div>
                        <h1 className='text-center font-semibold'>Down</h1>
                        {
                            Object.entries(question.forCrossWord.down).map(([keys, val], index) => (
                                <div className='space-y-2'>
                                    <h1>{keys}</h1>
                                    <h1><span className='text-bgblue'>Clue:</span> <span>{val.clue}</span></h1>
                                    {
                                        val.url &&
                                            val.url.includes('/image/') ?
                                            <img className='w-[140px] h-[140px] object-contain' src={val.url} />
                                            :
                                            val.url.includes('/video/') ?
                                                <audio src={val.url} controls />
                                                :
                                                null
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className=' py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </div>
    )
}

export default CrossWordGame