import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ScheduleQuizzComponent from '../components/ScheduleQuizComponents/ScheduleQuizzComponent'
import ScheduleTable from '../components/ScheduleQuizComponents/ScheduleTable/ScheduleTable'
import Layout from './../components/Layout/Layout'
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom'
import quizImg from '../assets/staticdata/add-question.png'
import TabsForQuizType from '../components/ScheduleQuizComponents/tabsForQuizTypes/TabsForQuizType'
import { keepUserLoggedIn } from '../actions/auth.actions'
const ScheduleQuiz = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showSchedulQuizz, setshowSchedulQuizz] = useState(false)
  const [showUpdateQuiz, setshowUpdateQuiz] = useState(null)
  const { staticTab, auth } = useSelector(s => s)
  useEffect(() => {
    if (showUpdateQuiz) {
      setshowSchedulQuizz(true)
    }
  }, [showUpdateQuiz])
  useEffect(() => {
    dispatch(keepUserLoggedIn())
  }, [])
  return (
    <Layout>
      {staticTab.questionsSubmitted > 10 ? <div className='flex items-center justify-between mb-2 bg-white p-3 rounded-xl'>
        <h1 className='text-bgblue font-bold'>Schedules Quizzes</h1>
        <div className='bg-mydarkgreen flex items-center p-3 rounded-full space-x-2 cursor-pointer' onClick={() => {
          setshowUpdateQuiz(null)
          setshowSchedulQuizz(!showSchedulQuizz)
        }
        }>
          <AddIcon className='bg-white text-mylightgreen rounded-full' />
          <span className='text-white text-xs'>{`${showSchedulQuizz ? 'Show Quizez' : 'Create Quiz'}`}</span>
        </div>
      </div> :
        <div className='w-full flex flex-col items-center'>
          <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
          <div className='w-full flex flex-col items-center'>
            <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
              You dont have any question in your question Bank. Please Add atleast 10 question in your question bank to schedule your 1st quiz.
            </p>
            <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/questionbank')}>Add Questions</button>
          </div>
        </div>}
      {
        showSchedulQuizz
          ?
          <>
            {auth.data.user.quizzes > 0 ?
              <ScheduleQuizzComponent showUpdateQuiz={showUpdateQuiz} />
              :
              <div className='w-full h-screen flex justify-center items-center'>
                <div className='max-w-[400px] text-red-400 text-center'>
                  You have the 0 quizzes left, please buy a Qzeto package to continue.
                </div>
              </div>
            }
          </>
          :
          staticTab.questionsSubmitted > 10 && <TabsForQuizType setshowUpdateQuiz={setshowUpdateQuiz} />
      }
    </Layout>
  )
}

export default ScheduleQuiz 