import axios from '../utils/myAxios'
import { globalConstants, lifeLinesConstants } from './appConstants'
import store from '../store'

export const updateLifeLines = (qid) => {
    return async (dispatch) => {
        const { lifeLines: { lifeLines: { fiftyfifty, showCorrectAnswer, skipQuestion } }, auth: { data: { user } } } = store.getState()
        try {
            const _obj = {
                fiftyfifty: fiftyfifty,
                showCorrectAnswer: showCorrectAnswer,
                skipQuestion: skipQuestion,
            }
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.post(`/update/lifelines/${qid}/${user._id}`, {
                ..._obj
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: false
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}