import React, { useState } from 'react'
const Option = ({ index, value, onChangeHandler, targetCol, onChangeHandlerForBC }) => {
    const [alphabets, setalphabets] = useState(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"])
    return (
        <div className='flex items-center h-10 w-full'>
            <div className={`border h-full
             ${index % 2 === 0 ? 'bg-mylightyellow' : 'bg-mylightgreen'}
              px-4 flex items-center justify-center`}>
                <span>{alphabets[index]}</span>
            </div>
            <input
                value={value}
                disabled={targetCol === 'c' ? true : false}
                // targetCol
                type='text'
                onChange={(e) => {
                    if (targetCol === 'b') {
                        onChangeHandlerForBC(targetCol, index, e.target.value)
                    } else {
                        onChangeHandler(targetCol, index, e.target.value)
                    }
                }
                }
                className='border border-black w-full h-full outline-none border-l-0 border-l-white border-opacity-50 px-2'
            />
        </div>
    )
}
export default Option;