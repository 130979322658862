import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fron_end_url_server, globalConstants } from '../../../actions/appConstants';


//
const website_url = 'http://localhost:3000'
//
export default function BasicMenu() {
    const dispatch = useDispatch()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleCopyLink = () => {
        //console.log('location==>', location)
        navigator.clipboard.writeText(`${fron_end_url_server}${location.pathname}`)
        dispatch({
            type: globalConstants.ALERT,
            payload: { success: "Link copied to clipboad!" }
        })
        handleClose()
    }
    return (
        <div>
            <span
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className='flex items-center space-x-2 cursor-pointer'
            >
                <ShareOutlinedIcon className='text-blue-400' /><span>share</span>
            </span>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleCopyLink}>Copy Link</MenuItem>
            </Menu>
        </div>
    );
}