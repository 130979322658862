import React, { useState, useEffect } from "react";
import { imageUpload } from "../../utils/cloudinary.config";
import axios from "../../utils/myAxios";
import { useDispatch } from "react-redux";
import { globalConstants } from "../../actions/appConstants";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Heading = ({ number, title }) => {
  return (
    <div className="flex items-center space-x-2 mb-5 mt-5">
      <div className="h-7 w-7 bg-bgblue rounded-full flex items-center justify-center">
        <span className="text-white font-bold">{number}</span>
      </div>
      <h1 className="font-semibold capitalize">{title}</h1>
    </div>
  );
};
const allLinks = [
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Question Bank",
    link: "/questionbank",
  },
  {
    name: "Schedule Quiz & Earn",
    link: "/schedulequiz",
  },
  {
    name: "my wallet",
    link: "/mywallet",
  },
  {
    name: "Profile",
    link: "/profile",
  },
  {
    name: "played quizzes",
    link: "/playedquizez",
  },
  {
    name: "Quiz Detail",
    link: "/singlequizz/detailscreen/:qid",
  },
  {
    name: "plans",
    link: "/plans",
  },
  {
    name: "not authorized",
    link: "/notauthorized",
  },
  {
    name: "user scheduled quizzes",
    link: "/userschedulesquizez/:uid",
  },
  {
    name: "payment histories",
    link: "/payments",
  },
  {
    name: "rigistered quizzes",
    link: "/registeredquizzes",
  },
  {
    name: "Quiz Library",
    link: "/quiz-library",
  },
  {
    name: "Ended Quiz",
    link: "/cancelledquizzes",
  },
  {
    name: "Quizmaster-Pro",
    link: "/quizmaster-pro",
  },
  {
    name: "Redemption",
    link: "/redemption",
  },
  {
    name: "Refferals",
    link: "/referral",
  },
  {
    name: "Upload excel sheets",
    link: "/importexcelsheets",
  },
  {
    name: "Manage users",
    link: "/usermanagement",
  },
  {
    name: "Admin schedules quizzes",
    link: "/adminschedulequiz",
  },
  {
    name: "Plateform settings",
    link: "/plateformsettings",
  },
  {
    name: "Categories",
    link: "/static/categories",
  },
  {
    name: "Quizzes",
    link: "/category/quizzes/:category",
  },
  {
    name: "Active Plans",
    link: "/user/subscriptions",
  },
  {
    name: "My Following",
    link: "/following/profile",
  },
  {
    name: "Quiz Masters",
    link: "/quizmasters",
  },
  {
    name: "Subjects",
    link: "/categories/subjects/:cid",
  },
  {
    name: "Redemptions",
    link: "/userredemption",
  },
];
function DashboardBannerUpload() {
  const [banner, setBanner] = useState({ bannerImage: null, path: "" });
  const [banners, setBanners] = useState([]);
  const dispatch = useDispatch();
  const handleFileChange = (e) => {
    setBanner({ ...banner, bannerImage: e.target.files[0] });
  };
  const handleUpload = async () => {
    const updated = await imageUpload([banner.bannerImage]);
    if (updated.length === 0)
      return dispatch({
        type: globalConstants.ALERT,
        payload: { error: "no image uploaded!" },
      });
    axios
      .post("/addDashBoard", { bannerImage: updated[0].url, path: banner.path })
      .then((res) => {
        console.log(res);
        setBanner({ bannerImage: null, path: "" });
        getBanners();
        dispatch({
          type: globalConstants.ALERT,
          payload: { success: "Banner Uploaded!" },
        });
      })
      .catch((err) => console.log(err.response));
  };
  const getBanners = async () => {
    axios.get("/getDashBoard").then((res) => {
      console.log("This is the response", res);

      setBanners(res.data.banners);
    });
  };
  useEffect(() => {
    getBanners();
  }, []);
  const handleDelete = async (id) => {
    axios.delete(`/deleteDashBoard/${id}`).then((res) => {
      console.log(res);
      getBanners();
      dispatch({
        type: globalConstants.ALERT,
        payload: { success: "Banner deleted successfully" },
      });
    });
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="w-full flex flex-col">
          <Heading number={1} title="Upload Banner" />
          <>
            <div className="w-full">
              <label htmlFor="">Banner Path</label>
              <select
                name=""
                id=""
                className="w-full h-10 my-2 rounded-lg border"
                value={banner.path}
                onChange={(e) => setBanner({ ...banner, path: e.target.value })}
              >
                <option value="">Please choose a path</option>
                {allLinks.map((val, i) => (
                  <option value={val.name} key={i}>
                    {val.name}
                  </option>
                ))}
              </select>
            </div>
            {banner.bannerImage ? (
              <div className="w-64 relative my-5 border border-slate-300 rounded-lg">
                <svg
                  onClick={() => setBanner({ ...banner, bannerImage: null })}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-red-600 absolute top-[-10px] right-[-10px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <img
                  src={URL.createObjectURL(banner?.bannerImage)}
                  alt=""
                  className="w-full"
                />
              </div>
            ) : (
              <input
                type="file"
                accept="image/*"
                className="text-sm text-grey-500 my-3 file:mr-5 file:py-2 file:px-5 file:rounded-full file:border-0 file:text-md file:text-white file:bg-green-500 file:outline-none hover:file:cursor-pointer hover:file:opacity-80 text-transparent"
                onChange={handleFileChange}
              />
            )}
          </>
        </div>
        {banner && (
          <button
            className="w-32 text-sm text-grey-500 my-3 mr-5 py-2 px-5 rounded-full text-white bg-green-500 border border-green-400 hover:text-green-400 hover:bg-white outline-none hover:cursor-pointer"
            onClick={handleUpload}
          >
            Upload
          </button>
        )}
      </div>
      <div className="w-full flex flex-col">
        <Heading number={2} title="Banners" />
        <div className="w-full flex flex-col">
          {banners?.length > 0 &&
            banners?.map((val, i) => (
              <div className="w-full flex items-center md:px-10" key={i}>
                <div className="w-64 relative my-5 border border-slate-300 rounded-lg">
                  <img src={val.bannerImage} className="w-full" alt="" />
                </div>
                <DeleteForeverIcon
                  sx={{ color: "red" }}
                  onClick={() => handleDelete(val._id)}
                ></DeleteForeverIcon>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardBannerUpload;
