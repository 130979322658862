import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import { CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { homeModalConstants } from '../actions/extras.actions'
const DistributePastQuizzPrizzes = () => {
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    const navigate = useNavigate()
    const { qid } = useParams()

    useEffect(async () => {
        if (qid) {
            try {
                const _obj = {
                    user: auth.data.user._id
                }
                const res = await axios.post(`/distibute/prizzes/to/pastquizzplayer/${qid}`, {
                    ..._obj
                })
                if (res.data.earnedMoney) {
                    dispatch({
                        type: homeModalConstants.SHOW_HOME_MODAL,
                        payload: {
                            show: true,
                            earning: res.data.earnedMoney,
                            earningtype: res.data.earningtype
                        }
                    })
                }
                navigate(`/singlequizz/detailscreen/${qid}`, { state: { isPastQuiz: true } })
            } catch (error) {

            }
        }
    }, [qid])
    return (
        <Layout>
            <div className='flex items-center justify-center mt-5'>
                <CircularProgress />
            </div>
        </Layout>
    )
}

export default DistributePastQuizzPrizzes