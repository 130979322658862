import React, { useEffect, useState } from 'react'
import MyDropAbles from './MyDropAbles'
import { getAnswers, getSentence } from './textConverters'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDispatch, useSelector } from 'react-redux'
import { lifeLinesConstants } from '../../../actions/appConstants'

const sentence = `my <name> is <salleh>`

const DragDropFillUpTheBlanks = ({
    question,
    handleLeaveQuestion,
    handleNextQuestion,
    isLastQuestion
}) => {
    const dispatch = useDispatch()
    //
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines
    //

    const [mapAbleSentence, setmapAbleSentence] = useState([])
    const [dragableFields, setdragableFields] = useState([])
    const [sentence, setsentence] = useState(null)
    useEffect(() => {
        if (question && sentence) {
            const mappedSentence = getSentence(sentence)
            //console.log('mapped data==>', mappedSentence)
            setmapAbleSentence(mappedSentence)
            const _get_draggables = getAnswers(sentence).map(v => ({ text: v, id: uuid() }))
            setdragableFields(_get_draggables)
            //console.log('_get_draggables', _get_draggables)
        }
    }, [question, sentence])
    const hanldDragEnd = (e) => {
        const { source, destination } = e
        //console.log('e====>', e)
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        if (destination.droppableId === 'draggeableitems') return;
        const copy_mapAbleSentence = [...mapAbleSentence]
        const copy_dragablefields = [...dragableFields]
        const draggedValue = copy_dragablefields[source.index]
        const newValue = copy_mapAbleSentence.map((v, index) => {
            if (v.id === Number(destination.droppableId)) {
                return { ...v, displayed: draggedValue.text }
            }
            return v;
        })
        setmapAbleSentence(newValue)
    }
    const handleValidation = () => {
        let correct = true;
        mapAbleSentence.forEach((val) => {
            if (val.type == 'answer') {
                if (val.text !== val.displayed) {
                    correct = false
                }
            }
        })
        handleNextQuestion({ ...{ ...question, userSubmission: mapAbleSentence }, correct })
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: mapAbleSentence }, correct: false })
    }
    useEffect(() => {
        if (question.forDragDropFillUpBlanks) {
            setsentence(question.forDragDropFillUpBlanks)
        }
    }, [question.forDragDropFillUpBlanks])
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...
    //shwow correct answer handler
    useEffect(() => {
        try {
            if (clickedShowCorrectAnswer) {
                let updateMapAble = [...mapAbleSentence]
                updateMapAble = updateMapAble.map(val => {
                    if (val.type === 'answer') {
                        return {
                            ...val,
                            displayed: val.text
                        }
                    } else {
                        return val
                    }
                })
                setmapAbleSentence(updateMapAble)
                //console.log('final upate updateMapAble==>', updateMapAble)
            }
        } catch (error) {
            //console.error('error==>', error)
        }

    }, [clickedShowCorrectAnswer])
    //shwow correct answer handler
    return (
        <>
            <DragDropContext onDragEnd={hanldDragEnd}>
                <div>
                    <div className='flex items-center gap-4 flex-wrap shadow  text-md bg-white p-4 rounded-md lg:w-[70%] m-auto'>
                        {
                            mapAbleSentence.length > 0 &&
                            mapAbleSentence.map((val, index) => {
                                if (val.type === 'answer') {
                                    return <MyDropAbles val={val} />
                                }
                                return <span>{val.text}</span>
                            })
                        }
                    </div>
                    {/* draggable fields! */}
                    <Droppable droppableId='draggeableitems' direction='horizontal'>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                //   style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                {...provided.droppableProps}
                                className='flex items-center justify-center flex-wrap gap-4 mt-10 '
                            >
                                {
                                    dragableFields.map((val, index) => (
                                        <Draggable key={val.id} draggableId={val.id} index={index}>
                                            {(provided) => (
                                                <span
                                                    className='bg-white text-gray-500  border border-gray-500 !px-2 p-1 rounded'
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <DragIndicatorIcon fontSize='small' />
                                                    {val.text}
                                                </span>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </>
    )
}

export default DragDropFillUpTheBlanks