import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QuizzesWithDifferentSubjectsPage from '../../../screens/QuizzesWithDifferentSubjectsPage';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
   
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function BasicTabs(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="fullWidth"
                    value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab className='!p-0' label="Free" {...a11yProps(0)} />
                    <Tab className='!p-0' label="Paid" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel className='!p-0' value={value} index={0}>
                <QuizzesWithDifferentSubjectsPage quizzType={`free`} />
            </TabPanel >
            <TabPanel className='!p-0' value={value} index={1}>
                <QuizzesWithDifferentSubjectsPage quizzType={`paid`} />
            </TabPanel>
        </Box>
    );
}
