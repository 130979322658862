import React, {useEffect, useState} from 'react'
import FAQDropDown from './FAQDropDown'
import FAQModal from './FAQModal'
import FAQTypesModal from './FAQTypesModal'
import axios from '../../utils/myAxios'
import FaqQuestions from './FaqQuestions'
import FAQTypes from './FAQTypes'
const Heading = ({ number, title }) => {
    return (
        <div className='flex items-center space-x-2 mb-5 mt-5'>
            <div className='h-7 w-7 bg-bgblue rounded-full flex items-center justify-center'>
                <span className='text-white font-bold'>{number}</span>
            </div>
            <h1 className='font-semibold capitalize'>{title}</h1>
        </div>
    )
} 

function Faqs() {
    const [open, setOpen] = useState(false)
    const [openType, setType] = useState(false)
    const [types, setTypes] = useState([])
    const [faqs, setFaqs] = useState()
    useEffect(() => {
        axios.get('/getFaqs').then(res => {
            console.log(res.data)
            setFaqs(res.data.faqs)
        })
        axios.get('/getTypes').then(res => {
            setTypes(res.data.types)
        })

    }, [open, openType])

  return (
    <div className='relative w-full flex flex-col'>
        
        <div className='flex justify-end p-5 gap-2'>
            <button 
            className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white'
            onClick = {() => {
                setOpen(!open)
                setType(false)
            }}
            >
                Add Faq
            </button>
            <button 
            className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white'
            onClick = {() => {
                setOpen(false)
                setType(!openType)}}
            >
                Add Type
            </button>

        </div>
        {open && <FAQModal setOpen={setOpen} />}
        {openType && <FAQTypesModal setTypeOpen={setType}/>}
        <Heading number = {1} title = 'FAQs' />
        <FaqQuestions questions = {faqs} setFaqs = {setFaqs} />
        <Heading number = {2} title = 'FAQ Types' />
        <FAQTypes setTypes = {setTypes} types = {types} />
    </div>
  )
}

export default Faqs