import React, { useEffect, useState } from 'react'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNavigate } from 'react-router-dom';
const SubscriptionCard = ({ val }) => {
    const navigate = useNavigate()
    const [active, setactive] = useState(false)
    const [expirydate, setexpirydate] = useState(0)
    // console.log(val)
    useEffect(() => {
        if (val) {
            const date2 = new Date(val.expiry_date)
            const date1 = new Date()
            if (date2 < date1) { setactive(false) } else { setactive(true) }

            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setexpirydate(diffDays)
        }
    }, [val])
    return (
        <div className={`border-2 ${active ? 'border-blue-500 text-black' : 'bg-bgblue text-white'}  p-5  rounded-md relative`}>
            {
                active &&
                <CheckCircleOutlineOutlinedIcon className='!absolute !-top-0 !-right-0 !text-blue-500' />
            }

            <div className='flex items-center justify-between'>
                <h1 className='text-lg font-bold'>{val.subscription.title}</h1>
                <h1 className='text-lg tracking-widest font-bold'><span className='text-xs'>₹</span>{val.subscription.price}<span className='opacity-50'>/month</span></h1>
            </div>

            <div className='flex items-center justify-between mt-2'>
                <h1>Number of quizzes left</h1>
                <h1>
                    {
                        active ?
                            val.subscription.numberOfQuiz
                            :
                            0
                    }
                </h1>
            </div>
            <div className='mt-5 flex items-center space-x-3'>
                {
                    active ?

                        <>
                            <span className='bg-white py-2 px-4 rounded-md text-bgblue font-bold' >Activated</span>
                            <h1 className='text-xs'>This plan is activated</h1>
                        </>
                        :
                        <>
                            <button onClick={() => navigate('/plans')} className='bg-white py-2 px-4 rounded-md text-bgblue'>Upgrade</button>
                            <h1 className='text-xs text-red-500'>Upgrade your expired plan</h1>
                        </>
                }
            </div>
            <h1 className='text-sm  mt-2 text-red-400'>
                {active ?
                    `${expirydate} days are left to expire`
                    :
                    `expired ${expirydate} days ago`}
            </h1>
        </div>
    )
}

export default SubscriptionCard