import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import tw from 'twin.macro';
import { globalConstants } from '../../../actions/appConstants';
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
const FillInTheBlanks = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const dispatch = useDispatch()
    const { auth, question } = useSelector(state => state)
    const initial_state = {
        statement: '',
        correctAnswer: '',
    }
    const [fillInTheBlanksData, setfillInTheBlanksData] = useState(initial_state)
    const { statement, correctAnswer } = fillInTheBlanksData;
    const handleSubmit = async (arg) => {
        let obj = {
            questionCategory: type,
            subject,
            statement,
            correctAnswer
        }
        if (!obj.questionCategory) {
            return dispatch({

            })
        }
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: {
                    error: "kindly fill the question type!"
                }
            })
        }
        if (!(obj.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question statement!" }
            })
        }
        if (!(obj.correctAnswer).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly fill correct answer" }
        })
        if (!(obj.subject).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly select the subject!" }
        })
        const _idFind = question.questionsCategories.find((v) => v.name === type)

        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id
        }
        //console.log('fill in the blanks===>', obj)
        //check if it is a reapproval request or not
        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        //check if it is a reapproval request or not
        if (arg) {
            setfillInTheBlanksData(initial_state)
        } else {
            setfillInTheBlanksData(initial_state)
            setshowAddQuestions(false)
        }
    }
    useEffect(() => {
        if (showQuestinData) {
            setfillInTheBlanksData(showQuestinData)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    const handleReApprovalReQuest = async () => {
        try {
            //console.log('reapproval request', showQuestinData)
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    return (
        <Container>
            <div className='space-y-2'>
                <h1 className='text-bgblue font-bold'>Question</h1>
                <input
                    className='border border-black py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    onChange={(e) => setfillInTheBlanksData({ ...fillInTheBlanksData, statement: e.target.value })}
                    placeholder='write question statement...'
                />
            </div>
            <div className='space-y-2 mt-5'>
                <h1 className='text-bgblue font-bold'>Correct Answer</h1>
                <input
                    className='border border-black py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={correctAnswer}
                    onChange={(e) => setfillInTheBlanksData({ ...fillInTheBlanksData, correctAnswer: e.target.value })}
                    placeholder='write question statement...'
                />
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <BottomButtons>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </BottomButtons>
            }
            {/* {
                canEdit &&
                <BottomButtons>
                    <button onClick={handleReApprovalReQuest} className='bg-bgblue text-white p-2 text-xs rounded-2xl capitalize'>reapproval request</button>
                </BottomButtons>
            } */}
        </Container>
    )
}
const Container = styled.div`
${tw`mt-10`}
`
const BottomButtons = styled.div`
${tw`flex items-center justify-end mt-16 space-x-2`}
`
export default FillInTheBlanks