import React from 'react'
import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from '../utils/myAxios'
import UserCard from './UserCard'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../actions/appConstants'

function SearchUser() {
  const [user, setUser] = useState([])
  const [userName, setUserName] = useState('')
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    setUserName(e.target.value)

  }
  const handleSearch = () => {
    if (userName.length >= 1) {
      setLoader(true)

      axios.post('/getsingleuser', { userName })
        .then((body) => {
          setUser(body.data.users)
          // console.log(body.data)
          setLoader(false)
        })
        .catch(err => {
          // console.log(err)
          setLoader(false)
        })
      setUserName('')
    }
    else{
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: "Please enter a username" }
    })
    }

  }
  useEffect(() => {
    const keyDownHandler = event => {
      // console.log('User pressed: ', event.key);

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ call submit function here
        handleSearch();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (user?.length === 0) {
      axios.get('/getuser')
        .then(res => {

          setUser(res.data.user)
        })
        .catch(err => console.log(err))
    }

  }, [])
  return (
    <>
      {loader ? <div className='w-full h-screen flex items-center justify-center'><CircularProgress /></div> : <div className='w-full'>
        <div>
          <div className="w-full flex justify-center mb-5 gap-4">
            <input className='w-[60%] h-8 rounded-full border-none' type="text" placeholder='Enter the User' onChange={(e) => { handleChange(e) }} />
            <button className='w-24 h-8 rounded-full bg-yellow-500 text-white ' onClick={() => handleSearch()} >Search</button>
          </div>
          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {

              user?.length > 0 && user.map((item, index) => {
                return <UserCard key={index} item={item} />
              })
            }
          </div>
        </div>
      </div>}
    </>

  )
}

export default SearchUser