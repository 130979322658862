import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import { makeStyles } from '@material-ui/core'
import { IconButton, Typography, DialogActions, styled, Button } from '@mui/material';
// import Control from './Controds/Control';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { extrasConstancts } from '../../../actions/appConstants';
import { Link } from 'react-router-dom';

// const useStyles = makeStyles(theme=>({
//     dialogWrapper:{
//         padding: theme.spacing(2),
//         position:"absolute",
//         top: theme.spacing(0)
//     }

// }))

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  color: 'white',
  backgroundColor: '#F89820',
  borderColor: '#F89820',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
  },
  '&:focus': {

  },
});


const MyPopUp = (props) => {
  // const classes = useStyles();

  const { title, children, openPopUp, setOpenPopUp } = props
  const { auth } = useSelector(s => s)
  const dispatch = useDispatch()

  return (
    <>
      <Dialog open={openPopUp} maxWidth="xs"  >
        <DialogTitle>
          <div style={{ display: "flex" }}>
            <Typography sx={{ flexGrow: 1 }} variant="h6" >{title}</Typography>
            <IconButton color="secondary"
              onClick={() => { setOpenPopUp(false) }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>


        <DialogContent dividers >
          {children}
        </DialogContent>
        <DialogActions>
          {
            <BootstrapButton
              onClick={() => dispatch({
                type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                payload: true
              })}
              color="inherit"
            >
              GET STARTED
            </BootstrapButton>
          }
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MyPopUp