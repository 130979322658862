import React, { useEffect } from 'react'
import QuizzithionQuiz from './QuizzithonQuiz'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { quizzithonActions , scheduleQuizzithon } from '../../actions/quizzithon.actions'
import {useNavigate} from 'react-router-dom'


function ScheduleQuizzithonComponment() {
    const {quizzithon : {quizzithon} , auth} = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    console.log(auth , quizzithon)
    useEffect(() => {
        dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : {target : {name : 'scheduled_by' , value : auth?.data?.user?._id }}})
    }, [auth])
  return (
    <div className='w-full border rounded-lg bg-white p-4 text-lg'>
        <div w-full>
        <h1 className='text-bgblue text-lg font-semibold'>Schedule Quizzithon</h1>
            <div className='w-full pt-4'>
                <div className='w-full'>
                    <label htmlFor="" className='block text-lg my-5'>Quizzithon title</label>
                    <input className='w-full rounded-md' type="text" placeholder='Enter the quizzithon title' name = "title" value = {quizzithon.title} onChange = {(e)=>dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : e})}/>
                </div>
            </div>
            <div className='w-full grid grid-cols-2 gap-4'>
                <div className=''>
                    <label htmlFor="" className='block my-5'>Quizzithon type</label>
                    <select className='rounded-md w-full' name="type" onChange = {(e)=>dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : e})} value = {quizzithon.type}>
                        <option disabled value="">Select an option</option>
                        <option value="free">Free</option>
                        <option value="paid">Paid</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="" className='block my-5'>Registeration Fees</label>
                    <input className='w-full rounded-md' type="number" min = {0} placeholder='Reg. Fee' name = "reg_fee" value={quizzithon.reg_fee} onChange = {(e)=>{dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : e})}}/>
                </div>
            </div>
            <div className='w-full grid grid-cols-2 gap-4'>
                <div>
                    <label htmlFor="" className='block my-5'>No of Quizzes</label>
                    <input className='w-full rounded-md' type="number" min = {0} placeholder='No of quizzes' name ="no_quizzes" value = {quizzithon.no_quizzes} onChange = {(e)=>{dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : e})}} />
                </div>
                <div>
                    <label htmlFor="" className='block my-5'>Min Participants</label>
                    <input className='w-full rounded-md' type="number" min = {0} placeholder='Min Participants' name = "min_participants" value={quizzithon.min_participants} onChange = {(e)=>{dispatch({type : quizzithonActions.UPDATE_QUZZITHON , payload : e})}} />
                </div>
            </div>
            
        </div>
        {quizzithon.quizzes.length > 0 && <div className="w-full pt-5">
            {
                quizzithon.quizzes.map((quiz , index) => {
                    console.log(index)
                    return <QuizzithionQuiz index = {index}/>
                })
            }
            
        </div>}
        <div className='w-full flex justify-center my-7'>
            <button className='h-10 border border-green-400 text-white bg-green-400 text-base rounded-full hover:text-green-400 hover:bg-white px-3 ' onClick = {()=>dispatch(scheduleQuizzithon(navigate))}>Schedule Quizzithon</button>
        </div>
        
    </div>
  )
}

export default ScheduleQuizzithonComponment