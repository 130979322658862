import React from 'react'
import quiz5 from '../../assets/staticdata/quiz5.png'
import quiz6 from '../../assets/staticdata/quiz6.png'
import quiz4 from '../../assets/staticdata/quiz4.png'
function Steps() {
    return (
        <div className='w-full bg-[#223344] text-white flex flex-col items-center pb-10'>
            <h1 className='text-[32px] text-center p-5'>3 Easy Steps</h1>
            <div className=' text-[16px]  w-[80%] pb-5 text-center'>Quizzingbox.com is not just about taking quizzes, it is about challenging yourself, gathering knowledge every time and get rewarded for this knowledge. Every right hit let you win rewards. So the game never stops, Excitements never end, & Reward never stop coming. You are just few clicks away from taking all those entertaining quizzes and rewarding in big time.
            </div>
            <div className="max-w-7xl mx-auto w-full grid grid-cols-9 px-2">

                <div className="col-span-4 w-full h-full ">
                    <div className="w-full h-full flex justify-end p-2 md:pl-4">
                        <div className='flex flex-col items-center'>
                            <img className='w-[195px]' src={quiz5} alt="" />
                            <span className='text-[20px]  text-yellow-500 text-center'>Create Quiz & Earn</span>
                        </div>

                    </div>
                </div>
                <div className="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div className="h-full w-1 border-r border-dashed"></div>
                    <div className="absolute w-8 h-8 rounded-full bg-white z-10 text-[#223344] text-center text-[18px]">1</div>
                </div>
                <div className="col-span-4 w-full h-full "></div>



                <div className="col-span-4 w-full h-full"></div>
                <div className="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div className="h-full w-1 border-r border-dashed"></div>
                    <div className="absolute w-8 h-8 rounded-full bg-white z-10 text-[#223344] text-center text-[18px]">2</div>
                </div>
                <div className="col-span-4 w-full h-full ">
                    <div className="w-full h-full flex justify-start p-2 md:pl-4">
                        <div className='flex flex-col items-center'>
                            <img className='w-[195px]' src={quiz6} alt="" />
                            <span className='text-[20px]  text-yellow-500 text-center'>Play Quiz & Earn</span>
                        </div>
                    </div>
                </div>


                <div className="col-span-4 w-full h-full ">
                    <div className="w-full h-full flex justify-end p-2 md:pl-4">
                        <div className='flex flex-col items-center'>
                            <img className='w-[195px]' src={quiz4} alt="" />
                            <span className='text-[20px]  text-yellow-500 text-center'>Get Rewarded</span>
                        </div>
                    </div>
                </div>
                <div className="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div className="h-full w-1 border-r border-dashed"></div>
                    <div className="absolute w-8 h-8 rounded-full bg-white z-10 text-[#223344] text-center text-[18px]">3</div>
                </div>
                <div className="col-span-4 w-full h-full"></div>
            </div>

        </div>
    )
}

export default Steps