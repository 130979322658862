import React from 'react'
import PlayedQuizzesC from '../PlayedQuizzesC'
const PaidTab = ({
  user
}) => {
  return (
    <>
      <PlayedQuizzesC
        type='paid'
        user={user}
      />
    </>
  )
}

export default PaidTab