import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const CollapsAbleList = ({ val }) => {
    
    const location = useLocation();
    
    const [collapseHanlde, setcollapseHanlde] = useState(false)
    return (
        <>
            <ListItemButton
                className={`${location.pathname == val.path ? "bg-bgblue text-white font-semibold" : "text-bgblue"} flex items-center space-x-2 p-2 rounded-md mb-2 hover:bg-bgblue hover:text-white hover:font-semibold cursor-pointer`}
                onClick={() => setcollapseHanlde(!collapseHanlde)}
            >
                
                    {val.icon}
                
                <ListItemText primary={val.title} sx { ...{fonstSize : "14px"}} />
                {collapseHanlde ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={collapseHanlde} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className='!ml-5'>
                    {/* <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon>
                        <ListItemText primary="Starred" />
                    </ListItemButton> */}
                    {
                        val.items.map((_v, _i) => (
                            <Link
                                to={_v.path}
                                style={{ textDecoration: "none", color: "white" }}
                                
                            >
                                <ListItem
                                    className={`${location.pathname === _v.path ? "bg-bgblue text-white font-semibold" : "text-bgblue"} flex items-center space-x-2 rounded-md hover:bg-bgblue hover:text-white mb-2 hover:font-semibold cursor-pointer`}
                                    // sx={{
                                    //     borderRadius: "7px",
                                    //     marginTop: "10px",
                                    //     ":hover": {
                                    //         background: "white",
                                    //         color: "#00459E",
                                    //         borderRadius: "7px",
                                    //     },
                                    // }}
                                    // button
                                    key={_i}
                                >
                                    
                                        {_v.icon}
                                    
                                    <ListItemText className="" primary={_v.title} />
                                </ListItem>
                            </Link>
                        ))
                    }
                </List>
            </Collapse>
        </>
    )
}

export default CollapsAbleList