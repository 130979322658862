import React from 'react'
import Layout from '../components/Layout/Layout'
import ArchiveQuizzesC from '../components/archiveQuizzesC/ArchiveQuizzesC'
const ArchiveQuizzesTab = () => {
    return (
        <Layout>
            <ArchiveQuizzesC />
        </Layout>
    )
}

export default ArchiveQuizzesTab