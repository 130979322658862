import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Vector from '../../../assets/staticdata/Vector.png';
import Box from '@mui/material/Box';
import { Grid, styled } from '@mui/material';
import FeatcherImgC from "../../../assets/staticdata/featureImg.jpg"

const MoreButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '10px 64px',
  border: '1px solid',
  borderRadius: "25px",
  letterSpacing: "2px",
  textTransform: "uppercase",
  lineHeight: 1.5,
  color: 'white',
  backgroundColor: '#82B1FF',
  borderColor: '#82B1FF',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
  },
  '&:focus': {

  },
});

const FeaturesCard = ({ title, imgAvatar }) => {
  return (
    <Box className='rounded-xl bg-white shadow-xl border space-y-5 p-3 py-7'>
      <Box textAlign="center" >
        <img className='!m-auto w-36 object-contain' src={imgAvatar} alt="" />
      </Box>
      <Typography variant="h5" textAlign="center" sx={{ fontSize: "18px", color: "#82B1FF", fontWeight: "bold" }}>{title}</Typography>
      <Typography className='opacity-70' variant="subtitle1" textAlign="center" sx={{ color: "", fontSize: '14px' }}>Many desktop publishing packages and web uncover many web sites still in their infancy.</Typography>
    </Box>
  )
}

export default FeaturesCard