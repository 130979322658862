import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useDispatch } from 'react-redux';
import { globalConstants } from '../../../actions/appConstants';
const MyForm = ({ handleAdd }) => {
    const [type, settype] = useState('')
    const [currectItem, setcurrectItem] = useState({
        name: '',
        file: ''
    })
    const dispatch = useDispatch()
    const handleOnCangeFile = (e) => {
        const file = e.target.files[0]
        if (!file) return;
        if (file['type'].split('/')[0] !== 'image') return dispatch({ type: globalConstants.ALERT, payload: { error: "only images are allowed!" } })
        setcurrectItem({ ...currectItem, file: file })
        e.target.value = ''
    }
    const submit = () => {
        if (!currectItem.name.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "fill the name field!" } })
        if (!currectItem.file) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly select the file!" } })
        if (!type.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly select the type!" } })
        handleAdd(currectItem, type, setcurrectItem)
    }
    return (
        <div className='lg:w-[40%] w-full m-auto  border border-gray-300 rounded-md p-3 shadow-md space-y-4 mt-5 mb-5'>
            <select value={type} onChange={e => settype(e.target.value)} className='border rounded-md px-2 border-gray-400 w-full p-1 outline-none'>
                <option value={''}>Select type</option>
                <option value={'correct'}>For Correct</option>
                <option value={'incorrect'}>For Incorrect</option>
            </select>

            <input type={'text'}  onChange={(e) => setcurrectItem({ ...currectItem, name: e.target.value })} value={currectItem.name} placeholder='Enter the name...' className='border px-2 border-gray-400 rounded-md w-full p-1 outline-none' />

            <div className='flex items-center space-x-2'>
                <IconButton className='!relative !cursor-pointer'>
                    <CloudUploadOutlinedIcon className='!text-bgblue' />
                    <input onChange={handleOnCangeFile} className='absolute top-0 left-0 right-0 bottom-0 z-20 opacity-0 cursor-pointer' type={'file'} />
                </IconButton>
                <span className='text-xs'>Upload file</span>
            </div>
            {
                currectItem?.file &&
                <div className='flex items-center justify-center'>
                    <img src={URL.createObjectURL(currectItem?.file)} className='w-[200px] h-[200px] object-contain' />
                </div>
            }
            <button type='button' onClick={submit} className='bg-bgblue text-white py-1 text-center w-full'>Add</button>
        </div>
    )
}

export default MyForm