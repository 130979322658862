import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AboutPage from "../AboutPage";
import Link from "@mui/material/Link";
import { useLocation, useNavigate } from "react-router-dom";
import Notifications from "../../screens/Notifications";
function handleClick(event) {
  event.preventDefault();
}
const allLinks = [
  {
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    name: "Question Bank",
    link: "/questionbank",
  },
  {
    name: "Schedule Quiz & Earn",
    link: "/schedulequiz",
  },
  {
    name: "my wallet",
    link: "/mywallet",
  },
  {
    name: "Profile",
    link: "/profile",
  },
  {
    name: "played quizzes",
    link: "/playedquizez",
  },
  {
    name: "Quiz Detail",
    link: "/singlequizz/detailscreen/:qid",
  },
  {
    name: "plans",
    link: "/plans",
  },
  {
    name: "not authorized",
    link: "/notauthorized",
  },
  {
    name: "user scheduled quizzes",
    link: "/userschedulesquizez/:uid",
  },
  {
    name: "payment histories",
    link: "/payments",
  },
  {
    name: "rigistered quizzes",
    link: "/registeredquizzes",
  },
  {
    name: "Quiz Library",
    link: "/quiz-library",
  },
  {
    name: "Quiz Masters",
    link: "/quizmasters",
  },
  {
    name: "Ended Quiz",
    link: "/cancelledquizzes",
  },
  {
    name: "Quizmaster-Pro",
    link: "/quizmaster-pro",
  },
  {
    name: "Redemption",
    link: "/redemption",
  },
  {
    name: "Refferals",
    link: "/referral",
  },
  {
    name: "Upload excel sheets",
    link: "/importexcelsheets",
  },
  {
    name: "Manage users",
    link: "/usermanagement",
  },
  {
    name: "Admin schedules quizzes",
    link: "/adminschedulequiz",
  },
  {
    name: "Plateform settings",
    link: "/plateformsettings",
  },
  {
    name: "Categories",
    link: "/static/categories",
  },
  {
    name: "Quizzes",
    link: "/category/quizzes/:category",
  },
  {
    name: "Past Quizzes",
    link: "/past/quizzes/categories/subjects:category",
  },
  {
    name: "Active Plans",
    link: "/user/subscriptions",
  },
  {
    name: "Followers",
    link: "/my-following/followers",
  },
  {
    name: "Following",
    link: "/my-following/following",
  },
  {
    name: "Profile",
    link: "/following/profile/:id",
  },
  {
    name: "Subjects",
    link: "/categories/subjects/:cid",
  },
  {
    name: "Redemptions",
    link: "/userredemption",
  },
];

export default function BasicBreadcrumbs() {
  const navigate = useNavigate();
  const [path, setPath] = React.useState("");
  const [pathArray, setpathArray] = React.useState([]);
  const location = useLocation();

  React.useEffect(() => {
    const pathname = location.pathname.split("/").length;

    let found;
    if (location.pathname.split("/").length > 2) {
      found = allLinks.find(
        (s) =>
          s.link.slice(0, location.pathname.lastIndexOf("/")) ===
          location.pathname.slice(0, location.pathname.lastIndexOf("/"))
      );
    } else {
      found = allLinks.find((s) => s.link === location.pathname);
    }

    if (found) {
      setPath(found.name);
      if (found?.arr?.length > 0) {
        setpathArray(found.arr);
      } else {
        setpathArray([]);
      }
    }
  }, [location.pathname]);
  // console.log("this is the path name" , pathArray)
  return (
    <div className="w-full flex flex-col">
      <div
        className="w-full justify-between flex"
        role="presentation"
        onClick={handleClick}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            onClick={() => navigate("/")}
            underline="hover"
            color="inherit"
            href="/"
          >
            Home
          </Link>
          <Link
            underline="hover"
            color="inherit"
            className="!capitalize cursor-pointer"
          >
            {path}
          </Link>
          {pathArray.map((val, index) => (
            <Link
              key={index}
              onClick={() => navigate(val.link)}
              underline="hover"
              color="inherit"
              className="!capitalize cursor-pointer"
            >
              {val.name}
            </Link>
          ))}
        </Breadcrumbs>
        <AboutPage path={path} />
      </div>
      <div className="w-full">
        <Notifications path={path} />
      </div>
    </div>
  );
}
