import React, { useState } from 'react'
import Layout from '../components/Layout/Layout'
import RefferalTable from '../components/refferalComponents/RefferalTable'
import RefferalCodeModal from '../components/profileComponents/RefferalCodeModal'
import { useSelector, useDispatch } from 'react-redux'
import { globalConstants } from '../actions/appConstants'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const Referral = () => {
    const [text, setText] = useState('Copy Code')
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()

    return (
        <Layout>
            {/* show reffaral code to copy */}
            {/* <RefferalCodeModal
                open={open}
                setOpen={setOpen}
                code={auth?.data?.user?.referalCode}
            /> */}
            <div className="w-full flex justify-center items-center">
                <div className='w-4/5 flex items-center justify-between bg-yellow-50 my-10 rounded-full'>
                    <span className='pl-10 text-mylightyellow '>Click Copy Code to copy your referral code</span>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(`${auth.data.user.referalCode}`)
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { success: "Referral Code is copied!" }
                            })
                        }}
                        onMouseEnter={() => {text === 'Copy Code' ? setText(auth.data.user.referalCode) : setText('Copy Code')}}
                        onMouseLeave={() => {text === 'Copy Code' ? setText(auth.data.user.referalCode) : setText('Copy Code')}}
                        className={`w-64 hover:animate-none capitalize bg-mylightyellow text-white py-2 text-center rounded-full px-5 `}
                    ><ContentCopyIcon/> {text}</button>
                </div>
            </div>
            
            {/* show reffaral code to copy */}
            <RefferalTable />
        </Layout>
    )
}

export default Referral