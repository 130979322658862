import React, { useState } from 'react'
import moment from 'moment'
// import TableModal from './TableModal'
const TableRow = ({ val, questions, setquestions }) => {
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    return (
        <>
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{new Date(val.createdAt).toLocaleDateString()}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>₹{val.amount ?val?.amount :0}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.numberOfQzetos ?val?.numberOfQzetos :0}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.quizzes ?val?.quizzes :0}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.quizzithon ?val?.quizzithon :0}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.ai_questions ?val?.ai_questions :0}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.quizmaster_pro ?val?.quizmaster_pro :0}</span>
                </td>
            </tr>
        </>
    )
}

export default TableRow