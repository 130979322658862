import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import { TrainRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
const PaymentHistoryTable = ({ user }) => {
    console.log('user===>', user)
    const { auth } = useSelector(s => s)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(TrainRounded)
                const res = await axios.get(`/get/boughtqzeto/history/${user}?page=${page}&limit=${limit}`)
                
                if (res.status === 200) {
                    setquestions(res.data.histories)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setloading(false)
            } catch (error) {
                setloading(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit])
    return (
        <div>
            {
                loading ?
                    <div className='flex items-center justify-center w-full'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?
                        <>
                            <MyTable questions={questions} setquestions={setquestions} />
                            <div className='flex justify-center px-5 pt-5'>
                                <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                            </div>
                        </>
                        :
                        <NotFoundDataUi
                            text={`No payment history available`}
                        />
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No payment history available...</div>
            }

        </div>
    )
}

export default PaymentHistoryTable