import React from 'react'
import { alphabetsConstants } from '../../../actions/appConstants'
const StaticRows = ({ column }) => {
    return (
        <div className='space-y-3'>
            {
                column.map((val, index) => (
                    <div className='flex items-center  w-full '>
                        <div className={`border h-10
             ${index === 0 ? 'bg-mylightyellow' : ''}
             ${index === 1 ? 'bg-mylightgreen' : ''}
             ${index === 2 ? 'bg-mydarkgreen' : ''}
             ${index === 3 ? 'bg-mydarkyellow' : ''}
              px-4 flex items-center justify-center`}>
                            <span>{`
                  ${alphabetsConstants[index]}
                  `}</span>
                        </div>
                        <span
                            className='cursor-pointer border border-black w-full outline-none rounded-md border-opacity-50 px-2 py-2'
                        >{val}</span>
                    </div>
                ))
            }
        </div>
    )
}

export default StaticRows