import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { CircularProgress, Pagination } from '@mui/material'
import NotFoundDataUi from './../../uiComponents/NotFoundDataUi'
import quizImg from '../../../assets/staticdata/bg.png'

const ScheduleTable = ({ setshowUpdateQuiz, type }) => {
    const { auth } = useSelector(s => s)
    const [adminQuestionsState, setadminQuestionsState] = useState('')
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(true)
                const res = await axios(`/get/usercreated/quizzez?page=${page}&limit=${limit}&type=${adminQuestionsState}&quiztype=${type}`)
                //console.log('res==>', res.data)
                if (res.status === 200) {
                    //console.log('questions==>', res.data.quizzez)
                    setquestions(res.data.quizzez)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setloading(false)
            } catch (error) {
                setloading(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()

    }, [page, limit, adminQuestionsState])
    //console.log(questions)
    return (
        <div>
            {
                auth.data.user.isAdmin &&
                <div className='flex items-center justify-end p-5'>
                    <select value={adminQuestionsState} onChange={(e) => setadminQuestionsState(e.target.value)}
                        className='my__select'>
                        <option value=''>my quizzez</option>
                        <option value='all'>users quizzez</option>
                        <option value='disable'>disable quizzez</option>
                        <option value='open'>enabled quizzez</option>
                        <option value='admin'>admin quizzes</option>
                    </select>
                </div>
            }
            {
                loading ?
                    <div className='w-full flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {
                            questions.length > 0 ?
                                <>
                                    <MyTable
                                        setshowUpdateQuiz={setshowUpdateQuiz}
                                        adminQuestionsState={adminQuestionsState}
                                        questions={questions} setquestions={setquestions} />
                                    <div className='flex justify-center px-5 pt-5'>
                                        <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                                    </div>
                                </>
                                :
                                <div className='w-full flex flex-col items-center'>
                                    <img className='w-[60%] md:w-[50%]' src={quizImg} alt="" />
                                    <div className='w-full flex flex-col items-center'>
                                        <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                            No Quiz Data Found
                                        </p>
                                        {/* <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/quizmasters')}>Quiz Masters</button> */}
                                    </div>
                                </div>
                            // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No quizz available...</div>

                        }
                    </>
            }
        </div>
    )
}
export default ScheduleTable