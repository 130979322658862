import axios from '../utils/myAxios'
import { globalConstants, questionsConstants } from './appConstants'
import store from './../store'
export const createQuestionsAction = (body) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/question/create', {
                ...body
            })
            if (res.status === 200) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { success: "question created successfully!" }
                })
            }
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const getAllQuestionCategories = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get('/get/questions/categories')
            console.log(res)
            dispatch({
                type: questionsConstants.QUESTION_CATEGORIES,
                payload: res.data.questionCategories
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}

export const deleteQuestion = (data) => {
    return async (dispatch) => {
        try {
            //console.log(data._id)
            const res = await axios.delete(`/delete/question/${data._id}`)
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const approveQuestionAction = (qid) => {
    return async (dispatch) => {
        try {
            const res = await axios.post(`/questions/approve/${qid}`)
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "question approved successfully!" }
            })
            return res.data.question
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const rejectQuestionAction = (qid, rejectResons) => {
    return async (dispatch) => {
        try {
            const obj = {
                rejectedReasons: rejectResons
            }
            const res = await axios.post(`/questions/reject/${qid}`, {
                ...obj
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "question rejected successfully!" }
            })
            return res.data.question
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}


export const reApprovalRequestAction = (qid, obj) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post(`/question/reapproval/request/${qid}`, {
                ...obj
            })
            //console.log('res===>', res.data)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}