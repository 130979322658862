import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import MyDraftjs from './MyDraftJs'
const QuizzRules = () => {
    const dispatch = useDispatch()
    const { question } = useSelector(s => s)
    const [selectedCategory, setselectedCategory] = useState('')
    const [richData, setrichData] = useState('')
    const handleSave = async () => {
        if (!richData.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "empty document cannot be saved!" } })
        if (!selectedCategory.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly select the category!" } })
        try {
            const obj = {
                category: selectedCategory,
                rules: richData,
            }
            await axios.post('/root/categoryrules', {
                ...obj
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "canges saved succesfully!" }
            })
        } catch (error) {
            return dispatch({
                tyep: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    useEffect(async () => {
        if (selectedCategory) {
            try {
                const res = await axios.get(`/get/categoryrule/${selectedCategory}`)
                if (!res.data.rules) {
                    setrichData('')
                } else {
                    setrichData(res.data.rules.rules)
                }
            } catch (error) {

            }
        }
    }, [selectedCategory])
    return (
        <div>
            <div>
                <select value={selectedCategory} onChange={(e) => setselectedCategory(e.target.value)}
                    className='my__select'
                >
                    <option>Select the Category</option>
                    {
                        question.questionsCategories.map((val, index) => (
                            <option value={val._id}>{val.name.replace(/_/g, " ")}</option>
                        ))
                    }
                </select>
            </div>
            <div>
                <MyDraftjs content={richData} setContent={setrichData} />
            </div>
            <div className='flex items-center justify-end mt-5'>
                <button onClick={handleSave} className='bg-bgblue text-white py-1 px-2 rounded-lg '>Save</button>
            </div>
        </div>
    )
}

export default QuizzRules