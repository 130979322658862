import React, { useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
import { updateQuizzStatusAction } from '../../../actions/quizzSchedule.actions'
import { useDispatch, useSelector } from 'react-redux'
import { questionsCategoryConstants } from '../../../actions/appConstants'
const TableRow = ({ val, questions, setquestions }) => {
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleDisable = () => {
        dispatch(updateQuizzStatusAction(val._id, 'disable')).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleOpen = () => {
        dispatch(updateQuizzStatusAction(val._id, 'open')).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} />
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                {/* tile */}
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.title}</span>
                </td>
                {/* type */}
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.type}</span>
                </td>
                {/* my earning */}
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.playerEarning}</span>
                </td>
                {/* scheduler earning */}
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.createrPercent}</span>
                </td>
                {/* date */}
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val.createdAt).subtract(10, 'days').calendar()}</span>
                </td>
                {/* start time */}
                <td class="py-3 px-6 text-center">
                    <span>{moment(val.time).format("LT")}</span>
                </td>
                {/* end time */}
                <td class="py-3 px-6 text-center">
                    {
                        val.isDurationBound ?
                            moment(val.durationBoundEndTime).format("MMMM Do YYYY, h:mm:ss a")
                            :
                            moment(val.durationDateComplete).format("MMMM Do YYYY, h:mm:ss a")
                    }
                    {/* <span>{moment(val.durationDateComplete).format("LT")}</span> */}
                </td>
                {/* no of questions */}
                <td class="py-3 px-6 text-center">
                    {
                        val.category.name === questionsCategoryConstants.ELIMINATOR_QIZ ?
                            <span>{val.eliminatedQuiz.questions.length}</span>
                            :
                            val.category.name === questionsCategoryConstants.MIX_TYPE_QUIZ ?
                                <span>{val.forMixTypeQuestions.length}</span>
                                :
                                <span>{val.questions.length}</span>
                    }
                </td>
                {/* registered user... */}
                <td class="py-3 px-6 text-center">
                    <span>{val?.registerdUsers?.length}</span>
                </td>
                {/* registration free */}
                <td class="py-3 px-6 text-center">
                    {
                        val.type === 'free' ?
                            <span>{val.requiredPointsForFree}</span>
                            :
                            <span>{val.price}</span>
                    }
                </td>
                {/* category */}
                <td class="py-3 px-6 text-left">
                    <span>{(val?.category?.name).replace(/_/g, ' ')}</span>
                </td>
                <td class="py-3 px-6 text-center">
                    {
                        <span class="text-white bg-bgblue py-1 px-3 rounded-full text-xs capitalize">{val.status}</span>
                    }
                </td>
                {/* actions */}
                {
                    auth.data.user.isAdmin &&
                    <td class="py-3 px-6 text-center">
                        <div class="flex item-center justify-center space-x-3">
                            {
                                val.status === 'open' ?
                                    <button onClick={handleDisable} className='border-none  py-1 px-3 text-white bg-red-400 text-xs'>Disable</button>
                                    :
                                    val.status === 'disable' ?
                                        < button onClick={handleOpen} className='border-none  py-1 px-3 text-white bg-green-400 text-xs'>Enable</button>
                                        :
                                        null
                            }
                        </div>
                    </td>
                }
            </tr>
        </>
    )
}

export default TableRow