
export const getSentence = text => {
    return text.split(' ').map((w, id) => {
        if (w.startsWith('<')) {
            const m = w.slice(1, w.length - 1)
            return { id, text: m, type: 'answer', placed: false, displayed: '' };
        }
        return { id, text: w, type: 'word' };
    });
};
export const getAnswers = text => {
    const wordList = Array.from(new Set(text.split(' ')));
    return wordList.reduce((acc, cur) => {
        if (cur.startsWith('<')) {
            const m = cur.slice(1, cur.length - 1)
            //console.log('m-====>', m)
            return acc.concat(m);
        }
        return acc;
    }, []);
};
