import React, { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import CloseIcon from "@mui/icons-material/Close";
import axios from "../utils/myAxios";
import DangerousIcon from "@mui/icons-material/Dangerous";
function AboutPage({ path }) {
  const [pathData, setPathData] = useState();
  useEffect(() => {
    axios
      .get(`/getaboutinfo/${path}`)
      .then((body) => {
        setPathData(body.data.data);
      })
      .catch((err) => err);
  }, [path]);
  const [open, setOpen] = useState(false);
  return (
    <>
      {pathData?.info && (
        <>
          <div
            className="cursor-pointer text-green-500"
            onClick={() => setOpen(true)}
          >
            <HelpIcon />
          </div>
          <Dialog
            fullWidth={true}
            maxWidth="md"
            className="!scrollbar-hide "
            open={open}
          >
            <div
              className="w-full p-5 flex flex-row-reverse cursor-pointer text-red-400 font-semibold text-2xl"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-5">
              <h1 className="font-semibold text-2xl text-yellow-500">
                About this page
              </h1>
              {pathData?.info ? (
                <div
                  className="w-full text-lg px-10 py-3 list"
                  dangerouslySetInnerHTML={{ __html: pathData?.info }}
                ></div>
              ) : (
                <div className="flex items-center text-[100px]">
                  <DangerousIcon
                    color="error"
                    fontSize="inherit"
                  ></DangerousIcon>
                  <span className="text-[40px] text-slate-800">
                    No Information to display !..
                  </span>
                </div>
              )}
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}

export default AboutPage;
