import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { AiOutlineClose } from 'react-icons/ai'
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import axios from '../../utils/myAxios'
import { useDispatch } from 'react-redux';
import { globalConstants } from '../../actions/appConstants';
function TableModal({
    imgURL,
    setimgURL,
    open,
    setopen,
    uid,
    setuid,
    kycstatus,
    setkycstatus,
    setquestions
}) {
    const dispatch = useDispatch()
    const handleClose = () => {
        setopen(false)
        setimgURL('')
        setuid('')
        setkycstatus(false)
    };
    const handleApproveKyc = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _obj = {
                status: true,
                uid: uid,
            }
            const res = await axios.post('/update/user/kyc/status', {
                ..._obj,
            })
            setquestions(prevState => prevState.map((val) => val.user._id === uid ? { ...val, user: res.data.user } : val))
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: 'status updated successfully!' }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    const handleRejectKyc = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _obj = {
                status: false,
                uid: uid,
            }
            const res = await axios.post('/update/user/kyc/status', {
                ..._obj,
            })
            setquestions(prevState => prevState.map((val) => val.user._id === uid ? { ...val, user: res.data.user } : val))
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: 'status updated successfully!' }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <Dialog
            maxWidth='xs'
            fullWidth={true}
            onClose={() => { }}
            open={open}
        >
            <div className='p5'>
                <IconButton
                    onClick={handleClose}
                    className='!absolute !right-0 !top-0 mr-4 mt-4 cursor-pointer'
                >
                    <ClearIcon />
                </IconButton>
                <div className='w-full p-5'>
                    {
                        imgURL.trim() !== '' &&
                        <img src={imgURL} alt='img' className='w-full mt-8 object-contain'
                        />
                    }
                    {
                        kycstatus ?
                            <div className='mt-5'>
                                <button onClick={handleRejectKyc} className='capitalize bg-red-700 text-white py-2 px-4 text-sm text-center rounded'>Reject</button>
                            </div>
                            :
                            <div className='mt-5'>
                                <button onClick={handleApproveKyc} className='capitalize bg-bgblue text-white py-2 px-4 text-sm text-center rounded'>Approve</button>
                            </div>
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default TableModal;