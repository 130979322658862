import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PlayFreeQuiz from '../staticComponents/howitWorksC/PlayFreeQuiz';
import SheduleFreeQuiz from '../staticComponents/howitWorksC/SheduleFreeQuiz'
import PlayPaidQuiz from '../staticComponents/howitWorksC/PlayPaidQuiz';
import ShedulePaidQuiz from '../staticComponents/howitWorksC/ShedulePaidQuiz'
import Quizmasterpro from '../staticComponents/howitWorksC/Quizmasterpro';
import PlayQuizzithon from '../staticComponents/howitWorksC/PlayQuizzithon';
import ScheduleQuizzithon from '../staticComponents/howitWorksC/ScheduleQuizzithon';
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'
import howitworksHerofrom from '../../assets/staticdata/howitworksHero.jpeg'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HowitWork = () => {

  const p = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));


  return (
    <div className='!bg-white overflow-x-hidden'>
      <NavBar />
      <img
        src={howitworksHerofrom}
        className='w-full md:mt-[4rem]'
      />
      <Typography variant={matches ? "h3" : "h4"} textAlign='center' sx={{ color: "black" }}>How it Work</Typography>
      <Typography mt={5} px={matches ? 20 : 0} variant='subtitle1' textAlign='center' sx={{ color: "black", fontSize: 18 }}>Do you like to take challenges and win rewards? Are you one of those people who wants to have it all? Do you want to feel the adrenalin and rush of blood in your veins while you take respect and earn big time? Well, we might have something just for you.
      </Typography>
      <Box className = 'py-10' mt={5} sx={{ width: '100%' }}>
        <Box sx={{ display: "flex", justifyContent: "center", }}>
          <Tabs className='pb-5' value={value} onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",

              }
            }} aria-label="basic tabs example">
            <Tab label="Play Free Quiz" {...a11yProps(0)} />
            <Tab label="Shedule Free Quiz" {...a11yProps(1)} />
            <Tab label="Play Paid Quiz" {...a11yProps(2)} />
            <Tab label="Shedule Paid Quiz" {...a11yProps(3)} />
            <Tab label="Quizmaster-Pro" {...a11yProps(4)} />
            <Tab label="Play Quizzithon" {...a11yProps(5)} />
            <Tab label="Schedule Quizzithon" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PlayFreeQuiz />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SheduleFreeQuiz />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <PlayPaidQuiz />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ShedulePaidQuiz />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Quizmasterpro />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <PlayQuizzithon />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ScheduleQuizzithon />
        </TabPanel>
      </Box>
      
      


      <Footer />
    </div>
  )
}

export default HowitWork