import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { extrasConstancts } from '../actions/appConstants'
import { FaChalkboardTeacher } from 'react-icons/fa'
import PlansC from '../components/plansComponents/PlansC';
import StaticCategoriesC from '../components/staticCategoriesC/StaticCategoriesC'
import SchoolQuizC from './../components/schoolQuizC/SchoolQuizC'
import SchoolTabs from '../components/schoolQuizC/schoolTabs/SchoolTabs'
import Layout from './../components/Layout/Layout'
import { keepUserLoggedIn } from '../actions/auth.actions';

const MySchoolQuizzes = () => {
    const { extras , auth } = useSelector(state => state);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(keepUserLoggedIn())
    }, [])
    return (
        <Layout>
            {/* <StaticCategoriesC isSchoolQuizzes={true} /> */}
            {auth.data.user.quizmaster_pro > 0  ?
                <SchoolTabs />

                : 
                <div className='bg-white py-5 flex items-center justify-center flex-col'>
                    {/* <Quizmasterpro /> */}
                    <div className='w-full h-screen flex justify-center items-center'>
                        <div className='max-w-[400px] text-red-400 text-center'>
                        You have the 0 Quizmaster Pro quizzes left, please buy a Qzeto package to continue.
                        </div>
                    </div>
                    {/* <div
                        onClick={() => dispatch({
                            type: extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION,
                            payload: true
                        })}
                        className= 'w-64 h-12 my-10 flex items-center justify-center space-x-2 bg-mydarkgreen rounded-2xl py-1.5 cursor-pointer text-white font-semibold text-lg'>
                        <FaChalkboardTeacher />
                        <span>Become A Quiz Master</span>
                    </div> */}
                </div>}
        </Layout>
    )
}

export default MySchoolQuizzes