import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import FriendVector from './../../assets/profilepage/friend.svg'
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants } from '../../actions/appConstants';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    const { onClose, selectedValue, open, code } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog

            onClose={handleClose}
            open={open}
            fullWidth={true}
            maxWidth='xs'
        >
            <div className='p-5 py-9'>
                <div className='flex items-center justify-center'>
                    <img
                        src={FriendVector}
                        className='w-[40%] object-contain'
                        alt=''
                    />
                </div>
                <div>
                    <div className='w-max m-auto mt-10 space-y-2'>
                        <h1 className='uppercase text-bgblue text-sm tracking-widest font-semibold'>referral code</h1>
                        <div
                            onClick={() => {
                                navigator.clipboard.writeText(`${auth.data.user.referalCode}`)
                                dispatch({
                                    type: globalConstants.ALERT,
                                    payload: { success: "Referral Code is copied!" }
                                })
                            }}
                            className='border-2 w-max border-dashed border-yellow-400 bg-yellow-50 p-2 flex items-center space-x-10 cursor-pointer'>
                            <h1 className='font-semibold text-lg tracking-widest'>{code}</h1>
                            <span className='text-bgblue text-sm'>Tap to copy</span>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog> 
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({
    setOpen, open, code
}) {
    // const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <SimpleDialog
                code={code}
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    );
}
