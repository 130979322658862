import axios from "../utils/myAxios";
import { globalConstants, walletConstants } from "./appConstants";
import store from "./../store";

export const getWalletAction = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`/wallet/get/${id}`);

      dispatch({
        type: walletConstants.GET_WALLET,
        payload: res.data.wallet,
      });
    } catch (error) {
      if (error.response.data.msg) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.msg },
        });
      } else if (error.response?.data?.error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.error },
        });
      } else {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  };
};

export const redeamFreeToRealAction = () => {
  return async (dispatch) => {
    try {
      const { auth } = store.getState();
      const res = await axios.post(`/redeam/freetoreal/${auth.data.user._id}`);
      dispatch({
        type: globalConstants.ALERT,
        payload: { success: res.data.msg },
      });
    } catch (error) {
      if (error.response.data.msg) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.msg },
        });
      } else if (error.response?.data?.error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.error },
        });
      } else {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  };
};
export const redeamBonusToFreeAction = () => {
  return async (dispatch) => {
    try {
      const { auth } = store.getState();
      const res = await axios.post(`/redeam/bonustofree/${auth.data.user._id}`);
      dispatch({
        type: globalConstants.ALERT,
        payload: { success: res.data.msg },
      });
    } catch (error) {
      if (error.response.data.msg) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.msg },
        });
      } else if (error.response?.data?.error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.error },
        });
      } else {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  };
};
export const redeamBonusToRealAction = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: true },
      });
      const { auth } = store.getState();
      const res = await axios.post(`/redeam/bonustoreal/${auth.data.user._id}`);
      dispatch({
        type: globalConstants.ALERT,
        payload: { success: res.data.msg },
      });
    } catch (error) {
      if (error.response.data.msg) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.msg },
        });
      } else if (error.response?.data?.error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.error },
        });
      } else {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  };
};
