import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Multiple from "../../../assets/categoriesimg/multiple.png"
import FilInThe from "../../../assets/categoriesimg/fill.png"
import MatchPair from "../../../assets/categoriesimg/columns.png"
import Sequence from "../../../assets/categoriesimg/sequence.png"
import Paragraphy from "../../../assets/categoriesimg/indent.png"
import Sharing from "../../../assets/categoriesimg/sharing.png"
import Spin from "../../../assets/categoriesimg/spin.png"
import Eleminete from "../../../assets/categoriesimg/kick-off.png"
import MixType from "../../../assets/categoriesimg/experiment.png"
import TrueOrFalse from "../../../assets/categoriesimg/true-or-false.png"
import CrossWords from "../../../assets/categoriesimg/cross.png"
import DragAndDrop from "../../../assets/categoriesimg/drag-and-drop.png"
import Closed from "../../../assets/categoriesimg/closed.png"
import Word from "../../../assets/categoriesimg/word.png"
import Waste from "../../../assets/categoriesimg/waste.png"
import Drag from "../../../assets/categoriesimg/drag.png"
import Tic from "../../../assets/categoriesimg/tic-tac-toe.png"
import MyPopUp from './MyPopUp'
import { Container, Typography } from '@mui/material'
const quizzCategories = [
    {
        id: 1,
        icon: Multiple,
        title: "Multiple Questions"
    },
    {
        id: 2,
        icon: FilInThe,
        title: "Fill In The blank"
    },
    {
        id: 3,
        icon: MatchPair,
        title: "match the pair"
    },
    {
        id: 4,
        icon: Sequence,
        title: "sequence quiz"
    },
    {
        id: 5,
        icon: Paragraphy,
        title: "paragraph ordering quiz"
    },
    {
        id: 6,
        icon: Sharing,
        title: "identify image video or voice"
    },
    {
        id: 7,
        icon: Spin,
        title: "spin a wheel quiz"
    },
    {
        id: 8,
        icon: Eleminete,
        title: "eliminator quiz"
    },
    {
        id: 9,
        icon: MixType,
        title: "mix type quiz"
    },
    {
        id: 10,
        icon: TrueOrFalse,
        title: "true or false"
    },
    {
        id: 11,
        icon: CrossWords,
        title: "cross words"
    },
    {
        id: 12,
        icon: DragAndDrop,
        title: "drag drop fill up the blanks"
    },
    {
        id: 13,
        icon: Closed,
        title: "closed ended"
    },
    {
        id: 14,
        icon: Word,
        title: "sentence sequence"
    },
    {
        id: 15,
        icon: Waste,
        title: "sorting classification"
    },
    {
        id: 16,
        icon: Drag,
        title: "drag drop visual simulating"
    },
    {
        id: 17,
        icon: Tic,
        title: "Tic tac toe"
    },

]

const QuizCategory = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [openPopUp, setOpenPopUp] = useState(false)
    return (
        <>
            <Container className = "py-5" fluid={true}>
            <Typography variant={matches ? "h3" : "h5"} textAlign="center" sx={{ color: "#800000" , marginTop : "30px" ,marginBottom : "30px" , fontSize : "bold" }}>
                Quiz Category
              </Typography>
                <div className='allCategory'>

                    {
                        quizzCategories.map(item => (
                            <div className='eachCategory' key={item.id} onClick={() => { setOpenPopUp(true) }}>
                                <div className='img-container'>
                                    <img width="40%" src={item.icon} alt="" />
                                </div>
                                <div className='title'>
                                    <h4>{item.title}</h4>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <MyPopUp
                    title="Quizzingbox"
                    openPopUp={openPopUp}
                    setOpenPopUp={setOpenPopUp}
                >
                    <Typography variant="subtitle1" sx={{ color: "black" }}>

                        Create exemplary online quizzes of different formats and from across all subjects. Draft the right questions and answers using our question bank module, Earn rewards while you learn playing & scheduling quizzes on this platform.</Typography>
                </MyPopUp>
            </Container>
        </>
    )
}

export default QuizCategory;