import React from 'react'
import { questionsCategoryConstants } from '../../../actions/appConstants'
import FillInTheBlanks from '../fillInTheBlanks/FillInTheBlanks'
import MatchThePair from '../matchThePair/MatchThePair'
import SpinWheal from '../spinWheal/SpinWheal'
import TrueFalse from '../trueFalse/TrueFalse'
import MultipleQuestions from './../multipleQuestions/MultipleQuestions'
import SequenceTheQuizz from './../sequenceTheQuizz/SequenceTheQuizz'
import ParagraphSequence from './../paragraphSequence/ParagraphSequence'
import IdentifyImageVideo from './../identifyImageVideo/IdentifyImageVideo'
import ClosedEnded from '../closedEnded/ClosedEnded'
import SentenceSequence from '../sentenceSequence/SentenceSequence'
import ClassificationSorting from '../classificationSorting/ClassificationSorting'
import DragDropVisualSimulatin from '../dragDropVisulaSimulating/DragDropVisualSimulatin'
import DragDropFillUpTheBlanks from '../dragDropFillUpTheBlanks/DragDropFillUpTheBlanks'
import CrossWordGame from '../crossWordGame/CrossWordGame'
import TicTacToe from '../ticTacToe/TicTacToe'
const TypeContainer = ({ questionsArray, questionIndex, handleLeaveQuestion, handleNextQuestion, played }) => {
    //console.log('from ===>', played)
    return (
        <div className='mb-10 px-5 pb-5'>
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.MULTIPLE_QUESTIONS &&
                <MultipleQuestions
                    isLastQuestion={questionIndex === questionsArray?.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.FILL_IN_THE_BLANKS &&
                <FillInTheBlanks
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.TRUE_FALSE &&
                <TrueFalse
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.MATCH_THE_PAIR &&
                <MatchThePair
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.SEQUENCING_QUIZ &&
                <SequenceTheQuizz
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ &&
                <ParagraphSequence
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE &&
                <IdentifyImageVideo question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.CLOSED_ENDED &&
                <ClosedEnded
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.SENTENCE_SEQUENCE &&
                <SentenceSequence
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.SORTING_CLASSIFICATION &&
                <ClassificationSorting
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING &&
                <DragDropVisualSimulatin
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS &&
                <DragDropFillUpTheBlanks
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.CROSS_WORDS &&
                <CrossWordGame
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {
                questionsArray[questionIndex].questionCategory.name === questionsCategoryConstants.TIC_TAC_TOE &&
                <TicTacToe
                    isLastQuestion={questionIndex === questionsArray.length - 1}
                    question={questionsArray[questionIndex]}
                    handleLeaveQuestion={handleLeaveQuestion} handleNextQuestion={handleNextQuestion}
                />
            }
            {/* <SpinWheal /> */}
        </div>
    )
}

export default TypeContainer