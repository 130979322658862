import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SubscriptionCard from './SubscriptionCard'
import axios from '../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
//
import CircularProgress from '@mui/material/CircularProgress';
import PaymentHistoryTable from './paymentHistoryTable/PaymentHistoryTable'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
const SubscriptionC = () => {
    const [loading, setloading] = useState(false)
    const [subscriptions, setsubscriptions] = useState([])
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(true)
                const res = await axios.get(`/getalluserboughtsubscriptions/${auth.data.user._id}`)
                // console.log('res==>', res.data)
                setsubscriptions(res.data.subscriptions)
                setloading(false)
            } catch (error) {
                setloading(false)
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
        getData()
    }, [])
    return (
        <div>
            <h1 className='bg-bgblue text-lg text-white p-2 font-bold'>Purchased Plans</h1>
            {
                loading ?
                    <div className='w-full flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    subscriptions.length > 0 ?
                        < PlanCont >
                            <div>
                                <div className='grid lg:grid-cols-3 mt-5 gap-4 grid-cols-1'>
                                    {
                                        subscriptions.map((val, index) => (
                                            <SubscriptionCard key={index} val={val} />
                                        ))
                                    }
                                </div>
                            </div>
                        </PlanCont>
                        :
                        <NotFoundDataUi
                            text={`no subscriptions available`}
                        />
                // <h1 className='capitalize text-center'>no subscriptions available</h1>
            }
        </div >
    )
}
const PlanCont = styled.div`

`
export default SubscriptionC