import React from 'react'
import { Container, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import TextBookImg from "../../../assets/staticdata/quiz1.jpg"
import Science from "../../../assets/staticdata/science.png"
import Country from "../../../assets/staticdata/coronavirus.png"
import Exams from "../../../assets/staticdata/exam.png"
import Lingual from "../../../assets/staticdata/belgium.png"

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const imgData = [
  {
    img: Country,
    
    title1: "Share quiz link with passcode to any one to join the quiz"
  },
  {
    img: Exams,
    title1: "Prepare your students for any exam pattern",
    
  },
  {
    img: Science,
    title1: "Check result instantly with performance analysis",
   
  },
  {
    img: Lingual,
    title1: "Access to all quiz formats which gives enjoyable learning experience",
    
  },
]
const TestBookPass = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Container>
        <Grid spacing={4} container mt={10}>
          <Grid style={{ display: "flex", justifyContent: "center", alighnItem: "center" }} item md={6} xs={12}>
            <img src={TextBookImg} alt="" width="100%" />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant= {matches ? 'h3' : 'h5'} textAlign={matches ? 'left' : 'center'} sx={{ color: "#800000" }}>
              Quizmaster Pro
            </Typography>
            <Typography py={3} variant="subtitle1" textAlign="left" sx={{ color: "black" }}>
              This subscription based feature help you create your own quizzing community
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "black", fontWeight: "bold" }}>
              What you get with Quizmaster Pro
            </Typography>
            <div className='grid grid-cols-2 gap-8 mt-8'>
              {
                imgData.map((val, index) => (
                  <div key={index} className='flex items-center w-full space-x-3'>
                    <div>
                      <img className='w-14 h-14 object-contain' src={val.img} alt="" />
                    </div>
                    <div>
                      <h1>{val.title1}</h1>
                      <h1>{val.title2}</h1>
                    </div>
                  </div>
                ))
              }
            </div>
            {/*
            </Grid> */}
            {/* 

              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img className="h-14 w-14 object-cover" src={DailyImg} alt="" />
                  <Box >
                    <Typography variant="h5" sx={{ color: "black", fontSize: "13px", fontWeight: "600" }}>Multi-lingual</Typography>
                    <Typography variant="h5" sx={{ color: "black", fontSize: "13px", fontWeight: "600" }}>Mock Tests</Typography>
                  </Box>
                </Box>


              </Grid>

            </Grid> */}
          </Grid>


        </Grid>

      </Container>
    </>
  )
}

export default TestBookPass;
