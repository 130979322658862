import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import UsersRedemptionTab from '../components/userRedemptionTab/UsersRedemptionTab'
const UserRedemptionTab = () => {
    const { auth } = useSelector(s => s)
    const { state } = useLocation()
    const [loading, setloading] = useState(true)
    const [user, setuser] = useState('')

    //check if user management want to see user questions
    useEffect(async () => {
        if (state?.userManagement) {
            // console.log('state?.userManagement==>', state?.userManagement)
            await setuser(state?.userManagement)
            setloading(false)
        } else {
            setuser(auth.data.user._id)
            setloading(false)
        }
    }, [state])
    //check if user management want to see user questions
    return (
        <Layout>
            {
                loading ?
                    <div>loading...</div>
                    :
                    <UsersRedemptionTab
                        user={user}
                    />
            }
        </Layout>
    )
}

export default UserRedemptionTab