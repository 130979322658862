import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import logo from '../../assets/logo/logo.png'
import axios from '../../utils/myAxios'
import CircularProgress from '@mui/material/CircularProgress';
import { backend_url, extrasConstancts, globalConstants, showBuyInfoModalEnums } from '../../actions/appConstants'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DoneIcon from '@mui/icons-material/Done';
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}
const getExpiry = () => {
    const d = new Date()
    d.setMonth(d.getMonth() + 1)
    return d;
}
const PlanCard = ({ val }) => {
    const [dataForOrderId, setdataForOrderId] = useState(null)
    const dispath = useDispatch()
    const { auth } = useSelector(state => state)
    const [loading, setloading] = useState(false)
    const displayRazorPay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            return alert('Razorpay SDK failed to load, Are you online!')
        }
        //fetching the data from the backend against selected subscription
        // const _res = await axios.post(`/razorpay/${val._id}`)
        //fetching the data from the backend against selected subscription
        // const { data } = _res
        
        var options = {
            "key": process.env.REACT_APP_RAZOR_KEY_ID, // Enter the Key ID generated from the Dashboard
            // "currency": data.currency,
            "name": "Buy Subscription",
            "description": "Your going to buy our subscription!",
            "image": ``,
            "order_id": dataForOrderId.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": async (response) => {
                try {
                    dispath({
                        type: globalConstants.ALERT,
                        payload: { loading: true }
                    })
                    const responseObj = {
                        ...response,
                        orderId: dataForOrderId.id,
                    }
                    const razorPayRes = await axios.post('/razorpayvarification', {
                        ...responseObj
                    })
                    //real time functionality...
                    dispath({
                        type: extrasConstancts.IS_BOUGHT_SCHOOL_QUIZ_SUBSCRIPTION,
                        payload: true,
                    })
                    dispath({
                        type: globalConstants.ALERT,
                        payload: { loading: false }
                    })
                    dispath({
                        type: extrasConstancts.SHOW_BUY_INFO_MODAL,
                        payload: {
                            open: true,
                            title: `${val.title} subscription`,
                            description: `${val.numberOfQuiz} quizzes are added to your account`,
                            numbers: 100,
                            type: showBuyInfoModalEnums.SUBSCRIPTIONS,
                        },
                    })
                } catch (error) {
                    if (error.response.data.msg) {
                        dispath({
                            type: globalConstants.ALERT,
                            payload: { error: error.response.data.msg }
                        })
                    } else if (error.response.data.error) {
                        dispath({
                            type: globalConstants.ALERT,
                            payload: { error: error.response.data.error }
                        })
                    }
                    else {
                        dispath({
                            type: globalConstants.ALERT,
                            payload: { error: error.message }
                        })
                    }
                }
            },

            "prefill": {
            },
            "notes": {
                "subscription_id": val._id,
                "user_id": auth.data.user._id,
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            dispath({
                type: globalConstants.ALERT,
                payload: { error: response.error.reason }
            })
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });
        rzp1.open()
    }
    const handlePurchase = async () => {
        try {
            const obj = {
                amount: val.price
            }
            dispath({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/buysubscriptions/create/order', {
                ...obj
            })
            setdataForOrderId(res.data.order)
            dispath({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            dispath({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            if (error.response.data.msg) {
                dispath({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispath({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispath({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    useEffect(() => {
        if (dataForOrderId) {
            displayRazorPay()
        }
    }, [dataForOrderId])
    return (
        <> 
        {loading ?
                    <div className='flex flex-col justify-center items-center'>
                        <CircularProgress />
                    </div> :  
                    <div className=' rounded-2xl border border-slate-200 shadow-lg p-8 bg-white flex flex-col'>
                        <h4 className='text-lg font-semibold leading-5'>{val.title}</h4>
                    
                        <div className='mt-4 bg-slate-100 p-6 rounded-lg -mx-6 '>
                            <p className='text-sm font-semibold text-slate-500 flex items-center'>
                                <span>INR</span>
                                <span className='text-3xl text-slate-900 ml-3'><CurrencyRupeeIcon/>{val.price}</span>
                                <span className='ml-1.5'>/month</span>
                            </p>
                        </div>
                        <p className='mt-4 text-slate-700 text-sm leading-6 flex-1'>{val.description}</p>
                    
                        <ul className='mt-6 space-y-3 '>
                            <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{val.numberOfQuiz} Quizes Allowed</li>
                            {/* <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon> Feature 2</li>
                            <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon> Feature 3</li>
                            <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon> Feature 4</li>
                            <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon> Feature 5</li> */}
                        </ul>
                    {/* button */}
                        <button onClick={handlePurchase} className='text-white bg-cyan-500 hover:bg-cyan-500 block mt-8 px-6 py-4 text-center rounded-lg shadow-md'>Become Quiz Master</button>
                    </div>}
            
        </>

    )
}
const ExtraData = styled.div`
::-webkit-scrollbar{
    width: 10px;
    cursor: pointer !important;
}
::-webkit-scrollbar-track{
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.25);
    cursor: pointer !important;
}
::-webkit-scrollbar-thumb{
border-radius: 5px;
background-color: #1e3a8a;
cursor: pointer !important;
}

`
const Cont = styled.div`
${tw`w-full bg-[#A8C8F2] border border-gray-300  rounded-md   flex flex-col `}
`
export default PlanCard