import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//
import axios from '../../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants } from '../../../actions/appConstants';
import { Button, CircularProgress } from '@mui/material';
//
//
import CircleData from './CircleData'
//
import { questionsCategoryConstants } from '../../../actions/appConstants'
import StarRatings from 'react-star-ratings'
import { getAnswers } from '../dragDropFillUpTheBlanks/textConverters';
import { useNavigate } from 'react-router-dom'
const Circle = ({ numbers, text, last, isTicTacToe }) => {
    return (
        <div className={`relative flex items-center justify-center flex-col `}>

            <div className={`${last ? 'bg-white text-black border-green-400' : ''} w-20 h-20 rounded-full flex items-center justify-center border-4 border-white text-white`}>
                <span className={`text-xl `}>{numbers}</span>
            </div>
            <h1 className='text-white mt-2'>{text}</h1>
        </div>
    )
}
const Question = ({
    correct,
    incorrect,
    val
}) => {
    return (
        <div className={`relative border ${correct ? "border-green-500 bg-green-100" : "border-red-500 bg-red-100"
            }  p-5 rounded-xl`}>
            {
                correct ?
                    <CheckCircleIcon className='!absolute text-green-500 top-2 right-2' />
                    :
                    <CancelIcon className='!absolute text-red-500 top-2 right-2' />
            }
            <h1 className='font-bold'>
                <span className='mr-3'>Question:</span>{val.statement}
            </h1>
            {
                val.questionCategory.name === questionsCategoryConstants.MATCH_THE_PAIR &&
                val.collumns.c.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val.questionCategory.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val.questionCategory.name === questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ)
                &&
                val.collumns.b.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                Object.values(val?.forCrossWord.across).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.answer}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                Object.values(val?.forCrossWord?.down).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.answer}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS)
                &&
                getAnswers(val.forDragDropFillUpBlanks).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING)
                &&
                Object.values(val?.forDragDropVisualSimulating?.correct).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c.name}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING)
                &&
                Object.values(val?.forDragDropVisualSimulating?.incorrect).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c.name}
                    </h1>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.SORTING_CLASSIFICATION)
                &&
                Object.values(val?.forSortingClassificationType).map((_c, _i) => (
                    <div>
                        <h1 className='font-bold mt-1'>{_c.name} :</h1>
                        {
                            _c.values.map((val, index) => (
                                <h1 key={index} className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                                    {val}
                                </h1>
                            ))
                        }
                    </div>
                ))
            }
            {
                (val.questionCategory.name === questionsCategoryConstants.FILL_IN_THE_BLANKS ||
                    val.questionCategory.name === questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE ||
                    val.questionCategory.name === questionsCategoryConstants.MULTIPLE_QUESTIONS ||
                    val.questionCategory.name === questionsCategoryConstants.TRUE_FALSE ||
                    val.questionCategory.name === questionsCategoryConstants.CLOSED_ENDED ||
                    val.questionCategory.name === questionsCategoryConstants.SENTENCE_SEQUENCE
                ) &&
                <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                    {val.userSubmission }     <span className='font-bold mx-3'>Correct Answer : </span> {val.correctAnswer}
                </h1>
            }
        </div>
    )
}
const PlayedQuizzScreen = ({ quizzId, user  , isPastQuiz}) => {
    const navigate = useNavigate()
    const [rating, setrating] = useState(0)
    const [reviewText, setreviewText] = useState('')
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    const [loading, setloading] = useState(false)
    const [gammerData, setgammerData] = useState(null)
    const handleRating = (e) => {
        setrating(e)
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(true)
                const obj = {
                    user: user,
                    quizzId: quizzId,
                    isPastQuiz
                }
                const res = await axios.post('/gammer/get/playedquizz', {
                    ...obj
                })
                setgammerData(res.data.gammer)
                setloading(false)
            } catch (error) {
                setloading(false)
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
        if (quizzId) {
            getData()
        }
    }, [quizzId])
    useEffect(async () => {
        if (rating > 0) {
            try {
                const rObj = {
                    user: auth.data.user._id, quizzId: quizzId, rating: rating
                }
                const ratingRes = await axios.post('/create/update/ratings', {
                    ...rObj,
                })
            } catch (error) {

            }
        }
    }, [rating])
    useEffect(async () => {
        try {
            const rObj = {
                user: auth.data.user._id, quizzId: quizzId
            }
            const ratingRes = await axios.post('/get/rating', {
                ...rObj,
            })
            if (ratingRes.data.data) {
                setrating(ratingRes.data.data.rating)
            }
        } catch (error) {

        }
    }, [])
    const handleReviewSubmit = async () => {
        if (!reviewText.trim()) return;
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const reviewObj = {
                user: auth.data.user._id,
                quizzId: quizzId,
                review: reviewText,
            }
            await axios.post('/creat/reviews', {
                ...reviewObj,
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            setreviewText('')
            navigate(`/singlequizz/detailscreen/${quizzId}`, { state: { submissions: true } })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <Cont>
            {
                loading
                    ?
                    <div className='flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    gammerData === null ?
                        <div>no data</div>
                        :
                        <>
                            <PerformanceCont>
                                <div className='grid grid-cols-2 gap-y-10'>
                                    <div className='lg:grid lg:grid-cols-3 grid-cols-2 gap-3'>
                                        <Circle
                                            isTicTacToe={gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE ? true : false}
                                            numbers={
                                                gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE
                                                    ?
                                                    gammerData.totalQuestions
                                                    :
                                                    gammerData.correctQuestions.length + gammerData.incorrectQuestions.length
                                            }
                                            text={gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE ? 'Total Lines' : 'Total Questions'}
                                        />
                                        <Circle
                                            isTicTacToe={gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE ? true : false}
                                            numbers={
                                                gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE
                                                    ?
                                                    gammerData.totalQuestions
                                                    :
                                                    gammerData.correctQuestions.length + gammerData.incorrectQuestions.length
                                            }
                                            text={gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE ? 'Attempted Lines' : 'Attempted Questions'}
                                        />
                                        <Circle
                                            numbers={Number(gammerData.numberOfCorrectQuestions)}
                                            text={gammerData.quizzId.category.name === questionsCategoryConstants.TIC_TAC_TOE ? 'Correct Lines' : 'Correct Questions'} />
                                    </div>
                                    <div className='grid-cols-1 gap-4 flex flex-col justify-center items-center'>
                                        {/* <Circle numbers={`${gammerData.correctQuestions.length}/${gammerData.correctQuestions.length + gammerData.incorrectQuestions.length}`} text='Your Score' last={true} /> */}
                                        <div>
                                            <CircleData title={`${gammerData.correctQuestions.length}/${gammerData.correctQuestions.length + gammerData.incorrectQuestions.length}`}
                                                progress={((gammerData.correctQuestions.length * 100) / (gammerData.correctQuestions.length + gammerData.incorrectQuestions.length))}
                                            />
                                        </div>
                                        <h1 className='text-white'>Your Score</h1>
                                    </div>
                                </div>
                            </PerformanceCont>
                            {
                                !auth?.data?.user?._id &&
                                <div className='pt-3'>
                                    <button
                                        onClick={() => navigate(`/singlequizz/detailscreen/${quizzId}`, { state: { isSchoolQuiz: true, hidelayout: true } })}
                                        className='bg-mylightyellow text-white p-2 rounded'>Quiz Detail</button>
                                </div>
                            }
                            <SubmissionCont>
                                <h1 className='capitalize font-semibold text-xl'>Submissions</h1>
                                <div className='space-y-3 mt-2'>
                                    {
                                        gammerData.correctQuestions.map((val, index) => (
                                            <Question correct={true} incorrect={false} val={val} />
                                        ))
                                    }
                                    {
                                        gammerData.incorrectQuestions.map((val, index) => (
                                            <Question correct={false} incorrect={true} val={val} />
                                        ))
                                    }
                                </div>
                            </SubmissionCont>
                            {
                                auth?.data?.user?._id &&
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-10'>
                                    <div className=''>
                                        <h1 className='capitalize font-semibold text-sm mb-2'>Rate This Quiz</h1>
                                        <StarRatings
                                            rating={rating}
                                            starRatedColor="#FFD800"
                                            starHoverColor='#FFD800'
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="30px"
                                            starSpacing="4px"
                                            changeRating={handleRating}

                                        />
                                    </div>
                                    <div className=''>
                                        <h1 className='capitalize font-semibold text-xl mb-2'>Add Your Review</h1>
                                        <textarea
                                            value={reviewText}
                                            onChange={(e) => setreviewText(e.target.value)}
                                            rows={8}
                                            className='border border-gray-500 p-2  w-full'
                                            placeholder='Write your review...'
                                        />
                                        <Button onClick={handleReviewSubmit} variant='contained'>Submit</Button>
                                    </div>
                                </div>
                            }
                        </>
            }

        </Cont >
    )
}
const SubmissionCont = styled.div`
${tw`mt-10`}
`
const PerformanceCont = styled.div`
${tw`bg-[#395983] rounded-md p-5`}
`
const Cont = styled.div`

    `
export default PlayedQuizzScreen