import React from 'react'
import quizmasterimg from '../../assets/staticdata/quizmasterimg.png'
import quiz1 from '../../assets/staticdata/quiz1.jpg'
import science from '../../assets/staticdata/science.png'
import exam from '../../assets/staticdata/exam.png'
import study from '../../assets/staticdata/study.png'
import network from '../../assets/staticdata/network.png'
import target from '../../assets/staticdata/target.png'
import authorization from '../../assets/staticdata/authorization.png'
import schedule from '../../assets/staticdata/schedule.png'
import password from '../../assets/staticdata/password.png'
function QuizMaster() {
    return (
        <div className='w-full flex flex-col items-center my-10'>
            <div className='w-[80%] flex flex-col lg:flex-row items-center justify-between'>
                <div className='w-[80%]'>
                    <h1 className='text-[32px] text-yellow-500'>
                        Become Our Quizmaster
                    </h1>
                    <p className='pr-5 text-[16px] text-justify'>
                        Are you feeling smart? Do you like to take challenges and win rewards? Are you one of those people who want to have it all? Do you want to feel the adrenalin and rush of blood in your veins while you take respect and earn big time? Well, we might have something just for you. Become our quizmaster & schedule unlimited quiz daily to earn rewards
                    </p>
                </div>
                <img className='w-[80%] lg:w-[50%]' src={quizmasterimg} alt="" />
            </div>
            <div className='w-[80%] flex flex-col-reverse lg:flex-row items-center lg:justify-evenly mt-5'>
                <img className='w-[80%] lg:w-[45%]' src={quiz1} alt="" />
                <div className='w-[90%] md:w-[80%] lg:w-[45%] pl-5'>
                    <h1 className='text-[32px] text-yellow-500'>
                        Quizmaster-Pro
                    </h1>
                    <div className='w-full text-[20px]'>
                        <p className='text-[16px]'>
                        This subscription based feature help you create your own quizzing community
                        </p>
                        <p className='text-[16px] font-semibold py-5'>
                            What you get with Quizmaster Pro
                        </p>
                        {/* 
                        Access to all quiz formats which gives enjoyable learning experience to your audience


Share the quiz passcode to your intended group to join the quiz instantly

Check the quiz result instantly for the quiz scheduled for your audience
 */}
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={study} alt="" />Access to all quiz formats which gives enjoyable learning experience</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={schedule} />Schedule quizzes dedicated to your audience or group</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={password} />Every quiz scheduled generates a passcode to participate in the quiz</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={authorization} />These quiz will only be accesible to your desired audience who has acess to the quiz passcode</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={network} />Share quiz passcode to any one to join the quiz</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={target} />Conduct any kind of test,survey or any event for dedicated group of audience</span>
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={science} />Check result instantly with performance analysis</span>  
                        <span className='text-[16px] flex pb-3'><img className= 'w-[35px] h-[35px] mr-5' src={exam} />Prepare your audience/group for any challenging event</span>
                        
                        
                        
                        
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizMaster