import React, { useState } from 'react'
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { IconButton, Tooltip } from '@mui/material';
import QuestionModal from './QuestionModal'
const QuestionMarkWithModal = () => {
    const [open, setopen] = useState(false)
    return (
        <div className='flex items-center justify-end'>
            <QuestionModal open={open} setOpen={setopen} />
            <Tooltip title="Help">
                <IconButton className='!bg-mylightyellow' onClick={() => setopen(!open)}>
                    <QuestionMarkOutlinedIcon fontSize='small' className='!text-white' />
                </IconButton>
            </Tooltip>
        </div >
    )
}

export default QuestionMarkWithModal