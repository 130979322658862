import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
import Boxes from './Boxes'
import SortingForm from './SortingForm'

const SortingClassification = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const { auth, question } = useSelector(s => s)
    const dispatch = useDispatch()
    const [statement, setstatement] = useState('')
    const [mainState, setmainState] = useState([])
    const addNewBox = (name, setnewName) => {
        const alreadyExistName = mainState.find(v => v.name === name)
        if (alreadyExistName) return dispatch({ type: globalConstants.ALERT, payload: { error: "Boxes name should be unique!" } })
        setmainState([
            ...mainState,
            {
                name: name,
                values: ['']
            }
        ])
        setnewName('')
    }
    const addNewInput = (index) => {
        let mainStateTemp = [...mainState];
        const updated = mainStateTemp.map((v, _i) => {
            if (_i === index) {
                return {
                    ...v,
                    values: [...v.values, '']
                }
            }
            return v;
        })
        setmainState(updated)
    }
    const fieldsOnChangeHandler = (e, index) => {
        const { name, value } = e.target
        let updated = [...mainState]
        const newUpdated = updated.map((_v, _i) => {
            if (_v.name === name) {
                const newValues = _v.values.map((__v, __i) => {
                    if (__i === index) {
                        return value;
                    }
                    return __v
                })
                return { ..._v, values: newValues }
            }
            return _v;
        })
        // console.log('new updated', newUpdated)
        setmainState(newUpdated)
    }
    const deleteBox = (index) => {
        const updated = [...mainState]
        updated.splice(index, 1)
        setmainState(updated)
    }
    const handleSubmit = async (arg) => {
        if (mainState.length === 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "No Box Is Created!" } })
        //
        let isZeroLength = false;
        mainState.forEach((v) => {
            if (v.values.length <= 1) {
                isZeroLength = true
            }
        })
        if (isZeroLength) {
            return dispatch({ type: globalConstants.ALERT, payload: { error: "Each Box should have atleast 2 filled field!" } })
        }
        let obj = {
            questionCategory: type,
            subject,
            statement,
        }
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: {
                    error: "kindly fill the question type!"
                }
            })
        }
        if (!(obj.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question statement!" }
            })
        }
        if (!(obj.subject).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly select the subject!" }
        })
        const _idFind = question.questionsCategories.find((v) => v.name === type)

        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id
        }
        let isEmpty = false;
        //validateion for empty fields in the boxes
        mainState.map((val, index) => {
            const found = val.values.find(v => v.trim() === '')
            if (found !== undefined) {
                isEmpty = true
            }

        })

        if (isEmpty) return dispatch({ type: globalConstants.ALERT, payload: { error: 'Boxes fields should not be empty!' } })
        //validateion for empty fields in the boxes
        obj.forSortingClassificationType = mainState;

        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        if (arg) {
            setmainState([])
        } else {
            setshowAddQuestions(false)
        }
    }
    const deleteField = (boxName, fieldIndex) => {
        const updated = mainState.map((value, index) => {
            if (value.name === boxName) {
                return {
                    ...value,
                    values: value.values.filter((v, i) => i !== fieldIndex)
                }
            }
            return value;
        })
        setmainState(updated)
    }
    useEffect(() => {
        if (showQuestinData) {
            //console.log('questine data==>', showQuestinData)
            setstatement(showQuestinData.statement)
            setmainState(showQuestinData.forSortingClassificationType)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <div>
            <div className='space-y-2'>
                <h1 className='text-bgblue font-bold'>Question Statement</h1>
                <input
                    className='border border-gray-400 py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    onChange={(e) => setstatement(e.target.value)}
                    placeholder='write question statement...'
                />
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <SortingForm addNewBox={addNewBox} />
            }
            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 mt-5'>
                {
                    mainState.map((val, index) => (
                        <Boxes deleteField={deleteField} deleteBox={deleteBox} fieldsOnChangeHandler={fieldsOnChangeHandler} addNewInput={addNewInput} key={index} val={val} index={index} />
                    ))
                }
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <div className='mt-10 flex items-center justify-end space-x-3'>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </div>
            }
        </div>
    )
}

export default SortingClassification