import { Avatar, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants } from '../../actions/appConstants'
import { changePasswordAction, updateProfileAction } from '../../actions/auth.actions'
import { imageUpload } from '../../utils/cloudinary.config'
import CreateIcon from '@mui/icons-material/Create';
import RefferalCodeModal from './RefferalCodeModal'
import { BsFillCameraFill } from 'react-icons/bs'
import countries from '../../assets/countries.json'
const ProfileC = () => {
    const [open, setOpen] = useState(false)
    const { auth } = useSelector(state => state)
    const dispatch = useDispatch()
    const [data, setdata] = useState(
        {
            changedImage: false,
            firstName: "",
            middleName: "",
            lastName: "",
            userName: "",
            email_number: "",
            new_password: "",
            address: "",
            city: "",
            state: "",
            cn_password: '',
            currentPassword: "",
            profileImage: "",
            idImg: '',
            idType: '',
            country : ""
        }
    )
    const { firstName, middleName,
        lastName, userName, email_number,
        new_password, address, state, cn_password,
        currentPassword,
        city, profileImage,
        idImg,
        idType,
        country,
        stripe_id, 
        phone_number
    } = data;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setdata({
            ...data,
            [name]: value
        })
    }
    const handleChangePassword = () => {

        if (!(currentPassword).trim())
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "current password is required!" }
            })
        if (!(new_password).trim())
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "new password is required!" }
            })
        if (new_password !== cn_password)
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "confirm password does not matched!" }
            })
        const obj = {
            oldPass: currentPassword,
            newPass: new_password
        }
        dispatch(changePasswordAction(obj))
            .then((val) => {
                if (val) setdata({ ...data, new_password: '', currentPassword: "", cn_password: "" })
            })
    }
    const handleImage = (e) => {
        const file = e.target.files[0]
        if (!file) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "no file selected!" }
            })
        }
        if (file['type'].split('/')[0] !== 'image') {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "you can only select image as you profile image" }
            })
        }
        setdata({
            ...data,
            profileImage: file,
            changedImage: true,
        })
        e.target.value = ''
    }
    const handleUpdateProfile = async () => {
        let obj = {
            firstName,
            middleName,
            lastName,
            userName,
            email_number,
            address,
            city,
            state,
            profileImage,
            country, 
            stripe_id : ""
        }
        if (!(firstName).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "first Name is requrid!" }
        })
        if (!(country).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "Country is requrid!" }
        })
        if (!(lastName).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "Last Name is requrid!" }
        })
        if (!(userName).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "username is requrid!" }
        })
        if (!(email_number).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "email_number is requrid!" }
        })
        if (!(address).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "address is requrid!" }
        })
        if (!(city).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "city is requrid!" }
        })
        if (!(state).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "state is requrid!" }
        })

        dispatch({
            type: globalConstants.ALERT,
            payload: { loading: true }
        })
        //handle uploading of the image and the ID type
        if (typeof idImg === 'object') {
            const idImgUrl = await imageUpload([idImg])
            if (idImgUrl.length === 0) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "error in white uploading image, kindly try again later" }
                })
            }
            obj.idImg = idImgUrl[0].url;
        }
        if (idType.trim()) {
            obj.idType = idType;
        }
        //handle uploading of the image and the ID type
        try {
            if (data.changedImage) {
                const updated = await imageUpload([data.profileImage])
                if (updated.length === 0) return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "no image uploaded!" }
                })
                obj = {
                    ...obj,
                    profileImage: updated[0].url
                }
            }
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        }

        dispatch(updateProfileAction(obj))
    }
    useEffect(() => {
        const _d = auth.data.user
        setdata({
            ...data,
            ..._d,
        })
    }, [auth.authenticate])
    const handleIdCardsChanges = (e) => {
        const { name } = e.target
        const file = e.target.files[0]
        if (!file) {
            e.target.value = ''
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "no file selected!" }
            })
        }
        if (file['type'].split('/')[0] !== 'image') {
            e.target.value = ''
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "you can only select image as you profile image" }
            })
        }
        setdata({
            ...data,
            [name]: file,
        })
        e.target.value = ''
    }
    return (
        <Cont>
            <Right>
                <div className='grid grid-cols-6 gap-4 mt-5'>
                    <div className='relative lg:col-span-6 col-span-6 flex flex-col items-center mb-5'>
                        <div className='relative'>
                            <Avatar
                                className=''
                                src={
                                    data.changedImage ?
                                        URL.createObjectURL(data.profileImage) :
                                        data.profileImage
                                }
                                alt=''
                                sx={{
                                    width: '130px',
                                    height: '130px'
                                }}
                            />
                            <div className='w-max absolute bottom-5 right-0'>
                                <div className='cursor-pointer relative flex items-center justify-center flex-col w-9  h-9 rounded-full bg-blue-700 overflow-hidden  mt-5 '>
                                    <CreateIcon fontSize='small' className='text-white' />
                                    <input onChange={handleImage} type='file' className='bg-red-500 absolute opacity-0 top-0 left-0 right-0 bottom-0 z-10 !cursor-pointer' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>First Name</h1>
                        <input
                            value={firstName}
                            onChange={handleChange}
                            name='firstName'
                            type='text'
                            className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your First Name'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>Middle Name</h1>
                        <input
                            value={middleName}
                            onChange={handleChange}
                            name='middleName'
                            type='text'
                            className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Middle Name'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>Last Name</h1>
                        <input
                            value={lastName}
                            onChange={handleChange}
                            name='lastName'
                            type='text'
                            className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Last Name'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>Username</h1>
                        <input
                            value={userName}
                            // onChange={handleChange}
                            name='userName'
                            type='text'
                            className='text-gray-400 border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Username'
                            disabled = {true}
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>Email</h1>
                        <input
                            disabled
                            value={email_number}
                            // onChange={handleChange}
                            name='email_number'
                            type='text'
                            className=' text-gray-400 border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Email/Number'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>Phone Number</h1>
                        <input
                            disabled
                            value={phone_number}
                            // onChange={handleChange}
                            name='email_number'
                            type='text'
                            className='text-gray-400 border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Email/Number'
                        />
                    </div>
                    <div className='col-span-12 lg:col-span-6'>
                        <h1 className='font-semibold text-xs'>Address</h1>
                        <input
                            value={address}
                            onChange={handleChange}
                            name='address'
                            type='text'
                            className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your Address'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>City</h1>
                        <input
                            value={city}
                            onChange={handleChange}
                            name='city'
                            type='text'
                            className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your City'
                        />
                    </div>
                    <div className='col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs'>State</h1>
                        <input
                            value={state}
                            onChange={handleChange}
                            name='state'
                            type='text'
                            className='outline-none border border-opacity-10 border-black w-full px-2 py-2 rounded bg-transparent'
                            placeholder='Enter Your State'
                        />
                    </div>
                    <div className='col-span-12 lg:col-span-6'>
                        <h1 className='font-semibold text-xs'>Country</h1>
                        <select value= {country} name="country" onChange={handleChange} className='w-full rounded px-2 py-2 outline-none border border-opacity-10 border-black'>
                            <option value="">Please Select a country</option>
                            {
                                countries.countries.map((country,index)=>(
                                    <option key={index} value={country.code}>{country.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    {/* KYC and IDentity Type for KYC */}
                    <div className='space-y-2 col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs capitalize'>ID Type*</h1>
                        <select
                            value={idType}
                            onChange={(e) => setdata({ ...data, idType: e.target.value })}
                            className='my__select w-full'
                        >
                            <option value=''>Select ID Type</option>
                            <option value='aadhar'>Aadhar</option>
                            <option value='pan'>Pan</option>
                            <option value='passport'>Passport</option>
                            <option value='driving license'>Driving license</option>
                        </select>
                    </div>
                    <div className='space-y-2 group cursor-pointer col-span-6 lg:col-span-3'>
                        <h1 className='font-semibold text-xs capitalize'>ID front side image*</h1>
                        <div className={`${idImg ? '' : 'border-dashed border border-green-700'} w-full relative h-36  `}>
                            {
                                typeof (idImg) === 'object' ?
                                    <img
                                        src={URL.createObjectURL(idImg)}
                                        className='w-full h-full object-cover'
                                    />
                                    :
                                    (typeof (idImg) === 'string' && idImg.trim()) &&
                                    <img
                                        src={idImg}
                                        className='w-full h-full object-cover'
                                    />

                            }
                            <div className={` ${idImg ? 'opacity-0 group-hover:opacity-100' : ''} w-max absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]`}>
                                <div className='relative  w-max '>
                                    <input onChange={(e) => {
                                        const file = e.target.files[0]
                                        if (!file) {
                                            return dispatch({
                                                type: globalConstants.ALERT,
                                                payload: { error: "no file selected!" }
                                            })
                                        }
                                        if (file['type'].split('/')[0] !== 'image') {
                                            return dispatch({
                                                type: globalConstants.ALERT,
                                                payload: { error: "you can only select image as you ID image" }
                                            })
                                        }
                                        setdata({
                                            ...data,
                                            idImg: file,
                                        })
                                        e.target.value = ''
                                    }} type='file' className='absolute w-full h-full opacity-0' />
                                    <BsFillCameraFill size={30} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* KYC and IDentity Type for KYC */}
                </div>
                <div className='flex mt-5'>
                    <button onClick={handleUpdateProfile} className='w-full bg-bgblue rounded py-1 px-3 shadow-2xl text-white'>Update Profile</button>
                </div>
            </Right>

            <Left>
                <div className='bg-white rounded-2xl  shadow-2xl px-5 py-5'>
                    <div className='space-y-3 mt-5'>
                        <div>
                            <h1 className='font-semibold text-xs'>Current Password</h1>
                            <input
                                value={currentPassword}
                                onChange={handleChange}
                                name='currentPassword'
                                type='password'
                                className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                                placeholder='Enter Your Current Password...'
                            />
                        </div>
                        <div>
                            <h1 className='font-semibold text-xs'>New Password</h1>
                            <input
                                value={new_password}
                                onChange={handleChange}
                                name='new_password'
                                placeholder='Ener Your New Password...'
                                type='password'
                                className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            />
                        </div>
                        <div>
                            <h1 className='font-semibold text-xs'>Confirm Password</h1>
                            <input
                                value={cn_password}
                                onChange={handleChange}
                                name='cn_password'
                                type='password'
                                placeholder='Enter Confirm Password!'
                                className='border-opacity-10 outline-none border border-black w-full px-2 py-2 rounded bg-transparent'
                            />
                        </div>
                    </div>
                    <div className='flex mt-5 '>
                        <button onClick={handleChangePassword} className=' bg-bgblue rounded py-1 px-3 text-white w-full'>Change Password</button>
                    </div>
                </div>
                {/* show reffaral code to copy */}
                <RefferalCodeModal
                    open={open}
                    setOpen={setOpen}
                    code={auth?.data?.user?.referalCode}
                />
            </Left>
        </Cont>
    )
}
const Right = styled.div`
${tw`lg:col-span-4 col-span-6 pl-5   px-5 bg-white rounded-2xl pb-5 shadow-2xl`}
`
const Left = styled.div`
${tw`lg:col-span-2 col-span-6  `}

`
const Cont = styled.div`
${tw`grid grid-cols-6 gap-4`}
`
export default ProfileC 