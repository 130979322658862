import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import TableRow from './TableRow'

const MyTable = ({ questions, setquestions }) => {
    const { auth } = useSelector(s => s)
    //console.log('played quizz data==>', questions)
    return (
        <div class="overflow-x-auto">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                <div class="w-full  overflow-auto">
                    <div class="bg-white  shadow-md rounded p-3">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <th class="py-3 px-6 text-left">Quiz title</th>
                                    <th class="py-3 px-6 text-left">Type</th>
                                    <th class="py-3 px-6 text-center">My Earnings</th>
                                    <th class="py-3 px-6 text-center">Tax on qzetos</th>
                                    <th class="py-3 px-6 text-center">Quiz Scheduler Earnings</th>
                                    <th class="py-3 px-6 text-left">Date</th>
                                    <th class="py-3 px-6 text-center">Start Time</th>
                                    <th class="py-3 px-6 text-center">End Time</th>
                                    <th class="py-3 px-6 text-center">Total Questions</th>
                                    <th class="py-3 px-6 text-center">Total Registered Users</th>
                                    <th class="py-3 px-6 text-center">Registration Fee</th>
                                    <th class="py-3 px-6 text-center">Quiz Category</th>
                                    <th class="py-3 px-6 text-center">Quiz Status</th>
                                    {/* {
                                        auth.data.user.isAdmin &&
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    } */}
                                    <th class="py-3 px-6 text-center">Quiz Detail</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    questions.length > 0 &&
                                    questions.map((val, index) => (
                                        <TableRow questions={questions} setquestions={setquestions} val={val} key={index} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTable