import React, { useEffect, useState } from 'react'
import axios from '../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import styled from 'styled-components'
// import AddSubscriptionModal from './AddSubscriptionModal'
import CircularProgress from '@mui/material/CircularProgress';
import QuizzCard from '../DashBoardComponets/QuizCards/QuizzCard';
import quizImg from '../../assets/staticdata/bg.png'
import { useNavigate } from 'react-router-dom';
const RegisteredQuizzes = () => {
    const [open, setopen] = useState(false)
    const { auth } = useSelector(s => s)
    const [laoding, setlaoding] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setlaoding(true)
                const res = await axios(`/getregisteredquizzes/${auth.data.user._id}?page=${page}&limit=${limit}`)
                if (res.status === 200) {
                    console.log('questions==>11', res.data)
                    setquestions(res.data.quizzes)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setlaoding(false)
            } catch (error) {
                setlaoding(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit])
    return (
        <>
            {
                laoding ?
                    <div className='flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {
                            questions.length > 0 ?
                                <>
                                    <Container>
                                        <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2  gap-4 mt-10'>
                                            {
                                                questions.map((val, index) => (
                                                    <QuizzCard quizz={val} key={index} user={auth.data.user._id} />
                                                ))
                                            }
                                        </div>
                                    </Container>
                                    <div className='flex justify-center px-5 pt-5'>
                                        <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                                    </div>
                                </>
                                :
                                <div className='w-full flex flex-col items-center'>
                                    <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                                    <div className='w-full flex flex-col items-center'>
                                        <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                            You haven't registered for any quiz yet, play quiz and get rewarded
                                        </p>
                                        <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/dashboard')}>Play Quiz</button>
                                    </div>
                                </div>
                            // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No quizz available...</div>
                        }
                    </>
            }
        </>
    )
}
const AdsContainer = styled.div`
`
const Container = styled.div`
`
export default RegisteredQuizzes