import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { varifyUser, resendCodeAction, resetPasswordAction } from '../../actions/auth.actions'
import BGImage from '../../assets/bg/result.svg'
import Logo from '../../assets/logo/logo.png'
import PhoneInput from 'react-phone-number-input'
const ResetPasswordRequest = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const extras = useSelector(state => state.extras)
    const [formData, setformDAta] = useState({
        email_number: ""
    })
    const [option, setOption] = useState('email')
    const [value , setValue] = useState()
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformDAta({
            ...formData,
            [name]: value
        })
    }
    useEffect(()=>{
        setformDAta({...formData , value})
    }, [value])
    const handleSubmit = (e) => {
        e.preventDefault()
        setformDAta({...formData , value})
        dispatch(resetPasswordAction(formData, navigate))
        // console.log('form data==>', formData)
    }
    const handleResendCode = () => {
        // if (extras.extraLoginData === null) {
        //     return navigate('/login', { replace: true })
        // }
        // dispatch(resendCodeAction(extras.extraLoginData))
    }
    return (
        <Container BGImage={BGImage}>
            <MinCont>
                <div className='flex items-center justify-center my-6'>
                    <img onClick={() => navigate('/')} className='w-[12rem] object-contain cursor-pointer' src={Logo} alt='' />
                </div>
                <h1 className='text-xl !mt-0 text-center capitalize font-semibold'>reset password</h1>
                <form className='space-y-7' onSubmit={handleSubmit}>
                    <div className="w-[90%] ml-auto mr-auto flex flex-row items-center">

                        <input className='mr-3' type="radio" value="email" checked= {option ==='email' ? true : false} onClick={() => { setOption('email') }} />
                        <label className='mr-3'>Reset by e-Mail</label>

                        <input className='mr-3' type="radio" value="number" checked= {option ==='number' ? true : false}onClick={() => { setOption('number') }} />
                        <label htmlFor="">Reset by Phone</label>
                    </div>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <h1 className='font-bold capitalize'>{`Enter your ${option}`}</h1>

                        {option === "email" ? <input
                            value={formData.email_number}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder={`Enter your ${option} ...`}
                            name='email_number'
                            onChange={onChangeHandler}
                            type='text'
                            required
                        /> :
                            <PhoneInput
                                international
                                countryCallingCodeEditable={true}
                                limitMaxLength={true}
                                placeholder="Enter your phone no"
                                defaultCountry="IN"
                                name="phone_number"
                                value={value}
                                onChange={setValue}
                            />
                        }
                    </div>
                    <div className='bg-blue-700 text-white border-blue-600 relative  w-[90%] ml-auto mr-auto border p-5 rounded-md cursor-pointer'>
                        <button type='submit' className='absolute top-0 left-0 bottom-0 right-0 font-bold text-center'>Verify</button>
                    </div>
                </form>
            </MinCont>
        </Container>
    )
}
const MinCont = styled.div`
${tw`lg:w-[30%] w-[95%] bg-white space-y-10 p-2 rounded-xl`}
`
const Container = styled.div`
${tw`relative h-screen flex items-center justify-center`}
background-image: url(${BGImage}) ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover;
`
export default ResetPasswordRequest