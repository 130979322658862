import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ScheduleTable from './ScheduleTable/ScheduleTable'
const UserScheduledQuizezC = () => {
  const { uid } = useParams()
  const [userId, setuserId] = useState('')
  useEffect(() => {
    if (uid)
      setuserId(uid)
  }, [uid])
  return (
    <ScheduleTable userId={userId} />
  )
}

export default UserScheduledQuizezC