import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import axios from '../utils/myAxios'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../actions/appConstants'
import { CircularProgress } from '@mui/material'
import Section from '../components/quizzesWithDifferentSubjectC/Section'
import { useNavigate } from 'react-router-dom'
import quizImg from '../assets/staticdata/quiz-library.png'
const QuizzesWithDifferentSubjectsPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [category, setcategory] = useState('')
    const [quizzes, setquizzes] = useState([])
    const [loading, setloading] = useState(false)
    const { cid } = useParams()
    useEffect(async () => {
        if (cid) {
            try {
                setloading(true)
                setcategory(cid)
                const res = await axios.get(`/get/past/quizzes/with/differentsubjects/${cid}`)
                setquizzes(res.data.data)
                setloading(false)
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    
    }, [cid])
    return (
        <Layout>
            {
                loading ?
                    <div className='flex items-center justify-center mt-5'>
                        <CircularProgress />
                    </div>
                    :
                    quizzes.length > 0 ?
                        <div>
                            {
                                quizzes.map((val, index) => (
                                    <Section
                                        isPastQuiz={true}
                                        cid={category}
                                        key={index}
                                        val={val}
                                    />
                                ))
                            }
                        </div>
                        :
                        // <NotFoundDataUi text={`No Quizzes Available`} />
                        <div className='w-full flex flex-col items-center'>
                            <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                            <div className='w-full flex flex-col items-center'>
                                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                    No quiz to show, Why dont you become our quizmaster and schedule a quiz for others & get rewarded.
                                </p>
                                <button 
                                className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' 
                                onClick={()=>navigate('/schedulequiz')}>
                                    Schedule Quiz</button>
                            </div>
                        </div>
            }
        </Layout>
    )
}

export default QuizzesWithDifferentSubjectsPage