import React, { useState } from "react";
import StatisticsTab from "../components/DashBoardComponets/StatisticsTab/StatisticsTab";
import Layout from "./../components/Layout/Layout";
import StaticCategoriesC from "../components/staticCategoriesC/StaticCategoriesC";

const DashBoard = () => {
  return (
    <Layout>
      <StatisticsTab />
      {/* <QuizCards /> */}
      {/* <MainTabs /> */}
      <StaticCategoriesC />
    </Layout>
  );
};

export default DashBoard;
