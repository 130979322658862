import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lifeLinesConstants } from '../../../actions/appConstants'
import { updateLifeLines } from '../../../actions/lifeLines.actions'
import fiftyFifty from '../../../assets/lifelines/fiftyfifty.png'
import correctAnswer from '../../../assets/lifelines/correctAnswer.png'
const LifeLinesC = ({ qid }) => {

  const { lifeLines } = useSelector(s => s)
  const {
    showFifityFiftyLife,
    showCorrectAnswerLife,
    showSkipQuestionLife,


    clickedFiftyFifty,
    clickedShowCorrectAnswer,
    clickedSkipQuestion,
  } = lifeLines
  const dispatch = useDispatch()
  const handleFiftyfiftyClick = () => {
    if (lifeLines?.lifeLines?.fiftyfifty > 0) {
      dispatch({
        type: lifeLinesConstants.FIFTY_FIFTY,
        payload: true
      })
      dispatch(updateLifeLines(qid))
    }
  }
  const handleShowCorrectAnswer = async () => {
    if (lifeLines?.lifeLines?.showCorrectAnswer > 0) {
      dispatch({
        type: lifeLinesConstants.SHOW_CORRECT_ANSWER,
        payload: true
      })
      dispatch(updateLifeLines(qid))
    }
  }
  const handleSkipQuestion = () => {
    if (lifeLines?.lifeLines?.skipQuestion > 0) {
      dispatch({
        type: lifeLinesConstants.SKIP_QUESTION,
        payload: true
      })
    }
  }
  return (
    <div className='flex items-center flex-wrap gap-2 mt-2 ml-3'>
      {
        (showFifityFiftyLife && lifeLines?.lifeLines?.fiftyfifty > 0) &&
        <div onClick={handleFiftyfiftyClick} className='relative flex items-center space-x-4 border border-indigo-500 rounded-full p-3 w-max cursor-pointer hover:bg-gray-300'>
          <img src={fiftyFifty} alt="fiftyfifty"
            className='w-8 h-8 object-contain'
          />
          {/* <span class="animate-ping absolute inline-flex h-[50%] w-[50%] rounded-full bg-indigo-400 opacity-75"></span> */}
          <spna className='font-bold text-sm'>50-50</spna>
        </div>
      }
      {
        (showCorrectAnswerLife && lifeLines?.lifeLines?.showCorrectAnswer > 0) &&
        <div onClick={handleShowCorrectAnswer} className='relative flex items-center space-x-4 border border-yellow-500 rounded-full p-3 w-max cursor-pointer hover:bg-gray-300'>
          <img src={correctAnswer} alt="fiftyfifty"
            className='w-8 h-8 object-contain'
          />
          {/* <span class="animate-ping -translate-x-[50%] -translate-y-[50%] top-[50%] left-[50%]  absolute inline-flex h-[50%] w-[50%] rounded-full bg-yellow-400 opacity-75"></span> */}
          <spna className='font-bold text-sm'>Show Correct Answer { }</spna>
        </div>
      }
      {
        (showSkipQuestionLife && (clickedFiftyFifty === false && clickedFiftyFifty === false)) &&
        <div onClick={handleSkipQuestion} className='flex items-center space-x-4 border border-gray-500 rounded-full p-3 w-max cursor-pointer hover:bg-gray-300'>
          <img src={fiftyFifty} alt="fiftyfifty"
            className='w-8 h-8 object-contain'
          />
          <spna className='font-bold text-sm'>Skip Question {lifeLines?.lifeLines?.skipQuestion}</spna>
        </div>
      }
    </div>
  )
}

export default LifeLinesC