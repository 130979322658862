import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
const ListItems = ({ val, handleDelete, type, showQuestinData }) => {
    return (
        <div className='relative border border-r-gray-300 rounded-md p-3'>
            <IconButton onClick={() => handleDelete(val, type)} className='!absolute top-0 right-0 '>
                <CloseOutlinedIcon />
            </IconButton>
            <h1 className='text-center text-bgblue font-extrabold'>{val.name}</h1>
            <div className='flex items-center justify-center'>
                {
                    typeof (val.file) === 'string' ?
                        val.file.includes('/image/')
                        &&
                        <img
                            className='w-[100px] h-[100px] object-contain'
                            src={val.file}
                            alt=''
                        />
                        :
                        null
                }
                {
                    typeof (val.file) === 'object' ?
                        < img
                            className='w-[100px] h-[100px] object-contain'
                            src={URL.createObjectURL(val.file)}
                            alt=''
                        />
                        :
                        null
                }
            </div>
        </div>
    )
}

export default ListItems