import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { Link, useNavigate } from 'react-router-dom';
import QuizzingBoxLogo from "../../../assets/logo/logo.png"
import Logo from '../../../assets/logo/whitelogo.png'
import bannerImage from '../../../assets/staticdata/bannerImage.png'
// import Logo from '../../../assets/logo/whitelogo.png'
import { useDispatch, useSelector } from 'react-redux';
import { extrasConstancts } from '../../../actions/appConstants';

const preventDefault = (event) => event.preventDefault();

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 25px',
  border: '1px solid',

  lineHeight: 1.5,
  color: 'white',
  backgroundColor: '#F89820',
  borderColor: '#F89820',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});



const Header = () => {
  const [openPopUp, setOpenPopUp] = useState(false)
  const dispatch = useDispatch()
  const [background, setBackground] = useState('none');
  const { auth } = useSelector(s => s)
  const navigate = useNavigate()
  const profile = document.querySelector(".top_banner"); 
  const sectionOptions = {
      rootMargin : "-200px 0px 0px 0px"
  }
  const sectionOneObserver = new IntersectionObserver((enteries, sectionOneObserver)=>{
      enteries.forEach(entry=>{
        if(!entry.isIntersecting)
        {
          setBackground("white")
        }
        else{
          setBackground('white')
        }
      })
  }, sectionOptions)
  profile&&sectionOneObserver.observe(profile)
  return (
    <>
      <div >
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <AppBar elevation={0} sx={{ color: 'black' , background , position : "static" }}  >
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                >
                </IconButton>
                <Typography sx={{ width: "50%" }} variant='h6'>
                  <Link to="/" ><img src={QuizzingBoxLogo} className='object-contain w-[14rem]' /></Link>
                </Typography>
                {
                  auth.authenticate ?
                    <div className='flex items-center space-x-2 justify-end ml-auto'>
                      <span
                        onClick={() => navigate('/quizmasterpro/quizzes')}
                        className='hover:bg-[#ffb42e] hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer 
                        '
                      >
                        Quiz Master Pro
                      </span>
                      <span
                        onClick={() => navigate('/static/howitworks')}
                        className='hover:bg-[#ffb42e] hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer  
                        '
                      >
                        How It Works
                      </span>
                      <Link
                        to={'/dashboard'}
                        className='hover:bg-[#ffb42e] hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer '>
                        Dashboard
                      </Link>
                    </div>
                    :
                    <div className='flex items-center space-x-5 justify-end ml-auto'>
                       <span
                        onClick={() => navigate('/quizmasterpro/quizzes')}
                        className='hover:bg-[#ffb42e] hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer 
                        '
                      >
                        Quiz Master Pro
                      </span>
                      <button onClick={() => navigate('/static/howitworks')}
                        className='hover:bg-[#ffb42e]  hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer 
                        '
                      >
                        How it Works
                      </button>
                      <button
                        onClick={() => dispatch({
                          type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                          payload: true
                        })}
                        className='hover:bg-[#ffb42e] hover:border-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer '
                      >
                        Register/Login
                      </button>
                      {/* <button
                        onClick={() => dispatch({
                          type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                          payload: true
                        })}
                        className=' hover:bg-[#ffb42e] whitespace-nowrap  text-sm  hover:text-white py-2 font-[600] tracking-widest px-4 
                        rounded-[2rem]  bg-white text-[#223344] border border-[#223344] cursor-pointer '>
                          Log In
                      </button> */}
                    </div>
                }
              </Toolbar>
            </AppBar>
          </Box>
        </Container>
      </div>
    </>

  )
}

export default Header
