import React, { useState } from 'react'
import moment from 'moment'
// import TableModal from './TableModal'
import { approveQuestionAction, rejectQuestionAction } from '../../actions/question.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const TableRow = ({ val, questions, setquestions }) => {
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const disaptch = useDispatch()
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleApprove = () => {
        // console.log(val)
        disaptch(approveQuestionAction(val._id)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleReject = () => {
        // console.log(val)
        disaptch(rejectQuestionAction(val._id)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    //console.log('user1==>', val)
    //console.log('user2==>', auth?.data?.user?._id)
    return (
        <>
            {/* <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} /> */}
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val?.quizzId?.title}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span className='capitalize'>{val?.quizzId?.type}</span>
                </td>
                {/* my earnings */}
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    {
                        val?.quizzId?.status === 'closed'
                            ?
                            <span>{(val.playerEarning).toFixed(0)} Qzeto's</span>
                            :
                            <span className='rounded-xl py-1 px-2 text-xs bg-red-500 text-white'>Pending</span>
                    }
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    {
                        (val?.taxQzetos).toFixed(0)
                    }
                </td>
                {/* my earnings */}
                {/* quiz scheduler earning */}
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    {
                        ((auth?.data?.user?._id === val?.quizzId.createdBy) || auth.data.user.isAdmin)
                            ?
                            <span>{val?.createrPercent ? (val?.createrPercent).toFixed(0) : 0}</span>
                            :
                            <span>----</span>
                    }
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val?.quizzId?.Date).format("MMM Do YYYY")}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val?.quizzId?.time).format("MMMM Do YYYY, h:mm:ss a")}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    {
                        val.isDurationBound ?
                            moment(val.durationBoundEndTime).format("MMMM Do YYYY, h:mm:ss a")
                            :
                            moment(val.durationDateComplete).format("MMMM Do YYYY, h:mm:ss a")
                    }
                    {/* <span>{moment(val?.quizzId?.durationDateComplete).format('LT')}</span> */}
                </td>
                <td class="py-3 px-6  whitespace-nowrap text-center">
                    <span >{val?.totalQuestions}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    <span>{val?.quizzId?.registerdUsers?.length}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    <span>{val?.quizzId?.type === 'free' ? `${val?.quizzId?.requiredPointsForFree} Qzetos` : `${val?.quizzId?.price} Qzetos`}</span>
                </td>

                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    <span>{val?.quizzId?.category?.name.replace(/_/g, " ")}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    <span className=' bg-red-400  rounded-xl text-white text-xs py-1 px-2'>{val?.quizzId?.status}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap ">
                    <button onClick={() => navigate(`/singlequizz/detailscreen/${val.quizzId._id}`)} type='button' className='rounded-md text-white bg-bgblue py-1 px-2'>View Quiz</button>
                </td>
            </tr>
        </>
    )
}

export default TableRow