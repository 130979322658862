import axios from '../utils/myAxios'
import { globalConstants, subjectConstants } from './appConstants'


export const getAllSubjects = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get('/getallsubjects')
            dispatch({
                type: subjectConstants.GET_ALL_SUBJECTS,
                payload: res.data.subjects
            })
        } catch (error) {
            // console.log('error==>', error)
            // console.log('error==>', error.response)

            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const createSubjectAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/createsubject', {
                ...data,
            })
            console.log('new subject==>', res.data.subject)
            dispatch({
                type: subjectConstants.CREATE_SUBJECT,
                payload: res.data.subject
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "subject created successfully!" }
            })
            return true;
        } catch (error) {
            // console.log('error==>', error)
            // console.log('error==>', error.response)

            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const deleteSubjectAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.delete(`/deletesubject/${id}`)
            dispatch({
                type: subjectConstants.DELETE_SUBJECT,
                payload: id
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            return true;
        } catch (error) {
            // console.log('error==>', error)
            // console.log('error==>', error.response)

            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const updateSubjectAction = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post(`/updatesubject/${data.id}`, {
                ...data
            })
            dispatch({
                type: subjectConstants.UPDATE_SUBJECT,
                payload: res.data.subject
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            return true;
        } catch (error) {
            // console.log('error==>', error)
            // console.log('error==>', error.response)

            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}