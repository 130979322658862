import { authConstants, extrasConstancts } from "../actions/appConstants";
import { homeModalConstants } from "../actions/extras.actions";

const inititalState = {
    varifyuser: '',
    extraLoginData: null,
    keepUserLoginToken: "",
    HomeModalData: {
        show: false,
        earning: 0,
        earningtype: '',
    },
    showBuyQzetosModal: false,
    boughtSchoolQuizSubscription: false,
    buySchoolQuizScriptionModal: false,

    showSignInModal: false,
    showSigUpModal: false,


    showBuyInfoModal: {
        open: false,
        title: '',
        type: '',
        description: "",
        numbers: 0,
    },
    showSubmissionModal: {
        open: false,
        user: ''
    }
}


const extras = (state = inititalState, action) => {
    switch (action.type) {
        case authConstants.VARIFY_USER_TOKEN:
            state = {
                ...state,
                varifyuser: action.payload
            }
            break;
        case authConstants.EXTRAS_LOGIN_DATA:
            state = {
                ...state,
                extraLoginData: action.payload
            }
            break;
        case homeModalConstants.SHOW_HOME_MODAL:
            state = {
                ...state,
                HomeModalData: action.payload
            }
            break;
        case extrasConstancts.SHOW_BUY_QZETO_MODAL:
            
            state = {
                ...state,
                showBuyQzetosModal: action.payload,
            }
            break;
        case extrasConstancts.IS_BOUGHT_SCHOOL_QUIZ_SUBSCRIPTION:
            state = {
                ...state,
                boughtSchoolQuizSubscription: action.payload,
            }
            break;
        case extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION:
            state = {
                ...state,
                buySchoolQuizScriptionModal: action.payload
            }
            break;
        case extrasConstancts.SHOW_SIGN_IN_MODAL:
            state = {
                ...state,
                showSignInModal: action.payload,
            }
            break;
        case extrasConstancts.SHOW_SIGN_UP_MODAL:
            state = {
                ...state,
                showSigUpModal: action.payload,
            }
            break;
        case extrasConstancts.SHOW_BUY_INFO_MODAL:
            state = {
                ...state,
                showBuyInfoModal: action.payload,
            }
            break;
        case extrasConstancts.HIDE_BUY_INFO_MODAL:
            state = {
                ...state,
                showBuyInfoModal: {
                    open: false,
                    title: '',
                    type: '',
                    numbers: 0,
                    type: ''
                }
            }
            break;
        case extrasConstancts.SHOW_SUBMISSION_MODAL:
            console.log(action.payload)
            state = { ...state, showSubmissionModal: action.payload }
            break
    }
    return state;
}
export default extras;