import React from 'react'
import Layout from '../components/Layout/Layout'
import RegisteredQuizzes from '../components/registeredQuizzesComponents/RegisteredQuizC'
const RegisteredQuizzesScreen = () => {
    return (
        <Layout>
            <RegisteredQuizzes />
        </Layout>
    )
}

export default RegisteredQuizzesScreen 