import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'

const SentenceSequence = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const { auth, question } = useSelector(s => s)
    const dispatch = useDispatch()
    const [statement, setstatement] = useState('')
    const [actualQuestion, setactualQuestion] = useState('')
    const handleSubmit = async (arg) => {
        let obj = {
            questionCategory: type,
            subject,
            statement,
            correctAnswer: actualQuestion
        }
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: {
                    error: "kindly fill the question type!"
                }
            })
        }
        if (!(obj.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill  statement!" }
            })
        }
        if (!(obj.correctAnswer).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly fill sentence!" }
        })
        if (!(obj.subject).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly select the subject!" }
        })
        const _idFind = question.questionsCategories.find((v) => v.name === type)

        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id
        }
        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        if (arg) {
            setactualQuestion('')
            setstatement('')
        } else {
            setshowAddQuestions(false)
        }
    }
    useEffect(() => {
        if (showQuestinData) {
            setstatement(showQuestinData.statement)
            setactualQuestion(showQuestinData.correctAnswer)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <div className='mt-5'>
            <div className='space-y-2'>
                <h1 className='text-bgblue font-bold'>Statement</h1>
                <input
                    className='border border-gray-300 py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    onChange={(e) => setstatement(e.target.value)}
                    placeholder='statement...'
                />
            </div>
            <div className='space-y-2 mt-5'>
                <h1 className='text-bgblue font-bold'>Write your sentence</h1>
                <input
                    className='border border-gray-300 py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={actualQuestion}
                    onChange={(e) => setactualQuestion(e.target.value)}
                    placeholder='write your sentence...'
                />
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <div className='flex items-center justify-end mt-16 space-x-2'>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </div>
            }
        </div>
    )
}

export default SentenceSequence