import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CardModal from './CardModal'
import axios from '../../../utils/myAxios'
//icons

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { unRegisterQuizzAction } from '../../../actions/quizzSchedule.actions'
import { useNavigate } from 'react-router-dom'
import { globalConstants, fron_end_url_server } from '../../../actions/appConstants'
import { SiBuymeacoffee } from 'react-icons/si'
import { keepUserLoggedIn } from '../../../actions/auth.actions';

//icons
import first from '../../../assets/prizez/1.svg'
import second from '../../../assets/prizez/2.svg'
import thrid from '../../../assets/prizez/3.svg'
//react-icons


//images..




//images..
//react-icons
const Icons = ({ total, icon, color, price }) => {
    return (
        <div className='flex items-center gap-x-3'>
            <Avatar className={`!text-white `} style={{
                background: color ? color : ''
            }}
                sx={{ width: 24, height: 24 }}
            >
                {icon}
            </Avatar>
            <h1 className='text-xs whitespace-pre opacity-50 !text-black font-bold '>{price ? `$ ${total}` : total}</h1>
        </div>
    )
}
//profile image style
//


//random colors
const randColors = [
    'bg-red-600', 'bg-blue-600', 'bg-orange-600', 'bg-lime-600', 'bg-indigo-600',
    'bg-fuchsia-600', 'bg-purple-600', 'bg-violet-600', 'bg-cyan-600',
]
//random colors
const QuizzCard = ({
    quizz, user, handleUpdatedQuizz, index, setquestions, questions,
    isArchive = false,
    handleArchive,
    isSchoolQuiz = false,
    hidelayout = false,
    isUser = false,
}) => {
    
    const [timeForCard, settimeForCard] = useState([])
    const [btnText, setBtnText] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setopen] = useState(false)
    const prize = Number(quizz.registerdUsers.length *  quizz.type === "paid" ? quizz.price : quizz.requiredPointsForFree) - 10
    let firstPrize = prize * 0.5
    let secondPrize = prize * 0.3
    let thirdPrize = prize * 0.2


    const [bgState, setBgState] = useState(false)


    const [timer, settimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    const cardInit = {
        play: false,
        timesup: false,
        register: false,
        unregister: false,
    }
    const [cardStates, setcardStates] = useState(cardInit)
    const { auth } = useSelector(state => state)
    const [image, setImage] = useState(auth.data.user.profileImage)
    const [dummy, setDummy] = useState('https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8dGVjaG5vbG9neXxlbnwwfDB8MHx8&auto=format&fit=crop&w=600&q=60')
    const [bgImage, setBgImage] = useState('')
    useEffect(() => {
        const getCatImage = () => {
            axios.get(`/getsubject/${quizz.subject}`)
                .then(body => {
                    setBgImage(body.data.image)
                })
                .catch(err => err)//console.log(err))
        }
        getCatImage()
    }, [quizz])
    useEffect(async () => {
        const t = setTimeout(async () => {
            const fun = () => {
                let _tempUpdatingTime = new Date(quizz.time)
                let _tempDate = new Date(quizz.date)
                _tempUpdatingTime.setDate(_tempDate.getDate())
                _tempUpdatingTime.setMonth(_tempDate.getMonth())
                _tempUpdatingTime.setFullYear(_tempDate.getFullYear())
                const _newUpdatedTime = new Date(_tempUpdatingTime)
                const str2 = _newUpdatedTime;
                const str1 = new Date()
                var diff = Date.parse(str2) - Date.parse(str1);
                return isNaN(diff) ? NaN : {
                    diff: diff,
                    ms: Math.floor(diff % 1000),
                    s: Math.floor(diff / 1000 % 60),
                    m: Math.floor(diff / 60000 % 60),
                    h: Math.floor(diff / 3600000 % 24),
                    d: Math.floor(diff / 86400000)
                }
            }
            const d = fun()
            // console.log('timer==>', d)
            settimer({
                days: d?.d >= 0 ? d.d : 0,
                hours: d?.h >= 0 ? d.h : 0,
                minutes: d?.m >= 0 ? d.m : 0,
                seconds: d?.s >= 0 ? d.s : 0
            })
            //for reistered and times up or time of playing
            if (d.d <= 0 && d.h <= 0 && d.m <= 0 && d.s <= 0) {
                
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    
                    const s_time = new Date(quizz.time)
                    if (quizz.isDurationBound) {
                        //add the quiz end_time of 1 Day in the start time for `DURATINO BOUND TYPE QUIZ`
                        s_time.setDate(s_time.getDate() + 1)
                    } else {
                        s_time.setMinutes(s_time.getMinutes() + Number(quizz.duration))
                    }
                    const c_time = new Date().getTime()
                    if (s_time.getTime() < c_time) {
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    } else {
                        try {
                            if (quizz.resheduleAllow) {
                                // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            } else {
                                setcardStates({
                                    ...cardInit,
                                    play: true
                                })
                            }
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    }
                } else {
                    if (quizz.resheduleAllow) {
                        try {
                            // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                            // handleUpdatedQuizz(_res_res.data.quizz)
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    } else {
                        try {
                            if (quizz.status !== 'closed' && quizz.isSchoolTypeQuizz) {
                                // const its_obj = { status: 'closed' }
                                // const _res_res = await axios.post(`/update/quizzstatus/${quizz._id}`, {
                                //     ...its_obj
                                // })
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            }
                        } catch (error) {
                            // dispatch({
                            //     type: globalConstants.ALERT,
                            //     payload: { error: error.message }
                            // })
                        }
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    }
                }
            }
            //for reistered and times up or time of playing

            //for registered unregistered
            if (d.d > 0 || d.h > 0 || d.m > 0 || d.s > 0) {
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    setcardStates({
                        ...cardInit,
                        unregister: true
                    })
                } else {
                    setcardStates({
                        ...cardInit,
                        register: true
                    })
                }
            }
            //for registered unregistered
            //increase the days if the minimum numbers of people are not matched
        }, 1000);
        return () => clearInterval(t)
    }, [timer])
    useEffect(() => {

    }, [bgImage])
    const handleRegist = () => {
        setopen(true)
    }
    const handleUnRegister = async () => {
        //console.log('handleregister clicked')
        await dispatch(unRegisterQuizzAction({ quizzId: quizz._id, quizz }, handleUpdatedQuizz))
    }
    const handleNavigate = () => {
        if (quizz.isSchoolTypeQuizz === true) {
            if (hidelayout) {
                navigate(`/singlequizz/detailscreen/${quizz._id}`, { state: { isSchoolQuiz: true, hidelayout: true } })
                return
            } else {
                navigate(`/singlequizz/detailscreen/${quizz._id}`, { state: { isSchoolQuiz: true } })
                return;
            }
        }
        navigate(`/singlequizz/detailscreen/${quizz._id}`)
    }
    const breakDate = (time) => {

    }
    useEffect(() => {
        if (quizz) {
            const toReturn = moment(quizz.time).format(' Do MMM').split(' ')
            settimeForCard(toReturn)
        }
    }, [quizz])
    const handlePlayOnClick = async () => {
        await dispatch(keepUserLoggedIn())
        if (quizz.isSchoolTypeQuizz === false) {
            try {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { loading: true }
                })
                const _obj = {
                    qid: quizz._id
                }
                const res = await axios.post('/check/minimumnumberofusers/forrefunds', { ..._obj })
                //console.log('res,registered users..==>', res)
                if (res?.data?.okay) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { loading: false }
                    })
                    navigate(`/playquizz/${quizz._id}`, { state: { user: user, isPastQuiz: false } })
                } else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: 'Quiz is cancelled due to minimum number of registered users not met!' }
                    })
                }
            } catch (error) {
                if (error?.response?.data?.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error?.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        } else {
            navigate(`/playquizz/${quizz._id}`, { state: { user: user, isPastQuiz: false } })
        }

    }
    const handleUnArchiveQuiz = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _obj = {
                status: "open"
            }
            const res = await axios.post(`/quizz/scheduler/archive/${quizz._id}`, {
                ..._obj
            })
            //remove from quizzes after unarchiving
            setquestions(q => q.filter((_q) => _q._id !== res.data.quiz._id))
            //remove from quizzes after unarchiving
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const handleArchiveQuiz = async () => {
        try {

            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _obj = {
                status: "archive"
            }
            const res = await axios.post(`/quizz/scheduler/archive/${quizz._id}`, {
                ..._obj
            })
            // handleUpdatedQuizz(res.data.quiz)
            //remove from quizzes after archiving
            if (handleArchive) {
                handleArchive(res.data.quiz)
            } else {
                setquestions(q => q.filter((_q) => _q._id !== res.data.quiz._id))
            }
            //remove from quizzes after archiving
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    useEffect(() => {

        if (quizz.createdBy?.followers?.findIndex((u) => u === user) !== -1) {
            setBtnText("Unfollow")

        }
        else {
            setBtnText("Follow")
        }
    }, [])
    const handleFollow = async () => {
        try {

            if (quizz.createdBy?.followers?.findIndex((u) => u === user) !== -1 || btnText === "UnFollow") {
                let res = await axios.post('/follower/remove', {
                    user: user,
                    f_user: quizz.createdBy._id
                })
                if (res) {

                    setBtnText("Follow")
                }

            }
            else {
                const res2 = await axios.post('/follower/add', {
                    user: user,
                    f_user: quizz.createdBy
                })
                if (res2) {

                    setBtnText("UnFollow")
                }



            }

        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className='max-w-[300px] h-full hover:scale-105 transform transition-all duration-300'>
            {quizz?.subject && <div className="card relative h-full" >

                <CardModal handleUpdatedQuizz={handleUpdatedQuizz} open={open} setopen={setopen} quizz={quizz} />
                <div className="half_1 relative z-10" style={{ backgroundImage: `url(${bgImage ? bgImage : dummy})` }}>
                    <div className='w-full flex flex-col justify-center px-1'>


                        <div className="w-full flex justify-end">
                            {/* <button className='w-16 h-6 bg-yellow-400 border rounded-full text-[10px] text-white' onClick={() => { handleFollow() }}>{btnText}</button> */}
                            {
                            (auth.data.user.isAdmin === true && !isUser) &&
                            (
                                quizz.status === 'archive'
                                    ?
                                    <button
                                        onClick={handleUnArchiveQuiz}
                                        className='h-6 flex items-center justify-center rounded-3xl bg-red-400 text-white text-[10px] px-2'>UnArchive Quiz</button>
                                    :
                                    <button
                                        onClick={handleArchiveQuiz}
                                        className='absolute top-1 right-2 h-6 flex items-center justify-center rounded-3xl bg-red-400 text-white text-[10px] px-2'>Archive Quiz</button>
                            )
                        }
                        </div>
                        

                        <div className="flex w-full justify-center">
                            <Avatar

                                src={image}
                                sx={{ width: 55, height: 55 }}
                                onClick={() => { navigate(`/following/profile/${quizz.createdBy._id}`) }}
                                className='cursor-pointer ring ring-green-400 ring-offset-2 absolute top-[60%] '
                            />
                        </div>

                    </div>


                </div>
                <div className="half_2 pt-8 rounded-xl bg-white relative cursor-pointer" onClick={handleNavigate} >
                    <h2 className='cursor-pointer text-sm text-center' onClick={handleNavigate}>Quiz Name : {quizz.title}</h2>
                    {/* <h5 className='cursor-pointer text-xs' onClick={handleNavigate}>{quizz.category?.name?.replace("_", " ")}</h5> */}
                    {/* <ul className='cursor-pointer text-xs' onClick={handleNavigate}>
                        <li><h4>{moment(quizz.time).format(" h:mm a")}</h4><span>Start Time</span></li>
                        <li><h4>
                            {
                                quizz.isDurationBound ?
                                    moment(quizz.durationBoundEndTime).format(" h:mm a")
                                    :
                                    moment(quizz.durationDateComplete).format("h:mm a")
                            }
                        </h4><span>End Time</span></li>
                    </ul> */}
                    <span className="text-xs hover:underline-offset-1"><strong>Quizmaster</strong> : {quizz.createdBy.userName}</span>

                    {!isSchoolQuiz && <div className='grid grid-cols-3 px-5 text-[#BD5E00] font-semibold h-[60px] mt-2'>
                        <div className='justify-self-end self-end relative'>
                            <img src={second} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{secondPrize > 0 ? secondPrize.toFixed(0) : 0  }</p>
                        </div>
                        
                        <div className='justify-self-start self-start relative'>
                            <img src={first} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{firstPrize > 0 ? firstPrize.toFixed(0) : 0}</p>
                        </div>
                        <div className='justify-self-end self-end relative'>
                            <img src={thrid} alt="" className='w-full ' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{thirdPrize > 0 ? thirdPrize.toFixed(0) : 0}</p>
                        </div>
                    </div>}

                    <div className='w-full flex justify-evenly my-3'>

                        {
                            cardStates.play ? (<><div className="absolute top-2 left-2 px-2 h-6 flex items-center justify-center rounded-3xl bg-green-400 text-white cursor-pointer text-xs" onClick={handlePlayOnClick}> Play Quiz </div></>) :
                                (!(cardStates.timesup)) &&
                                <>
                                    <div className='drop-shadow absolute top-0 right-1 px-1 h-4 my-2 flex items-center justify-center rounded-3xl bg-green-400 text-white text-[9px] text-center'>{`${timer.days}d:${timer.hours}h:${timer.minutes}m:${timer.seconds}s`}</div>
                                    {(cardStates.register && quizz.isSchoolTypeQuizz === false && quizz.isQuizzithonQuiz === false) && <div className="  px-2 h-6 flex items-center justify-center rounded-3xl bg-yellow-400 text-white cursor-pointer text-xs" onClick={handleRegist}>
                                        Register
                                    </div>}
                                    {
                                        (cardStates.unregister && quizz.isSchoolTypeQuizz === false && quizz.isQuizzithonQuiz === false) && <div className=" px-2 h-6 flex items-center justify-center rounded-3xl bg-red-400 text-white cursor-pointer text-xs" onClick={handleUnRegister}>
                                            Un Register
                                        </div>
                                    }

                                </>
                        }
                        {
                            cardStates.timesup && <div className='absolute top-0 right-1 drop-shadow px-1 h-4 my-2 flex items-center justify-center rounded-3xl bg-red-400 text-white text-[8px]'>Quiz Ended</div>

                        }

                        {/* {
                            (auth.data.user.isAdmin === true) &&
                            (
                                quizz.status === 'archive'
                                    ?
                                    <button
                                        onClick={handleUnArchiveQuiz}
                                        className='w-2/5 h-8 flex items-center justify-center rounded-3xl bg-red-400 text-white text-xs'>UnArchive Quiz</button>
                                    :
                                    <button
                                        onClick={handleArchiveQuiz}
                                        className='w-2/5 h-8 flex items-center justify-center rounded-3xl bg-red-400 text-white text-xs'>Archive Quiz</button>
                            )
                        } */}
                    </div>
                    {
                        isSchoolQuiz && !isUser &&
                        <div className='w-4/5 flex items-center justify-between flex-wrap mb-3 '>

                            <button
                                onClick={async () => {
                                    await navigator.clipboard.writeText(`${quizz.schoolQuizzPasscode}`)
                                    dispatch({
                                        type: globalConstants.ALERT,
                                        payload: { success: "Passcode copied!" }
                                    })
                                }}
                                className='w-2/5 h-8 bg-opacity-75 hover:bg-opacity-100 text-[10px] bg-bgblue tracking-wide text-white py-1 px-2 rounded-3xl'>
                                Passcode
                            </button>
                            <button onClick={() => navigate(`/singlequizz/detailscreen/${quizz._id}`, { state: { isSchool: true } })} className='w-2/5 h-8 bg-opacity-75 hover:bg-opacity-100  text-[10px] bg-bgblue tracking-wide text-white py-1 px-2 rounded-3xl '>Results</button>
                        </div>
                    }
                    {
                        isSchoolQuiz && isUser &&
                        <div className='w-4/5 flex items-center justify-center flex-wrap mb-3 '>

                            {/* <button
                                onClick={async () => {
                                    await navigator.clipboard.writeText(`${quizz.schoolQuizzPasscode}`)
                                    dispatch({
                                        type: globalConstants.ALERT,
                                        payload: { success: "Passcode copied!" }
                                    })
                                }}
                                className='w-2/5 h-8 bg-opacity-75 hover:bg-opacity-100 text-[10px] bg-bgblue tracking-wide text-white py-1 px-2 rounded-3xl'>
                                Register
                            </button> */}
                            <button onClick={(event) => {
                                event.stopPropagation()
                                navigate(`/quizmaster`, { state: { isSchool: true } })}} className='w-2/5 h-8 bg-opacity-75 hover:bg-opacity-100  text-[10px] bg-bgblue tracking-wide text-white py-1 px-2 rounded-3xl z-20 '>Register</button>
                        </div>
                    }
                    {/* <div className='grid grid-cols-3 px-5 text-[#BD5E00] font-semibold'>
                        <div className='relative h-fit'>
                            <img src={first} alt="" className='w-full  pt-7 pb-3' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center' >5000</p>
                        </div>
                        
                        <div className='relative h-fit'>
                            <img src={first} alt="" className='w-full pt-5' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center' >5000</p>
                        </div>
                        <div className='relative'>
                            <img src={first} alt="" className='w-full  pt-7 pb-3' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center' >5000</p>
                        </div>
                    </div> */}

                    
                </div>
            </div>}
        </div>
    )
}
// const MainCont = styled.div`
// ${tw`lg:col-span-9 col-span-10   text-black p-3 grid grid-cols-12   gap-2 cursor-pointer bg-white`}
// `
// const DateButtonsCont = styled.div`
// ${tw`lg:col-span-1 col-span-10 text-white p-3 space-y-1 w-full flex  items-center  justify-center  flex-col`}
// `
// const Cont = styled.div`
// ${tw`grid grid-cols-10 shadow-xl border border-gray-300 !rounded-xl overflow-hidden`}
// `
export default QuizzCard;