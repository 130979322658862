import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import { globalConstants } from "../../actions/appConstants";
import axios from "../../utils/myAxios";
const Heading = ({ number, title }) => {
  return (
    <div className="flex items-center space-x-2 mb-5 mt-5">
      <div className="h-7 w-7 bg-bgblue rounded-full flex items-center justify-center">
        <span className="text-white font-bold">{number}</span>
      </div>
      <h1 className="font-semibold capitalize">{title}</h1>
    </div>
  );
};
const PercentageCriterias = () => {
  const dispatch = useDispatch();
  const [data, setdata] = useState({
    platefrom_commision_percent: 0,
    schedular_commision_percent: 0,
    taxation_commision_percent: 0,
    freeQuzeto: 0,
    realQuzeto: 0,
    bonusQzeto: 0,
    ReffreeQuzeto: 0,
    reffereralQzeto: 0,
    bonusToReal: 0,
    realToInr: 0,
    buyPriceOfOneINR: 0,
    pastQuizPrizeForTopThree: 0,
    pastQuizzPrizeAfterTopThree: 0,
    prizeDistributionForTopThree: {
      first: 0,
      second: 0,
      third: 0,
    },
  });
  const {
    platefrom_commision_percent,
    schedular_commision_percent,
    taxation_commision_percent,
    freeQuzeto,
    realQuzeto,
    bonusQzeto,
    ReffreeQuzeto,
    reffereralQzeto,
    bonusToReal,
    realToInr,
    buyPriceOfOneINR,
    pastQuizPrizeForTopThree,
    pastQuizzPrizeAfterTopThree,
    prizeDistributionForTopThree,
    qzetosForAI,
    ai_questions,
    quizzithon,
    quizmaster_pro,
    quizzes,
  } = data;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = async (id) => {
    try {
      let total_of = 0;
      total_of =
        Number(prizeDistributionForTopThree.first) +
        Number(prizeDistributionForTopThree.second) +
        Number(prizeDistributionForTopThree.third);
      //console.log('total_of', total_of)
      if (total_of !== 100) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "Total of prize distribution should be 100" },
        });
      }
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: true },
      });
      const res = await axios.post(`/update/percentagecriteria/${id}`, {
        ...data,
      });
      if (res.status === 200) {
        setdata(res.data.crieteria);
      }
      dispatch({
        type: globalConstants.ALERT,
        payload: { success: res.data.msg },
      });
    } catch (error) {
      if (error.response.data.msg) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.msg },
        });
      } else if (error.response?.data?.error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.response.data.error },
        });
      } else {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  };
  useEffect(() => {
    const getInitial = async () => {
      try {
        const res = await axios.get("/get/percentagecriteria");
        if (res.data.data.length > 0) {
          setdata(res.data.data[0]);
        }
      } catch (error) {
        if (error.response.data.msg) {
          dispatch({
            type: globalConstants.ALERT,
            payload: { error: error.response.data.msg },
          });
        } else if (error.response?.data?.error) {
          dispatch({
            type: globalConstants.ALERT,
            payload: { error: error.response.data.error },
          });
        } else {
          dispatch({
            type: globalConstants.ALERT,
            payload: { error: error.message },
          });
        }
      }
    };
    getInitial();
  }, []);
  return (
    <Cont className="my__rounded ">
      <h1 className="text-bgblue font-bold">Plateform Settings</h1>
      <Heading number={1} title="Tax and Commision" />
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 grid-cols-1 gap-3">
        <div>
          <h1 className="">Plateform commision</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={platefrom_commision_percent}
            name="platefrom_commision_percent"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="">Scheduler commision</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={schedular_commision_percent}
            name="schedular_commision_percent"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="">{`Applicable Tax (if winning ammount > ₹10,000)`}</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={taxation_commision_percent}
            name="taxation_commision_percent"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={2} title="Daily Qzeto" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <h1 className="">Free Qzeto's</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={freeQuzeto}
            name="freeQuzeto"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={3} title="Refferal Qzeto" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <h1 className="">Refferee Qzetos</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={ReffreeQuzeto}
            name="ReffreeQuzeto"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="">Refferel Qzetos</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={reffereralQzeto}
            name="reffereralQzeto"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={4} title="Conversions settings" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <h1 className="capitalize">NO. of bunos qzetos to 1 real qzetos*</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={bonusToReal}
            name="bonusToReal"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={4} title="Redemption Settings" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <h1 className="capitalize">Number of qzetos to 1INR*</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={buyPriceOfOneINR}
            name="buyPriceOfOneINR"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={5} title="Quiz Prize Distribution Criteria" />
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
        <div>
          <h1 className="capitalize">First</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={prizeDistributionForTopThree.first}
            onChange={(e) => {
              setdata({
                ...data,
                prizeDistributionForTopThree: {
                  ...data.prizeDistributionForTopThree,
                  first: e.target.value,
                },
              });
            }}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">Second</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={prizeDistributionForTopThree.second}
            onChange={(e) => {
              setdata({
                ...data,
                prizeDistributionForTopThree: {
                  ...data.prizeDistributionForTopThree,
                  second: e.target.value,
                },
              });
            }}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">Third</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={prizeDistributionForTopThree.third}
            onChange={(e) => {
              setdata({
                ...data,
                prizeDistributionForTopThree: {
                  ...data.prizeDistributionForTopThree,
                  third: e.target.value,
                },
              });
            }}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={6} title="Past Quiz Prize Distribution Criteria" />
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
        <div>
          <h1 className="capitalize">
            No. Of Bonus Qzeto's if User is In Top 3*
          </h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={pastQuizPrizeForTopThree}
            name="pastQuizPrizeForTopThree"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">
            No. Of Free Qzeto's if User is not in the top 3*
          </h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={pastQuizzPrizeAfterTopThree}
            name="pastQuizzPrizeAfterTopThree"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <Heading number={7} title="Sign up Rewards" />
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-3">
        <div>
          <h1 className="capitalize">Quizzes</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={quizzes}
            name="quizzes"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">Quizzithon</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={quizzithon}
            name="quizzithon"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">Quizmaster Pro quizzes</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={quizmaster_pro}
            name="quizmaster_pro"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
        <div>
          <h1 className="capitalize">AI Questions</h1>
          <input
            type="number"
            placeholder="Enter value..."
            value={ai_questions}
            name="ai_questions"
            onChange={handleOnChange}
            className="border border-black w-full py-2 outline-none px-2 rounded-md"
          />
        </div>
      </div>
      <div className="flex justify-end my-5">
        <button
          onClick={() => handleSubmit(data._id)}
          className="py-1 px-5 bg-bgblue text-white rounded-2xl"
        >
          Save
        </button>
      </div>
    </Cont>
  );
};
const Cont = styled.div`
  ${tw`bg-white p-3`}
`;
export default PercentageCriterias;
