import { authConstants, questionsConstants } from "../actions/appConstants";

const inititalState = {
    questionsCategories: []
}


const question = (state = inititalState, action) => {
    switch (action.type) {
        case questionsConstants.QUESTION_CATEGORIES:
            state = {
                ...state,
                questionsCategories: action.payload,
            }
            break;
    }
    return state;
}
export default question; 