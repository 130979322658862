import React from 'react'
import Layout from './../components/Layout/Layout'
const AdminScheduleQuiz = () => {
  return (
    <Layout>
      admingschedulesquixx
    </Layout>
  )
}

export default AdminScheduleQuiz