import React, { useEffect, useState } from 'react'
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import styled from 'styled-components'
import { Avatar } from '@mui/material'
import first from '../../../../assets/prizez/1.svg' 
import second from '../../../../assets/prizez/2.png'
import third from '../../../../assets/prizez/3.png'
import tw from 'twin.macro';
import axios from '../../../../utils/myAxios'
import LeaderBoardTable from './leaderBoardTable/LeaderBoardTable'
const positions = [
    first,
    second,
    third,
]
const Items = ({ index, value, isSchool }) => {
    return (
        <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-4'>
                <div className='flex items-center'>
                    <Avatar
                        sx={{
                            height: 45,
                            width: 45
                        }}
                        src={value.user.profileImage}
                    />
                    {
                        index > 2 ?
                            <h1 className=' font-bold text-bgblue pl-2'>{index} position</h1>
                            :
                            <div className='w-36 h-36'>
                                <img
                                className='w-full h-full object-contain'
                                    src={positions[index]}
                                />
                            </div>

                    }
                </div>
                <h1 className='tracking-widest text-sm font-semibold capitalize'>{isSchool ? value.user : value.user.userName}</h1>
            </div>
            <h1 className='text-bgblue font-semibold'>{value.numberOfCorrectQuestions}</h1>
        </div>
    )
}
const LeaderBoard = ({ qid , isPastQuiz }) => {
    const [leaderData, setleaderData] = useState([])
    const [isSchool, setisSchool] = useState(false)
    // useEffect(() => {
    //     const getData = async () => {
    //         try {
    //             const res = await axios.get(`/gammer/quizz/leaderboarddata/${qid}`)
    //             //console.log('leader board data==>', res.data.data)
    //             setleaderData(res.data.data)
    //             setisSchool(res.data.isSchoolTypeQuizz)
    //         } catch (error) {
    //         }
    //     }
    //     getData()
    // }, [qid])
    
    return (
        <Cont>
            <LeaderBoardTable qid={qid} isPastQuiz = {isPastQuiz}/>
        </Cont>
    )
}
const Cont = styled.div`
${tw`grid grid-cols-1 gap-4`}
`
export default LeaderBoard