import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from './../components/Layout/Layout'
import { CircularProgress } from '@mui/material'
import QuizmasterProStatsC from '../components/UserManagementComponents/quizmasterprostates/ScheduleTable'
const QuizPromasterStats = () => {
    const { state } = useLocation()
    const [loading, setloading] = useState(true)
    const [user, setuser] = useState(null)
    //check if user management want to see user questions
    useEffect(async () => {
        if (state?.userManagement) {
            // console.log('state?.userManagement==>', state?.userManagement)
            await setuser(state?.userManagement)
            setloading(false)
        } else {
            setloading(false)
        }
    }, [state])
    //check if user management want to see user questions
    return (
        <Layout>
            {
                loading ?
                    <div className='flex items-center justify-center mt-5'>
                        <CircularProgress />
                    </div>
                    :
                    <div>
                        <QuizmasterProStatsC uid={user} />
                    </div>
            }

        </Layout>
    )
}

export default QuizPromasterStats