import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
// import Aboutimg from '../../../assets/staticdata/aboutimg.jpg'
import Aboutimg from '../../../assets/staticdata/aboutus.png'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const AboutQuizBoz = () => {
  const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div className='bg-gray-800 py-10'>
      <Container>
        <div className='w-full grid md:grid-cols-12 grid-cols-1  gap-4 '>
          <div className='flex h-full flex-col justify-center md:col-span-5 pr-5'>
            <Typography className = "text-yellow-500" variant={matches ? 'h3' : 'h5'} textAlign= {matches ? "left" : 'center'} sx={{ color: 'rgb(234 179 8 / var(--tw-text-opacity)', fontSize: 'bold' }}>
              About Quizzingbox
            </Typography>
            <Typography className='tracking-wide' mt={3} variant='subtitle1' textAlign='left' sx={{ color: '#fff' }}>
              We provide a global quizzing platform that offers unlimited number of quizzes with opportunity of winning rewards both by quiz schedulling and quiz playing.Every single minute we make it a preferred choice for millions of happy users all around the globe. Feel the excitement and the adrenalin that never leave your body till the last question pops in. Experience the rush of winning both rewards and respect from other quiz takers. Take quizzes, test your skills and keep on learning.
            </Typography>
          </div>
          <div className='md:col-span-7 flex items-center justify-center'>
            <img className='w-full object-contain' src={Aboutimg} alt='' />
          </div>
        </div>
      </Container>
	  
	  
     
    </div>
  )
}

export default AboutQuizBoz