import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import { makeStyles } from '@material-ui/core'
import { IconButton, Typography, DialogActions, styled, Button } from '@mui/material';
// import Control from './Controds/Control';
import CloseIcon from '@mui/icons-material/Close';
import SignUp from '../../../screens/SignUp'
import { useDispatch, useSelector } from 'react-redux'
import { extrasConstancts } from '../../../actions/appConstants';

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    color: 'white',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#F89820',
        borderColor: '#F89820',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#F89820',
        borderColor: '#F89820',
    },
    '&:focus': {

    },
});
const MyPopUp = (props) => {
    const dispatch = useDispatch()
    const { extras } = useSelector(s => s)
    const { title, children, } = props
    const [openPopUp, setOpenPopUp] = useState(extras.showSigUpModal)
    useEffect(() => {
        if (extras.showSignInModal) {
            setOpenPopUp(false)
        }
    }, [extras.showSignInModal])
    useEffect(() => {
        if (extras.showSigUpModal) {
            setOpenPopUp(true)
        } else {
            setOpenPopUp(false)
        }
    }, [extras.showSigUpModal])
    return (
        <>
            <Dialog open={openPopUp} maxWidth='sm' fullWidth={true}>
                <div className='!relative py-5'>
                    <IconButton className='!absolute !right-0 !top-0 z-50' color="secondary"
                        onClick={() => {
                            dispatch({
                                type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                payload: false,
                            })
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <SignUp isPopUp={true} />
                </div>
            </Dialog>
        </>
    )
}

export default MyPopUp