import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import SchoolQuestionTable from './SchoolQuestionsTable/SchoolQuestionTable'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const {
        onClose, selectedValue, open,
        schedulerData,
        setschedulerData
    } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog maxWidth='md' fullWidth={true} onClose={handleClose} open={open}>
            <div className='py-3 relative'>
                <IconButton onClick={handleClose} className='!absolute !top-0 !right-0 '>
                    <CloseIcon />
                </IconButton>
                
                <SchoolQuestionTable
                    schedulerData={schedulerData}
                    setschedulerData={setschedulerData}
                    onClose = {onClose}
                    selectedValue={selectedValue}
                />
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({
    open,
    setOpen,
    schedulerData,
    setschedulerData
}) {

    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <div>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
                schedulerData={schedulerData}
                setschedulerData={setschedulerData}
            />
        </div>
    );
}
