import React, { useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
const TableRow = ({ val, questions, setquestions, setReset }) => {
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} setReset={setReset} />
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.name}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.amount}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.quizzes}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.quizmaster_pro}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.quizzithon}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.ai_questions}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.price}</span>
                </td>
                <td class="py-3 px-6">
                    <div class="flex item-center">
                        <div
                            onClick={() => {
                                setstate({ ...init, view: true })
                                setopen(true)
                            }}
                            class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                        <div onClick={() => {
                            setstate({ ...init, edit: true })
                            setopen(true)
                        }} class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                        </div>
                        <div onClick={() => {
                            setstate({ ...init, delete: true })
                            setopen(true)
                        }}
                            class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TableRow