import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import Dollar from '../../assets/layout/dollar.png'
import Hand from '../../assets/layout/hand.png'
import Wallet from '../../assets/layout/wallet.png'
import { useDispatch } from 'react-redux'
import { redeamBonusToFreeAction, redeamBonusToRealAction, redeamFreeToRealAction } from '../../actions/wallet.actions'
import RealToINRModal from './RealToINRModal'
import BonusQzetosEarning from './../bonusQzetosEarning/BonusQzetosEarning'
import RealQzetosEarning from './../realQzetosEarning/RealQzetosEarning'
import FreeQzetosEarning from './../freeQzetosHistory/FreeQzetosEarning'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import QzetoRedemtionModal from './qzetoRedemptionModal'
const MyWalletC = () => {
    const [conversionValues, setconversionValues] = useState({
        realToINR: 0,
        bonusToReal: 0,
    })
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const { wallet } = useSelector(s => s)
    const getWalletData = (type) => {
        const obj = wallet.wallet.find(v => v.walletsType === type)
        const toreturn = obj?.credit ? obj.credit : 0
        return toreturn;
    }
    const handleFreeRedeam = () => {
        dispatch(redeamFreeToRealAction())
    }
    const handleBonusRedeam = () => {
        dispatch(redeamBonusToFreeAction())
    }
    const handleBonusToReal = () => {
        dispatch(redeamBonusToRealAction())
    }
    const handleRealToINR = () => {
        console.log("clicked")
        setOpen(true)
    }
    useEffect(async () => {
        try {
            const res = await axios.get('/get/percentagecriteria')
            console.log('res==>', res.data)
            const conversion = res.data.data[0]
            setconversionValues({
                bonusToReal: conversion.bonusToReal,
                realToINR: conversion.buyPriceOfOneINR
            })
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }, [])
    return (
        <Cont>
            <div className='grid grid-cols-1  lg:grid-cols-3 gap-10'>
                <div className='border-2 border-gray-200 rounded-2xl p-5 bg-white'>
                    <QzetoRedemtionModal open={open} setOpen={setOpen} />
                    <div className='flex items-center space-x-3'>
                        <div className='w-7 h-7'>
                            <img src={Wallet} className='w-full h-full object-cover' />
                        </div>
                        <h1 className='font-bold text-lg capitalize'>real qzetos</h1>
                    </div>
                    <div className='mt-5 flex items-center justify-between'>
                        <div>
                            <h1 className='capitalize'>{getWalletData('real').toFixed(0)} real qzetos available</h1>
                            <h1 className='italic text-xs text-bgblue'>{`“${conversionValues.realToINR} real qzetos = 1 INR”`}</h1>
                        </div>
                        <button onClick={handleRealToINR} className='rounded-2xl py-1 px-4 text-xs text-white bg-bgblue'>Redeem</button>
                    </div>
                </div>
                {/* real redemption */}
                <div className='border-2 border-gray-200 rounded-2xl p-5 bg-white'>
                    <div className='flex items-center space-x-3'>
                        <div className='w-7 h-7'>
                            <img src={Dollar} className='w-full h-full object-cover' />
                        </div>
                        <h1 className='font-bold text-lg capitalize'>bonus qzetos</h1>
                    </div>
                    <div className='mt-5 flex items-center justify-between'>
                        <div>
                            <h1 className='capitalize'>{getWalletData('bonus').toFixed(0)} bonus qzetos available</h1>
                            <h1 className='italic text-xs text-bgblue'>{`“${conversionValues.bonusToReal} Bonus qzetos = 1 real qzeto”`}</h1>
                        </div>
                        <button onClick={handleBonusToReal} className='rounded-2xl py-1 px-4 text-xs text-white bg-bgblue'>Redeem</button>
                    </div>
                </div>
            </div>
            <div className='grid lg:grid-cols-3 grid-cols-1 gap-4 mt-10'>
                <div className='space-y-3'>
                    <h1 className='capitalize text-[14px] font-semibold '>free qzetos earnings</h1>
                    <FreeQzetosEarning />
                </div>
                <div className='space-y-3'>
                    <h1 className='capitalize text-[14px] font-semibold '>bonus qzetos earnings</h1>
                    <BonusQzetosEarning />
                </div>
                <div className='space-y-3'>
                    <h1 className='capitalize text-[14px] font-semibold '>real qzetos earnings</h1>
                    <RealQzetosEarning />
                </div>

            </div>
        </Cont>
    )
}
const Button = styled.button`
${tw`bg-white py-2 px-5 text-center font-semibold m-auto shadow-2xl`}
`
const Cont = styled.div`
${tw``}
`
export default MyWalletC