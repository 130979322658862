import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleQuizzComponent from './ScheduleQuizzithonQuiz';
import { IconButton } from '@mui/material';
function QuizzithonQuiz({ index }) {
    const [open, setOpen] = React.useState(index=== 0 ? true :false);
    const handleOpen = () => {
        setOpen(!open)
    }
    return (
        <div className='w-full'>
            <div className='flex justify-between p-3 border-b-2 border-slate-100'>
                <h1 className='text-xl text-center'>Quiz # {index + 1}</h1>
                <IconButton>
                    <ExpandMoreIcon onClick={handleOpen} />
                </IconButton>

            </div>
            {open && <div className=''>
                <ScheduleQuizzComponent quizIndex = {index} isQuizzithonQuiz={true} key = {index}/>
            </div>}
        </div>
    )
}

export default QuizzithonQuiz