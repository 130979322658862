import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'

const Heading = ({ text }) => <h1 className='mt-5 font-bold text-xl'>{text}</h1>
const MyLink = ({ text, link }) => <a target={`_blank`} className='text-blue-600 underline' href={link}>{text}</a>
const MyParagraph = ({ children }) => <p className='text-[16px] leading-8 mt-5'>{children}</p>


const MyBold = ({ text }) => <span className='text-lg font-extrabold'>{text}</span>

const PrivacyPolicy = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const p = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])

  return (
    <>
      <div className='!bg-white '>
        <NavBar />
        <h1 className='text-3xl bg-bgblue text-white p-5 md:pl-14 py-10 md:mt-[4rem]'>Privacy Policy</h1>
        <div className='md:w-[80%] w-[95%] m-auto mt-5'>
          <MyParagraph>
            We, <MyBold text={`Quizlabs Technology Private Limited,`} />  incorporated under the Companies Act, 2013
            (hereinafter referred to as <MyBold text={`“Company”`} />), having its registered office at Sai Tanvi Residency, Door No – 101, Sector – 1, Block – H, Alkapoor Township, Nikhanampur, Hyderabad – 500081, India, the creator of this Privacy Policy ensure our firm commitment to your privacy vis-à-vis the protection of your priceless information. In order to endow you with our uninterrupted use of services, we may collect and, in some circumstances, disclose information about you. To enhance better protection of your privacy we provide this notice explaining our information practices and the choices
            you can make about the way your
            information is collected and used.
          </MyParagraph>


          <MyParagraph>
            All visitors to <MyLink link={`http://www.quizzingbox.com`} text={`www.quizzingbox.com`} />  (Website) are advised to read and understand our
            Privacy Policy carefully, as by accessing the Website you agree to be bound by the terms
            and conditions of the Privacy Policy and consent to the collection, storage and use of information
            relating to you as provided herein.
          </MyParagraph>
          <MyParagraph>
            If you do not agree with the terms and conditions of our Privacy Policy, including in relation to the
            manner of collection or use of your information, please do not use or access the Site.
          </MyParagraph>
          <MyParagraph>
            If you have any questions or concerns regarding this privacy policy, you should contact our Customer Support Desk at ___________
          </MyParagraph>

          <MyParagraph>
            ANY CAPITALIZED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY MANNER. NEITHER THE USER NOR THE CREATERS OF THIS PRIVACY POLICY MAY USE THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY MANNER.
          </MyParagraph>
          <Heading text={`Defination`} />
          <MyParagraph>
            <ul className='pl-5'>
              <li className='!list-disc'>
                “We”, “Our”, and “Us” shall mean and refer to the creators of this privacy policy.
              </li>
              <li className='!list-disc'>
                “You”, “Your”, “Yourself” and “User” shall mean and refer to natural and legal individuals who use the Website.
              </li>
              <li className='!list-disc'>
                “Website” shall mean and refer to <MyLink link={`http://www.quizzingbox.com`} text={`www.quizzingbox.com`} /> created by Quizlabs Technology Private Limited
              </li>
              <li className='!list-disc'>
                “Personal Information” shall mean and refer to any personally identifiable information that We may collect from You. For removal of any doubts, please refer to Clause 2.
              </li>
              <li className='!list-disc'>
                “Third Parties” refer to any Website, website, company or individual apart from the User and the creator of the Website.
              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Overview`} />
          <MyParagraph>
            We commit to respecting your online privacy data. We further recognize your need for appropriate protection and management of any personally identifiable information ("Personal Information") you share with us. Information that is considered personal about you by us includes, but is not limited to, your name, address, email address, phone number or other contact information.
            In order to purchase any product from this Website, you are required to provide the following information which includes, but is not limited to:
            <ul className='pl-5'>
              <li className='!list-disc'>
                Name;
              </li>
              <li className='!list-disc'>
                User name;
              </li>
              <li className='!list-disc'>
                Password;
              </li>
              <li className='!list-disc'>
                Address;
              </li>
              <li className='!list-disc'>
                Mobile Number;
              </li>
              <li className='!list-disc'>
                E-mail Address
              </li>
              <li className='!list-disc'>
                PAN Card Number
              </li>
            </ul>
            This privacy policy also applies to data we collect from users who are not registered as members of this site, including, but not limited to, browsing behavior, pages viewed etc.
          </MyParagraph>
          <Heading text={`Notification of Modifications and Changes to the T&C and Privacy Policy`} />
          <MyParagraph>
            We reserve the right to change the Terms and Privacy Policy from time to time as we deem fit, without any intimation to you, and your continued use of the site will signify your acceptance of any amendment to these terms.
            You are therefore advised to re-read the Terms of Service on a regular basis. Should it be that you do not accept any of the modifications or amendments to the Terms, you may terminate your use of this website immediately.
          </MyParagraph>
          <Heading text={`Information We Collect`} />
          <MyParagraph>
            Our site's online requisition form requires users to give us contact information (like your name, address, telephone number and email address), and demographic information (like your zip code/pin code). As a member, you are required to provide a valid email address at registration and choose a username or alias that represents your identity on our website. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on the Site or not), which URL you next go to (whether this URL is on the Site or not), your computer browser information, your IP address, and other information associated with your interaction with the Site.
          </MyParagraph>
          <MyParagraph>
            We also collect and store personal information provided by you from time to time on the Site. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:
            <ul className='pl-5'>
              <li className='!list-disc'>
                To enable the provision of services opted for by you;

              </li>
              <li className='!list-disc'>
                To communicate necessary account and product/service related information from time to time;
              </li>
              <li className='!list-disc'>
                To allow you to receive quality customer care services;
              </li>
              <li className='!list-disc'>
                To undertake necessary fraud and money laundering prevention checks, and comply with the highest security standards;

              </li>
              <li className='!list-disc'>
                To comply with applicable laws, rules and regulations; and

              </li>
              <li className='!list-disc'>
                To provide you with information and offers on products and services, on updates, on promotions, on related, affiliated or associated service providers and partners, that we believe would be of interest to you.

              </li>
            </ul>
          </MyParagraph>
          <MyParagraph>
            Where any service requested by you involves a third party, such information as is reasonably necessary by the Company to carry out your service request may be shared with such third party.

          </MyParagraph>
          <MyParagraph>
            We also do use your contact information to send you offers based on your interests and prior activity. The Company may also use contact information internally to direct its efforts for product improvement, to contact you as a survey respondent, to notify you if you win any contest; and to send you promotional materials from its contest sponsors or advertisers.
          </MyParagraph>
          <MyParagraph>
            Further, you may from time to time choose to provide payment related financial information (credit card, debit card, bank account details, billing address etc.) on the Site. We are committed to keeping all such sensitive data/information safe at all times and ensure that such data/information is only transacted over secure Site of approved payment gateways which are digitally encrypted, and provide the highest possible degree of care available under the technology presently in use.

          </MyParagraph>
          <MyParagraph>
            The Company will not use your financial information for any purpose other than to complete a transaction with you.

          </MyParagraph>
          <MyParagraph>
            To the extent possible, we provide you the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Site, and opt out of any non-essential communications from the Company.

          </MyParagraph>
          <MyParagraph>
            Further, transacting over the internet has inherent risks which can only be avoided by you following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.

          </MyParagraph>
          <Heading text={`How Information Is Collected`} />
          <MyParagraph>
            <ul className='pl-5'>
              <li className='!list-disc'>
                Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.

              </li>
              <li className='!list-disc'>
                We will collect and use of personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.
              </li>
              <li className='!list-disc'>
                We will only retain personal information as long as necessary for the fulfilment of those purposes.

              </li>
              <li className='!list-disc'>
                We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.


              </li>
              <li className='!list-disc'>
                Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.

              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Cookies`} />
          <MyParagraph>
            <ul className='pl-5'>
              <li className='!list-disc'>
                We use data collection devices such as "cookies" on certain pages of our Websites. "Cookies" are small files sited on your hard drive that assist us in providing customized services. We also offer certain features that are only available through the use of a "cookie". Cookies can also help us provide information, which is targeted to your interests. Cookies may be used to identify logged in or registered users.


              </li>
              <li className='!list-disc'>
                Third party vendors including <MyLink link={`https://www.google.com/`} text={`www.google.com`} /> (“Google”) may use cookies to serve
                ads based on your
                visits to this Website. You may visit the website of the third party and choose to opt out of the use of cookies
                for interest-based advertising, if the third party offers such an option. You may choose to opt-out of the
                DoubleClick cookie that Google and its partners use for
                interest-based advertising by visiting <MyLink link={`http://www.google.com/ads/preferences/`} text={`Ads Settings`} />
                . (Alternatively, you can direct users to opt out of a third-party vendor's use of cookies for interest based advertising by visiting <MyLink link={`http://www.aboutads.info`} text={`aboutads.info`} />)
              </li>
              <li className='!list-disc'>
                The website also has enabled the Google Analytics Advertising, which allows Google to collect data
                about users on our website, in addition to Google advertising cookies and anonymous identifiers.
                You may choose to opt out of this by downloading and installing the Google Analytics opt-out add-on
                here <MyLink link={`https://tools.google.com/dlpage/gaoptout/`} text={`https://tools.google.com/dlpage/gaoptout/`} />
              </li>
            </ul>
          </MyParagraph>
          <Heading text={`External Links on Website`} />
          <MyParagraph>
            The Website may include hyperlinks to other web sites or content or resources. We have no control over any websites or resources, which are provided by companies or persons other than Us.

          </MyParagraph>
          <MyParagraph>
            You acknowledge and agree that We are not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such websites or resources.

          </MyParagraph>
          <MyParagraph>
            You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such websites or resources. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of your personal information that you may be subject to. We recommend that when you enter a Third-Party Site, you review the Third Party Site’s privacy policy as it relates to safeguarding your personal information. We use third-party advertising companies to serve ads when you visit the Website.

          </MyParagraph>
          <Heading text={`Our Use of Your Information`} />
          <MyParagraph>
            Your contact information is also used to contact you when necessary. We use your IP address to help diagnose problems with our server, and to administer our Website. Your IP address is also used to help identify you and to gather broad demographic information. Finally, we may use your IP address to help protect our partners and ourselves from fraud. We will continue to enhance our security procedures as new technology becomes available. We will transfer information about you if We are acquired by or merged with another company. In this event, we will notify you by email or by putting a prominent notice on the site before information about you is transferred and becomes subject to a different privacy policy.

          </MyParagraph>
          <MyParagraph>
            We may release your personal information to a third-party in order to comply with a Court Order or other similar legal procedure, or when we believe in good faith that such disclosure is necessary to comply with the law; prevent imminent physical harm or financial loss; or investigate or take action regarding illegal activities, suspected fraud, or violations of Our Terms of Use. We may disclose personally identifiable information to parties in compliance with our Copyright Policy as mentioned in the Terms of Use as we in our sole discretion believe necessary or appropriate in connection with an investigation of fraud, intellectual property infringement, piracy, or other unlawful activity. In such events, We may disclose name, address, country, phone number, email address and company name.
          </MyParagraph>


          <Heading text={`Confidentiality`} />
          <MyParagraph>
            You further acknowledge that the Website may contain information which is designated confidential by Us and that you shall not disclose such information without our prior written consent.
          </MyParagraph>
          <MyParagraph>
            Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities.

          </MyParagraph>
          <MyParagraph>
            We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services and products.
          </MyParagraph>
          <Heading text={`Our Disclosure of Your Information`} />
          <MyParagraph>
            Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Under certain circumstances, third parties may unlawfully intercept or access transmissions or private communications, or members may abuse or misuse your information that they collect from our Website. Therefore, although we use industry standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private.

          </MyParagraph>
          <MyParagraph>
            As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:
            <ul className='pl-5'>
              <li className='!list-disc'>
                <Heading text={`External Service Providers: `} />
                There may be a number of services offered by external service providers that help you use our Websites. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or grant them permission to collect information about you, then their use of your information is governed by their private policy.

              </li>
              <li className='!list-disc'>
                <Heading text={`Other Corporate Entities:`} />
                We share much of our data, including personally identifiable information about you, with our parent and/or subsidiaries that are committed to serving your online needs and related services, throughout the world. Such data will be shared for the sole purpose of enhancing your browsing experience and providing our services to you. To the extent that these entities have access to your information, they will treat it at least as protectively as they treat information they obtain from their other members. It is possible that We and/or its subsidiaries, or any combination of such, could merge with or be acquired by another business entity. Should such a combination occur, you should expect that we would share some or all of your information in order to continue to provide the service. You will receive notice of such event (to the extent that it occurs).

              </li>
              <li className='!list-disc'>
                <Heading text={`Law and Order:`} />
                We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as: intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.
              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Accessing and Reviewing Information`} />
          <MyParagraph>
            Following registration, you can review and change the information you submitted each time except your display name. If you change any information we may keep track of your old information. You can change your registration information such as: name, address, city, state, zip code, country, phone number, and profile.

          </MyParagraph>
          <MyParagraph>
            We will retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Further, such prior information is never completely removed from our databases due to technical and legal constraints, including stored 'back up' systems. Therefore, you should not expect that all of your personally identifiable information will be completely removed from our databases in response to your requests.
          </MyParagraph>
          <Heading text={`Control of Your Password`} />
          <MyParagraph>
            When you sign up to become a Member, you will also be asked to choose a password. You are entirely responsible for maintaining the confidentiality of your password. It is important that you protect it against unauthorized access of your account and information by choosing your password carefully, and keeping your password and computer secure by signing out after using our services.

          </MyParagraph>
          <MyParagraph>
            You agree not to use the account, username, email address or password of another Member at any time or to disclose your password to any third party. If you choose to share this information with third parties to provide you additional services, you are responsible for all actions taken with your login information and password and therefore should review each third party's privacy policy. You are responsible for all actions taken with your login information and password, including fees. If you lose control of your password, you may lose substantial control over your personally identifiable information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has been compromised for any reason, you should immediately change your password. You agree to notify us immediately if you suspect any consistent unauthorized use of your account or access to your password even after changing it.

          </MyParagraph>
          <Heading text={`Other Information Collectors`} />
          <MyParagraph>
            Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Websites or on other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their own privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.

          </MyParagraph>
          <Heading text={`Security`} />
          <MyParagraph>
            We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the company. We do not recommend transfer of sensitive information (such as credit card number) and bank account details via the Site to other Users. Users are recommended to do so offline, on the phone or via personal emails. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once We receive it. However, "perfect security" does not exist on the Internet. You therefore agree that any security breaches beyond the control of our standard security procedures are at your sole risk and discretion.

          </MyParagraph>
          <Heading text={`Disclaimer`} />
          <MyParagraph>
            We cannot ensure that all of your private communications and other personal information (including sensitive information like credit card information and bank account number) will never be disclosed in ways not otherwise described in this Privacy Policy. Therefore, although we are committed to protecting your privacy, we do not promise, and you should not expect, that your personal information will always remain private. As a user of the Site, you understand and agree that you assume all responsibility and risk for your use of the Site, the internet generally, and the documents you post or access and for your conduct on and off the Site.

          </MyParagraph>
          <Heading text={`Disputes and Jurisdiction`} />
          <MyParagraph>
            All disputes involving but not limited to rights conferred, compensation, refunds, and other claims through this policy will be resolved through a two-step Alternate Dispute Resolution mechanism.
            <ul className='pl-5'>
              <li className='!list-disc'>
                <Heading text={`Stage 1: Mediation.`} />
                In case of a dispute, the matter will first be attempted to be resolved by a sole mediator who is a neutral third party and will be selected at the mutual acceptance of a proposed mediator by both parties. Both parties may raise a name for sole arbitrator and in case both parties accept the proposed name, the said person shall be appointed as sole mediator. In any case the parties are not able to reach a consensus within two proposed mediators, the Company reserves the right to decide who the final mediator is. The decision of the mediator is not binding on both parties, however the parties in good faith will attempt to bind by the decision.

              </li>
              <li className='!list-disc'>
                <Heading text={`Stage 2: Arbitration. `} />
                In case that mediation does not yield a result suitable or preferred by any one of the parties, arbitration may follow, the award of which is binding on both parties. The Arbitration Board shall comprise three members – one appointed by each party and the third member to be nominated by the two appointed members by mutual consent. Arbitration shall be held at [.] India. The proceedings of arbitration shall be in the English language. The arbitrator’s award shall be final and binding on the Parties. If the dispute cannot be resolved by this two-step Alternate Dispute Resolution mechanism, it shall be referred to the courts at [.], India.

              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Questions and Suggestions`} />
          <MyParagraph>
            If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to <MyLink link={`mailto:info@quizzingbox.com`} text={`info@quizzingbox.com`} />
          </MyParagraph>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PrivacyPolicy