import React from 'react'
import Logo from '../../../assets/logo/logo.png'
import CallIcon from '@mui/icons-material/Call';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux'
function Footer() {
    const { auth } = useSelector(state => state)
  return (
    <footer>
        {/* <div className="footer">
            <div className="logo">
                <img src={Logo} alt="" />
            </div>
            <div className="links">
                <ul>
                    <li>Home</li>
                    <li>Frequently Asked Questions</li>
                    <li>Contact Us</li>
                    <li>Test </li>
                    <li>Test</li>
                </ul>
            </div>
            <div className="contact">
                <span>
                    <CallIcon/> <p> +913858210056</p>
                </span>
                <span>
                    <AlternateEmailIcon/><p> fantita@jagomail.com</p>
                </span>
                <span>
                    <LocationOnIcon/> <p> 2261 Market Street <br /> #4000 San Francisco <br /> CA, 94114</p>
                </span>
            </div>
            
        </div>
        <div className="bottom">
            Copyright 2022 Quizzing Box. All rights reserved
        </div> */}
        <div className="p-10 bg-gray-800 text-gray-200">
            <div className="max-w-7xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                    <div className="mb-5">
                        <Link to = "/" className='text-white'><h4 className='text-2xl pb-4 cursor-pointer'>Quizzing Box</h4></Link>
                        
                        <p className='text-gray-500'>
                            <span>Quizlabs Technolgy Private Limited<br></br>SY.NO- 73/1,73/2A,74(P) & 81 <br /> Bommenahalli, Bidarahalli, Hobli,<br /> Bengaluru, Karnataka 562129, India  <br /> <br /></span>
                            {/* <strong><CallIcon/></strong> +91 385 821 0056<br />
                            <strong><AlternateEmailIcon/></strong> fantita@jagomail.com    */}
                        </p>
                        
                    </div>
                    <div className="mb-5">
                        <h4 className='text-2xl pb-4'>Useful Links</h4>
                        <ul className='text-gray-500'>
                            {/* <li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> Home</li> */}
                            <Link to="/static/faqs" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> FAQs</li></Link>
                            <Link to="/static/howitworks" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> How it Works</li></Link>
                            <Link to="/static/privacypolicy" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> Privacy Policy</li></Link>
                            <Link to="/static/termsandcondition" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon>Terms and Conditions</li></Link>
                        </ul>        
                    </div>
                    <div className="mb-5">
                        <h4 className='text-2xl pb-4'>Shortcuts</h4>
                        <ul className='text-gray-500'>
                            <Link to="/" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> Home</li></Link>
                            <Link to="/" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> About Us</li></Link>
                            <Link to="/static/contactus" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> Contact Us</li></Link>
                            <Link to="/quizmasterpro/quizzes" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon>Quiz Master Pro</li></Link>
                            {auth.authenticate &&<Link to="/reportbug" className='text-gray-500'><li className='pb-4 hover:text-yellow-500'><ChevronRightIcon className='text-yellow-500'></ChevronRightIcon> Report a Bug</li></Link>}
                        </ul> 
                    </div>
                    {/* 
                     




                     */}

                    <div className="mb-5">
                        <h4 className='text-2xl pb-4'>Follow us on Social Media</h4>
                        <div className='text-xl mb-2'>
                            <a href="https://www.youtube.com/channel/UCj2AXIKRDG4tBb3PHiOiqng" className='w-10 h-10 rounded-full bg-yellow-500 text-center hover:bg-yellow-600 mx-1 inline-block pt-1'><YouTubeIcon className='text-white'></YouTubeIcon></a>
                            <a href="https://www.instagram.com/quizzing_box/" className='w-10 h-10 rounded-full bg-yellow-500 text-center hover:bg-yellow-600 mx-1 inline-block pt-1'><InstagramIcon className='text-white'></InstagramIcon></a>
                            <a href="https://web.facebook.com/profile.php?id=100087976161768" className='w-10 h-10 rounded-full bg-yellow-500 text-center hover:bg-yellow-600 mx-1 inline-block pt-1'><FacebookIcon className='text-white'></FacebookIcon></a>
                            <a href="https://twitter.com/BoxQuizzing" className='w-10 h-10 rounded-full bg-yellow-500 text-center hover:bg-yellow-600 mx-1 inline-block pt-1'><TwitterIcon className='text-white'></TwitterIcon></a>
                            <a href="https://pin.it/1L0BsoR" className='w-10 h-10 rounded-full bg-yellow-500 text-center hover:bg-yellow-600 mx-1 inline-block pt-1'><PinterestIcon className='text-white'></PinterestIcon></a>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
        <div className="w-full bg-gray-900 text-gray-500 px-10">
            <div className='max-w-7xl flex flex-col sm:flex-row py-4 mx-auto justify-center items-center'>
                <div className='text-center'>
                    <div>
                        Copyright 2022 <strong><span className='text-yellow-500'>Quizzing Box</span></strong>. All Rights Reserved.
                    </div>
                    
                </div>
                {/* <div className='text-center text-xl mb-2'>
                    <a href="#" className='w-10 h-10 rounded-full bg-yellow-500 hover:bg-yello-600 mx-1 inline-block pt-1'><GoogleIcon className='text-white'></GoogleIcon></a>
                    <a href="#" className='w-10 h-10 rounded-full bg-yellow-500 hover:bg-yello-600 mx-1 inline-block pt-1'><InstagramIcon className='text-white'></InstagramIcon></a>
                    <a href="#" className='w-10 h-10 rounded-full bg-yellow-500 hover:bg-yello-600 mx-1 inline-block pt-1'><FacebookIcon className='text-white'></FacebookIcon></a>
                    <a href="#" className='w-10 h-10 rounded-full bg-yellow-500 hover:bg-yello-600 mx-1 inline-block pt-1'><TwitterIcon className='text-white'></TwitterIcon></a>
                    <a href="#" className='w-10 h-10 rounded-full bg-yellow-500 hover:bg-yello-600 mx-1 inline-block pt-1'><LinkedInIcon className='text-white'></LinkedInIcon></a>

                </div> */}
            </div>
        </div>
    </footer>
  )
}

export default Footer