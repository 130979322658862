import React, { useEffect, useState } from 'react'
import { questionsCategoryConstants } from '../../../actions/appConstants'
const staticData = [
    {
        name: questionsCategoryConstants.MULTIPLE_QUESTIONS,
        data: ['You have given an input to fill the statement for the question .', '4 options to be filled in the option field.', 'Out of these 4 option, one of them will be correct.',
            'This correction option need to be selected from the dropdown.', 'Click on save and next button to save the question with correct answer'
        ]
    },
    {
        name: questionsCategoryConstants.CLOSED_ENDED,
        data: ['Closed Ended question means One word answer to the question. ',
            'You have to fill the statement field for the question. ',
            'And then just write the correct answer to the bellow field.',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.CROSS_WORDS,
        data: ['You need to create cross word for any subject',
            'In this type of question you have fill the crossword board with the words and provide clues related to that word . ',
            'You can also select an image as a clue related to the specific crossword. ',
            'Crossword need to be placed either "Across" (horizental) Or "Downward" (vertical)',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS,
        data: ['This is fill up the blanks type of question with multiple fill up the blank spaces',
            'While writing an sentence if you want to introduce fill up the blank just enclose the word in this <> (i.e. hi <how> are you <i> am good <what> about you. ) ',
            'In the example there are three words that will be treated as Blanks to be fill up. (i.e... how,i,what). ',
            'The word which will created as to be filled up in the blank by user will appear in the bottom of the question',
            'User while attemting the question would drag and drop these words at appropriate places in the sentence with multiple blanks',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING,
        data: ['This is question type where question will be created with image as its answer',
            'For eg consider the question statement Select all feature which belongs to tajmahal',
            'For this question you have to provide detail for the "Correct" & "Incorrect" feature of tajmahal in the form of image.',
            'There can be some feature which will be correct and some which will be incorrect',
            'Based on correct/incorrect, upload respective images like for correct feature you can upload Agra photo',
            'For incorrect feature you can upload picture of something black but tajmahal is white and so on',
            'If user while answering the question selects any incorrect feature option then his answer will be marked as incorrect. ',
            'You must have atleast one correct and one incorrect options to create.',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.FILL_IN_THE_BLANKS,
        data: [' You need to write a question statement with underscore _____ whereever you want user fill up in the question',
            'Only one fill in the blanks has to be created within the question wherever required',
            'Provide the answer for the fill up the blanks in the space provided.',
            'Click on save and next button to save the question with correct answer',
        ]
    },
    {
        name: questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE,
        data: ['You need to write the question having 4 multiple coice options.',
            '4 options to be filled in the option field. ',
            'Out of these 4 option, one of them will be correct',
            'This question is of Multiple Question type. ',
            'After that you have to select the image or video, so user can see it and give answer according to it. ',
            'The media should be related to the question.',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.MATCH_THE_PAIR,
        data: ['You have 3 Columns with fields. ',
            'You have to fill the first two columns such that 1st coulmn has the question and 2nd coulmn has its matching answer in random order. ',
            'The third column will be filled automatically when you type of the second column. ',
            'Then you can Drag/Drop and sort the third Column in order of correct answers which will be used by system to mark correct order. ',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ,
        data: ['You need to write an pragraph such that each sentence of that paragraph follows a sequence ',
            'Write down the the sentences of the paragraph in any sequence in 1st coulmn',
            'Second coulmn will automatically get filled up as is from 1st coulmn',
            'Drag and drop the 2nd coulmn and sort it in correct sequence which will be used by system to mark correct order',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.SENTENCE_SEQUENCE,
        data: ['You have to wirte a sentence of different words. i.e. ( A quick brown cunning fox jumps over a lazy dog ). ',
            'Quiz will randomize the words in the sentences and present it to the user. ',
            'User have to Drag/Drop and sequence the sentence in the correct sequence.',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.SEQUENCING_QUIZ,
        data: ['You need to write an event or any process such that each sentence of that event or process follows a sequence ',
            'For eg making tea, this is a process and will follow a sequence of steps',
            'Write down the the sentences of the event in any sequence in 1st coulmn',
            'Second coulmn will automatically get filled up as is from 1st coulmn',
            'Drag and drop the 2nd coulmn and sort it in correct sequence which will be used by system to mark correct order',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.SORTING_CLASSIFICATION,
        data: ['Enter the unique name in the form given to you to create the question like "Sort the given rocks into its different Types"',
            'Rocks can have 3 different forms like Igneous Rocks, Metamorphic Rocks and Sdimentory rocks',
            'When you Cick "Add" button it will create a box to which you can give a name.',
            'These boxes which will be used by users to place all the rocks of a type in respective boxes.',
            'So you would need 3 such boxes Igneous Rocks, Metamorphic Rocks and Sdimentory rocks. ',
            'You have to click on the ' + ' button within the box to add different fields or rocks name for this eg and fill the fields. ',
            'You need to repeat this for all boxes.',
            'Users will see these 3 boxes in his question and all the fields will appear below these 3 boxes',
            'User need to drag and drop these fields in its respective boxes as per the question',
            'Click on save and next button to save the question with correct answer']
    },
    {
        name: questionsCategoryConstants.TRUE_FALSE,
        data: ['You need to write a question statement', '2 options to be provided as True and False as an answer to the statement question',
            'Select the option from dropdown which is the correct answer for the question',
            'Click on save and next button to save the question with correct answer']
    },

]
const CategoryStaticCreationRules = ({ val }) => {
    const [selectCat, setselectCat] = useState({
        name: '',
        data: ['']
    })
    useEffect(() => {

        if (val) {
            const found = staticData.find(v => v.name === val.name)
            if (!found) return;
            setselectCat(found)
        }
    }, [val])
    //console.log('selectCat==>', selectCat)
    return (
        <div className='space-y-3 px-5 list'>
            {
                selectCat &&
                <>
                    <h1 className='text-lg tracking-wide font-bold !capitalize' >{selectCat?.name?.replace(/_/g, ' ')}</h1>
                    <ul >
                        {
                            selectCat.data?.map((item) => {
                                return <li>{item}</li>
                            })
                        }
                    </ul>
                </>
            }
        </div>
    )
}

export default CategoryStaticCreationRules