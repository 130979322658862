import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import CategoryStaticCreationRules from './CategoryStaticCreationRules'
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open,
    setOpen }) {
    const { question } = useSelector(s => s)
    const [value , setValue] = React.useState('')
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    React.useEffect(()=>
    {
    
    },[value])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth={'md'}
            >
                <AppBar position='sticky'>
                    <Toolbar className='!flex !items-center !justify-between'>
                        <h1 className='text-white text-2xl font-bold'>Help</h1>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className='p-5 grid grid-cols-1 gap-4'>
                    <select value = {value} name="test" onChange={(e)=>{setValue(e.target.value)}}>

                    <option value="">Select an option</option>
                    {
                        question.questionsCategories.map((val, index) => {
                            
                            return (
                            
                             (<option value={val.name} key = {index}>{val.name.replace(/_/g, ' ')}</option>)
                        )})
                    }
                    </select>
                    <CategoryStaticCreationRules val={question.questionsCategories.find((item)=> item.name === value)}  />
                </div>
            </Dialog>
        </div>
    );
}
