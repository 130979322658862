import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
import CancelledQuizzCard from './CancelledQuizzCard'
const CancelledQuizzesC = () => {
    const [laoding, setlaoding] = useState(false)
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setlaoding(true)
                const _obj = {
                    user: auth.data.user._id
                }
                const res = await axios.post(`/get/quizzez/for/distributeprizez?page=${page}&limit=${limit}`, {
                    ..._obj
                })
                if (res.status === 200) {
                    //console.log('questions==>', res.data)
                    setquestions(res.data.data)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setlaoding(false)
            } catch (error) {
                setlaoding(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit])
    const handleUpdatedQuizz = (quizz) => {
        const updatedQuestions = questions.map(val => val._id === quizz._id ? quizz : val)
        setquestions(updatedQuestions)
    }
    return (
        <>
            {
                laoding ?
                    <div className='w-full flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?

                        <>
                            <div className='mt-10'>
                                <CardContainer>
                                    <div className='grid lg:grid-cols-3 grid-cols-1   gap-3'>
                                        {
                                            questions.map((val, index) => (
                                                <CancelledQuizzCard
                                                    handleUpdatedQuizz={handleUpdatedQuizz}
                                                    quizz={val} key={index} user={auth.data.user._id} />

                                            ))
                                        }
                                    </div>
                                </CardContainer>
                                <div className='flex justify-center px-5 pt-5'>
                                    <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                                </div>
                            </div>
                            <AdsContainer>
                            </AdsContainer>
                        </>
                        :
                        <NotFoundDataUi text={`No quiz available`} />
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No quizz available.y..</div>
            }

        </ >
    )
}
const Cont = styled.div`

            `
const AdsContainer = styled.div`
            ${tw`col-span-1`}
            `
const CardContainer = styled.div`
            ${tw`
lg:col-span-7 col-span-8 
`}
            `
const Container = styled.div`
            ${tw`grid grid-cols-8 mt-10`}
            `
export default CancelledQuizzesC