import React, { useEffect, useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
import { approveQuestionAction, rejectQuestionAction } from '../../../actions/question.actions'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox';
const TableRow = ({
    val,
    questions,
    setquestions,
    schedulerData,
    setschedulerData
}) => {
    const { auth } = useSelector(s => s)
    const disaptch = useDispatch()
    const [open, setopen] = useState(false)
    const [isChecked, setisChecked] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleApprove = () => {
        // console.log(val)
        disaptch(approveQuestionAction(val._id)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleReject = () => {
        // console.log(val)
        disaptch(rejectQuestionAction(val._id)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleCheckBoxChange = (e) => {
        const isFound = schedulerData.questions.find(q => q._id === val._id)
        if (isFound) {
            setschedulerData({
                ...schedulerData,
                questions: schedulerData.questions.filter(q => q._id !== val._id)
            })
        } else {
            setschedulerData({
                ...schedulerData,
                questions: [...schedulerData.questions, val]
            })
        }
    }
    useEffect(() => {
        const isFound = schedulerData.questions.find(q => q._id === val._id)
        if (isFound) setisChecked(true)
        else setisChecked(false)

    }, [schedulerData, isChecked])
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} />
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <Checkbox checked={isChecked} onChange={handleCheckBoxChange} inputProps={{ 'aria-label': 'controlled' }} />
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val.createdAt).subtract(10, 'days').calendar()}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val?.statement}</span>
                </td>
                <td class="py-3 px-6 text-left">
                    <span>{(val?.questionCategory?.name).replace(/_/g, ' ')}</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span>{val.subject}</span>
                </td>
                <td class="py-3 px-6 text-center">
                    {
                        val.approveStatus === 'pending' ?
                            <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">pending</span>
                            :
                            val.approveStatus === 'rejected' ?
                                <span class="bg-red-400 text-white py-1 px-3 rounded-full text-xs">rejected</span>
                                :
                                <span class="bg-green-400 text-white py-1 px-3 rounded-full text-xs">approved</span>

                    }
                </td>
            </tr>
        </>
    )
}

export default TableRow