import React, {useState, useEffect} from 'react'
import axios from '../../utils/myAxios'

function FAQModal({setOpen}) {
    const [faq, setfaq] = useState()
    const [types, setTypes] = useState()
    const handleChange = (e) => {
        const {value, name} = e.target
        setfaq({
            ...faq,
            [name]: value
        })
    }
    const handleSubmit = () => {
        console.log(faq)
        axios.post('/addFAQ', faq).then(res => {
            setOpen(false)
            console.log(res.data)
        })
    }
    useEffect(() => {
        axios.get('/getTypes').then(res => {
            setTypes(res.data.types)
            console.log(res.data)
        })
    }, [])


  return (
    <div className=' p-3 flex flex-col w-full bg-white rounded-md'>
        <div className=''>
            <label htmlFor="">FAQ category </label>
            <select onChange={(e)=>handleChange(e)} name = "faqType" id="" className='w-full h-9 rounded-lg border'>
                <option value="">Choose the FAQ type</option>
                {
                    types && types.map(type => {
                        return <option value={type._id}>{type.name}</option>
                    })
                }
            </select>
        </div>
        <div className=''>
            <label htmlFor="">Question</label>
            <input type="text" className='w-full h-9 rounded-lg border' onChange={(e)=>handleChange(e)} name = 'question' />
        </div>
        <div className=''>
            <label htmlFor="">Answer</label>
            <input type="text" className='w-full h-9 rounded-lg border' onChange={(e)=>handleChange(e)} name = 'answer' />
        </div>
        <div className='w-full flex justify-center py-5 gap-2'>
            <button className='px-2 h-9 rounded-full border bg-red-400 text-white hover:text-red-400 hover:bg-white' onClick={()=>setOpen(false)}>Cancel</button>
            <button className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white' onClick={()=>handleSubmit()}>Add FAQ</button>
        </div>
    </div>
  )
}

export default FAQModal 