import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { globalConstants, lifeLinesConstants, questionsCategoryConstants } from '../../../actions/appConstants'
const data = [
    'first questio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahnquestio;sddkhf;dlsk dsak;fhsdahn',
    'seconds',
    'third',
    'fourth'
]
const Option = ({
    index, value,
    selectedOptionIndex,
    setselectedOptionIndex,
    showCorrectAnswer,
}) => {
    return (
        <div className='flex items-center  w-full '>
            <div className={`border h-10
             ${index === 0 ? 'bg-mylightyellow' : ''}
             ${index === 1 ? 'bg-mylightgreen' : ''}
             ${index === 2 ? 'bg-mydarkgreen' : ''}
             ${index === 3 ? 'bg-mydarkyellow' : ''}
              px-4 flex items-center justify-center`}>
                <span>{`
                  ${index === 0 ? 'A' : ''}
                  ${index === 1 ? 'B' : ''}
                  ${index === 2 ? 'C' : ''}
                  ${index === 3 ? 'D' : ''}
                  `}</span>
            </div>
            <span
                onClick={() => setselectedOptionIndex(index)}
                className={` cursor-pointer border border-black w-full outline-none rounded-md border-opacity-50 px-2 py-2
                ${(selectedOptionIndex === index) ? 'bg-green-300' : showCorrectAnswer ? 'bg-yellow-200' : ''}
                `}
            >
                {value}
            </span>
        </div>
    )
}
const MultipleQuestions = ({ question, handleLeaveQuestion, handleNextQuestion, isLastQuestion }) => {
    const dispatch = useDispatch()
    const [mapForFiftyFifty, setmapForFiftyFifty] = useState([])
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion
    } = lifeLines;
    //console.log('multiple question==>', question)
    const [selectedOptionIndex, setselectedOptionIndex] = useState(null)
    const handleValidation = () => {
        if (selectedOptionIndex === null) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly select atleast one option!" } })
        let correct = false;
        if (clickedFiftyFifty) {
            if (mapForFiftyFifty[selectedOptionIndex].option === question.correctAnswer) {
                correct = true
            }
        } else {
            if (question.options[selectedOptionIndex].option === question.correctAnswer) {
                correct = true
            }
        }

        handleNextQuestion({ ...{ ...question, userSubmission: selectedOptionIndex > -1 ? question.options[selectedOptionIndex].option : "" }, correct })
        setselectedOptionIndex(null)
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: selectedOptionIndex > -1 ? question.options[selectedOptionIndex].option : "" }, correct: false })
    }
    //make array for the fifty-fifty life line
    useEffect(() => {
        if (question.questionCategory.name === questionsCategoryConstants.MULTIPLE_QUESTIONS) {
            let map = []
            question.options.forEach(q => {
                if (q === question.correctAnswer) {
                    map.push(q)
                }
            });
            question.options.forEach(q => {
                if (q !== question.correctAnswer && map.length < 2) {
                    map.push(q)
                }
            });
            setmapForFiftyFifty(map)
        }
    }, [question])
    //make array for the fifty-fifty life line...

    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: true,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...
    return (
        <Cont>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-10'>
                {
                    clickedShowCorrectAnswer ?
                        question.options.map((val, index) => (
                            <Option
                                key={index}
                                index={index}
                                value={val.option}
                                selectedOptionIndex={selectedOptionIndex}
                                setselectedOptionIndex={setselectedOptionIndex}
                                showCorrectAnswer={(clickedShowCorrectAnswer && question.options[index].option === question.correctAnswer) ? true : false}
                            />
                        ))
                        :
                        clickedFiftyFifty ?
                            mapForFiftyFifty.map((val, index) => (
                                <Option
                                    key={index}
                                    index={index}
                                    value={val.option}
                                    selectedOptionIndex={selectedOptionIndex}
                                    setselectedOptionIndex={setselectedOptionIndex}
                                />
                            ))
                            :
                            question.options.map((val, index) => (
                                <Option
                                    key={index}
                                    index={index}
                                    value={val.option}
                                    selectedOptionIndex={selectedOptionIndex}
                                    setselectedOptionIndex={setselectedOptionIndex}
                                />
                            ))
                }
            </div>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button
                    onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'
                >
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </Cont>
    )
}
const Cont = styled.div`
`
export default MultipleQuestions