import { quizzithonActions } from "../actions/quizzithon.actions";


import axios from "../utils/myAxios";




const inititalState = {
    quizzithon: {
        title: '',
        no_quizzes: null,
        start_time: '',
        end_time: '',
        schedule_time: '',
        type: 'free',
        scheduled_by: '',
        reg_fee: null,
        min_participants: null,
        quizzes: []
    }
}
const quizzithonReducer = (state = inititalState, action) => {

    switch (action.type) {

        case quizzithonActions.UPDATE_QUZZITHON:
            
            state = {
                ...state,
                quizzithon: { ...state.quizzithon, [action.payload.target.name]: action.payload.target.value }
            }
            if (action.payload.target.name === 'no_quizzes') {
                let arr = []
                for (let i = 0; i < action.payload.target.value; i++) {
                    arr.push({
                        subject: "",
                        title: "",
                        createdBy: state.quizzithon.scheduled_by,
                        category: "",
                        type: "",
                        price: 20,
                        requiredPointsForFree: 0,
                        date: new Date(),
                        time: new Date(),
                        duration: 0,
                        minimumNumberOfSeats: 1,
                        questions: [],
                        spinWheal: {},
                        eliminatedQuiz: {
                            lifelines: {
                                fiftyfifty: 1,
                                showCorrectAnswer: 1,
                                skipQuestion: 1
                            }
                        },
                        questions_info: {
                            count: 0,
                            subjects: [
                                {
                                    cat: "",
                                    subject: "",
                                    count: 1
                                }
                            ]
                        },
                        isSchoolTypeQuizz: false,


                        resheduleAllow: false,
                        rescheduleTime: new Date(),
                        rescheduleDate: new Date(),
                        rescheduleDuration: 0,
                        durationDateComplete: new Date(),
                        ResheduledurationDateComplete: new Date(),


                        tictactoeRows: 0,
                        quizzSubject: "",

                        isAdmin: false,


                        //duration bound
                        isDurationBound: false,
                        durationBoundMinutes: 0,

                        //pending implementation
                        durationBoundEndTime: new Date(Date),
                    })
                }
                state = {
                    ...state,
                    quizzithon: { ...state.quizzithon, quizzes: arr }
                }

            }

            break;
        case quizzithonActions.UPDATE_QUIZ:
            try {
                const arr = [...state.quizzithon.quizzes]
                arr[action.payload.quizIndex] = action.payload.quiz
                // Example ISO date strings

                
                // Extract date from the first ISO string
                if (action.payload.quiz?.date?.length === 0 || action.payload.quiz?.time?.length === 0 || action.payload.quiz?.durationDateComplete?.length === 0) {

                    break;
                } else {
                    const date = new Date(action.payload.quiz?.date);
                    const year = date.getUTCFullYear();
                    const month = `0${date.getUTCMonth() + 1}`?.slice(-2);
                    const day = `0${date.getUTCDate()}`?.slice(-2);

                    // Extract time from the second ISO string
                    const time = action.payload.quiz?.time?.slice(11, 16); // Extracts the time portion from index 11 to 15 (HH:MM)
                    const endTime = action.payload?.quiz?.durationDateComplete?.slice(11, 16);
                    // Create the new date string by combining the extracted date and time
                    const newStartDate = `${year}-${month}-${day}T${time}:00Z`;
                    const newEndTime = `${year}-${month}-${day}T${endTime}:00Z`;
                    

                    if (newStartDate.valueOf() < state.quizzithon.start_time.valueOf() || state.quizzithon.start_time === "") {
                        
                        state = {
                            ...state,
                            quizzithon: {
                                ...state.quizzithon,
                                start_time: newStartDate
                            }
                        }
                    }
                    if (newEndTime?.valueOf > state.quizzithon?.end_time.valueOf() || state.quizzithon?.end_time === "") {
                        
                        state = {
                            ...state,
                            quizzithon: {
                                ...state.quizzithon,
                                end_time: newEndTime
                            }
                        }
                        state = {
                            ...state,
                            quizzithon: {
                                ...state.quizzithon,
                                quizzes: arr
                            }
                        }
                    }
                }

                break;
            } catch (error) {
                break;
            }


        case quizzithonActions.SCHEDULE_QUIZZITHON:
            axios.post("/create/quizzithon", state.quizzithon)
                .then(res => {
                    state = {
                        ...state,
                        quizzithon: inititalState.quizzithon
                    }

                })
                .catch(err => {
                    
                }
                )
            break;

        case quizzithonActions.INITIALIZE_QUIZZITHON:
            state = {
                ...state,
                quizzithon: inititalState.quizzithon
            }
            break;

    }
    return state;
}
export default quizzithonReducer;
