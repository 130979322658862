import React from 'react'
import styled from 'styled-components'
import PastQuizzezC from '../components/pastQuizzezComponents/PastQuizzezC'
import Layout from '../components/Layout/Layout'
import tw from 'twin.macro'
import StaticCategoriesC from '../components/staticCategoriesC/StaticCategoriesC'
// import StaticCategoriesPage from '../components/'
const PastQuizzez = () => {
    return (
        <Layout>
            {/* <PastQuizzezC /> */}
            {/* show the categories */}
            <StaticCategoriesC isPastQuizzes={true} />
        </Layout>
    )
}

export default PastQuizzez