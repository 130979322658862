import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import axios from '../../utils/myAxios'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import QuizzCard from '../DashBoardComponets/QuizCards/QuizzCard'
import QuizzithonLeaderBoard from './QuizzithonLeaderBoard'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
import QuizzithonDetailCard from './QuizzithonDetailCard'
import CloseIcon from '@mui/icons-material/Close';
import { set } from 'lodash'
import ReviewCard from './ReviewCard'

function QuizzithonDetail() {
    let { id } = useParams()
    const { auth } = useSelector(state => state)
    const [quizzithonDetail, setQuizzithonDetail] = useState({})
    const [leaderboardData, setLeaderboardData] = useState([])
    const [reviewData, setReviewData] = useState([])
    const [reviewText, setReviewText] = useState('')
    const [open, setOpen] = useState(false)
    const [rules, setRules] = useState('')
    const [review, setReview] = useState(false)

    useEffect(() => {
        axios.get(`/get/quizzithon/${id}`)
            .then(res => {

                setQuizzithonDetail(res.data.quizzithon)
            })
            .catch(err => console.log(err))
    }, [id])
    useEffect(() => {
        axios.get(`/get/leaderboard/${id}`)
            .then(res => {
                setLeaderboardData(res.data.leaderBoardData)
            }
            )
            .catch(err => console.log(err))
    }, [quizzithonDetail])

    useEffect(() => {
        axios.get("/root/quizzithonrules")
            .then(res => {

                setRules(res.data.rules.rules)
            }
            )
            .catch(err => console.log(err))

    }, [])
    useEffect(() => {
        if (quizzithonDetail && leaderboardData) {
            axios.get(`/get/quizzithon/reviews/${quizzithonDetail._id}`)
            .then(res=>{
                setReviewData(res.data.reviews)
                
                let count = leaderboardData.reduce((acc,curr)=>{
                    if(curr.user ===auth.data.user._id){
                        return acc+1
                    }
                    return acc
                },0)
                

                if(res.data.reviews.findIndex(review=>review.user._id===auth.data.user._id)===-1 && count === quizzithonDetail.no_quizzes ){
                    setReview(true)

                }
            })
            .catch(err=>console.log(err))
        }
    }, [quizzithonDetail , leaderboardData])
    const manageReview = (e) => {
        setReviewText(e.target.value)
    }
    const submitReview = () => {
        axios.post('/post/quizzithon/review', { quizzithonId: quizzithonDetail._id, review: reviewText, userId: auth.data.user._id })
            .then(res => {
                setReview(false)
                setReviewText('')
                setReviewData(res.data.reviews)
            })
            .catch(err => console.log(err))

    }
    return (
        <Layout>
            {
                open && <div className='fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-50 flex justify-center items-center'>
                    <div className='w-1/2 h-[70vh] overflow-y-scroll bg-white rounded-md'>
                        <div className='flex justify-end items-center px-3'>

                            <button className=' text-red-400 ' onClick={() => setOpen(false)}><CloseIcon /></button>
                        </div>
                        <div className='p-3'>
                            <h1 className='text-lg text-yellow-500 py-3'>Quizzithin Rules</h1>
                            <div className='w-full text-lg px-10 py-3 list' dangerouslySetInnerHTML={{ __html: rules }} ></div>
                        </div>
                    </div>
                </div>

            }
            <>
                {review && <div className='fixed top-0 left-0 w-full h-screen bg-black bg-opacity-50 z-50 flex justify-center items-center'>
                    <div className='w-[60%] bg-white border rounded-md z-10 py-4'>
                        <div className='flex justify-end items-center px-3'>

                            <button className=' text-red-400 ' onClick={() => setReview(false)}><CloseIcon /></button>
                        </div>
                        <div className='flex justify-center items-center flex-col gap-4'>
                            <h2 className='text-left w-[90%] font-semibold text-yellow-400'>User Review</h2>
                            <textarea name="" id="" className='w-[90%] rounded-md' placeholder='Write your review here' onChange={(e) => manageReview(e)}></textarea>
                            <button className='bg-yellow-500 px-3 text-center border text-white border-yellow-400 hover:text-yellow-400 hover:bg-white rounded-full' onClick={()=>submitReview()} >Submit</button>
                        </div>
                    </div>
                </div>}
                {

                    quizzithonDetail &&
                    <div className=' p-5 rounded-md'>
                        <div className='flex justify-between  w-full bg-white items-center rounded-xl'>
                            <h1 className='text-lg p-3'>Quizzithion / {quizzithonDetail?.title}</h1>
                            <button className='w-32 h-8 bg-green-400 text-white border rounded-full m-3 hover:bg-white hover:text-green-400 border-green-400 ' onClick={setOpen}>Rules</button>
                        </div>


                        {/* {quizzithonDetail?.quizzes?.length > 0 ? <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        <h2>Quizzes</h2>
                        {
                            quizzithonDetail?.quizzes?.map((quiz) => {
                                console.log("quizzes ====>", quiz)
                                return <QuizzCard quizz={quiz.quiz_id} user={auth?.data?.user._id} />
                            })
                        }
                        

                    </div> : <div className='w-full text-center py-5'><NotFoundDataUi text = "No quizzes to show"/></div> } */}
                        <div className='w-full'>
                            <QuizzithonDetailCard quizzithonDetails={quizzithonDetail} />
                        </div>
                        <div className='my-2'>
                            <h1 className='text-lg text-yellow-500 py-3'>Leaderboard</h1>
                            <QuizzithonLeaderBoard leaderboardData={leaderboardData} quizzes={quizzithonDetail?.quizzes} quizzithon={quizzithonDetail} />
                        </div>
                        <div className="my-2">
                            <h2 className='text-yellow-500 text-lg py-3'>User Reviews</h2>
                            <div className='w-full flex flex-col gap-4'>
                            {
                                
                                reviewData?.length > 0 ? reviewData.map((review) => {

                                    return <ReviewCard review = {review} />
                                }) : <span>No reviews to show</span>
                            }
                            </div>
                        </div>


                    </div>}
            </>

        </Layout>
    )
}

export default QuizzithonDetail