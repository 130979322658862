import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants } from '../../../actions/appConstants'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
import Option from '../MatchThePair/Options'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const SequenceQuizz = ({ type, subject, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const [alphabets, setalphabets] = useState(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"])
    const dispatch = useDispatch()
    const { question, auth } = useSelector(state => state)
    const initial_state = {
        statement: '',
        collumns: {
            a: [''],
            b: ['']
        }
    }
    const [sequenceQuizz, setsequenceQuizz] = useState(initial_state)
    const { statement, collumns } = sequenceQuizz
    const onChangeHandler = (targetCol, index, value) => {
        const data = {
            ...sequenceQuizz,
            collumns: {
                ...sequenceQuizz.collumns,
                a: sequenceQuizz.collumns[targetCol].map((_val, _index) => _index === index ? value : _val),
                b: sequenceQuizz.collumns[targetCol].map((_val, _index) => _index === index ? value : _val),
            }
        }
        setsequenceQuizz({
            ...sequenceQuizz,
            collumns: data.collumns
        })
    }
    const handleSubmit = async (arg) => {
        let obj = {
            ...sequenceQuizz,
            questionCategory: type,
            subject,
        }
        let avail = true
        for (let x = 0; (x < sequenceQuizz.collumns.a.length && avail); x++) {
            if (!(sequenceQuizz.collumns.a[x]).trim()) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: `kindly fill the fields` }
                })
                avail = false
                break;
            }
        }
        for (let x = 0; (x < sequenceQuizz.collumns.b.length && avail); x++) {
            if (!(sequenceQuizz.collumns.b[x]).trim()) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: `kindly fill the fields` }
                })
                avail = false
                break;
            }
        }
        if (!avail) return;
        if (!(sequenceQuizz.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill the statement!" }
            })
        }
        const _idFind = question.questionsCategories.find((v) => v.name === obj.questionCategory)
        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id,
            statement: sequenceQuizz.statement,
        }
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question category!" }
            })
        }
        if (!(obj.subject).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill subject!" }
            })
        }
        //console.log('obj==>', obj)
        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        if (arg) {
            setsequenceQuizz(initial_state)
        } else {
            setsequenceQuizz(initial_state)
            setshowAddQuestions(false)
        }
    }
    const handleAddMore = () => {
        setsequenceQuizz({
            ...sequenceQuizz,
            collumns: {
                a: [...sequenceQuizz.collumns.a, ''],
                b: [...sequenceQuizz.collumns.b, '']
            }
        })
    }
    const handleRemoveLastRow = () => {
        if (sequenceQuizz.collumns.a.length < 2) return;
        //console.log('in the thing==.')
        sequenceQuizz.collumns.a.pop()
        sequenceQuizz.collumns.b.pop()
        setsequenceQuizz({ ...sequenceQuizz })
        //console.log('in the thing==.')
    }
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(sequenceQuizz.collumns.b);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setsequenceQuizz({
            ...sequenceQuizz,
            collumns: {
                ...sequenceQuizz.collumns,
                b: items
            }
        })
        //console.log(items)
    }
    useEffect(() => {
        if (showQuestinData) {
            setsequenceQuizz(showQuestinData)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <Cont>
            <div className='space-y-2 mt-5'>
                <h1 className='text-bgblue font-bold'>Sequence quiz</h1>
                <input
                    className='border border-black py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    placeholder='write sequence statement...'
                    onChange={(e) => setsequenceQuizz({ ...sequenceQuizz, statement: e.target.value })}
                />
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-3 mt-10'>
                <div>
                    <h1 className='text-bgblue font-semibold'>Random sequence</h1>
                    {
                        sequenceQuizz?.collumns?.a?.map((val, index) => (
                            <Option targetCol='a' key={index} onChangeHandler={onChangeHandler} index={index} value={val} />
                        ))
                    }
                </div>
                <div>
                    <h1 className='text-bgblue font-semibold'>Correct sequence</h1>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='characters'>
                            {
                                (provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {
                                            sequenceQuizz.collumns.b.map((val, index) => (
                                                <div key={index}>
                                                    <Draggable key={val} draggableId={val} index={index}>
                                                        {
                                                            (provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                    className='flex items-center h-10 w-full'>
                                                                    <div className={`border h-full
                                                 ${index % 2 === 0 ? 'bg-mylightyellow' : 'bg-mylightgreen'}
                                                  px-4 flex items-center justify-center`}>
                                                                        <span>{alphabets[index]}</span>
                                                                    </div>
                                                                    <input
                                                                        value={val}
                                                                        disabled={true}
                                                                        // targetCol
                                                                        type='text'
                                                                        onChange={(e) => { onChangeHandler('c', index, e.target.value) }}

                                                                        className='border border-black w-full h-full outline-none border-l-0 border-l-white border-opacity-50 px-2'
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </Draggable>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                    {/* {
                        sequenceQuizz.collumns.b.map((val, index) => (
                            <Option targetCol='b' key={index} onChangeHandler={onChangeHandler} index={index} value={val} />
                        ))
                    } */}
                </div>
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <>
                        <div className=''>
                            {
                                sequenceQuizz?.collumns?.a.length > 1 &&
                                <div>
                                    <button onClick={handleRemoveLastRow} className='bg-red-500 text-white py-3 px-5 rounded-md text-xs mt-3'>Remove last row</button>
                                </div>
                            }
                            <div>
                                <button className='mt-10 text-white bg-bgblue py-2 px-4 rounded-md' onClick={handleAddMore}>Add more</button>
                            </div>
                        </div>

                        <BottomButtons>
                            <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                            <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                        </BottomButtons>
                    </>
            }
        </Cont>
    )
}
const Cont = styled.div`
`
const BottomButtons = styled.div`
${tw`flex items-center justify-end mt-16 space-x-2`}
`
export default SequenceQuizz