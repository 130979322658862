import React from 'react'
import ScheduleTable from './../ScheduleTable/ScheduleTable'
const FreeTab = ({ setshowUpdateQuiz }) => {
  return (
    <>
      <ScheduleTable
        type='free'
        setshowUpdateQuiz={setshowUpdateQuiz} />
    </>
  )
}

export default FreeTab