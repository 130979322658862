import React from 'react'
import ScheduleTable from '../ScheduleTable/ScheduleTable'
const PaidTab = ({ setshowUpdateQuiz }) => {
  return (
    <>
      <ScheduleTable
        type='paid'
        setshowUpdateQuiz={setshowUpdateQuiz} />
    </>
  )
}

export default PaidTab