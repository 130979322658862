import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
//slick css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from '../../../utils/myAxios'
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",

};
const ReviewsSection = ({ qid }) => {
    const { auth } = useSelector(s => s)
    const [reviews, setreviews] = useState([])
    useEffect(async () => {
        if (qid) {
            try {
                const obj = {
                    qid: qid
                }
                const res = await axios.post('/get/users/reviews', {
                    ...obj
                })
                setreviews(res.data.reviews)
            } catch (error) {

            }
        }
    }, [qid])
    return (
        <>
            <h1 className='uppercase tracking-widest mt-10 mb-3'>Users Reviews</h1>
            <div className='bg-white rounded-xl'>
                <div className='lg:w-[50%] w-[85%] m-auto  mb-10 py-5  rounded-xl'>
                    <Slider {...settings} className='my-5'>
                        {
                            reviews.map((val, index) => (
                                <div key={index} className='bg-gray-100 p-5 w-full rounded-xl flex '>
                                    <div className='flex justify-center flex-col items-center'>
                                        <Avatar src={val.user.profileImage} sx={{
                                            width: '80px',
                                            height: '80px'
                                        }} />
                                        <h1 className='text-[16px] font-semibold italic  text-center'>{val.user.userName}</h1>
                                    </div>
                                    <div className='p-3'>
                                        <p className=' font-semibold tracking-widest text-[12px] italic !text-center'>
                                            {val.review}
                                        </p>
                                    </div>
                                </div>

                            ))
                        }
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default ReviewsSection