import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import MyLoader from '../timerandstatement/MyLoader'
const TimerAndStatement = ({
    isPastQuiz,
    simpleQuizz,
    questionsArray,
    questionIndex,
    handleOnTimesUpAction,
    setcalculateTimeTaken,
    calculateTimeTaken,
    settimerTimeIsFinished,
}) => {
    return (
        <Cont>
            <div className='relative h-28 lg:w-[60%] w-full border border-bgblue rounded-2xl border-opacity-50'>
                <MyLoader settimerTimeIsFinished={settimerTimeIsFinished} calculateTimeTaken={calculateTimeTaken} setcalculateTimeTaken={setcalculateTimeTaken} isPastQuiz={isPastQuiz} handleOnTimesUpAction={handleOnTimesUpAction} simpleQuizz={simpleQuizz} />
                <h1 className='text-xl font-bold p-2 text-center mt-5'>{
                    questionsArray.length > 0 &&
                        questionsArray[questionIndex]?.statement ? questionsArray[questionIndex]?.statement : ''
                }</h1>
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw`flex items-center justify-center p-5 mt-5`}
`
export default TimerAndStatement