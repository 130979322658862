import React, { useState, useEffect } from 'react'
import axios from '../utils/myAxios'
import Layout from './Layout/Layout'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PersonOffIcon from '@mui/icons-material/PersonOff';
import quizImg from '../assets/staticdata/followers.png'
import { CircularProgress } from '@mui/material'

import UserCard from './UserCard';

function Following() {
  const [loader, setLoader] = useState(true)
  const { type } = useParams()
  const navigate = useNavigate()
  const [followData, setFollowData] = useState([])
  const { auth } = useSelector(s => s)
  useEffect(() => {
    setFollowData([])
    setLoader(true)
    if (type === 'following') {
      axios.post('/getsingleuser', { IDs: auth.data.user.following })
        .then(body => {
          setLoader(false)
          setFollowData(body.data.users)
        })
        .catch(err=> setLoader(false))
    }
    else if (type === "followers") {
      axios.post('/getsingleuser', { IDs: auth.data.user.followers })
      .then(body => {
        setLoader(false)
        setFollowData(body.data.users)
      })
      .catch(err=> setLoader(false))
    }
    


  }, [type]);
  useEffect(() => {

  }, [followData, type])
  return (
    <Layout>
      <>
        {loader ? <div className='w-full h-screen flex justify-center items-center'><CircularProgress /></div> : <>
          {followData.length > 0 ? <><h1 className='text-lg md:text-2xl text-center capitalize text-gray-600'>{type === "followers" ? "Quizlovers following you, keep doing the great job" : "Quizmasters followed by you"}</h1>
            {/* <StaticCategoriesC fid = "following"/> */}
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
              {
                followData.map((item) => {

                  return <UserCard item={item} />
                }

                )
              }
            </div></> : type === 'followers' ?
            <div className='w-full flex flex-col items-center'>
              <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
              <div className='w-full flex flex-col items-center'>
                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                  You don't have any followers, Lets help you getting followed by other quiz lovers. schedule quizzes and get rewarded.
                </p>
                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/schedulequiz')}>Schedule Quiz</button>
              </div>
            </div> :
            <div className='w-full flex flex-col items-center'>
              <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
              <div className='w-full flex flex-col items-center'>
                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                  You don't follow any quizmaster, Check out our quizmaster.
                </p>
                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/quizmasters')}>Quiz Masters</button>
              </div>
            </div>

          }

        </>}
      </>

    </Layout>
  )
}

export default Following