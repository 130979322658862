import React, { useState } from 'react'
import styled from 'styled-components'
import Options from './Options'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const DragAbleC = ({
    c, onChangeHandler, onChangeHandlerForBC,
    setmatchThePairData,
    matchThePairData,
}) => {
    const [alphabets, setalphabets] = useState(["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"])
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(c);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setmatchThePairData({
            ...matchThePairData,
            collumns: {
                ...matchThePairData.collumns,
                c: items
            }
        })
        //console.log(items)
    }
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='characters'>
                {
                    (provided) => (
                        <Cont {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                c.map((val, index) => (
                                    <div key={index}>
                                        <Draggable key={val} draggableId={val} index={index}>
                                            {
                                                (provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        className='flex items-center h-10 w-full'>
                                                        <div className={`border h-full
                                                 ${index % 2 === 0 ? 'bg-mylightyellow' : 'bg-mylightgreen'}
                                                  px-4 flex items-center justify-center`}>
                                                            <span>{alphabets[index]}</span>
                                                        </div>
                                                        <input
                                                            value={val}
                                                            disabled={true}
                                                            // targetCol
                                                            type='text'
                                                            onChange={(e) => { onChangeHandlerForBC('c', index, e.target.value) }}

                                                            className='border border-black w-full h-full outline-none border-l-0 border-l-white border-opacity-50 px-2'
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Draggable>
                                    </div>
                                ))
                            }
                        </Cont>
                    )
                }
            </Droppable>
        </DragDropContext>
    )
}
const Cont = styled.div`

`
export default DragAbleC