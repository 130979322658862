import React from 'react'
import closed from '../../assets/categoriesimg/closed.png'
import columns from '../../assets/categoriesimg/columns.png'
import cross from '../../assets/categoriesimg/cross.png'
import drag from '../../assets/categoriesimg/drag-and-drop.png'
import drag2 from '../../assets/categoriesimg/drag.png'
import experiment from '../../assets/categoriesimg/experiment.png'
import fill from '../../assets/categoriesimg/fill.png'
import indent from '../../assets/categoriesimg/indent.png'
import kickOff from '../../assets/categoriesimg/kick-off.png'
import multiple from '../../assets/categoriesimg/multiple.png'
import sequence from '../../assets/categoriesimg/sequence.png'
import sharing from '../../assets/categoriesimg/sharing.png'
import spin from '../../assets/categoriesimg/spin.png'
import ticTacToe from '../../assets/categoriesimg/tic-tac-toe.png'
import trueFalse from '../../assets/categoriesimg/true-or-false.png'
import waste from '../../assets/categoriesimg/waste.png'
import word from '../../assets/categoriesimg/word.png'
function Slider() {
    return (
        <>
        <h1 className='text-[32px] text-center text-yellow-500 mt-10'>Quiz Categories</h1>
        <div className='slider'>
            <div className="slide-track text-[16px]">
                <div className="slide flex">
                    <img src={closed} alt="" />
                    <span className='text-[16px]'>Closed Ended</span>
                </div>
                <div className="slide">
                    <img src={columns} alt="" />
                    <span className='text-[16px]'>Match The Pair</span>
                </div>
                <div className="slide">
                    <img src={multiple} alt="" />
                    <span className='text-[16px]'>Multiple Choice</span>
                </div>
                <div className="slide">
                    <img src={fill} alt="" />
                    <span className='text-[16px]'>Fill in the Blanks</span>
                </div>
                <div className="slide">
                    <img src={sequence} alt="" />
                    <span className='text-[16px]'>Sequence Quiz</span>
                </div>
                <div className="slide">
                    <img src={indent} alt="" />
                    <span className='text-[16px]'>Paragraph Ordering</span>
                </div>
                <div className="slide">
                    <img src={sharing} alt="" />
                    <span className='text-[16px]'>Identify Image Voice or Video</span>
                </div>
                <div className="slide">
                    <img src={spin} alt="" />
                    <span className='text-[16px]'>Spin the wheel</span>
                </div>
                <div className="slide">
                    <img src={kickOff} alt="" />
                    <span className='text-[16px]'>Eliminator Quiz</span>
                </div>
                <div className="slide">
                    <img src={experiment} alt="" />
                    <span className='text-[16px]'>Mix Type Quiz</span>
                </div>
                <div className="slide">
                    <img src={trueFalse} alt="" />
                    <span className='text-[16px]'>True or False</span>
                </div>
                <div className="slide">
                    <img src={cross} alt="" />
                    <span className='text-[16px]'>Cross Words</span>
                </div>
                <div className="slide">
                    <img src={drag} alt="" />
                    <span className='text-[16px]'>Drag Drop Fill in The Blanks</span>
                </div>
                <div className="slide">
                    <img src={closed} alt="" />
                    <span className='text-[16px]'>Closed Ended</span>
                </div>
                <div className="slide">
                    <img src={cross} alt="" />
                    <span className='text-[16px]'>Cross Words</span>
                </div>
                <div className="slide">
                    <img src={word} alt="" />
                    <span className='text-[16px]'>Sentence Sequence</span>
                </div>
                <div className="slide">
                    <img src={waste} alt="" />
                    <span className='text-[16px]'>Sorting Classification</span>
                </div>
                <div className="slide">
                    <img src={drag2} alt="" />
                    <span className='text-[16px]'>Drag Drop Visual Stimulating</span>
                </div>
                <div className="slide">
                    <img src={ticTacToe} alt="" />
                    <span className='text-[16px]'>Tic Tac Toe</span>
                </div>



                <div className="slide flex">
                    <img src={closed} alt="" />
                    <span className='text-[16px]'>Closed Ended</span>
                </div>
                <div className="slide">
                    <img src={columns} alt="" />
                    <span className='text-[16px]'>Match The Pair</span>
                </div>
                <div className="slide">
                    <img src={multiple} alt="" />
                    <span className='text-[16px]'>Multiple Choice</span>
                </div>
                <div className="slide">
                    <img src={fill} alt="" />
                    <span className='text-[16px]'>Fill in the Blanks</span>
                </div>
                <div className="slide">
                    <img src={sequence} alt="" />
                    <span className='text-[16px]'>Sequence Quiz</span>
                </div>
                <div className="slide">
                    <img src={closed} alt="" />
                </div>
                <div className="slide">
                    <img src={indent} alt="" />
                    <span className='text-[16px]'>Paragraph Ordering</span>
                </div>
                <div className="slide">
                    <img src={sharing} alt="" />
                    <span className='text-[16px]'>Identify Image Voice or Video</span>
                </div>
                <div className="slide">
                    <img src={spin} alt="" />
                    <span className='text-[16px]'>Spin the wheel</span>
                </div>
                <div className="slide">
                    <img src={kickOff} alt="" />
                    <span className='text-[16px]'>Eliminator Quiz</span>
                </div>
                <div className="slide">
                    <img src={experiment} alt="" />
                    <span className='text-[16px]'>Mix Type Quiz</span>
                </div>
                <div className="slide">
                    <img src={trueFalse} alt="" />
                    <span className='text-[16px]'>True or False</span>
                </div>
                <div className="slide">
                    <img src={cross} alt="" />
                    <span className='text-[16px]'>Cross Words</span>
                </div>
                <div className="slide">
                    <img src={drag} alt="" />
                    <span className='text-[16px]'>Drag Drop Fill in The Blanks</span>
                </div>
                <div className="slide">
                    <img src={closed} alt="" />
                    <span className='text-[16px]'>Closed Ended</span>
                </div>
                <div className="slide">
                    <img src={cross} alt="" />
                    <span className='text-[16px]'>Cross Words</span>
                </div>
                <div className="slide">
                    <img src={word} alt="" />
                    <span className='text-[16px]'>Sentence Sequence</span>
                </div>
                <div className="slide">
                    <img src={waste} alt="" />
                    <span className='text-[16px]'>Sorting Classification</span>
                </div>
                <div className="slide">
                    <img src={drag2} alt="" />
                    <span className='text-[16px]'>Drag Drop Visual Stimulating</span>
                </div>
                <div className="slide">
                    <img src={ticTacToe} alt="" />
                    <span className='text-[16px]'>Tic Tac Toe</span>
                </div>

            </div>
        </div>
        </>
    )
}

export default Slider