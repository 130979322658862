import React from 'react'
import Layout from './../components/Layout/Layout'
import styled from 'styled-components'
import SubscriptionC from '../components/subscriptionComponents/SubscriptionC'
const Subscriptions = () => {
    return (
        <Layout>
            <SubscriptionC />
        </Layout>
    )
}
const BoughtPlanCont = styled.div`

`
const Cont = styled.div`

`
export default Subscriptions