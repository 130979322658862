import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { extrasConstancts } from '../actions/appConstants'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { globalConstants } from '../actions/appConstants'
import TabSection from '../components/DashBoardComponets/DashboardTabs/TabSection'
import SubmittedAnswers from '../components/DashBoardComponets/DashboardTabs/Tabs/SubmittedAnswers';
import CloseIcon from '@mui/icons-material/Close';
// import QuizCards from '../components/DashBoardComponets/QuizCards/QuizCards'
// import StatisticsTab from '../components/DashBoardComponets/StatisticsTab/StatisticsTab'
import Layout from '../components/Layout/Layout'
import axios from '../utils/myAxios'
import { useLocation } from 'react-router-dom'
const SinglrQuizzDetailScreen = () => {
    //register & unregister
    const cardInit = {
        play: false,
        timesup: false,
        register: false,
        unregister: false,
    }
    const [cardStates, setcardStates] = useState(cardInit)
    //register & unregister
    const { state } = useLocation()
    const dispatch = useDispatch()
    const { auth, extras } = useSelector(s => s)
    const { qid } = useParams()
    const [singleQuizz, setsingleQuizz] = useState(null)
    useEffect(async () => {
        if (qid) {
            try {
                const res = await axios(`/get/singlequizz/byid/${qid}`)
                setsingleQuizz(res.data.quizz)
            } catch (error) {
                if (error?.response?.data?.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
    }, [qid])
    // console.log('singleQuizzDetailScreen state===>', state)
    const handleUpdatedQuizz = (quiz) => {
        setsingleQuizz(quiz)
    }

    return (
        <>

            {
                state?.hidelayout
                    ?
                    singleQuizz &&
                    <TabSection hidecomments={true} handleUpdatedQuizz={handleUpdatedQuizz} singleQuizz={singleQuizz} qid={qid} user={auth.data.user._id} />
                    :
                    <Layout>
                        {/* <StatisticsTab singleQuizz={singleQuizz} /> */}
                        {
                            singleQuizz &&
                            <TabSection handleUpdatedQuizz={handleUpdatedQuizz} singleQuizz={singleQuizz} qid={qid} user={auth.data.user._id} />
                        }
                    </Layout>
            }
            <div className="w-full flex justify-center">
                <div className={`${extras.showSubmissionModal.open === false ? 'hidden' : "flex"} drop-shadow-lg rounded-xl  flex-col items-center w-auto absolute top-[10%] p-10 h-auto bg-white z-[100000]`} >
                    <CloseIcon className='absolute top-3 right-3' onClick={() => {
                        dispatch({
                            type: extrasConstancts.SHOW_SUBMISSION_MODAL,
                            payload: {
                                open: false,
                                user: ''
                            }
                        })
                    }}></CloseIcon>
                    <h1 className='font-semibold text-2xl p-10'>User Submission</h1>
                    <div>
                        <SubmittedAnswers quizz={qid} user={extras.showSubmissionModal.user} />
                    </div>

                </div>
            </div>
        </>

    )
}
const Cont = styled.div`

`
export default SinglrQuizzDetailScreen