import React from 'react'
import { Navigate, Outlet, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
const RequireAuth = ({ allowedUsers }) => {
    const dispatch = useDispatch()
    const { auth } = useSelector(state => state)
    const location = useLocation()
    return (
        <>
            {
                (auth?.authenticate)
                    ?
                    <Outlet />
                    :
                    < Navigate to='/' state={{ from: location }} replace />

            }
        </>
    )
}

export default RequireAuth