import React, { useState } from 'react'
import { Button, Container, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import Reading from "../../../assets/staticdata/reading-book.png"
import Hotspot from "../../../assets/staticdata/wifi.png"
import Dataanalysis from "../../../assets/staticdata/data-analysis.png"
import Translation from "../../../assets/staticdata/translation.png"
// import Doubt from "../../assets/4th.webp"
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux'
import { extrasConstancts } from '../../../actions/appConstants'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-flip";

import { Autoplay, Pagination, EffectFlip } from "swiper";

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  color: 'white',
  backgroundColor: '#F89820',
  borderColor: '#F89820',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
  },
  '&:focus': {

  },
});

const WhyTextBook = () => {
  const [openPopUp, setOpenPopUp] = useState(false)
  const theme = useTheme();
  const dispatch = useDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <Container className='my-10'>
        <Grid container mt={5} mb={matches ? 0 : 5}>
          <Grid style={{ display: "flex", justifyContent: "center", alighnItem: "center", flexDirection: "column" }} item md={6} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", alighnItem: "center", flexDirection: "column" }}>
              <Typography className='text-yellow-500' variant={matches ? "h3" : "h5"} textAlign="center" sx={{ fontSize: "bold" }}>
                Why Quizzingbox ?
              </Typography>
              <Typography px={matches ? 10 : 1} ml={1} py={3} variant="subtitle1" textAlign="justify" sx={{ color: "black", fontSize: "16px" }}>
                Quizzingbox platform offers unlimited number of quizzes with opportunity of winning rewards every single minute which make it a preferred choice for millions of happy users all around the globe. Feel the excitement and the adrenalin that never leave your body till the last question pops in. Experience the rush of winning both rewards and respect from other quiz takers
              </Typography>
            </Box>
            <Box mb={matches ? 2 : 5} mt={matches ? 0 : 2} pl={11}>
              <BootstrapButton onClick={() => dispatch({
                type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                payload: true
              })}

                color="inherit" className='!rounded-2xl'>Get Started For Free</BootstrapButton>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              effect= {"flip"}
              modules={[Autoplay , EffectFlip]} className="mySwiper w-full h-full pb-10 md:pb-5">
              {/* 1st slide */}
              <SwiperSlide>
                <Grid container spacing={matches ? 3 : 3}>
                  <Grid item md={6} xs={12}>
                    <Box className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Reading} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Schedule Unlimited Quiz</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                        Quizzingbox allow users to schedule unlimmited quizzes from different quiz formats
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#C07AB6" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Hotspot} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Play Unlimited Quiz</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                        Feel the thrill by challenging yourself in different format of the quiz, gathering knowledge every time and get rewarded for knowledge.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={matches ? 3 : 3} className={`mt-${matches ? 5 : 0}`}>
                  <Grid className='col-3' item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#A8C8F2" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Dataanalysis} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Instant result of the Quiz</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                        Get a detailed breakdown of your strengths & weaknesses and discover insights to improve your score
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#FFBE40" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Translation} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Earning While Learning</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                        Earn rewards while you schedule quiz or play quiz on this platform
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </SwiperSlide>
              {/* 2nd slide */}
              <SwiperSlide>
                <Grid container spacing={matches ? 3 : 3}>
                  <Grid item md={6} xs={12}>
                    <Box className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Reading} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Improved focus and concentration </Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                      Quiz games require you to focus and concentrate on the task at hand. This can help to improve your focus and concentration in other areas of your life.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#C07AB6" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Hotspot} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Increased motivation</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                        Quiz games can help to increase your motivation to learn and improve. This can lead to a more positive attitude towards learning and a greater desire to succeed.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={matches ? 3 : 3} className={`mt-${matches ? 5 : 0}`}>
                  <Grid className='col-3' item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#A8C8F2" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Dataanalysis} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Enhanced social skills</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                      Quiz games can be a great way to socialize and interact with others. This can help to improve your social skills and make you more comfortable in social situations.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                    <Box sx={{ backgroundColor: "#FFBE40" }} className='Why-right-card'>
                      <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Translation} alt="" width="40%" />
                      <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Reduced stress levels</Typography>
                      <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                      Quiz games can be a fun and relaxing way to spend your time. This can help to reduce stress levels and improve your overall mood.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </SwiperSlide>
            </Swiper>
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <Grid container spacing={matches ? 3 : 3}>
              <Grid item md={6} xs={12}>
                <Box className='Why-right-card'>
                  <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Reading} alt="" width="40%" />
                  <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Schedule Unlimited Quiz</Typography>
                  <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                    Quizzingbox allow users to schedule unlimmited quizzes from different quiz formats
                  </Typography>
                </Box>
              </Grid>
              <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                <Box sx={{ backgroundColor: "#C07AB6" }} className='Why-right-card'>
                  <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Hotspot} alt="" width="40%" />
                  <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Play Unlimited Quiz</Typography>
                  <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                    Feel the thrill by challenging yourself in different format of the quiz, gathering knowledge every time and get rewarded for knowledge. Every right hit let you win rewards
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={matches ? 3 : 3} className={`mt-${matches ? 5 : 0}`}>
              <Grid className='col-3' item md={6} xs={12}>
                <Box sx={{ backgroundColor: "#A8C8F2" }} className='Why-right-card'>
                  <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Dataanalysis} alt="" width="40%" />
                  <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Instant result of the Quiz</Typography>
                  <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                    Get a detailed breakdown of your strengths & weaknesses and discover insights to improve your score
                  </Typography>
                </Box>
              </Grid>
              <Grid className='col-class' mt={matches ? 4 : 0} item md={6} xs={12}>
                <Box sx={{ backgroundColor: "#FFBE40" }} className='Why-right-card'>
                  <img className='!w-[40%] p-5 bg-white rounded-2xl' src={Translation} alt="" width="40%" />
                  <Typography mt={matches ? 5 : 3} variant="h5" sx={{ color: "black", fontSize: "16px", fontWeight: "bold" }}>Earning While Learning</Typography>
                  <Typography textAlign={matches ? "left" : "center"} mt={matches ? 1 : 2} pr={matches ? 3 : 0} variant="subtitle1" sx={{ color: "black", fontSize: "16px" }}>
                    Earn rewards while you schedule quiz or play quiz on this platform
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>


      </Container>
    </>
  )
}

export default WhyTextBook
