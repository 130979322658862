import React from 'react'

function Embeded() {
    return (
        <div className="w-full flex justify-center mb-5">
        <div className='w-[60%]'>

        
            <div className="box-border relative" style={{width : "100%" , paddingTop: "56.25%"}} >
                <iframe className="absolute inset-0 w-full h-full rounded-lg" src="https://www.youtube-nocookie.com/embed/26E5DtDOFV8?rel=0" frameborder="0"></iframe>
            </div>
        </div>
        </div>
    )
}

export default Embeded