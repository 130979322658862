import React from 'react'
import { Avatar } from '@mui/material'
function ReviewCard({ review }) {
    return (
        <div className='w-full flex border rounded-xl bg-white p-3 px-5 items-center'>
            <Avatar

                src={review.user.profilePic}
                sx={{ width: 40, height: 40 }}
                // onClick={() => { navigate(`/following/profile/${review.user._id}`) }}
                className='cursor-pointer ring ring-green-400 ring-offset-2'
            />
            <div className="px-3 grow">
                <p>{review.review}</p>
                <span className=' font-light italic text-right block'>~ {review.user.userName}</span>
            </div>
        </div>
    )
}

export default ReviewCard