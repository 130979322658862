import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import MyDraftjs from './MyDraftJs'
const QuizzRules = () => {
    const dispatch = useDispatch()
    const [richData, setrichData] = useState('')
    const handleSave = async () => {
        if (!richData.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "empty document cannot be saved!" } })
        
        try {
            const obj = {
                type : "quizzithon",
                rules: richData,
            }
            let res = await axios.post('/root/quizzithonrules', {
                ...obj
            })
            
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "canges saved succesfully!" }
            })
        } catch (error) {
            return dispatch({
                tyep: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    useEffect(async () => {
        
            try {
                const res = await axios.get(`/root/quizzithonrules`)
                
                if (!res.data.rules) {
                    setrichData('')
                } else {
                    setrichData(res.data.rules.rules)
                }
            } catch (error) {

            }
        
    }, [])
    return (
        <div>
            
            <div>
                <MyDraftjs content={richData} setContent={setrichData} />
            </div>
            <div className='flex items-center justify-end mt-5'>
                <button onClick={handleSave} className='bg-bgblue text-white py-1 px-2 rounded-lg '>Save</button>
            </div>
        </div>
    )
}

export default QuizzRules