import React , {useEffect , useState} from 'react'
import TableRow from './TableRow'
import { set } from 'lodash'

function QuizzithonLeaderBoard({leaderboardData , quizzes , quizzithon}) {
    const [quizzData , setQuizzData] = useState([])
    useEffect(()=>{
        setQuizzData(quizzes)
    },[leaderboardData , quizzes])
  return (
    <div class="overflow-x-auto drop-shadow">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                <div class="w-full  overflow-auto">
                    <div class="bg-white shadow-md rounded">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class=" flex justify-evenly bg-gray-300 text-gray-600 uppercase text-sm leading-normal">
                                    <td class=" basis-0 grow py-3 px-6 text-center">#</td>
                                    <td class="basis-0 grow py-3 px-6 text-center">Player</td>
                                    { 
                                        quizzData?.map((val, index) => {
                                            return <td class="basis-0 grow py-3 px-6 text-center">Quiz {index + 1}</td>
                                        }
                                        )
                                    }
                                    <td class="basis-0 grow py-3 px-6 text-center">Total Points</td>
                                    <td class="basis-0 grow py-3 px-6 text-center">time taken</td>
                                    {quizzithon.status ==="closed" && <td class="basis-0 grow py-3 px-6 text-center">Prize</td>}
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    leaderboardData.length > 0 ?
                                    leaderboardData.map((val, index) => {
                                        
                                        return <TableRow index={index+1} val={val} key={index} quizzes = {quizzData} quizzithon = {quizzithon}/>
                                        
                                    }) : <div className='w-full text-center py-5'>No user has played quizziton quiz yet</div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default QuizzithonLeaderBoard