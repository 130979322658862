import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from "xlsx";
import { globalConstants, questionsCategoryConstants } from '../../actions/appConstants'
import { createQuestionsAction } from '../../actions/question.actions';
import AttachmentIcon from '@mui/icons-material/Attachment';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from '../../utils/myAxios'

const ExcelQuestionUploads = () => {

    const dispatch = useDispatch()
    const { question, auth, subjects } = useSelector(s => s)
    const [items, setItems] = useState([]);
    const [finalFormatedQuestions, setfinalFormatedQuestions] = useState([])
    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setItems(d);
        });
    };
    const handleChange = (e) => {
        const file = e.target.files[0]
        //console.log('files name templete===>', file.type.split('/'))
        if (
            file.type.split('/')[1] !== 'csv'
            && file.type.split('/')[1] !== 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            && file.type.split('/')[1] !== 'vnd.ms-excel'
        ) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "Kindly select an excel file!" }
            })
        }
        //validation for only excel sheet selection
        //validation for only excel sheet selection
        readExcel(file);
        e.target.value = ''
    }
    const isAllowedCategores = (cat, x) => {
        if (
            cat === questionsCategoryConstants.MULTIPLE_QUESTIONS
            || cat === questionsCategoryConstants.FILL_IN_THE_BLANKS
            || cat === questionsCategoryConstants.TRUE_FALSE
            || cat === questionsCategoryConstants.CLOSED_ENDED
        ) {
            return true;
        } else {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: `${cat} is not allowed in ${x + 1}` }
            })
            return false;
        }
    }
    useEffect(() => {
        if (items.length > 0) {
            let correctFormat = [];
            let okay = true
            // question.questionsCategories

            for (let x = 0; (x < items.length && okay === true); x++) {
                const {
                    statement,
                    correctAnswer,
                    subject,
                    option1,
                    option2,
                    option3,
                    option4,
                    questionCategory
                } = items[x]
                const foundSubject = subjects.subjects.find(s => s.name === subject)
                //validation for allowed categories...
                // if (!isAllowedCategores(questionCategory), x) {
                //     return;
                // }
                //
                if (!foundSubject) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: `${subject} is invalid subject for question#${x + 1}. kindly select defined subjects in the app.` }
                    })
                    okay = false;
                    break;
                }
                if (!statement) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: `statement field is required for the Question#${x + 1}` }
                    })
                    okay = false;
                    break;
                }
                if (!questionCategory) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: `questionCategory field is required for the Question#${x + 1}` }
                    })
                    okay = false;
                    break;
                }
                if (!correctAnswer) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: `correctAnswer field is required for the Question#${x + 1}` }
                    })
                    okay = false;
                    break;
                }
                const findTheCategoryId = question.questionsCategories.find(c => c.name === questionCategory)
                if (!findTheCategoryId) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: `incorrect category for QNO.${(x + 1)}` }
                    })
                    okay = false
                    break;
                }
                if (!subject?.trim()) {
                    dispatch({ type: globalConstants.ALERT, payload: { error: `subject is required for QNO. ${(x + 1)}` } })
                    okay = false
                    break;
                }
                if (!items[x].questionCategory?.trim()) {
                    dispatch({ type: globalConstants.ALERT, payload: { error: `question category is required for QNO. ${(x + 1)}` } })
                    okay = false
                    break;
                }
                if (
                    items[x].questionCategory === questionsCategoryConstants.FILL_IN_THE_BLANKS
                    || items[x].questionCategory === questionsCategoryConstants.CLOSED_ENDED
                ) {

                    if (!statement?.trim()) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `statement is required for the QNO.${(x + 1)}` } })
                        okay = false;
                        break;
                    }
                    if (!correctAnswer) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `correctAnswer is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    correctFormat.push({
                        user: auth.data.user._id,
                        statement: statement,
                        correctAnswer: correctAnswer,
                        questionCategory: findTheCategoryId._id,
                        subject: subject
                    })
                } else if (items[x].questionCategory === questionsCategoryConstants.MULTIPLE_QUESTIONS) {
                    if (!statement?.trim()) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `statement is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!correctAnswer) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `correctAnswer is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!option1) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `optin1 is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!option2) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `optin2 is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!option3) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `optin3 is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!option4) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `optin4 is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    correctFormat.push({
                        user: auth.data.user._id,
                        statement: statement,
                        options: [
                            { option: option1 },
                            { option: option2 },
                            { option: option3 },
                            { option: option4 },
                        ],
                        correctAnswer: correctAnswer,
                        subject: subject,
                        questionCategory: findTheCategoryId._id
                    })

                } else if (items[x]?.questionCategory === questionsCategoryConstants.TRUE_FALSE) {
                    if (!statement?.trim()) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `statement is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    if (!correctAnswer) {
                        dispatch({ type: globalConstants.ALERT, payload: { error: `correctAnswer is required for the QNO.${(x + 1)}` } })
                        okay = false
                        break;
                    }
                    correctFormat.push({
                        user: auth.data.user._id,
                        statement: statement,
                        correctAnswer: correctAnswer,
                        subject: subject,
                        questionCategory: findTheCategoryId._id
                    })
                }
            }
            //console.log('correct format', correctFormat)
            if (okay) {
                setfinalFormatedQuestions(correctFormat)
                setItems([])
            }
        }
    }, [items])
    // //console.log('final formated questions==>', items)
    useEffect(async () => {
        if (finalFormatedQuestions.length > 0) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            try {
                for (let x = 0; x < finalFormatedQuestions.length; x++) {
                    const res = await axios.post('/question/create', {
                        ...finalFormatedQuestions[x]
                    })
                }
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }

            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "questions uploaded successfully!" }
            })
        }
    }, [finalFormatedQuestions])
    return (
        <div className='relative w-10 h-10 overflow-hidden rounded-full bg-white flex items-center justify-center cursor-pointer'>
            <FileUploadIcon className='!text-bgblue' />
            <input
                onChange={handleChange}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type='file'
                className='absolute top-0 left-0 bottom-0 right-0 opacity-0 z-10 !cursor-pointer'
            />
        </div>
    )
}

export default ExcelQuestionUploads