import React from 'react'
import styled from 'styled-components'
import AnimatedText from 'react-animated-text-content';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import tw from 'twin.macro'
import topBannerImg from '../../../assets/staticdata/bannerImage.png'
const TopBanner = () => {
    return (
        <Cont>
            <div className='relative w-full  bg-no-repeat bg-center sm:bg-left text-4xl flex flex-col items-center justify-end text-yellow-500' >
                <img className='w-full' src={topBannerImg} alt="" />
                <div className='absolute flex flex-col items-center justify-between'>
                <AnimatedText animationType='throw' duration =  {1} className='mb-10 font-semibold p-2 sm:p-5 text-lg md:text-4xl bg-white rounded-full'>Battle of Brains</AnimatedText>
                <ArrowCircleDownIcon className='animate-bounce' fontSize='inherit' ></ArrowCircleDownIcon>
                </div>
            </div>
            
        </Cont>
    )
}
const Cont = styled.div`
${tw` md:mt-[4rem] `}
`
export default TopBanner;
