import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { deleteQuestion } from '../../../actions/question.actions';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function TableModal({ open, setopen, state, val, questions, setquestions }) {
    const dispatch = useDispatch()
    const {
        edit,
        view,
        delete: del
    } = state
    const handleClose = () => {
        setopen(false)
    };
    const handleDelete = () => {
        const updated = questions.filter(_val => _val._id !== val._id)
        setquestions(updated)
        // dispatch(deleteQuestion(val))
        setopen(false)
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Quiz</DialogTitle>
            {
                del &&
                <div className='p-10 space-y-5'>
                    <h1 className='text-bgblue'>Are you sure your want to delete this Quiz?</h1>
                    <div className='grid grid-cols-2 gap-4'>
                        <button onClick={() => setopen(false)} className='bg-green-400 text-white py-3 px-6 rounded-md'>Cancel</button>
                        <button onClick={handleDelete} className='bg-red-400 text-white py-3 px-6 rounded-md'>Delete</button>
                    </div>
                </div>

            }
        </Dialog>
    );
}

export default TableModal;