import { combineReducers } from 'redux'
import auth from './auth.reducers'
import alert from './alert.reducers'
import extras from './extras.reducers'
import question from './question.reducers'
import quizzez from './quizzSchedule.reducers'
import wallet from './wallet.reducers'
import subjects from './subject.reducers'
import staticTab from './statictabData.reducers'
import lifeLines from './lineLines.reducers'
import quizzithon from './quizzithon.reducers'
import stripeState from './stripe.reducers'
const rootReducer = combineReducers({
    auth,
    alert,
    extras,
    question,
    quizzez,
    wallet,
    subjects,
    staticTab,
    lifeLines,
    quizzithon,
    stripeState
})

export default rootReducer