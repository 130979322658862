import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Avatar } from "@mui/material";


import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import PaymentTable from "../components/paymentHistoryTable/PaymentsTable";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PeopleIcon from "@mui/icons-material/People";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import Layout from "./../components/Layout/Layout";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../utils/myAxios";

const Icons = ({ total, title, icon, color }) => {
  return (
    <div className="flex  items-center   gap-x-2">
      <Avatar
        className={`!text-white `}
        style={{
          background: color ? color : "",
        }}
      >
        {icon}
      </Avatar>
      <div>
        <h1
          className={`text-lg `}
          style={{
            color: color ? color : "",
          }}
        >
          {total}
        </h1>
        <h1 className="text-[11px] whitespace-pre opacity-50">{title}</h1>
      </div>
    </div>
  );
};

const PaymentsScreen = () => {
  const { auth } = useSelector((s) => s);
  const { state } = useLocation();
  const [loading, setloading] = useState(true);
  const [userData, setUserData] = useState({});
  const [user, setuser] = useState(auth.data.user._id);

  //check if user management want to see user questions
  useEffect(async () => {
    if (state?.userManagement) {
      console.log("state?.userManagement==>", state?.userManagement);
      await setuser(state?.userManagement);
      const data = await axios.get(
        `/static/tabs/data/${state?.userManagement}`
      );
      setUserData(data.data.user);
      console.log("data==>", data.data.user);
      setloading(false);
    } else {
      setloading(false);
    }
  }, [state]);
  //check if user management want to see user questions
  return (
    <Layout>
      {loading ? (
        <div>loading...</div>
      ) : (
        <>
          {/* <h1 className=' text-lg  p-2 font-bold mt-5'>Subscriptions History</h1>
                        <PaymentHistoryTable
                            user={user}
                        /> */}
          {state?.userManagement && (
            <>
              <Container>
                {/* First slide */}
                <>
                  <div className="">
                    <h1 className="capitalize text-bgblue text-lg">
                      Available
                    </h1>
                  </div>
                  <ImgContainer className="capitalize ">
                    <Icons
                      icon={<StackedLineChartOutlinedIcon />}
                      color="#00459E"
                      title="Quizzes"
                      total={userData.quizzes ? userData.quizzes : 0}
                    />
                    <Icons
                      icon={<WorkspacesIcon />}
                      color="#EEC907"
                      title="Quizzithon"
                      total={userData.quizzithon ? userData.quizzithon : 0}
                    />
                    <Icons
                      icon={<PeopleIcon />}
                      color="#42BA96"
                      title="Community Quizzes"
                      total={
                        userData.quizmaster_pro ? userData.quizmaster_pro : 0
                      }
                    />
                    <Icons
                      icon={<SmartToyIcon />}
                      color="#32CD32"
                      title="AI Questions"
                      total={userData.ai_questions}
                    />
                  </ImgContainer>
                </>
              </Container>
            </>
          )}
          <h1 className=" text-lg  p-2 font-bold mt-5">Qzeto Bought History</h1>
          <PaymentTable user={user} />
        </>
      )}
    </Layout>
  );
};
const ImgContainer = styled.div`
  ${tw`grid lg:grid-cols-3 grid-cols-2 gap-x-5 gap-y-5 justify-center`}
`;
const Container = styled.div`
  ${tw`bg-white shadow-xl p-4 rounded-2xl space-y-5 `}
`;
export default PaymentsScreen;
