import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment'

function diff_minutes(dt2, dt1) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

}
export default function CircularStatic({
    calculateTimeTaken,
    simpleQuizz,
    handleOnTimesUpAction,
    isPastQuiz,
    setcalculateTimeTaken,
    settimerTimeIsFinished,
}) {
    const [simpletimer, setsimpletimer] = React.useState({
        minutes: simpleQuizz.isDurationBound ? (simpleQuizz.durationBoundMinutes - 1) : (simpleQuizz.duration - 1),
        seconds: simpleQuizz.isDurationBound ? (simpleQuizz.durationBoundMinutes > 0 ? 60 : 0) : (simpleQuizz.duration > 0 ? 60 : 0),
    })
    const init_p = {
        minutes: 0,
        seconds: 0,
        days: 0,
    }
    const [progress, setProgress] = React.useState(init_p);
    //for past Quizzes
    React.useEffect(() => {
        let timer;
        if (isPastQuiz && simpleQuizz.isDurationBound === false) {
            setcalculateTimeTaken(prev => ({ ...prev, seconds: Number(prev.seconds + 1) }))
            timer = setTimeout(() => {
                setsimpletimer({
                    ...simpletimer,
                    seconds: simpletimer.seconds + 1
                })
            }, 1000)
            if (simpletimer.seconds >= 59) {
                setsimpletimer({
                    minutes: simpletimer.minutes - 1,
                    seconds: 0
                })
            }
            if (simpletimer.minutes <= 0) {
                settimerTimeIsFinished(true)
                handleOnTimesUpAction()
            }
        }
        return () => {
            clearInterval(timer);
        }
    }, [simpletimer, isPastQuiz, simpleQuizz])
    //for past Quizzes
    React.useEffect(() => {
        let timer;
        if (!isPastQuiz && simpleQuizz.isDurationBound === false) {
            timer = setInterval(() => {
                //Updated Loader time...
                const fun = () => {
                    let str2 = new Date(simpleQuizz.time);
                    str2.setMinutes(str2.getMinutes() + simpleQuizz.duration)
                    const str1 = new Date()
                    var diff = Date.parse(str2) - Date.parse(str1);
                    return isNaN(diff) ? NaN : {
                        diff: diff,
                        ms: Math.floor(diff % 1000),
                        s: Math.floor(diff / 1000 % 60),
                        m: Math.floor(diff / 60000 % 60),
                        h: Math.floor(diff / 3600000 % 24),
                        d: Math.floor(diff / 86400000)
                    }
                }
                const d = fun()
                var minutes = Math.floor(d.diff / 60000);
                var seconds = ((d.diff % 60000) / 1000).toFixed(0);
                //
                setcalculateTimeTaken(prev => ({ ...prev, seconds: Number(prev.seconds + 1) }))
                if (minutes <= 0 && seconds <= 0) {
                    //handle timesup functionalities...
                    settimerTimeIsFinished(true)
                    handleOnTimesUpAction(calculateTimeTaken)
                }
                setProgress({
                    minutes: minutes <= 0 ? 0 : minutes,
                    seconds: seconds <= 0 ? 0 : seconds
                })
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isPastQuiz]);
    //for duration Bound Quizzes
    React.useEffect(() => {
        let timer;
        if (simpleQuizz.isDurationBound) {
            setcalculateTimeTaken(prev => ({
                ...prev,
                seconds: Number(prev.seconds + 1),
            }))
            timer = setTimeout(() => {
                setsimpletimer({
                    ...simpletimer,
                    seconds: simpletimer.seconds - 1,
                })
            }, 1000)
            if (simpletimer.seconds <= 0) {
                setsimpletimer({
                    minutes: simpletimer.minutes - 1,
                    seconds: 60
                })
            }
            if (simpletimer.minutes <= 0 && simpletimer.seconds <= 0) {
                settimerTimeIsFinished(true)
                handleOnTimesUpAction()
            }
        }
        return () => {
            clearInterval(timer);
        }
    }, [simpleQuizz.isDurationBound, simpletimer])
    //for duration Bound Quizzes
    return (
        <div className='absolute -top-8 left-[48%] w-12 h-12 border-2 border-green-600 bg-white rounded-full flex items-center justify-center '>
            {
                (isPastQuiz || simpleQuizz.isDurationBound) ?
                    <span>{`${simpletimer.minutes}:${simpletimer.seconds}`}</span>
                    :
                    <span>{`${progress.minutes}:${progress.seconds}`}</span>
            }
        </div>
    )
}
