import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import ListItems from './ListItems'
import MyForm from './MyForm'
import { imageUpload } from '../../../utils/cloudinary.config'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'

const DragDropVisualSimulating = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const { auth, question } = useSelector(s => s)
    const dispatch = useDispatch()
    const [statement, setstatement] = useState('')
    const initMainState = {
        correct: [],
        incorrect: [],
    }
    const [mainState, setmainState] = useState({
        correct: [],
        incorrect: [],
    })
    const handleAdd = (value, type, setcurrectItem) => {
        let findDuplicateName = mainState.correct.find(v => v.name === value.name)
        if (findDuplicateName) return dispatch({ type: globalConstants.ALERT, payload: { error: 'duplicate names are not allowed!' } })
        if (type === 'correct') {
            setmainState({
                ...mainState,
                correct: [...mainState.correct, value]
            })
        }
        if (type === 'incorrect') {
            setmainState({
                ...mainState,
                incorrect: [...mainState.incorrect, value]
            })
        }
        setcurrectItem({
            name: '',
            file: ''
        })
    }
    //console.log('mainstate==>', mainState)
    const handleSubmit = async (stay) => {
        let obj = {
            questionCategory: type,
            subject,
            statement,
        }
        if (mainState.correct.length === 0) return dispatch({ type: globalConstants.ALERT, payload: { error: 'Add atleast one Correct option!' } })
        if (mainState.incorrect.length === 0) return dispatch({ type: globalConstants.ALERT, payload: { error: 'Add atleast one InCorrect option!' } })
        if (!(obj.questionCategory).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: {
                    error: "kindly fill the question type!"
                }
            })
        }
        if (!(obj.statement).trim()) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question statement!" }
            })
        }
        if (!(obj.subject).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly select the subject!" }
        })
        try {
            //upload images to cloudinary
            dispatch({ type: globalConstants.ALERT, payload: { loading: true } })
            let update = { ...mainState }
            for (let x = 0; x < update.correct.length; x++) {
                let img = []
                if (typeof (update.correct[x].file) === 'string') {
                    img[0] = {
                        url: update.correct[x].file
                    }
                } else {
                    img = await imageUpload([update.correct[x].file])
                }
                update = {
                    ...update,
                    correct: update.correct.map((_vv, _ii) => _ii === x ? { ..._vv, file: img[0].url } : _vv)
                }
            }
            for (let x = 0; x < update.incorrect.length; x++) {
                let img = []
                if (typeof (update.incorrect[x].file) === 'string') {
                    img[0] = {
                        url: update.incorrect[x].file
                    }
                } else {
                    img = await imageUpload([update.incorrect[x].file])
                }
                update = {
                    ...update,
                    incorrect: update.incorrect.map((_vv, _ii) => _ii === x ? { ..._vv, file: img[0].url } : _vv)
                }
            }
            const _idFind = question.questionsCategories.find((v) => v.name === type)

            obj = {
                ...obj,
                questionCategory: _idFind._id,
                user: auth.data.user._id,
                forDragDropVisualSimulating: update
            }
            if (canEdit) {
                await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
            } else {
                await dispatch(createQuestionsAction(obj))
            }
            if (stay) {
                setstatement('')
                setmainState(initMainState)
            } else {
                setshowAddQuestions(false)
            }
        } catch (error) {
            return dispatch({ type: globalConstants.ALERT, payload: { error: error.message } })
        }
    }
    const handleDelete = (value, type) => {
        setmainState({
            ...mainState,
            [type]: [...mainState[type].filter(v => v.name !== value.name)]
        })
    }
    useEffect(() => {
        if (showQuestinData) {
            //console.log('questine data==>', showQuestinData)
            setstatement(showQuestinData.statement)
            setmainState(showQuestinData.forDragDropVisualSimulating)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <div className='mt-10'>
            <div className='space-y-2'>
                <h1 className='text-bgblue font-bold'>Question Statement</h1>
                <input
                    className='border border-gray-400 py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    onChange={(e) => setstatement(e.target.value)}
                    placeholder='write question statement...'
                />
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <MyForm handleAdd={handleAdd} />
            }
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-10 mt-5'>
                <div>
                    <h1 className='font-extrabold'>Correct Answers</h1>
                    <div className='space-y-2'>
                        {
                            mainState.correct.map((_v, _i) => (
                                <ListItems showQuestinData={showQuestinData} handleDelete={handleDelete} type='correct' val={_v} key={_i} />
                            ))
                        }
                    </div>
                </div>
                <div>
                    <h1 className='font-extrabold'>Incorrect Answers</h1>
                    <div className='space-y-2'>
                        {
                            mainState.incorrect.map((_v, _i) => (
                                <ListItems showQuestinData={showQuestinData} handleDelete={handleDelete} type='incorrect' val={_v} key={_i} />
                            ))
                        }
                    </div>
                </div>
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <div className='mt-10 flex items-center justify-end space-x-3'>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </div>
            }
        </div>
    )
}

export default DragDropVisualSimulating