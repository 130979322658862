import React from 'react'
import { useState, useEffect } from 'react'
import axios from '../../utils/myAxios'
import QuizzithonCard from './QuizzithonCard'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
import { CircularProgress } from '@mui/material'
import { set } from 'lodash'
import quizImg from '../../assets/staticdata/3.png'
import { useNavigate } from 'react-router-dom'
function Quizzithon({type}) {
    const [quizzithons, setQuizzithons] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        setLoader(true)
        axios.get(`/get/quizzithon/type/${type}`)
            .then(res => {
                setLoader(false)
                setQuizzithons(res.data.quizzithon)
            })
            .catch(err => setLoader(false))
    }, [type])
    const handleQuizzithonUpdate = (updated) => {
        
        setQuizzithons(quizzithons.map(quizzithon => quizzithon._id === updated._id ? updated : quizzithon))
    }
    useEffect(() => {
        
    }, [quizzithons])
    return (
        <>
        {loader ? <div className='w-full flex justify-center items-center'><CircularProgress /></div> :
        <div className='flex w-full justify-center items-center'>
            
                {
                    quizzithons.length > 0 ?<div className='w-full'> Quizzithons <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {quizzithons.map((quizzithon , index) => <QuizzithonCard key={index} quizzithon={quizzithon} handleQuizzithonUpdate={handleQuizzithonUpdate} />)}
                        </div> </div>: 
                        <div className='w-full flex flex-col items-center'>
                        <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                        <div className='w-full flex flex-col items-center'>
                            <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                There is no live quizzithon now, Why don't you become our quizmaster and schedule a quizzithon for others
                            </p>
                            <button className='w-38 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white text-sm px-3' onClick={()=>navigate('/schedule-quizzithon')}>Schedule Quizzithon</button>
                        </div>
                    </div>
                }
        </div>}
            
        </>
    )
}

export default Quizzithon