import { authConstants } from "../actions/appConstants";

const inititalState = {
    authenticating: true,
    authenticate: false,
    data: {
        accessToken: '',
        user: {}
    }
}


const userReducer = (state = inititalState, action) => {
    switch (action.type) {
        case authConstants.LOGIN:
            state = {
                ...state,
                authenticate: true,
                data: action.payload,
                authenticating: false,
            }
            break;
        case authConstants.EXTRAS_KEEP_KEEP_USER_LOGIN_TOKEN:
            state = {
                ...state,
                data: {
                    ...state.data,
                    accessToken: action.payload,
                }
            }
            break;
        case authConstants.LOGOUT:
            state = {
                ...inititalState,
                authenticating: false
            }
            break;
        case authConstants.HANDLE_AUTENTICATING:
            state = {
                ...state,
                authenticating: action.payload
            }
            break;
    }
    return state;
}
export default userReducer;