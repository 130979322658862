import React from 'react'
import quizMobile from '../../assets/staticdata/quizMobile.png'
import { useDispatch } from 'react-redux'
import { globalConstants  , extrasConstancts} from '../../actions/appConstants'
function DownloadApp() {
    const dispatch = useDispatch()
    return (
        <div className='relative w-full my-36 flex justify-center drop-shadow-lg'>
            <div className='w-[80%] bg-[#223344] py-10 px-10 flex flex-col items-center'>
                <div className=' w-full flex flex-col items-center lg:items-start'>
                    <h1 className='w-full text-[32px] text-yellow-500'>Start Your Earning & Learning <br />Journey Now!</h1>
                    <p className='w-full text-[16px] text-white pb-4'><span className='pr-2'>Learn</span><span className='pr-2'>Earn</span><span className='pr-2'>Improve</span><span className='pr-2'>Succeed</span></p>
                    <img className='lg:absolute top-[-50%] right-12' src={quizMobile} alt="" />
                    <div className='flex flex-wrap gap-2 justify-center'>
                        <div className='w-40 h-12 p-3 text-[16px] rounded-3xl bg-yellow-500 text-white text-center border border-yellow-500  cursor-pointer' onClick={() => dispatch({
                            type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                            payload: true
                        })}>
                            Get Started
                        </div>
                        <div className='w-40 h-12 p-3 text-[16px] rounded-3xl text-yellow-500 bg-white text-center border border-yellow-500 cursor-pointer'>
                            <a href="https://play.google.com/store/apps/details?id=com.wQuizzingBox_17087988" target='_blank' className='text-yellow-500' >Get App</a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default DownloadApp