import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { globalConstants, questionsCategoryConstants } from '../../actions/appConstants'
import { createQuizzScheduleAction } from '../../actions/quizzSchedule.actions'
const exclued_cat = ["SPIN_A_WHEEL_QUIZZ", "ELIMINATOR_QUIZZ", "MIX_TYPE_QUIZ", "TIC_TAC_TOE"]
const Sections = ({ index, val, schedulerData, onChangeHandler, question }) => {
  const { subjects } = useSelector(s => s)
  return (
    <>
      <div className='grid lg:grid-cols-3 grid-cols-1 gap-4'>
        {/* subject */}
        <div className=''>
          <h1 className=''>Select subject</h1>
          <select
            value={val.subject}
            onChange={(e) => onChangeHandler("subject", index, e.target.value)}
            className='border border-black w-full py-2 outline-none px-2 rounded-md'
          >
            <option value=''>select the subject</option>
            {
              subjects.subjects.map((val, index) => (
                <option value={val.name}>{val.name}</option>
              ))
            }
          </select>
        </div>
        {/* category */}
        <div className=''>
          <h1 className=''>Question type</h1>
          <select
            value={schedulerData.questions_info.subjects[index].cat}
            onChange={(e) => onChangeHandler("cat", index, e.target.value)}
            className='border border-black w-full py-2 outline-none px-2 rounded-md'
          >
            <option value=''>select the category</option>
            {
              question.questionsCategories.filter(v => !exclued_cat.includes(v.name))?.map((val, index) => (
                <option value={val._id}>{(val?.name).replace(/_/g, ' ')}</option>
              ))
            }
          </select>
        </div>
        {/* count */}
        <div className=''>
          <h1 className=''>Number of questions</h1>
          <input
            type='number'
            value={schedulerData.questions_info.subjects[index].count}
            onChange={(e) => onChangeHandler("count", index, Number(e.target.value))}
            className='border border-black w-full py-1.5 outline-none px-2 rounded-md'
          />
        </div>
      </div>
    </>
  )
}
const Different = ({ schedulerData, setschedulerData, ____durTime , quizIndex }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { question } = useSelector(state => state)
  const onChangeHandler = (targetprop, index, value) => {
    schedulerData.questions_info.subjects[index][targetprop] = value
    setschedulerData({
      ...schedulerData,
      schedulerData,
    })
  }
  const handleAddMore = () => {
    const n = schedulerData.questions_info.subjects.push({
      cat: "",
      subject: "",
      count: 0
    })
    setschedulerData({
      ...schedulerData,
      schedulerData
    })
  }
  const handleRemoveLastRow = () => {
    schedulerData.questions_info.subjects.pop()
    setschedulerData({
      ...schedulerData,
      schedulerData
    })
  }
  const handleSubmit = async () => {
    if (schedulerData.category === questionsCategoryConstants.TIC_TAC_TOE && schedulerData.tictactoeRows === 0) {
      return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly  TIC_TAC_TOE Order!" } })
    }
    const find = question.questionsCategories.find(val => val.name === schedulerData.category)
    const obj = {
      ...schedulerData,
      category: find._id
    }
    if (obj.winningPrize <= 0) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: "winning prize is required!" }
    })
    if (!(schedulerData.title).trim()) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: "title is required!" }
    })
    if (schedulerData.minimumNumberOfSeats === 0) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: "minum participants are required!" }
    })
    if (!(obj.type).trim()) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: 'type is required!' }
    })
    let avail = true
    for (let x = 0; (x < schedulerData.questions_info.subjects.length && avail === true); x++) {
      if (schedulerData.questions_info.subjects[x].count <= 0) {
        avail = false
        break;
      }
      if (!(schedulerData.questions_info.subjects[x].cat).trim()) {
        avail = false;
        break;
      }
      if (!(schedulerData.questions_info.subjects[x].subject).trim()) {
        avail = false
        break;
      }
    }
    if (!avail) return dispatch({
      type: globalConstants.ALERT,
      payload: { error: 'kindly fill all the fields!' }
    })
    //console.log('final quizz==>', obj)
    if (schedulerData.category === questionsCategoryConstants.TIC_TAC_TOE) {
      let totalQuestions = 0;
      schedulerData.questions_info.subjects.map((_s, _i) => {
        totalQuestions += _s.count
      })
      if (totalQuestions < (schedulerData.tictactoeRows * schedulerData.tictactoeRows)) {
        return dispatch({ type: globalConstants.ALERT, payload: { error: `Kindly select atleast ${(schedulerData.tictactoeRows * schedulerData.tictactoeRows)} questions!` } })
      }
      if (totalQuestions > (schedulerData.tictactoeRows * schedulerData.tictactoeRows)) {
        return dispatch({ type: globalConstants.ALERT, payload: { error: `Kindly select only ${(schedulerData.tictactoeRows * schedulerData.tictactoeRows)} questions!` } })
      }
    }
    //time validations
    const date = new Date(schedulerData.date)
    const time = new Date(schedulerData.time)
    //
    const date_month = date.getMonth()
    const date_date = date.getDate()
    //
    const currDate = new Date()
    if ((date.getDate() < currDate.getDate()) && (date.getMonth() === currDate.getMonth())) {
      return dispatch({
        type: globalConstants.ALERT,
        payload: { error: "select future date!" }
      })
    }
    if (date_month === currDate.getMonth() && date_date === currDate.getDate()) {
      if (time < currDate) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "select future time!" }
        })
      }
    }

    //for past Quiz End Time
    if (schedulerData.isDurationBound === false) {
      //console.log('in if==>')
      const endtime = new Date(schedulerData.durationDateComplete)
      if (endtime < currDate) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "selct future end time!" }
        })
      }
      if (endtime <= time) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "end time must be greater than start time." }
        })
      }
    } else {
      //console.log('in else==>')
      let newEndTime = new Date(obj.time)
      newEndTime.setMonth(date.getMonth())
      newEndTime.setDate(date.getDate())
      let endtime = new Date(newEndTime)
      endtime.setMinutes(endtime.getMinutes() + Number(____durTime))
      endtime = new Date(endtime)
      obj.durationBoundEndTime = endtime

      if (endtime < currDate) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "selct future end time!" }
        })
      }
      if (endtime <= time) {
        return dispatch({
          type: globalConstants.ALERT,
          payload: { error: "end time must be greater than start time." }
        })
      }
    }
    //for past Quiz End Time
    //time validations


    dispatch(createQuizzScheduleAction(obj)).then((val) => {
      if (val) {
        navigate('/dashboard')
      }
    })
  }
  //console.log('category ===>___', schedulerData.category)
  return (
    <Cont>
      {
        schedulerData.questions_info.subjects.map((val, index) => (
          <Sections question={question} key={index} index={index} val={val} schedulerData={schedulerData} onChangeHandler={onChangeHandler} />
        ))
      }
      {
        schedulerData.questions_info.subjects.length > 1 &&
        <div>
          <button onClick={handleRemoveLastRow} className='bg-red-500 text-white py-3 px-5 rounded-md text-xs mt-3'>Remove last row</button>
        </div>
      }
      <div>
        <button className='mt-10 text-white bg-bgblue py-2 px-4 rounded-md' onClick={handleAddMore}>Add more</button>
      </div>
      <div className='flex justify-end my-5'>
        <button onClick={handleSubmit} className='text-white bg-bgblue py-1 px-3 rounded-2xl'>Save</button>
      </div>
    </Cont>
  )
}
const Cont = styled.div`
`
export default Different