import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { deleteQuestion } from '../../../actions/question.actions';
import { deleteSubscriptionAction, updateSubscription } from '../../../actions/subscription.actions';
import axios from '../../../utils/myAxios'
import { set } from 'lodash';
const emails = ['username@gmail.com', 'user02@gmail.com'];

function TableModal({ open, setopen, state, val, questions, setquestions , setReset }) {
    console.log(questions)
    const [updatedData, setupdatedData] = React.useState(val)
    const dispatch = useDispatch()
    const {
        edit,
        view,
        delete: del
    } = state
    const handleClose = () => {
        setopen(false)
    };
    const handleDelete = () => {
        
        
        axios.delete(`/deleteQzetosPlan/${val._id}`).then(res => {
            if(res.status === 200){
                setquestions(res.data.plans)
                setopen(false)
            }
        }
        )
        .catch(err => console.log(err))

    }
    //console.log(updatedData)
    const handleOnChange = (e) => {
        const { value, name } = e.target
        setupdatedData({
            ...updatedData,
            [name]: value
        })
    }
    const handleUpdate = () => {
        // dispatch(updateSubscription(updatedData)).then(val => {
        //     if (val) setopen(false)
        // })
        console.log(updatedData)
        axios.put('/updateQzetosPlan' , updatedData).then(res => {
            if(res.status === 200){
                setopen(false)
                setquestions(res.data.plans)
            }
        })
        .catch(err => console.log(err))        
    }
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true}
            maxWidth={del ? 'xs' : 'md'}>
            <DialogTitle>Qzeto Plan</DialogTitle>
            {
                (edit || view) &&
                <div className='p-10 w-full'>
                    <div>
                    <h1 className='my-2 font-medium text-gray-500'>Title</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.name}
                        onChange={handleOnChange}
                        name='name'
                    />
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-3'>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>No of Quizzes</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizzes}
                            onChange={handleOnChange}
                            name='quizzes'
                            type='number'
                            min={1}

                        />
                    </div>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>Quizmaster Quizzes</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizmaster_pro}
                            onChange={handleOnChange}
                            name='quizmaster_pro'
                            type='number'
                            min={1}

                        />
                    </div>
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-3'>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>AI Questions</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.ai_questions}
                            onChange={handleOnChange}
                            name='ai_questions'
                            type='number'
                            min={1}

                        />
                    </div>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>Quizzithons</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizzithon}
                            onChange={handleOnChange}
                            name='quizzithon'
                            type='number'
                            min={1}

                        />
                    </div>
                </div>
                <div className='my-3'>
                    <h1 className='my-2 font-medium text-gray-500'>Qzetos</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.amount}
                        onChange={handleOnChange}
                        name='amount'
                        type='number'
                        min={1}
                    />
                </div>
                <div className='my-3'>
                    <h1 className='my-2 font-medium text-gray-500'>Price</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.price}
                        onChange={handleOnChange}
                        name='price'
                        type='number'
                        min={1}

                    />
                </div>
                
                    <div className='flex items-center justify-end space-x-3 mt-5'>
                        {
                            edit &&
                            <Button onClick={handleUpdate} variant='contained' color='primary'>Update</Button>
                        }
                        <Button onClick={handleClose} variant='contained' color='secondary'>Cancel</Button>
                    </div>
                </div>
            }
            {
                del &&
                <div className='p-10 space-y-5'>
                    <h1 className='text-bgblue'>Delete Qzeto Plan?</h1>
                    <div className='grid grid-cols-2 gap-4'>
                        <button onClick={handleClose} className='bg-green-400 text-white py-3 px-6 rounded-md'>Cancel</button>
                        <button onClick={handleDelete} className='bg-red-400 text-white py-3 px-6 rounded-md'>Delete</button>
                    </div>
                </div>

            }
        </Dialog>
    );
}

export default TableModal;