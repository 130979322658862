import React from 'react'
import NavBar from '../../staticApp/staticComponents/navBar/NavBar'
import StaticCategoriesC from '../staticCategoriesC/StaticCategoriesC'
function QuizMasterPro() {
  return (
    <div className='w-full overflow-x-hidden'>
        <NavBar/>
        <div className='px-5'>
            <StaticCategoriesC isSchoolQuizzes = {true} isUser = {true}/>
        </div>
        
    </div>
    )
}

export default QuizMasterPro