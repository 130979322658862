import React from 'react'
import PlayedQuizzesC from '../PlayedQuizzesC'
const FreeTab = ({
  user
}) => {
  return (
    <>
      <PlayedQuizzesC
        type='free'
        user={user}
      />
    </>
  )
}

export default FreeTab