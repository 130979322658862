import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import HeroImg from '../../../assets/staticdata/quizMobile.png'
import Ply from '../../../assets/staticdata/ply.png'


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import { extrasConstancts , globalConstants } from '../../../actions/appConstants';
import { useDispatch } from 'react-redux';

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  color: 'white',
  backgroundColor: '#F89820',
  borderColor: '#F89820',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#F89820',
    borderColor: '#F89820',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#F89820',
    borderColor: '#F89820',
  },
  '&:focus': {

  },
});
const RequestButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 52px',
  border: '1px solid',
  lineHeight: 1.5,
  color: 'white',
  backgroundColor: 'black',
  borderColor: 'black',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: 'black',
    opacity: "80%",
    borderColor: 'black',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'black',
    borderColor: 'black',
  },
  '&:focus': {

  },
});
const StratLearning = () => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <>
      <div>
        <Container>

          <Grid className='startbg' my={matches ? 20 : 0} container>

            <Grid py={10} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} item md={6} xs={12}>
              <Typography variant={matches ? "h4" : "h5"} textAlign={matches ? "left" : "center"} sx={{ color: '#324161', fontSize: 'bold' }}>
                Start Your Earning & Learning <br /> Journey Now!
              </Typography>
              <Stack direction="row" spacing={2} my={matches ? 2 : 2} >
                <Typography sx={{ fontSize: "16px" }} variant='h4'>Learn</Typography>
                <Typography sx={{ fontSize: "16px" }} variant='h4'>Earn</Typography>
                <Typography sx={{ fontSize: "16px" }} variant='h4'>Improve</Typography>
                <Typography sx={{ fontSize: "16px" }} variant='h4'>Succeed</Typography>
              </Stack>
              <Stack direction="column" width="50%" spacing={2}>
                <BootstrapButton
                  onClick={() => dispatch({
                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                    payload: true
                  })}
                  color="inherit" className='!rounded-2xl'>Get Started</BootstrapButton>
                <div
                  className='flex items-center justify-center cursor-pointer bg-black text-white py-1.5 m-3 rounded-2xl space-x-3' onClick={() => {
                    dispatch({
                      type: globalConstants.ALERT,
                      payload: { success: "App will be available soon" }
                    })
                  }}>
                  <img
                    className='h-7 w-7 object-contain'
                    src={Ply}
                  />
                  <span>Download App</span>
                </div>
                {/* <RequestButton className="request-button" style={{ position: "relative" }} color="inherit">Download App</RequestButton>
                <img className="ply-img2" style={{ position: "absolute", marginLeft: "145px", marginTop: "7px" }} width="2%" src={Ply} alt="" /> */}
              </Stack>
            </Grid>
            <Grid item md={6} xs={12}>
              <img className='startimg' width="50%" src={HeroImg} alt='' />
            </Grid>
          </Grid>


        </Container>
      </div>
    </>
  )
}

export default StratLearning
