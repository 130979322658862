import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'
import { shuffle } from '../../../utils/utilsFunctions'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { lifeLinesConstants } from '../../../actions/appConstants';
const SentenceSequence = ({
    question,
    handleLeaveQuestion,
    handleNextQuestion,
    isLastQuestion
}) => {
    const dispatch = useDispatch()
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines
    //correct answer for showCorrectAnswerLife
    const [correctSequenceForShowCorrectAnswer, setcorrectSequenceForShowCorrectAnswer] = useState([])
    //
    const [correctSequence, setcorrectSequence] = useState([])
    const [shuffleSequence, setshuffleSequence] = useState([])
    useEffect(() => {
        //correct array..
        const splitted = question.correctAnswer.split(' ')
        setcorrectSequence(splitted)
        //correct array..
        //shuffle array...
        setcorrectSequenceForShowCorrectAnswer(question.correctAnswer.split(' ').map((val, index) => ({ value: val, id: uuid() })))
        const shuffled = shuffle(question.correctAnswer.split(' ').map((val, index) => ({ value: val, id: uuid() })))
        setshuffleSequence(shuffled)
        //shuffle array...
    }, [question])
    console.log('correctsequence==>', correctSequence)
    console.log('shuffleSequence==>', shuffleSequence)
    const handleDragEnd = (e) => {
        console.log('e===>', e)
        const { destination, source } = e
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        const completed = [...shuffleSequence]
        const dragValue = completed[source.index]
        const dropOnValue = completed[destination.index]
        completed.splice(source.index, 1, dropOnValue)
        completed.splice(destination.index, 1, dragValue)
        setshuffleSequence(completed)
    }
    const handleValidation = () => {
        const findWrongSequence = shuffleSequence.find((_v, _i) => {
            if (_v.value === correctSequence[_i]) return false;
            return true
        })
        console.log('sequence result===>', findWrongSequence)
        let correct = false;
        if (!findWrongSequence) {
            correct = true
        }
        handleNextQuestion({ ...{ ...question, userSubmission: shuffleSequence }, correct })
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: shuffleSequence }, correct: false })
    }
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...

    //handles showCorrectAnswer lifeLines ...
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            setshuffleSequence(correctSequenceForShowCorrectAnswer)
        }
    }, [clickedShowCorrectAnswer])
    //handles showCorrectAnswer lifeLines ...
    return (
        <div>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='sequence' direction='horizontal'>
                    {
                        (provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{ backgroundColor: snapshot.isDraggingOver ? '' : '' }}
                                {...provided.droppableProps}
                                // ${snapshot.isDraggingOver ? 'bg-bgblue' : 'bg-gray-300'}
                                className={`w-[70%] m-auto flex   p-10 space-x-3 overflow-auto bg-bgblue border-bgblue border-2 rounded-md`}>
                                {
                                    shuffleSequence.length > 0 &&
                                    shuffleSequence.map((val, index) => (
                                        <Draggable
                                            key={val.id}
                                            index={index}
                                            draggableId={val.id}
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className='bg-white rounded-md p-2 flex items-center space-x-2 pr-6 shadow-md '
                                                >
                                                    <DragIndicatorOutlinedIcon className='!text-blue-300' fontSize='small' />
                                                    <span className='text-lg text-bgblue font-semibold'>{val.value}</span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                </Droppable>
            </DragDropContext>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </div >
    )
}

export default SentenceSequence;