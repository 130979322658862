import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
import { useNavigate } from 'react-router-dom'
const Option = ({ index, onChangeHandler, value }) => {
    //console.log('index==>', index)
    return (
        <div className='flex items-center h-10 w-full'>
            <div className={`border h-full
             ${index === 0 ? 'bg-mylightyellow' : ''}
             ${index === 1 ? 'bg-mylightgreen' : ''}
             ${index === 2 ? 'bg-mydarkgreen' : ''}
             ${index === 3 ? 'bg-mydarkyellow' : ''}
              px-4 flex items-center justify-center`}>
                <span>{`
                  ${index === 0 ? 'A' : ''}
                  ${index === 1 ? 'B' : ''}
                  ${index === 2 ? 'C' : ''}
                  ${index === 3 ? 'D' : ''}
                  `}</span>
            </div>
            <input
                onChange={(e) => onChangeHandler(index, e.target.value)}
                type='text'
                value={value}
                className='border border-black w-full h-full outline-none rounded-md border-opacity-50 px-2'
            />
        </div>
    )
}
const MultipleQuestions = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const navigate = useNavigate()
    const q = useSelector(state => state.question)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const initial_state = {
        statement: '',
        options: [
            { option: '' },
            { option: '' },
            { option: '' },
            { option: '' },
        ],
        correctAnswer: ''
    }
    const [multipleQuestionData, setmultipleQuestionData] = useState(initial_state)
    const { statement, correctAnswer, options } = multipleQuestionData
    const onChangeHandler = (index, value) => {
        setmultipleQuestionData({
            ...multipleQuestionData,
            options: multipleQuestionData.options.map((_v, _i) => index === _i ? { option: value } : _v)
        })
    }
    const handleSubmit = async (arg) => {
        let obj = {
            ...multipleQuestionData,
            questionCategory: subject,
            subject,
        }
        const isEmpty = options.find(val => (val.option).trim() === '')
        if (isEmpty) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill all the options!" }
            })
        }
        if (!obj.questionCategory) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill subject!" }
            })
        }
        if (!(statement.trim())) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill question statement!" }
            })
        }
        if (!(correctAnswer).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "kindly fill correct answer" }
        })
        const _idFind = q.questionsCategories.find((v) => v.name === type)

        obj = {
            ...obj,
            questionCategory: _idFind._id,
            user: auth.data.user._id,
        }
        if (canEdit) {
            await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
        } else {
            await dispatch(createQuestionsAction(obj))
        }
        if (arg) {
            setmultipleQuestionData(initial_state)
        } else {
            setmultipleQuestionData(initial_state)
            setshowAddQuestions(false)
        }
    }
    useEffect(() => {
        if (showQuestinData) {
            //console.log('show questin data===>', showQuestinData)
            setmultipleQuestionData({ ...showQuestinData, correctAnswer: showQuestinData.correctAnswer })
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])

    return (
        <Container>
            <div className='space-y-2'>
                <h1 className='text-bgblue font-bold'>Question</h1>
                <input
                    className='border border-black py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={statement}
                    onChange={(e) => setmultipleQuestionData({ ...multipleQuestionData, statement: e.target.value })}
                    placeholder='write question statement...'
                />
            </div>
            <div className='space-y-2 mt-5'>
                <h1 className='text-bgblue font-bold'>Correct Answer</h1>
                <select value={correctAnswer} onChange={(e) => setmultipleQuestionData({
                    ...multipleQuestionData,
                    correctAnswer: e.target.value
                })} className='w-full py-2 border border-black outline-none px-2 rounded-md'>
                    <option value=''>Select your currect answer...</option>
                    {
                        options?.map((val, index) => (
                            <option key={index} value={val.option}>{val.option}</option>
                        ))
                    }
                </select>
            </div>
            <div className='mt-10'>
                <h1 className='bg-bgblue p-2 w-full rounded-md text-white capitalize'>options</h1>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 mt-5'>
                    {multipleQuestionData?.options?.map((val, index) => (
                        <Option onChangeHandler={onChangeHandler} index={index} key={index} value={val.option} />
                    ))}
                </div>
            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <BottomButtons>
                        <button className='bg-bgblue text-white p-2 text-xs rounded-2xl' onClick={() => handleSubmit(false)}>{`Save & Close`}</button>
                        <button className='bg-bgblue text-white p-2 text-xs rounded-2xl' onClick={() => handleSubmit(true)}>{`Save & And Add Next`}</button>
                    </BottomButtons>
            }
        </Container>
    )
}
const BottomButtons = styled.div`
${tw`flex items-center justify-end mt-16 space-x-2`}
`
const Container = styled.div`
${tw`mt-5`}
`
export default MultipleQuestions