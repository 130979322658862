import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { globalConstants } from '../../actions/appConstants'
import { updateSubjectAction } from '../../actions/subjects.actions'
import SubjectModal from './SubjectModal'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { imageUpload } from '../../utils/cloudinary.config'
const SubjectItem = ({ subject }) => {
    const dispatch = useDispatch()
    const [update, setupdate] = useState(false)
    const [open, setopen] = useState(false)
    const [changedName, setchangedName] = useState({
        name: subject.name,
        subjectImage : subject.subjectImage
    })
    const handleSubmit = async () => {
        //console.log("subject ==>" , subject)
        //console.log(changedName)
        try {
            //console.log(typeof(changedName.subjectImage) === 'object')
            if (typeof(changedName.subjectImage) === 'object') {
                //console.log("hello from first condition")
                imageUpload([changedName.subjectImage])
                .then((updated)=> 
                {
                    if (updated.length === 0) return dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: "no image uploaded!" }
                    })
                    setchangedName({ ...changedName, subjectImage: updated[0].url })
                    const obj = {
                        name: changedName.name,
                        subjectImage: updated[0].url,
                        id: subject._id
                    }
                    dispatch(updateSubjectAction(obj)).then((val) => {
                        if (val) {
                            setupdate(false)
                        }
                    })
                })
                
            }
            else{
                //console.log("hello from other condition")
                const obj = {
                    name: changedName.name,
                    subjectImage: changedName.subjectImage,
                    id: subject._id
                }
                dispatch(updateSubjectAction(obj)).then((val) => {
                    if (val) {
                        setupdate(false)
                    }
                })
            }
    
           
    
            
        } catch (error) {
            //console.log(error)
        }
        
        
    }
    return (
        <Cont>
            <SubjectModal open={open} setopen={setopen} subject={subject} />
            <div className={`border ${update ? "flex flex-col" : 'grid grid-cols-2'} border-black items-center justify-between p-3 `}>
                {
                    update ?
                        <div className='w-full flex flex-col py-2'>
                            <input
                                value={changedName.name}
                                onChange={(e) => setchangedName({ ...changedName, name: e.target.value })}
                                placeholder='Enter new name'
                                className='border-b border-b-black w-full p-1 outline-none'
                            />
                            <div className=''>
                                <div className={`${changedName.subjectImage ? '' : 'border-dashed border border-green-700'} w-full relative h-36  `}>
                                    {
                                        typeof (changedName.subjectImage) === 'object' ?
                                            <img
                                                src={URL.createObjectURL(changedName.subjectImage)}
                                                className='w-full h-full object-cover'
                                            />
                                            :
                                            (typeof (changedName.subjectImage) === 'string' && changedName.subjectImage.trim()) &&
                                            <img
                                                src={changedName.subjectImage}
                                                className='w-full h-full object-cover'
                                            />

                                    }
                                    <div className={` ${changedName.subjectImage ? 'opacity-0 group-hover:opacity-100' : ''} w-full absolute top-[50%] left-[50%]  -translate-y-[50%]`}>
                                        <div className='relative  w-full h-full '>
                                            <input onChange={(e) => {
                                                const file = e.target.files[0]
                                                if (!file) {
                                                    return dispatch({
                                                        type: globalConstants.ALERT,
                                                        payload: { error: "no file selected!" }
                                                    })
                                                }
                                                if (file['type'].split('/')[0] !== 'image') {
                                                    return dispatch({
                                                        type: globalConstants.ALERT,
                                                        payload: { error: "you can only select image as you ID image" }
                                                    })
                                                }
                                                setchangedName({
                                                    ...subject,
                                                    subjectImage: file,
                                                })
                                                e.target.value = ''
                                            }} type='file' className='absolute w-full h-full opacity-0' />
                                            <FileUploadIcon color="primary" fontSize={'large'} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <h1>{subject.name}</h1>
                }
                {
                    update
                        ?
                        <div className='space-x-1 flex items-center justify-center'>
                            <button onClick={() => setupdate(false)} className='text-sm border border-red-600 bg-red-600 text-white py-1 px-2 uppercase'>
                                CANCEL
                            </button>
                            <button onClick={handleSubmit} className='text-sm border border-bgblue bg-bgblue text-white py-1 px-2 uppercase'>
                                SUBMIT
                            </button>
                        </div>

                        :
                        <>
                            <div className='space-x-1 flex items-center justify-center'>
                                <button onClick={() => setopen(true)} className='text-sm border border-red-600 bg-red-600 text-white py-1 px-2 uppercase'>
                                    DELETE
                                </button>
                                <button onClick={() => setupdate(true)} className='text-sm border border-bgblue bg-bgblue text-white py-1 px-2 uppercase'>
                                    UPDATE
                                </button>
                            </div>
                        </>
                }

            </div>
        </Cont>
    )
}
const Cont = styled.div`
`
export default SubjectItem