import axios from "axios";
import {
  authConstants,
  backend_url,
  globalConstants,
} from "../actions/appConstants";
import store from "../store";
const localUrl = "http://localhost:8888/api";
const serverUrl2 = "https://quizzingbox-production.onrender.com/api";
const serverUrl = "https://quizzingbox-jblw.onrender.com/api";
const instance = axios.create({

  baseURL: serverUrl2,
  withCredentials: true,
});
instance.interceptors.request.use(
  (req) => { 
    const {
      extras: { varifyuser },
    } = store.getState();
    const {
      auth: {
        data: { accessToken },
      },
    } = store.getState();
    //authentication
    if (accessToken) {
      req.headers.Authorization = accessToken;
    }
    //authentication
    if (varifyuser) {
      req.headers.varifyuser = `${varifyuser}`;
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.data?.signOut) {
      store.dispatch({
        type: authConstants.LOGOUT,
      });
    }
    return Promise.reject(error);
  }
);
export default instance;
