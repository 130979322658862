import React from 'react'
import Grid from '@mui/material/Grid';
import WorkCard from './WorkCard';
import Container from '@mui/material/Container';


import Submitimg from '../../../assets/staticdata/sign-up.png'
import Checkimg from '../../../assets/staticdata/approve.png'
import Questionimg from '../../../assets/staticdata/plus-sign.png'
import Shareimg from '../../../assets/staticdata/timer.png'
import Cashimg from '../../../assets/staticdata/social-media.png'
import Userimg from '../../../assets/staticdata/customer.png'
import Dataimg from '../../../assets/staticdata/calendar.png'
import Winnerimg from '../../../assets/staticdata/success.png'
import Awardimg from '../../../assets/staticdata/reward.png'
import Rewardimg from '../../../assets/staticdata/reward-card.png'
import Convertrewardimg from '../../../assets/staticdata/convert.png'





const data = [
   {
      icon: Submitimg,
      heading: "Step 1",
      detail: "Log in to your account"
   },
   {
      icon: Checkimg,
      heading: "Step 2",
      detail: "Navigate to quizmaster pro tab"
   },
   {
      icon: Questionimg,
      heading: "Step 3",
      detail: "Purchase the quizmaster pro subscription"
   },
   {
      icon: Shareimg,
      heading: "Step 4",
      detail: "Based on subscription purchased, user will be enabled to schedule all formats of quiz from all available formats"
   },
   {
      icon: Cashimg,
      heading: "Step 5",
      detail: "Build your question bank from question bank module of quizzingbox platform"
   },
   {
      icon: Userimg,
      heading: "Step 6",
      detail: "Schedule quiz using your question bank"
   },
   {
      icon: Dataimg,
      heading: "Step 7",
      detail: "Each scheduled quiz generate a sharable quiz link and passcode"
   },
   {
      icon: Winnerimg,
      heading: "Step 8",
      detail: "Share the quiz link and passcode with your desired audience who wish to participate in your scheduled quiz"
   },
   {
      icon: Awardimg,
      heading: "Step 9",
      detail: "Any user with the quiz link and passcode can participate in the quiz without having account on quizzingbox platform"
   },
   {
      icon: Rewardimg,
      heading: "Step 10",
      detail: "Quiz starts as per the schedule"
   },
   {
      icon: Convertrewardimg,
      heading: "Step 11",
      detail: "users play the quiz and ranked as per there performance automatically"
   },
]



const SheduleQuiz = () => {
   return (
      <div>
         <div className='flex flex-wrap gap-4 justify-center'>
            {
               data.map((val, index) => (
                  <WorkCard
                     key={index}
                     Details={val.detail}
                     Heading={val.heading}
                     IconImg={val.icon}
                  />
               ))
            }
         </div>
      </div>
   )
}

export default SheduleQuiz