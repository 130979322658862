import React, { useState, useEffect } from 'react'
import axios from '../utils/myAxios'
import { useSelector } from 'react-redux'

function Notifications({path}) {
    const [banner, setBanner] = useState()
    const {auth} = useSelector(state => state)
    useEffect(() => {
        if(path){
            axios.get(`/getLatestDashBoard/${path}/${auth?.data.user?._id}`)
            .then(res => {      
                if(res.data.banner){
                    setBanner(res.data?.banner)
                }                          
            })
            .catch(err => console.log(err))
        }        
    }, [path])
    
    return (
        <>
            {(banner?.bannerImage ) && 
                <div className='w-full my-3 relative'>
                    <div className='absolute top-1 right-2 border rounded-full text-xs w-6 h-6 flex items-center justify-center border-red-400 text-red-400 cursor-pointer' onClick = {()=>{
                        axios.post(`/updateDashBoard/${banner._id}/${auth?.data.user?._id}`, {})
                        .then(res => {
                            setBanner(null)
                        }
                        )

                    }}>
                        &#10005;
                    </div>
                    <img src={banner?.bannerImage} className='w-full rounded-xl shadow-md' alt="" />
                </div>
            }
        </>
    )
}

export default Notifications