import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'
import { shuffle } from '../../../utils/utilsFunctions';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'; import { lifeLinesConstants } from '../../../actions/appConstants';
import { useDispatch, useSelector } from 'react-redux';
const ClassificationSorting = ({ question, handleLeaveQuestion, handleNextQuestion, isLastQuestion }) => {
    const dispatch = useDispatch()
    //
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines
    //
    const [tobeFilledBoxes, settobeFilledBoxes] = useState([])
    const [dragAbleFields, setdragAbleFields] = useState([])
    const [correctAnswer, setcorrectAnswer] = useState({})
    useEffect(() => {
        if (question) {
            let boxesWithEmptyFields = question.forSortingClassificationType.map(v => ({ ...v, values: [] }))
            settobeFilledBoxes(boxesWithEmptyFields)
            let allDragAbbleFields = []
            let tempArrForCorrect = []
            question.forSortingClassificationType.forEach((v, index) => {
                let temtemp = []
                v.values.forEach(_v => {
                    allDragAbbleFields.push({ id: uuid(), value: _v })
                    temtemp.push({ id: uuid(), value: _v })
                })
                tempArrForCorrect.push({
                    name: v.name,
                    values: temtemp
                })
            });
            // console.log('tempArrForCorrect===>', tempArrForCorrect)
            setcorrectAnswer(tempArrForCorrect)
            setdragAbleFields(shuffle(allDragAbbleFields))

        }
    }, [question])
    const handleDragEnd = (e) => {
        const { source, destination } = e;
        //console.log(e)
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        //user cant drop fields back to the draggable container
        if (source.droppableId !== 'dragAbleFields' && destination.droppableId === 'dragAbleFields') return;

        const _drag_fields = dragAbleFields.slice()
        const _tobe_filled_boxes = tobeFilledBoxes.slice()
        if (source.droppableId === 'dragAbleFields') {
            const draggedValue = _drag_fields[source.index];
            const newTobeFilled = _tobe_filled_boxes.map((val, index) => {
                if (val.name === destination.droppableId) {
                    let tempVal = { ...val }
                    tempVal.values.splice(destination.index, 0, draggedValue)
                    return val;
                }
                return val;
            })
            //remove that field from the dragabble array...
            _drag_fields.splice(source.index, 1)
            setdragAbleFields(_drag_fields)
        } else {
            let draggedValue;
            _tobe_filled_boxes.map((val, index) => {
                if (val.name === source.droppableId) {
                    draggedValue = val.values[source.index]
                    val.values.splice(source.index, 1)
                }
            })
            _tobe_filled_boxes.map((val, index) => {
                if (val.name === destination.droppableId) {
                    val.values.splice(destination.index, 0, draggedValue)
                }
            })
        }
    }
    const handleValidation = () => {
        let correct = true;
        question.forSortingClassificationType.map((val, index) => {
            const containerArray = tobeFilledBoxes[index].values.map((t) => t.value)
            //console.log('container array==>', containerArray)
            val.values.map((_v, _i) => {
                const isContain = containerArray.includes(_v)
                if (!isContain) {
                    correct = false
                }
            })
        })
        handleNextQuestion({ ...{ ...question, userSubmission: tobeFilledBoxes }, correct })
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: tobeFilledBoxes }, correct: false })
    }
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true,
            })
        }
    }, [question])
    //handles which lifelines to show ...
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            settobeFilledBoxes(correctAnswer)
            setdragAbleFields([])
        }
    }, [clickedShowCorrectAnswer])
    return (
        <>
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className=''>
                    {/* draggalbe fields */}
                    <div className='mb-10'>
                        <Droppable droppableId="dragAbleFields" direction='horizental'>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className='bg-bgblue border-bgblue border-2 rounded-md p-10 flex items-center flex-wrap gap-4 justify-center mt-10' >
                                    {
                                        dragAbleFields.map((val, index) => (
                                            <Draggable key={val.id} draggableId={val.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='bg-white rounded-md p-2 flex items-center space-x-2 pr-6 shadow-md'
                                                    >
                                                        <DragIndicatorOutlinedIcon className='!text-blue-300' fontSize='small' />
                                                        <span className='text-lg text-bgblue font-semibold'>{val.value}</span>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    {/* draggalbe fields */}
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4'>
                        {/* droppable boxes */}
                        {tobeFilledBoxes.map((val, index) => (
                            <div className=' shadow-indigo-600 shadow-2xl rounded-md p-4 bg-white'>
                                <h1 className=' text-center font-semibold '>{val.name}</h1>
                                <Droppable droppableId={val.name} >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className={`bg-blue-200 p-2 space-y-3 min-h-[300px] ${snapshot.isDraggingOver ? 'bg-yellow-200' : ''}`}>
                                            {
                                                val.values.length == 0 ?
                                                    <div className='!h-full '>
                                                        <div className='flex flex-col items-center justify-center min-h-[300px] opacity-70'>
                                                            <CloudUploadOutlinedIcon />
                                                            <h1 className='font-extrabold text-2xl'>Drag & Drop</h1>
                                                        </div>
                                                    </div>
                                                    :
                                                    val.values.map((_val, _index) => (
                                                        <Draggable key={_val.id} draggableId={_val.id} index={_index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='bg-white rounded-md p-2 flex items-center space-x-2 pr-6 shadow-md'
                                                                >
                                                                    <DragIndicatorOutlinedIcon className='!text-blue-300' fontSize='small' />
                                                                    <span className='text-lg text-bgblue font-semibold'>{_val.value}</span>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                            }
                                            {provided.placeholder}
                                        </div>

                                    )}
                                </Droppable>
                            </div>
                        ))
                        }
                    </div>

                </div>
            </DragDropContext>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </>
    )
}

export default ClassificationSorting