import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import TableRow from './TableRow'

const MyTable = ({ questions, setquestions, adminQuestionsState, setshowUpdateQuiz }) => {
    const { auth } = useSelector(s => s)
    return (
        <div class="overflow-x-auto">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden rounded-xl">
                <div class="w-full overflow-auto">
                    <div class=" shadow-md rounded p-3 ">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class=" bg-gray-300 text-gray-600 uppercase text-sm leading-normal">
                                    <th class="py-3 px-6 text-left">quiz title</th>
                                    <th class="py-3 px-6 text-center">type</th>
                                    <th class="py-3 px-6 text-center">My Earnings</th>
                                    <th class="py-3 px-6 text-center">tax on qzetos</th>
                                    <th class="py-3 px-6 text-center">Quiz Scheduler Earning</th>
                                    <th class="py-3 px-6 text-left">Date</th>
                                    <th class="py-3 px-6 text-center">start time</th>
                                    <th class="py-3 px-6 text-center">end time</th>
                                    <th class="py-3 px-6 text-center">total Questions</th>
                                    <th class="py-3 px-6 text-center">TOTAL REGISTERED USERS</th>
                                    <th class="py-3 px-6 text-center">REGISTRATION FEE</th>
                                    <th class="py-3 px-6 text-center">Category</th>
                                    <th class="py-3 px-6 text-center">Status</th>
                                    <th class="py-3 px-6 text-center">Quiz Detail</th>
                                    {/* <th class="py-3 px-6 text-center">Actions</th> */}
                                    {/* <th class="py-3 px-6 text-center">qzeto type</th> */}
                                    {
                                        auth.data.user.isAdmin &&
                                        <th class="py-3 px-6 text-center">Actions</th>
                                    }
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    questions.length > 0 &&
                                    questions.map((val, index) => (
                                        <TableRow
                                            setshowUpdateQuiz={setshowUpdateQuiz}
                                            adminQuestionsState={adminQuestionsState}
                                            questions={questions} setquestions={setquestions} val={val} key={index} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTable