import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { lifeLinesConstants } from '../../../actions/appConstants'
import MyDragAbleC from './../matchThePair/MyDragAbleC'
import StaticRows from './../matchThePair/StaticRows'
const MatchThePair = ({ question, handleLeaveQuestion,
    handleNextQuestion, isLastQuestion }) => {
    const dispatch = useDispatch()
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion
    } = lifeLines;
    const [ca, setca] = useState(null)
    const [cb, setcb] = useState(null)
    useEffect(() => {
        setca(question.collumns.a)
        setcb(question.collumns.b)
    }, [question])
    const handleValidation = () => {
        let status = true;
        question.collumns.b.map((v, index) => {
            if (v !== ca[index]) {
                status = false;
            }
        })
        let correct = status
        handleNextQuestion({ ...{ ...question, userSubmission: ca }, correct })
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: ca }, correct: false })
    }
    //handles which lifelines to show ... 
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...
    //show correct answer life line
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            setca(question.collumns.b)
        }
    }, [clickedShowCorrectAnswer])
    //show correct answer life line
    return (
        <Cont>
            {
                (ca && cb) &&
                <MyDragAbleC setc={setca} column={ca} />
            }
            <div className='mt-20 p-2 flex items-center justify-end text-sm '>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw`grid`}
`
export default MatchThePair