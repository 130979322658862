import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
// import CardModal from './CardModal'
import axios from '../../utils/myAxios'
//icons
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { unRegisterQuizzAction } from '../../actions/quizzSchedule.actions'
import { useNavigate } from 'react-router-dom'
import { globalConstants } from '../../actions/appConstants'
//icons
const Icons = ({ total, icon, color, price }) => {
    return (
        <div className='flex items-center gap-x-3'>
            <Avatar className={`!text-white `} style={{
                background: color ? color : ''
            }}
                sx={{ width: 24, height: 24 }}
            >
                {icon}
            </Avatar>
            <h1 className='text-xs whitespace-pre opacity-50 !text-black font-bold '>{price ? `${total} Qzetos` : total}</h1>
        </div>
    )
}
const QuizzCard = ({ quizz, user, handleUpdatedQuizz }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setopen] = useState(false)
    const [timer, settimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    const cardInit = {
        play: false,
        timesup: false,
        register: false,
        unregister: false,
    }
    const [cardStates, setcardStates] = useState(cardInit)
    const { auth } = useSelector(state => state)
    useEffect(async () => {
        const t = setTimeout(async () => {
            const fun = () => {
                const str2 = quizz.time
                const str1 = new Date()
                var diff = Date.parse(str2) - Date.parse(str1);
                return isNaN(diff) ? NaN : {
                    diff: diff,
                    ms: Math.floor(diff % 1000),
                    s: Math.floor(diff / 1000 % 60),
                    m: Math.floor(diff / 60000 % 60),
                    h: Math.floor(diff / 3600000 % 24),
                    d: Math.floor(diff / 86400000)
                }
            }
            const d = fun()
            // console.log('timer==>', d)
            settimer({
                days: d?.d >= 0 ? d.d : 0,
                hours: d?.h >= 0 ? d.h : 0,
                minutes: d?.m >= 0 ? d.m : 0,
                seconds: d?.s >= 0 ? d.s : 0
            })
            //for reistered and times up or time of playing
            if (d.d <= 0 && d.h <= 0 && d.m <= 0 && d.s <= 0) {
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    const s_time = new Date(quizz.time)
                    s_time.setMinutes(s_time.getMinutes() + Number(quizz.duration))
                    const c_time = new Date().getTime()
                    if (s_time.getTime() < c_time) {
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    } else {
                        try {
                            if (quizz.resheduleAllow) {
                                // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            } else {
                                setcardStates({
                                    ...cardInit,
                                    play: true
                                })
                            }
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    }
                } else {
                    if (quizz.resheduleAllow) {
                        try {
                            // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                            // handleUpdatedQuizz(_res_res.data.quizz)
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    } else {
                        try {
                            // if (quizz.status !== 'closed') {
                            //     const its_obj = { status: 'closed' }
                            //     const _res_res = await axios.post(`/update/quizzstatus/${quizz._id}`, {
                            //         ...its_obj
                            //     })
                            //     handleUpdatedQuizz(_res_res.data.quizz)
                            // }
                        } catch (error) {
                            // dispatch({
                            //     type: globalConstants.ALERT,
                            //     payload: { error: error.message }
                            // })
                        }
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    }
                }
            }
            //for reistered and times up or time of playing
            //for registered unregistered
            if (d.d > 0 || d.h > 0 || d.m > 0 || d.s > 0) {
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    setcardStates({
                        ...cardInit,
                        unregister: true
                    })
                } else {
                    setcardStates({
                        ...cardInit,
                        register: true
                    })
                }
            }
            //for registered unregistered
            //increase the days if the minimum numbers of people are not matched
        }, 1000);
        return () => clearInterval(t)
    }, [timer])
    const handleRegist = () => {
        setopen(true)
    }
    const handleUnRegister = async () => {
        await dispatch(unRegisterQuizzAction({ quizzId: quizz._id, quizz }, handleUpdatedQuizz))
    }
    const handleNavigate = () => {
        if (quizz.isSchoolTypeQuizz === true) return;
        navigate(`/singlequizz/detailscreen/${quizz._id}`)
    }
    const handleDistribution = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const _obj = {
                qid: quizz._id
            }
            const res = await axios.post('/distribute/prizzes', {
                ..._obj
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "prizez distributed successfully!" }
            })
            handleUpdatedQuizz(res.data.quizz)
        } catch (error) {
            // console.log('error==>', error.response)
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <Container>
            {/* <CardModal handleUpdatedQuizz={handleUpdatedQuizz} open={open} setopen={setopen} quizz={quizz} /> */}
            <Top onClick={handleNavigate}>
                <div className='flex flex-col justify-between p-2'>
                    <div className='flex  px-2 pt-1 z-10'>
                        <h1 className='line-clamp-2 text-lg  flex-1 capitalize'>
                            {quizz.title}
                        </h1>
                        {
                            quizz.type === 'free'
                                ?
                                <span className='capitalize !shrink-0 w-max h-max py-1 px-2  bg-green-800 rounded-xl text-xs  tracking-widest'>free</span>
                                :
                                <span className='capitalize !shrink-0 w-max h-max py-1 px-2  bg-red-800 rounded-xl text-xs  tracking-widest'>paid</span>
                        }
                    </div>
                    <div className='p-2 space-y-2 flex flex-col justify-end'>
                    </div>
                </div>
                <div className='w-full relative flex items-center gap-x-3 bg-[#F0F0F0] p-1 !text-black font-bold'>
                    <Icons
                        color='#BF2B60'
                        icon={<QuestionMarkOutlinedIcon fontSize='small' className='p-[.2rem]' />}
                        total={quizz.questions.length}
                    />
                    <Icons
                        color='#00459E'
                        icon={<PermIdentityOutlinedIcon fontSize='small' className='p-[.2rem]' />}
                        total={quizz.registerdUsers.length}
                    />
                    <Icons
                        color='#FFB125'
                        icon={<AttachMoneyOutlinedIcon fontSize='small' className='p-[.2rem]' />}
                        total={quizz.type === 'free' ? Number(quizz.requiredPointsForFree) : Number(quizz.price)}
                        price={true}
                    />
                    {/* created by ... */}
                    <Avatar className={`!text-white !bg-bgblue  -top-6 right-12 ring-1 ring-white`}
                        sx={{
                            position: 'absolute',
                            width: 50,
                            height: 50,
                        }}
                    >
                        <PermIdentityOutlinedIcon fontSize='medium' />
                    </Avatar>
                </div>
            </Top>
            <Bottom>
                <div onClick={handleNavigate} className='flex'>
                    <div className='flex-1 space-y-3'>
                        <h1 className='capitalize text-sm font-semibold tracking-wide'>Quiz schedule</h1>
                        <div className='space-y-2'>
                            <h1 className='text-xs tracking-widest font-semibold opacity-50'>Date: {moment(quizz.date).format("MMM Do YY")}</h1>
                            <h1 className='text-xs tracking-widest font-semibold opacity-50'>Start: {moment(quizz.time).format('LT')}</h1>
                            <h1 className='text-xs tracking-widest font-semibold opacity-50'>Duration: {`${quizz.duration} minutes`}</h1>
                        </div>
                    </div>
                </div>
                {
                    quizz.status === 'closed'
                        ?
                        <button disabled className='bg-red-500 text-white rounded-md py-2 w-[100%] ml-auto text-center outline-none border-none'>Distributed</button>
                        :
                        <button onClick={handleDistribution} className='bg-mylightyellow text-white rounded-md py-2 w-[100%] ml-auto text-center outline-none border-none'>Distribute Prize</button>
                }
            </Bottom>
        </Container >
    )
}
const TimerCont = styled.div`
${tw`flex items-center gap-x-4`}
`
const Bottom = styled.div`
${tw` !h-[50%] w-full flex flex-col justify-between pt-2 px-2 pb-1
 bg-white
 `}
`
const Top = styled.div`
${tw`
// bg-[#00459E] 
!h-[50%] relative text-white flex flex-col justify-between z-10`}
&::before{
    content: '';
    background-image: url('https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80');
    /* background-image: url('https://i.pinimg.com/originals/db/34/d4/db34d40b271fb59477621550bf73ea0b.jpg'); */
background-position: center center;
background-size: cover;
position: absolute;
top: 0;
left:0;
right: 0;
bottom: 0;
z-index: 0 !important;
}

`

const Container = styled.div`
${tw` h-[350px] w-full shadow-2xl cursor-pointer rounded-xl overflow-hidden `}
`
export default QuizzCard