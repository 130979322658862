import React from 'react'
import Grid from '@mui/material/Grid';
import WorkCard from './WorkCard';
import Container from '@mui/material/Container';
import Loginimg from '../../../assets/staticdata/permission.png'
import Filterimg from '../../../assets/staticdata/filter.png'
import Checkimg from '../../../assets/staticdata/clipboard.png'
import Ruleimg from '../../../assets/staticdata/auction.png'
import Computerimg from '../../../assets/staticdata/add.png'
import Dataimg from '../../../assets/staticdata/calendar.png'
import Winnerimg from '../../../assets/staticdata/success.png'
import Awardimg from '../../../assets/staticdata/reward.png'

const data = [
   {
      img: Loginimg,
      heading: "Step 1",
      details: "Log in to your account"
   },
   {
      img: Filterimg,
      heading: "Step 2",
      details: "Navigate to play quiz & earn tab"
   },
   {
      img: Checkimg,
      heading: "Step 3",
      details: "Select the quiz category for which you want play the quiz"
   },
   {
      img: Ruleimg,
      heading: "Step 4",
      details: "By default all the free quiz for the category selected appears "
   },
   {
      img: Computerimg,
      heading: "Step 5",
      details: "Read the quiz rules carefully before registering for the quiz"
   },
   {
      img: Dataimg,
      heading: "Step 6",
      details: "Register to the quiz using free qzetos & play the quiz as per the quiz schedule"
   },
   {
      img: Winnerimg,
      heading: "Step 7",
      details: "Winners are awarded with bonus qzetos as reward in thier bonus qzetos wallet instantly once quiz is over"
   },
   {
      img: Awardimg,
      heading: "Step 8",
      details: "Convert bonus qzetos to real qzetos from wallet tab, and redeem these real qzetos for award from the wallet"
   },
]
const PlayFreeQuiz = () => {
   return (
      <div>
         <div className='flex gap-4 flex-wrap justify-center'>
            {
               data.map((item, index) => (
                  <WorkCard key={index} IconImg={item.img} Heading={item.heading} Details={item.details} />
               ))
            }
         </div>
      </div>
   )
}

export default PlayFreeQuiz