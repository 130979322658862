import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import PlayQuizComponents from '../components/playQuizComponents/PlayQuizComponents'
import { set } from 'lodash'
const PlayQuizz = () => {
    const [user, setuser] = useState(null)
    const [isPastQuizz, setisPastQuizz] = useState(false)
    const [isQuizzithonQuiz, setisQuizzithonQuiz] = useState(false)
    const [quizzithonId , setquizzithonId] = useState(null)
    const { state } = useLocation();
    const { auth } = useSelector(s => s)
    useEffect(() => {
        if (state?.user) {
            setisPastQuizz(state.isPastQuiz)
            setuser(state.user)
            setisQuizzithonQuiz(state.isQuizzithonQuiz)
            setquizzithonId(state.quizzithonId? state.quizzithonId : null)
        }
    }, [state])
    // console.log('state==>outside...', state)
    return (
        <>
            {
                auth.authenticate ?
                    <>
                    
                    <Layout>
                        {
                            
                            user !== null ?
                                <PlayQuizComponents user={user} isPastQuiz={isPastQuizz} isQuizzithonQuiz={isQuizzithonQuiz} quizzithonId = {quizzithonId} />
                                :
                                <div>loading....</div>
                        }
                    </Layout>
                    </>
                    :
                    <>
                    
                    <div className='p-5 select-none'>
                        {
                            user !== null ?
                                <PlayQuizComponents user={user} isPastQuiz={isPastQuizz} />
                                :
                                <div>loading....</div>
                        }
                    </div>
                    </>
            }
        </>
    )
}

export default PlayQuizz