import React from 'react'
import ExcelQuestionUploads from './../components/QuestionBankComponents/ExcelQuestionUploads'
import Layout from '../components/Layout/Layout'
import { useSelector } from 'react-redux'
import templatefile from '../assets/exceltemplate/Template.xlsx'
import { questionsCategoryConstants } from '../actions/appConstants'
const includednames = [
    questionsCategoryConstants.MULTIPLE_QUESTIONS,
    questionsCategoryConstants.FILL_IN_THE_BLANKS,
    questionsCategoryConstants.TRUE_FALSE,
    questionsCategoryConstants.CLOSED_ENDED,
]
const ImportExcelSheets = () => {
    const { question, subjects } = useSelector(s => s)
    return (
        <Layout>
            <div className='p-5'>
                <div className=' bg-bgblue p-4 rounded-xl'>
                    <div className='flex items-center flex-wrap gap-4 justify-between
                    flex-col 
                    lg:flex-row
                    '>
                        <div className='flex items-center space-x-2'>
                            <h1 className='text-white text-xs font-semibold'>upload your file</h1>
                            <ExcelQuestionUploads />
                        </div>
                        <a download href={templatefile} className='bg-green-500 text-white text-center shadow-xl   rounded-md py-2 px-3'>
                            Download Sample Document
                        </a>
                    </div>
                </div>
                {/* allowed categories */}
                <h1 className='text-lg font-semibold capitalize text-center mt-5 underline underline-offset-4'>Allowed categories</h1>
                <div class="overflow-x-auto mt-5">
                    <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                        <div class="w-full  overflow-auto">
                            <div class="bg-white shadow-md rounded p-3">
                                <table class="min-w-max w-full table-auto overflow-x-scroll">
                                    <thead>
                                        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th class="py-3 px-6 text-left">category name</th>
                                            <th class="py-3 px-6 text-center">category value</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 text-sm font-light">
                                        {
                                            question.questionsCategories.filter(c => includednames.includes(c.name)).map((val, index) => (
                                                <tr class="border-b border-gray-200 hover:bg-gray-100">
                                                    <td class="py-3 px-6 text-left whitespace-nowrap">
                                                        <span>{val.name.replace(/_/g, ' ')}</span>
                                                    </td>
                                                    <td class="py-3 px-6 text-center whitespace-nowrap">
                                                        <span>{val.name}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* allowed categories */}


                {/* allowed subjects */}
                <h1 className='text-lg font-semibold capitalize text-center mt-5 underline underline-offset-4'>Allowed Subjects</h1>
                <div class="overflow-x-auto mt-5">
                    <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                        <div class="w-full  overflow-auto">
                            <div class="bg-white shadow-md rounded p-3">
                                <table class="min-w-max w-full table-auto overflow-x-scroll">
                                    <thead>
                                        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th class="py-3 px-6 text-left">Subject No.</th>
                                            <th class="py-3 px-6 text-center">Subject Name</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 text-sm font-light">
                                        {
                                            subjects.subjects.map((val, index) => (
                                                <tr class="border-b border-gray-200 hover:bg-gray-100">
                                                    <td class="py-3 px-6 text-left whitespace-nowrap">
                                                        <span>{index + 1}</span>
                                                    </td>
                                                    <td class="py-3 px-6 text-center whitespace-nowrap">
                                                        <span>{val.name}</span>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* allowed subjects */}
            </div>
        </Layout>
    )
}

export default ImportExcelSheets