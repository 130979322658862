import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Wallet from './../../../assets/layout/wallet.png'
import Hand from './../../../assets/layout/hand.png'
import Dollar from './../../../assets/layout/dollar.png'
import { useDispatch, useSelector } from 'react-redux';
import { registerInQuizzAction } from '../../../actions/quizzSchedule.actions';
import { useNavigate } from 'react-router-dom';
export default function CardModal({ open, setopen, quizz, handleUpdatedQuizz }) {
    const navigate = useNavigate()
    const [modalState, setmodalState] = React.useState({
        diableYes: false,
    })
    const dispatch = useDispatch()
    const { wallet } = useSelector(state => state)
    const handleClose = () => {
        setopen(false)
    };
    const getWalletData = (type) => {
        const obj = wallet.wallet.find(v => v.walletsType === type)
        const toreturn = obj?.credit ? obj.credit : 0
        return toreturn;
    }
    const handleYes = () => {
        dispatch(registerInQuizzAction({
            quizzScheduleId: quizz._id,
            quizz
        }, handleUpdatedQuizz, setopen))
    }
    //
    React.useEffect(() => {
        if (quizz) {
            const fun = () => {
                let bol = false;
                if (quizz.type === 'free') {
                    const credit = getWalletData('free')
                    //console.log('free credit==>', credit)
                    if (Number(credit) < Number(quizz.requiredPointsForFree)) {
                        bol = true
                    }
                }
                if (quizz.type === 'paid') {
                    const credit = getWalletData('real')
                    //console.log('paid credit==>', credit)
                    if (Number(credit) < Number(quizz.price)) {
                        bol = true;
                    }
                }
                setmodalState({ ...modalState, diableYes: bol })
            }
            fun()
        }
    }, [quizz])
    return (
        <Dialog onClose={handleClose} open={open}>
            <div className='p-10'>
                <h1 className='capitalize font-bold text-center mb-5'>do you want to register?</h1>
                <div className='flex items-center space-x-4'>
                    <div className="flex items-center gap-x-3 tracking-widests border-l-4 border-l-bgblue border pl-2 border-t-0 border-r-0 border-b-0">
                        <img src={Wallet} alt='' className="h-5 object-contain" />
                        <div className="gap-y-1">
                            <h1 className="capitalize text-xs font-semibold">{`Required ${quizz.type === 'paid' ? 'real' : 'free'} qzeto`}</h1>
                            <h1 className="text-xs">{quizz.type === 'paid' ? quizz.price : quizz.requiredPointsForFree}</h1>
                            <h1 className="capitalize text-xs font-semibold">{`${quizz.type === 'paid' ? 'real' : 'free'} qzeto`}</h1>
                        </div>
                    </div>
                    <div className="flex items-center gap-x-3 tracking-widests border-l-4 border-l-bgblue border pl-2 border-t-0 border-r-0 border-b-0">
                        <img src={Wallet} alt='' className="h-5 object-contain" />
                        <div className="gap-y-1">
                            <h1 className="capitalize text-xs font-semibold">{`Available ${quizz.type === 'paid' ? 'real' : 'free'} qzeto`}</h1>
                            <h1 className="text-xs">{quizz.type === 'paid' ? (getWalletData('real')).toFixed(0) : (getWalletData('free')).toFixed(0)}</h1>
                            <h1 className="capitalize text-xs font-semibold">{`${quizz.type === 'paid' ? 'real' : 'free'} qzeto`}</h1>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center space-x-2 mt-5'>
                    <button onClick={() => setopen(false)} className='bg-gray-300 py-1 px-6 rounded-2xl'>No</button>
                    <button
                        disabled={modalState.diableYes}
                        onClick={handleYes} className={`${modalState.diableYes ? 'bg-gray-500' : 'bg-mydarkgreen'} text-white py-1 px-6 rounded-2xl`}
                    >Yes
                    </button>
                </div>
                {
                    modalState.diableYes &&
                    <h1 className='text-xs text-center mt-4'>Not Enough Coins*</h1>
                }
            </div>
        </Dialog>
    );
}