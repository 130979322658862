import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants, lifeLinesConstants } from '../../../actions/appConstants'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
//regex for checking if the user adeed any special character in the Blanks
var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
//regex for checking if the user adeed any special character in the Blanks
const DragDropFillUpTheBlanks = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const dispatch = useDispatch()
    const { auth, question, lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion
    } = lifeLines
    const [textareadata, settextareadata] = useState('')
    const [blanks, setblanks] = useState([])
    const [statement, setstatement] = useState('')
    const handleSubmit = async (stay) => {
        try {
            let obj = {
                questionCategory: type,
                subject,
                statement,
                user: auth.data.user._id
            }
            //validations
            if (!(obj.questionCategory).trim()) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: {
                        error: "kindly fill the question type!"
                    }
                })
            }
            if (!(obj.statement).trim()) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "kindly fill question statement!" }
                })
            }
            if (!(obj.subject).trim()) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "kindly select the subject!" }
                })
            }
            if (blanks.length === 0) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "No fill in the blanks added!" }
                })
            }
            let hasSpecialCharacter = false;
            const newBlanks = [...blanks]
            const updatedBlanks = newBlanks.map((_b) => _b.slice(1, _b.length - 1))
            updatedBlanks.forEach((_b) => {
                if (format.test(_b)) {
                    hasSpecialCharacter = true;
                }
            })
            if (hasSpecialCharacter) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "blanks with special characters are not allowed!" }
            })
            //validations
            const _idFind = question.questionsCategories.find((v) => v.name === type)

            obj.questionCategory = _idFind._id
            obj.forDragDropFillUpBlanks = textareadata;
            //console.log('obj==>', obj)
            if (canEdit) {
                await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
            } else {
                await dispatch(createQuestionsAction(obj))
            }
            if (stay) {
                setstatement('')
                settextareadata('')
                setblanks([])
            } else {
                setshowAddQuestions(false)
            }
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const getBlanks = (sentence) => {
        if (!sentence.trim()) return setblanks([]);
        const splitted = sentence.split(' ')
        let newBlanks = [];
        splitted.forEach(v => {
            if (v.startsWith('<') && v.endsWith('>')) {
                let isOkay = true;
                if (v.length === 2) {
                    isOkay = false;
                }
                v.split('').forEach(character => {
                    if (!character.trim()) {
                        isOkay = false;
                    }
                })
                if (isOkay) {
                    newBlanks.push(v)
                }
            }
        })
        setblanks(newBlanks)
    }
    const handleTextOnChange = (e) => {
        settextareadata(e.target.value)
        getBlanks(e.target.value)
    }
    useEffect(() => {
        if (showQuestinData) {
            //console.log('questine data==>', showQuestinData)
            settextareadata(showQuestinData.forDragDropFillUpBlanks)
            setstatement(showQuestinData.statement)
            getBlanks(showQuestinData.forDragDropFillUpBlanks)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
   
    return (
        <div>
            <div className='p-5'>
                <div className='space-y-2 mb-2'>
                    <h1 className='text-bgblue text-sm '>Statement</h1>
                    <input
                        className='border border-gray-300 py-2 px-2 w-full outline-none rounded-md'
                        type='text'
                        value={statement}
                        onChange={(e) => setstatement(e.target.value)}
                        placeholder='write question statement...'
                    />
                </div>
                <div className='space-y-2'>
                    <h1 className='text-bgblue text-sm '>Your blanks</h1>
                    <textarea
                        placeholder='drap & drop for fill in the blanks...'
                        className='w-full border border-gray-300 rounded-md outline-none p-3  '
                        rows={6}
                        value={textareadata}
                        onChange={handleTextOnChange}
                    />
                </div>
                <div className='space-x-3'>
                    {
                        blanks.map((val, index) => {
                            let sentence = val.split('<')[1]
                            sentence = sentence.split('>')[0]
                            return (
                                <span className='text-white rounded-md text-xs bg-green-400 py-1 px-2'>{sentence}</span>
                            )
                        })
                    }
                </div>
                {
                    showQuestinData?.edit === false ?
                        <div></div>
                        :
                        <div className='flex items-center justify-end space-x-4 mt-4'>
                            <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                            <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                        </div>
                }
            </div>
        </div>
    )
}

export default DragDropFillUpTheBlanks