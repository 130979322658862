import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import axios from '../../utils/myAxios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants } from '../../actions/appConstants';

function PaymentSuccess() {
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch = useDispatch();
  const {stripeState} = useSelector(s=>s)
  const queryParams = new URLSearchParams(location.search);
  const [payment_intent, setPaymentIntent] = useState(null);
  const [payment_secret, setPaymentSecret] = useState(null);
  const [status, setStatus] = useState(null);
  const [plan, setPlan] = useState(null);
  useEffect(() => {
    setPaymentIntent(queryParams.get('payment_intent'))
    setPaymentSecret(queryParams.get('payment_intent_client_secret'))
    setStatus(queryParams.get('redirect_status'))
    setPlan(JSON.parse(localStorage.getItem('plan')))
  }, [])
  const updateBalance = async ()=>{
    try {
      await axios.post('/addrealqzetos/afterpayment', {
        numberOfRealQzetos : plan.amount,
        plan
      })
     await axios.post('/create/boughtqzeto/history', {
        user : localStorage.getItem('uid'),
        payment_id : payment_intent,
        amount : plan.price,
        numberOfQzetos : plan.amount,
        quizzes : plan.quizzes,
        ai_questions : plan.ai_questions,
        quizzithon : plan.quizzithon,
        quizmaster_pro : plan.quizmaster_pro
      })

      dispatch({
        type: globalConstants.ALERT,
        payload: {success: 'Purchase successful'}
      
      })
      localStorage.removeItem('plan')
      navigate('/payments')
    } catch (error) {
      dispatch({
        type: globalConstants.ALERT,
        payload: {success: 'Purchase successful'}
      
      })
      console.log(error.response.data)
    }
  }
  
  useEffect(()=>{
    if(status === 'succeeded'){
      updateBalance()
    }
  },[payment_intent])
  console.log(queryParams.get("payment_intent"))
  return (
    <div className='w-full h-screen flex justify-center items-center flex-col'>
      <h1>Processing</h1>
      <CircularProgress sx={{
        color: 'green'
      }}/>
    </div>
  )
}

export default PaymentSuccess