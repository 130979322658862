import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import QuizzRules from './Tabs/QuizzRules';
import PrizePool from './Tabs/PrizePool';
import KnowledgeBank from './Tabs/KnowledgeBank';
import LeaderBoard from './Tabs/LeaderBoard';
import HowItWorks from './Tabs/HowItWorks'
import { useLocation } from 'react-router-dom'
import SubmittedAnswers from './Tabs/SubmittedAnswers';
import NotFoundDataUi from '../../uiComponents/NotFoundDataUi'
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        className='w-full'
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ qid, quizz }) {
    const [showQuestions, setshowQuestions] = React.useState(true)
    const [value, setValue] = React.useState(0);
    const [hideLeaderBoard, sethideLeaderBoard] = React.useState(false)

    const handleChange = (event, newValue) => {
        
        setValue(newValue);
    };
    const { state } = useLocation()
    //console.log('state location===>', state)
    React.useEffect(() => {
        if (state?.isPastQuiz === false) {
            setshowQuestions(false)
        }
    }, [state])
    React.useEffect(() => {
        if (state?.isSchool) {
            setValue(4)
        }
        if (state?.submissions) {
            setValue(5)
        }
    }, [state])
    React.useEffect(() => {
        if (quizz.isDurationBound) {
            const s_time = new Date(quizz.time)
            const c_time = new Date().getTime()
            s_time.setDate(s_time.getDate() + 1)
            if (s_time.getTime() > c_time) {
                sethideLeaderBoard(true)
            } else {
                sethideLeaderBoard(false)
            }
        }
    }, [quizz.isDurationBound])
    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider'  , width: '100%'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    <Tab label="Quizz Rules" {...a11yProps(0)} />
                    {/* <Tab label="How It Works" {...a11yProps(1)} /> */}
                    {!quizz.isQuizzithonQuiz &&<Tab label="Prize Pools" {...a11yProps(1)} />}
                    {/* {
                        !state?.isPastQuiz && */}
                    {/* <Tab label="Knowledge Bank" {...a11yProps(3)} /> */}
                    {/* } */}
                    <Tab label="Leader Board" {...a11yProps(2)} />
                    <Tab label="Submissions" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{
                height: "300px",
                overflow: "auto"
            }}>
                <QuizzRules quizz={quizz} />
            </TabPanel>
            {/* <TabPanel value={value} index={1} style={{
                height: "300px",
                overflow: "auto"
            }}>
                <HowItWorks quizz={quizz} />
            </TabPanel> */}
            {!quizz.isQuizzithonQuiz &&  <TabPanel value={value} index={1} style={{
                height: "300px",
                overflow: "auto"
            }}>
                <PrizePool quizz={quizz} />
            </TabPanel>}
            {/* <TabPanel value={value} index={3} style={{
                height: "300px",
                overflow: "auto"
            }}>
                {
                    (quizz.status === 'closed' && showQuestions && state?.isPastQuiz === false) ?
                        <KnowledgeBank quizz={quizz} />
                        :
                        <NotFoundDataUi
                            text={`No Date Available`}

                        />
                }
            </TabPanel> */}
            <TabPanel value={value} index={quizz.isQuizzithonQuiz ? 1 : 2} style={{
                height: "300px",
                overflow: "auto"
            }}>
                {
                    //console.log(state?.isPastQuiz)
                }
                {
                    // hideLeaderBoard ?
                    //     <div>
                    //         <h1 className='text-center text-bgblue font-semibold  italic'>Quiz is not ended yet</h1>
                    //     </div>
                    //     :
                    
                <LeaderBoard qid={qid} isPastQuiz = {state?.isPastQuiz } />
                }
            </TabPanel>
            <TabPanel value={value} index={quizz.isQuizzithonQuiz ? 2 : 3} style={{
                height: "300px",
                overflow: "auto"
            }}>
                <SubmittedAnswers quizz={qid} isPastQuiz = {state?.isPastQuiz }/>
            </TabPanel>
        </Box>
    );
}
