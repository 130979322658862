import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { extrasConstancts, globalConstants } from '../../../../../actions/appConstants'
import axios from '../../../../../utils/myAxios'

//
import { Avatar } from '@mui/material'
import first from '../../../../../assets/prizez/1.svg'
import second from '../../../../../assets/prizez/2.png'
import third from '../../../../../assets/prizez/3.png'
const positions = [
    first,
    second,
    third,
]
//
// import TableModal from './TableModal'
const TableRow = ({ val, questions, setquestions, isSchoolTypeQuizz, index }) => {
    const dispatch = useDispatch()
    const [time, settime] = useState({
        minutes: 0,
        seconds: 0
    })
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleApproveAction = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            //
            const res = await axios.post(`/makepayouts/usingfundid/${val._id}`)
            const newQuestions = questions.filter(v => v._id !== val._id)
            setquestions(newQuestions)
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "Payout Request Approved Successfully!" }
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    //calucate the timetaken into minutes and seconds...
    useEffect(() => {
        if (val) {
            const minutes = Math.floor(val?.timeTaken / 60);
            const seconds = val.timeTaken - minutes * 60;
            settime({
                minutes: minutes,
                seconds: seconds,
            })
        }
    }, [val])
    return (
        <>

            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6  whitespace-nowrap">
                    <div className='flex items-center space-x-3 cursor-pointer' onClick={() => {
                        if (isSchoolTypeQuizz)
                            dispatch(
                                {
                                    type: extrasConstancts.SHOW_SUBMISSION_MODAL,
                                    payload: {
                                        open: true,
                                        user: val?.user
                                    }
                                })
                    }}>
                        <Avatar
                            src={val.user.profileImage}
                        />
                        <span className='font-semibold'>
                            {
                                isSchoolTypeQuizz ?
                                    `${val?.firstname} ${val?.middlename} ${val?.lastname}`
                                    :
                                    val?.user?.userName
                            }
                        </span>
                    </div>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap flex justify-center">
                    {index < 4 ? <div className='w-12 h-12 relative'>
                        <span className='font-bold text-[14px] text-[#DF6D00] absolute left-[50%] top-[45%] -translate-x-[50%] -translate-y-[50%]'
                        >
                            {index}
                        </span>
                        <img src={first} className='w-full h-full object-contain' />
                    </div> :
                        <span className='w-12 h-12 font-bold text-[14px]'>{index}</span>
                    }
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span className='font-semibold'>
                        {
                            val?.numberOfCorrectQuestions
                        }
                    </span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span className='font-semibold text-blue-600'>
                        {
                            `${time.minutes}m:${time.seconds}s`
                        }
                    </span>
                </td>
            </tr>
        </>
    )
}

export default TableRow