import React from 'react'
import TableRow from './TableRow'

const MyTable = ({ questions, setquestions , setReset }) => {
    return (
        <div class="overflow-x-auto">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                <div class="w-full  overflow-auto">
                    <div class="bg-white shadow-md rounded p-3">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <th class="py-3 px-6 text-left">Title</th>
                                    <th class="py-3 px-6 text-center">No of Qzetos</th>
                                    <th class="py-3 px-6 text-center">Number of Quizes</th>
                                    <th class="py-3 px-6 text-center">Quizmaster Quizes</th>
                                    <th class="py-3 px-6 text-center">Quizzithons</th>
                                    <th class="py-3 px-6 text-center">AI Questions</th>
                                    <th class="py-3 px-6 text-left">Price</th>
                                    <th class="py-3 px-6 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    questions.length > 0 &&
                                    questions.map((val, index) => (
                                        <TableRow questions={questions} setquestions={setquestions} val={val} key={index} setReset = {setReset}/>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTable