import { IconButton } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const Boxes = ({ val, index, addNewInput, fieldsOnChangeHandler, deleteBox, deleteField }) => {
    const handleAddNewRow = () => {
        addNewInput(index)
    }
    return (
        <div className='relative border border-gray-400 rounded-2xl py-2 px-2'>
            <IconButton onClick={() => deleteBox(index)} className='!absolute  !right-0 !top-0 '>
                <CloseIcon fontSize='small' className='hover:!text-red-500 ' />
            </IconButton>
            <h1 className='text-center font-semibold text-bgblue py-2 mt-1'>{val.name}</h1>
            <div className='space-y-2'>
                {
                    val.values.map((_v, _i) => (
                        <div className='flex items-center'>
                            <IconButton onClick={() => deleteField(val.name, _i)}>
                                <CloseOutlinedIcon />
                            </IconButton>
                            <input name={val.name} onChange={(e) => fieldsOnChangeHandler(e, _i)} value={_v} placeholder='fill the input...' type={'text'} className='border border-gray-300 w-full outline-none p-1' />
                        </div>
                    ))
                }
            </div>
            <div className='flex items-center justify-center p-1'>
                <IconButton className='!bg-bgblue' onClick={handleAddNewRow}>
                    <AddIcon className=' text-white' />
                </IconButton>
            </div>
        </div>
    )
}

export default Boxes