import { IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { HiTemplate } from "react-icons/hi";
import { questionsCategoryConstants } from "../../actions/appConstants";
import { getAllQuestionCategories } from "../../actions/question.actions";
//img-icons...
const catIcons = {
  [questionsCategoryConstants.MULTIPLE_QUESTIONS]: require("./../../assets/categoriesimg/multiple.png"),
  [questionsCategoryConstants.FILL_IN_THE_BLANKS]: require("./../../assets/categoriesimg/fill.png"),
  [questionsCategoryConstants.CLOSED_ENDED]: require("./../../assets/categoriesimg/closed.png"),
  [questionsCategoryConstants.CROSS_WORDS]: require("./../../assets/categoriesimg/cross.png"),
  [questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS]: require("./../../assets/categoriesimg/drag-and-drop.png"),
  [questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING]: require("./../../assets/categoriesimg/drag.png"),
  [questionsCategoryConstants.ELIMINATOR_QIZ]: require("./../../assets/categoriesimg/kick-off.png"),
  [questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE]: require("./../../assets/categoriesimg/sharing.png"),
  [questionsCategoryConstants.MATCH_THE_PAIR]: require("./../../assets/categoriesimg/columns.png"),
  [questionsCategoryConstants.MIX_TYPE_QUIZ]: require("./../../assets/categoriesimg/experiment.png"),
  [questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ]: require("./../../assets/categoriesimg/indent.png"),
  [questionsCategoryConstants.SENTENCE_SEQUENCE]: require("./../../assets/categoriesimg/word.png"),
  [questionsCategoryConstants.SEQUENCING_QUIZ]: require("./../../assets/categoriesimg/sequence.png"),
  [questionsCategoryConstants.SORTING_CLASSIFICATION]: require("./../../assets/categoriesimg/waste.png"),
  [questionsCategoryConstants.SPIN_A_WHEEL_QUIZ]: require("./../../assets/categoriesimg/spin.png"),
  [questionsCategoryConstants.TIC_TAC_TOE]: require("./../../assets/categoriesimg/tic-tac-toe.png"),
  [questionsCategoryConstants.TRUE_FALSE]: require("./../../assets/categoriesimg/true-or-false.png"),
};
const StaticCategoriesC = ({
  isPastQuizzes = false,
  isSchoolQuizzes = false,
  fid = "",
  isUser = false,
}) => {
  const location = useLocation();

  const [link, setLink] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  // console.log(fid)
  const linkGenerate = (cid) => {
    if (location.pathname.includes("following/profile")) {
      return `/categories/subjects/${cid}/${id}`;
    } else if (fid === "following") {
      return `/categories/subjects/${cid}/all`;
    } else {
      return `/categories/subjects/${cid}`;
    }
    return "/";
  };

  const navigate = useNavigate();
  const { question } = useSelector((s) => s);
  useEffect(() => {
    dispatch(getAllQuestionCategories());
    console.log(question);
  }, []);
  return (
    <>
      <div className="p-3 mt-5">
        <h1 className="capitalize text-bgblue text-lg">Quiz Categories</h1>
      </div>
      <div className="p-0 grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
        {question.questionsCategories.map((val, index) => (
          <div
            onClick={() =>
              isPastQuizzes
                ? navigate(`/past/quizzes/categories/subjects/${val._id}`)
                : isSchoolQuizzes
                ? navigate(
                    `/school/quizzes/categories/subjects/${val._id}/${
                      isUser ? "yes" : ""
                    }`
                  )
                : navigate(linkGenerate(val._id))
            }
            className="bg-white border rounded-xl p-4 cursor-pointer space-y-3"
          >
            <div className="flex items-center justify-center">
              {catIcons[val.name] ? (
                <img src={catIcons[val.name]} className="w-10 object-contain" />
              ) : (
                <IconButton>
                  <HiTemplate />
                </IconButton>
              )}
            </div>
            <h1 key={index} className="text-xs font-semibold text-center">
              {val.name.replace(/_/g, " ")}
            </h1>
          </div>
        ))}
      </div>
    </>
  );
};

export default StaticCategoriesC;
