import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import { TrainRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import NotFoundDataUi from '../../../../uiComponents/NotFoundDataUi'
const PaymentHistoryTable = ({ qid , isPastQuiz }) => {
    const [isSchoolTypeQuizz, setisSchoolTypeQuizz] = useState()
    const { auth } = useSelector(s => s)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const [registered , setRegistered] = useState([])
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setloading(TrainRounded)
                const res = await axios.get(`/gammer/quizz/leaderboarddata/${qid}/${isPastQuiz}?page=${page}&limit=${limit}`)
                //console.log('data==>', res.data)
                if (res.status === 200) {
                    setisSchoolTypeQuizz(res.data.isSchoolTypeQuizz)
                    setquestions(res.data.data)
                    
                    setRegistered(res.data.data[0].quizzId.registerdUsers)
                    //console.log(res.data.data[0].quizzId.registerdUsers.user)
                    // setquestions(questions.filter((item)=> item.user._id !== registered.forEach((item2)=> item2)))
                    //console.log('leader board data===>', res.data.data)
                    //console.log('leader board totallength===>', res.data.totalLength)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))

                    settotalLength(l)
                }
                setloading(false)
            } catch (error) {
                setloading(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        if (qid) {
            getData()
        }
    }, [page, limit])
    return (
        <div>
            {
                loading ?
                    <div className='flex items-center justify-center w-full'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?
                        <>
                            <MyTable questions={questions} setquestions={setquestions} registered = {registered} isPastQuiz = {isPastQuiz} isSchoolTypeQuizz={isSchoolTypeQuizz} />
                            <div className='flex justify-center px-5 pt-5'>
                                <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                            </div>
                        </>
                        :
                        <NotFoundDataUi
                            text={`No Players To Show`}
                        />
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest capitalize'>No Players To Show...</div>
            }

        </div>
    )
}

export default PaymentHistoryTable;