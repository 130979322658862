import React, { useEffect, useState } from "react";
import {
  globalConstants,
  questionsCategoryConstants,
  lifeLinesConstants,
} from "./../../actions/appConstants";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import PrizeImg from "../../assets/prizeimg.png";
import Peopleimg from "../../assets/peopleimg.png";
import TimerAndStatement from "./timerandstatement/TimerAndStatement";
import MultipleQuestions from "./multipleQuestions/MultipleQuestions";
import FillInTheBlanks from "./fillInTheBlanks/FillInTheBlanks";
import TrueFalse from "./trueFalse/TrueFalse";
import SpinWheal from "./spinWheal/SpinWheal";
import MatchThePair from "./matchThePair/MatchThePair";
import TypeContainer from "./typeContainer/TypeContainer";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../utils/myAxios";
import { findAllByDisplayValue } from "@testing-library/react";
import CircularProgress from "@mui/material/CircularProgress";
import MyLoader from "./timerandstatement/MyLoader";
import PlayedQuizzScreen from "./playedQuizzScreen/PlayedQuizzScreen";
import TicTacToe from "./ticTacToe/TicTacToe";
//
import LifeLinesC from "./lifeLinesComponent/LifeLinesC";
//
import { logoutAction } from "../../actions/auth.actions";
const PlayQuizComponents = ({
  user,
  isPastQuiz = false,
  isQuizzithonQuiz = false,
  quizzithonId,
}) => {
  const [timerTimeIsFinished, settimerTimeIsFinished] = useState(false);
  const [isAlreadyRegistered, setisAlreadyRegistered] = useState(false);
  const [calculateTimeTaken, setcalculateTimeTaken] = useState({
    seconds: 0,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, lifeLines } = useSelector((s) => s);
  const { qid } = useParams();
  const dispatch = useDispatch();
  const [loading, setloading] = useState(findAllByDisplayValue);
  const [questionsArray, setquestionsArray] = useState([]);
  const [simpleQuizz, setsimpleQuizz] = useState(null);
  const [questionIndex, setquestionIndex] = useState(0);
  //
  const [forSpin, setforSpin] = useState(null);
  const [selectedSubject, setselectedSubject] = useState(null);
  //
  //
  const [ticTacToeQuestions, setticTacToeQuestions] = useState([]);
  //gammer
  const [gammerData, setgammerData] = useState(null);
  //gammer
  //tictactoe
  const [tictactoeAnswersData, settictactoeAnswersData] = useState({
    user: user,
    correctQuestions: [],
    incorrectQuestions: [],
    totalQuestions: 0,
    numberOfCorrectQuestions: 0,
    linesCompleted: 0,
    completedRows: [],
  });
  const [finishedTicTacToe, setfinishedTicTacToe] = useState(false);
  const handleNextQuestion = async (obj) => {
    await localStorage.removeItem("guesses");
    //make lifelines back to false which user selected
    dispatch({
      type: lifeLinesConstants.MAKE_TRUE_TO_FALSE,
    });
    //make lifelines back to false which user selected
    //
    if (simpleQuizz.category.name === questionsCategoryConstants.TIC_TAC_TOE) {
      const totalPlayedQuestions =
        tictactoeAnswersData.correctQuestions.length +
        tictactoeAnswersData.incorrectQuestions.length;
      if (obj.correct) {
        settictactoeAnswersData({
          ...tictactoeAnswersData,
          correctQuestions: [...tictactoeAnswersData.correctQuestions, obj],
          numberOfCorrectQuestions: [
            ...tictactoeAnswersData.correctQuestions,
            obj,
          ].length,
        });
      } else {
        settictactoeAnswersData({
          ...tictactoeAnswersData,
          incorrectQuestions: [...tictactoeAnswersData.incorrectQuestions, obj],
        });
      }
      setquestionsArray([]);
      if (totalPlayedQuestions === ticTacToeQuestions.length - 1) {
        setfinishedTicTacToe(true);
      }
    } else {
      if (questionIndex < questionsArray.length - 1) {
        try {
          const _obj = {
            ...gammerData.gammer,
            subject: selectedSubject,
            lastIndex: questionIndex + 1,
            isPastQuiz: isPastQuiz,
          };
          if (obj.correct) {
            _obj.correctQuestions = [..._obj.correctQuestions, obj];
          } else {
            _obj.incorrectQuestions = [..._obj.incorrectQuestions, obj];
          }
          _obj.numberOfCorrectQuestions = _obj.correctQuestions.length;
          _obj.totalQuestions = questionsArray.length;
          _obj.timeTaken = calculateTimeTaken.seconds;
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: true },
          });
          const gammerResUpdates = await axios.post("/gammer/update", {
            ..._obj,
          });
          setgammerData({
            ...gammerData,
            gammer: gammerResUpdates.data.gammer,
          });
          //tictactoe...
          if (ticTacToeQuestions.length > 0) {
            setquestionsArray([]);
          } else {
            setquestionIndex(questionIndex + 1);
          }
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: false },
          });
        } catch (error) {
          dispatch({
            type: globalConstants.ALERT,
            payload: { error: error.response.data.msg },
          });
        }
      } else {
        //perform the quizz end functions
        const _obj = {
          ...gammerData.gammer,
          played: true,
          subject: selectedSubject,
          isPastQuiz: isPastQuiz,
        };
        if (obj.correct) {
          _obj.correctQuestions = [..._obj.correctQuestions, obj];
        } else {
          _obj.incorrectQuestions = [..._obj.incorrectQuestions, obj];
        }
        _obj.numberOfCorrectQuestions = _obj.correctQuestions.length;
        _obj.totalQuestions = questionsArray.length;
        _obj.timeTaken = calculateTimeTaken.seconds;
        try {
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: true },
          });
          const getUpdated = await axios.post("/gammer/update", {
            ..._obj,
          });
          setgammerData({
            ...gammerData,
            gammer: getUpdated.data.gammer,
          });
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: false },
          });
        } catch (error) {
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: false },
          });
        }
      }
    }

    //end the quiz if quiz category is "Eliminator Quiz" or "Spin Wheal" And The Question Answer is Wrong
    if (
      (simpleQuizz.category.name ===
        questionsCategoryConstants.ELIMINATOR_QIZ ||
        simpleQuizz.category.name ===
          questionsCategoryConstants.SPIN_A_WHEEL_QUIZ) &&
      obj.correct === false
    ) {
      settimerTimeIsFinished(true);
    }
    //end the quiz if quiz category is "Eliminator Quiz" or "Spin Wheal" And The Question Answer is Wrong
  };

  const handleLeaveQuestion = () => {};
  const handleSelectedSpinQuestions = (spinQuestions) => {
    setquestionsArray(spinQuestions);
    setforSpin(null);
  };
  useEffect(async () => {
    if (qid && auth.data.user.restricted === false) {
      //remove cross word guesses
      await localStorage.removeItem("guesses");
      //remove cross word guesses
      //for lifelines
      dispatch({
        type: lifeLinesConstants.MAKE_TRUE_TO_FALSE,
      });
      //for lifelines
      try {
        setloading(true);
        const _obj = {
          user: user,
          quizzId: qid,
          isPastQuiz: isPastQuiz,
        };
        const _gammerRes = await axios.post("/gammer/create", {
          ..._obj,
        });
        if (_gammerRes.data?.old === true) {
          setisAlreadyRegistered(true);
        }
        setcalculateTimeTaken({
          seconds: _gammerRes.data.gammer.timeTaken
            ? _gammerRes.data.gammer.timeTaken
            : 0,
        });
        setgammerData({
          ..._gammerRes?.data,
          gammer: {
            ..._gammerRes?.data?.gammer,
            played: isPastQuiz ? false : _gammerRes.data?.gammer?.played,
          },
        });
        setselectedSubject(_gammerRes.data.gammer.subject);
        if (isPastQuiz) {
          const pastObj = {
            user: user,
            quizzId: qid,
            played: false,
            correctQuestions: [],
            incorrectQuestions: [],
            lastIndex: 0,
            numberOfCorrectQuestions: 0,
            isPastQuiz: true,
          };
          _obj.timeTaken = calculateTimeTaken.seconds;
          const getUpdated = await axios.post("/gammer/update", {
            ...pastObj,
          });
          setgammerData({
            ...gammerData,
            gammer: getUpdated.data.gammer,
          });
        } else {
          setquestionIndex(_gammerRes.data.gammer.lastIndex);
        }
        if (!_gammerRes.data.old) {
          const res = await axios.get(`/get/singlequizz/byid/${qid}`);
          if (res.status === 200) {
            if (
              res.data.quizz.category.name ===
              questionsCategoryConstants.SPIN_A_WHEEL_QUIZ
            ) {
              if (_gammerRes.data.gammer.subject) {
                let stop = false;
                for (
                  let x = 0;
                  x < res.data.quizz.spinWheal.length && stop === false;
                  x++
                ) {
                  if (
                    res.data.quizz.spinWheal[x].subject ===
                    _gammerRes.data.gammer.subject
                  ) {
                    handleSelectedSpinQuestions(
                      res.data.quizz.spinWheal[x].data
                    );
                    stop = true;
                  }
                }
              } else {
                setforSpin(res.data.quizz.spinWheal);
              }
              setsimpleQuizz(res.data.quizz);
            } else if (
              res.data.quizz.category.name ===
              questionsCategoryConstants.MIX_TYPE_QUIZ
            ) {
              setsimpleQuizz(res.data.quizz);
              setquestionsArray(res.data.quizz.forMixTypeQuestions);
            } else if (
              res.data.quizz.category.name ===
              questionsCategoryConstants.ELIMINATOR_QIZ
            ) {
              // seteliminatorLifeLines(res.data.quizz.eliminatedQuiz.lifelines)
              dispatch({
                type: lifeLinesConstants.ADD_LIFE_LINES,
                payload: _gammerRes?.data?.gammer?.lifelines,
              });
              setsimpleQuizz(res.data.quizz);
              setquestionsArray(res.data.quizz.eliminatedQuiz.questions);
            } else if (
              res.data.quizz.category.name ===
              questionsCategoryConstants.TIC_TAC_TOE
            ) {
              setsimpleQuizz(res.data.quizz);
              setticTacToeQuestions(res.data.quizz.forMixTypeQuestions);
            } else {
              setsimpleQuizz(res.data.quizz);
              setquestionsArray(res.data.quizz.questions);
            }
          }
        }
        else{
          dispatch({
            type : globalConstants.ALERT, 
            payload : {error : "Quiz has already been played or been played in another Browser/Tab"}
          })
            await dispatch(logoutAction());
            navigate('/dashboard')
        }
        setloading(false);
      } catch (error) {
        setloading(false);
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
    else if (qid && auth?.data?.user?.restricted === true ){
      dispatch({
        type : globalConstants.ALERT, 
        payload : {error : "Quiz has already been played or been played in another Browser/Tab"}
      })
      await dispatch(logoutAction())
      navigate('/dashboard')
    }
  }, [qid]);
  const handleOnTimesUpAction = async (timeTaken) => {};
  const handleTicTacToeQuestionsIndexes = async (indexdata, arrBoardData) => {
    await localStorage.removeItem("guesses");
    setquestionIndex(indexdata);
    setquestionsArray(ticTacToeQuestions);
  };
  useEffect(async () => {
    if (finishedTicTacToe) {
      try {
        //validating number of correct lines...
        let correctLines = 0;
        let totalLines = 0;
        tictactoeAnswersData.completedRows.map((val, index) => {
          let lined = true;
          totalLines += 1;
          val.map((v, i) => {
            const _toFindIndex = 3 * v.bigIndex + v.smallIndex;
            const getIndexedQuestion = ticTacToeQuestions[_toFindIndex];
            if (getIndexedQuestion) {
              const findInCorrect = tictactoeAnswersData.correctQuestions.find(
                (vv) => vv._id === getIndexedQuestion._id
              );
              if (!findInCorrect) {
                lined = false;
              }
            } else {
              lined = false;
            }
          });
          if (lined) {
            correctLines += 1;
          }
        });
        //
        const _obj = {
          ...gammerData.gammer,
          ...tictactoeAnswersData,
          played: true,
          numberOfCorrectQuestions: correctLines,
          totalQuestions: totalLines,
          isPastQuiz: isPastQuiz,
        };
        _obj.timeTaken = calculateTimeTaken.seconds;
        dispatch({
          type: globalConstants.ALERT,
          payload: { loading: true },
        });
        const getUpdated = await axios.post("/gammer/update", {
          ..._obj,
        });

        setgammerData({
          ...gammerData,
          gammer: getUpdated.data.gammer,
        });
        dispatch({
          type: globalConstants.ALERT,
          payload: { loading: false },
        });
      } catch (error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    }
  }, [finishedTicTacToe]);
  useEffect(async () => {
    const handleRealTimesUp = async () => {
      const _obj = {
        ...gammerData.gammer,
        played: true,
        subject: selectedSubject,
        timeTaken: calculateTimeTaken.seconds,
        isPastQuiz: isPastQuiz,
      };
      _obj.numberOfCorrectQuestions = _obj.correctQuestions.length;
      _obj.totalQuestions = questionsArray.length;
      _obj.timeTaken = Number(calculateTimeTaken.seconds);
      // console.log('before ending timetaken===>', calculateTimeTaken)
      // console.log('befor endin the timesup===>', _obj)
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: true },
      });
      const getUpdated = await axios.post("/gammer/update", {
        ..._obj,
      });
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: false },
      });
      setgammerData({
        ...gammerData,
        gammer: getUpdated.data.gammer,
      });
    };
    if (timerTimeIsFinished) {
      handleRealTimesUp();
    }
  }, [timerTimeIsFinished]);
  return (
    <>
      {gammerData?.gammer?.played && isPastQuiz === false ? (
        isQuizzithonQuiz ? (
          <Navigate to={`/quizzithon/${quizzithonId}`} />
        ) : (
          <PlayedQuizzScreen
            quizzId={gammerData?.gammer?.quizzId}
            user={user}
            isPastQuiz={isPastQuiz}
          />
        )
      ) : (
        gammerData?.gammer?.played &&
        isPastQuiz === true && (
          <Navigate to={`/distribute/pastquizzprizzes/${qid}`} />
        )
      )}
      {gammerData?.gammer?.played === false && (
        <Cont>
          {loading ? (
            <div className="flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : forSpin ? (
            <SpinWheal
              setselectedSubject={setselectedSubject}
              forSpin={forSpin}
              handleSelectedSpinQuestions={handleSelectedSpinQuestions}
            />
          ) : (
            <div className="bg-white rounded-xl shadow-xl select-none">
              <Top>
                <h1 className="text-white">
                  {simpleQuizz?.title ? simpleQuizz.title : ""}
                </h1>
                <div className="flex items-center space-x-2">
                  {/* <div className='flex items-center bg-white rounded-2xl py-1 px-2 space-x-2'>
                                                <img className='w-5 h-5' src={PrizeImg} />
                                                <h1 className='text-sm'>{`Prize : ${simpleQuizz?.type === 'free' ? simpleQuizz.requiredPointsForFree : simpleQuizz.price}`}</h1>
                                            </div> */}
                  <div className="flex items-center bg-white rounded-2xl py-1 px-2 space-x-2">
                    <img className="w-5 h-5" src={Peopleimg} />
                    <h1 className="text-sm">{`Participents :${simpleQuizz?.registerdUsers?.length}`}</h1>
                  </div>
                </div>
              </Top>
              {ticTacToeQuestions.length > 0 &&
                gammerData.gammer.played === false && (
                  <TicTacToe
                    tictactoeAnswersData={tictactoeAnswersData}
                    settictactoeAnswersData={settictactoeAnswersData}
                    questionsArray={questionsArray}
                    handleTicTacToeQuestionsIndexes={
                      handleTicTacToeQuestionsIndexes
                    }
                    questions={ticTacToeQuestions}
                  />
                )}
              {simpleQuizz.category.name ===
                questionsCategoryConstants.ELIMINATOR_QIZ &&
                lifeLines.show && <LifeLinesC qid={simpleQuizz._id} />}
              {simpleQuizz && questionsArray.length > 0 && (
                <Bottom>
                  {/* <MyLoader simpleQuizz={simpleQuizz} /> */}
                  <TimerAndStatement
                    settimerTimeIsFinished={settimerTimeIsFinished}
                    calculateTimeTaken={calculateTimeTaken}
                    setcalculateTimeTaken={setcalculateTimeTaken}
                    isPastQuiz={isPastQuiz}
                    handleOnTimesUpAction={handleOnTimesUpAction}
                    questionsArray={questionsArray}
                    questionIndex={questionIndex}
                    simpleQuizz={simpleQuizz}
                  />
                  <div className="flex items-center justify-center">
                    <span>{`${questionIndex + 1} of ${
                      questionsArray.length
                    }`}</span>
                  </div>
                  {questionsArray.length > 0 && (
                    <TypeContainer
                      played={"playquizz"}
                      questionIndex={questionIndex}
                      simpleQuizz={simpleQuizz}
                      questionsArray={questionsArray}
                      handleLeaveQuestion={handleLeaveQuestion}
                      handleNextQuestion={handleNextQuestion}
                    />
                  )}
                </Bottom>
              )}
            </div>
          )}
        </Cont>
      )}
    </>
  );
};
const Bottom = styled.div`
  ${tw``}
`;

const Top = styled.div`
  ${tw`bg-bgblue flex items-center justify-between p-3 rounded-md`}
`;
const Cont = styled.div``;
export default PlayQuizComponents;
