import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { deleteQuestion, rejectQuestionAction } from '../../../actions/question.actions';
import { globalConstants } from '../../../actions/appConstants';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function TableModal({ open, setopen, state, val, questions, setquestions }) {
    const [rejectResons, setrejectResons] = React.useState('')
    const dispatch = useDispatch()
    const {
        edit,
        view,
        delete: del
    } = state
    const handleClose = () => {
        setopen(false)
    };
    const handleDelete = () => {
        const updated = questions.filter(_val => _val._id !== val._id)
        setquestions(updated)
        dispatch(deleteQuestion(val))
        setopen(false)
    }
    const handleReject = () => {
        if (!rejectResons.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly wright rejected reasons!" } })
        dispatch(rejectQuestionAction(val._id, rejectResons)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
        setopen(false)
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Question</DialogTitle>
            <div className='p-10 space-y-5'>
                <textarea
                    value={rejectResons}
                    onChange={(e) => setrejectResons(e.target.value)}
                    rows={5}
                    className='w-full outline-none border border-red-500 p-3'
                    placeholder='Write rejected Question Reasons...'
                />
                <button onClick={handleReject} className='py-1 px-3 w-full bg-red-500 text-white'>Reject</button>
            </div>
        </Dialog>
    );
}

export default TableModal;