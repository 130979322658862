import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'
const Heading = ({ text }) => <h1 className='mt-5 font-bold text-xl '>{text}</h1>
const MyLink = ({ text, link }) => <a target={`_blank`} className='text-blue-600 underline ' href={link}>{text}</a>
const MyParagraph = ({ children }) => <p className='text-[16px] leading-8 mt-5 '>{children}</p>


const MyBold = ({ text }) => <span className='text-lg font-extrabold'>{text}</span>

const TermandCondition = () => {
  const p = useParams()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])
  return (
    <>
      <div className='!bg-white'>
        <NavBar />
        <h1 className='text-3xl bg-bgblue text-white p-5 md:pl-14 py-10 md:mt-[4rem]'>Terms and Conditions</h1>
        <div className='md:w-[80%] w-[95%] m-auto mt-5'>
          <Heading text={`General`} />
          <MyParagraph>
            <MyLink text={`www.quizzingbox.com`} link={`http://www.quizzingbox.com`} /> (hereinafter, the “Website”) is owned and operated by Quizlabs Technology Private Limited (“Company”), a company incorporated under the Companies Act, having its registered office at Shriram Greenfield, Tower-C, #C904,  Bommenahalli, Bengaluru -560049, India.
          </MyParagraph>
          <MyParagraph>
            You are advised to read and understand these Terms carefully as moving past the home page, or using any of the services shall be taken to mean that You have read and agreed to all of the policies, which constitute a legally binding agreement between you and the website. These terms are to be read along with the Privacy Policy and any other policies on the website.
          </MyParagraph>
          <MyParagraph>
            This document is an electronic record published in accordance with the provisions of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the Rules and Regulations, Privacy Policy and Terms of Service for access or usage of the website and being generated by a computer system, it does not require any physical or digital signatures.

          </MyParagraph>
          <MyParagraph>
            For the purpose of these Terms of Use, along with any amendments to the same, and wherever the context so requires “You”, “Your” or “User” refer to the person visiting, accessing, browsing through and/or using the Website at any point in time. The term “We”, “Us”, “Our” shall mean and refer to the website and/or the Company, depending on the context.
          </MyParagraph>
          <MyParagraph>
            The headings of each section in this Agreement are only for the purpose of organising the various provisions under this Agreement in an orderly manner. These headings shall not be used by either party to interpret the provisions contained under them in any manner. Further, the headings have no legal or contractual value.
          </MyParagraph>
          <MyParagraph>
            We hold the sole right to modify the Terms of Service without prior permission from You or providing notice to You. The relationship creates on You a duty to periodically check the Terms of Service and stay updated on its requirements. If You continue to use the Website or avail any of its services without registration following such change, this is deemed as consent by You to the so amended policies. Your continued use of the Website is conditioned upon your compliance with the Terms of Service, including but not limited to compliance with the Terms of Service even after alterations, if any.
          </MyParagraph>
          <Heading
            text={`Services Overview`}
          />
          <MyParagraph>
            Quizzingbox.com is an online quiz platform which lets users participate in various quizzes from all sorts of fields and disciplines, learning new things and winning millions of  rewards. Users may take quizzes with people with similar interests and different IQ levels. Quizzingbox.com is not just about taking quizzes, it is about challenging oneself, gathering knowledge every time and get rewarded for this knowledge. Every right hit will let you win  rewards. So, the game never stops, Excitements never end and Rewards never stop coming.
          </MyParagraph>
          <Heading text={`Registration`} />
          <MyParagraph>
            All users who are residing in India and across the globe can register on www.quizzingbox.com and participate in all formats of quiz. Users who visit the website for the first time can sign up for free on the website to start participating in the different formats of the quiz. Below mentioned details will be required to sign up on the site
            <ol className='pl-5' >
              <li className=''>
                <MyBold text={`a)`} /> Username;
              </li>
              <li className=''>
                <MyBold text={`b)`} /> Login Password;
              </li>
              <li className=''>
                <MyBold text={`c)`} /> Email-ID;
              </li>
              <li className=''>
                <MyBold text={`d)`} /> Mobile NO.
              </li>
            </ol>
          </MyParagraph>
          <MyParagraph>
            All the above mentioned fields are mandatory failing which will not allow the users to sign up. Email address entered while signing up for first time need to be verified by the user by clicking the link sent to registered email address. Also, Mobile No. entered by the users will be verified on the later stage henceforth it is very vital for the users to enter the correct mobile number and verifying the email address failing which users will not be allowed to redeem any Qzeto points from their account for  rewards.

          </MyParagraph>
          <MyParagraph>
            You are solely responsible for protecting the confidentiality of your username and password and any activity under the account will be deemed to have been done by you. In the event you provide us with false/inaccurate details or the Company has a reasonable ground to believe that false and inaccurate information has been furnished, we hold the right to permanently suspend your account

          </MyParagraph>
          <Heading text={`Quiz Contests, participation and Prizes:`} />
          <MyParagraph>
            After successful login, every user is provided with their own Qzeto wallet which contains Qzeto points enabling them to participate in all kinds of quiz formats. Users can see their Qzeto points balance on the top section of each page beside the Qzeto logo. Quizzingbox.com maintains three kinds of point system in the Qzeto wallet for users which includes Free Qzeto, Bonus Qzeto and Real Qzeto.
          </MyParagraph>
          <MyParagraph>
            <ul className='pl-5'>
              <li className='!list-disc'>
                <Heading text={`Paid Quizzes-`} />
                This format allow users to participate and schedule all quiz categories of paid quizzes which can be scheduled or played 24/7 on the platform. User can login to their account and navigate to play quiz and earn tab under home page. All paid quizzes require real qzeto as registration fee. Users need to use their real qzeto points balance in their Qzeto wallet as registration fee to participate in any paid quiz formats. Users can win millions of real qzeto throughout the day by playing real quizzes which can be redeemed for  rewards in one click

              </li>
              <li className='!list-disc'>
                <Heading text={`Free Quizzes-`} />
                This format allow users to participate and schedule all quiz categories of free quizzes which can be scheduled or played 24/7 on the platform. User can login to their account and navigate to play quiz and earn tab under home page. All free quizzes are absolutely free to participate. Users need to use their free qzeto points in their Qzeto wallet as registration fee to participate in any free quiz formats. Users can win bonus qzeto after playing free quizzes which can be converted to real qzeto and can be redeemed for  rewards

              </li>
              <li className='!list-disc'>
                <Heading text={`Quizmaster-Pro -`} />
                This format allow subscribed users to schedule all quiz categories on the platform. User can login to their account and navigate to quizmaster-pro tab under home page. In this format user can schedule the quiz to build their own community of students, colleague etc. User can schedule the quiz as per subscription and share the quiz link and passcode to any other user registered or not registered on the platform to participate in the quiz. Quiz taker participate in the quiz with the quiz link and passcode withour registering on the quizzingbox platform

              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Taking the Quiz`} />
          <MyParagraph>
            Once quiz schedule time approach user can start the quiz by clicking start button         on quiz detail page. Question will be displayed automatically on the Screens for all registered users. Each question will be of different quiz categories and subject as per the rules and FAQ mentioned on the quiz detail page. The registered user for the quiz need to answer the entire all the question within stipulated time of the quiz. User who gives maximum correct answers in minimum time will be declared winner. Three positions will be declared winner as 1st, 2nd and 3rd based on number of correct answer given and time taken

          </MyParagraph>
          <Heading text={`Declaring the winner`} />
          <MyParagraph>
            User who gives maximum correct answers in minimum time will be declared winner. Three positions will be paid and declared winner as 1st, 2nd and 3rd based on number of correct answer given and time taken. Once user submit his answers within the allotted time, he/she can immediately check how many correct answer he has given and time taken in the leader board. To declare winner we adopt very transparent mechanism of showing the leader board immediately after quiz ends to all users. This leader board keeps updating on real time. Final winners will be decided automatically by the software based on the ranking done in reference to the number of correct answer given and time taken by the entire registered user for the quiz. The ranking done will be available on the leader board with number of correct answer given and time take by all user after allotted time of . This leader board will also show the correct answers of the all question once the quiz is over making the entire process completely transparent.
            Based on the prize pool, final prize money is decided for all the winning position before the start of quiz and same will be credited to winners account instantly in the form of qzetos which can be redeemed for  rewards wherever applicable.
          </MyParagraph>
          <Heading text={`Tie between 1 or more registered user for the quiz`} />
          <MyParagraph>
            Although there is very rare chance of TIE as the first winning criteria is to give maximum correct answer and second winning criteria are the time taken which is measured in Seconds cum Milliseconds up to three decimal places where first criteria take precedence over second. In case of a TIE i.e. exactly the same number of correct answer given in same fraction amount of time taken by users, the winning amount will be distributed equally based on the ranking.
          </MyParagraph>
          <Heading text={`Procedure for the users to redeem the winning Amount`} />
          <MyParagraph>
            It is mandatory for every contestant to complete the Account Verification Process. The Process constitutes of the following mentioned steps
            Email address entered while signing up for first time need to be verified by the user by clicking the link sent to registered email address. Also, Mobile No. entered by the users will be verified on the later stage henceforth it is very vital for the users to enter the correct mobile number and verifying the email address failing which users will not be allowed to redeem any qzeto points from their account.
            User need to update his PAN NUMBER (Permanent Account Number as registered with Govt. of India) under my accounts tab in profile section. One user account can only be associated with only one PAN card number, one mobile number and one email address
            Navigate to Redeem section of my accounts tab and enter the details required to redeem your real qzeto balance from your Qzeto wallet. You need to enter the below mentioned detail and hit redeem button and you are done
            Your redeem request will be processed in 10 working days after verification of all the details entered by user. The equivalent redeem amount will be credited to user account number provided after successful verification of all details. User will receive a confirmation mail from redeem@quizzingbox.com once quizzingbox.com process the redeem request. Kindly check with your respective banks once you receive and email from quizzingbox.com for your successful redemption. It should take maximum of 3-4 days of time for the bank to reflect the redeemed amount into your bank account after you received the mail from quizzingbox.com

          </MyParagraph>
          <Heading text={`Security`} />
          <MyParagraph>
            Transactions on the Site are secure and protected. Any information entered by the User when transacting on the Website is encrypted to protect the User against unintentional disclosure to third parties. The User’s credit and debit card information is not received, stored by or retained by the Company / Website in any manner. This information is supplied by the User directly to the relevant payment gateway which is authorized to handle the information provided, and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with.
          </MyParagraph>
          <div className='text-red-600'>
            <Heading text={`COMPLIANCE WITH LAWS`} />
            <MyParagraph>
              With regards to the provisions contained in this Terms of Service and as a user of this website, You shall comply with all applicable Central, State and local laws, rules, regulations and ordinances for the time being in force.
            </MyParagraph>
          </div>
          <MyParagraph>
            <ul className='pl-5'>
              <li className='!list-disc'>
                <Heading text={`License and Access`} />
                The Company grants you a limited sub-license to access and make personal use of the Site, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of the Company. Such limited sub- license does not include/permit any resale or commercial use of the Site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the Site or its contents; any downloading or copying of information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools. Any portion of the Site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of the Company. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Website or of the Company and/or its affiliates without the express written consent of the Company. You may not use any meta tags or any other “hidden text” utilizing the Company’s name or trademarks without the express written consent of the Company.  You shall not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks connected to the Site or to any server, computer, network, or to any of the services offered on or through the Site, by hacking, ‘password mining’ or any other illegitimate means.
                You hereby agree and undertake not to host, display, upload, modify, publish, transmit, update or share any information which:
              </li>
              <li className='!list-disc'>
                belongs to another person and to which you do not have any right;

              </li>
              <li className='!list-disc'>
                is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;

              </li>
              <li className='!list-disc'>
                harms minors in any way;

              </li>
              <li className='!list-disc'>
                infringes any patent, trademark, copyright or other proprietary/intellectual property rights;
              </li>
              <li className='!list-disc'>
                violates any law for the time being in force;

              </li>
              <li className='!list-disc'>
                deceives or misleads the addressee about the origin of such messages communicates any information which is grossly offensive or menacing in nature;
              </li>
              <li className='!list-disc'>
                impersonates another person;

              </li>
              <li className='!list-disc'>
                contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
              </li>
              <li className='!list-disc'>
                threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation; or

              </li>
              <li className='!list-disc'>
                is misleading or known to be false in any way.
              </li>

            </ul>
          </MyParagraph>
          <Heading text={`Communications`} />
          <MyParagraph >
            By using this Website, it is deemed that you have consented to receiving calls, autodialed and/or pre-recorded message calls, e-mails, from Us at any time with the use of the telephone number and e-mail address that has been provided by you for the use of this website which are subject to the Privacy Policy. The user agrees to receive promotional communication and newsletters from the Company and its partners.

          </MyParagraph>
          <MyParagraph >
            This includes contacting you through information received through other parties.  The use of this website is also your consent to receive SMS from Us at any time we deem fit.  This consent to be contacted is for purposes that include and are not limited to clarification calls and marketing and promotional calls. The user can opt out from such communication and/or newsletters either by unsubscribing on the Website itself, or by contacting the customer services team and placing a request for unsubscribing by sending an email to support@quizzingbox.com.
          </MyParagraph>
          <MyParagraph >
            You may also be contacted by Service Providers with whom we have entered into a contract in furtherance of our rights, duties and obligations under this document and all other policies followed by Us. Such contact will be made only in pursuance of such objectives, and no other calls will be made.
            The sharing of the information provided by you will be governed by the Privacy Policy and We will not give out such contact information of yours to third parties not connected with the Website.

          </MyParagraph>
          <Heading text={`Payment Terms`} />
          <MyParagraph>
            In respect of any transactions entered into on the Quizzingbox.com, including making a payment to participate in the paid versions of Quiz Contest(s), Users agree to be bound by the below mentioned payment terms. The payment Users make to participate in the Quiz Contest(s) and register for Quizmaster is inclusive of the nominated fee for registering to the paid formats of Quiz Contest(s) and Quiz scheduling fee charged by Quizlabs Technolgy Private Limited. Subject to these Terms and Conditions, all other amounts collected from the User are held in escrow until determination of the Winners and distribution of prizes.
            Quizlabs Technolgy Private Limited provides users with three categories of wallet for the processing and reconciliation of payments in relation to services offered on its website www.quizzingbox.com.

          </MyParagraph>
          <Heading text={`Real Qzeto Wallet`} />
          <MyParagraph>
            The Users winnings in any Paid Quiz Contest will reflect as credits to the users Real Qzeto Wallet. It is clarified that in no instance will Quizlabs Technolgy Private Limited permit the transfer of any amounts in the Users real qzeto wallet to any other category of wallet held by the user with Quizlabs Technolgy Private Limited or any third party wallet, including a bank wallet held by a third party.
            Users shall be required to remit the required amount to Quizlabs Technolgy Private Limited through the designated payment gateway as explained below
            The payment made shall be credited to the Users real qzeto wallets and each time a User enters a quiz contest, the applicable amount towards participation in the contest shall be debited from the Users real qzeto wallet. In debiting amounts from the Users real qzeto wallets towards the registration fee of such user in any paid format of the quiz , Quizlabs Technolgy Private Limited shall first debit the Users real qzeto wallet. In case there is any amount remaining to be paid by the User in relation to such Users participation in any quiz Contest(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of participation fee, the amount in excess shall be transferred to the Users real qzeto Wallet and will be available for use in participation in any paid quiz Contest(s) or for withdrawal in accordance website Terms and Conditions
          </MyParagraph>
          <Heading text={`Bonus Qzeto Wallet`} />
          <MyParagraph>
            The Bonus qzeto Wallet shall contain amounts issued by Quizlabs Technolgy Private Limited to the User for participating and for winning in any free formats of the quiz as per its rules mentioned in the quiz info page. User shall be permitted to convert any available credit in bonus qzeto wallet to real qzeto credit as per than available conversion rule of bonus qzeto into real qzeto.
          </MyParagraph>
          <MyParagraph>
            The usage of any amounts issued and present in the Bonus qzeto Wallet shall be subject to the limitations and restrictions, including without limitation, restrictions as to time within which such amount must be used, as applied by Quizlabs Technolgy Private Limited and notified to the User at the time of issue of such amount.
          </MyParagraph>
          <MyParagraph>
            The issue of any amount to the Bonus qzeto Wallet is subject to the sole discretion of Quizlabs Technolgy Private Limited and cannot be demanded by any User as a matter of right. The issue of any such amount by Quizlabs Technolgy Private Limited on any day shall not entitle the user to demand the issuance of such amount at any subsequent period in time nor create an expectation of recurring issue of such amount by Quizlabs Technolgy Private Limited to such User.
            In case the User terminates his/her account with www.quizzingbox.com or such wallet if terminated by www.quizzingbox.com, all amounts standing to the credit of such User in the  Bonus Wallet shall return to Quizlabs Technolgy Private Limited and the User shall not have any right or interest in such amounts.
            Users agree that once they confirm a transaction on Quizzingbox.com, they shall be bound by and make payment for that transaction. .
          </MyParagraph>
          <Heading text={`Free Qzeto Wallet`} />
          <MyParagraph>
            A User shall not be permitted to withdraw any amounts credited into such User’s free qzeto wallet for any reason whatsoever. All amounts credited into User’s free qzeto wallet must be only utilized for participating in all free formats of the quiz contest only
          </MyParagraph>
          <Heading text={`Purchasing Real Qzetos (Deposit)`} />
          <MyParagraph>
            www.quizzingbox.com offers a wide variety of payment methods using credit cards, debit cards, Net Banking and wide variety of wallet etc. All our payment methods are highly user-friendly, efficient, and completely secure. Once you make a payment through any payment method, real qzetos gets immediately credited to your wallet for instant use for participating in all formats of paid quiz game shows. The value of one real qzeto is one INR.
          </MyParagraph>
          <Heading text={`To ensure the highest security of our customers`} />
          <MyParagraph>
            Payment gateway offered in our website uses Industry standard 100% PCI DSS Compliant & Certified solution. Even there is no need for site to be HTTPS secured or PCI DSS certified as all card info is submitted directly to PCI DSS certified system over HTTPS. To protect all other vital customer data we use https protocol over internet which transfer any kind of data in encrypted form.
          </MyParagraph>
          <Heading text={`You can make a purchase in easy steps as mentioned below`} />
          <MyParagraph>
            Click on Buy Real Qzetos in My account section (after creating your wallet with us). Enter the number of real qzetos which need to be purchased and equivalent transaction amount will be shown which needs to be paid. Select the payment method (Credit Card/Debit Card/Netbanking/Cashwallet) and click on the payment method. You shall automatically return to our site once you complete the transaction. You can immediately see the Real Qzetos updated in your wallet under Qzetos balance. You shall also receive an email on your registered email ID indicating the amount of the transaction and confirming the success of the transaction. Keeping in line with RBI norms, we do not accept International cards on the site.
          </MyParagraph>
          <Heading text={`Redemption Policy (Withdrawal)`} />
          <MyParagraph>
            At www.quizzingbox.com, we have a user friendly redemption policy. All amounts that you choose to redeem will be instantly deducted from your Qzeto wallet. It will no longer be available to participate in any format of the quiz. The minimum amount that can be redeemed is Rs 100. Mobile verification and email verification along with initial KYC verification is mandatory for users requesting redemption. In case you purchase Real Qzetos on our site and wish to withdraw your money back, you can just follow the instructions for redemption mentioned on our site under my wallet section. In case you are making a withdrawal without participating in adequate number of paid quiz contest, quizzingbox.com holds the right to reject that redemption request for further verification of your identity and wallet detail to avoid any act of money laundering. In case of rejection in above scenario depositor has to furnish his complete details as and when requested by quizzingbox.com.
            Such transfer will reflect as a debit to the Users real qzeto Wallet. Quizlabs Technolgy Private Limited shall not charge any processing fee for the online transfer of such amount from the real qzeto Wallet to the Users bank account on record with Quizlabs Technolgy Private Limited. Users are requested to note that they will be required to provide valid photo identification and address proof documents for proof of identity and address wherever required in order for Quizlabs Technolgy Private Limited to process the withdrawal request whenever asked for. The name mentioned on the Users photo identification document should correspond with the name provided by the User at the time of registration on Quizzingbox.com, as well as the name and address existing in the records of the Users bank account as provided to Quizlabs Technolgy Private Limited. In the event that no bank account has been registered by the User with Quizlabs Technolgy Private Limited, or the User has not verified his/her User account with Quizlabs Technolgy Private Limited, to Quizlabs Technolgy Private Limited’s satisfaction and in accordance with Terms and Conditions of the website, Quizlabs Technolgy Private Limited shall provide such User with a notification to the Users email address as on record with Quizlabs Technolgy Private Limited at least 30 days prior to the Auto Transfer Date, and in case the User fails to register a bank account with his/her User Wallet and/or to verify his/her User Wallet by the Auto Transfer Date, Quizlabs Technolgy Private Limited shall be entitled to forfeit any amounts subject to transfer on the Auto Transfer Date. Failure to provide Quizlabs Technolgy Private Limited with a valid bank wallet or valid identification documents (to Quizlabs Technolgy Private Limited’s satisfaction) may result in the forfeiture of any amounts subject to transfer in accordance with this clause.
            The User acknowledges that transactions on Quizzingbox.com may take up to 24 hours to be processed. Any amount paid or transferred into the Users real qzeto Wallet may take up to 24 hours to reflect in the Users real qzeto Wallet balance. Similarly, money debited from the Users Bonus qzeto Wallet, real qzeto Wallet may take up to 24 hours to reflect in the Users real qzeto Wallet balance. Users agree not to raise any complaint or claim against Quizlabs Technolgy Private Limited in respect of any delay, including any lost opportunity to join any quiz Contest due to delay in crediting of transaction amount into any of the Users wallets.

          </MyParagraph>
          <Heading text={`Quiz Cancellation and Refund`} />
          <MyParagraph>
            A transaction, once confirmed, is final and no cancellation is permissible. However, Quizlabs Technolgy Private Limited may, at its sole and absolute discretion, permit a User to cancel a transaction and refund the amount paid If the User sends a written request to Quizlabs Technolgy Private Limited from the registered email Id to cancel such payment; or If the payment is made for registration in the paid format(s) of the quiz Contest(s), the un-registration request must be received maximum by at least 10 minute before the start of the quiz contest time prior to the commencement of the quiz in respect of which the payment is made. User need to just unregister from the quiz from quiz info page and all such refunds in case of un-registration, will be in the form of real qzetos which will be refunded back to user account for further redemption if required by the user. Quizlabs Technolgy Private Limited shall not be liable to refund any amount thereafter.
          </MyParagraph>
          <MyParagraph>
            Quizlabs Technolgy Private Limited reserve the rights for cancelling any formats of quiz in rare scenario if less number of user register for any such quiz contest than decided earlier by Quizlabs Technolgy Private Limited. In all these scenario of quiz cancellation, all the users registered for a particular quiz will be refunded back there registration fee in the form of real qzetos or whichever will be applicable directly to their respective Qzeto’s wallet immediately after the quiz cancellation.
            Quizlabs Technolgy Private Limited may, at its sole and absolute discretion, refund the amount to the User after deducting applicable cancellation charges and taxes. At the time of the transaction, Users may also be required to take note of certain additional terms and conditions and such additional terms and conditions shall also govern the transaction. To the extent that the additional terms and conditions contain any clause that is conflicting with the present terms and conditions, the additional terms and conditions shall prevail.
          </MyParagraph>
          <MyParagraph>
            The following payment options are available on the Application:
            <ul className='pl-5'>
              <li className='!list-disc'>
                Domestic and international credit cards issued by banks and financial institutions that are part of the Visa, Master Card & Amex Card networks;
              </li>
              <li className='!list-disc'>
                Visa & Master Card Debit cards;
              </li>
              <li className='!list-disc'>
                Netbanking/Direct Debit payments from select banks in India. A list of available options will be made available at the time of ‘checkout’.
              </li>

            </ul>
          </MyParagraph>
          <MyParagraph>
            <Heading text={`10. User Obligations`} />
            You are a restricted user of this website.
            <ul className='pl-5'>
              <li className='!list-disc'>
                You are bound not to cut, copy, distribute, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or software obtained from the website. With our prior permission limited use may be allowed. For the removal of doubt, it is clarified that unlimited or wholesale reproduction, copying of the content for commercial or non-commercial purposes and unwarranted modification of data and information within the content of the Website is not permitted.

              </li>
              <li className='!list-disc'>
                You agree not to access (or attempt to access) the Website and/or the materials or Services by any means other than through the interface that is provided by the website. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website. You acknowledge and agree that by accessing or using the Website or Services, You may be exposed to content from other users that You may consider offensive, indecent or otherwise objectionable. We disclaim all liabilities arising in relation to such offensive content on the Website. Further, You may report such offensive content.

              </li>
              <li className='!list-disc'>
                In places where this website allows you to post or upload data/information, You undertake to ensure that such material is not offensive and in accordance with applicable laws. Further, You undertake not to:

              </li>
              <li className='!list-disc'>
                Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;

              </li>
              <li className='!list-disc'>
                Engage in any activity that interferes with or disrupts access to the Website or the Services (or the servers and networks which are connected to the Website);


              </li>
              <li className='!list-disc'>
                Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;
              </li>
              <li className='!list-disc'>
                Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;

              </li>
              <li className='!list-disc'>
                Post any file that infringes the copyright, patent or trademark of other legal entities;


              </li>
              <li className='!list-disc'>
                Post any file that infringes the copyright, patent or trademark of other legal entities;
              </li>
              <li className='!list-disc'>
                Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another's computer;
              </li>
              <li className='!list-disc'>
                Download any file posted by another user that you know, or reasonably should know, cannot be legally distributed in such manner;
              </li>
              <li className='!list-disc'>
                Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to, the Website, or any other customer of the website, including any website Account not owned by You, to its source, or exploit the Website or Service or information made available or offered by or through the Website, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than Your own information, as provided for by the Website;

              </li>
              <li className='!list-disc'>
                Disrupt or interfere with the security of, or otherwise cause harm to, the Website, system resources, accounts, passwords, servers or networks connected to or accessible through the Website or any affiliated or linked sites;
              </li>
              <li className='!list-disc'>
                Collect or store data about other users in connection with the prohibited conduct and activities set forth in this Section;

              </li>
              <li className='!list-disc'>
                Use the Website or any material or Content for any purpose that is unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal activity or other activity which infringes the rights of this website or other third parties;
              </li>
              <li className='!list-disc'>
                Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;

              </li>
              <li className='!list-disc'>
                Violate any applicable laws or regulations for the time being in force within or outside India;

              </li>
              <li className='!list-disc'>
                Violate the Terms of Service including but not limited to any applicable Additional Terms of the Website contained herein or elsewhere;
              </li>
              <li className='!list-disc'>
                Violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;

              </li>
              <li className='!list-disc'>
                Threaten the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or cause incitement to the commission of any cognizable offence or prevent investigation of any offence or is insulting any other nation;
              </li>
              <li className='!list-disc'>
                Publish, post, disseminate information that is false, inaccurate or misleading; violate any applicable laws or regulations for the time being in force in or outside India;
              </li>
              <li className='!list-disc'>
                Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force;
              </li>
              <li className='!list-disc'>
                Create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers.
                You shall not engage in advertising to, or in solicitation of, other Users of the Website to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Website or related to us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Website. It shall be a violation of these Terms of Service to use any information obtained from the Website in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in our sole discretion, believe it necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which a user may send to other Users in any 24-hour period which We deem appropriate in our sole discretion. You understand that We have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Website) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena.
                We have no obligation to monitor the materials posted on the Website. We shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Service. Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. In no event shall We assume or have any responsibility or liability for any Content posted or for any claims, damages or losses resulting from the use of Content and/or appearance of Content on the Website. You hereby represent and warrant that You have all necessary rights in and to all Content which You provide and all information it contains and that such Content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortuous, or otherwise unlawful information.
              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Reviews, Feedback and Submissions`} />
          <MyParagraph>
            All reviews, comments, feedback, suggestions, ideas, and other submissions disclosed, submitted or offered on the Site or otherwise disclosed, submitted or offered in connection with use of the Site (collectively, the Comments) shall be and remain the property of the Company. Such disclosure, submission or offer of any Comments shall constitute an assignment to the Company of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments. Thus, the Company shall exclusively own all such rights, titles and interests in the Comments and shall not be limited in any way in its use, commercial or otherwise.
          </MyParagraph>
          <MyParagraph>
            The Company will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from any Comments, and publish, display and distribute any Comments submitted for any purpose whatsoever without restriction and without compensating the user in any way. The Company is and shall be under no obligation to: (i) maintain any Comments in confidence; or (ii) pay compensation for any Comments; or (iii) respond to any Comments. You agree that any Comments submitted by you on the Site will not violate the Terms of Use or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no Comments submitted by you on the Site will be or contain libelous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mails or any form of ‘spam’. The Company does reserve the right (but assumes no obligation) to monitor, edit and/or remove any Comments submitted on the Site. You hereby grant the Company the right to use names that you submit in connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are, and shall remain, responsible for the content of any Comments you make and you agree to indemnify the Company and its affiliates against all claims, loss and liabilities resulting from any Comments you submit.
            Further, any reliance placed on Comments available on the Site from a third party shall be at your sole risk and expense.
          </MyParagraph>
          <Heading text={`Copyright and Trademark`} />
          <MyParagraph>
            The Company, its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, images, content and other materials which appear on the Site. Access to or use of the Site does not confer and should not be considered as conferring upon anyone any license to the Company or any third party’s intellectual property rights. All rights, including copyright, in and to the Site are owned by or licensed to the Company. Any use of the Site or its contents, including copying or storing it or them in whole or part is prohibited without the permission of the Company.
          </MyParagraph>
          <MyParagraph>
            You may not modify, distribute or re-post anything on the Site for any purpose. The names and logos and all related product and service names, design marks and slogans are the trademarks/service marks of the Company, its affiliates, its partners or its suppliers/service providers. All other marks are the property of their respective owners. No trademark or service mark license is granted in connection with the materials contained on the Site. Access to or use of the Site does not authorize anyone to use any name, logo or mark in any manner. References on the Site to any names, marks, products or services of third parties or hypertext links to third party sites or information are provided solely as a convenience to you and do not in any way constitute or imply the Company’s endorsement, sponsorship or recommendation of the third party, the information, its product or services.
          </MyParagraph>
          <MyParagraph>
            The Company is not responsible for the content of any third party sites and does not make any representations regarding the content or accuracy of material on such sites. If you decide to access a link of any third party websites, you do so entirely at your own risk and expense.
          </MyParagraph>
          <Heading text={`Indemnification and Limitation of Liability`} />
          <MyParagraph>
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THIS WEBSITE/COMPANY INCLUDING BUT NOT LIMITED TO ITS AFFILIATE VENDORS, AGENTS AND EMPLOYEES FROM AND AGAINST ANY AND ALL LOSSES, LIABILITIES, CLAIMS, DAMAGES, DEMANDS, COSTS AND EXPENSES (INCLUDING LEGAL FEES AND DISBURSEMENTS IN CONNECTION THEREWITH AND INTEREST CHARGEABLE THEREON) ASSERTED AGAINST OR INCURRED BY US THAT ARISE OUT OF, RESULT FROM, OR MAY BE PAYABLE BY VIRTUE OF, ANY BREACH OR NON-PERFORMANCE OF ANY REPRESENTATION, WARRANTY, COVENANT OR AGREEMENT MADE OR OBLIGATION TO BE PERFORMED BY YOU PURSUANT TO THESE TERMS OF SERVICE. FURTHER, YOU AGREE TO HOLD US HARMLESS AGAINST ANY CLAIMS MADE BY ANY THIRD PARTY DUE TO, OR ARISING OUT OF, OR IN CONNECTION WITH, YOUR USE OF THE WEBSITE, ANY CLAIM THAT YOUR MATERIAL CAUSED DAMAGE TO A THIRD PARTY, YOUR VIOLATION OF THE TERMS OF SERVICE, OR YOUR VIOLATION OF ANY RIGHTS OF ANOTHER, INCLUDING ANY INTELLECTUAL PROPERTY RIGHTS.
          </MyParagraph>
          <MyParagraph>
            IN NO EVENT SHALL, QZETO, ITS OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS OR SUPPLIERS BE LIABLE TO YOU, THE VENDOR OR ANY THIRD PARTY FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING THOSE RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER OR NOT FORESEEABLE OR WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR BASED ON ANY THEORY OF LIABILITY, INCLUDING BREACH OF CONTRACT OR WARRANTY, NEGLIGENCE OR OTHER TORTIOUS ACTION, OR ANY OTHER CLAIM ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR ACCESS TO THE WEBSITE, SERVICES OR MATERIALS.
          </MyParagraph>
          <MyParagraph>
            THE LIMITATIONS AND EXCLUSIONS IN THIS SECTION APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
          </MyParagraph>
          <Heading text={`Termination`} />
          <MyParagraph>
            This User Agreement is effective unless and until terminated by either you or the Company. You may terminate this User Agreement at any time, provided that you discontinue any further use of the Website. The Company may terminate this User Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Site.
          </MyParagraph>
          <MyParagraph>
            Such termination will be without any liability to the Company. The Company’s right to any Comments and to be indemnified pursuant to the terms hereof, shall survive any termination of this User Agreement.
          </MyParagraph>
          <Heading text={`Disputes and Jurisdiction`} />
          <MyParagraph>
            All disputes involving but not limited to rights conferred, compensation, refunds, and other claims will be resolved through a two-step Alternate Dispute Resolution mechanism.
            <ul className='pl-5'>
              <li className='!list-disc'>
                <Heading text={`Stage 1: Mediation.`} />  In case of a dispute, the matter will first be attempted to be resolved by a sole mediator who is a neutral third party and will be selected at the mutual acceptance of a proposed mediator by both parties. Both parties may raise a name for the sole mediator and in the case both parties accept the proposed name, the said person shall be appointed as sole mediator. In case the parties are not able to reach a consensus within two proposed mediators, the Company reserves the right to decide who the final mediator is. The decision of the mediator is not binding on both parties.
              </li>
              <li className='!list-disc'>
                <Heading text={`Stage 2: Arbitration.`} />  In case that mediation does not yield a result suitable or preferred by any one of the parties, arbitration may follow, the award of which is binding on both parties. The Arbitration Board shall comprise three members – one appointed by each party and the third member to be nominated by the two appointed members by mutual consent. Arbitration shall be held at Bengaluru, Karnataka, India. The proceedings of arbitration shall be in the English language. The arbitrator’s award shall be final and binding on the Parties.
                If the dispute cannot be resolved by this two-step Alternate Dispute Resolution mechanism, it shall be referred to the courts at Karnataka.
              </li>
            </ul>
          </MyParagraph>
          <Heading text={`Privacy`} />
          <MyParagraph>
            We encourage you to read the Privacy Policy and to use the information it contains to make informed decisions regarding Your personal information. Please also note that certain information, statements, data and content (such as but not limited to photographs) which You provide on the Website are likely to reveal Your gender, ethnic origin, nationality, age, and/or other personal information about You. You acknowledge and agree that your submission of such information is voluntary on Your part. Further, You acknowledge, consent and agree that we may access, preserve, and disclose information You provide to Us at any stage during Your use of the Website. Disclosures of information to Third Parties are further addressed in Our Privacy Policy.
          </MyParagraph>
          <Heading text={`Miscellaneous Provisions`} />
          <ul className='pl-5'>
            <li className='!list-disc'>
              <Heading text={`Waiver: `} />  The failure of either party at any time to require performance of any provision of this Agreement in no manner shall affect such party's right at a later time to enforce the same. No waiver by either party of any breach of this Agreement, whether by conduct or otherwise, in any one or more instances, shall be deemed to be, or construed as, a further or continuing waiver of any other such breach, or a waiver of any other breach of this Agreement.
            </li>
            <li className='!list-disc'>
              <Heading text={`Severability:`} /> If any provision of this Agreement shall to any extent be held invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions of this Agreement shall in no way be affected or impaired thereby and each such provision of this Agreement shall be valid and enforceable to the fullest extent permitted by law. In such a case, this Agreement shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the rights and commercial expectations of the parties hereto, as expressed herein.
            </li>
          </ul>
          <Heading text={`Contact Us`} />
          <MyParagraph>
            If you have any questions about this Agreement, the practices of Quizzingbox, or your experience with the Service, you can e-mail us through our contact us at        <MyLink link={`mailto:info@quizzingbox.com`} text={`info@quizzingbox.com`} />
          </MyParagraph>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermandCondition;