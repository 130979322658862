import React, {useEffect} from 'react'
import Layout from '../Layout/Layout'
import ScheduleQuizzithonComponent from './ScheduleQuizzithonComponment'
import { useSelector, useDispatch } from 'react-redux'
import { keepUserLoggedIn } from '../../actions/auth.actions'
function ScheduleQuizzithon() {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth.data)
  
  useEffect(() => {
    dispatch(keepUserLoggedIn())
  }, [])

  return (
    <Layout>
      {
        user?.quizzithon > 0 ? <ScheduleQuizzithonComponent /> :
          <div className='w-full h-screen flex justify-center items-center'>
            <div className='max-w-[400px] text-red-400 text-center'>
              You have 0 quizzithons left, please buy a Qzeto package to continue.
            </div>
          </div>
      }

    </Layout>
  )
}

export default ScheduleQuizzithon