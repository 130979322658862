import React from 'react'
import Hero from '../staticComponents/staticHomeC/Hero'
import AboutQuizBoz from '../staticComponents/staticHomeC/AboutQuizBoz'
import KeyFeatures from '../staticComponents/staticHomeC/KeyFeatures'
import AboutQuiz from '../staticComponents/staticHomeC/AboutQuiz'
import TextbookSelect from '../staticComponents/staticHomeC/TextbookSelect'
import QuizCategory from '../staticComponents/staticHomeC/QuizCategory'
import TestBookPass from '../staticComponents/staticHomeC/TestBookPass'
import WhyTextBook from '../staticComponents/staticHomeC/WhyTextBook'
import StratLearning from '../staticComponents/staticHomeC/StratLearning'
import Footer from '../staticComponents/footer/Footer'
import NavBar from '../staticComponents/navBar/NavBar'
import './../static.css'

const StaticHome = () => {
    return (
        <div className='!bg-white'>
            <NavBar />
            <Hero />
            <AboutQuizBoz />
            <WhyTextBook />
            {/* <KeyFeatures /> */}
            <AboutQuiz />
            <QuizCategory />
            <TextbookSelect />
            
            <TestBookPass />
            
            <StratLearning />
            <Footer />
        </div>
    )
}

export default StaticHome