import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QuizzithonTable from './QuizzithonTable'
import Layout from "../Layout/Layout"
import axios from "../../utils/myAxios"
import { CircularProgress } from '@mui/material'
import { set } from 'lodash'
const UserQuizzithon = () => {
  const { uid } = useParams()
  const [userId, setuserId] = useState('')
  const [loading, setloading] = useState(true)
  const [quizzithon, setquizzithon] = useState([{}])
  useEffect(async () => {
    if (uid)
    {   console.log(uid)
        setloading(true)   
        let quizzithon = await axios.get("get/quizzithon/registered/"+uid)
        
        setquizzithon(quizzithon.data.quizzithons)
        setloading(false)
        setuserId(uid)
    }
        

  }, [uid])
  return (
    <Layout>
        {
            loading ?<div><CircularProgress></CircularProgress></div>
        :<QuizzithonTable quizzithon={quizzithon} />}
    </Layout>
    
  )
}

export default UserQuizzithon