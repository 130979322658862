import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SpinC from './SpinC'
import tw from 'twin.macro'
const SpinWheal = ({ forSpin, handleSelectedSpinQuestions, setselectedSubject }) => {
    const [subjectsArrays, setsubjectsArrays] = useState(null)
    useEffect(() => {
        if (forSpin) {
            //console.log('for spin==>', forSpin)
            let arr = []
            forSpin.map((val) => {
                arr.push(val.subject)
            })
            setsubjectsArrays(arr)
        }
    }, [forSpin])
    const handleSelectedSubject = (selected) => {
        handleSelectedSpinQuestions(forSpin[selected].data)
        //console.log('selected subject===>', forSpin[selected].subject)
        setselectedSubject(forSpin[selected].subject)
    }
    return (
        <Cont>
            {
                subjectsArrays &&
                <div className='flex items-center justify-center select-none'>
                    <SpinC subjectsArrays={subjectsArrays} forSpin={forSpin} handleSelectedSubject={handleSelectedSubject} />
                </div>
            }
        </Cont>
    )
}
const Cont = styled.div`
${tw`bg-white rounded-xl shadow-xl py-2`}
`
export default SpinWheal