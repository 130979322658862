import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from '../../../../utils/myAxios'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../../../actions/appConstants'
const Item = ({ position, qzeto, index }) => {
    return (
        <div className={`flex flex-col items-center justify-center border-none ring-2  ring-opacity-40 p-5 rounded-xl
        ${index % 2 === 0 ? 'ring-mylightyellow' : 'ring-mylightgreen'}
        `}>
            <div className={`w-16 h-16 rounded-full
           ${index % 2 === 0 ? 'bg-mylightyellow' : 'bg-mylightgreen'}
              flex items-center justify-center`}>
                <span className='text-white text-sm'>{position}</span>
            </div>
            <h1 className={`${index % 2 === 0 ? 'text-mylightyellow' : 'text-mylightgreen text-sm'}`}>{qzeto}</h1>
            <h1 className={`${index % 2 === 0 ? 'text-mylightyellow' : 'text-mylightgreen text-sm'}`}>Qzeto</h1>
        </div>
    )
}
const PrizePool = ({ quizz }) => {
    const [data, setdata] = useState([])
    const [count, setCount] = useState(0)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.post(`/get/quizz/prizepol/${quizz._id}`)
                const {
                    firstPerson, secondPerson, thirdPerson
                } = res.data
                let arr = [firstPerson, secondPerson, thirdPerson]
                setdata(arr)
                setloading(false)
            } catch (error) {
                setloading(false)
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
        if (quizz) {
            //console.log(quizz)
            getData()
        }
    }, [quizz])
    //console.log("datadata===>", quizz)
    return (
        <Cont>
            {quizz &&
                loading ?
                <div className='flex items-center justify-center'>
                    <CircularProgress />
                </div>
                :
                <div className='p-5 grid grid-flow-col gap-4'>
                    {
                        quizz.registerdUsers.length > 0 ?
                            data.map((val, index) => {
                                
                                if (quizz.registerdUsers.length >= 3) {
                                    return <Item
                                        position={index + 1}
                                        qzeto={`${(val).toFixed(0)} ${quizz.type}`}
                                        index={index}
                                    />
                                }
                                else if(quizz.registerdUsers.length < 3 &&  index < quizz.registerdUsers.length) {
                                    return <Item
                                        position={index + 1}
                                        qzeto={`${(val).toFixed(0)} ${quizz.type}`}
                                        index={index}
                                    />
                                }
                                
                                

                            }

                            ) : <>No users to display</>
                    }
                </div>
            }
        </Cont>
    )
}
const Cont = styled.div`

`
export default PrizePool