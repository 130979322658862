import React , {useState} from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import QuizzingBoxLogo from "../../../assets/staticdata/QuizzingboxLogo.png"
// import { Divider } from '@mui/material'
import Logo from '../../../assets/logo/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { extrasConstancts } from '../../../actions/appConstants';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const HeaderDrawerResponsive = () => {


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [background, setBackground] = useState('none');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { auth } = useSelector(s => s)
  const dispatch = useDispatch()
  const profile = document.querySelector(".top_banner"); 
  const sectionOptions = {
      rootMargin : "-200px 0px 0px 0px"
  }
  const sectionOneObserver = new IntersectionObserver((enteries, sectionOneObserver)=>{
      enteries.forEach(entry=>{
        if(!entry.isIntersecting)
        {
          setBackground("white")
        }
        else{
          setBackground('white')
        }
      })
  }, sectionOptions)
  profile && sectionOneObserver.observe(profile)
  return (
    <>
      <Box sx={{ display: 'flex'  , background : "none"}}>
        <CssBaseline />
        <AppBar elevation={0} sx={{ color: 'black', background: "none" , position : "static" , }} open={open}>
          <Toolbar sx = {{backgroundColor : background}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon sx={{ color : 'black' }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              <Link to="/" >
                <img
                  className='object-contain w-44'
                  src={Logo} alt=""
                />
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List className='!font-semibold'
            onClick={() => setOpen(false)}
          >
            <Link to='/'>
              <ListItem button>
                <ListItemText primary='HOME' />
              </ListItem>
            </Link>
            <Divider />
            <Link
              to="/static/howitworks"
            >
              <ListItem button>
                <ListItemText primary='HOW IT WORKS' />
              </ListItem>
            </Link>
            <Divider />
            {
              auth.authenticate ?
                <>
                  <Link
                    to={'/dashboard'}
                  >
                    <ListItem button>
                      <ListItemText primary='DASHBOARD' />
                    </ListItem>
                  </Link>
                  <Divider />
                </>
                :
                <>
                  <ListItem button
                    onClick={() => dispatch({
                      type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                      payload: true
                    })}
                  >
                    <ListItemText primary='GET STARTED' />
                  </ListItem>
                  <Divider />
                  <ListItem button
                    onClick={() => dispatch({
                      type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                      payload: true
                    })}
                  >

                    <ListItemText primary='LOGIN' />
                  </ListItem>
                  <Divider />
                </>
            }
            <Link to='/quizmasterpro/quizzes'>
              <ListItem button>
                <ListItemText className='uppercase' primary='Quiz Master Pro' />
              </ListItem>
            </Link>
            <Divider />
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
        </Main>
      </Box>
    </>
  )
}

export default HeaderDrawerResponsive