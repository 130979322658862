import { Avatar } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//icons
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import GamesOutlinedIcon from "@mui/icons-material/GamesOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  getUserStaticTabData,
  keepUserLoggedIn,
} from "../../../actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper";

//icons
const Icons = ({ total, title, icon, color }) => {
  return (
    <div className="flex  items-center   gap-x-2">
      <Avatar
        className={`!text-white `}
        style={{
          background: color ? color : "",
        }}
      >
        {icon}
      </Avatar>
      <div>
        <h1
          className={`text-lg `}
          style={{
            color: color ? color : "",
          }}
        >
          {total}
        </h1>
        <h1 className="text-[11px] whitespace-pre opacity-50">{title}</h1>
      </div>
    </div>
  );
};
// playedQuizzes(pin):3
// scheduledQuizzes(pin):1
// questionsSubmitted(pin):2
// approvedQuestions(pin):1
// rejectedQuestions(pin):1
const StatisticsTab = () => {
  const { staticTab, auth } = useSelector((s) => s);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserStaticTabData(auth?.data?.user?._id));
    dispatch(keepUserLoggedIn());
  }, []);
  console.log("staticTab", staticTab);
  return (
    <>
      <div className="p-3">
        <h1 className="capitalize text-bgblue text-lg">Statistics</h1>
      </div>

      <Container>
        <Swiper
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper w-full h-full pb-10 md:pb-5"
        >
          {/* First slide */}
          <SwiperSlide>
            <div className="">
              <h1 className="capitalize text-bgblue text-lg">Quizzes</h1>
            </div>
            <ImgContainer className="capitalize py-5">
              <Icons
                icon={<StackedLineChartOutlinedIcon />}
                color="#00459E"
                title="Schedule Quizzes"
                total={staticTab.scheduledQuizzes}
              />
              <Icons
                icon={<GamesOutlinedIcon />}
                color="#EEC907"
                title="Played Quizzes"
                total={staticTab.playedQuizzes}
              />
              <Icons
                icon={<FileDownloadOutlinedIcon />}
                color="#42BA96"
                title="Available Quizzes"
                total={auth.data.user.quizzes}
              />
            </ImgContainer>
          </SwiperSlide>
          {/* Second slide */}
          <SwiperSlide>
            <div className="">
              <h1 className="capitalize text-bgblue text-lg">Quizzthon</h1>
            </div>
            <ImgContainer className="capitalize py-5">
              <Icons
                icon={<StackedLineChartOutlinedIcon />}
                color="#00459E"
                title="Schedule Quizzithon"
                total={staticTab.scheduledQuizzithon}
              />
              <Icons
                icon={<GamesOutlinedIcon />}
                color="#EEC907"
                title="Played Quizzithon"
                total={staticTab.playedQuizzithon}
              />
              <Icons
                icon={<FileDownloadOutlinedIcon />}
                color="#42BA96"
                title="Available Quizzithon"
                total={auth.data.user.quizzithon}
              />
            </ImgContainer>
          </SwiperSlide>
          {/* 3rd slide */}
          <SwiperSlide>
            <div className="">
              <h1 className="capitalize text-bgblue text-lg">
                Community Quizzes
              </h1>
            </div>
            <ImgContainer className="capitalize py-5">
              <Icons
                icon={<StackedLineChartOutlinedIcon />}
                color="#00459E"
                title="Schedule Community Quizzes"
                total={staticTab.scheduledProQuizzes}
              />
              <Icons
                icon={<GamesOutlinedIcon />}
                color="#EEC907"
                title="Played Community Quizzes"
                total={staticTab.playedProQuizzes}
              />
              <Icons
                icon={<FileDownloadOutlinedIcon />}
                color="#42BA96"
                title="Available Community Quizzes"
                total={auth.data.user.quizmaster_pro}
              />
            </ImgContainer>
          </SwiperSlide>
          {/* 4th slide */}
          <SwiperSlide>
            <div className="">
              <h1 className="capitalize text-bgblue text-lg">Questions</h1>
            </div>
            <ImgContainer className="capitalize py-5">
              <Icons
                icon={<StackedLineChartOutlinedIcon />}
                color="#00459E"
                title="Submitted Questions"
                total={staticTab.scheduledQuizzithon}
              />
              <Icons
                icon={<GamesOutlinedIcon />}
                color="#EEC907"
                title="Approved Questions"
                total={staticTab.approvedQuestions}
              />
              <Icons
                icon={<FileDownloadOutlinedIcon />}
                color="#42BA96"
                title="AI Questions"
                total={staticTab.aiQuestion}
              />
              <Icons
                icon={<CheckOutlinedIcon />}
                color="#32CD32"
                title="AI Available"
                total={auth.data.user.ai_questions}
              />
            </ImgContainer>
          </SwiperSlide>
        </Swiper>
      </Container>
    </>
  );
};
const ImgContainer = styled.div`
  ${tw`grid lg:grid-cols-3 grid-cols-2 gap-x-5 gap-y-5 justify-center`}
`;
const Container = styled.div`
  ${tw`bg-white shadow-xl p-4 rounded-2xl space-y-5 `}
`;
export default StatisticsTab;
