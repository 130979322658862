import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { globalConstants } from "../../actions/appConstants";
import axios from "../../utils/myAxios";
import { CircularProgress } from "@mui/material";
import { getWalletAction } from "../../actions/wallet.actions";
//css
import "./RealToINRModal.css";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const [available, setAvailable] = useState([]);
  const [selectedReward, setSelectedReward] = useState({});
  const [realWallet, setRealWallet] = useState();
  const { auth, wallet } = useSelector((s) => s);
  console.log("wallet", wallet);
  const { onClose, selectedValue, open, setOpen } = props;
  const [rewardDetails, setRewardDetails] = useState({});
  const [confirm, setConfirm] = useState(false);
  const handleClose = () => {
    onClose(selectedValue);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };
  const onChangeHandler = (e) => {
    const { value, name } = e.target;
    setRewardDetails({
      ...rewardDetails,
      [name]: value,
    });
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (rewardDetails.qzetos > realWallet?.credit) {
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: "You don't have enough balance" },
      });
      return;
    }
    if (rewardDetails.qzetos < rewardDetails.min) {
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: `Min qzetos should be atleast ${rewardDetails.min}` },
      });
      return;
    }
    const body = {
      user: auth.data.user,
      amount: rewardDetails.amount,
      qzetos: rewardDetails.qzetos,
      utid: selectedReward.utid,
      currency: selectedReward.currencyCode,
    };
    setloading(true);
    axios
      .post("/generateReward", body)
      .then((res) => {
        setloading(false);
        setRewardDetails({});
        setSelectedReward({});
        dispatch({
            type: globalConstants.ALERT,
            payload: { success: "Reward Generated successfully, please check your email or redemption section " },
          });
        dispatch(getWalletAction(auth.data.user._id));
        handleClose();
      })
      .catch((err) => {dispatch({
        type: globalConstants.ALERT,
        payload: { error: err.response.data.msg },
      });});
  };
  const getAvailableRewards = async () => {
    try {
      setloading(true);
      const res = await axios.get('/getRewardAvailable')
      console.log('res==>', res.data)
      setAvailable(res.data.rewards)
      // setAvailable([
      //   {
      //     utid: "U068775",
      //     rewardName: "Reward Link Austria",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 5000,
      //     createdDate: "2020-10-29T19:57:18.21Z",
      //     lastUpdateDate: "2023-03-08T17:43:52.882Z",
      //     countries: ["AT"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lesen Sie die Allgemeinen Geschäftsbedingungen </a></p>\n<ul>\n<li>Klicken Sie auf den Einlösungslink oben, um Ihren Reward Link zu aktivieren.</li>\n<li>Als nächstes können Sie Ihr Guthaben für Geschenkgutscheine im Einzelhandel ausgeben.  </li>\n</ul>\n<p>Wenn Sie nicht Ihren gesamten Prämienlink-Wert sofort ausgeben möchten, speichern Sie die E-Mail oder URL und kehren Sie über den Einlöselink zurück.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U040868",
      //     rewardName: "Reward Link Belgium",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 5,
      //     maxValue: 1800,
      //     createdDate: "2020-02-19T22:25:53.309Z",
      //     lastUpdateDate: "2023-03-07T23:57:41.088Z",
      //     countries: ["BE"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lire les conditions générales</a></p>\n<ul>\n<li>Cliquez sur le lien d\'échange ci-dessus pour activer votre lien de récompense.</li>\n<li>Ensuite, vous pourrez dépenser votre solde en cartes-cadeaux.</li>\n</ul>\n<p>Si vous ne souhaitez pas dépenser la totalité de la valeur de votre lien de récompense immédiatement, enregistrez l\'e-mail ou l\'URL et revenez via le lien d\'échange.</p>\n<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lees de algemene voorwaarden</a></p>\n<ul>\n<li>Klik op de inwissellink hierboven om uw Reward Link te activeren.</li>\n<li>Vervolgens kunt u uw saldo besteden aan cadeaubonnen voor de detailhandel.</li>\n</ul>\n<p>Als je niet meteen je volledige waarde van de beloningslink wilt besteden, sla dan de e-mail of URL op en keer terug via de inwissellink.</p>\n<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lesen Sie die Allgemeinen Geschäftsbedingungen </a></p>\n<ul>\n<li>Klicken Sie auf den Einlösungslink oben, um Ihren Reward Link zu aktivieren.</li>\n<li>Als nächstes können Sie Ihr Guthaben für Geschenkgutscheine im Einzelhandel ausgeben.  </li>\n</ul>\n<p>Wenn Sie nicht Ihren gesamten Prämienlink-Wert sofort ausgeben möchten, speichern Sie die E-Mail oder URL und kehren Sie über den Einlöselink zurück.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U813978",
      //     rewardName: "Reward Link Bulgaria",
      //     currencyCode: "BGN",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 14,
      //     maxValue: 3500,
      //     createdDate: "2020-05-06T16:57:54.251Z",
      //     lastUpdateDate: "2021-11-24T21:00:42.333Z",
      //     countries: ["BG"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Прочетете Общи условия</a></p>\n<ul>\n<li>Кликнете върху връзката за обратно изкупуване по-горе, за да активирате връзката си за награда.</li>\n<li>След това ще можете да изразходвате баланса си на карти за подаръци на дребно.</li>\n</ul>\n<p>Ако не искате да изразходвате цялата си стойност на връзката за награда веднага, запазете имейла или URL адреса и се върнете чрез връзката за обратно изкупуване.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U440575",
      //     rewardName: "Reward Link Canada",
      //     currencyCode: "CAD",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.05,
      //     maxValue: 2000,
      //     createdDate: "2017-06-20T00:09:49.22Z",
      //     lastUpdateDate: "2022-08-26T21:13:52.768Z",
      //     countries: ["CA"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       "<p><a href=\"http://www.rewardsgenius.com/reward-link-terms-of-service/\">Lire les conditions générales</a></p>\n<ul>\n<li>Cliquez sur le lien d'échange ci-dessus pour activer votre Reward Link.</li>\n<li>Ensuite, dépensez votre solde de récompenses.</li>\n</ul>\n<p>Si vous ne souhaitez pas dépenser la totalité de la valeur de votre lien de récompense immédiatement, enregistrez l'e-mail ou l'URL et revenez via le lien d'échange.</p>\n<p><a href=\"https://www.rewardsgenius.com/reward-link-terms-of-service/\">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, spend your reward balance.</li>\n</ul>\n<p>IIf you don't want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>",
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U208639",
      //     rewardName: "Reward Link Denmark",
      //     currencyCode: "DKK",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 20,
      //     maxValue: 13500,
      //     createdDate: "2023-02-16T19:13:13.497Z",
      //     lastUpdateDate: "2023-02-16T19:13:13.497Z",
      //     countries: ["DK"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Læs Vilkår og betingelser</a></p>\n<ul>\n<li>Klik på indløsningslinket ovenfor for at aktivere dit Belønningslink.</li>\n<li>Derefter kan du bruge din saldo til gavekort til detailforhandlere.</li>\n</ul>\n<p>Hvis du ikke ønsker at bruge hele din værdi på Belønningslinket med det samme, kan du gemme e-mailen eller webadressen og vende tilbage via indløsningslinket.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U907672",
      //     rewardName: "Reward Link Finland",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 5,
      //     maxValue: 1000,
      //     createdDate: "2020-12-09T21:13:33.623Z",
      //     lastUpdateDate: "2023-03-08T20:03:30.289Z",
      //     countries: ["FI"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lue käyttöehdot</a></p>\n<ul>\n<li>Napsauta yllä olevaa lunastuslinkkiä aktivoidaksesi palkintolinkin.</li>\n<li>Seuraavaksi voit käyttää saldosi vähittäiskaupan lahjakortteihin.</li>\n</ul>\n<p>Jos et halua kuluttaa koko Reward Link -arvoasi heti, tallenna sähköposti tai URL ja palaa lunastuslinkin kautta.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U748343",
      //     rewardName: "Reward Link France",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2018-07-23T23:38:24.73Z",
      //     lastUpdateDate: "2023-08-14T23:01:30.597Z",
      //     countries: ["FR"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       "<p><a href=\"http://www.rewardsgenius.com/reward-link-terms-of-service/\">Lire les conditions générales </a></p>\n<ul>\n<li>Cliquez sur le lien d'échange ci-dessus pour activer votre lien de récompense.</li>\n<li>Ensuite, vous pourrez dépenser votre solde en cartes-cadeaux.</li>\n</ul>\n<p>Si vous ne souhaitez pas dépenser la totalité de la valeur de votre lien de récompense immédiatement, enregistrez l'e-mail ou l'URL et revenez via le lien d'échange.</p>\n<p> </p>\n<p><a href=\"https://www.rewardsgenius.com/reward-link-terms-of-service/\">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don't want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>",
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U486235",
      //     rewardName: "Reward Link Germany",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2018-05-03T21:52:54.565Z",
      //     lastUpdateDate: "2023-08-14T22:57:28.449Z",
      //     countries: ["DE"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lesen Sie die Allgemeinen Geschäftsbedingungen </a></p>\n<ul>\n<li>Klicken Sie auf den Einlösungslink oben, um Ihren Reward Link zu aktivieren.</li>\n<li>Als nächstes können Sie Ihr Guthaben für Geschenkgutscheine im Einzelhandel ausgeben.  </li>\n</ul>\n<p>Wenn Sie nicht Ihren gesamten Prämienlink-Wert sofort ausgeben möchten, speichern Sie die E-Mail oder URL und kehren Sie über den Einlöselink zurück.</p>\n<p> </p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U949317",
      //     rewardName: "Reward Link Hungary",
      //     currencyCode: "HUF",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 1000,
      //     maxValue: 700000,
      //     createdDate: "2023-02-16T20:05:17.707Z",
      //     lastUpdateDate: "2023-08-14T22:47:56.276Z",
      //     countries: ["HU"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Olvassa el az Általános szerződési feltételeket</a></p>\n<ul>\n<li>Kattintson a fenti beváltási linkre a saját Reward Link aktiválásához.</li>\n<li>Ezután egyenlegét kiskereskedelmi ajándékkártyákra költheti el.</li>\n</ul>\n<p>Ha nem szeretné azonnal elkölteni az Reward Link jutalmának teljes ellenértékét, mentse el az e-mailt vagy az URL-címet, és térjen vissza a beváltási hivatkozáson keresztül.</p>\n<p> </p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U667222",
      //     rewardName: "Reward Link Ireland",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2019-10-31T20:00:48.035Z",
      //     lastUpdateDate: "2023-08-14T22:41:22.419Z",
      //     countries: ["IE"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U683043",
      //     rewardName: "Reward Link Italy",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2018-02-05T20:58:42.077Z",
      //     lastUpdateDate: "2023-08-14T22:10:41.181Z",
      //     countries: ["IT"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       "<p><a href=\"http://www.rewardsgenius.com/reward-link-terms-of-service/\">Leggi i Termini e condizioni</a></p>\n<ul>\n<li>Clicca il link di riscossione indicato sopra per attivare il tuo link di ricompensa. </li>\n<li>Successivamente, potrai spendere il tuo saldo su gift card al dettaglio.</li>\n</ul>\n<p>Se non vuoi spendere subito l'intero valore del Reward Link, salva l'e-mail o l'URL e restituiscilo tramite il link di riscatto.</p>\n<p><a href=\"https://www.rewardsgenius.com/reward-link-terms-of-service/\">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don't want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>",
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U974332",
      //     rewardName: "Reward Link Japan",
      //     currencyCode: "JPY",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 3,
      //     maxValue: 250000,
      //     createdDate: "2023-07-26T22:38:59.409Z",
      //     lastUpdateDate: "2023-07-26T23:21:53.848Z",
      //     countries: ["JP"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">利用規約をお読みください</a></p>\n<ul>\n<li>上記の交換リンクをクリックしてReward Linkを有効にしてください。</li>\n<li>次に小売ギフトカードとして残高の金額をお使いいただけます。</li>\n</ul>\n<p>Reward Linkの残高の全額を一度に使用することを希望されない場合、メールもしくはURLを保存し、有効期限が失効する前にReward Linkより提供された交換リンクにアクセスしてください。</p>\n<p>&nbsp;</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.&nbsp;</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U553879",
      //     rewardName: "Reward Link Lithuania",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 5,
      //     maxValue: 2000,
      //     createdDate: "2021-05-14T19:43:53.257Z",
      //     lastUpdateDate: "2023-08-14T19:50:16.785Z",
      //     countries: ["LT"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Perskaitykite sąlygas ir nuostatas</a></p>\n<ul>\n<li>Spustelėkite aukščiau esančią išpirkimo nuorodą, kad suaktyvintumėte premijos nuorodą.</li>\n<li>Tada likutį galėsite išleisti mažmeninės prekybos dovanų kortelėms.</li>\n</ul>\n<p><br />Jei visos „Reward Link“ vertės nenorite išleisti iš karto, išsaugokite el. laišką arba URL ir grįžkite naudodami išpirkimo nuorodą.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link before the expiration date provided with your Reward Link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U704712",
      //     rewardName: "Reward Link Luxembourg",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 1800,
      //     createdDate: "2023-03-09T20:56:03.205Z",
      //     lastUpdateDate: "2023-03-09T20:56:03.205Z",
      //     countries: ["LU"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       "<p><a href=\"https://www.tangocard.com/legal/terms-of-service\">Lire Les Modalités &amp; Conditions</a></p>\n<ul>\n<li>Cliquez sur le lien d'échange ci-dessus pour activer votre Reward Link.</li>\n<li>Vous pourrez ensuite dépenser votre solde en cartes-cadeaux pour le commerce au détail.</li>\n</ul>\n<p>Si vous ne souhaitez pas dépenser la totalité de la valeur de votre Reward Link immédiatement, enregistrez l'e-mail ou l'URL et revenez ultérieurement via le lien d'échange.</p>\n<p><a href=\"https://www.rewardsgenius.com/reward-link-terms-of-service/\">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don't want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>",
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U506378",
      //     rewardName: "Reward Link Mexico",
      //     currencyCode: "MXN",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 50,
      //     maxValue: 35000,
      //     createdDate: "2022-01-13T18:59:28.389Z",
      //     lastUpdateDate: "2022-05-05T17:06:52.425Z",
      //     countries: ["MX"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lea los términos y condiciones</a></p>\n<ul>\n<li>Haga clic en el enlace de reembolso de arriba para activar su enlace de recompensa.</li>\n<li>Luego, gaste su saldo de recompensas.</li>\n</ul>\n<p>Si no desea gastar todo su valor de enlace de recompensa de inmediato, guarde el correo electrónico o la URL y regrese a través del vínculo de canje - su valor estará esperando para ser gastado. Esto le permite almacenar el saldo para canjear en otro momento.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, spend your reward balance.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link before the expiration date provided with your Reward Link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U848696",
      //     rewardName: "Reward Link Netherlands",
      //     currencyCode: "EUR",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2019-10-07T22:09:21.215Z",
      //     lastUpdateDate: "2023-03-08T17:50:50.215Z",
      //     countries: ["NL"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lees de algemene voorwaarden </a></p>\n<ul>\n<li>Klik hierboven op de inwissellink om uw Reward Link te activeren.</li>\n<li>Vervolgens kunt u uw saldo uitgeven aan winkelcadeaubonnen.</li>\n</ul>\n<p>Als je niet meteen je volledige waarde van de beloningslink wilt besteden, sla dan de e-mail of URL op en keer terug via de inwissellink.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U987285",
      //     rewardName: "Reward Link Norway",
      //     currencyCode: "NOK",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 5,
      //     maxValue: 20000,
      //     createdDate: "2023-02-09T21:25:51.49Z",
      //     lastUpdateDate: "2023-08-11T00:30:57.575Z",
      //     countries: ["NO"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Les vilkår og betingelser</a></p>\n<ul>\n<li>Klikk på innløsningslenken ovenfor for å aktivere din Reward Link.</li>\n<li>Deretter vil du kunne bruke saldoen din på detaljhandelsgavekort.</li>\n</ul>\n<p>Hvis du ikke vil bruke hele Reward Link-verdien med en gang, lagre e-posten eller URL-en og returner via innløsningslenken.</p>\n<p> </p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link. </p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U545325",
      //     rewardName: "Reward Link Poland",
      //     currencyCode: "PLN",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 10,
      //     maxValue: 8000,
      //     createdDate: "2020-05-06T18:00:47.763Z",
      //     lastUpdateDate: "2023-08-10T23:56:08.058Z",
      //     countries: ["PL"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Przeczytaj regulamin</a></p>\n<ul>\n<li>Kliknij powyższy link realizacji, aby aktywować link do nagrody.</li>\n<li>Następnie będziesz mógł wydać saldo na karty upominkowe.</li>\n</ul>\n<p>Jeśli nie chcesz od razu wydać całej wartości Reward Link, zapisz e-mail lub adres URL i wróć, korzystając z linku do wykorzystania.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U917209",
      //     rewardName: "Reward Link Romania",
      //     currencyCode: "RON",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 50,
      //     maxValue: 8000,
      //     createdDate: "2020-05-06T18:15:55.721Z",
      //     lastUpdateDate: "2023-08-10T22:10:20.718Z",
      //     countries: ["RO"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Citiți Termenii și condițiile</a></p>\r\n\r\n<ul>\r\n\t<li>Faceți clic pe linkul de răscumpărare de mai sus pentru a activa Link-ul de recompensă.</li>\r\n\t<li>&Icirc;n continuare, veți putea să vă cheltuiți soldul pe carduri cadou cu amănuntul.</li>\r\n</ul>\r\n\r\n<p>Dacă nu doriți să vă cheltuiți &icirc;ntreaga valoare a Reward Link, salvați e-mailul sau adresa URL și returnați prin linkul de răscumpărare &icirc;nainte de data de expirare furnizată &icirc;mpreună cu Link-ul dvs. de recompensă.</p>\r\n\r\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\r\n\r\n<ul>\r\n\t<li>Click the redemption link above to activate your Reward Link.</li>\r\n\t<li>Next, you will be able to spend your balance on retail gift cards.</li>\r\n</ul>\r\n\r\n<p>If you don&#39;t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link before the expiration date provided with your Reward Link.</p>\r\n',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U401938",
      //     rewardName: "Reward Link Serbia",
      //     currencyCode: "RSD",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 500,
      //     maxValue: 100000,
      //     createdDate: "2023-02-17T23:11:15.426Z",
      //     lastUpdateDate: "2023-08-10T21:47:42.286Z",
      //     countries: ["RS"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Прочитајте Услове и одредбе</a></p>\n<ul>\n<li>Кликните линк за искоришћење да бисте активирали свој „Reward Link“.</li>\n<li>Онда ћете моћи да потрошите свој салдо на малопродајне поклон-картице.</li>\n</ul>\n<p>Ако не желите да потрошите читаву вредност вашег „Reward Link“-а, сачувајте имејл или URL и вратите га путем линка за искоришћење.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms &amp; Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U835134",
      //     rewardName: "Reward Link Sweden",
      //     currencyCode: "SEK",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 1,
      //     maxValue: 10000,
      //     createdDate: "2020-11-10T16:10:45.69Z",
      //     lastUpdateDate: "2023-08-09T22:24:19.497Z",
      //     countries: ["SE"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Läs villkoren</a></p>\n<ul>\n<li>Klicka på inlösningslänken ovan för att aktivera din belöningslänk.</li>\n<li>Därefter kommer du att kunna spendera ditt saldo på presentkort.</li>\n</ul>\n<p>Om du inte vill spendera hela ditt belöningslänkvärde direkt, spara e-postmeddelandet eller webbadressen och returnera via inlösenlänken.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U945895",
      //     rewardName: "Reward Link Switzerland",
      //     currencyCode: "CHF",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 5,
      //     maxValue: 350,
      //     createdDate: "2020-10-13T21:40:43.359Z",
      //     lastUpdateDate: "2023-08-09T22:15:50.576Z",
      //     countries: ["CH"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="http://www.rewardsgenius.com/reward-link-terms-of-service/">Lesen Sie die Allgemeinen Geschäftsbedingungen </a></p>\n<ul>\n<li>Klicken Sie auf den Einlösungslink oben, um Ihren Reward Link zu aktivieren.</li>\n<li>Als nächstes können Sie Ihr Guthaben für Geschenkgutscheine im Einzelhandel ausgeben.  </li>\n</ul>\n<p>Wenn Sie nicht Ihren gesamten Prämienlink-Wert sofort ausgeben möchten, speichern Sie die E-Mail oder URL und kehren Sie über den Einlöselink zurück.</p>\n<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U636024",
      //     rewardName: "Reward Link UK",
      //     currencyCode: "GBP",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2017-06-20T00:18:29.28Z",
      //     lastUpdateDate: "2023-08-09T22:03:06.493Z",
      //     countries: ["UK", "GB"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      //   {
      //     utid: "U453114",
      //     rewardName: "Reward Link US (No Donations)",
      //     currencyCode: "USD",
      //     status: "active",
      //     valueType: "VARIABLE_VALUE",
      //     rewardType: "reward link",
      //     isWholeAmountValueRequired: false,
      //     minValue: 0.01,
      //     maxValue: 2000,
      //     createdDate: "2023-04-12T17:45:21.121Z",
      //     lastUpdateDate: "2023-05-08T22:51:22.346Z",
      //     countries: ["US"],
      //     credentialTypes: ["expirationDate", "redemptionUrl"],
      //     redemptionInstructions:
      //       '<p><a href="https://www.rewardsgenius.com/reward-link-terms-of-service/">Read Terms & Conditions</a></p>\n<ul>\n<li>Click the redemption link above to activate your Reward Link.</li>\n<li>Next, you will be able to spend your balance on retail gift cards.</li>\n</ul>\n<p>If you don\'t want to spend your entire Reward Link value right away, save the email or URL and return via the redemption link.</p>',
      //     fulfillmentType: "DIGITAL",
      //   },
      // ]);
      setloading(false);
    } catch (error) {
      setloading(false);
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: error.message },
      });
    }
  };
  useEffect(() => {
    getAvailableRewards();
    
  }, []);
  useEffect(()=>{
    setRealWallet(
        wallet.wallet.filter((wallet) => wallet.walletsType === "real")[0]
      );
  }, [wallet])
  const getExchangeRate = async (currencyCode) => {
    try {
      console.log(currencyCode);
      if("INR" === currencyCode){
        return 1;
      }
      const { data } = await axios.get(
        `/getExchangeRates?base=INR&reward=${currencyCode}`
      );
      console.log('This is data',data);
      
      return data.rate;
      
    } catch (error) {
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: error.message },
      });
    }
  };
  useEffect(() => {
    if (selectedReward.utid) {
      getExchangeRate(selectedReward.currencyCode).then((rate) => {
        console.log(rate);
        setRewardDetails({
          ...rewardDetails,
          rate,
          min: Math.ceil(
            (selectedReward.minValue / rate) > 1
              ? (selectedReward.minValue / rate)
              : 1 / rate
          ),
        });
      });
    }
  }, [selectedReward]);
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"sm"}>
      {!loading && !confirm ? (
        <form className="p-5" onSubmit={handleOnSubmit}>
          <h1 className="text-[16px] font-semibold capitalize text-black  tracking-widest ">
            Choose The Reward Details
          </h1>
          <div>
            <div className="w-full flex flex-col">
              <label htmlFor="" className="py-2">
                Choose Reward Type
              </label>
              <select
                name="utid"
                onChange={(e) => {
                  setRewardDetails({
                    ...rewardDetails,
                    utid: e.target.value,
                    amount: null,
                    qzetos: null,
                    min: null,
                  });
                  setSelectedReward(
                    available.filter(
                      (reward) => reward.utid === e.target.value
                    )[0]
                  );
                  console.log(e.target.value);
                }}
                className="w-full h-12 border rounded-lg"
              >
                <option value="">Choose The Reward option</option>
                {available?.map((reward) => {
                  return (
                    <option value={reward.utid} key={reward.utid}>
                      {reward.rewardName} - {reward.currencyCode}
                    </option>
                  );
                })}
              </select>
            </div>
            {rewardDetails.min ? (
              <>
                <div className="w-full text-right text-xs text-red-500 py-2">
                  1 Qzetos = {rewardDetails.rate} {selectedReward.currencyCode}
                </div>

                <div className="w-full flex flex-col">
                  <label htmlFor="" className="py-2">
                    Redemtion Amount
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setRewardDetails({
                        ...rewardDetails,
                        qzetos: e.target.value,
                        amount: (e.target.value * rewardDetails.rate)?.toFixed(2),
                      });
                    }}
                    value={rewardDetails.qzetos}
                    placeholder={`Enter qzetos (${rewardDetails.min} - ${realWallet?.credit})`}
                    className="w-full h-12 border rounded-lg"
                  />
                  {rewardDetails.qzetos < rewardDetails.min && (
                    <div className="w-full text-right text-xs text-red-500 py-2">
                      Qzetos should be {rewardDetails.min} or greater
                    </div>
                  )}
                </div>
                <div className="w-full flex flex-col">
                  <label htmlFor="" className="py-2">
                    Gift card value
                  </label>
                  <input
                    type="text"
                    value={rewardDetails?.amount}
                    className="w-full h-12 border rounded-lg"
                    disabled={true}
                  />
                </div>
                <div className="w-full flex justify-center items-center py-5">
                  <button
                    onClick={(e) => {
                      // handleOnSubmit(e);
                      setConfirm(true);
                    }}
                    className="px-5 h-10 border rounded-full bg-green-400 text-white border-green-400 hover:bg-white hover:text-green-400"
                  >
                    Redeem
                  </button>
                </div>
              </>
            ) : (
              <>
                {selectedReward.utid && (
                  <div className="flex justify-center items-center py-10">
                    <CircularProgress />
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      ) : confirm ? (<>
        <div className="p-5 flex justify-center flex-col items-center">
          <h1 className="text-green-400 text-xl text-bold">Confirm</h1>
            <p className="text-lg">You are going to redeem {rewardDetails.amount} on {selectedReward.rewardName}</p>
            <div className="flex gap-2 mt-5">
              <button className="px-2 h-10 border border-red-400 bg-red-400 text-white rounded-full hover:text-red-400 hover:bg-white" onClick={()=>setConfirm(false)}>
                Cancel
              </button>
              <button className="px-2 h-10 border border-green-400 bg-green-400 text-white rounded-full hover:text-green-400 hover:bg-white" onClick={(e)=>{
                setConfirm(false);
                handleOnSubmit(e)}}>
                Confirm
              </button>
            </div>
        </div>
      </>) : (<>
        <div className="flex justify-center items-center py-10">
          <CircularProgress />
        </div>
      </>)}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo({ open, setOpen }) {
  const [selectedValue, setSelectedValue] = useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        setOpen={setOpen}
      />
    </div>
  );
}
