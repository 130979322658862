import React, {useState} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from '../../utils/myAxios'
import { CircularProgress, inputAdornmentClasses } from '@mui/material';

function FAQType({type, setTypes}) {
    const [faqType, setFaqType] = useState(type)
    const [loading, setLoading] = useState(false)
    const [edit, setEdit] = useState(false)
    const handleChange = (e)=>{
        const {value, name} = e.target
        console.log(faqType)
        setFaqType({
            ...faqType,
            [name]: value
        })
    }
    const handleSubmit = ()=>{
        console.log(faqType)
        setLoading(true)
        axios.post('/updateType', faqType).then(res => {
            setTypes(res.data.types)
            setEdit(false)
            setLoading(false)
        })
    }
    const handleDelete = ()=>{
        setLoading(true)
        axios.post('/deleteType', faqType).then(res => {
            setTypes(res.data.types)
            setEdit(false)
            setLoading(false)
        })
    }

  return (
    <div className='w-full rounded-lg p-2 bg-white my-2 flex justify-between'>
        {!edit && <span>{faqType?.name}</span>}
        {edit && <input type="text" value = {faqType?.name} name = "name" onChange = {(e)=>handleChange(e)} className='w-full h-9 rounded-lg'/> }
        {!edit && <div className=''>
            <EditIcon onClick = {()=>setEdit(true)}/>
            <DeleteIcon className='text-red-500' onClick = {()=>handleDelete()}/>
        </div>}
        {edit && <div className='flex gap-2 px-2'>
            {edit && <button className='px-2 h-9 rounded-full border bg-red-400 text-white hover:text-red-400 hover:bg-white' onClick={()=>setEdit(false)}>Cancel</button>}
            {edit && <button className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white' onClick={()=>handleSubmit()}>Save</button>}
        </div>}
    </div>
  )
}

export default FAQType