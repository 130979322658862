import React, { useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
import { approveQuestionAction, rejectQuestionAction } from '../../../actions/question.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { globalConstants } from '../../../actions/appConstants'
import axios from '../../../utils/myAxios'
const TableRow = ({ val, questions, setquestions, showQuestinData, adminQuestionsState }) => {
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const disaptch = useDispatch()
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleApprove = () => {
        // console.log(val)
        disaptch(approveQuestionAction(val._id)).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleReject = () => {
        // console.log(val)
        setopen(true)
    }
    const handleQuestionEdit = () => { }
    const handleQuestionCopy = async () => {
        try {
            disaptch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.post(`/copy/user/questions/to/admin/questions/${val._id}/${auth.data.user._id}`)
            disaptch({
                type: globalConstants.ALERT,
                payload: { success: 'Question copied successfully!' }
            })
        } catch (error) {
            if (error.response.data.msg) {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    const handleQuestionDelete = async () => {
        try {
            disaptch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            await axios.delete(`/admin/delete/users/questions/${val._id}`)
            setquestions(q => q.filter(_q => _q._id !== val._id))
            disaptch({
                type: globalConstants.ALERT,
                payload: { success: 'Question deleted successfully!' }
            })
        } catch (error) {
            if (error.response.data.msg) {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                disaptch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} />
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val.createdAt).subtract(10, 'days').calendar()}</span>
                </td>
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val?.statement}</span>
                </td>
                <td class="py-3 px-6 text-left">
                    <span>{(val?.questionCategory?.name).replace(/_/g, ' ')}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    {
                        val.subject
                    }
                </td>
                <td class="py-3 px-6 text-center">
                    {
                        val.approveStatus === 'pending' ?
                            <span class="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">pending</span>
                            :
                            val.approveStatus === 'rejected' ?
                                <span class="bg-red-200 py-1 px-3 rounded-full text-red-700">rejected</span>
                                :
                                <span class="bg-green-200 text-green-700 py-1 px-3 rounded-full text-xs">approved</span>

                    }
                </td>
                {
                    (auth.data.user.isAdmin && adminQuestionsState === 'all') &&
                    <td class="py-3 px-6 text-center">
                        <div class="flex item-center justify-center space-x-3">
                            <button onClick={handleApprove} className='border-none rounded-md  py-1 px-3 text-white bg-green-400 text-xs'>Approve</button>
                            <button onClick={handleReject} className='border-none  rounded-md py-1 px-3 text-white bg-red-400 text-xs'>Reject</button>
                            <div className='ml-3 space-x-3'>
                                <button onClick={() => showQuestinData({ ...val, edit: true, editReal: true, })} className='border-none  rounded-md py-1 px-3 text-white bg-red-400 text-xs'>Edit</button>
                                <button onClick={handleQuestionCopy} className='border-none  rounded-md py-1 px-3 text-white bg-red-400 text-xs'>Copy</button>
                                <button onClick={handleQuestionDelete} className='border-none  rounded-md py-1 px-3 text-white bg-red-400 text-xs'>Delete</button>
                            </div>
                        </div>
                    </td>
                }
                <td class="py-3 px-6 text-center whitespace-nowrap space-x-3">
                    <button onClick={() => showQuestinData({ ...val, edit: false })} className='rounded-md py-1 text-xs px-3 bg-gray-500 text-white capitalize'>view question</button>
                    {
                        (val.rejectedReasons && adminQuestionsState !== 'all') &&
                        <button onClick={() => showQuestinData({ ...val, edit: true })} className='rounded-md py-1 text-xs px-3 bg-red-500 text-white capitalize'>approval request</button>
                    }
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    {
                        val.approveStatus === 'rejected' ?
                            <h1 className='text-red-500'>{val.rejectedReasons}</h1>
                            :
                            <h1>---</h1>
                    }
                </td>
            </tr>
        </>
    )
}

export default TableRow