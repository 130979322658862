import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DoneIcon from '@mui/icons-material/Done';
const BuyRealQzetosCard = ({
    price,
    qzetos,
    handleBuyNow, 
    ai_questions,
    quizzes,
    quizzithon,
    quizmaster_pro,
    plan
}) => {
    return (
        <div className='relative rounded-2xl border border-slate-200 shadow-lg p-8 bg-white flex flex-col'>
            
            <h4 className='text-lg font-semibold leading-5'>{plan?.name}</h4>
        
            <div className='mt-4 bg-slate-100 p-6 rounded-lg -mx-6 '>
                <p className='text-sm font-semibold text-slate-500 flex items-center'>
                    <span>INR</span>
                    <span className='text-3xl text-slate-900 ml-3'><CurrencyRupeeIcon/>{price}</span>
                    <span className='ml-1.5'></span>
                </p>
            </div>
            {/* Items included in package */}
            <ul className='mt-6 space-y-1 '>
                <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{qzetos} Qzetos Included</li>
                <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{ai_questions} AI Questions</li>
                <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{quizmaster_pro} Quizmaster Pro quizzes</li>
                <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{quizzithon} Quizzithons</li>
                <li className='text-sm leading-6'><DoneIcon className='text-cyan-500 mr-2'></DoneIcon>{quizzes} Quizzes</li>
            </ul>
        {/* button */}
            <button onClick={() => handleBuyNow(qzetos, price, plan)} className='text-white bg-cyan-500 hover:bg-cyan-500 block mt-8 px-6 py-4 text-center rounded-lg shadow-md'>Purchase</button>
        </div>

    )
}
const ExtraData = styled.div`
::-webkit-scrollbar{
    width: 10px;
    cursor: pointer !important;
}
::-webkit-scrollbar-track{
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0,0,0,0.25);
    cursor: pointer !important;
}
::-webkit-scrollbar-thumb{
border-radius: 5px;
background-color: #1e3a8a;
cursor: pointer !important;
}

`
const Cont = styled.div`
${tw`w-full bg-[#FFBE40] border border-gray-300  rounded-md   flex flex-col `}
`
export default BuyRealQzetosCard