import React, { useEffect, useState } from 'react'
import first from '../../assets/prizez/1.svg'

function TableRow({ val, index , quizzes , quizzithon }) {
    const [time, settime] = useState()
    useEffect(() => {
        if (val) {
            const minutes = Math.floor(val?.time / 60);
            const seconds = val.time - minutes * 60;
            settime({
                minutes: minutes,
                seconds: seconds,
            })
        }
    }, [val])
    return (
        <>

            <tr class=" flex justify-evenly border-b border-gray-200 hover:bg-gray-100">
                <td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                    {index < 4 ? <div className='w-12 h-12 relative'>
                        <span className='font-bold text-[14px] text-[#DF6D00] absolute left-[50%] top-[45%] -translate-x-[50%] -translate-y-[50%]'
                        >
                            {index}
                        </span>
                        <img src={first} className='w-full h-full object-contain' />
                    </div> :
                        <span className='w-12 h-12 font-bold text-[14px]'>{index}</span>
                    }
                </td>
                <td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                    

                        <span className='font-semibold'>
                            {

                                val?.userInfo?.userName
                            }
                        </span>
                    
                </td>
                
                {
                    quizzes?.map((quiz, i) => {
                        
                        return (<td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                        <span className='font-semibold'>
                            {
                                val?.quizAttempted.findIndex((item)=> item.quizId === quiz.quiz_id._id) === -1 ? "n/a" : val.quizAttempted[val.quizAttempted.findIndex((item)=> item.quizId === quiz.quiz_id._id)].correctQuestions
                            }
                        </span>
                    </td>)

                    })}
                <td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                    <span className='font-semibold text-blue-600'>
                        {
                            val.correctQuestions
                        }
                    </span>
                </td>
                <td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                    <span className='font-semibold text-blue-600'>
                        {
                            `${time?.minutes}m:${time?.seconds}s`
                        }
                    </span>
                </td>
                {
                    quizzithon.status === "closed" && <td class="basis-0 grow py-3 px-6  whitespace-nowrap flex justify-center items-center">
                        <span className='font-semibold text-blue-600'>
                            {
                                index === 1 ? quizzithon.firstPerson + " Qzetos" : index === 2 ? quizzithon.secondPerson + " Qzetos" : index === 3 ? quizzithon.thirdPerson + " Qzetos" : "n/a"
                            }
                        </span>
                    </td>
                }
            </tr>
        </>
    )
}

export default TableRow