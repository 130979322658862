import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import axios from '../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../actions/appConstants'
import { CircularProgress } from '@mui/material'
import Section from '../components/quizzesWithDifferentSubjectC/Section'
import { useNavigate } from 'react-router-dom'
import quizImg from '../assets/staticdata/3.png'
import NavBar from '../staticApp/staticComponents/navBar/NavBar'


const QuizzesWithDifferentSubjectsPage = (isUser) => {
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()
    const [category, setcategory] = useState('')
    const [quizzes, setquizzes] = useState([])
    const [loading, setloading] = useState(false)
    const { cid , hide } = useParams()
    const [query , setQuery] = useState('')
    useEffect(async () => {
        if (cid) {
            try {
                setloading(true)
                setcategory(cid)
                const res = await axios.get(`/get/school/quizzes/with/differentsubjects/${cid}${hide ? "/?quiz="+query : "/"+auth.data.user._id}`)
                setquizzes(res.data.data)
                console.log('data==>', res.data)
                setloading(false)
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }, [cid])
    const handleSearch = async () => {
        try {
            setloading(true)
            const res = await axios.get(`/get/school/quizzes/with/differentsubjects/${cid}?quiz=${query}`)
            setquizzes(res.data.data)
            console.log('data==>', res.data)
            setloading(false)
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }

    return (
        <>
        {

            hide ? <div className='w-full overflow-x-hidden'>
            <NavBar/>
            {

                loading ?
                    <div className='flex items-center justify-center mt-5'>
                        <CircularProgress />
                    </div>
                    :
                    quizzes.length > 0 ?
                    <>
                        
                        <div className='flex flex-col items-center'>
                            
                            <div className=' md:w-full lg:w-[80%]'>
                            <div className='w-full flex'>
                                <input className='grow rounded-xl h-8 mx-3' type="text" placeholder='Search quiz here' value={query} onChange={(e)=>{setQuery(e.target.value)}} />
                                <button className='px-2 h-8 bg-yellow-500 hover:text-yellow-500 hover:bg-white border border-yellow-500 rounded-full' onClick={handleSearch} >Search</button>
                            </div>
                            {
                                quizzes.map((val, index) => (
                                    <Section
                                        isSchoolQuiz={true}
                                        isUser = {true}
                                        cid={category}
                                        key={index}
                                        val={val}
                                    />
                                ))
                            }
                            </div>
                        </div>
                        </>
                        :
                        // <NotFoundDataUi text={`No Quizzes Available`} />
                        <div className='w-full flex flex-col items-center'>
                            <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                            <div className='w-full flex flex-col items-center'>
                                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                    There is no live quiz now, Why don't you become our quizmaster and schedule a quiz for others & get rewarded
                                </p>
                                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={()=>navigate('/schedulequiz')}>Schedule Quiz</button>
                            </div>
                        </div>
            }</div> :<Layout>
            {
                loading ?
                    <div className='flex items-center justify-center mt-5'>
                        <CircularProgress />
                    </div>
                    :
                    quizzes.length > 0 ?
                        <div>
                            {
                                quizzes.map((val, index) => (
                                    <Section
                                        isSchoolQuiz={true}
                                        cid={category}
                                        key={index}
                                        val={val}
                                    />
                                ))
                            }
                        </div>
                        :
                        // <NotFoundDataUi text={`No Quizzes Available`} />
                        <div className='w-full flex flex-col items-center'>
                            <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                            <div className='w-full flex flex-col items-center'>
                                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                    There is no live quiz now, Why don't you become our quizmaster and schedule a quiz for others & get rewarded
                                </p>
                                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={()=>navigate('/schedulequiz')}>Schedule Quiz</button>
                            </div>
                        </div>
            }
        </Layout>
        }
        
        </>
    )
}

export default QuizzesWithDifferentSubjectsPage