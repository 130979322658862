import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../utils/myAxios'
import { useDispatch } from 'react-redux'
import { globalConstants, questionsCategoryConstants } from '../../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import { TrainRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import NotFoundDataUi from './../../uiComponents/NotFoundDataUi'
import quizImg from '../../../assets/staticdata/add-question.png'
import { useNavigate } from 'react-router-dom'
const QuestionsTable = ({
    schedulerData,
    setschedulerData,
    onClose, selectedValue
}) => {
    const handleClose = () => {
        onClose(selectedValue);
    };
    const { auth, question } = useSelector(s => s)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    const [adminQuestionsState, setadminQuestionsState] = useState('')
    useEffect(() => {
        const getData = async () => {
            try {
                const findTheIdOfType = question.questionsCategories.find(v => v.name === schedulerData.category)
                let toSendCat;
                if (
                    findTheIdOfType.name === questionsCategoryConstants.MIX_TYPE_QUIZ
                    || findTheIdOfType.name === questionsCategoryConstants.SPIN_A_WHEEL_QUIZ
                    || findTheIdOfType.name === questionsCategoryConstants.TIC_TAC_TOE
                    || findTheIdOfType.name === questionsCategoryConstants.ELIMINATOR_QIZ
                ) {
                    toSendCat = ''
                } else {
                    toSendCat = findTheIdOfType._id
                }
                //console.log('filter Category==>', findTheIdOfType)
                setloading(TrainRounded)
                const res = await axios(`/getuserquestions?page=${page}&limit=${limit}&type=${adminQuestionsState}&category=${toSendCat}&subject=${schedulerData.quizzSubject}`)
                if (res.status === 200) {
                    //console.log('questions==>', res.data)
                    setquestions(res.data.questions)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setloading(false)
            } catch (error) {
                setloading(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        if (schedulerData.quizzSubject && schedulerData.category) {
            getData()
        }
    }, [page, limit, schedulerData.quizzSubject, schedulerData.category])
    //console.log('totallength==>', totalLength)
    return (
        <div>
            {
                loading ?
                    <div className='flex items-center justify-center w-full'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?
                        <>
                            <div className='flex items-center justify-between flex-wrap px-2 mt-6'>
                                <h1>Set Questions</h1>
                                <button onClick={handleClose} className='py-2 px-3 rounded-md bg-bgblue text-white' >Add Questions</button>
                            </div>
                            <MyTable
                                schedulerData={schedulerData}
                                setschedulerData={setschedulerData}
                                questions={questions} setquestions={setquestions} />
                            <div className='flex justify-center px-5 pt-5'>
                                <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                            </div>
                        </>
                        :
                        <div className='w-full flex flex-col items-center'>
                            <img className='w-[40%] md:w-[30%]' src={quizImg} alt="" />
                            <div className='w-full flex flex-col items-center'>
                                <p className='w-[70%] lg:w-[45%] text-center text-gray-600 py-5 text-lg'>
                                    You dont have enough questions in your question bank to schedule a quiz. Please add more questions to your question bank.
                                </p>
                                <button className='w-32 h-10 bg-green-400 hover:bg-white hover:text-green-400 border border-green-400 rounded-full text-white' onClick={() => navigate('/questionbank')}>Add Questions</button>
                            </div>
                        </div>
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No school questions available...</div>
            }

        </div >
    )
}

export default QuestionsTable