import moment from 'moment'
import React from 'react'
import TableRow from './TableRow'

const MyTable = ({ questions, setquestions }) => {
    return (
        <div class="overflow-x-auto">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                <div class="w-full overflow-auto">
                    <div class="p-3">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <th class="py-3 px-6 text-left">username</th>
                                    <th class="py-3 px-6 text-left">Profile Image</th>
                                    <th class="py-3 px-6 text-center">mobile/email</th>
                                    <th class="py-3 px-6 text-center">varified status</th>
                                    <th class="py-3 px-6 text-center">user status</th>
                                    <th class="py-3 px-6 text-center">make admin/user</th>
                                    <th class="py-3 px-6 text-center">question auto approval</th>
                                    <th class="py-3 px-6 text-center">quizzes</th>
                                    <th class="py-3 px-6 text-center">question bank</th>
                                    <th class="py-3 px-6 text-center">Purchases</th>
                                    <th class="py-3 px-6 text-center">redemptions</th>
                                    <th class="py-3 px-6 text-center">played quizzes</th>
                                    <th class="py-3 px-6 text-center">Quizmaster-Pro Stats</th>
                                    <th class="py-3 px-6 text-center">Quizzithons</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    questions.length > 0 &&
                                    questions.map((val, index) => (
                                        <TableRow questions={questions} setquestions={setquestions} val={val} key={index} />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTable