import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { globalConstants } from '../../actions/appConstants'
import { createQuizzScheduleAction } from '../../actions/quizzSchedule.actions'
import { quizzithonActions } from '../../actions/quizzithon.actions'
const MostCommon = ({ schedulerData, setschedulerData, ____durTime , quizIndex , isQuizzithonQuiz}) => {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { question, subjects , quizzithon } = useSelector(state => state)
    
    const handleSubmit = async () => {
        setschedulerData({...schedulerData , type :quizzithon.quizzithon.type})
        const find = question.questionsCategories.find(val => val.name === schedulerData.category)
        const obj = {
            ...schedulerData,
            category: find._id
        }
        if (obj.winningPrize <= 0) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "winning prize is required!" }
        })
        if (!(obj.subject).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "subject is required!" }
        })
        if (!(obj.type).trim()) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: 'type is required!' }
        })
        if (schedulerData.questions_info?.count <= 0) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "number of questions is required!" }
        })
        if (schedulerData.minimumNumberOfSeats <= 0) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: "minum participants are required!" }
        })

        // console.log(obj)

        const date = new Date(schedulerData.date)
        const time = new Date(schedulerData.time)
        //
        const date_month = date.getMonth()
        const date_date = date.getDate()
        //
        const currDate = new Date()
        if ((date.getDate() < currDate.getDate()) && (date.getMonth() === currDate.getMonth())) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "select future date!" }
            })
        }
        if (date_month === currDate.getMonth() && date_date === currDate.getDate()) {
            if (time < currDate) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "select future time!" }
                })
            }
        }
        //for past Quiz End Time
        if (schedulerData.isDurationBound === false) {
            //console.log('in if==>')
            const endtime = new Date(schedulerData.durationDateComplete)
            if (endtime < currDate) {
                console.log("timings" , endtime , currDate)
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "selct future end time!" }
                })
            }
            if (endtime <= time) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "end time must be greater than start time." }
                })
            }
        } else {
            //console.log('in else==>')
            let newEndTime = new Date(obj.time)
            newEndTime.setMonth(date.getMonth())
            newEndTime.setDate(date.getDate())
            let endtime = new Date(newEndTime)
            endtime.setMinutes(endtime.getMinutes() + Number(____durTime))
            endtime = new Date(endtime)
            obj.durationBoundEndTime = endtime
            //console.log('endtime==>', endtime)
            if (endtime < currDate) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "selct future end time!" }
                })
            }
            if (endtime <= time) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "end time must be greater than start time." }
                })
            }
        }
        //for past Quiz End Time
        if(!isQuizzithonQuiz){
            
            dispatch(createQuizzScheduleAction(obj)).then((val) => {
                if (val) {
                    navigate('/dashboard')
                }
            })
        }
        else{
            dispatch({
                type : quizzithonActions.UPDATE_QUIZ, 
                payload : {
                    quizIndex : quizIndex,
                    quiz : {...obj , isQuizzithonQuiz : true}
                }
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success : "Quiz info saved" }
            })
        }
        

    }
    return (
        <Cont>
            <div className='grid lg:grid-cols-3 grid-cols-1 gap-7'>
                <div className=''>
                    <h1 className=''>Select subject</h1>
                    <select
                        value={schedulerData.subject}
                        onChange={(e) => {
                            setschedulerData({ ...schedulerData, subject: e.target.value })
                            dispatch({
                                type : quizzithonActions.UPDATE_QUIZ, 
                                payload : {
                                    quizIndex : quizIndex,
                                    quiz : {...schedulerData , isQuizzithonQuiz : true}
                                }
                            })
                        }}
                        className='border border-black w-full py-2 outline-none px-2 rounded-md capitalize'
                    >
                        <option value=''>select the subject</option>
                        {
                            subjects.subjects.map((val, index) => (
                                <option value={val.name}>{val.name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className=''>
                    <h1 className=''>Number of questions</h1>
                    <input
                        type='number'
                        value={schedulerData.questions_info?.count}
                        onChange={(e) => {setschedulerData({
                            ...schedulerData, questions_info: {
                                ...schedulerData.questions_info,
                                count: e.target.value
                            }
                        })
                        dispatch({
                            type : quizzithonActions.UPDATE_QUIZ, 
                            payload : {
                                quizIndex : quizIndex,
                                quiz : {...schedulerData , isQuizzithonQuiz : true}
                            }
                        })
                    }}
                        className='border border-black w-full py-1.5 outline-none px-2 rounded-md'
                    />
                </div>
            </div>
            <div className='flex justify-end my-5'>
                <button onClick={handleSubmit} className='text-white bg-bgblue py-1 px-3 rounded-2xl'>Save</button>
            </div>
        </Cont>
    )
}
const Cont = styled.div`

`
export default MostCommon