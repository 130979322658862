import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import NavBar from "../staticComponents/navBar/NavBar";
import Footer from "../staticComponents/footer/Footer";
import "./../static.css";
import frequentylyAskedHero from "../../assets/staticdata/frequentylyAskedHero.jpeg";
import { CircularProgress } from "@mui/material";
import axios from "../../utils/myAxios";
import { useNavigate } from "react-router-dom";
const Heading = ({ text }) => (
  <h1 className="mt-5 font-bold text-xl  text-center mb-3">{text}</h1>
);
const data = [
  {
    heading: "Registration",
    accord: [
      {
        q: "How Do I Get Started On Www.quizzingbox.com?",
        a: "Just follow these simple steps: Register with us free by entering a Username, Desired Password, E-mail id, and Mobile numberLog-in using the Username and Password you registered with.Click on Quiz tab to see the list of scheduled quiz on the platformClick on view' button beside each scheduled quiz to check the detail quiz structureClick on register' button on each scheduled quiz and you are ready to take the quiz online.",
      },
      {
        q: "I Am Not Able To Login To My Account?",
        a: "Please check whether your registered email address/Username and password are entered correctly and try again. If you've forgotten your password, click on 'Forgot Password', enter your registered email ID and we’ll send you a link to reset your password through an email within a few minutes! If you've forgotten your registered username, you can very well login with your registered e-mail id and password. If you have forgotten both your registered email id and password reach out to us via Contact Us page.",
      },
      {
        q: "How Do I Change My Password?",
        a: "When you are logged in, click on My Account tab and click on 'Change Password'",
      },
      {
        q: "Can I Have More Than One Quiz Account?",
        a: "Having multiple accounts is strictly prohibited on www.quizzingbox.com. Each user is allowed to have only one account on the site. Making multiple accounts may result in deletion of all duplicate accounts.",
      },
      {
        q: "Can I Update / Edit My Information?",
        a: "Yes you can very well do that. Simply log in and go to my account section click on the ‘Edit Profile’ button. You will be able to update / edit your profile detail except registered email id, & mobile number.",
      },
      {
        q: "I Have Entered The Right Username And Password But I Still Can't Log In.",
        a: "If you are sure that you are a registered user on quizzingbox.com and are entering the correct details, please reach out to us from the Contact Us page. Our Customer Care team will be happy to assist you.",
      },
    ],
  },
  {
    heading: "GETTING STARTED",
    accord: [
      {
        q: "I Did Not Get Any Confirmation Email After I Signed Up.",
        a: "It could have been marked as Spam mail by your mailbox. Please check your spam mail and mark the email from quizzingbox.com as ‘Not Spam’. b) You may not have entered the correct email address during registration. For any assistance, please reach out to us from the Contact Us page.",
      },
      {
        q: "Is My Quiz Account Safe?",
        a: "Yes, your account remains safe until your password is not revealed to anyone. Your password is the key to your account and it is your responsibility not to disclose it to anyone.",
      },
      {
        q: "Which Quiz Formats Can I Play On quizzingbox.com?",
        a: "www.quizzingbox.com offers both free and paid quizzes. Free quiz formats are those quizzes which can be played by using free qzeto which every user gets daily as joining bonus on daily basis. Paid quizzes can only be played by using real qzeto which users need to purchase for their participation",
      },
      {
        q: "Can I Register For More Than 1 Quiz At A Time?",
        a: "Yes, you can register for any number of quiz at a time but start timing of each quiz contest will be different so only one quiz contest can be participated at atime",
      },
      {
        q: " What Is The Time Limit For A Quiz?",
        a: "Time limit for each quiz will vary and will be mentioned in the rules table for the particular quiz",
      },
      {
        q: "How Will I Be Informed If I Win A  Prize?",
        a: "Ranking and winners of every quiz contest scheduled will be displayed immidietely on the leader board section on its quiz info page at the end of every quiz. If you win any Paid Quiz Contest, The real qzetos as mentioned in the prizepool summary table will be credited automatically into your quizzingbox wallet. Same will be communicated to all winners via email as well",
      },
      {
        q: "Does The Amount In My Winnings Account Have Any Expiry Date?",
        a: "If the amount in your Winnings Account is not redeemed to your bank account within 335 days from the date of credit, it will be deposited automatically to your bank account which is on record with quizzingbox.com, provided that you have verified your email associated with quizzingbox.com account. In case no redeem request is received by us or you have failed to complete the email verification for the quizzingbox.com account within 365 days, the amount would stand forfeited by quizzingbox.com. Hence it’s important that you get your quizzingbox.com account verified as soon as possible.",
      },
    ],
  },
  {
    heading: "QZETOS",
    accord: [
      {
        q: "How Do I Check My quizzingbox.com Account Balance?",
        a: "The balance in your quizzingbox.com account will be displayed on the top of your screen at all times.",
      },
      {
        q: "Is It Safe To Pay Through Your Website?",
        a: "Absolutely Yes! To ensure that all the payments are safe and secure, we use reputed and verified third party payment gateways that have multiple security checks and comply with every single law on online transactions.",
      },
      {
        q: " How Do I Get Notification For My Transaction?",
        a: "For all successful transactions, you will get a confirmation email with your Order detail and the Amount from quizzingbox.com.",
      },
      {
        q: "What If My Account Balance Is Not Correct?",
        a: "If you require any help regarding your quizzingbox.com account balance or any other queries, please feel free to reach us from the Contact Us page",
      },
      {
        q: "I Am Getting An Error Message When I Am Trying To Pay Via The Payment Gateway?",
        a: "The mode of payment you have chosen may not be authorized by your bank, in which case you should call them and get it fixed. Also, please reach us from the Contact Us page with the error message screenshot so that we can advise you on how to complete your transaction.",
      },
      {
        q: "How Long Does It Take For My Amount To Be Credited Into My quizzingbox.com Account Balance In Form Of Real Qzetos?",
        a: "Once you have made the deposit, your quizzingbox.com account balance will get updated instantly in the form of real qzetos. If the updated balance is not reflected in your account please reach us from the Contact Us page.",
      },
    ],
  },
  {
    heading: "QUIZ FORMATS",
    accord: [
      {
        q: "What Do You Mean By Free Qzetos?",
        a: "Free Qzetos are points given to all users for participating in free quiz. User need to take the free quiz using these free qzetos . Everyday user receives 20 free qzetos in the wallet on first login.",
      },
      {
        q: "What Do You Mean By Bonus Qzetos?",
        a: "Bonus Qzetos are points which user earns by playing free quizzes on the www.quizzingbox.com which can be converted to Real Qzetos for participating in paid quizzes While taking the free quiz every user gets rewarded with Bonus Qzetos as per below rules 100% correct answer- 5 bonus qzetos95-99% correct answer- 3 bonus qzetos90-94% correct answer- 1 bonus qzetos",
      },
      {
        q: "What Is The Conversion Ratio Of Bonus Qzetos Into Real Qzetos?",
        a: "100 Bonus Qzetos is equal to 1 Real Qzetos. This will be changing as and when required and quizzingbox.com owns the right to this any time",
      },
      {
        q: "How Should I Convert My Bonus Qzetos Into Real Qzetos?",
        a: "Go to My Account section, click on 'Convert', enter the bonus qzeto balance which you want to convert and hit the 'convert to real qzeto button'. Based on the existing conversion ratio your bonus qzeto balance will be converted into real qzeto balance instantaneously",
      },
      {
        q: "I Won 15000 Real Qzeto In A Quiz But Only 10365 Real Qzeto Got Credited In My Account. Why?",
        a: "As per Section 115BB of the Income Tax Act the current rate of Income Tax on winnings from games/contests offering  reward is 30%. However there is education and higher education cess payable on the taxable amount and hence the total effective amount of tax payable is 30.90%. When you win any amount equal or greater than 10000 its liable for flat 30.9% TDS deduction as per government rule. So in your case 30.9% of 15000 is 4635 which is deducted as TDS and remaining 10365 is paid to you in your wallet",
      },
      {
        q: "Are Bonus Qzetos Redeemable?",
        a: "After conversion into real qzetos, bonus qzetos are redeemable.",
      },
      {
        q: "How To Purchase Real Qzetos?",
        a: "It's very easy to buy Real Qzetos on www.quizzingbox.com. Create an account by filling up simple details.Click on Buy Qzetos& button on top of every page, agree to the terms and conditions. and you are ready to purchase real qzetos1 Real Qzetos will cost you one rupee and Qzetos balance will be shown in your wallet immediately after you complete your transaction",
      },
    ],
  },
  {
    heading: " REWARDS",
    accord: [
      {
        q: "What Are The Various Payment Options Available At quizzingbox.com?",
        a: "www.quizzingbox.com accepts payments by Debit Cards, Credit Cards, Net banking and all UPI channels.",
      },
      {
        q: "What Is The Min. &max. Real Qzetos Which I Can Buy?",
        a: "The minimum Real Qzetos to be purchased is 50 and maximum is 5000.",
      },
      {
        q: "How Do I Redeem The Real Qzetos On Www.quizzingbox.com?",
        a: "Redeeming the Real Qzetos rewarded to you is very easy. When you are rewarded with any Real Qzetos on our platform, the winning amount shall be credited to your Real Qzetos balance immediately. Same can be checked on 'My Account' section at any time. You can redeem this balance or a part of it by following the method given below. Go to My Account page.Click on Redeem & agree to the terms and conditions.You can choose Redeem by bank transfer if you wish the money to be deposited in your account online. Here, you will need to provide your Username, Bank Account Number ,IFSC Code, E-mail address and Mobile number which was used while registration Note: Email Verification is compulsory for all users who make a redemption request while registering for the first time. Failing to do so, User will not be able to reddem their Qzetos balance.",
      },
    ],
  },
];
const FrequentlyAsked = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState();
  const navigate = useNavigate();
  const p = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [p]);
  useEffect(() => {
    axios.get("/getFaqs").then((res) => {
      setLoading(false);
      setFaqs(res.data.faqs);
    });
  }, []);

  return (
    <div className="!bg-white">
      <NavBar />
      <div className="md:mt-[4rem] ">
        <img src={frequentylyAskedHero} className="w-full h-auto" />
      </div>
      <h1 className="text-2xl font-bold text-center mt-5">
        Frequently Asked Questions
      </h1>
      <div className="Accordion">
        <Container>
          {/* {data.map((val, index) => (
            <div key={index}>
              <Heading text={val.heading} />
              {val.accord.map((_val, _index) => (
                <Accordion key={_index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{_val.q}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{_val.a}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          ))} */}
          {loading ? (
            <div className="w-full flex justify-center my-5">
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <>
              {faqs?.map((val, index) => (
                <div key={index}>
                  <Heading text={val.faqTypeData[0].name} />
                  {val?.faqItems?.map((_val, _index) => (
                    <Accordion key={_index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{_val.question}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{_val.answer}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              ))}
            </>
          )}
          <div className="w-full flex justify-center pt-10 flex-col items-center gap-4">
            <p>
              Did you get the answer of what you looking for? Please contact us
              for any further assistance.{" "}
            </p>
            <button
              className="w-48 h-10 bg-green-400 text-white rounded-full border border-green-400 hover:bg-white hover:text-green-400"
              onClick={() => navigate("/static/contactus")}
            >
              Contact Us
            </button>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default FrequentlyAsked;
