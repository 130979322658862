import React, { useEffect, useState } from 'react'
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants, lifeLinesConstants } from '../../../actions/appConstants'

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined';
const DragDropVisualSimulatin = ({
    question,
    handleLeaveQuestion,
    handleNextQuestion,
    isLastQuestion
}) => {
    const dispatch = useDispatch()
    //
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines;
    //
    const [draggableImages, setdraggableImages] = useState([])
    const [boxData, setboxData] = useState([])
    // const [correctDragsForShowCorrectAnswer, setcorrectDragsForShowCorrectAnswer] = useState([])
    const onDragEnd = (e) => {
        //console.log(e)
        const { destination, source } = e;
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;
        if (destination.droppableId === source.droppableId) return;
        const _draggable_images = [...draggableImages];
        const _box_data = [...boxData]
        if (destination.droppableId === 'dragableimages') {
            const draggedValue = boxData[source.index]
            _box_data.splice(source.index, 1)
            setboxData(_box_data)
            _draggable_images.splice(destination.index, 0, draggedValue)
            setdraggableImages(_draggable_images)
        } else {
            if (question.forDragDropVisualSimulating.correct.length === boxData.length) {
                return dispatch({ type: globalConstants.ALERT, payload: { error: `${question.forDragDropVisualSimulating.correct.length} Images are allowed to drop!` } })
            }
            const draggedValue = _draggable_images[source.index];
            _draggable_images.splice(source.index, 1)
            setdraggableImages(_draggable_images)
            setboxData([...boxData, draggedValue])
        }
    }
    useEffect(() => {
        if (question) {
            let all = [...question.forDragDropVisualSimulating.correct, ...question.forDragDropVisualSimulating.incorrect]
            setdraggableImages(all);
            // setcorrectDragsForShowCorrectAnswer([...question.forDragDropVisualSimulating.correct])
        }
    }, [question])
    //console.log('box data==>', boxData)
    const handleValidation = () => {
        let correct = true;
        const getAllCorrectArrays = question.forDragDropVisualSimulating.correct.map((val) => val.name)
        const boxesCorrectArrat = boxData.map((val) => val.name)
        boxesCorrectArrat.forEach(v => {
            const isOkay = getAllCorrectArrays.includes(v)
            if (!isOkay) {
                correct = false;
            }
        })
        if (boxData.length < question.forDragDropVisualSimulating.correct.length) {
            correct = false;
        }
        //console.log('final result==>', correct)

        handleNextQuestion({ ...{ ...question, userSubmission: boxData }, correct })
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: boxData }, correct: false })
    }
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ... 
    //shwow correct answer handler
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            setdraggableImages([...question.forDragDropVisualSimulating.incorrect])
            setboxData([...question.forDragDropVisualSimulating.correct])
        }
    }, [clickedShowCorrectAnswer])
    //shwow correct answer handler
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className=''>
                    <Droppable droppableId="mainbox" direction='horizental'>
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                {...provided.droppableProps}
                                className={`shadow-indigo-500 shadow bg-white rounded-md p-5 min-h-[200px] w-full mb-5 flex items-center justify-center flex-wrap gap-4
                                ${snapshot.isDraggingOver ? 'bg-yellow-200' : ''}
                                `}>
                                {
                                    boxData.length === 0 ?
                                        <div className='flex flex-col items-center justify-center  opacity-70'>
                                            {
                                                !snapshot.isDraggingOver &&
                                                <>
                                                    <CloudUploadOutlinedIcon />
                                                    <h1 className='font-extrabold text-2xl'>Drag & Drop</h1>
                                                </>
                                            }
                                        </div>
                                        :
                                        boxData?.map((val, index) => (
                                            <Draggable key={val.name} draggableId={val.name} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='bg-bgblue w-max flex items-center justify-center flex-col p-2'>
                                                        <div className='h-24 w-24 overflow-hidden'>
                                                            <img
                                                                src={val.file}
                                                                alt=''
                                                                className='w-full h-full object-contain'
                                                            />
                                                        </div>
                                                        <h1 className='text-white'>{val.name}</h1>
                                                        <DragHandleOutlinedIcon className='!text-white' />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                }
                                {provided.placeholder}
                                <div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                    {/* drageable images... */}
                    <div>
                        <Droppable droppableId="dragableimages" direction='horizental'>
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    // style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
                                    {...provided.droppableProps}
                                    className='flex items-center mt-10 justify-center flex-wrap overflow-auto gap-4 bg-indigo-100 w-full min-h-[200px]'>
                                    {
                                        draggableImages.map((val, index) => (
                                            <Draggable key={val.name} draggableId={val.name} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className='bg-bgblue w-max flex items-center justify-center flex-col p-2'>
                                                        <div className='h-24 w-24 overflow-hidden'>
                                                            <img
                                                                src={val.file}
                                                                alt=''
                                                                className='w-full h-full object-contain'
                                                            />
                                                        </div>
                                                        <h1 className='text-white'>{val.name}</h1>
                                                        <DragHandleOutlinedIcon className='!text-white' />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                </div>
            </DragDropContext>
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </>
    )
}

export default DragDropVisualSimulatin