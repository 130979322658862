import React, {useState} from 'react'
import FAQType from './FAQType'
function FAQTypes({types, setTypes}) {
  return (
    <div className='w-full'>
        {
            types?.map((val, index) => (
                <FAQType type = {val} key = {index} setTypes={setTypes} />
            ))

        }
    </div>
  )
}

export default FAQTypes