import React from 'react'
import styled from 'styled-components'
import PlansC from '../components/plansComponents/PlansC'
import Layout from './../components/Layout/Layout'
import PlansTabs from './../components/plansComponents/PlansTabs'
const Plans = () => {
  return (
    <Layout>
      <PlansTabs />
    </Layout>

  )
}
const Cont = styled.div`

`
export default Plans