import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants, lifeLinesConstants } from '../../../actions/appConstants'
const FillInTheBlanks = ({
    question,
    handleLeaveQuestion,
    handleNextQuestion,
    isLastQuestion
}) => {
    const dispatch = useDispatch()
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion,
    } = lifeLines
    const [selectedAnswer, setselectedAnswer] = useState('')
    const handleValidation = () => {
        if (!(selectedAnswer).trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly write your answer !" } })
        let correct = false;
        if (question.correctAnswer === selectedAnswer) {
            correct = true
        }
        handleNextQuestion({ ...{ ...question, userSubmission: selectedAnswer }, correct })
        setselectedAnswer('')
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: selectedAnswer }, correct: false })
    }
    useEffect(() => {
        if (question) {
            setselectedAnswer('')
        }
    }, [question])
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...
    //shwow correct answer handler
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            setselectedAnswer(question.correctAnswer)
        }
    }, [clickedShowCorrectAnswer])
    //shwow correct answer handler
    return (
        <Cont>
            <input
                value={selectedAnswer}
                onChange={(e) => setselectedAnswer(e.target.value)}
                className='border border-black  py-2 px-1 outline-none bg-transparent rounded-md w-full'
                placeholder='Write your answer!'
            />
            <div className='mt-20 p-2 flex items-center justify-end text-sm'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className=' py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw`lg:m-auto lg:w-[60%] w-full  p-3`}
`
export default FillInTheBlanks