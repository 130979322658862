import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../utils/myAxios'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import Pagination from '@mui/material/Pagination';
import AddPackage from './AddPackage'
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import NotFoundDataUi from '../../uiComponents/NotFoundDataUi'
const QzetosPackages = () => {
    const [open, setopen] = useState(false)
    const [laoding, setlaoding] = useState(false)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [reset , setReset] = useState(false)
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                console.log(reset, "This is reset")
                setlaoding(true)
                const res = await axios(`/getQzetosPlans?page=${page}&limit=${limit}`)
                if (res.status === 200) {
                    //console.log('questions==>', res.data)
                    setquestions(res.data.plans)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setlaoding(false)
            } catch (error) {
                setlaoding(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit, reset])
    return (
        <div>
            {
                laoding ?
                    <div className='flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <div className='flex items-center justify-end'>
                            <AddPackage open={open} setopen={setopen} setquestions={setquestions} />
                            <Button className='!my-2 rounded-xl' onClick={() => setopen(true)} variant="contained" startIcon={<AddIcon />}>
                                New Plan
                            </Button>
                        </div>
                        {
                            questions.length > 0 ?
                                <>
                                    <MyTable questions={questions} setquestions={setquestions} setReset={setReset} />
                                    <div className='flex justify-center px-5 pt-5'>
                                        <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                                    </div>
                                </>
                                :
                                <NotFoundDataUi
                                    text={`No subscriptions available`}
                                />                    
                        }
                    </>
            }
        </div>
    )
}

export default QzetosPackages