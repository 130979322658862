import React, { useState } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
// import TableModal from './TableModal'
const TableRow = ({ val, questions, setquestions }) => {
    const dispatch = useDispatch()
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleApproveAction = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            //
            const res = await axios.post(`/makepayouts/usingfundid/${val._id}`)
            const newQuestions = questions.filter(v => v._id !== val._id)
            setquestions(newQuestions)
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "Payout Request Approved Successfully!" }
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <>
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{new Date(val.updatedAt).toLocaleDateString()}</span>
                </td>
                <td class="py-3 px-6 text-center whitespace-nowrap">
                    <span>{val.playerEarning}</span>
                </td>
            </tr>
        </>
    )
}

export default TableRow