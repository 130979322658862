import React, {useState} from 'react'
import FaqQuestionAcordin from './FaqQuestionAcordin'

const Heading = ({ text }) => <h1 className='mt-5 font-bold text-xl  text-center mb-3'>{text}</h1>
function FaqQuestions({ questions, setFaqs }) {
    
    return (
        <div>
            {
                questions?.map((val, index) =>
                (<div key={index}>
                    <Heading text={val.faqTypeData[0].name} />
                    {
                        val?.faqItems?.map((_val, _index) => (
                            <FaqQuestionAcordin _val = {_val} key = {index} setFaqs={setFaqs}/>
                        ))
                    }
                </div>))
            }
        </div>
    )
}

export default FaqQuestions