import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../../utils/myAxios'
import { CircularProgress } from '@mui/material'
import { keepUserLoggedIn } from '../../../actions/auth.actions'
import { useDispatch } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
function AIQuestions() {
    const { subjects, auth } = useSelector(s => s)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [showIndex, setShowIndex] = useState(0)
    const [questions, setQuestions] = useState([])
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState({
        questionCategory: '',
        difficultyLevel: '',
        noOfQuestions: '',
        subject: '',
        topic: ''
    })
    const generateQuestions = async () => {
        setLoader(true)
        try {
            console.log(options)
            // setLoader(false)
            const res = await axios.post('/generateAiQuestions', { ...options, user: auth.data.user._id })
            if (res) {
                setOptions({
                    questionCategory: '',
                    difficultyLevel: '',
                    noOfQuestions: '',
                    subject: '',
                    topic: ''
                })
                setLoader(false)
            }
            console.log(res.data)
            setQuestions(res.data?.quiz?.length > 0 ? res.data.quiz : res.data)
            setOpen(true)
            dispatch(keepUserLoggedIn())
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    return (
        <div className='relative w-full flex flex-col'>
            {open && <div className='absolute w-[90%] max-w-[400px] inset-1/2 top-0 transform -translate-x-1/2 h-32 bg-white shadow-lg border rounded-lg flex justify-center items-center flex-col gap-4'>
                <p className='w-full flex justify-center items-center'>
                    You have succeccfully generated {questions.length} questions
                </p>
                <button className='px-2 h-9 flex items-center justify-center bg-green-400 text-white hover:text-green-400 hover:bg-white border border-green-400 rounded-full' onClick={() => setOpen(false)} >Okay</button>

            </div>}
            {loader ? <div className='w-full flex justify-center items-center py-10 flex-col'>
                <CircularProgress />
                <p className='text-center text-gray-500'>Please wait don't leave the page. It will take 30 - 40 sec to generate questions</p>
            </div> :

                <div className='w-full flex flex-col '>
                    <div className='w-full'>
                        <h1>Choose the options</h1>
                        <div className='grid grid-cols-2 w-full gap-4 py-5'>
                            <div className='w-full'  >
                                <label htmlFor="" >Question Type</label>
                                <select name="" id="" className="w-full h-9 px-2 border rounded-lg py-2" value={options.questionCategory} onChange={(e) => setOptions({ ...options, questionCategory: e.target.value })}>
                                    <option value="">Select the option</option>
                                    <option value="Multiple Choice">Multiple Choice</option>
                                    <option value="True False">True False</option>
                                    <option value="Fill in the blanks">Fill in the blanks</option>
                                </select>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="">Difficulty Level</label>
                                <select name="" id="" className='w-full h-9 px-2 border rounded-lg py-2' value={options.difficultyLevel} onChange={(e) => setOptions({ ...options, difficultyLevel: e.target.value })}>
                                    <option value="">Select the option</option>
                                    <option value="Easy">Easy</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Hard">Hard</option>
                                    <option value="Expert">Expert</option>
                                </select>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 w-full gap-4 py-5'>
                            <div className='w-full'>
                                <label htmlFor="">No of Question</label>
                                <input type="number" className='w-full h-9 px-2 border rounded-lg py-2' placeholder='No of questions' value={options.noOfQuestions} onChange={(e) => {
                                    if (Number(e.target.value) > auth.data.user.ai_questions) {
                                        setOptions({ ...options, noOfQuestions: auth.data.user.ai_questions })
                                    } else {
                                        setOptions({ ...options, noOfQuestions: Number(e.target.value) })
                                    }
                                }} />
                                <p className='w-full flex justify-end text-blue-600 text-xs'>Questions Available : {auth.data.user.ai_questions}</p>
                            </div>
                            <div className='w-full'>
                                <label htmlFor="">Subject</label>
                                <select name="" id="" className='w-full h-9 px-2 border rounded-lg py-2' value={options.subject} onChange={(e) => setOptions({ ...options, subject: e.target.value })} >
                                    <option value="">Select the option</option>
                                    {subjects.subjects.map((subject, i) => (
                                        <option key={i} value={subject.name}>{subject.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='w-full'>
                            <label htmlFor="">Topic</label>
                            <input type="text" className='w-full h-9 px-2 border rounded-lg py-2' placeholder='Topic' value={options.topic} onChange={(e) => setOptions({ ...options, topic: e.target.value })} />
                        </div>
                        <div className='w-full flex justify-center items-center py-10'>
                            <button className='px-2 h-9 bg-green-400 text-white border border-green-400 hover:text-green-400 hover:bg-white rounded-full' onClick={generateQuestions}>Generate</button>
                        </div>
                    </div>
                    {questions.length > 0 && <div className='w-full'>
                        <h1>Questions Generated</h1>
                        {
                            questions?.map((question, i) => (
                                <div className='w-full bg-slate-200 rounded-lg pb-2'>
                                    <div className='w-full flex bg-slate-200 rounded-lg p-3 text-base mt-3 justify-between'>
                                        <p className='' key={i}>{i + 1} - {question?.statement} </p>
                                        <span className=' cursor-pointer ' onClick={() => {
                                            if (showIndex === i) {
                                                setShowIndex(-1)
                                                return
                                            }
                                            setShowIndex(i)
                                        }}> <KeyboardArrowDownIcon />
                                        </span>
                                    </div>
                                    {showIndex === i &&
                                        <>
                                            {question?.options?.map((option, i) => (
                                                <p className='bg-white rounded-lg p-3 text-base m-2' >{option.option}</p>
                                            ))}
                                            <p className='bg-white rounded-lg p-3 text-base m-2' >Correct Answer : {question?.correctAnswer}</p>
                                        </>
                                    }

                                </div>
                            ))
                        }

                    </div>}
                </div>}
        </div>
    )
}

export default AIQuestions