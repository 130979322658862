import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import axios from '../../utils/myAxios'

// import CardModal from './CardModal'
// import axios from '../../utils/myAxios'
// //icons
// import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
// import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
// import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { unRegisterQuizzAction } from '../../actions/quizzSchedule.actions'
import { useNavigate } from 'react-router-dom'
import { globalConstants } from '../../actions/appConstants'
import first from '../../assets/prizez/1.svg'
import second from '../../assets/prizez/2.svg'
import thrid from '../../assets/prizez/3.svg'
// import { SiPytorchlightning } from 'react-icons/si'
// import { BsFillCalendarRangeFill } from 'react-icons/bs'
// import { HiOutlineWifi } from 'react-icons/hi'

// import dollar from '../../assets/layout/dollar.png'
// import hand from '../../assets/layout/hand.png'
// import wallet from '../../assets/layout/wallet.png'
// //icons
// import { SiBuymeacoffee } from 'react-icons/si'
// import { MdSchool } from 'react-icons/md'
// import { MdSubtitles } from 'react-icons/md'
// import { BsPatchQuestionFill } from 'react-icons/bs'
// import { MdCategory } from 'react-icons/md'
// import { FaCoins } from 'react-icons/fa'
// //random color
// const randColors = [
//     'bg-red-600', 'bg-blue-600', 'bg-orange-600', 'bg-lime-600', 'bg-indigo-600',
//     'bg-fuchsia-600', 'bg-purple-600', 'bg-violet-600', 'bg-cyan-600',
// ]
//random color
// const Icons = ({ total, icon, color, price }) => {
//     return (
//         <div className='flex items-center gap-x-3'>
//             <Avatar className={`!text-white `} style={{
//                 background: color ? color : ''
//             }}
//                 sx={{ width: 24, height: 24 }}
//             >
//                 {icon}
//             </Avatar>
//             <h1 className='text-xs whitespace-pre opacity-50 !text-black font-bold '>{price ? `$ ${total}` : total}</h1>
//         </div>
//     )
// }
const QuizzCard = ({ quizz, user, handleUpdatedQuizz, index }) => {

    const { auth } = useSelector(s => s)

    const [timeForCard, settimeForCard] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [btnText, setBtnText] = useState('')
    const [open, setopen] = useState(false)
    const [bgImage, setBgImage] = useState()
    const [dummy, setDummy] = useState('https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8dGVjaG5vbG9neXxlbnwwfDB8MHx8&auto=format&fit=crop&w=600&q=60')
    useEffect(()=>{
        const getCatImage = () => {
            axios.get(`/getsubject/${quizz.subject}`)
                .then(body => {
                    setBgImage(body.data.image)
                })
                .catch(err => err)
        }
        getCatImage()
    } , [quizz])

    const [timer, settimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    const cardInit = {
        play: false,
        timesup: false,
        register: false,
        unregister: false,
    }
    const [cardStates, setcardStates] = useState(cardInit)
    // const { auth } = useSelector(state => state)
    const [image, setimage] = useState(quizz?.createdBy?.profileImage)
    useEffect(async () => {
        const t = setTimeout(async () => {
            const fun = () => {
                const str2 = quizz.time
                const str1 = new Date()
                var diff = Date.parse(str2) - Date.parse(str1);
                return isNaN(diff) ? NaN : {
                    diff: diff,
                    ms: Math.floor(diff % 1000),
                    s: Math.floor(diff / 1000 % 60),
                    m: Math.floor(diff / 60000 % 60),
                    h: Math.floor(diff / 3600000 % 24),
                    d: Math.floor(diff / 86400000)
                }
            }
            const d = fun()
            // console.log('timer==>', d)
            settimer({
                days: d?.d >= 0 ? d.d : 0,
                hours: d?.h >= 0 ? d.h : 0,
                minutes: d?.m >= 0 ? d.m : 0,
                seconds: d?.s >= 0 ? d.s : 0
            })
            //for reistered and times up or time of playing
            if (d.d <= 0 && d.h <= 0 && d.m <= 0 && d.s <= 0) {
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    const s_time = new Date(quizz.time)
                    s_time.setMinutes(s_time.getMinutes() + Number(quizz.duration))
                    const c_time = new Date().getTime()
                    if (s_time.getTime() < c_time) {
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    } else {
                        try {
                            if (quizz.resheduleAllow) {
                                // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            } else {
                                setcardStates({
                                    ...cardInit,
                                    play: true
                                })
                            }
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    }
                } else {
                    if (quizz.resheduleAllow) {
                        try {
                            // const _res_res = await axios.post(`/varify/minimumnumberofseats/${quizz._id}`)
                            // handleUpdatedQuizz(_res_res.data.quizz)
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    } else {
                        try {
                            // if (quizz.status !== 'closed') {
                            //     const its_obj = { status: 'closed' }
                            //     const _res_res = await axios.post(`/update/quizzstatus/${quizz._id}`, {
                            //         ...its_obj
                            //     })
                            //     handleUpdatedQuizz(_res_res.data.quizz)
                            // }
                        } catch (error) {
                            // dispatch({
                            //     type: globalConstants.ALERT,
                            //     payload: { error: error.message }
                            // })
                        }
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    }
                }
            }
            //for reistered and times up or time of playing
            //for registered unregistered
            if (d.d > 0 || d.h > 0 || d.m > 0 || d.s > 0) {
                if (quizz.registerdUsers.find(u => u.user === user)) {
                    setcardStates({
                        ...cardInit,
                        unregister: true
                    })
                } else {
                    setcardStates({
                        ...cardInit,
                        register: true
                    })
                }
            }
            //for registered unregistered
            //increase the days if the minimum numbers of people are not matched
        }, 1000);
        return () => clearInterval(t)
    }, [timer])
    const handleRegist = () => {
        setopen(true)
    }
    const handleUnRegister = async () => {
        await dispatch(unRegisterQuizzAction({ quizzId: quizz._id, quizz }, handleUpdatedQuizz))
    }
    const handleNavigate = () => {
        if (quizz.isSchoolTypeQuizz === true) return;
        navigate(`/singlequizz/detailscreen/${quizz._id}`, { state: { isPastQuiz: true } })
    }

    useEffect(() => {
        
        if (quizz.createdBy.followers.findIndex((u) => u === user) !== -1) {
            setBtnText("Unfollow")
            
        }
        else {
            setBtnText("Follow")
        }
    }, [])
    const handleFollow = async () => {
        try {
            
            if (quizz.createdBy.followers.findIndex((u) => u === user) !== -1 || btnText === "UnFollow") {
                let res = await axios.post('/follower/remove', {
                    user: user,
                    f_user: quizz.createdBy._id
                })
                if (res) {
                    
                    setBtnText("Follow")
                }
                
            }
            else {
                const res2 = await axios.post('/follower/add', {
                    user: user,
                    f_user: quizz.createdBy
                })
                if (res2) {

                    setBtnText("UnFollow")
                }



            }

        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        if (quizz) {
            const toReturn = moment(quizz.time).format(' Do MMM').split(' ')
            settimeForCard(toReturn)
        }
    }, [quizz])
    return (

       
        <div className='w-full hover:scale-105 transform transition-all duration-300'>
            {(quizz) && <div className="card cursor-pointer " >
                {/* {console.log('quizz', quizz)} */}
                <div className="half_1 border-b-2 border-slate-500 relative" style={{ backgroundImage: `url(${bgImage ? bgImage : dummy})` }}>
                    <div className='w-full flex flex-col justify-center px-3'>


                        {/* <div className="w-full flex justify-end">
                            <button className='w-16 h-6 bg-yellow-400 border rounded-full text-[10px] text-white' onClick={() => { handleFollow() }}>{btnText}</button>
                        </div> */}
                        <div className="flex w-full justify-center">
                            <Avatar

                                src={image}
                                sx={{ width: 55, height: 55 }}
                                onClick={() => { navigate(`/following/profile/${quizz.createdBy._id}`) }}
                                className='cursor-pointer ring ring-green-400 ring-offset-2 absolute top-[60%] '
                            />
                        </div>

                    </div>


                </div>
                <div className="half_2 pt-5">
                    <h2 className='text-sm py-2' onClick={handleNavigate}>Quiz Title : {quizz.title} </h2>
                    {/* <h5 className='text-xs' onClick={handleNavigate}>{quizz.category.name.replace('_', ' ')}</h5> */}
                    {/* <ul>
                    <li><h4>{moment(quizz.time).format(" h:mm a")}</h4><span>Start Time</span></li>
                    <li><h4>
                        {
                            quizz.isDurationBound ?
                                moment(quizz.durationBoundEndTime).format(" h:mm a")
                                :
                                moment(quizz.durationDateComplete).format("h:mm a")
                        }
                    </h4><span>End Time</span></li>
                </ul> */}
                    <span className=" text-xs hover:underline-offset-1" onClick={() => { navigate(`/following/profile/${quizz.createdBy._id}`) }}><strong>Quiz Master</strong> : {quizz?.createdBy?.userName}</span>
                    
                    <div className='grid grid-cols-3 px-5 text-[#BD5E00] font-semibold h-[60px] mt-2'>
                        <div className='justify-self-end self-end relative'>
                            <img src={second} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5 text-slate-500' >10</p>
                        </div>
                        
                        <div className='justify-self-start self-start relative'>
                            <img src={first} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >10</p>
                        </div>
                        <div className='justify-self-end self-end relative'>
                            <img src={thrid} alt="" className='w-full ' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5 text-amber-800' >10</p>
                        </div>
                    </div>
                    {
                        (<><div className="px-2 h-6 my-5 flex items-center justify-center rounded-3xl bg-green-400 text-white cursor-pointer text-xs" onClick={() => navigate(`/playquizz/${quizz._id}`, { state: { user: user, isPastQuiz: true } })}> Play Quiz </div></>)

                    }



                </div>
            </div>}</div>
    )
}
// const MainCont = styled.div`
// ${tw`lg:col-span-9 col-span-10   text-black p-3 grid grid-cols-12   gap-2 cursor-pointer bg-white`}
// `
// const DateButtonsCont = styled.div`
// ${tw`lg:col-span-1 col-span-10 text-white p-3 space-y-1 w-full flex  items-center  justify-center  flex-col`}
// `
// const Cont = styled.div`
// ${tw`grid grid-cols-10 shadow-xl border border-gray-300 !rounded-xl overflow-hidden`}
// `
export default QuizzCard