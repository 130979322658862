import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux'
import { globalConstants } from '../../actions/appConstants';

import MuiAlert from '@mui/material/Alert';
// vertical
// horizontal
let vertical = "top"
let horizontal = "right"
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function MySnacBarError() {
    const handleAlert = () => {
        dispatch({
            type: globalConstants.ALERT,
            payload: {}
        })
    }
    const { alert } = useSelector(state => state)
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleAlert}
            >
                <CloseIcon fontSize="small" oncli />
            </IconButton>
        </React.Fragment >
    );
    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
            open={alert?.error ? true : false}
            autoHideDuration={6000}
            // onClose={handleClose}
            // message={alert?.error ? alert?.error : ''}
            onClose={handleAlert}
            action={action}
        >
            <Alert onClose={handleAlert} severity={alert?.error ? 'error' : ''} sx={{ width: '100%' }}>
                <span className='capitalize'>{alert?.error ? alert?.error : ''}</span>
            </Alert>
        </Snackbar >
    );
}