import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import PlaySchoolQuizzC from '../components/playSchoolQuizzComponents/PlaySchoolQuizzC'
const PlaySchoolQuizz = () => {
    const { qid } = useParams()
    const [quizzId, setquizzId] = useState('')
    useEffect(() => {
        if (qid) {
            setquizzId(qid)
        }
    }, [qid])
    // console.log(quizzId)
    return (
        <Cont>
            {
                
                    <PlaySchoolQuizzC />
                    
            }
        </Cont>
    )
}
const Cont = styled.div`

`
export default PlaySchoolQuizz