import React, { useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
import { updateQuizzStatusAction } from '../../../actions/quizzSchedule.actions'
import { useDispatch, useSelector } from 'react-redux'
import { questionsCategoryConstants, globalConstants } from '../../../actions/appConstants'
import { useNavigate } from 'react-router-dom'
import axios from '../../../utils/myAxios'

const TableRow = ({ val, questions, setquestions, adminQuestionsState, setshowUpdateQuiz }) => {
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const handleDisable = () => {
        dispatch(updateQuizzStatusAction(val._id, 'disable')).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleOpen = () => {
        dispatch(updateQuizzStatusAction(val._id, 'open')).then((resp) => {
            if (resp) {
                const newQuestions = questions.map(val => val._id === resp._id ? resp : val)
                setquestions(newQuestions)
            }
        })
    }
    const handleEdit = () => {
        setshowUpdateQuiz(val)
    }
    const handleCopy = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post(`/admin/copy/admin/quizzes/${val._id}/${auth.data.user._id}`)
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    const handleDelete = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.delete(`/admin/delete/admin/quizzes/${val._id}`)
            setquestions(q => q.filter(_q => _q._id !== val._id))
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} />
            <tr class="border-b border-gray-200 hover:bg-gray-100">
                {/* title */}
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{val.title}</span>
                </td>
                {/* type */}
                <td class="py-3 px-6 text-center">
                    <span>{val.type}</span>
                </td>
                {/* plyer earnings */}
                <td class="py-3 px-6 text-center">
                    <span>{(val?.playerEarning).toFixed(0)} Qzetos</span>
                </td>
                {/* tax on qzetos */}
                <td class="py-3 px-6 text-center">
                    <span>{(val?.taxQzetos).toFixed(0)} Qzetos</span>
                </td>
                {/* scheduler earning */}
                <td class="py-3 px-6 text-center">
                    {
                        ((auth.data.user._id == val.createdBy) || auth.data.user.isAdmin)
                            ?
                            <span>{val?.createrPercent ? (val.createrPercent).toFixed(0) : 0} Qzetos</span>
                            :
                            <span>----</span>
                    }
                </td>
                {/* date */}
                <td class="py-3 px-6 text-left whitespace-nowrap">
                    <span>{moment(val.createdAt).subtract(10, 'days').calendar()}</span>
                </td>
                {/* start time */}
                <td class="py-3 px-6 text-center">
                    <span>{moment(val.time).format("MMMM Do YYYY, h:mm:ss a")}</span>
                </td>
                {/* end time */}
                <td class="py-3 px-6 text-center">
                    {
                        val.isDurationBound ?
                            moment(val.durationBoundEndTime).format("MMMM Do YYYY, h:mm:ss a")
                            :
                            moment(val.durationDateComplete).format("MMMM Do YYYY, h:mm:ss a")
                    }
                    {/* <span>{moment(val.durationDateComplete).format("LT")}</span> */}
                </td>
                {/* no of questions */}
                <td class="py-3 px-6 text-center">
                    {
                        val.category.name === questionsCategoryConstants.ELIMINATOR_QIZ ?
                            <span>{val.eliminatedQuiz.questions.length}</span>
                            :
                            val.category.name === questionsCategoryConstants.MIX_TYPE_QUIZ ?
                                <span>{val.forMixTypeQuestions.length}</span>
                                :
                                <span>{val.questions.length}</span>
                    }
                </td>
                {/* registered user... */}
                <td class="py-3 px-6 text-center">
                    <span>{val?.registerdUsers?.length}</span>
                </td>
                {/* registration free */}
                <td class="py-3 px-6 text-center">
                    {
                        val.type === 'free' ?
                            <span>{val.requiredPointsForFree}</span>
                            :
                            <span>{val.price}</span>
                    } Qzetos
                </td>
                {/* category */}
                <td class="py-3 px-6 text-left">
                    <span>{(val?.category?.name).replace(/_/g, ' ')}</span>
                </td>
                {/* statuses */}
                <td class="py-3 px-6 text-center">
                    <span class="text-white bg-bgblue py-1 px-3 rounded-full text-xs capitalize">{val.status}</span>
                </td>
                {/* statuses */}

                <td class="py-3 px-6 text-center">
                    <button onClick={() => navigate(`/singlequizz/detailscreen/${val._id}`)} type='button' className='rounded-md text-white bg-bgblue py-1 px-2'>View Quiz</button>
                </td>
                {/* <td class="py-3 px-6 text-center">
                    <span>{(val.}</span>
                </td> */}
                {/* actions */}
                {
                    auth.data.user.isAdmin &&
                    (
                        <td class="py-3 px-6 text-center">
                            <div class="flex item-center justify-center space-x-3">
                                {
                                    val.status === 'open' ?
                                        <button onClick={handleDisable} className='border-none rounded  py-1 px-3 text-white bg-red-400 text-xs'>Disable</button>
                                        :
                                        val.status === 'disable' ?
                                            <button onClick={handleOpen} className='border-none rounded  py-1 px-3 text-white bg-green-400 text-xs'>Enable</button>
                                            :
                                            null
                                }
                                {
                                    adminQuestionsState === 'admin' &&
                                    <div className='space-x-3'>
                                        <button
                                            onClick={handleEdit}
                                            className='border-none rounded  py-1 px-3 text-white bg-red-400 text-xs'>Edit</button>
                                        <button
                                            onClick={handleCopy}
                                            className='border-none rounded  py-1 px-3 text-white bg-red-400 text-xs'>Copy</button>
                                        <button
                                            onClick={handleDelete}
                                            className='border-none rounded  py-1 px-3 text-white bg-red-400 text-xs'>Delete</button>
                                    </div>
                                }
                            </div>

                        </td>
                    )
                }
            </tr>
        </>
    )
}

export default TableRow