import { data } from 'autoprefixer'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { extrasConstancts, globalConstants } from '../actions/appConstants'
import { signUpActions } from '../actions/auth.actions'
import BGImage from './../assets/bg/result.svg'
import Logo from './../assets/logo/logo.png'
const SignUp = ({ isPopUp = false }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pass_text, setPass_text] = useState('')
    const [formData, setformDAta] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        userName: "",
        email_number: "",
        password: "",
        address: "",
        city: "",
        state: "",
        cnf_pass: '',
        reffereePersonCode: '',
        phone_number: ''
    })
    const [value, setValue] = useState()
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformDAta({
            ...formData,
            [name]: value
        })
        if (name === 'cnf_pass') {
            if (!(value === formData.password)) {
                setPass_text("Password does not match !")
            }
            else {
                setPass_text('')
            }
        }
        if (name === 'password') {
            if (value.length < 8) {
                setPass_text("Password should be atleast 8 characters long !")

            }
            else if (!(value.match(/[a-z]/gm))) {
                setPass_text("Password should contain a character !")

            }
            else if (!(value === formData.cnf_pass) && formData.cnf_pass) {
                setPass_text('Password does not match !')
            }
            else {
                setPass_text('')
            }
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setformDAta({ ...formData, phone_number: value })
        if (formData.password !== formData.cnf_pass) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'confirm password does not match' }
            })
            return;
        }
        if (!formData.email_number.includes('+') && !formData.email_number.includes('@')) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "Kindly Enter Valid Email or Number" }
            })
        }
        dispatch(signUpActions({ ...formData, phone_number: value }, navigate))
    }
    return (
        <Container isPopUp={isPopUp} BGImage={BGImage} sx = {{overfolowY : "scroll"}}>
            {/* {
                isPopUp ?
                    null
                    :
                    < img onClick={() => navigate('/')} className='absolute h-[45px] top-2 left-2 cursor-pointer' src={Logo} alt='' />
            } */}
            <MinCont isPopUp={isPopUp} sx = {{maxHeight : "fit-content"}}>
                <div className='flex items-center justify-center my-6'>
                    <img onClick={() => navigate('/')} className='w-[12rem] object-contain cursor-pointer' src={Logo} alt='' />
                </div>
                <h1 className='text-xl !mt-0 text-center capitalize font-semibold'>sign up</h1>
                <form className=' grid grid-cols-6 gap-x-4 gap-6' onSubmit={handleSubmit}>
                    {/* first name */}
                    {/* <div className='lg:col-span-2 col-span-6
                     space-y-2'>
                        <h1 className='font-bold capitalize'>firt name</h1>
                        <input
                            required
                            type='text'
                            value={formData.firstName}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your first name...'
                            name='firstName'
                        />
                    </div> */}
                    {/* middlename name */}
                    {/* <div className='lg:col-span-2 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>middle name</h1>
                        <input
                            required
                            type='text'
                            value={formData.middleName}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your middle name...'
                            name='middleName'
                        />
                    </div> */}
                    {/* last name */}
                    {/* <div className='lg:col-span-2 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>last name</h1>
                        <input
                            required
                            type='text'
                            value={formData.lastName}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your last name...'
                            name='lastName'
                        />
                    </div> */}
                    {/* user name */}
                    <div className='lg:col-span-3 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>username</h1>
                        <input
                            required
                            type='text'
                            value={formData.userName}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your username...'
                            name='userName'
                        />
                    </div>
                    {/* Email or Number */}
                    <div className='lg:col-span-3 col-span-6  space-y-2'>
                        <h1 className='font-bold capitalize'>Email</h1>
                        <input
                            required
                            type='email'
                            value={formData.email_number}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your email/number...'
                            name='email_number'
                        />
                    </div>


                    {/* address */}
                    {/* <div className='lg:col-span-2 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>address</h1>
                        <input
                            required
                            type='text'
                            value={formData.address}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your address...'
                            name='address'
                        />
                    </div> */}
                    {/* city */}
                    {/* <div className='lg:col-span-2 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>city</h1>
                        <input
                            required
                            type='text'
                            value={formData.city}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='City name...'
                            name='city'
                        />
                    </div> */}
                    {/* state */}
                    {/* <div className='lg:col-span-2 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>state</h1>
                        <input
                            required
                            type='text'
                            value={formData.state}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='State name...'
                            name='state'
                        />
                    </div> */}
                    <div className='lg:col-span-3 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>Phone No*</h1>
                        <PhoneInput
                            international
                            countryCallingCodeEditable={true}
                            limitMaxLength={true}
                            defaultCountry="IN"
                            name="phone_number"
                            value={value}
                            onChange={setValue}
                        />
                    </div>
                    {/* referee code.. */}
                    <div className='lg:col-span-3 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>Refrel Code*</h1>
                        <input
                            type='text'
                            value={formData.reffereePersonCode}
                            onChange={(e) => {
                                setformDAta({
                                    ...formData,
                                    reffereePersonCode: (e.target.value).toString()
                                })
                            }}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='referel code...'
                            name='reffereePersonCode'
                        />
                    </div>
                    
                    {/* password */}
                    <div className='lg:col-span-3 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>password</h1>
                        <input
                            required
                            type='password'
                            value={formData.password}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your password...'
                            name='password'
                        />
                    </div>
                    {/* confirm password */}
                    <div className='lg:col-span-3 col-span-6 space-y-2'>
                        <h1 className='font-bold capitalize'>confirm password</h1>
                        <input
                            required
                            type='password'
                            value={formData.cnf_pass}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your confirm password...'
                            name='cnf_pass'
                        />
                    </div>
                    {pass_text && <div className='lg:col-span-6 col-span-6 space-y-2'>
                        <p className='text-red-600'> * {pass_text}</p>
                    </div>}
                    <h1 className='text-sm w-[100%] m-auto col-span-6'>By creating an account you agree
                        <span className='text-blue-900 font-semibold cursor-pointer' onClick={() => navigate("/static/termsandcondition")}> terms of use </span>
                        and our
                        <span className='text-blue-900 font-semibold cursor-pointer' onClick={() => navigate("/static/privacypolicy")}> privacy policy.</span>
                    </h1>
                    <div className='
                    bg-blue-600 border-blue-600 text-white
                     col-span-6 relative w-[100%] ml-auto mr-auto border  p-5 rounded-md cursor-pointer'>
                        <button className='absolute top-0 left-0 bottom-0 right-0 font-bold text-center capitalize'>create account</button>
                    </div>
                    {/* if "isPopUp" is true than show the modal for the Sign In in the extras reducers*/}
                    {
                        isPopUp ?
                            <h1 className='text-center col-span-6 '>
                                Already have an account?
                                <span className='text-blue-900 cursor-pointer font-semibold uppercase'
                                    onClick={() => {
                                        dispatch({
                                            type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                            payload: false,
                                        })
                                        dispatch({
                                            type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                                            payload: true,
                                        })
                                    }
                                    }
                                >
                                    sign in
                                </span>
                            </h1>
                            :
                            <h1 className='text-center col-span-6 '>Already have an account? <span className='text-blue-900 cursor-pointer font-semibold uppercase' onClick={() => navigate('/login')}>sign in</span></h1>
                    }
                </form>
            </MinCont>
        </Container >
    )
}
const MinCont = styled.div`
${props => props.isPopUp ? tw`w-full px-5` : tw`w-[95%] lg:w-[55%] lg:mt-10 mt-28 p-5`}
${tw` bg-white space-y-10  rounded-xl   mb-10`}
`
const Container = styled.div`
${tw`relative  flex items-center justify-center`}
background-image:${props => props.isPopUp ? '' : `url(${props.BGImage})`} ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover;
`
export default SignUp