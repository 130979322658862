import React, { useState, useEffect } from "react";
import axios from "../utils/myAxios";
import MyDraftjs from "./PlateformSettingComponent/MyDraftJs";
import { useDispatch } from "react-redux";
import { globalConstants } from "../actions/appConstants";
function AboutInfo() {
  const allLinks = [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
    {
      name: "Question Bank",
      link: "/questionbank",
    },
    {
      name: "Schedule Quiz & Earn",
      link: "/schedulequiz",
    },
    {
      name: "my wallet",
      link: "/mywallet",
    },
    {
      name: "Profile",
      link: "/profile",
    },
    {
      name: "played quizzes",
      link: "/playedquizez",
    },
    {
      name: "Quiz Detail",
      link: "/singlequizz/detailscreen/:qid",
    },
    {
      name: "plans",
      link: "/plans",
    },
    {
      name: "not authorized",
      link: "/notauthorized",
    },
    {
      name: "user scheduled quizzes",
      link: "/userschedulesquizez/:uid",
    },
    {
      name: "payment histories",
      link: "/payments",
    },
    {
      name: "rigistered quizzes",
      link: "/registeredquizzes",
    },
    {
      name: "Quiz Library",
      link: "/quiz-library",
    },
    {
      name: "Ended Quiz",
      link: "/cancelledquizzes",
    },
    {
      name: "Quizmaster-Pro",
      link: "/quizmaster-pro",
    },
    {
      name: "Redemption",
      link: "/redemption",
    },
    {
      name: "Refferals",
      link: "/referral",
    },
    {
      name: "Upload excel sheets",
      link: "/importexcelsheets",
    },
    {
      name: "Manage users",
      link: "/usermanagement",
    },
    {
      name: "Admin schedules quizzes",
      link: "/adminschedulequiz",
    },
    {
      name: "Plateform settings",
      link: "/plateformsettings",
    },
    {
      name: "Categories",
      link: "/static/categories",
    },
    {
      name: "Quizzes",
      link: "/category/quizzes/:category",
    },
    {
      name: "Active Plans",
      link: "/user/subscriptions",
    },
    {
      name: "My Following",
      link: "/following/profile",
    },
    {
      name: "Quiz Masters",
      link: "/quizmasters",
    },
    {
      name: "Subjects",
      link: "/categories/subjects/:cid",
    },
    {
      name: "Redemptions",
      link: "/userredemption",
    },
  ];
  const [path, setPath] = useState();
  const [pathInfo, setPathInfo] = useState([]);
  const [aboutInfo, setAbout] = useState([]);
  const [richData, setrichData] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get("/getAbout")
      .then((body) => {
        if (body.data.data.length > 0) {
        }
        setAbout(body.data.data);
      })
      .catch((err) => err);
  }, []);
  const handlePath = (e) => {
    setPath(e.target.value);
    const index = aboutInfo.findIndex((item) => item.path === e.target.value);
    setrichData("");
    setrichData(aboutInfo[index].info);
  };
  const handleSubmit = () => {
    const getData = () => {
      axios
        .get("/getAbout")
        .then((body) => {
          setAbout(body.data.data);
        })
        .catch((err) => err);
    };
    axios
      .post("/newAbout", {
        path: path,
        info: richData,
      })
      .then((body) => {
        getData();
      })
      .catch((err) => err);
  };
  useEffect(() => {
    // console.log(richData)
  }, [richData]);

  return (
    <div className="w-full h-screen bg-white">
      <div className="flex items-center p-10">
        <span className="px-10">Select Path</span>
        {aboutInfo.length > 0 && (
          <select className="w-48 rounded-lg" onChange={(e) => handlePath(e)}>
            <option name={"test"} value={"none"} defaultValue>
              Select an option
            </option>
            {aboutInfo.map((item) => {
              return (
                <option name={item.path} value={item.path} id="">
                  {item.path}
                </option>
              );
            })}
          </select>
        )}
      </div>

      {path && (
        <div className="flex flex-col p-5 items-center">
          <div className="w-full">
            <MyDraftjs
              content={richData ? richData : ""}
              setContent={setrichData}
            />
          </div>
          <button
            className="w-48 h-12 bg-yellow-500 text-white rounded-xl text-xl my-5"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

export default AboutInfo;
