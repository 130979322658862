import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { getAllQuestionCategories } from '../../actions/question.actions'
import { quizzithonActions } from '../../actions/quizzithon.actions'
// import { DateTimePicker, TimePicker } from '@material-ui/pickers'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import QuizproMasterQuestionTableModal from '../ScheduleQuizComponents/QuizproMasterQuestionTableModal'
import DateTimePicker from '@mui/lab/DateTimePicker';
import TimePicker from '@mui/lab/TimePicker';
import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material'
import { DesktopDatePicker, MobileDatePicker } from '@mui/lab'
import MostCommon from '../ScheduleQuizComponents/MostCommon'
import Different from '../ScheduleQuizComponents/Different'
// import SchoolquestionTable from './SchoolQuestionsTable/SchoolQuestionTable'
import { globalConstants, questionsCategoryConstants, questionsCategoryConstants as types } from '../../actions/appConstants'
import { createQuizzScheduleAction } from '../../actions/quizzSchedule.actions'
import axios from '../../utils/myAxios'
import { useNavigate } from 'react-router-dom'
import { set } from 'lodash'
const Heading = ({ number, title }) => {
    return (
        <div className='flex items-center space-x-2 mb-5 mt-5'>
            <div className='h-7 w-7 bg-bgblue rounded-full flex items-center justify-center'>
                <span className='text-white font-bold'>{number}</span>
            </div>
            <h1 className='font-semibold capitalize'>{title}</h1>
        </div>
    )
}
const ScheduleQuizzComponent = ({ showUpdateQuiz, isSchoolQuiz, setValue ,quizIndex , isQuizzithonQuiz }) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(true)
    //scholl quizz
    const [schedulingType, setschedulingType] = useState('schedulequizz')
    //scholl quizz
    const { auth, question, extras, subjects , quizzithon} = useSelector(state => state)
    const dispatch = useDispatch()
    const [frontEndTime, setfrontEndTime] = React.useState(new Date());
    const [ReschedulefrontEndTime, ReschedulesetfrontEndTime] = React.useState(new Date());
    const initialData = {
        subject: "",
        title: "",
        createdBy: auth?.data?.user?._id ? auth.data.user._id : '',
        category: "",
        type:  '',
        price: 20,
        requiredPointsForFree: 0,
        date: new Date(),
        time: new Date(),
        duration: 0,
        minimumNumberOfSeats: 3,
        questions: [],
        spinWheal: {},
        eliminatedQuiz: {
            lifelines: {
                fiftyfifty: 1,
                showCorrectAnswer: 1,
                skipQuestion: 1
            }
        },
        questions_info: {
            count: 0,
            subjects: [
                {
                    cat: "",
                    subject: "",
                    count: 1
                }
            ]
        },
        isSchoolTypeQuizz: false,


        resheduleAllow: false,
        rescheduleTime: new Date(),
        rescheduleDate: new Date(),
        rescheduleDuration: 0,
        durationDateComplete: new Date(),
        ResheduledurationDateComplete: new Date(),


        tictactoeRows: 0,
        quizzSubject: "",

        isAdmin: (auth.data.user.isAdmin || auth.data.user.root) ? true : false,


        //duration bound
        isDurationBound: false,
        durationBoundMinutes: 0,

        //pending implementation
        durationBoundEndTime: new Date(),
        //pending implementation

        //duration bound
    }
    const [schedulerData, setschedulerData] = useState(initialData)
    useEffect(() => {
        setschedulerData(initialData)
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }, [quizIndex])
    
    const handleDuration = (newValue) => {
        const startTime = new Date(schedulerData?.time)
        const endTime = new Date(newValue)
        //console.log('difference in minutes==>', Math.round((endTime.getTime() - startTime.getTime()) / 60000))

        const calculateDurationComplete = new Date(schedulerData?.time)
        calculateDurationComplete.setMinutes(calculateDurationComplete.getMinutes() + Math.round((endTime.getTime() - startTime.getTime()) / 60000))

        const final = new Date(calculateDurationComplete).toISOString()
        //console.log('final==>', final)
        setschedulerData({
            ...schedulerData, duration: Math.round((endTime.getTime() - startTime.getTime()) / 60000),
            durationDateComplete: final,
        })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
        setfrontEndTime(newValue)
    };
    const handleDatePicker = (newValue) => {
        const checkIfNav = new Date(newValue)
        if (isNaN(checkIfNav.getTime())) return;
        const d = new Date(newValue).toISOString()
        //console.log('updated date==>', d)
        setschedulerData({ ...schedulerData, date: d })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }
    const handleStartTime = (newValue) => {
        const d = new Date(newValue).toISOString()
        let newDate = new Date();
        //do not allow to select the past time

        //do not allow to select the past time
        newDate.setDate(newDate.getDate())
        let durationBoundEndTime = new Date(newDate).toISOString()
        setschedulerData({ ...schedulerData, time: d, durationBoundEndTime: durationBoundEndTime })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }
    useEffect(() => {
        setOpen(false)
        dispatch(getAllQuestionCategories())
        let currentTime = new Date()
        currentTime.setDate(currentTime.getDate())

        setschedulerData({
            ...schedulerData,
            durationBoundEndTime: new Date(currentTime),
        })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }, [])
    const handleSubmit = () => {
        //console.log(schedulerData)
    }
    //console.log('quiz subject===>', schedulerData?.quizzSubject)
    //
    const handleSaveSchoolQuizz = () => {
        let _obj = {
            ...schedulerData,
        }

        _obj.isSchoolTypeQuizz = true
        if (_obj.questions.length === 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "no questions added!" } })
        if (!(_obj.title).trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "title is required!" } })
        if (!_obj.category) return dispatch({ type: globalConstants.ALERT, payload: { error: "no category added!" } })
        if (!_obj.isSchoolTypeQuizz) return dispatch({ type: globalConstants.ALERT, payload: { error: "isSchoolTypeQuizz is false..." } })
        //time validation
        const date = new Date(schedulerData?.date)
        const time = new Date(schedulerData?.time)
        //
        const date_month = date.getMonth()
        const date_date = date.getDate()
        //
        const currDate = new Date()
        _obj.minimumNumberOfSeats = 0;
        if ((date.getDate() < currDate.getDate()) && (date.getMonth() === currDate.getMonth())) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "select future date!" }
            })
        }
        if (date_month === currDate.getMonth() && date_date === currDate.getDate()) {
            if (time < currDate) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "select future time!" }
                })
            }
        }
        //for past Quiz End Time
        if (schedulerData?.isDurationBound === false) {
            //console.log('in if==>')
            const endtime = new Date(schedulerData?.durationDateComplete)
            if (endtime < currDate) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "selct future end time!" }
                })
            }
            if (endtime <= time) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "end time must be greater than start time." }
                })
            }
        } else {
            let newEndTime = new Date(_obj.time)
            newEndTime.setMonth(date.getMonth())
            newEndTime.setDate(date.getDate())
            let endtime = new Date(newEndTime)
            endtime.setMinutes(endtime.getMinutes() + Number(_obj.durationBoundMinutes))
            endtime = new Date(endtime)
            _obj.durationBoundEndTime = endtime
            if (endtime < currDate) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "selct future end time!" }
                })
            }
        }
        //for past Quiz End Time
        //time validation
        //for spinWheal type
        //set type to multiple questions...
        const _q_category = question.questionsCategories.find(_c => _c.name === schedulerData?.category)
        _obj.category = _q_category._id;
        //set type to multiple questions...
        if (schedulerData?.category === questionsCategoryConstants.SPIN_A_WHEEL_QUIZ) {
            const differentSubjects = {}
            let spinWheal = []
            _obj.questions.forEach(q => {
                //console.log(q)
                differentSubjects[q.subject] = q.subject
            })
            Object.values(differentSubjects).forEach((v) => {
                let object = {
                    subject: "",
                    data: []
                }
                object.data = _obj.questions.filter(fq => fq.subject === v)
                object.subject = v;
                spinWheal = [...spinWheal, object]
            })
            _obj.spinWheal = spinWheal;
        } else if (schedulerData?.category === questionsCategoryConstants.MIX_TYPE_QUIZ) {
            _obj.forMixTypeQuestions = [..._obj.questions]
        } else if (schedulerData?.category === questionsCategoryConstants.ELIMINATOR_QIZ) {
            _obj.eliminatedQuiz = { ..._obj.eliminatedQuiz, questions: _obj.questions }
        } else if (schedulerData?.category === questionsCategoryConstants.TIC_TAC_TOE) {
            if (_obj.questions.length < 9 || _obj.questions.length > 9) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "Kindly select 9 Questions only!" }
                })
            }
            _obj.forMixTypeQuestions = [..._obj.questions]
        } else {
            const _ids = [];
            _obj.questions.forEach(vvv => {
                _ids.push(vvv._id)
            })
            _obj.questions = _ids
        }
        //console.log('final object to create quizz ===>', _obj)
        dispatch(createQuizzScheduleAction(_obj, setValue))
    }
    //
    //reschedule function====
    const ReschedulehandleDatePicker = (newValue) => {
        const checkIfNav = new Date(newValue)
        if (isNaN(checkIfNav.getTime())) return;
        const d = new Date(newValue).toISOString()
        setschedulerData({ ...schedulerData, rescheduleDate: d })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }
    const ReschedulehandleStartTime = (newValue) => {
        const d = new Date(newValue).toISOString()
        setschedulerData({ ...schedulerData, rescheduleTime: d })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }
    const ReschedulehandleDuration = (newValue) => {
        const startTime = new Date(schedulerData?.rescheduleTime)
        const endTime = new Date(newValue)
        //console.log('difference in minutes==>', Math.round((endTime.getTime() - startTime.getTime()) / 60000))

        const calculateDurationComplete = new Date(schedulerData?.rescheduleTime)
        calculateDurationComplete.setMinutes(calculateDurationComplete.getMinutes() + Math.round((endTime.getTime() - startTime.getTime()) / 60000))

        const final = new Date(calculateDurationComplete).toISOString()

        setschedulerData({
            ...schedulerData, rescheduleDuration: Math.round((endTime.getTime() - startTime.getTime()) / 60000),
            ResheduledurationDateComplete: final
        })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
        ReschedulesetfrontEndTime(newValue)
    };
    //reschedule function====
    //update type
    useEffect(() => {
        
        setschedulerData({ ...schedulerData, type: quizzithon.quizzithon.type })
        dispatch({
            type : quizzithonActions.UPDATE_QUIZ, 
            payload : {
                quizIndex : quizIndex,
                quiz : {...schedulerData , isQuizzithonQuiz : true}
            }
        })
    }, [quizzithon.type])
    //update request
    useEffect(() => {
        if (showUpdateQuiz) {
            //console.log('showUpdateQuiz==>', showUpdateQuiz)
            setschedulerData({
                ...schedulerData,
                title: showUpdateQuiz.title,
                type: showUpdateQuiz.type,
                // category: showUpdateQuiz.name,
                price: showUpdateQuiz.price,
                requiredPointsForFree: showUpdateQuiz.requiredPointsForFree,
                date: new Date(showUpdateQuiz.date),
                minimumNumberOfSeats: showUpdateQuiz.minimumNumberOfSeats,
                quizzSubject: showUpdateQuiz.subject,
                resheduleAllow: showUpdateQuiz.resheduleAllow,
            })
            dispatch({
                type : quizzithonActions.UPDATE_QUIZ, 
                payload : {
                    quizIndex : quizIndex,
                    quiz : {...schedulerData , isQuizzithonQuiz : true}
                }
            })
        }
    }, [showUpdateQuiz])
    //update request
    const handleUpdateQuiz = async () => {
        try {
            const {
                title,
                type,
                price,
                requiredPointsForFree,
                date,
                time,
                duration,
                minimumNumberOfSeats,
                resheduleAllow,
                rescheduleTime,
                rescheduleDate,
                rescheduleDuration,
                durationDateComplete,
                ResheduledurationDateComplete,
                quizzSubject
            } = schedulerData
            if (!title.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "title is required!" } })
            if (!type.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "type is required!" } })
            if (type === 'free' && requiredPointsForFree < 20) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "atleast 20 registration fee is required!" }
            })
            if (type === 'paid' && price < 20) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "atleast 20 registration fee is required!" }
            })
            if (minimumNumberOfSeats <= 0) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'minimum number of seats should be greater than 0' }
            })
            if (!quizzSubject) return dispatch({ type: globalConstants.ALERT, payload: { error: "subject is required!" } })
            const _obj = {
                title,
                type,
                price,
                requiredPointsForFree,
                date,
                time,
                duration,
                minimumNumberOfSeats: Number(minimumNumberOfSeats),
                resheduleAllow,
                rescheduleTime,
                rescheduleDate,
                rescheduleDuration,
                durationDateComplete,
                ResheduledurationDateComplete,
                quizzSubject
            }
            //console.log('updatedquizz', _obj)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post(`/admin/update/admin/quiz/${showUpdateQuiz._id}`, {
                ..._obj
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg, }
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response.data.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    //
    useEffect(() => {
        if (isSchoolQuiz) {
            setschedulingType('scheduleschoolquizz')
            //console.log('first')
        } else {
            setschedulingType('schedulequizz')
        }
    }, [isSchoolQuiz])
    return (
        <div>
           
            <Cont>
                <h1 className='text-bgblue text-lg font-semibold'>Schedule new quiz</h1>
                <Heading number={1} title='Basic detail' />
                
                <div>
                    <h1 className=''>Title</h1>
                    <input
                        type='text'
                        placeholder='Enter title...'
                        value={schedulerData?.title}
                        onChange={(e) => {setschedulerData({ ...schedulerData, title: e.target.value })
                        dispatch({
                            type : quizzithonActions.UPDATE_QUIZ, 
                            payload : {
                                quizIndex : quizIndex,
                                quiz : {...schedulerData , isQuizzithonQuiz : true}
                            }
                        })
                    }}
                        className='border border-black w-full py-2 outline-none px-2 rounded-md'
                    />
                </div>
                
                {
                    schedulingType !== 'scheduleschoolquizz' &&
                    <div className='grid grid-cols-1 gap-7 mt-5
            lg:grid-cols-3
            '>
                        
                        {
                            
                            <div>
                                <h1 className=''>Categories</h1>
                                <select
                                    value={schedulerData?.category}
                                    onChange={(e) => {
                                        
                                        setschedulerData({ ...schedulerData, category: e.target.value })
                                        dispatch({
                                            type : quizzithonActions.UPDATE_QUIZ, 
                                            payload : {
                                                quizIndex : quizIndex,
                                                quiz : {...schedulerData , isQuizzithonQuiz : true}
                                            }
                                        })
                                    }
                                    }
                                    className='border border-black w-full py-2 outline-none px-2 rounded-md capitalize'
                                >
                                    <option value=''>select categories</option>
                                    {
                                        question?.questionsCategories.length > 0 &&
                                        question?.questionsCategories.map((val, index) => (
                                            <option key={index} value={val.name}>{(val.name).replace(/_/g, ' ')}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        }
                        
                        < div className=''>
                            <h1 className=''>Subject</h1>
                            <select
                                value={schedulerData?.quizzSubject}
                                onChange={(e) => {

                                    setschedulerData({
                                        ...schedulerData,
                                        quizzSubject: e.target.value,
                                        subject: e.target.value,
                                    })
                                    dispatch({
                                        type : quizzithonActions.UPDATE_QUIZ, 
                                        payload : {
                                            quizIndex : quizIndex,
                                            quiz : {...schedulerData , isQuizzithonQuiz : true}
                                        }
                                    })
                                }}
                                className='border border-black w-full py-2 outline-none px-2 rounded-md capitalize'
                            >
                                <option value=''>select subject</option>
                                {
                                    subjects.subjects.map((val, index) => (
                                        <option key={index} value={val.name}>{val.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                }
                <Heading number={2} title='Timing And Audience' />
                {/*  */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
                        <DesktopDatePicker
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={schedulerData?.date}
                            onChange={handleDatePicker}
                            minDate={new Date()}
                            renderInput={(params) => <TextField
                                {...params} />}
                        />
                        <TimePicker
                            label="Start time"
                            value={schedulerData?.time}
                            onChange={handleStartTime}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        {
                            schedulerData?.isDurationBound === false &&
                            <TimePicker
                                label="End Time"
                                value={frontEndTime}
                                onChange={handleDuration}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        }
                    </div>
                    {/* reschedule timing... */}
                    {
                        
                       
                    }
                </LocalizationProvider>
                {/*  */}
                <div className='grid lg:grid-cols-4 grid-cols-1 gap-7 mt-5'>
                    {
                        
                    }
                    {
                        schedulingType !== 'scheduleschoolquizz' &&
                        <>
                           
                            {
                                schedulerData?.category === types.TIC_TAC_TOE &&
                                <div className=''>
                                    <h1 className=''>Order</h1>
                                    <select
                                        value={schedulerData?.tictactoeRows}
                                        onChange={(e) => {setschedulerData({ ...schedulerData, tictactoeRows: e.target.value })
                                        dispatch({
                                            type : quizzithonActions.UPDATE_QUIZ, 
                                            payload : {
                                                quizIndex : quizIndex,
                                                quiz : {...schedulerData , isQuizzithonQuiz : true}
                                            }
                                        })
                                    }}
                                        className='border border-black w-full py-2 outline-none px-2 rounded-md capitalize'
                                    >
                                        <option value={0}>select sequence</option>
                                        {
                                            [3].map((val, index) => (
                                                <option key={index} value={val}>{`${val} x ${val}`}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                        </>
                    }
                </div>
                {
                    (true) &&
                    <>
                        <Heading number={3} title='Question detail' />
                        <div>
                            {
                                (schedulerData?.category === types.FILL_IN_THE_BLANKS ||
                                    schedulerData?.category === types.TRUE_FALSE ||
                                    schedulerData?.category === types.MATCH_THE_PAIR ||
                                    schedulerData?.category === types.IDENTIFY_IMAGE_VIDEO_OR_VOICE ||
                                    schedulerData?.category === types.PARAGRAPH_ORDERING_QUIZ ||
                                    schedulerData?.category === types.SEQUENCING_QUIZ ||
                                    schedulerData?.category === types.MULTIPLE_QUESTIONS ||
                                    schedulerData?.category === types.CLOSED_ENDED ||
                                    schedulerData?.category === types.SENTENCE_SEQUENCE ||
                                    schedulerData?.category === types.SORTING_CLASSIFICATION ||
                                    schedulerData?.category === types.DRAG_DROP_VISUAL_SIMULATING ||
                                    schedulerData?.category === types.DRAD_DROP_FILL_UP_THE_BLANKS ||
                                    schedulerData?.category === types.CROSS_WORDS
                                ) &&
                                <MostCommon ____durTime={schedulerData?.durationBoundMinutes} schedulerData={schedulerData} setschedulerData={setschedulerData} Heading={Heading} isQuizzithonQuiz = {isQuizzithonQuiz} quizIndex = {quizIndex} />
                            }
                            {
                                (
                                    schedulerData?.category === types.SPIN_A_WHEEL_QUIZ ||
                                    schedulerData?.category === types.ELIMINATOR_QIZ ||
                                    schedulerData?.category === types.MIX_TYPE_QUIZ ||
                                    schedulerData?.category === types.TIC_TAC_TOE
                                ) &&
                                <Different ____durTime={schedulerData?.durationBoundMinutes} schedulerData={schedulerData} setschedulerData={setschedulerData} Heading={Heading} isQuizzithonQuiz = {isQuizzithonQuiz} quizIndex = {quizIndex}/>
                            }
                        </div>
                    </>
                }
                {
                    (schedulingType === 'scheduleschoolquizz' && (showUpdateQuiz === null || showUpdateQuiz === undefined)) &&
                    <div className='flex items-center justify-end'>
                        <button onClick={handleSaveSchoolQuizz} className='bg-bgblue text-white  rounded-xl py-1 px-3 border-none outline-none'>Save</button>
                    </div>
                }
                {
                    showUpdateQuiz &&
                    <div className='flex items-center justify-end'>
                        <button onClick={handleUpdateQuiz} className='bg-bgblue text-white  rounded-xl py-1 px-3 border-none outline-none'>Update Quiz</button>
                    </div>
                }
            </Cont >
        </div >
    )
}
const Cont = styled.div`
${tw`bg-white p-3 rounded-xl`}
`
export default ScheduleQuizzComponent