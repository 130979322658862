import React from 'react'
import TabsForMain from './tabsForMain/TabsForMain'
const MainTabs = ({
    cat,
    sub
}) => {
    return (
        <div className='mt-5'>
            <TabsForMain
                cat={cat}
                sub={sub}
            />
        </div>
    )
}

export default MainTabs