import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { varifyUser, resendCodeAction } from '../../actions/auth.actions'
import BGImage from '../../assets/bg/result.svg'
import Logo from '../../assets/logo/logo.png'
const VarifyUser = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const extras = useSelector(state => state.extras)
    const { auth } = useSelector(state => state)
    const [formData, setformDAta] = useState({
        email_number: ""
    })
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformDAta({
            ...formData,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(varifyUser(formData, navigate))
    }
    const handleResendCode = () => {
        if (extras.extraLoginData === null) {
            return navigate('/login', { replace: true })
        }
        dispatch(resendCodeAction(extras.extraLoginData))
    }
    useEffect(() => {
        if (auth.authenticate) {
            navigate('/dashboard', { replace: true })
        }
    }, [auth.authenticate])
    return (
        <Container BGImage={BGImage}>

            <MinCont>
                <div className='flex items-center justify-center my-6'>
                    <img onClick={() => navigate('/')} className='w-[12rem] object-contain cursor-pointer' src={Logo} alt='' />
                </div>
                <h1 className='text-xl !mt-0 text-center capitalize font-semibold'>Verify yourself</h1>
                <h3 className='text-center px-5 text-green-400'>Please enter the code you received on the provided Email and Phone No</h3>
                <form className='space-y-7' onSubmit={handleSubmit}>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <h1 className='font-bold'>Enter code</h1>
                        <input
                            value={formData.code}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter code ...'
                            name='code'
                            onChange={onChangeHandler}
                            type='text'
                            required
                        />
                    </div>
                    <div className='hover:bg-blue-700 hover:text-white hover:border-blue-700 relative  w-[90%] ml-auto mr-auto border border-black p-5 rounded-md cursor-pointer'>
                        <button type='submit' className='absolute top-0 left-0 bottom-0 right-0 font-bold text-center'>Verify</button>
                    </div>
                    <h1 className='text-center pb-4'>Don't get a varification code? <span className='text-blue-900 cursor-pointer font-semibold uppercase' onClick={handleResendCode}>resend code</span></h1>
                </form>
            </MinCont>
        </Container>
    )
}
const MinCont = styled.div`
${tw`w-[30%] bg-white space-y-10 p-2 rounded-xl`}
`
const Container = styled.div`
${tw`relative h-screen flex items-center justify-center`}
background-image: url(${BGImage}) ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover;
`
export default VarifyUser