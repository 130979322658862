import React, { useEffect, useState } from 'react'
import { Avatar, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from '../utils/myAxios'



function UserCard({ item }) {
    const { auth } = useSelector(s => s)
    const [btnText, setBtnText] = useState('')
    const navigate = useNavigate()
    const [userData, setUserData] = useState(item)
    const [loader , setLoader] = useState(false)
    useEffect(() => {
        
        if (userData.followers.findIndex((user) => user === auth.data.user._id) !== -1) {
            
            setBtnText("UnFollow")
        }
        else {
            setBtnText("Follow")
        }
    }, [userData])
    const getUserData = async () => {
        try {
            let res = await axios.post('/follower/getFollowingData', {
                user: item._id
            })
            if (res) {
                setUserData(res.data._user)
                
            }
            // console.log(res)
        } catch (error) {
            // console.log(error)
        }
    }
    const handleFollow = async () => {
        try {
            setLoader(true)
            if (userData.followers.findIndex((user) => user === auth.data.user._id) !== -1) {
                let res = await axios.post('/follower/remove', {
                    user: auth.data.user._id,
                    f_user: item._id
                })
                if(res){
                    setLoader(false)
                    getUserData()
                }
                
                setBtnText("UnFollow")
            }
            else {
                const res2 = await axios.post('/follower/add', {
                    user: auth.data.user._id,
                    f_user: item._id
                })
                if (res2) {
                    setLoader(false)
                    setBtnText("UnFollow")
                }
                getUserData()


            }

        } catch (error) {
            // console.log(error)
        }
    }
    return (
        <>
            {userData ? <div className="flex flex-col w-full bg-slate-50 items-center p-5 rounded-md drop-shadow-md m-3">
                {/* <div className="border-2 rounded-full w-24 h-24 bg-gray-500 ">
                <img className='w-full rounded-full' src= {item.profileImage} alt="" />
            </div> */}
                <Avatar

                    src={userData.profileImage}
                    sx={{ width: 60, height: 60 }}
                    onClick={() => { navigate(`/following/profile/${userData._id}`) }}
                    className='cursor-pointer ring ring-green-400 ring-offset-2'
                />
                <div className="w-[95%] pt-3 flex flex-col items-center gap-2">
                    <span className='font-semibold text-sm md:text-md'>{userData.userName}</span>
                    <span className='text-sm flex justify-between gap-2'>
                        <span>Followers : {userData.followers.length}</span>
                        {/* <span>Following : {userData.following.length}</span> */}

                    </span>
                    {/* <span className='text-[13px]'><strong>Member Since </strong>{monthNames[date.getMonth()] + " " + date.getFullYear()}</span> */}
                    <div className='w-full grid grid-cols-2 gap-1 my-2 text-white'>
                        <button className="w-full h-8 bg-yellow-500 hover:bg-white hover:text-yellow-500 border border-yellow-500 text-xs rounded-full" onClick={() => { handleFollow() }} >{loader ? <CircularProgress size="1rem" color='inherit'/>:btnText}</button>
                        <button className="w-full h-8 bg-green-500 hover:bg-white hover:text-green-500 border border-green-500 text-xs rounded-full" onClick={() => { navigate(`/following/profile/${userData._id}`) }}>View Profile</button>
                    </div>

                    {/* <span className='text-yellow-500 hover:underline cursor-pointer text-xl' onClick = {()=>{navigate(`/following/profile/${item._id}`)}}>View Quizzes</span> */}
                </div>

            </div> : <CircularProgress/>}
        </>
    )
}

export default UserCard