import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
const TesingCard = () => {
    return (
        <Cont>
            <DateButtonsCont>
                <h1 className='font-extrabold  text-center text-red-900'>Monday 11th April</h1>
                < button className='bg-mylightgreen text-white rounded-md py-1 w-[100%]  text-center outline-none border-none'>Register</button>
            </DateButtonsCont>
            <MainCont>
                <div className=' space-y-2 col-span-2'>
                    <h1 className='text-white font-bold text-lg capitalize text-center'>Quiz Master</h1>
                    <h1 className='text-bgblue font-extrabold text-center'>Salleh Shah m</h1>
                </div>
                <div className=' space-y-2 col-span-2'>
                    <h1 className='text-white font-bold text-lg capitalize text-center'>Quiz Name</h1>
                    <h1 className='text-bgblue font-extrabold text-center'>this is the quiz name</h1>
                </div>
                <div className='space-y-2 col-span-2'>
                    <h1 className='text-white font-bold text-lg capitalize text-center'>Quiz Categoy</h1>
                    <h1 className='text-bgblue font-extrabold capitalize text-center'>Spin wheal</h1>
                </div>
                <div className='space-y-2 col-span-2'>
                    <h1 className='text-white font-bold text-lg capitalize text-center'>Prize pool</h1>
                    <div className='flex items-center justify-center'>
                        <div className='w-8 h-8'>
                            <img
                                src={require('./../../../assets/trophy.png')}
                                className='w-full h-full object-cover'
                            />
                        </div>
                        <h1 className='text-bgblue font-extrabold'>1000 INR</h1>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col space-y-2 col-span-4'>
                    <h1 className='text-white font-bold text-lg capitalize'>Starts in</h1>
                    <div className='flex items-center bg-bgblue space-x-2 p-2'>
                        <div className='flex flex-col rounded-md'>
                            <h1 className='bg-blue-700 flex-1 text-center text-white font-semibold'>39</h1>
                            <h1 className='bg-white text-xs text-center p-[.1rem] '>minutes</h1>
                        </div>
                        <div className='flex flex-col rounded-md'>
                            <h1 className='bg-blue-700 flex-1 text-center text-white font-semibold'>39</h1>
                            <h1 className='bg-white text-xs text-center p-[.1rem] '>minutes</h1>
                        </div>
                        <div className='flex flex-col rounded-md'>
                            <h1 className='bg-blue-700 flex-1 text-center text-white font-semibold'>39</h1>
                            <h1 className='bg-white text-xs text-center p-[.1rem] '>minutes</h1>
                        </div>
                        <div className='flex flex-col rounded-md'>
                            <h1 className='bg-blue-700 flex-1 text-center text-white font-semibold'>39</h1>
                            <h1 className='bg-white text-xs text-center p-[.1rem] '>minutes</h1>
                        </div>
                    </div>
                </div>
            </MainCont>
        </Cont>
    )
}
const MainCont = styled.div`
${tw`col-span-8 bg-[#12C8CB]  p-3 grid lg:grid-cols-12 grid-cols-1  gap-2`}
`
const DateButtonsCont = styled.div`
${tw`col-span-2 bg-[#12C8CB] p-3 space-y-1 w-full flex  items-center  justify-center  flex-col `}
`
const Cont = styled.div`
${tw`grid lg:grid-cols-10 grid-cols-1 lg:gap-2`}
`
export default TesingCard