import React, { useEffect } from 'react'
import NavBar from '../staticComponents/navBar/NavBar'
import Footer from '../staticComponents/footer/Footer'
import Banner from './Banner'
import WhyQuizzingBox from './WhyQuizzingBox'
import Steps from './Steps'
import Slider from './Slider'
import QuizMaster from './QuizMaster'
import DownloadApp from './DownloadApp'
import Embeded from './Embeded'
import WhyTextBook from '../staticComponents/staticHomeC/WhyTextBook'
import axios from "../../utils/myAxios"
function Home() {
  useEffect(async () => {
    await axios.get("/update/quizzes")
  }, [])
  return (
    <div className='w-full bg-white overflow-hidden'>
        <NavBar></NavBar>
        <Banner></Banner>
        {/* <WhyQuizzingBox></WhyQuizzingBox> */}
        <WhyTextBook></WhyTextBook>
        <Steps></Steps>
        <Slider></Slider>
        <QuizMaster></QuizMaster>
        <Embeded></Embeded>
        <DownloadApp></DownloadApp>
        
        <Footer></Footer>
    </div>
  )
}

export default Home