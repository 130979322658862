import React, { useEffect, useState } from "react";
import CommentItem from "./CommentItem";
import styled from "styled-components";
import tw from "twin.macro";
import { Button, Pagination } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../utils/myAxios";
//
import SendIcon from "@mui/icons-material/Send";
import { globalConstants } from "../../../actions/appConstants";
const Comment = ({ qid }) => {
  //pagination
  const [totalLength, settotalLength] = useState(0);
  const [queries, setqueries] = useState({
    page: 1,
    limit: 3,
  });
  const handlePagination = (e, currentPage) => {
    setqueries({
      ...queries,
      page: currentPage,
    });
  };
  //pagination
  const [commentsData, setcommentsData] = useState([]);
  const dispatch = useDispatch();
  const { auth } = useSelector((s) => s);
  const [comment, setcomment] = useState("");
  const handleSubmitComment = async () => {
    if (!comment.trim()) return;
    try {
      //
      const obj = {
        user: auth.data.user._id,
        quizzId: qid,
        comment: comment,
      };
      const res = await axios.post("/comment/create", {
        ...obj,
      });
      setcommentsData([res.data.comment, ...commentsData]);
      setcomment("");
    } catch (error) {}
  };
  const { page, limit } = queries;
  useEffect(() => {
    const getData = async () => {
      try {
        const obj = {
          qid: qid,
        };
        //console.log(qid)
        const res = await axios.post(
          `/get/allquizz/comments?page=${queries.page}&limit=${queries.limit}`,
          {
            ...obj,
          }
        );
        setcommentsData(res.data.comments);
        const l = Math.ceil(
          Number(res.data.totalLength) / Number(queries.limit)
        );
        settotalLength(l);
      } catch (error) {
        dispatch({
          type: globalConstants.ALERT,
          payload: { error: error.message },
        });
      }
    };
    if (qid) {
      getData();
    }
  }, [qid, page, limit]);
  return (
    <>
      <h1 className=" mt-10 mb-2 uppercase tracking-widest">comment section</h1>
      <div className="bg-white rounded-xl">
        {commentsData.length > 0 && (
          <CommentCont>
            {commentsData.map((val, index) => (
              <CommentItem val={val} key={index} />
            ))}
          </CommentCont>
        )}
        <div className="flex items-center justify-end pr-4">
          <Pagination
            count={totalLength}
            page={queries.page}
            onChange={handlePagination}
            color="primary"
          />
        </div>
        <div className="  p-4 flex flex-col  ">
          <h1 className="text-sm my-2 uppercase opacity-100 tracking-widest">
            Add your comments
          </h1>
          <textarea
            value={comment}
            onChange={(e) => setcomment(e.target.value)}
            className="border-mydarkyellow text-sm"
            rows={6}
            placeholder="Your question should not contain contact information such as email, phone or external web links. This is for your account security purpose."
          />
          <button
            onClick={handleSubmitComment}
            className="text-white bg-mydarkyellow py-1 px-2 w-max ml-auto mt-3"
          >
            Add Comment
          </button>
        </div>
      </div>
    </>
  );
};
const CommentCont = styled.div`
  ${tw`relative p-5   space-y-3  rounded-xl`}
`;
export default Comment;
