import React from 'react'
import ProfileC from '../components/profileComponents/ProfileC'
import Layout from './../components/Layout/Layout'
const Profile = () => {
  return (
    <Layout>
      <ProfileC />
    </Layout>
  )
}

export default Profile