import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
const MyDropAbles = ({ val }) => {
    return (
        <Droppable droppableId={String(val.id)}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    // style={{ backgroundColor: snapshot.isDraggingOver ? 'yellow' : '' }}
                    {...provided.droppableProps}
                >
                    <div className={`!min-w-[40px]  flex border border-bgblue items-center text-bgblue p-2 !min-h-[20px]   rounded   border-b-2 ${snapshot.isDraggingOver ? '!bg-yellow-300' : ''} `}>
                        <span className='font-bold'>
                            {
                                val.displayed.trim() &&
                                val.displayed
                            }
                        </span>
                    </div>
                </div>
            )}
        </Droppable>
    )
}

export default MyDropAbles