import axios from '../utils/myAxios'
import { authConstants, globalConstants, quizzSchedulerConstants, walletConstants } from './appConstants'
import store from './../store'

export const createQuizzScheduleAction = (data, setValue) => {
    return async (dispatch) => {
        try {
            
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/quizz/scheduler/create', {
                ...data
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            if (data.isSchoolTypeQuizz) {
                setValue(0)
            }
            return true;
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            if (error?.response?.data?.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error?.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}

export const getAllQuizzez = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.get(`/quizz/scheduler/getall?page=${data.page}`)
            //console.log('res===>', res.data)
            dispatch({
                type: quizzSchedulerConstants.GET_ALL_SHEDULED_QUIZZEZ,
                payload: res.data.quizzSchedulers
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: {}
            })
        } catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const getQuizzezByUserIdAction = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get()
        }
        catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const registerInQuizzAction = (data, handleUpdatedQuizz, setopen) => {
    return async (dispatch) => {
        try {
            const { auth } = store.getState()
            const {
                type,
                price,
                requiredPointsForFree
            } = data.quizz;
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/quizz/scheduler/register', {
                ...data
            })
            dispatch({
                type: quizzSchedulerConstants.QUIZZ_REGISTER_USER,
                payload: { user: auth.data.user._id, quizzId: data.quizzScheduleId }
            })
            //
            dispatch({
                type: walletConstants.DETECT_MONEY,
                payload: {
                    type,
                    price,
                    requiredPointsForFree
                }
            })
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            handleUpdatedQuizz(res.data.quizz)
            setopen(false)
        }
        catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const unRegisterQuizzAction = (data, handleUpdatedQuizz) => {
    return async (dispatch) => {
        try {
            const { auth } = store.getState()
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post('/quizz/scheduler/unregister', {
                ...data
            })
            dispatch({
                type: quizzSchedulerConstants.QUIZZ_UNREGISTER_USER,
                payload: { user: auth.data.user._id, quizzId: data.quizzId }
            })
            const {
                type,
                price,
                requiredPointsForFree
            } = data.quizz;
            //
            dispatch({
                type: walletConstants.ADD_MONEY,
                payload: {
                    type,
                    price,
                    requiredPointsForFree
                }
            })
            //
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: res.data.msg }
            })
            handleUpdatedQuizz(res.data.quizz)
        }
        catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}
export const updateQuizzStatusAction = (qid, status) => {
    return async (dispatch) => {
        try {
            const obj = {
                status: status
            }
            const res = await axios.post(`/update/quizzstatus/${qid}`, {
                ...obj
            })
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: "quizz status updated!" }
            })
            return res.data.quizz
        }
        catch (error) {
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
}