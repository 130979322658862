import React from 'react'
import { Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
import moment from 'moment'
const CommentItem = ({ val }) => {
    const { auth } = useSelector(s => s)
    return (
        <div className={`flex  ${val.user._id === auth.data.user._id ? 'justify-end' : ''}`}>
            <div className={`w-full p-2 px-4    ${val.user._id === auth.data.user._id ? 'bg-stone-100' : 'bg-gray-100'}   rounded-xl`}>
                <div className='flex  space-x-3'>
                    <Avatar className='!w-10 !h-10 shadow-2xl' src={val.user.profileImage} />
                    <div className='flex flex-col space-y-1'>
                        <span className='text-sm font-semibold'>{val.user.userName}</span>
                        <span className='text-xs opacity-50 font-semibold'>{moment(val.createdAt).fromNow()}</span>
                    </div>
                </div>
                {/* <div class="mb-2">
                    <div class="h-3 text-3xl text-left text-gray-600">“</div>
                    <p class="px-4 text-sm text-center text-gray-600">{val.comment} </p>
                    <div class="h-3 text-3xl text-right text-gray-600">”</div>
                </div> */}
                <p className='text-xs  my-4 break-words indent-13'>
                    {`${val.comment}`}
                </p>
                {/* <div className='flex items-center justify-end mt-1'>
                    <p className='text-xs text-bgblue font-semibold'>{moment(val.createdAt).format('MMMM Do YY, h:mm')}</p>
                </div> */}
            </div>
        </div>
    )
}

export default CommentItem