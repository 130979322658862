import React from "react";
import MyWalletC from "../components/myWalletComponents/MyWalletC";
import Layout from "./../components/Layout/Layout";

const MyWallet = () => {
  return (
    <Layout>
      <MyWalletC />
    </Layout>
  );
};

export default MyWallet;
