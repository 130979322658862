import React from 'react'
import { Container } from '@mui/material'
import { Typography } from '@mui/material'
import TextBookImg from "../../../assets/staticdata/quizmasterimg.png"
// import LiveImg from "../../../assets/staticdata/5th.webp"
// import DailyImg from "../../../assets/staticdata/quiz5.jpg"
// import Img360 from "../../../assets/staticdata/3rd.webp"
// import Doubt from "../../../assets/staticdata/4th.webp"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';



const TextbookSelect = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div className='bg-gray-800 py-5'>
      <Container>
        <div className='mt-10 grid md:grid-cols-12 gap-4'>
          <div className='md:col-span-5 flex justify-center flex-col'>
            <Typography variant={matches ? "h3" : "h5"} textAlign={matches ? "left" : "center"} sx={{ color: "rgb(234 179 8)" }}>
              Become Our Quiz Master
            </Typography>
            <Typography  py={3} variant="subtitle1" textAlign={matches ? "left" : "center"} sx={{ width : "100%" , color: "#fff" }}>
              Are you feeling smart? Do you like to take challenges and win  rewards? Are you one of those people who want to have it all? Do you want to feel the adrenalin and rush of blood in your veins while you take respect and earn big time? Well, we might have something just for you. Become our quizmaster & schedule unlimited quiz daily to earn rewards
            </Typography>
            
          </div>
          <div className='md:col-span-7 flex items-center justify-center'>
            <img className='w-full object-cover' src={TextBookImg} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TextbookSelect
