import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { fron_end_url_server, globalConstants } from '../../actions/appConstants'
import QuizzCard from '../DashBoardComponets/QuizCards/QuizzCard'
import PastQuizzezCard from '../pastQuizzezComponents/PastQuizzezCard'
import SchoolCard from '../SchoolCard'
let base_url = `${fron_end_url_server}/quizmaster`
const Section = ({ val, cid, isPastQuiz = false, isSchoolQuiz = false, handleUpdatedQuizz, handleArchive , isFollower , isUser = false }) => {
    const naviagte = useNavigate()
    const { auth } = useSelector(s => s)
    
    return (
        <div>
            <div className='flex items-center justify-between my-2'>
                <h1 className='capitalize text-bgblue font-semibold text-lg'>{val.subject}</h1>
                {
                    val.quizzes.length >= 5 &&
                    <span
                        onClick={() => {
                            isPastQuiz ?
                                naviagte(`/past/quizzes/category/quizzes/${cid}/${val.subject}`)
                                :
                                isSchoolQuiz ?
                                    naviagte(`/school/quizzes/category/quizzes/${cid}/${val.subject}`)
                                    :
                                    naviagte(`/category/quizzes/${cid}/${val.subject}`)
                        }}
                        className='semibold hover:underline hover:underline-offset-2 cursor-pointer tracking-widest text-bgblue  text-sm'>
                        show more
                    </span>
                }

            </div>
            <div className='grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-4 grid-rows-1'>
                {
                    val.quizzes.map((v, i) => (

                        isPastQuiz ?
                            <PastQuizzezCard
                                handleUpdatedQuizz={handleUpdatedQuizz}
                                quizz={v}
                                key={i}
                                user={auth.data.user._id}
                            />
                            :
                            isSchoolQuiz ?
                                <SchoolCard
                                    quizz={v}
                                    qid={v._id}
                                    code={v.schoolQuizzPasscode}
                                    base_url={base_url}
                                    isUser = {isUser}
                                    
                                />
                                : <QuizzCard
                                user={auth.data.user._id}
                                quizz={v}
                                key={i}
                                handleUpdatedQuizz={handleUpdatedQuizz}
                                handleArchive={handleArchive}
                            />
                    ))
                }
            </div>
        </div>
    )
}

export default Section;