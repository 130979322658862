import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';


const WorkCard = ({ Heading, IconImg, Details }) => {
  return (
    <>
      <Card sx={{display:'flex', backgroundColor: "#E5E5E5", color: "black", border: "none", boxShadow: "none",  borderRadius: 5 ,width:"300px"}}>


        <CardContent>
          <Box className='flex items-center justify-center'>
            <img className='iconCard' alt="" src={IconImg} />
          </Box>
          <Typography mt={3} textAlign='center' variant="h5" sx={{ fontSize: 18}} component="div">
            {Details}
          </Typography>

          <Typography textAlign='center' mt={3} variant="subtitle1">

          </Typography>
        </CardContent>

      </Card>
    </>
  )
}

export default WorkCard