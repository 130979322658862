import { ClosedCaptionDisabledSharp } from '@mui/icons-material'
import moment from 'moment'
import React , {useState} from 'react'
import TableRow from './TableRow'

const MyTable = ({ questions, setquestions, isSchoolTypeQuizz , registered , isPastQuiz}) => {
    let position = 0
    return (
        <div class="overflow-x-auto">
            <div class="min-w-screen  bg-white flex justify-center  font-sans overflow-hidden my__rounded">
                <div class="w-full  overflow-auto">
                    <div class="bg-white shadow-md rounded">
                        <table class="min-w-max w-full table-auto overflow-x-scroll">
                            <thead>
                                <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <th class="py-3 px-6 text-left">player</th>
                                    <th class="py-3 px-6 text-center">position</th>
                                    <th class="py-3 px-6 text-center">correct answers</th>
                                    <th class="py-3 px-6 text-center">time taken</th>
                                </tr>
                            </thead>
                            <tbody class="text-gray-600 text-sm font-light">
                                {
                                    questions.length > 0 &&
                                    questions.map((val, index) => {
                                        //console.log(isPastQuiz)
                                        // if(isPastQuiz && registered.findIndex((item)=> item.user === val.user._id)=== -1)
                                        // {
                                        //     position++
                                        //     return <TableRow index={position} isSchoolTypeQuizz={isSchoolTypeQuizz} questions={questions} setquestions={setquestions} val={val} key={index} />
                                        // }
                                        // else if(!isPastQuiz && registered.findIndex((item)=> item.user === val.user._id)!== -1)
                                        // {
                                        //     position++
                                        //     return <TableRow index={position} isSchoolTypeQuizz={isSchoolTypeQuizz} questions={questions} setquestions={setquestions} val={val} key={index} />
                                        // }
                                        // else if(isSchoolTypeQuizz)
                                        // {
                                        //     return <TableRow index={index+1} isSchoolTypeQuizz={isSchoolTypeQuizz} questions={questions} setquestions={setquestions} val={val} key={index} />
                                        // }
                                        
                                        return <TableRow index={index+1} isSchoolTypeQuizz={isSchoolTypeQuizz} questions={questions} setquestions={setquestions} val={val} key={index} />
                                        
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyTable