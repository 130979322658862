import React, { useEffect, useState } from 'react'
import StarRatings from 'react-star-ratings'
import axios from '../../../utils/myAxios'
const MyStarRating = ({ qid }) => {
    const [quizzRating, setquizzRating] = useState({
        npmRating: 0,
        ratingLength: 0,
    })
    useEffect(() => {
        const fetcData = async () => {
            try {
                if (qid) {
                    const res = await axios.get(`/get/real/quizz/rating/${qid}`)
                    //console.log('rating res===>', res.data)
                    const { rating, totalLength } = res.data
                    if (res.status === 200) {
                        setquizzRating({
                            npmRating: rating,
                            ratingLength: totalLength,
                        })
                    }
                }
            } catch (error) {

            }
        }
        if (qid) {
            fetcData()
        }
    }, [qid])
    return (
        <div className='flex  space-x-1 items-center'>
            <StarRatings
                rating={5}
                starRatedColor="#FFD800"
                numberOfStars={Math.floor(quizzRating.npmRating)}
                name='rating'
                starDimension="17px"
                starSpacing="4px"

            />
            <span className='cursor-pointer text-[10px]  hover:underline'>{`${quizzRating.ratingLength} Reviews`}</span>
        </div>
    )
}

export default MyStarRating