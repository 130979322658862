import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import MyBackDrop from "./components/alertHandlers/MyBackDrop";
import MySnacBarSuccess from "./components/alertHandlers/MySnacBarSuccess";
import MySnacBarError from "./components/alertHandlers/MySnacBarError";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import Quizmasters from "./components/Quizmasters";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
// import Home from './screens/Home'
import UnAuthorized from "./screens/UnAuthorized";
import NotFound from "./screens/NotFound";
import AdminHOC from "./HOC/AdminHOC";
import UserHoc from "./HOC/UserHoc";
import VarifyUser from "./screens/Auth/VarifyUser";
import ResetPasswordRequest from "./screens/Auth/ResetPasswordRequest";
import ResetPasswordConfirm from "./screens/Auth/ResetPasswordConfirm";
import ReportBug from "./staticApp/staticScreens/ReportBug";
//
import CircularProgress from "@mui/material/CircularProgress";
//
import DashBoard from "./screens/DashBoard";
import AdminScheduleQuiz from "./screens/AdminScheduleQuiz";
import MyWallet from "./screens/MyWallet";
import PlayedQuizez from "./screens/PlayedQuizez";
import Profile from "./screens/Profile";
import ScheduleQuiz from "./screens/ScheduleQuiz";
import UserManagement from "./screens/UserManagement";
import UserScheduledQuizez from "./screens/UserScheduledQuizez";
import QuestionBank from "./screens/QuestionBank";
import { useDispatch, useSelector } from "react-redux";
import { keepUserLoggedIn, getUserStaticTabData } from "./actions/auth.actions";
import PlateformSettings from "./screens/PlateformSettings";
import { getWalletAction } from "./actions/wallet.actions";
import PlayQuizz from "./screens/PlayQuizz";
import SinglrQuizzDetailScreen from "./screens/SinglrQuizzDetailScreen";
import { getAllQuestionCategories } from "./actions/question.actions";
import { getAllSubjects } from "./actions/subjects.actions";
import { authConstants, globalConstants } from "./actions/appConstants";
import NotAuthorized from "./screens/NotAuthorized";
import Plans from "./screens/Plans";
import Subscriptions from "./screens/Subscriptions";
import PaymentsScreen from "./screens/PaymentsScreen";
import RegisteredQuizzesScreen from "./screens/RegisteredQuizzesScreen";
import PlaySchoolQuizz from "./screens/PlaySchoolQuizz";
import PastQuizzez from "./screens/PastQuizzez";
import CancelledQuizzes from "./screens/CancelledQuizzes";
import MySchoolQuizzes from "./screens/MySchoolQuizzes";
// import Refferral from './screens/Refferral'
import Redemption from "./screens/Redemption";
import Referral from "./screens/Referral";
import ImportExcelSheets from "./screens/ImportExcelSheets";
import DistributePastQuizzPrizzes from "./screens/DistributePastQuizzPrizzes";
import PopUpModalForLoginWithEarning from "./components/uiComponents/PopUpModalForLoginWithEarning";
import BuyQzetosModal from "./components/plansComponents/BuyQzetosModal";
import BuySchoolQuizModal from "./components/schoolQuizC/BuySchoolQuizModal";
import ShowSelectedCatQuizzes from "./screens/ShowSelectedCatQuizzes";
import QuizMasterPro from "./components/schoolQuizC/QuizMasterPro";

// import StaticCategoriesPage from './screens/StaticCategoriesPage'
// import QuizzesWithDifferentSubjectsPage from './screens/QuizzesWithDifferentSubjectsPage'
import PastQuizzesWithDifferentSubjects from "./screens/PastQuizzesWithDifferentSubjects";
// import PastQuizzezC from './components/pastQuizzezComponents/PastQuizzezC'
import SchoolQuizzesWithDifferentSubjects from "./screens/SchoolQuizzesWithDifferentSubjects";
import SchoolQuizC from "./components/schoolQuizC/SchoolQuizC";
import ArchiveQuizzesTab from "./screens/ArchiveQuizzesTab";
import UserRedemptionTab from "./screens/UserRedemptionTab";
import UserQuestionBankViewByAdmins from "./screens/UserQuestionBankViewByAdmins";
import StaticHome from "./staticApp/staticScreens/StaticHome";
import TermandCondition from "./staticApp/staticScreens/TermandCondition";
// import PaymentPolicy from './staticApp/staticScreens/PaymentPolicy'
import PrivacyPolicy from "./staticApp/staticScreens/PrivacyPolicy";
import StaticHowitWork from "./staticApp/staticScreens/StaticHowitWork";
import FrequentlyAsked from "./staticApp/staticScreens/FrequentlyAsked";
import Careers from "./staticApp/staticScreens/Careers";
import ContactUs from "./staticApp/staticScreens/ContactUs";
import SignInModal from "./staticApp/staticComponents/staticHomeC/SignInModal";
import SinginSignupPopup from "./staticApp/staticComponents/staticHomeC/SinginSignupPopup";
import ModalForBuyInfo from "./components/uiComponents/ModalForBuyInfo";
import TabsForMain from "./components/pastQuizzezComponents/tabsForMain/TabsForMain";
import Layout from "./components/Layout/Layout";
import TabForSubjects from "./components/quizzesWithDifferentSubjectC/tabForFreePaidQuizzes/Tab";
import MakeMeAdmin from "./MakeMeAdmin";
import QuizPromasterStats from "./screens/QuizPromasterStats";
import FollowPage from "./components/FollowPage";
import Following from "./components/Following";
import Home from "./staticApp/staticScreens/Home";
// import axios from './utils/myAxios'
import "./App.css";
import ReactGA from "react-ga";
import ScheduleQuizzithon from "./components/quizithon/ScheduleQuizzithon";
import PlayQuizzithon from "./components/quizithon/PlayQuizzithon";
import QuizzithonDetail from "./components/quizithon/QuizzithonDetail";
import UserQuizzithon from "./components/quizithon/UserQuizzithon";
//stripe confirmation
import PaymentSuccess from "./components/plansComponents/PaymentSuccess";



const App = () => {
  const { auth, extras } = useSelector((state) => state);
  const dispatch = useDispatch();
  const token = localStorage.getItem(globalConstants.QUEZETO_AUTH_TOKEN);
  useEffect(() => {
    if (token) {
      dispatch(keepUserLoggedIn());
    }
  }, [token]);
  useEffect(() => {
    if (auth?.data?.user?._id) {
      dispatch(getWalletAction(auth.data.user._id));
      dispatch(getAllQuestionCategories());
      dispatch(getAllSubjects());
      dispatch(getUserStaticTabData(auth.data.user._id));
    }
  }, [auth?.data?.user?._id]);
  useEffect(() => {
    dispatch(getAllQuestionCategories());
  }, [window.location.pathname]);
  useEffect(() => {
    const TRACKING_ID = "UA-244329358-1"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname + window.location.search]);
  useEffect(() => {
    const t = localStorage.getItem(globalConstants.QUEZETO_AUTH_TOKEN);
    if (t) {
      dispatch({
        type: authConstants.HANDLE_AUTENTICATING,
        payload: true,
      });
    } else {
      dispatch({
        type: authConstants.HANDLE_AUTENTICATING,
        payload: false,
      });
    }
  }, []);
  if (auth.authenticating)
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <CircularProgress />
      </div>
    );

  return (
    <Router>
      {/* alert handlers */}
      <MyBackDrop />
      <MySnacBarSuccess />
      <MySnacBarError />
      <PopUpModalForLoginWithEarning />
      <SignInModal />
      <SinginSignupPopup />
      {/* alert handlers */}
      {/* Global Modals to show */}
      <BuyQzetosModal />
      <BuySchoolQuizModal />
      <ModalForBuyInfo />
      {/* Global Modals to show */}
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/unauthorized" element={<UnAuthorized />} />
        <Route path="/varifyuser" element={<VarifyUser />} />
        <Route
          path="/resetpasswordrequest"
          element={<ResetPasswordRequest />}
        />
        <Route
          path="/resetpassword/:token"
          element={<ResetPasswordConfirm />}
        />
        <Route path="/quizmaster" element={<PlaySchoolQuizz />} />
        <Route path="/playquizz/:qid" element={<PlayQuizz />} />
        <Route path="/reportbug" element={<ReportBug />} />
        {/* public routes */}
        {/* static pages */}
        <Route path="/" element={<StaticHome />} />
        <Route
          path="/static/termsandcondition"
          element={<TermandCondition />}
        />
        {/* <Route path='/static/paymentpolicy' element={<PaymentPolicy />} /> */}
        <Route path="/static/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/static/howitworks" element={<StaticHowitWork />} />
        <Route path="/static/faqs" element={<FrequentlyAsked />} />
        <Route path="/static/careers" element={<Careers />} />
        <Route path="/static/contactus" element={<ContactUs />} />
        <Route path="/quizmasterpro/quizzes" element={<QuizMasterPro />} />
        {/* static pages */}
        <Route
          path="/school/quizzes/categories/subjects/:cid/:hide"
          element={<SchoolQuizzesWithDifferentSubjects />}
        />
        {/* protected routes */}

        <Route
          path="/singlequizz/detailscreen/:qid"
          element={<SinglrQuizzDetailScreen />}
        />

        <Route element={<UserHoc />}>
          <Route path="/quizmasters" element={<Quizmasters />} />
          <Route path="/make/me/admin" element={<MakeMeAdmin />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/following/profile/:id" element={<FollowPage />} />
          <Route path="/my-following/:type" element={<Following />} />
          <Route path="/questionbank" element={<QuestionBank />} />
          <Route path="/schedulequiz" element={<ScheduleQuiz />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/mywallet" element={<MyWallet />} />
          <Route path="/playedquizez" element={<PlayedQuizez />} />

          <Route path="/notauthorized" element={<NotAuthorized />} />
          <Route path="/plans" element={<Plans />} />
          <Route
            path="/userschedulesquizez/:uid"
            element={<UserScheduledQuizez />}
          />
          <Route path="/user/quizzithons/:uid" element={<UserQuizzithon />} />
          <Route path="/user/subscriptions" element={<Subscriptions />} />
          <Route path="/payments" element={<PaymentsScreen />} />
          <Route
            path="/registeredquizzes"
            element={<RegisteredQuizzesScreen />}
          />
          <Route path="/quiz-library" element={<PastQuizzez />} />
          <Route path="/cancelledquizzes" element={<CancelledQuizzes />} />
          {/* <Route path='/myschoolquizzes' element={<MySchoolQuizzes />} /> */}
          <Route path="/quizmaster-pro" element={<MySchoolQuizzes />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/importexcelsheets" element={<ImportExcelSheets />} />
          <Route
            path="/distribute/pastquizzprizzes/:qid"
            element={<DistributePastQuizzPrizzes />}
          />
          {/* category tab */}
          <Route
            path="/category/quizzes/:category/:subject"
            element={<ShowSelectedCatQuizzes />}
          />

          {/* Quizzithon Routes */}
          <Route path="/schedule-quizzithon" element={<ScheduleQuizzithon />} />
          <Route path="/play-quizzithon" element={<PlayQuizzithon />} />
          <Route path="/quizzithon/:id" element={<QuizzithonDetail />} />
          {/* <Route path='/static/categories' element={<StaticCategoriesPage />} /> */}

          <Route
            path="/categories/subjects/:cid"
            // element={<QuizzesWithDifferentSubjectsPage />}
            element={
              <Layout>
                <TabForSubjects />
              </Layout>
            }
          />
          <Route
            path="/categories/subjects/:cid/:type"
            // element={<QuizzesWithDifferentSubjectsPage />}
            element={
              <Layout>
                <TabForSubjects />
              </Layout>
            }
          />
          {/* <Route path='/categories/subjects/:cid/:type'
            // element={<QuizzesWithDifferentSubjectsPage />} 
            element={<Layout><TabForSubjects /></Layout>}
          /> */}
          {/* category tab */}
          {/* past quizzes with categories */}
          <Route
            path="/past/quizzes/categories/subjects/:cid"
            element={<PastQuizzesWithDifferentSubjects />}
          />
          <Route
            path="/past/quizzes/category/quizzes/:category/:subject"
            element={
              <Layout>
                <TabsForMain />
              </Layout>
            }
          />
          {/* past quizzes with categories */}
          {/* School Quizzes */}
          <Route
            path="/school/quizzes/categories/subjects/:cid"
            element={<SchoolQuizzesWithDifferentSubjects />}
          />

          <Route
            path="/school/quizzes/category/quizzes/:category/:subject"
            element={<SchoolQuizC />}
          />
          {/* School Quizzes */}
          {/* user redemption tab */}
          <Route path="/userredemption" element={<UserRedemptionTab />} />
          {/* user redemption tab */}
          {/* payment success */}
          <Route path="/payment-success" element={<PaymentSuccess />} />
          {/* payment success */}
        </Route>
        <Route element={<AdminHOC />}>
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/adminschedulequiz" element={<AdminScheduleQuiz />} />
          <Route path="/plateformsettings" element={<PlateformSettings />} />
          <Route path="/redemption" element={<UserRedemptionTab />} />
          <Route path="/archivequizzes" element={<ArchiveQuizzesTab />} />
          {/* user management admin see user questions */}
          <Route
            path="/admin/users/questions/:uid"
            element={<UserQuestionBankViewByAdmins />}
          />
          <Route
            path="/admin/users/quizpromaster/stats/:uid"
            element={<QuizPromasterStats />}
          />

          {/* user management admin see user questions */}
        </Route>
        {/* protected routes */}
        {/* 404 */}
        <Route path="*" element={<NotFound />} />
        {/* 404 */}
      </Routes>
    </Router>
  );
};
const Container = styled.h1`
  ${tw`text-2xl text-red-700`}
`;
export default App;
