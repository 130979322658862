// user ID
// username
// email
// real qzeto Balance
// free qzeto Balance
// bonus qzeto Balance
// first name
// middle name
// last name
// varified status
// isAdmin
// address
// city
// state
import React, { useState } from 'react'
import moment from 'moment'
import TableModal from './TableModal'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import { Avatar } from '@mui/material'
const TableRow = ({ val, questions, setquestions }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [open, setopen] = useState(false)
    const init = {
        edit: false,
        view: false,
        delete: false,
    }
    const [state, setstate] = useState(init)
    const getWalletData = (wallet, type) => {
        const obj = wallet.find(v => v.walletsType === type)
        const toreturn = obj?.credit ? obj.credit : 0
        return toreturn;
    }
    const handleUpdateThequestions = async (id, value) => {
        try {
            const updated = questions.map(v => {
                if (v._id === id) {
                    return value
                }
                return v
            })
            setquestions(updated)
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const handleCreateAdmin = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const rs = await axios.post(`/root/createadmin/${val._id}`)
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: rs.data.msg }
            })
        } catch (error) {
            //console.log('error==>', error.response)
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    const handleRemoveAdmin = async () => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const rs = await axios.post(`/root/removeadmin/${val._id}`)
            dispatch({
                type: globalConstants.ALERT,
                payload: { success: rs.data.msg }
            })
        } catch (error) {
            //console.log('error==>', error.response)
            if (error.response.data.msg) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.msg }
                })
            } else if (error.response?.data?.error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.response.data.error }
                })
            }
            else {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }
    const handleAllowAutoApproval = async () => {
        try {
            const _obj = {
                uid: val._id,
                allow: true
            }
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            const res = await axios.post(`/autoapprove/question/request`, {
                ..._obj
            })
            await handleUpdateThequestions(res.data.user._id, res.data.user)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            // axios call for auto approval     
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    const handleRemoveAutoApproval = async () => {
        try {
            // axios call for auto approval     
            const _obj = {
                uid: val._id,
                allow: false
            }
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            const res = await axios.post(`/autoapprove/question/request`, {
                ..._obj
            })
            await handleUpdateThequestions(res.data.user._id, res.data.user)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    return (
        <>
            <TableModal val={val} open={open} setopen={setopen} state={state} questions={questions} setquestions={setquestions} />
            <tr class="border-b border-gray-200 hover:bg-gray-100 bg-white shadow-md">

                {/* username */}
                <td class="py-3 px-6 text-left">
                    <span>{val.userName}</span>
                </td>
                {/* profile image */}
                <td class="py-3 px-6 text-left flex items-center justify-center">
                    <Avatar
                        src={val.profileImage}
                        alt=''
                    />
                </td>
                {/* mobile/email */}
                <td class="py-3 px-6 text-center ">
                    <span>{val.email_number}</span>
                </td>
                {/*is varified statuses */}
                <td class="py-3 px-6 text-center">
                    {
                        val.isVarified === true ?
                            <span class="bg-green-400 text-white py-1 font-bold px-3 rounded-full text-xs">varified</span>
                            :
                            <span class="bg-red-400 font-bold text-white py-1 px-3 rounded-full text-xs">unvarified</span>

                    }
                </td>
                {/* is admin */}
                <td class="py-3 px-6 text-center">
                    {
                        val.isAdmin === true ?
                            <span class="bg-green-400 text-white py-1 font-bold px-3 rounded-full text-xs">Admin</span>
                            :
                            <span class="bg-red-400 font-bold text-white py-1 px-3 rounded-full text-xs">User</span>

                    }
                </td>
                {/* actions */}
                <td class="py-3 px-6 text-center">
                    {
                        val.isAdmin ?
                            <button onClick={handleRemoveAdmin} className='bg-opacity-80 hover:bg-opacity-100 bg-red-500 rounded-md text-white py-1 px-3 text-xs capitalize'>create user</button>
                            :
                            <button onClick={handleCreateAdmin} className='bg-opacity-80 hover:bg-opacity-100 bg-bgblue rounded-md text-white py-1 px-3 text-xs capitalize'>create admin</button>
                    }
                </td>
                <td class="py-3 px-6 text-center">
                    {
                        val.questionsAutoApproval ?
                            <button onClick={handleRemoveAutoApproval} className='bg-red-500 bg-opacity-80 hover:bg-opacity-100 rounded-md text-white py-1 px-3 text-xs capitalize'>Remove Auto Approval</button>
                            :
                            <button onClick={handleAllowAutoApproval} className='bg-bgblue bg-opacity-80 hover:bg-opacity-100 rounded-md text-white py-1 px-3 text-xs capitalize'>Allow Auto Approval</button>

                    }
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/userschedulesquizez/${val._id}`)}>view user quizzez</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/questionbank`, { state: { userManagement: val._id } })}>view user question bank</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/payments`, { state: { userManagement: val._id } })}>view user Purchases</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/userredemption`, { state: { userManagement: val._id } })}>view user redemption histories</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/playedquizez`, { state: { userManagement: val._id } })}>view user played quizzes</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/admin/users/quizpromaster/stats/${val._id}`, { state: { userManagement: val._id } })}>Quizmaster-Pro Stats</span>
                </td>
                <td class="py-3 px-6 text-center">
                    <span className='text-bgblue capitalize hover:underline hover:underline-offset-1 cursor-pointer' onClick={() => navigate(`/user/quizzithons/${val._id}`)}>User Quizzithon</span>
                </td>
            </tr>
        </>
    )
}

export default TableRow