import React, { useEffect, useState } from 'react'
import Layout from './../components/Layout/Layout'
import PlayedQuizzesC from '../components/playedQuizzesComponents/PlayedQuizzesC'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TabsForPlayedQuizzes from '../components/playedQuizzesComponents/tabsForMain/TabsForPlayedQuizzes'
const PlayedQuizez = () => {
  const { auth } = useSelector(s => s)
  const { state } = useLocation()
  const [loading, setloading] = useState(true)
  const [user, setuser] = useState('')
  //check if user management want to see user questions
  useEffect(async () => {
    if (state?.userManagement) {
      // console.log('state?.userManagement==>', state?.userManagement)
      await setuser(state?.userManagement)
      setloading(false)
    } else {
      setloading(false)
    }
  }, [state])
  //check if user management want to see user questions
  return (
    <Layout>
      {
        loading ?
          <div>loading...</div>
          :
          <TabsForPlayedQuizzes
            user={user}
          />
      }
    </Layout>
  )
}

export default PlayedQuizez