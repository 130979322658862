import React from 'react'
import PastQuizzezC from '../PastQuizzezC'
const FreeTab = ({
  cat,
  sub
}) => {
  return (
    <>
      <PastQuizzezC
        cat={cat}
        sub={sub}
        type='free'
      />
    </>
  )
}

export default FreeTab