import { Avatar, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import DashBoardTabs from '../DashBoardComponets/DashboardTabs/DashBoardTabs'
// import StarRatings from 'react-star-ratings'
import moment from 'moment'
import axios from '../../utils/myAxios'
import CommentContainer from '../DashBoardComponets/DashboardTabs/CommentContainer'
import { keepUserLoggedIn } from '../../actions/auth.actions'
//icons.
import { CircularProgress } from '@mui/material'

//icons.
//iconsright
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
// import CommentItem from './CommentItem'
import CommentViewShareState from '../DashBoardComponets/DashboardTabs/CommentViewShareState'
import ReviewsSection from '../DashBoardComponets/DashboardTabs/ReviewsSection'
import { useNavigate, useLocation } from 'react-router-dom'
import { unRegisterQuizzAction } from '../../actions/quizzSchedule.actions'
//card modal
import CardModal from '../DashBoardComponets/QuizCards/CardModal'
import MyStarRating from '../DashBoardComponets/DashboardTabs/MyStarRating'
import Logo from '../../assets/logo/logo.png'
import { set } from 'lodash'
//card modal
//iconsright
const questionConstants = {
    MULTIPLE_QUESTIONS: 'MULTIPLE_QUESTIONS',
    TRUE_FALSE: 'TRUE_FALSE',
    FILL_IN_THE_BLANKS: 'FILL_IN_THE_BLANKS',
    MATCH_THE_PAIR: "MATCH_THE_PAIR",
    SEQUENCING_QUIZ: "SEQUENCING_QUIZ",
    PARAGRAPH_ORDERING_QUIZ: "PARAGRAPH_ORDERING_QUIZZ",
    IDENTIFY_IMAGE_VIDEO_OR_VOICE: "IDENTIFY_IMAGE_VIDEO_OR_VOICE",
    SPIN_A_WHEEL_QUIZ: "SPIN_A_WHEEL_QUIZZ",
    ELIMINATOR_QIZ: "ELIMINATOR_QUIZZ",
    MIX_TYPE_QUIZ: "MIX_TYPE_QUIZ"
}

const RightItems = ({ Icon, text, data }) => {
    return (
        <div className='flex items-center justify-between opacity-50'>
            <div className='flex items-center space-x-3'>
                <Icon />
                <h1>{text}</h1>
            </div>
            <span>{data}</span>
        </div>
    )
}
const QuizzithonQuiz = ({ singleQuizz, qid, user, handleUpdatedQuizz, hidecomments = false, quizzithonId }) => {
    const [isPastQuiz, setisPastQuiz] = useState(false)
    const [played, setPlayed] = useState(false)
    const { state } = useLocation()
    const [open, setopen] = useState(false)
    const [btnText, setBtnText] = useState()
    const [user_data, setData] = useState()

    const handleUpdatedQuiz = () => {

    }
    useEffect(() => {
        axios.get(`/get/single/gammer/${user._id}/${qid}/fasle`)
            .then(res => {
                
                setPlayed(res.data.gamer.played)
            })
            .catch(err => {
                
            }
            )
    }, [user, qid])
    const navigate = useNavigate()
    //for play & unregister etc...
    const [timer, settimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    })
    const cardInit = {
        play: false,
        timesup: false,
        register: false,
        unregister: false,
    }
    const [cardStates, setcardStates] = useState(cardInit)

    //
    const [comment, setcomment] = useState('')
    //
    //rating state

    //rating state
    const dispatch = useDispatch()
    const [dataTosho, setdatatoshow] = useState({
        availTimeToRegister: false,
        numberOfQuestions: 0,
    })
    const { auth, question } = useSelector(state => state)
    const handleRegist = () => {
        setopen(true)
    }
    const handleUnRegister = async () => {

        await dispatch(unRegisterQuizzAction({ quizzId: singleQuizz._id, singleQuizz }, handleUpdatedQuizz))
    }
    useEffect(() => {
        if (singleQuizz.date) {
            const str2 = singleQuizz.date
            const str1 = new Date()
            var diff = Date.parse(str2) - Date.parse(str1);
            // //console.log('diffrere==>', diff)
            if (diff > 0) {
                setdatatoshow({
                    ...dataTosho,
                    availTimeToRegister: true
                })
            } else {
                setdatatoshow({
                    ...dataTosho,
                    availTimeToRegister: false
                })
            }
            const f = question.questionsCategories.find(_v => _v._id === singleQuizz.category._id)
            if (f?.name === questionConstants.MIX_TYPE_QUIZ) {
                setdatatoshow({
                    ...dataTosho,
                    numberOfQuestions: singleQuizz.forMixTypeQuestions.length
                })
            } else if (f?.name === questionConstants.SPIN_A_WHEEL_QUIZ) {
                setdatatoshow({
                    ...dataTosho,
                    numberOfQuestions: singleQuizz.spinWheal.length
                })
            } else {
                setdatatoshow({
                    ...dataTosho,
                    numberOfQuestions: singleQuizz.questions.length
                })
            }
        }
    }, [singleQuizz.date])


    //get like state

    //add the views for the quizz
    useEffect(async () => {
        if (qid) {
            try {
                const obj = {
                    user: auth.data.user._id,
                    quizzId: qid
                }
                // //console.log('obj==>', obj)
                const res = await axios.post('/view/create', {
                    ...obj,
                })
            } catch (error) {
            }
        }
    }, [qid])

    //follow feature
    const getUserData = async () => {
        try {
            let res = await axios.post('/follower/getFollowingData', {
                user: singleQuizz.createdBy
            })
            // console.log(res)
            setData(res.data)
        } catch (error) {
            // console.log(error)
        }
    }
    useEffect(async () => {
        try {
            let res = await axios.post('/follower/getFollowingData', {
                user: singleQuizz.createdBy
            })
            // console.log(res)
            setData(res.data)
            // console.log("data =>>>>>>->>>", user_data._user.followers.findIndex((i) => i === auth.data.user._id))
            if (res) {
                if (user_data._user.followers.findIndex((i) => i === auth.data.user._id !== -1)) {
                    // console.log("hello from condition")
                    setBtnText("UnFollow")
                }
                else {
                    setBtnText("Follow")
                }

            }
        } catch (error) {
            // console.log(error)
        }
    }, [])
    useEffect(() => {
        if (user_data?._user) {
            if (user_data._user.followers.findIndex((i) => i === auth.data.user._id) !== -1) {

                setBtnText("UnFollow")
            }
            else {
                setBtnText("Follow")
            }
        }

    }, [user_data])

    const handleFollow = async () => {
        if (user_data._user.followers.findIndex((i) => i === auth.data.user._id) !== -1) {
            setBtnText("progress")
            const res2 = await axios.post('/follower/remove', {
                user: auth.data.user._id,
                f_user: singleQuizz.createdBy
            })
            if (res2) {
                setBtnText("Follow")
            }
            getUserData()
        }
        else {
            setBtnText("progress")
            const res2 = await axios.post('/follower/add', {
                user: auth.data.user._id,
                f_user: singleQuizz.createdBy
            })
            if (res2) {
                setBtnText("UnFollow")
            }
            getUserData()
        }
    }


    //follow feature 
    useEffect(async () => {
        const t = setTimeout(async () => {
            const fun = () => {
                const str2 = singleQuizz.time
                const str1 = new Date()
                var diff = Date.parse(str2) - Date.parse(str1);
                return isNaN(diff) ? NaN : {
                    diff: diff,
                    ms: Math.floor(diff % 1000),
                    s: Math.floor(diff / 1000 % 60),
                    m: Math.floor(diff / 60000 % 60),
                    h: Math.floor(diff / 3600000 % 24),
                    d: Math.floor(diff / 86400000)
                }
            }
            const d = fun()

            settimer({
                days: d?.d >= 0 ? d.d : 0,
                hours: d?.h >= 0 ? d.h : 0,
                minutes: d?.m >= 0 ? d.m : 0,
                seconds: d?.s >= 0 ? d.s : 0
            })
            //for reistered and times up or time of playing
            if (d.d <= 0 && d.h <= 0 && d.m <= 0 && d.s <= 0) {
                // console.log(singleQuizz.registerdUsers , user)
                if (singleQuizz.registerdUsers.find(u => u.user === user._id)) {
                    
                    const s_time = new Date(singleQuizz.time)
                    if (singleQuizz.isDurationBound) {
                        //add the quiz end_time of 1 Day in the start time for `DURATINO BOUND TYPE QUIZ`
                        s_time.setDate(s_time.getDate() + 1)
                    } else {
                        s_time.setMinutes(s_time.getMinutes() + Number(singleQuizz.duration))
                    }
                    const c_time = new Date().getTime()
                    if (s_time.getTime() < c_time) {
                        // //console.log("done from here")
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    } else {

                        try {
                            if (singleQuizz.resheduleAllow) {
                                // const _res_res = await axios.post(`/varify/minimumnumberofseats/${singleQuizz._id}`)
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            } else {
                                setcardStates({
                                    ...cardInit,
                                    play: true
                                })
                            }
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    }
                } else {
                    if (singleQuizz.resheduleAllow) {
                        try {
                            // const _res_res = await axios.post(`/varify/minimumnumberofseats/${singleQuizz._id}`)
                            // handleUpdatedQuizz(_res_res.data.quizz)
                        } catch (error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    } else {
                        try {
                            if (singleQuizz.status !== 'closed') {
                                // const its_obj = { status: 'closed' }
                                // const _res_res = await axios.post(`/update/quizzstatus/${singleQuizz._id}`, {
                                //     ...its_obj
                                // })
                                // handleUpdatedQuizz(_res_res.data.quizz)
                            }
                        } catch (error) {
                            // dispatch({
                            //     type: globalConstants.ALERT,
                            //     payload: { error: error.message }
                            // })
                        }
                        setcardStates({
                            ...cardInit,
                            timesup: true
                        })
                    }
                }
            }
            //for reistered and times up or time of playing
            //for registered unregistered
            if (d.d > 0 || d.h > 0 || d.m > 0 || d.s > 0) {
                // //console.log("hello from the time function >>>")
                if (singleQuizz.registerdUsers.find(u => u.user === user)) {
                    setcardStates({
                        ...cardInit,
                        unregister: true
                    })
                } else {
                    setcardStates({
                        ...cardInit,
                        register: true
                    })
                }
            }
            //for registered unregistered
            //increase the days if the minimum numbers of people are not matched
        }, 1000);
        return () => clearInterval(t)
    }, [timer])
    const handlePlayOnClick = async (statuses) => {
        dispatch(keepUserLoggedIn())
        if (!hidecomments) {


            if (!state?.isPastQuiz) {
                if (singleQuizz.isSchoolTypeQuizz === false) {
                    try {
                        dispatch({
                            type: globalConstants.ALERT,
                            payload: { loading: true }
                        })
                        const _obj = {
                            qid: singleQuizz._id
                        }
                        const res = await axios.post('/check/minimumnumberofusers/forrefunds', { ..._obj })
                        // //console.log('res,registered users..==>', res)
                        if (res?.data?.okay) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { loading: false }
                            })
                            navigate(`/playquizz/${singleQuizz._id}`, { state: { user: user, isPastQuiz: false, isQuizzithonQuiz: singleQuizz.isQuizzithonQuiz, quizzithonId: quizzithonId } })
                        } else {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: 'You are refunded successfully. Quiz is cacelled due to minimum numbers of registered users are not met!' }
                            })
                        }
                    } catch (error) {
                        if (error?.response?.data?.msg) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.response.data.msg }
                            })
                        } else if (error?.response?.data?.error) {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.response.data.error }
                            })
                        }
                        else {
                            dispatch({
                                type: globalConstants.ALERT,
                                payload: { error: error.message }
                            })
                        }
                    }
                } else {
                    navigate(`/playquizz/${singleQuizz._id}`, { state: { user: user, isPastQuiz: false, isQuizzithonQuiz: singleQuizz.isQuizzithonQuiz, quizzithonId: quizzithonId } })
                }
            } else {
                navigate(`/playquizz/${singleQuizz._id}`, { state: { user: user, isPastQuiz: true } })
            }
        } else {
            const getUser = localStorage.getItem("urluser")
            if (!getUser) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "kindly log in the school quizz again!" }
                })
            }
            if (!statuses) {
                return dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: "Quiz is ended" }
                })

            }
            navigate(`/playquizz/${singleQuizz._id}`, { state: { user: getUser, isPastQuiz: false, isQuizzithonQuiz: singleQuizz.isQuizzithonQuiz, quizzithonId: quizzithonId } })
        }

    }
    useEffect(() => {
        // //console.log("it is from pas quiz",state)
        if (state?.isPastQuiz) {
            setisPastQuiz(true)
        }
    }, [state])
    return (
        <div className='w-full px-5'>

            {
                hidecomments &&
                <div className='p-4 '>
                    <img
                        onClick={() => navigate("/")}
                        className='object-contain w-52 cursor-pointer'
                        src={Logo} />
                </div>
            }
            <Cont className={hidecomments ? "md:px-14 px-5" : ""}>
                <Left>
                    <Top>
                        <div className='flex items-center space-x-3'>
                            <Avatar
                                src={singleQuizz?.createdBy?.profileImage}
                                sx={{
                                    width: 50,
                                    height: 50,
                                }}
                            />
                            <div className='flex flex-col'>
                                <h1 className='text-bgblue  text-lg'>Creater</h1>
                                <h1 className='text-sm'>{singleQuizz?.createdBy?.userName}</h1>

                            </div>
                        </div>
                        <div>
                            <h1 className='text-bgblue  text-lg'>Category</h1>
                            <h1 className='text-sm capitalize'>{(singleQuizz?.category?.name)?.replace(/_/g, ' ')}</h1>
                        </div>

                    </Top>
                    <Bottom>
                        <DashBoardTabs qid={qid} quizz={singleQuizz} />
                        {
                            !hidecomments &&
                            <CommentViewShareState qid={qid} />
                        }
                    </Bottom>
                </Left>
                <Right className='drop-shadow	'>
                    <div>
                        <h1 className='bg-mylightgreen p-5 text-center text-lg text-white rounded-t-xl'>{singleQuizz?.title}</h1>
                    </div>
                    <div className='p-3 bg-white grid grid-cols-1 gap-6 rounded-b-xl'>
                        <div className='flex items-center justify-between '>
                            <div className='flex items-center opacity-50 space-x-3'>
                                <SortOutlinedIcon />
                                <h1>Quiz type</h1>
                            </div>
                            {
                                singleQuizz.type === 'free'
                                    ?
                                    <div className='rounded-full py-1 px-3 flex items-center justify-center bg-green-400 '>
                                        <span className='text-white capitalize'>free</span>
                                    </div>
                                    :
                                    <div className='rounded-full py-1 px-3 flex items-center justify-center bg-red-400 '>
                                        <span className='text-white capitalize'>paid</span>
                                    </div>
                            }
                        </div>
                        <RightItems
                            Icon={AccessTimeOutlinedIcon}
                            data={
                                !isPastQuiz ?
                                    moment(singleQuizz.date).format("MMM Do YY")
                                    :
                                    "NA"
                            }
                            text='Date'
                        />
                        <RightItems
                            Icon={AccessTimeOutlinedIcon}
                            data={`${timer.days}:${timer.hours}:${timer.minutes}:${timer.seconds}`}
                            text='Time Remaining'
                        />
                        <RightItems
                            Icon={AccessTimeOutlinedIcon}
                            data={
                                !isPastQuiz ?
                                    moment(singleQuizz.time).format('LT')
                                    :
                                    "NA"
                            }
                            text='Start time'
                        />
                        <RightItems
                            Icon={AccessTimeOutlinedIcon}
                            data={
                                !isPastQuiz ?
                                    singleQuizz.isDurationBound ?
                                        moment(singleQuizz.durationBoundEndTime).format("MMMM Do YYYY, h:mm:ss a")
                                        :
                                        moment(singleQuizz.durationDateComplete).format("LT")
                                    :
                                    "NA"
                            }
                            text='End time'
                        />
                        <RightItems
                            Icon={AccessTimeOutlinedIcon}
                            data={
                                singleQuizz.isDurationBound ?
                                    `${singleQuizz.durationBoundMinutes} minutes`
                                    :
                                    `${singleQuizz.duration} minutes`
                            }
                            text="Duration"
                        />
                        <RightItems
                            Icon={HelpOutlineRoundedIcon}
                            data={dataTosho.numberOfQuestions}
                            text='Number of questions'
                        />
                        <RightItems
                            Icon={PersonRoundedIcon}
                            data={
                                !isPastQuiz ?
                                    singleQuizz.registerdUsers.length
                                    :
                                    "NA"
                            }
                            text='Number of participants'
                        />
                        <RightItems
                            Icon={PersonRoundedIcon}
                            data={
                                !isPastQuiz ?
                                    singleQuizz.type === 'free'
                                        ?
                                        singleQuizz.requiredPointsForFree
                                        :
                                        singleQuizz.price
                                    :
                                    "NA"
                            }
                            text='Registration fee'
                        />
                        {
                            !hidecomments &&
                            <>

                                {

                                    <button onClick={handlePlayOnClick} disabled={played || !cardStates.play}
                                        className='bg-yellow-500 text-white rounded-md py-2 w-[100%] ml-auto text-center outline-none border-none disabled:bg-yellow-300'
                                    >Play Quiz</button>
                                }

                            </>
                        }
                        {/* <button className='bg-bgblue text-white rounded-full p-1 px-2 text-[12px] absolute bottom-0 right-0'>Register</button> */}

                    </div>
                </Right>
            </Cont>
            {
                !hidecomments &&
                <>
                    <CommentContainer qid={qid} />
                    {!singleQuizz.isQuizzithonQuiz && <ReviewsSection qid={qid} />}
                </>
            }
        </div>
    )
}

const Right = styled.div`
${tw`lg:col-span-3 col-span-8`}
`
const Left = styled.div`
                    ${tw`lg:col-span-5 col-span-8  bg-white rounded-xl`}
                    `
const Bottom = styled.div`
                    ${tw``}
                    `
const Top = styled.div`
                    ${tw`grid lg:grid-cols-3 grid-cols-2 gap-4 p-5`}
                    `
const Cont = styled.div`
                    ${tw` grid grid-cols-8 gap-4 mt-5 `}
                    `

export default QuizzithonQuiz