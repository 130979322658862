import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
import QuizzCard from '../DashBoardComponets/QuizCards/QuizzCard'
import moment from 'moment'
import BGImage from '../../assets/bg/result.svg'
import tw from 'twin.macro'
//
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Logo from '../../assets/logo/logo.png'
const PlaySchoolQuizzC = ({ qid }) => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const [quizzFromBackend, setquizzFromBackend] = useState(null)
    const [quiz, setQuiz] = useState(null)
    const [userFromBackend, setuserFromBackend] = useState(null)
    const [otherDataFromBackend, setotherDataFromBackend] = useState(null)
    const [timer , setTimer] = useState(null)
    const [interval , setInterval] = useState(null)
    const [timeRemaining , setTimeRemaining] = useState(null)
    const [play , setPlay] = useState(false)
    const [currentState, setcurrentState] = useState({
        schoolQuizzPasscode: '',
        email: '',
        firstname: "",
        middlename: "",
        lastname: "",
        schoolname: "",
        classname: "",
        designation: "",
    })
    const {
        schoolQuizzPasscode,
        email,
        username,
        firstname,
        middlename,
        lastname,
        schoolname,
        classname,
        designation
    } = currentState;
    const onchangeHandler = (e) => {
        const { name, value } = e.target
        setcurrentState({
            ...currentState,
            [name]: value
        })
    }
    const getQuizz = async (passcode) => {
        try {
            const res = await axios.post(`/school/getquiz`, {
                passcode: passcode
            })
            setQuiz(res.data.quiz)
            console.log(res.data)
        } catch (error) {
            
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.response.data.msg }
            })
        }
    }
    useEffect(() => {
        if (quiz && quizzFromBackend && userFromBackend) {
            
            const interval = setInterval(() => {
                const currentTime = moment();
                let _tempUpdatingTime = new Date(quiz.time)
                let _tempDate = new Date(quiz.date)
                _tempUpdatingTime.setDate(_tempDate.getDate())
                _tempUpdatingTime.setMonth(_tempDate.getMonth())
                _tempUpdatingTime.setFullYear(_tempDate.getFullYear())

                const targetTime = moment(_tempUpdatingTime)
                // console.log(targetTime)
                const duration = moment.duration(targetTime.diff(currentTime));
                // console.log(duration)
                setTimer(duration)
                const days = Math.floor(duration.asDays()).toString().padStart(2, '0');
                const hours = duration.hours().toString().padStart(2, '0');
                const minutes = duration.minutes().toString().padStart(2, '0');
                const seconds = duration.seconds().toString().padStart(2, '0');
                // console.log(days, hours, minutes, seconds)
                setTimeRemaining(`${days}:${hours}:${minutes}:${seconds}`);
                console.log(quizzFromBackend , userFromBackend)
                // Clear the interval when the target time is reached
                if (duration.asMilliseconds() <= 0) {
                    clearInterval(interval);
                    setTimeRemaining('00:00:00:00');
                    navigate(`/playquizz/${quizzFromBackend._id}`, { state: { user: userFromBackend, isPastQuiz: false } })

                    

                }
            }, 1000);

            // Clean up the interval on component unmount
            return () => {
                clearInterval(interval);
            };
        }
    }, [ timer , quiz , quizzFromBackend , userFromBackend]);
    const handleOnClick = async () => {
        if (!(email).trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "email is required!" } })
        if (!(schoolQuizzPasscode).trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "passcode is required!" } })
        getQuizz(schoolQuizzPasscode)
            .then(async (res3) => {
                
                if(quiz){
                    if (!email) return dispatch({ type: globalConstants.ALERT, payload: { error: "email is required" } })
                    if (!username) return dispatch({ type: globalConstants.ALERT, payload: { error: "username is required" } })
                    if (!firstname) return dispatch({ type: globalConstants.ALERT, payload: { error: "firstname is required" } })
                    // if (!middlename) return dispatch({ type: globalConstants.ALERT, payload: { error: "middlename is required" } })
                    if (!lastname) return dispatch({ type: globalConstants.ALERT, payload: { error: "lastname is required" } })
                    if (!schoolname) return dispatch({ type: globalConstants.ALERT, payload: { error: "schoolname is required" } })
                    // if (!classname) return dispatch({ type: globalConstants.ALERT, payload: { error: "classname is required" } })
                    // if (!designation) return dispatch({ type: globalConstants.ALERT, payload: { error: "designation is required" } })
                    console.log(quiz)
                    const _obj = {
                        qid: quiz?._id,
                        schoolQuizzPasscode: schoolQuizzPasscode,
                        email: email,
                        username,
                        firstname,
                        middlename,
                        lastname,
                        schoolname,
                        classname,
                        designation
                    }
                    const res = await axios.post('/schoolquizz/varifypasscodeanduser', {
                        ..._obj
                    })
                    console.log(res.data)
                    setquizzFromBackend(res.data.quizz)
                    setuserFromBackend(res.data.user)
                    localStorage.setItem("urluser", JSON.stringify(res.data.user))
                    setotherDataFromBackend(res.data.otherData)
                    const newObj = {
                        user: res.data.user,
                        quizzId: res.data.quizz?._id,
                        username,
                        firstname: res.data.otherData.firstname,
    
                    }
                    const createResponse = await axios.post(`/gammer/create`, {
                        ...newObj,
                    })
                }
                
            })
            .catch((error) => {
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error?.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            })
    }
    return (
        <div>
            {
                // !(quizzFromBackend && userFromBackend) ?
                    <Cont BGImage={BGImage}>
                        {timeRemaining && <div className='px-2 flex items-center h-10 rounded-full bg-green-400 text-white'>Quiz Starts In {timeRemaining}</div>}
                        <div className='flex items-center justify-end'>
                            <img onClick={() => navigate('/')} className='h-[45px] cursor-pointer' src={Logo} alt='' />
                        </div>
                        <div className='flex flex-col space-y-3 items-center lg:w-[50%] w-[70%] bg-white p-5 rounded-md  lg:mt-0 mt-24'>
                            <h1 className='text-lg font-semibold text-center'>Enter Details</h1>
                            <div className='grid md:grid-cols-2 gap-4 w-full'>
                                <input placeholder='Email' name='email' value={email} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Passcode' name='schoolQuizzPasscode' value={schoolQuizzPasscode} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Username' name='username' value={username} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none capitlize' />
                                <input placeholder='Firstname' name='firstname' value={firstname} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Middlename (Optional)' name='middlename' value={middlename} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Lastname' name='lastname' value={lastname} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Schoolname/organization/company' name='schoolname' value={schoolname} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Classname (Optional)' name='classname' value={classname} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                                <input placeholder='Designation (Optional)' name='designation' value={designation} onChange={onchangeHandler} className='w-full border border-black p-2 rounded-md outline-none ' />
                            </div>
                            <button className='capitalize w-full border-none bg-bgblue text-white p-2 rounded-md outline-none' onClick={handleOnClick}>submit</button>
                        </div>
                    </Cont>
                    // :
                    // <div className='p-5'>
                    //     <div className='flex items-center justify-center'>

                    //     </div>
                    //     <div className='min-h-[80vh] flex flex-col space-y-2 items-center justify-center'>
                    //         <img onClick={() => navigate('/')} className='h-[45px]  cursor-pointer' src={Logo} alt='' />
                    //         {
                    //             <QuizzCard
                    //                 hidelayout={true}
                    //                 quizz={quizzFromBackend}
                    //                 user={userFromBackend}
                    //             />
                    //         }
                    //     </div>
                    // </div>
            }
        </div>
    )
}
const Cont = styled.div`
${tw`relative min-h-screen flex items-center justify-center flex-col space-y-5 mt-4 shadow-xl`}
/* background-image: url(${BGImage}) ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover; */
`
export default PlaySchoolQuizzC;