import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { globalConstants } from '../actions/appConstants';
import { useNavigate } from 'react-router-dom';
import QuizzCard from './DashBoardComponets/QuizCards/QuizzCard';

const SchoolCard = ({ base_url, qid, code, quizz, isUser = false }) => {
    const { auth } = useSelector(s => s)
    
    const dispatch = useDispatch()
    return (
        <QuizzCard
            quizz={quizz}
            user={auth.data.user._id}
            handleUpdatedQuizz
            setquestions
            handleArchive
            isSchoolQuiz={true}
            isUser={isUser}
            hidelayout = {isUser}
        />
    )
}

export default SchoolCard