import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
import { globalConstants, lifeLinesConstants } from '../../../actions/appConstants'
const Option = ({
    index,
    value,
    selectedOptionIndex,
    setselectedOptionIndex,
    correctAnswerFor
}) => {
    return (
        <div className='flex items-center  w-full '>
            <div className={`border h-10
             ${index === 0 ? 'bg-mydarkgreen' : ''}
             ${index === 1 ? 'bg-mydarkyellow' : ''}
              px-4 flex items-center justify-center`}>
                <span>{`
                  ${index === 0 ? 'A' : ''}
                  ${index === 1 ? 'B' : ''}
                  `}</span>
            </div>
            <span
                onClick={() => setselectedOptionIndex(value)}
                className={`hover:bg-mydarkgreen hover:text-white cursor-pointer border border-black w-full outline-none rounded-md border-opacity-50 px-2 py-2
                ${selectedOptionIndex === value ? 'bg-green-300' : correctAnswerFor === value ? "bg-yellow-200" : ""}
                `}
            >{value}</span>
        </div >
    )
}
const TrueFalse = ({
    handleLeaveQuestion,
    handleNextQuestion,
    question,
    isLastQuestion
}) => {
    const [correctAnswerFor, setcorrectAnswerFor] = useState(null)
    const dispatch = useDispatch()
    const { lifeLines } = useSelector(s => s)
    const {
        clickedFiftyFifty,
        clickedShowCorrectAnswer,
        clickedSkipQuestion
    } = lifeLines
    const [selectedOptionIndex, setselectedOptionIndex] = useState(null)
    const handleValidation = () => {
        if (selectedOptionIndex === null) return dispatch({ type: globalConstants.ALERT, payload: { error: "kindly select atleast one option!" } })
        let correct = false;
        if (question.correctAnswer === selectedOptionIndex) {
            correct = true;
        }
        handleNextQuestion({ ...{ ...question, userSubmission: selectedOptionIndex ? selectedOptionIndex : "" }, correct })
        setselectedOptionIndex(null)
    }
    const leaveQuestion = () => {
        handleNextQuestion({ ...{ ...question, userSubmission: selectedOptionIndex ? selectedOptionIndex : "" }, correct: false })
    }
    useEffect(() => {
        if (clickedShowCorrectAnswer) {
            setcorrectAnswerFor(question.correctAnswer)
        }
    }, [clickedShowCorrectAnswer])
    //handles which lifelines to show ...
    useEffect(() => {
        if (question) {
            dispatch({
                type: lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW,
                payload: {
                    showFifityFiftyLife: false,
                    showCorrectAnswerLife: true,
                    showSkipQuestionLife: false,
                }
            })
            dispatch({
                type: lifeLinesConstants.SHOW_LIFE_LINES,
                payload: true
            })
        }
    }, [question])
    //handles which lifelines to show ...
    return (
        <Cont>
            {
                ['true', 'false'].map((val, index) => (
                    <Option
                        selectedOptionIndex={selectedOptionIndex}
                        setselectedOptionIndex={setselectedOptionIndex}
                        key={index}
                        index={index}
                        value={val}
                        correctAnswerFor={correctAnswerFor}
                    />
                ))
            }
            <div className='mt-20 p-2 flex items-center justify-end text-sm col-span-2'>
                {/* <button onClick={leaveQuestion} className='py-2 px-3 bg-red-700 text-white rounded-2xl'>Leave quiz</button> */}
                <button onClick={handleValidation} className='py-2 px-3 bg-green-700 text-white rounded-2xl'>
                    {`${isLastQuestion ? 'Submit' : 'Next quiz'}`}
                </button>
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw`grid grid-cols-2 gap-10 lg:w-[50%] p-3 m-auto`}
`
export default TrueFalse