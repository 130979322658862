import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Quiz1 from '../../../assets/staticdata/quiz6.webp';
// import Quiz2 from '../../../assets/staticdata/quiz5.jpg';
// import Quiz3 from '../../../assets/staticdata/quiz4.jpg';
// import VectorQuiz from '../../assets/VectorQuiz.png';
// import VectorWhite from '../../../assets/staticdata/VectorWhite.png';
import { Box } from '@mui/material';
// import VectorBlack from '../../../assets/staticdata/VectorBlack.png';
// import Brain from '../../../assets/staticdata/brain.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';


import Quiz1 from '../../../assets/staticdata/quiz6.png';
import Quiz2 from '../../../assets/staticdata/quiz5.png';
import Quiz3 from '../../../assets/staticdata/quiz4.png';
// import VectorQuiz from '../../assets/VectorQuiz.png';
// import VectorWhite from '../../../assets/staticdata/VectorWhite.png';
// import VectorBlack from '../../../assets/staticdata/VectorBlack.png';
// import Brain from '../../../assets/staticdata/assets/brain.png';


const AboutQuiz = () => {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <div className='bg-gray-800 pt-10 mt-20'>
      <Typography className='text-yellow-500' variant={matches ? "h3" : "h5"} textAlign='center' sx={{ color: 'rgb(234 179 8 / var(--tw-text-opacity)', fontWeight: '400'  , fontSize : "bold"}}>3 Easy Step</Typography>
	  <Container>
        <Typography mt={3} variant='subtitle1' textAlign='center' sx={{ color: '#fff' }}>
          Quizzingbox.com is not just about taking quizzes, it is about challenging yourself, gathering knowledge every time and get rewarded for this knowledge. Every right hit let you win  rewards. So the game never stops, Excitements never end, & Reward never stop coming. You are just few clicks away from taking all those entertaining quizzes and rewarding in big time.
        </Typography>
      </Container>
      <Grid container className='!mt-10'>
        <Grid item md={4} xs={12}>
          <div style={{ textAlign: "center" }} className='bgclrHOUS flex items-center justify-center'>
            <img className='houseImg' src={Quiz1} alt="" />
          </div>
          <Typography className='pb-5 text-yellow-500' textAlign="center" variant={matches ? "h5" : "h5"} sx={{ color: "rgb(234 179 8 / var(--tw-text-opacity)", fontWeight: "400" }} >Create Quiz & Earn</Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <div style={{ textAlign: "center" }} className='bgclrHOUS flex items-center justify-center'>
            <img className='houseImg' src={Quiz2} alt="" />
          </div>
          <Typography className='pb-5 text-yellow-500' textAlign="center" variant={matches ? "h5" : "h5"} sx={{ color: "rgb(234 179 8 / var(--tw-text-opacity)", fontWeight: "400" }} >Play Quiz & Earn</Typography>

        </Grid>
        <Grid item md={4} xs={12}>
          <div style={{ textAlign: "center" }} className='bgclrHOUS flex items-center justify-center'>
            <img className='houseImg' src={Quiz3} alt="" />
          </div>
          <Typography className='pb-5 text-yellow-500' textAlign="center" variant={matches ? "h5" : "h5"} sx={{ color: "rgb(234 179 8 / var(--tw-text-opacity)", fontWeight: "400" }} >Get Rewarded</Typography>

        </Grid>
      </Grid>
    </div>
  )
}

export default AboutQuiz
