import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from './../components/Layout/Layout'
import ShowSelectedC from './../components/ShowSelectedCatQuizzezComponents/ShowSelectedCatC'
import { CircularProgress } from '@mui/material'
import MainTabs from './../components/DashBoardComponets/DashBoardMainTabe.js/MainTabs'
const ShowSelectedCatQuizzes = () => {
    const [cat, setcat] = useState('')
    const [sub, setsub] = useState('')
    const { category, subject } = useParams()
    useEffect(() => {
        if (category && subject) {
            setcat(category)
            setsub(subject)
        }
    }, [category, subject])
    return (
        <Layout>
            {
                !cat || !sub ?
                    <div className='flex items-center justify-center min-h-[400px]'>
                        <CircularProgress />
                    </div>
                    :
                    <MainTabs
                        cat={cat}
                        sub={sub}
                    />
                // <ShowSelectedC sub={sub} cat={cat} />
            }
        </Layout>
    )
}

export default ShowSelectedCatQuizzes