import React, { useState } from 'react'

const SortingForm = ({ addNewBox }) => {
    const [newName, setnewName] = useState('')
    const handleCreate = () => {
        if (!newName.trim()) return;
        addNewBox(newName, setnewName)
    }
    return (
        <div className='lg:w-[30%] w-full mt-5 m-auto  space-y-3 border border-gray-300 py-3 px-5'>
            <input type={'text'} value={newName} onChange={(e) => setnewName(e.target.value)} placeholder='name the box name...' className='w-full p-2 bg-transparent border border-gray-400 outline-none' />
            <button onClick={handleCreate} className='bg-bgblue text-white w-full text-center p-1'>Add</button>
        </div>
    )
}

export default SortingForm