import axios from '../utils/myAxios'
import { authConstants, globalConstants, quizzSchedulerConstants, walletConstants } from './appConstants'
import store from './../store'
// const dispatch = store.dispatch
export const quizzithonActions = {
    UPDATE_QUZZITHON: 'UPDATE_QUZZITHON',
    UPDATE_QUIZ: 'UPDATE_QUIZ',
    REGISTER_QUIZZITHON: 'REGISTER_QUIZZITHON',
    SCHEDULE_QUIZZITHON: 'SCHEDULE_QUIZZITHON',
    INITIALIZE_QUIZZITHON: 'INITIALIZE_QUIZZITHON'
}

export const updateQuizzithon = () => {
    axios.update('/update/quizzithon')
        .then(data => data)
        .catch()
}
export const registerInQuizzithonAction = (quizzithon, handleQuizzithonUpdate, setOpen) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            setOpen(false)
            const res = await axios.post('/register/quizzithon', {
                quizzithonId: quizzithon._id.valueOf(),
                userId: store.getState().auth.data.user._id.valueOf()
            })

            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
            handleQuizzithonUpdate(res.data.res_)
        } catch (error) {

            setOpen(false)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        }
    }

}
export const unRegisterQuizzithon = (quizzithon, handleQuizzithonUpdate) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            let res = await axios.post('/unregister/quizzithon', {
                quizzithonId: quizzithon._id.valueOf(),
                userId: store.getState().auth.data.user._id.valueOf()
            })
            handleQuizzithonUpdate(res.data.res_)
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })
        } catch (error) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: false }
            })

        }



    }


}
export const scheduleQuizzithon = (navigate) => {
    return async (dispatch) => {
        const quizzithon = store.getState().quizzithon.quizzithon

        dispatch({
            type: globalConstants.ALERT,
            payload: { loading: true }
        })
        if (quizzithon.title.length === 0) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'Quizzithon Title is required' }
            })
        }
        else if (quizzithon.reg_fee < 20) {
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'Quizzithon Registeration fees is required' }
            })
         }
        else if (quizzithon.no_quizzies === 0 || quizzithon.no_quizzes === null) { 
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'Quizzithon should have atleast 1 quiz' }
            })
        }
        else if (quizzithon.min_participants === 0 || quizzithon.min_participants === null) { 
            dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'Min participants should be greater than 0' }
            })
        }
        else {
            axios.post('/create/quizzithon', quizzithon)
                .then(() => {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { loading: false }
                    })
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { success: 'Quizzithon Scheduled Successfully' }
                    })
                    dispatch({ type: quizzithonActions.INITIALIZE_QUIZZITHON })
                    navigate('/dashboard')
                })
                .catch((err) => {
                    console.log(err.response.data.msg)
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { loading: false }
                    })
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: err.response.data.msg }
                    })
                })

        }





    }
}
