import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PercentageCriterias from './PercentageCriterias'
import SubScriptions from './subscriptions/SubScriptions'
import Subjects from './Subjects'
import QuizzRules from './QuizzRules';
import HowItWorks from './HowItWorks';
import AboutInfo from '../AboutInfo';
import QuizzithonRules from './QuizzithonRules';
import QzetosPackages from './QzetosPackages/QzetosPackages';
import DashboardBannerUpload from './DashboardBannerUpload';
import Faqs from './Faqs';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs scrollButtons
                    allowScrollButtonsMobile variant="scrollable" value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Percentage Criteria" {...a11yProps(0)} />
                    <Tab label="Subjects" {...a11yProps(1)} />
                    {/* <Tab label="Subscriptions" {...a11yProps(2)} /> */}
                    <Tab label="Qzeto Packages" {...a11yProps(2)} />
                    <Tab label="Quiz Rules" {...a11yProps(3)} />
                    <Tab label="How it Works" {...a11yProps(4)} />
                    <Tab label="About Info" {...a11yProps(5)} />
                    <Tab label="Quizzithon Rules" {...a11yProps(6)} />
                    <Tab label="Dashboard Banner" {...a11yProps(7)} />
                    <Tab label="FAQs." {...a11yProps(8)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}> 
                <PercentageCriterias />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Subjects />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <QzetosPackages />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <QuizzRules />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <HowItWorks />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <AboutInfo />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <QuizzithonRules />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <DashboardBannerUpload />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <Faqs />
            </TabPanel>
        </Box>
    );
}
