import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { extrasConstancts } from '../../actions/appConstants'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper";




import aboutus from '../../assets/staticdata/aboutus.png'
import proImg1 from "../../assets/staticdata/1.png"
import proImg2 from "../../assets/staticdata/2.png"
import proImg3 from "../../assets/staticdata/3.png"
import proImg4 from "../../assets/staticdata/4.png"



function Banner() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <div className='relative w-full bg-white flex flex-col justify-center items-center mb-10'>
            <div className='absolute opacity-50 overflow-x-hidden top-[-20%] md:top-[-10%] right-[40%] w-72 h-72 rounded-full bg-[#F41340] blur-3xl blurcss'></div>
            <div className='absolute opacity-50 overflow-x-hidden top-[30%] md:top-[20%] right-[-50%] md:right-[-20%] w-72 h-72 rounded-full bg-[#F5C407] blur-3xl blurcss'></div>
            <div className='absolute opacity-50 overflow-x-hidden left-[-40%] md:left-[-10%] top-[50%] sm:top-[30%] w-72 h-72 rounded-full bg-[#32D8C7] blur-3xl blurcss'></div>
            <Swiper 
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                pagination = {{
                    clickable : true, 
                }}
                modules={[Autoplay ,Pagination]} className="mySwiper w-full h-full pb-10 md:pb-5">
                {/* First slide */}
                <SwiperSlide>
                    <div className='w-full flex justify-center'>
                    <div className='w-[80%] flex flex-col md:flex-row justify-between items-center z-30'>
                        <div className='w-[100%] md:w-[40%] flex flex-col justify-between '>
                            <h1 className='text-[30px] md:text-[36px]'>Welcome To <br /><span className='text-yellow-500 font-semibold p-0'>QuizzingBox</span></h1>
                            <div className='flex mt-6 flex-wrap '>
                                <span className='mb-4 cursor-pointer w-40 h-6 md:h-10 flex items-center justify-center text-center rounded-full bg-[#223344] text-white p-3 mr-3 hover:border-yellow-500 border hover:bg-yellow-500' onClick={() => dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true
                                })}>Get Started</span>
                                <span className='cursor-pointer flex items-center justify-center w-40 h-6 md:h-10 text-center rounded-full border-[#223344] text-[#223344] p-3 hover:border-yellow-500 border hover:text-yellow-500 hover:bg-white ' onClick={() => navigate('/quizmasterpro/quizzes')}>Quiz Master Pro</span>
                            </div>

                        </div>
                        <div className='w-[100%] md:w-[60%]'>
                            <img className='w-full' src={aboutus} alt="" />
                        </div>
                    </div>
                    </div>
                </SwiperSlide>
                {/* Second slide */}
                <SwiperSlide>
                    <div className='w-full flex justify-center'>
                    <div className='w-[80%] flex flex-col md:flex-row justify-between items-center z-30'>
                        
                        <div className='w-[100%] md:w-[50%]'>
                            <img className='w-full' src={proImg1} alt="" />
                        </div>
                        <div className='w-[100%] md:w-[40%] flex flex-col justify-between '>
                            <h1 className='text-[30px] md:text-[36px]'>Play Quiz &<br /><span className='text-yellow-500 font-semibold p-0'>Earn Rewards</span></h1>
                            <div className='flex mt-6 flex-wrap '>
                                <span className='mb-4 cursor-pointer w-40 h-6 md:h-10 flex items-center justify-center text-center rounded-full bg-[#223344] text-white p-3 mr-3 hover:border-yellow-500 border hover:bg-yellow-500' onClick={() => dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true
                                })}>Get Started</span>
                                <span className='cursor-pointer flex items-center justify-center w-40 h-6 md:h-10 text-center rounded-full border-[#223344] text-[#223344] p-3 hover:border-yellow-500 border hover:text-yellow-500 hover:bg-white ' onClick={() => navigate('/quizmasterpro/quizzes')}>Quiz Master Pro</span>
                            </div>

                        </div>
                    </div>
                    </div>
                </SwiperSlide>
                {/* 3rd slide */}
                <SwiperSlide>
                    <div className='w-full flex justify-center'>
                    <div className='w-[80%] flex flex-col md:flex-row justify-between items-center z-30'>
                        <div className='w-[100%] md:w-[40%] flex flex-col justify-between '>
                            <h1 className='text-[30px] md:text-[36px]'>Build Knowladge in an<br /><span className='text-yellow-500 font-semibold p-0'>Inovative & Rewarding way</span></h1>
                            <div className='flex mt-6 flex-wrap '>
                                <span className='mb-4 cursor-pointer w-40 h-6 md:h-10 flex items-center justify-center text-center rounded-full bg-[#223344] text-white p-3 mr-3 hover:border-yellow-500 border hover:bg-yellow-500' onClick={() => dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true
                                })}>Get Started</span>
                                <span className='cursor-pointer flex items-center justify-center w-40 h-6 md:h-10 text-center rounded-full border-[#223344] text-[#223344] p-3 hover:border-yellow-500 border hover:text-yellow-500 hover:bg-white ' onClick={() => navigate('/quizmasterpro/quizzes')}>Quiz Master Pro</span>
                            </div>

                        </div>
                        <div className='w-[100%] md:w-[60%]'>
                            <img className='w-full' src={proImg2} alt="" />
                        </div>
                    </div>
                    </div>
                </SwiperSlide>
                {/* 4th slide */}
                <SwiperSlide>
                    <div className='w-full flex justify-center'>
                    <div className='w-[80%] flex flex-col md:flex-row justify-between items-center z-30'>
                        
                        <div className='w-[100%] md:w-[50%]'>
                            <img className='w-full' src={proImg3} alt="" />
                        </div>
                        <div className='w-[100%] md:w-[40%] flex flex-col justify-between '>
                            <h1 className='text-[30px] md:text-[36px]'>Become Our Quiz Master &<br /><span className='text-yellow-500 font-semibold p-0'>Build a quizzing community</span></h1>
                            <div className='flex mt-6 flex-wrap '>
                                <span className='mb-4 cursor-pointer w-40 h-6 md:h-10 flex items-center justify-center text-center rounded-full bg-[#223344] text-white p-3 mr-3 hover:border-yellow-500 border hover:bg-yellow-500' onClick={() => dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true
                                })}>Get Started</span>
                                <span className='cursor-pointer flex items-center justify-center w-40 h-6 md:h-10 text-center rounded-full border-[#223344] text-[#223344] p-3 hover:border-yellow-500 border hover:text-yellow-500 hover:bg-white ' onClick={() => navigate('/quizmasterpro/quizzes')}>Quiz Master Pro</span>
                            </div>

                        </div>
                    </div>
                    </div>
                </SwiperSlide>
                {/* 5th slider */}
                <SwiperSlide>
                    <div className='w-full flex justify-center'>
                    <div className='w-[80%] flex flex-col md:flex-row justify-between items-center z-30'>
                        <div className='w-[100%] md:w-[40%] flex flex-col justify-between '>
                            <h1 className='text-[30px] md:text-[36px]'>Schedule quiz & <br /><span className='text-yellow-500 font-semibold p-0'>Get rewarded</span></h1>
                            <div className='flex mt-6 flex-wrap '>
                                <span className='mb-4 cursor-pointer w-40 h-6 md:h-10 flex items-center justify-center text-center rounded-full bg-[#223344] text-white p-3 mr-3 hover:border-yellow-500 border hover:bg-yellow-500' onClick={() => dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true
                                })}>Get Started</span>
                                <span className='cursor-pointer flex items-center justify-center w-40 h-6 md:h-10 text-center rounded-full border-[#223344] text-[#223344] p-3 hover:border-yellow-500 border hover:text-yellow-500 hover:bg-white ' onClick={() => navigate('/quizmasterpro/quizzes')}>Quiz Master Pro</span>
                            </div>

                        </div>
                        <div className='w-[100%] md:w-[60%]'>
                            <img className='w-full' src={proImg4} alt="" />
                        </div>
                    </div>
                    </div>
                </SwiperSlide>
                
            </Swiper>
            
            <div className='w-[90%] md:w-[80%] flex flex-col justify-center items-center text-center leading-[1.75]'>
                <span className='text-[16px] pt-5'>If you are looking for a fun and beneficial way to spend your time, then quiz games are a great option. They offer a variety of benefits that can improve your memory, knowledge, critical thinking skills, confidence, focus, motivation, social skills, and stress levels.<br /></span>
                <span className='w-[80%] text-[16px] text-center'>Quizzingbox.com helps improve yourself in all of these areas, thereby helping you build your knowledge in an innovative and rewarding way</span>
            </div>
            
        </div>
    )
}

export default Banner