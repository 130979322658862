import React, { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import axios from "../../utils/myAxios";
import { useDispatch, useSelector } from "react-redux";
import { getUserStaticTabData, keepUserLoggedIn } from "../../actions/auth.actions"
import {
  backend_url,
  extrasConstancts,
  globalConstants,
  showBuyInfoModalEnums,
} from "../../actions/appConstants";
import BuyRealQzetosCard from "./BuyRealQzetosCard";
import { CircularProgress } from "@mui/material";
import { set } from "lodash";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
// const backend_url = 'http://localhost:5000/api'
const QzetosPlans = ({ showBuyQzetosModal }) => {
  const [percentageData, setpercentageData] = useState(1);
  const [loading, setloading] = useState(false);
  const [orderIdData, setorderIdData] = useState(null);
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const dispatch = useDispatch();
  const { auth } = useSelector((s) => s);
  const [numberOfRealQzetos, setnumberOfRealQzetos] = useState(1);
  //
  const [toBuyQzetos, settoBuyQzetos] = useState(1);
  //razorpay
  const displayRazorPay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      return alert("Razorpay SDK failed to load, Are you online!");
    }

    var options = {
      key: process.env.REACT_APP_RAZOR_KEY_ID, // Enter the Key ID generated from the Dashboard
      // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: orderIdData.currency,
      name: "Buy Subscription",
      description: "Your going to buy our subscription!",
      image: "",
      order_id: orderIdData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async (response) => {
        try {
          dispatch({
            type: globalConstants.ALERT,
            payload: { loading: true },
          });
          const resObj = {
            ...response,
            orderId: orderIdData.id,
          };
          const rsobjres = await axios.post(
            "/varify/razorpay/signatures/forqzetos",
            {
              ...resObj,
            }
          );
          if (rsobjres.status === 200) {
            const _obj = {
              numberOfRealQzetos: toBuyQzetos,
              uid: auth.data.user._id,
              plan: plan,
            };
            const payRes = await axios.post("/addrealqzetos/afterpayment", {
              ..._obj,
            });
            const historyRes = {
              user: auth.data.user._id,
              payment_id: response.razorpay_payment_id,
              amount: orderIdData.amount / 100,
              numberOfQzetos: toBuyQzetos,
            };
            const histoRes = await axios.post("/create/boughtqzeto/history", {
              ...historyRes,
              ...plan,
            });
            dispatch({
              type: globalConstants.ALERT,
              payload: { loading: false },
            });
            //hide the Qzetos buy modal first..
            dispatch({
              type: extrasConstancts.SHOW_BUY_QZETO_MODAL,
              payload: false,
            });
            dispatch(getUserStaticTabData(auth.data.user._id));
            dispatch(keepUserLoggedIn());
            dispatch({
              type: extrasConstancts.SHOW_BUY_INFO_MODAL,
              payload: {
                open: true,
                title: `Successfully bought ${histoRes.data.numberOfQzetos} Qzetos`,
                description: "Bought qzetos are added to your wallet.",
                numbers: 100,
                type: showBuyInfoModalEnums.QZETOS,
              },
            });
          }
        } catch (error) {
          //console.log('error==>', error)
          //console.log('error==>', error.response)

          if (error?.response?.data?.msg) {
            dispatch({
              type: globalConstants.ALERT,
              payload: { error: error.response.data.msg },
            });
          } else if (error?.response?.data?.error) {
            dispatch({
              type: globalConstants.ALERT,
              payload: { error: error.response.data.error },
            });
          } else {
            dispatch({
              type: globalConstants.ALERT,
              payload: { error: error.message },
            });
          }
        }
      },
      prefill: {},
      notes: {
        user_id: auth.data.user._id,
        numberOfRealQzetos: toBuyQzetos,
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: response.error.reason },
      });
    });
    rzp1.open();
  };
  //razorpay
  const handleBuyNow = async (qzetos, amount, plan) => {
    try {
      setPlan(plan);
      //get the order _id from backend..
      const obj = {
        numberOfRealQzetos: qzetos,
        numberOfRealQzetosAmount: amount,
      };

      settoBuyQzetos(qzetos);
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: true },
      });
      const res = await axios.post("/buyreal/create/order", {
        ...obj,
      });
      setorderIdData(res.data.order);
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: false },
      });
    } catch (error) {
      dispatch({
        type: globalConstants.ALERT,
        payload: { loading: false },
      });
      dispatch({
        type: globalConstants.ALERT,
        payload: { error: error.message },
      });
    }
  };
  useEffect(() => {
    if (orderIdData) {
      displayRazorPay();
    }
  }, [orderIdData]);
  useEffect(() => {
    const getSettingsData = async () => {
      try {
        const res = await axios.get("/get/percentagecriteria");
        //console.log('get settings data==>', res.data)
        setpercentageData(res.data.data[0].buyPriceOfOneINR);
        setnumberOfRealQzetos(res.data.data[0].buyPriceOfOneINR);
      } catch (error) {}
    };
    getSettingsData();
  }, []);
  useEffect(() => {
    setloading(true);
    axios
      .get("/getQzetosPlans")
      .then((res) => {
        setloading(false);
        setPlans(res.data.plans);
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div
        className={`grid ${
          showBuyQzetosModal ? "lg:grid-cols-3" : "lg:grid-cols-3"
        } grid-cols-1 gap-4 !scrollbar-hide`}
      >
        {/* {
                    [20, 50, 100, 200, 300, 500, 1000, 2000, 5000].map((val, index) => (
                        <BuyRealQzetosCard
                            key={index}
                            price={Number(numberOfRealQzetos * val)}
                            qzetos={Number(val)}
                            handleBuyNow={handleBuyNow}
                        />
                    ))
                } */}
        <>
          {loading ? (
            <div className="flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              {plans.map((plan, index) => (
                <BuyRealQzetosCard
                  key={index}
                  price={Number(plan.price)}
                  qzetos={Number(plan.amount)}
                  quizzes={Number(plan.quizzes)}
                  quizzithon={Number(plan.quizzithon)}
                  quizmaster_pro={Number(plan.quizmaster_pro)}
                  ai_questions={Number(plan.ai_questions)}
                  plan={plan}
                  handleBuyNow={handleBuyNow}
                />
              ))}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default QzetosPlans;
