import { authConstants } from "../actions/appConstants";
// playedQuizzes: 3
// scheduledQuizzes: 1
// questionsSubmitted: 2
// approvedQuestions: 1
// rejectedQuestions: 1
const inititalState = {
    playedQuizzes: 0,
    scheduledQuizzes: 0,
    questionsSubmitted: 0,
    approvedQuestions: 0,
    rejectedQuestions: 0
}


const staticTabsData = (state = inititalState, action) => {
    switch (action.type) {
        case authConstants.GET_USER_STATIC_TABS:
            state = action.payload
            break;
    }
    return state;
}
export default staticTabsData;