import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from './actions/appConstants'
import Layout from './components/Layout/Layout'
import axios from './utils/myAxios'
const MakeMeAdmin = () => {
    const { auth } = useSelector(s => s)
    const dispatch = useDispatch()

    const handleMakeMeAdmin = async () => {
        dispatch({ type: globalConstants.ALERT, payload: { loading: true } })
        await axios.post(`/create/root/${auth.data.user._id}`)
        dispatch({ type: globalConstants.ALERT, payload: { success: 'you are admin now refresh your app' } })
    }
    return (
        <Layout>
            <div className='min-h-[70vh] w-full flex items-center justify-center'>
                <button className='bg-bgblue text-white py-3 px-5 rounded-md' onClick={handleMakeMeAdmin}>Make Me Admin</button>
            </div>
        </Layout>
    )
}

export default MakeMeAdmin