import React from 'react'
import NoDataIcon from './../../assets/browser.png'
const NotFoundDataUi = ({ text }) => {
    return (
        <div className='flex items-center justify-center flex-col space-y-3 opacity-50 mt-10'>
            <img
                src={NoDataIcon}
                className='w-10 object-contain'
            />
            <h1 className='w-[350px] capitalize font-semibold text-center'>{text}</h1>

        </div>
    )
}

export default NotFoundDataUi