import { CircularProgress } from '@mui/material'
import axios from '../../../../utils/myAxios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { globalConstants } from '../../../../actions/appConstants'
const QuizzRules = ({ quizz }) => {
  //console.log('quizz==>', quizz)
  const [loading, setloading] = useState(false)
  const [rules, setrules] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (quizz) {
      const getData = async () => {
        setloading(true)
        try {
          
          const res = await axios.get(`/get/categoryrule/${quizz.isQuizzithonQuiz && quizz.status === "open" ? quizz.category :quizz.category._id}`)
          setrules(res.data?.rules?.rules)
          setloading(false)
        } catch (error) {
          setloading(false)
          dispatch({
            type: globalConstants.ALERT,
            payload: { error: error.message }
          })
        }
      }
      getData()
    }
  }, [quizz])
  return (
    <>
      {
        loading ?
          <div className='flex items-center justify-center'>
            <CircularProgress />
          </div>
          :
          rules ?
            <div className='w-full text-lg px-10 py-3 list' 
              dangerouslySetInnerHTML={{ __html: rules }}
            />
            // null
            :
            null
      }
    </>
  )
}

export default QuizzRules