import { subjectConstants } from "../actions/appConstants";

const inititalState = {
    subjects: []
}


const Subjects = (state = inititalState, action) => {
    switch (action.type) {
        case subjectConstants.GET_ALL_SUBJECTS:
            state = {
                ...state,
                subjects: action.payload
            }
            break;
        case subjectConstants.CREATE_SUBJECT:
            state = {
                ...state,
                subjects: [...state.subjects, action.payload]
            }
            break;
        case subjectConstants.DELETE_SUBJECT:
            state = {
                ...state,
                subjects: state.subjects.filter(val => val._id !== action.payload)
            }
            break;
        case subjectConstants.UPDATE_SUBJECT:
            state = {
                ...state,
                subjects: state.subjects.map(val => val._id === action.payload._id ? action.payload : val)
            }
            break;
    }
    return state;
}
export default Subjects; 