import { stripeActions } from "../actions/stripeActions";

const inititalState = {
    stripeClientSecret: '',
    stripeModalStatus: false,
    plan : {}
}

const stripeReducer = (state = inititalState, action) => {
    switch (action.type) {
        case stripeActions.SET_STRIPE_CLIENT_SECRET:
            state = {
                ...state,
                stripeClientSecret: action.payload, 
                stripeModalStatus : true
            }
            break;
        case stripeActions.SET_MODAL_STATUS:
            console.log('action.payload==>', action.payload)
            state = {
                ...state,
                stripeModalStatus: action.payload,
                stripeClientSecret : "",
                plan : {}
            }
            break;
        case stripeActions.SET_PLAN:
            state = {
                ...state,
                plan: action.payload
            }
            break;
        default:
    }
    return state;
}

export default stripeReducer;
