import { useSelect } from '@mui/base'
import { data } from 'autoprefixer'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { extrasConstancts } from '../actions/appConstants'
import { loginActions } from '../actions/auth.actions'
import BGImage from './../assets/bg/result.svg'
import Logo from './../assets/logo/logo.png'
import PhoneInput from 'react-phone-number-input'
const Login = ({ isPopUp = false }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [login_option, setOption] = useState('email')
    const [value, setValue] = useState('')
    const [formData, setformDAta] = useState({
        email_number: "",
        password: ""
    })
    const auth = useSelector(state => state.auth)
    const changeOption = (option)=>{
        setOption(option)
    }
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        setformDAta({
            ...formData,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        
        dispatch(loginActions({...formData , value}, navigate))
    }
    useEffect(() => {
        if (auth.authenticate) {
            navigate('/dashboard', { replace: true })
        }
    }, [auth.authenticate])
    useEffect(()=>
    {

    },[login_option])
    return (
        <Container isPopUp={isPopUp} BGImage={BGImage}>
            {/* {
                isPopUp ?
                    null
                    :
                    <img onClick={() => navigate('/')} className='absolute top-2 left-2 h-[40px] cursor-pointer' src={Logo} alt='' />
            } */}
            <MinCont isPopUp={isPopUp}>
                <div className='flex items-center justify-center my-6'>
                    <img onClick={() => navigate('/')} className='w-[12rem] object-contain cursor-pointer' src={Logo} alt='' />
                </div>
                <h1 className='text-xl !mt-0 text-center capitalize font-semibold select-none' onCopy = {()=>{console.log("hello")}}>log in</h1>
                <form className='space-y-7' onSubmit={handleSubmit}>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <div className="flex flex-row items-center">
                            
                            <input className='mr-3' type="radio" name = "login_option" value = "email" checked= {login_option ==='email' ? true : false} onClick={()=>{changeOption('email')}}/>
                            <label className='mr-3'>Login by e-Mail</label>
                            
                            <input className='mr-3' type="radio" name = "login_option" value = "number" checked= {login_option ==='number' ? true : false} onClick={()=>{changeOption('number')}}/>
                            <label htmlFor="">Login by Phone</label>
                        </div>
                        <h1 className='font-bold'>Enter your {login_option ==="email"?'Email': "Phone No" }</h1>

                        
                        {login_option === "email" ? <input
                            value={formData.email_number}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder={`Enter your ${login_option ==="email"?'Email': "Phone No" }...`}                            name='email_number'
                            onChange={onChangeHandler}
                            type= {login_option}
                            required
                        /> : 
                        <PhoneInput
                        international
                        countryCallingCodeEditable={true}
                        limitMaxLength =  {true}
                        placeholder = "Enter your phone no"
                        defaultCountry="IN"
                        name = "phone_number"
                        value={value}
                        onChange={setValue}
                    />
                        }
                    </div>
                    <div className='w-[90%] ml-auto mr-auto space-y-2'>
                        <h1 className='font-bold'>Password</h1>
                        <input
                            required
                            type='password'
                            value={formData.password}
                            onChange={onChangeHandler}
                            className='border border-black  outline-none w-full rounded-md p-2'
                            placeholder='Enter your password...'
                            name='password'
                        />
                    </div>
                    <h1 className='text-sm w-[90%] m-auto' onClick={() => {
                        if (isPopUp) {
                            dispatch({
                                type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                                payload: false,
                            })
                        }
                        navigate('/resetpasswordrequest')
                    }}>
                        fogot password?
                        <span className='text-blue-900 cursor-pointer font-semibold'> Reset your password</span></h1>
                    <div className=' relative  w-[90%] ml-auto mr-auto border border-blue-600 bg-blue-600 text-white p-5 rounded-md cursor-pointer'>
                        <button className='absolute top-0 left-0 bottom-0 right-0 font-bold text-center'>Log In</button>
                    </div>
                    {
                        isPopUp ?
                            <h1 className='text-center'>Don't have an account? <span onClick={() => {
                                dispatch({
                                    type: extrasConstancts.SHOW_SIGN_IN_MODAL,
                                    payload: false,
                                })
                                dispatch({
                                    type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                    payload: true,
                                })
                            }} className='text-blue-900 cursor-pointer font-semibold uppercase'>sign up</span></h1>
                            :
                            <h1 className='text-center'>Don't have an account? <span onClick={() => navigate('/signup')} className='text-blue-900 cursor-pointer font-semibold uppercase'>sign up</span></h1>
                    }
                </form>
            </MinCont>
        </Container>
    )
}
const MinCont = styled.div`
${(props) => props.isPopUp ? tw`w-full` : tw`lg:w-[30%] w-[90%] `}
${tw` bg-white space-y-10 p-2 rounded-xl`}
`
const Container = styled.div`
${(props) => props.isPopUp ? tw`` : tw`h-screen`}
${tw`relative  flex items-center justify-center`}
background-image:${props => props.isPopUp ? '' : `url(${props.BGImage})`} ;
background-position: bottom left top right;
background-size: cover;
background-repeat: no-repeat;
background-size: cover;
`
export default Login