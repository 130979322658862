import React, { useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import tw from 'twin.macro';
import { alphabetsConstants } from '../../../actions/appConstants';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
const finalSpaceCharacters = [
    {
        id: 'gary',
        name: 'Gary Goodspeed',
        thumb: '/images/gary.png'
    },
    {
        id: 'cato',
        name: 'Little Cato',
        thumb: '/images/cato.png'
    },
    {
        id: 'kvn',
        name: 'KVN',
        thumb: '/images/kvn.png'
    },
    {
        id: 'mooncake',
        name: 'Mooncake',
        thumb: '/images/mooncake.png'
    },
    {
        id: 'quinn',
        name: 'Quinn Ergon',
        thumb: '/images/quinn.png'
    }
]
const MyDragAbleC = ({ column, setc }) => {
    const [ch, setch] = useState(finalSpaceCharacters)
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(column);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setc(items);
    }
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='characters'>
                {
                    (provided) => (
                        <Cont {...provided.droppableProps} ref={provided.innerRef}>
                            {
                                column.map((val, index) => (
                                    <div key={index}>
                                        <Draggable key={val} draggableId={val} index={index}>
                                            {
                                                (provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <div className='flex items-center  w-full '>
                                                            <div className={`border h-10
            
              px-4 flex items-center justify-center`}>
                                                                <span><DragIndicatorIcon /></span>
                                                            </div>
                                                            <span
                                                                className='bg-white shadow-lg cursor-pointer border border-black w-full outline-none rounded-md border-opacity-50 px-2 py-2'
                                                            >{val}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Draggable>
                                    </div>
                                ))
                            }
                            {provided.placeholder}
                        </Cont>
                    )
                }
            </Droppable>
        </DragDropContext>
    )
}
const Cont = styled.div`
${tw`space-y-3`}
`
export default MyDragAbleC;