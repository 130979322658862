import React, { useState , useEffect } from 'react'
import RegisterQuizzithonModal from './RegisterQuizzithonModal'
import { Avatar } from '@mui/material'
import { useSelector , useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {unRegisterQuizzithon} from '../../actions/quizzithon.actions'
import moment from 'moment'
import first from '../../assets/prizez/1.svg'
import second from '../../assets/prizez/2.svg'
import thrid from '../../assets/prizez/3.svg'

import { set } from 'lodash'


function QuizzithonCard({ quizzithon , handleQuizzithonUpdate }) {
    const [dummy, setDummy] = useState('https://images.unsplash.com/photo-1550751827-4bd374c3f58b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8dGVjaG5vbG9neXxlbnwwfDB8MHx8&auto=format&fit=crop&w=600&q=60')
    const { auth } = useSelector(state => state)
    const [play , setPlay] = useState(false)
    const [canRegister , setCanRegister] = useState(true)
    const [open , setOpen] = useState(false)
    const [quizzithonData, setQuizzithonData] = useState(quizzithon)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const prize = Number(quizzithon.registerdUsers.length *  quizzithon.reg_fee) - 10
    let firstPrize = prize * 0.5
    let secondPrize = prize * 0.3
    let thirdPrize = prize * 0.2
    const getTime = (isoString) => {
        const date = new Date(isoString)
        const hours = date.getHours()
        const minutes = date.getMinutes()
        return `${hours}:${minutes}`


    }
    
    const [timeRemaining, setTimeRemaining] = useState('');

    const handleRegister = ()=>{
        setOpen(true)
    }
    const handleUnRegister = ()=>{
        dispatch(unRegisterQuizzithon(quizzithon , handleQuizzithonUpdate))
    }
    useEffect(() => {
        setQuizzithonData(quizzithon)
        
        
    }, [quizzithon])
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = moment();
      const targetTime = moment(quizzithon.start_time);

      const duration = moment.duration(targetTime.diff(currentTime));

      const days = Math.floor(duration.asDays()).toString().padStart(2, '0');
      const hours = duration.hours().toString().padStart(2, '0');
      const minutes = duration.minutes().toString().padStart(2, '0');
      const seconds = duration.seconds().toString().padStart(2, '0');

      setTimeRemaining(`${days}:${hours}:${minutes}:${seconds}`);

      // Clear the interval when the target time is reached
      if (duration.asMilliseconds() <= 0) {
        clearInterval(interval);
        setTimeRemaining('00:00:00:00');
        setCanRegister(false)
        setPlay(true)
        
        if(quizzithon.registerdUsers.findIndex(user=>user.user === auth.user?._id) !== -1 && quizzithonData.quizzes.length > 0 && !play){
            
            setPlay(true)
        }
        
      }
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);
    return (
        <div className='bg-white border rounded-2xl  flex flex-col relative cursor-pointer hover:scale-105 transform transition-all duration-300' >
            <RegisterQuizzithonModal quizzithon={quizzithon} handleQuizzithonUpdate={handleQuizzithonUpdate} open = {open} setopen={setOpen} />
            <div className="half_1 border-b-2 border-slate-500 relative top-0" style={{ backgroundImage: `url(${dummy})` }} onClick = {()=>navigate(`/quizzithon/${quizzithon._id}`)}>
                <div className='w-full flex flex-col justify-center px-3'>


                    {/* <div className="w-full flex justify-end">
                        <button className='w-16 h-6 bg-yellow-400 border rounded-full text-[10px] text-white' onClick={() => { handleFollow() }}>{btnText}</button>
                    </div> */}
                    <div className="flex w-full justify-center">
                        <Avatar

                            src={quizzithon.scheduled_by.profileImage}
                            sx={{ width: 55, height: 55 }}
                            // onClick={() => { navigate(`/following/profile/${quizz.createdBy._id}`) }}
                            className='cursor-pointer ring ring-green-400 ring-offset-2 absolute top-[60%] '
                        />
                    </div>

                </div>


            </div>
            <div className='flex flex-col items-center text-xs pt-7 pb-3 relative' >
                <h1 className='text-center text-sm'>Title : {quizzithon.title}</h1>
                {/* <p className='text-xs flex justify-between gap-2'><span>Start Time : {getTime(quizzithon.start_time)}</span><span>End Time : {getTime(quizzithon.end_time)}</span></p> */}
                <span>Quizmaster : {quizzithon.scheduled_by.userName} </span>
                <div className='grid grid-cols-3 px-5 text-[#BD5E00] font-semibold h-[60px] mt-2'>
                        <div className='justify-self-end self-end relative'>
                            <img src={second} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{secondPrize > 0 ? secondPrize.toFixed(0) : 0  }</p>
                        </div>
                        
                        <div className='justify-self-start self-start relative'>
                            <img src={first} alt="" className='w-full' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{firstPrize > 0 ? firstPrize.toFixed(0) : 0}</p>
                        </div>
                        <div className='justify-self-end self-end relative'>
                            <img src={thrid} alt="" className='w-full ' />
                            <p className='absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs w-full text-center pb-5' >{thirdPrize > 0 ? thirdPrize.toFixed(0) : 0}</p>
                        </div>
                </div>
                <div>
                    {
                       canRegister && (quizzithonData.registerdUsers.findIndex((user)=> user.user === auth?.data?.user._id) === -1?
                            <button className='px-2 h-6 bg-green-400 border border-green-400 hover:bg-white hover:text-green-400 rounded-full text-white mt-3' onClick={handleRegister}>Register</button> :
                            <button className='px-2 h-6 bg-red-400 border border-red-400 hover:bg-white hover:text-red-400 rounded-full text-white mt-3' onClick={handleUnRegister} >Un Register</button> )
                    }
                    {
                        play && quizzithon.registerdUsers.findIndex((item)=> item.user === auth.data.user._id) !== -1 &&
                        <button className='px-2 h-6 bg-green-400 border border-green-400 hover:bg-white hover:text-green-400 rounded-full text-white mt-3' onClick = {()=>navigate(`/quizzithon/${quizzithon._id}`)}>Play</button>
                    }

                </div>
                {!play&&<p className=' text-center absolute top-2 right-2 px-1 h-4 bg-green-400 text-white rounded-full text-[10px]'>{timeRemaining}</p>}
            </div>

        </div>
    )
}

export default QuizzithonCard