import React from 'react'
import Layout from '../components/Layout/Layout'
import SearchUser from './SearchUser'
function Quizmasters() {
  return (
    <Layout>
      <div className="w-full flex justify-center">
        <SearchUser/>
      </div>
    </Layout>
  )
}

export default Quizmasters