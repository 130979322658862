import React from 'react'
import QuizCards from '../../QuizCards/QuizCards'

const PaidTab = ({
  cat,
  sub
}) => {
  return (
    <>
      <QuizCards type={'paid'}
        cat={cat}
        sub={sub}
      />
    </>
  )
}

export default PaidTab