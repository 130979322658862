import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AboutPage from "../AboutPage";
import MyBreadCrumbs from "../uiComponents/MyBreadCrumbs";

//sidebar icons...
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import MyAvatar from "./MyAvatar";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PeopleIcon from "@mui/icons-material/People";
import { BsArchive } from "react-icons/bs";
import { FiLayers } from "react-icons/fi";
//sidebar icons...

//nav img
import Hand from "./../../assets/layout/hand.png";
import Dollar from "./../../assets/layout/dollar.png";
import Wallet from "./../../assets/layout/wallet.png";
//nav img
//logo
import hLogo from "../../assets/logo/logo.png";
//manue..
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import CollapsAbleList from "./CollapsAbleList";
import { extrasConstancts } from "../../actions/appConstants";
import SchoolQuizComponent from "../schoolQuizC/SchoolQuizComponent";
import { updateQuizzithon } from "../../actions/quizzithon.actions";
import { useEffect } from "react";
import axios from "../../utils/myAxios";
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const [collapseHanlde, setcollapseHanlde] = React.useState(false);
  const dispatch = useDispatch();
  const { auth, wallet, extras } = useSelector((state) => state);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [active, isActive] = React.useState();
  const navigate = useNavigate();
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const sideBarForUser = [
    // {
    //     title: "BecomeAdmin", //dashboard...
    //     icon: <DashboardOutlinedIcon />,
    //     path: "/make/me/admin",
    // },
    {
      title: "Play quiz & Earn", //dashboard...
      icon: <DashboardOutlinedIcon />,
      path: "/dashboard",
    },
    {
      title: "Play Quizzithon",
      icon: <FiLayers />,
      path: "/play-quizzithon",
    },
    {
      title: "Schedule Quizzithon ",
      icon: <BsArchive />,
      path: "/schedule-quizzithon",
    },
    {
      title: "Schedule Quiz & Earn", //schedule quiz...
      icon: <AccessTimeOutlinedIcon />,
      path: "/schedulequiz",
    },
    {
      title: "Quiz Library", //past quizzes
      icon: <ContentPasteOffIcon />,
      path: "/quiz-library",
    },
    {
      title: "My Followings",
      icon: <BookmarkIcon />,
      path: "",
      expandAble: true,
      items: [
        {
          title: "Followers",
          icon: <BookmarkIcon />,
          path: "/my-following/followers",
        },
        {
          title: "Following",
          icon: <BookmarkIcon />,
          path: "/my-following/following",
        },
      ],
    },
    {
      title: "My Account",
      icon: <ManageAccountsOutlinedIcon />,
      expandAble: true,
      items: [
        {
          title: "My Profile",
          icon: <PermIdentityOutlinedIcon />,
          path: "/profile",
        },
        {
          title: "Played Quizzithon",
          icon: <ExtensionOutlinedIcon />,
          path: `/user/quizzithons/${auth?.data?.user._id}`,
        },
        {
          title: "Played Quizzes",
          icon: <ExtensionOutlinedIcon />,
          path: "/playedquizez",
        },
        {
          title: "Registered Quizzes",
          icon: <QuizOutlinedIcon />,
          path: "/registeredquizzes",
        },
        {
          title: "My Wallet",
          icon: <AccountBalanceWalletOutlinedIcon />,
          path: "/mywallet",
        },
        {
          title: "My Reffereals",
          icon: <PeopleIcon />,
          path: "/referral",
        },
      ],
    },
    {
      title: "Quiz Masters",
      icon: <PeopleIcon />,
      path: "/quizmasters",
    },
    {
      title: "Quizmaster-Pro", //school quiz...
      icon: <HistoryEduIcon />,
      path: "/quizmaster-pro",
    },

    {
      title: "My Question Bank", //question bank...
      icon: <ViewInArOutlinedIcon />,
      path: "/questionbank",
    },
    // {
    //     title: "Played Quizzes",
    //     icon: <ExtensionOutlinedIcon />,
    //     path: "/playedquizez",
    // },
    // {
    //     title: "Registered Quizzes",
    //     icon: <QuizOutlinedIcon />,
    //     path: "/registeredquizzes",
    // },
    // {
    //     title: "My Wallet",
    //     icon: <AccountBalanceWalletOutlinedIcon />,
    //     path: "/mywallet",
    // },
    {
      title: "Payments",
      icon: <AccountBalanceIcon />,
      path: "/payments",
    },
    {
      title: "Redemptions",
      icon: <PaymentsOutlinedIcon />,
      path: "/userredemption",
    },
    // {
    //     title: "My Profile", //profile
    //     icon: <PermIdentityOutlinedIcon />,
    //     path: "/profile",
    // },

    // {
    //     title: "Available Plans", //plans
    //     icon: <CardMembershipOutlinedIcon />,
    //     path: "/plans",
    // }

    {
      title: "Active Plans", //plans
      icon: <FiLayers />,
      path: "/user/subscriptions",
    },
    // {
    //     title: "My Referrals", //referrals
    //     icon: <ConnectWithoutContactOutlinedIcon />,
    //     path: "/referral",
    // },
    // {
    //     title: "Categories",
    //     icon: <BiCategoryAlt />,
    //     path: "/static/categories",
    // },

    // {
    //     title: "Contact US",
    //     icon: <PaymentsOutlinedIcon />,
    //     path: "/static/contactus",
    // },
  ];
  const sidebarForAdmin = [
    {
      title: "Play quiz & Earn", //dashboard
      icon: <DashboardOutlinedIcon />,
      path: "/dashboard",
    },
    {
      title: "Schedule Quiz & Earn", //schedule quiz
      icon: <AccessTimeOutlinedIcon />,
      path: "/schedulequiz",
    },
    {
      title: "Play Quizzithon",
      icon: <FiLayers />,
      path: "/play-quizzithon",
    },
    {
      title: "Schedule Quizzithon ",
      icon: <BsArchive />,
      path: "/schedule-quizzithon",
    },
    {
      title: "My Followings",
      icon: <BookmarkIcon />,
      path: "",
      expandAble: true,
      items: [
        {
          title: "Followers",
          icon: <BookmarkIcon />,
          path: "/my-following/followers",
        },
        {
          title: "Following",
          icon: <BookmarkIcon />,
          path: "/my-following/following",
        },
      ],
    },
    {
      title: "My Account",
      icon: <ManageAccountsOutlinedIcon />,
      expandAble: true,
      items: [
        {
          title: "My Profile",
          icon: <PermIdentityOutlinedIcon />,
          path: "/profile",
        },
        {
          title: "Played Quizzes",
          icon: <ExtensionOutlinedIcon />,
          path: "/playedquizez",
        },
        {
          title: "Played Quizzithon",
          icon: <ExtensionOutlinedIcon />,
          path: `/user/quizzithons/${auth?.data?.user._id}`,
        },
        {
          title: "Registered Quizzes",
          icon: <QuizOutlinedIcon />,
          path: "/registeredquizzes",
        },
        {
          title: "My Wallet",
          icon: <AccountBalanceWalletOutlinedIcon />,
          path: "/mywallet",
        },
        {
          title: "My Reffereals",
          icon: <PeopleIcon />,
          path: "/referral",
        },
      ],
    },
    {
      title: "Quiz Masters",
      icon: <PeopleIcon />,
      path: "/quizmasters",
    },
    {
      title: "Quizmaster-Pro", //school quiz...
      icon: <HistoryEduIcon />,
      path: "/quizmaster-pro",
    },
    {
      title: "My Question Bank", //question bank
      icon: <ViewInArOutlinedIcon />,
      path: "/questionbank",
    },
    {
      title: "Redemption",
      icon: <PaymentsOutlinedIcon />,
      path: "/redemption",
    },
    {
      title: "Payments",
      icon: <AccountBalanceIcon />,
      path: "/payments",
    },
    // {
    //     title: "My Profile", //profile
    //     icon: <PermIdentityOutlinedIcon />,
    //     path: "/profile",
    // },
    // {
    //     title: "My Referrals", //referrals
    //     icon: <ConnectWithoutContactOutlinedIcon />,
    //     path: "/referral",
    // },

    {
      title: "Archive Quizzes",
      icon: <BsArchive />,
      path: "/archivequizzes",
    },

    {
      title: "Manage User",
      icon: <ManageAccountsOutlinedIcon />,
      path: "/usermanagement",
    },
    {
      title: "Plateform Settings",
      icon: <AdminPanelSettingsOutlinedIcon />,
      path: "/plateformsettings",
    },
    // {
    //     title: "Contact US",
    //     icon: <PaymentsOutlinedIcon />,
    //     path: "/static/contactus",
    // },
  ];
  useEffect(() => {
    axios
      .put("/update/quizzithon")
      .then((data) => data)
      .catch((err) => err);
  }, []);
  // console.log('path name===>', (location.pathname).split('/'))
  const drawer = (
    <div className="!bg-transparent !h-full overflow-auto scrollbar-hide">
      <Toolbar className="flex justify-center items-center">
        <img
          onClick={() => navigate("/dashboard")}
          src={hLogo}
          alt=""
          className="h-[45px] w-full object-contain cursor-pointer"
        />
      </Toolbar>
      <SchoolQuizComponent mobile={true} />
      <div className="p-2 lg:hidden ">
        <div
          onClick={() => {
            dispatch({
              type: extrasConstancts.SHOW_BUY_QZETO_MODAL,
              payload: true,
            });
          }}
          className="flex items-center justify-center space-x-2 bg-mylightyellow rounded-2xl py-1.5  cursor-pointer text-white "
        >
          <MonetizationOnOutlinedIcon />
          <span>Buy Qzetos</span>
        </div>
      </div>
      <List
        style={{ marginLeft: "10px", marginRight: "10px", borderRadius: "7px" }}
        // className='!bg-bgblue'
      >
        {auth?.data?.user?.isAdmin
          ? sidebarForAdmin.map((val, index) => (
              <>
                {val.expandAble ? (
                  <CollapsAbleList val={val} />
                ) : (
                  <div
                    onClick={() => {
                      navigate(val.path);
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    // className='cursor-pointer'
                  >
                    <div
                      className={`${
                        location.pathname == val.path
                          ? "bg-bgblue text-white font-semibold"
                          : "text-bgblue"
                      } flex items-center space-x-2 p-2 rounded-md mb-2 hover:bg-bgblue hover:text-white hover:font-semibold cursor-pointer`}
                    >
                      {val.icon}
                      <h1 className="capitalize text-[14px]">{val.title}</h1>
                    </div>
                  </div>
                )}
              </>
            ))
          : sideBarForUser.map((val, index) => (
              <>
                {val.expandAble ? (
                  <CollapsAbleList val={val} />
                ) : (
                  <div
                    onClick={() => {
                      if (val.path === "/quizmaster-pro") {
                        if (extras.boughtSchoolQuizSubscription) {
                          navigate(val.path);
                        } else {
                          navigate("/quizmaster-pro", {
                            state: { schoolPlans: true },
                          });
                        }
                      } else {
                        navigate(val.path);
                      }
                      // navigate(val.path)
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    // className='cursor-pointer'
                  >
                    <div
                      className={`${
                        location.pathname == val.path
                          ? "bg-bgblue text-white font-semibold"
                          : "text-bgblue"
                      } flex items-center space-x-2 p-2 rounded-md mb-2 hover:bg-bgblue hover:text-white hover:font-semibold cursor-pointer`}
                    >
                      {val.icon}
                      <h1 className="capitalize text-[14px]">{val.title}</h1>
                    </div>
                  </div>
                )}
              </>
            ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  //toggles fro menue..
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    // dispatch({ type: "LOGOUT" });
  };
  const getWalletData = (type) => {
    const obj = wallet.wallet.find((v) => v.walletsType === type);
    const toreturn = obj?.credit ? obj.credit : 0;
    return toreturn;
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
        className="!bg-gray-200"
      >
        <Toolbar className="bg-gray-200">
          <div className="text-black flex justify-between w-full items-center bg-white shadow-xl p-3 mt-2 rounded-2xl">
            {/*  */}
            <IconButton
              // color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
              style={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            {/*  */}
            <div className="flex items-center gap-x-5 ">
              <div className="flex items-center gap-x-3 tracking-widests">
                <img src={Wallet} alt="" className="h-5 object-contain" />
                <div className="gap-y-1">
                  <h1 className="text-[10px]">
                    {getWalletData("real").toFixed(0)}
                  </h1>
                  <h1 className="capitalize text-[10px] font-semibold">
                    real qzeto
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-x-3 tracking-widests">
                <img src={Hand} alt="" className="h-5 object-contain" />
                <div className="gap-y-1">
                  <h1 className="text-[10px]">
                    {getWalletData("free").toFixed(0)}
                  </h1>
                  <h1 className="capitalize text-[10px] font-semibold">
                    free qzeto
                  </h1>
                </div>
              </div>
              <div className="flex items-center gap-x-3 tracking-widests">
                <img src={Dollar} alt="" className="h-5 object-contain" />
                <div className="gap-y-1">
                  <h1 className="text-[10px]">
                    {getWalletData("bonus").toFixed(0)}
                  </h1>
                  <h1 className="capitalize text-[10px] font-semibold">
                    bonus qzeto
                  </h1>
                </div>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col-reverse  gap-2 md:flex-row items-center gap-x-3">
              <SchoolQuizComponent screen={true} />
              <div
                onClick={() => {
                  dispatch({
                    type: extrasConstancts.SHOW_BUY_QZETO_MODAL,
                    payload: true,
                  });
                }}
                className="lg:flex hidden items-center space-x-2 bg-mylightyellow rounded-2xl py-1 px-4 cursor-pointer text-white "
              >
                <MonetizationOnOutlinedIcon />
                <span>Buy Qzetos</span>
              </div>
              <div className="">
                <h1 className="tracking-widest text-[10px] lg:text-lg text-bgblue font-semibold">
                  {auth?.data?.user.userName}
                </h1>
                {auth?.data?.user.isAdmin ? (
                  <h1 className="text-bgblue text-[6px] lg:text-sm tracking-widest  text-right ">
                    Admin
                  </h1>
                ) : (
                  <h1 className="text-bgblue text-[6px] lg:text-sm tracking-widest  text-right ">
                    User
                  </h1>
                )}
              </div>
              <MyAvatar />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        // className="bg-bgblue"
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, mt: 8 }}
        className="bg-gray-200 !text-black !w-full !overflow-hidden"
      >
        <div className="w-full flex justify-between p-2">
          <MyBreadCrumbs />
          {/* <TestingBreadCrumb /> */}
        </div>
        {props.children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
