import React, { useState, useRef, useEffect } from 'react'
import Crossword from '@jaredreisinger/react-crossword'
import styled from 'styled-components'
import tw from 'twin.macro'
import useUndoableState from './UndoRedoHook'
//
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { imageUpload } from '../../../utils/cloudinary.config'
import { createQuestionsAction, reApprovalRequestAction } from '../../../actions/question.actions'
const init = {
    across: {},
    down: {},
}
const CrossWordQuestions = ({ subject, type, setshowAddQuestions, showQuestinData }) => {
    const [canEdit, setcanEdit] = useState(false)
    const { auth, question } = useSelector(s => s)
    const [questionStatement, setquestionStatement] = useState('')
    const dispatch = useDispatch()
    const crossWardReff = useRef()
    const dataEntryInit = {
        clue: '',
        answer: '',
        row: 0,
        col: 0,
        url: ""
    }
    const [dataEntry, setdataEntry] = useState(dataEntryInit)
    const [direction, setdirection] = useState('across')
    const {
        state: doc,
        setState: setDoc,
        resetState: resetDoc,
        index: docStateIndex,
        lastIndex: docStateLastIndex,
        goBack: undoDoc,
        goForward: redoDoc
    } = useUndoableState(init);
    const canUndo = docStateIndex > 0;
    const canRedo = docStateIndex < docStateLastIndex;
    useEffect(() => {
        if (doc) {
            crossWardReff.current.fillAllAnswers()
        }
    }, [doc])
    const handleAdd = () => {
        if (!dataEntry.clue.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "clue is required!" } })
        if (!dataEntry.answer.trim()) return dispatch({ type: globalConstants.ALERT, payload: { error: "answer is required!" } })
        if (dataEntry.col < 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "column number should be positive!" } })
        if (dataEntry.row < 0) return dispatch({ type: globalConstants.ALERT, payload: { error: "row number should be positive!" } })
        //check for duplicate x,y indexes
        const _row_ = dataEntry.row
        const _col_ = dataEntry.col
        let isDuplicateIndexes = false
        Object.values(doc.across).forEach(vvv => {
            if (vvv.col === _col_ && vvv.row === _row_ && direction === 'across') {
                isDuplicateIndexes = true
            }
        })
        Object.values(doc.down).forEach(vvv => {
            if (vvv.col === _col_ && vvv.row === _row_ && direction === 'down') {
                isDuplicateIndexes = true
            }
        })
        if (isDuplicateIndexes) return dispatch({
            type: globalConstants.ALERT,
            payload: { error: 'Duplicate Rows And Columns Are Not Allowed!' }
        })
        //console.log('dataIntry==>', dataEntry)
        //check for duplicate x,y indexes
        let lastIndex = Object.keys(doc.across).pop()
        let lastIndexDown = Object.keys(doc.down).pop()
        if (!lastIndex) lastIndex = 0;
        if (!lastIndexDown) lastIndexDown = 0;
        if (lastIndex > lastIndexDown) {
            lastIndex = lastIndex
        } else {
            lastIndex = lastIndexDown
        }
        //console.log(lastIndex)
        const newObj = {
            ...doc,
            [direction]: {
                ...doc[direction],
                [Number(Number(lastIndex) + 1)]: dataEntry
            }
        }
        // //console.log('new obj==>', newObj)
        setDoc(newObj)
        setdataEntry(dataEntryInit)
    }
    const handleSelectedFile = (e) => {
        const file = e.target.files[0]
        // if(file.)
        //console.log(file)
        if (file['type'].split('/')[0] !== 'image' && file['type'].split('/')[0] !== 'audio') {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'only Images & Audio files are allowed!' }
            })
        }
        setdataEntry({
            ...dataEntry,
            url: file
        })
        e.target.value = ''
    }
    const handleSubmit = async (shouldReset) => {
        try {
            dispatch({
                type: globalConstants.ALERT,
                payload: { loading: true }
            })
            let obj = {
                questionCategory: type,
                subject,
                statement: questionStatement,
            }
            if (!obj.questionCategory.trim()) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "question category type is required!" }
            })
            if (!obj.subject.trim()) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'subject is required!' }
            })
            if (!obj.statement.trim()) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: 'question statement is rquired!' }
            })
            if (Object.keys(doc.across).length === 0 && Object.keys(doc.down).length === 0) return dispatch({
                type: globalConstants.ALERT,
                payload: { error: "kindly fill the CROSS WORD PUZZLE!" }
            })
            //upload image or audio to the cloudinary 
            //for across...
            const acrossKeys = []
            Object.entries(doc.across).forEach(([key, val]) => {
                acrossKeys.push(key)
            })
            const valueOfObjects = Object.entries(doc.across).map(([key, val]) => {
                return val;
            })
            //console.log('value==>', valueOfObjects)
            let newAcross = {}
            for (let x = 0; x < acrossKeys.length; x++) {
                if (valueOfObjects[x].url) {
                    let imgUrl = []
                    if (typeof (valueOfObjects[x].url) === 'object') {
                        imgUrl = await imageUpload([valueOfObjects[x].url])
                    } else {
                        imgUrl[0] = {
                            url: valueOfObjects[x].url
                        }
                    }

                    valueOfObjects[x].url = imgUrl[0].url
                }
            }
            for (let x = 0; x < acrossKeys.length; x++) {
                newAcross[acrossKeys[x]] = valueOfObjects[x]
            }
            //console.log('final across', newAcross)
            //for across...
            //for down
            const downKeys = []
            Object.entries(doc.down).forEach(([key, val]) => {
                downKeys.push(key)
            })
            const valueOfObjectsDown = Object.entries(doc.down).map(([key, val]) => {
                return val;
            })
            let newDown = {}
            for (let x = 0; x < downKeys.length; x++) {
                if (valueOfObjectsDown[x].url) {
                    let imgUrl = []
                    if (typeof (valueOfObjectsDown[x].url) === 'object') {
                        imgUrl = await imageUpload([valueOfObjectsDown[x].url])
                    } else {
                        imgUrl[0] = {
                            url: valueOfObjectsDown[x].url
                        }
                    }
                    valueOfObjectsDown[x].url = imgUrl[0].url
                }
            }
            for (let x = 0; x < downKeys.length; x++) {
                newDown[downKeys[x]] = valueOfObjectsDown[x]
            }
            //for down
            const objFinal = {
                across: newAcross,
                down: newDown
            }
            obj.forCrossWord = objFinal
            obj.user = auth.data.user._id
            const _idFind = question.questionsCategories.find((v) => v.name === type)
            obj.questionCategory = _idFind._id
            if (shouldReset) {
                if (canEdit) {
                    await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
                } else {
                    await dispatch(createQuestionsAction(obj))
                }
                setDoc(init)
                setquestionStatement('')
            } else {
                if (canEdit) {
                    await dispatch(reApprovalRequestAction(showQuestinData._id, obj))
                } else {
                    await dispatch(createQuestionsAction(obj))
                }
                setshowAddQuestions(false)
            }
        } catch (error) {
            return dispatch({
                type: globalConstants.ALERT,
                payload: { error: error.message }
            })
        }
    }
    //console.log('after reset ==>', doc)
    useEffect(() => {
        if (showQuestinData) {
            setDoc(showQuestinData.forCrossWord)
            setquestionStatement(showQuestinData.statement)
            if (showQuestinData.edit) {
                setcanEdit(true)
            } else {
                setcanEdit(false)
            }
        }
    }, [showQuestinData])
    return (
        <Cont>
            <div className='space-y-2 mb-5'>
                <h1 className='text-bgblue font-bold'>Question Statement</h1>
                <input
                    className='border border-gray-400 py-2 px-2 w-full outline-none rounded-md'
                    type='text'
                    value={questionStatement}
                    onChange={(e) => setquestionStatement(e.target.value)}
                    placeholder='write question statement...'
                />
            </div>
            <div className={`grid ${showQuestinData?.edit === false ? 'lg:grid-cols-1' : 'lg:grid-cols-2'}  grid-cols-1`}>
                <div className=' flex items-center justify-center'>
                    <div className='w-[400px] h-[400px]'>
                        <MyCrossWord ref={crossWardReff} data={doc} />
                    </div>
                </div>
                {
                    showQuestinData?.edit === false ?
                        <div></div>
                        :
                        <div className={`p-2 space-y-3  rounded-md shadow-2xl`}>
                            <h1 className='capitalize text-center font-semibold text-bgblue'>fill the crossword</h1>
                            {/* direction */}
                            <div className=''>
                                <h1 className='capitalize font-semibold'>Select direction</h1>
                                <select value={direction} onChange={e => setdirection(e.target.value)} className='w-full border border-gray-300 rounded-md  p-2 outline-none'>
                                    <option value='across'>across</option>
                                    <option value='down'>down</option>
                                </select>
                            </div>
                            {/* clue */}
                            <div className='space-y-2'>
                                <h1 className='capitalize font-semibold'>write clue</h1>
                                <input type={'text'} className='w-full border border-gray-300 rounded-md p-2 outline-none '
                                    value={dataEntry.clue}
                                    name='clue'
                                    onChange={(e) => {
                                        setdataEntry({
                                            ...dataEntry,
                                            clue: e.target.value
                                        })
                                    }}
                                />
                            </div>
                            {/* answer */}
                            <div className=''>
                                <h1 className='capitalize font-semibold'>write answer</h1>
                                <input type={'text'} className='w-full border border-gray-300 rounded-md p-2 outline-none'
                                    value={dataEntry.answer}
                                    name='answer'
                                    onChange={(e) => {
                                        setdataEntry({
                                            ...dataEntry,
                                            answer: e.target.value.toUpperCase().replace(/\s/g, "")
                                        })
                                    }}
                                />
                            </div>
                            {/* file upload */}
                            <div>
                                <input
                                    accept="image/*,audio/mp3,audio/*"
                                    type={'file'}
                                    onChange={handleSelectedFile}
                                // onClick={e => {
                                //     e.target.value = ''
                                // }}
                                />
                                {
                                    typeof dataEntry?.url === 'object' && (
                                        dataEntry.url['type'].split('/')[0] === 'image' ?
                                            <img className='w-28 h-28 object-contain mt-3' src={URL.createObjectURL(dataEntry.url)} />
                                            :
                                            <audio
                                                className='w-full mt-3'
                                                autoPlay={false}
                                                controls
                                                src={URL.createObjectURL(dataEntry.url)}
                                            />)
                                }
                            </div>
                            {/* row & column */}
                            <div className='grid grid-cols-2 gap-3'>
                                <div className=''>
                                    <h1 className='capitalize font-semibold'>select row</h1>
                                    <input type={'number'} className='w-full border border-gray-300 rounded-md p-2 outline-none'
                                        value={dataEntry.row}
                                        name='row'
                                        onChange={(e) => setdataEntry({
                                            ...dataEntry,
                                            row: Number(e.target.value)
                                        })}
                                    />
                                </div>
                                <div className=''>
                                    <h1 className='capitalize font-semibold'>select column</h1>
                                    <input type={'number'} className='w-full border border-gray-300 rounded-md p-2 outline-none'
                                        value={dataEntry.col}
                                        name='col'
                                        onChange={(e) => {
                                            setdataEntry({
                                                ...dataEntry,
                                                col: Number(e.target.value)
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center justify-center space-x-3'>
                                <Button
                                    onClick={() => undoDoc()}
                                    disabled={!canUndo}
                                    variant='contained' startIcon={<UndoIcon />}>
                                    Undo
                                </Button>
                                <Button
                                    onClick={() => redoDoc()}
                                    disabled={!canRedo}
                                    variant='contained' startIcon={<RedoIcon />}>
                                    Redo
                                </Button>
                            </div>
                            <Button onClick={handleAdd} variant='contained' className='!w-full'>Add</Button>
                        </div>
                }

            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 my-3 gap-4'>
                <div className='p-2 bg-gray-100'>
                    <h1 className='font-semibold text-bgblue text-xl uppercase text-center mb-3'>Across</h1>
                    <div className='space-y-3'>
                        {Object.entries(doc?.across)?.length > 0 &&
                            Object.entries(doc?.across).map(([key, val], index) => (
                                <div className='flex items-center bg-gray-200 p-2 justify-between rounded-2xl'>
                                    <h1><span className='font-semibold'>{key}.</span> {val.clue}</h1>
                                    {
                                        //console.log('val //console', val)
                                    }
                                    {
                                        typeof (val?.url) === 'object' ? (
                                            val?.url['type']?.split('/')[0] === 'image' ?
                                                <img
                                                    className='w-20 border border-gray-400 h-20 object-contain'
                                                    src={URL.createObjectURL(val.url)}
                                                />
                                                :
                                                <audio
                                                    autoPlay={false}
                                                    controls
                                                    src={URL.createObjectURL(val.url)}
                                                />)
                                            :
                                            null
                                    }
                                    {
                                        typeof (val?.url) === 'string' ? (
                                            val?.url?.includes('/image/') ?
                                                <img
                                                    className='w-20 border border-gray-400 h-20 object-contain'
                                                    src={val?.url}
                                                />
                                                :
                                                val?.url?.includes('/video/') ?
                                                    < audio
                                                        autoPlay={false}
                                                        controls
                                                        src={val?.url}
                                                    />
                                                    :
                                                    null
                                        )
                                            :
                                            null
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='bg-gray-100 p-2'>
                    <h1 className='text-center font-semibold text-bgblue text-xl uppercase mb-3'>Down</h1>
                    <div className='space-y-3'>
                        {
                            Object.entries(doc?.down)?.length > 0 &&
                            Object.entries(doc.down).map(([key, val], index) => (
                                <div className='flex items-center bg-gray-200 p-2 justify-between rounded-2xl'>
                                    <h1><span className='font-semibold'>{key}.</span> {val.clue}</h1>
                                    {
                                        //console.log('val //console', val)
                                    }
                                    {

                                        typeof val?.url === 'object' ?
                                            (val?.url['type']?.split('/')[0] === 'image' ?
                                                <img
                                                    className='w-20 border border-gray-400 h-20 object-contain'
                                                    src={URL.createObjectURL(val.url)}
                                                />
                                                :
                                                <audio
                                                    autoPlay={false}
                                                    controls
                                                    src={URL.createObjectURL(val.url)}
                                                />)
                                            :
                                            null

                                    }
                                    {
                                        typeof val?.url === 'string' ? (
                                            val?.url?.includes('/image/') ?
                                                <img
                                                    className='w-20 border border-gray-400 h-20 object-contain'
                                                    src={val?.url}
                                                />
                                                :
                                                val?.url?.includes('/video/') ?
                                                    < audio
                                                        autoPlay={false}
                                                        controls
                                                        src={val?.url}
                                                    />
                                                    :
                                                    null
                                        )
                                            :
                                            null
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>
            {
                showQuestinData?.edit === false ?
                    <div></div>
                    :
                    <div className='flex items-center justify-end space-x-3 mt-5'>
                        <button onClick={() => handleSubmit(false)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & Close`}</button>
                        <button onClick={() => handleSubmit(true)} className='bg-bgblue text-white p-2 text-xs rounded-2xl'>{`Save & And Add Next`}</button>
                    </div>
            }
        </Cont>
    )
}
const Cont = styled.div`
${tw`p-5 mt-10`}
`
const MyCrossWord = styled(Crossword)`

`
export default CrossWordQuestions