import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'react-redux';
import { deleteSubjectAction } from '../../actions/subjects.actions';
export default function SubjectModal({ open, setopen, subject }) {
    const dispatch = useDispatch()
    const handleClose = () => {
        setopen(false)
    };
    const handleDelete = () => {
        dispatch(deleteSubjectAction(subject._id)).then((val) => {
            if (val) {
                setopen(false)
            }
        })
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <div className='p-10'>
                <h1 className='capitalize font-bold text-center mb-5 px-5'>Are you sure?</h1>
                <div className='flex space-x-2 items-center'>
                    <button onClick={handleDelete} className='py-2 px-4 border-none text-white bg-red-500 rounded-md'>Delete</button>
                    <button onClick={() => setopen(false)} className='py-2 px-4 border-none text-white bg-gray-500 rounded-md'>Cancle</button>
                </div>
            </div>
        </Dialog>
    );
}