import React, { useEffect, useState } from 'react'
import MyTable from './MyTable'
import axios from '../../../utils/myAxios'
import { useDispatch, useSelector } from 'react-redux'
import { globalConstants } from '../../../actions/appConstants'
import { Pagination } from '@mui/material'
const ScheduleTable = ({ userId }) => {
    const dispatch = useDispatch()
    const { auth } = useSelector(s => s)
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { loading: true }
                })
                const res = await axios(`/get/userquizzezbyid/${userId}?page=${page}&limit=${limit}&requser=${auth.data.user._id}`)
                if (res.status === 200) {
                    // console.log('questions==>', res.data.quizzez)
                    setquestions(res.data.quizzez)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                dispatch({
                    type: globalConstants.ALERT,
                    payload: {}
                })
            } catch (error) {
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        if (userId)
            getData()

    }, [page, limit, userId])
    // console.log(questions)
    return (
        <div>
            <MyTable questions={questions} setquestions={setquestions} />
            {
                questions.length > 0
                    ?
                    <div className='flex justify-center px-5 pt-5'>
                        <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                    </div>
                    :
                    <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No quiz available...</div>
            }
        </div>
    )
}

export default ScheduleTable