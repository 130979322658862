import React from 'react'
import Grid from '@mui/material/Grid';
import WorkCard from './WorkCard';
import Container from '@mui/material/Container';


import Submitimg from '../../../assets/staticdata/sign-up.png'
import Checkimg from '../../../assets/staticdata/approve.png'
import Questionimg from '../../../assets/staticdata/plus-sign.png'
import Shareimg from '../../../assets/staticdata/timer.png'
import Cashimg from '../../../assets/staticdata/social-media.png'
import Userimg from '../../../assets/staticdata/customer.png'
import Dataimg from '../../../assets/staticdata/calendar.png'
import Winnerimg from '../../../assets/staticdata/success.png'
import Awardimg from '../../../assets/staticdata/reward.png'
import Rewardimg from '../../../assets/staticdata/reward-card.png'
import Convertrewardimg from '../../../assets/staticdata/convert.png'





const data = [
   {
      icon: Submitimg,
      heading: "Step 1",
      detail: "Log in to your account"
   },
   {
      icon: Checkimg,
      heading: "Step 2",
      detail: "Navigate to question bank tab"
   },
   {
      icon: Questionimg,
      heading: "Step 3",
      detail: "Submit quiz questions from your question bank module"
   },
   {
      icon: Shareimg,
      heading: "Step 4",
      detail: "Quizzingbox approves the submitted question within 24 hrs duration"
   },
   {
      icon: Cashimg,
      heading: "Step 5",
      detail: "Navigate to the schedule quiz & earn tab"
   },
   {
      icon: Userimg,
      heading: "Step 6",
      detail: "Schedule free quiz of any category from any format using your approved question bank"
   },
   {
      icon: Dataimg,
      heading: "Step 7",
      detail: "Any user with valid account can participate in these quiz using free qzetos as registration fee"
   },
   {
      icon: Winnerimg,
      heading: "Step 8",
      detail: "Scheduled quiz starts automatically as per the schedule mentioned by the quiz scheduler while scheduling the quiz"
   },
   {
      icon: Awardimg,
      heading: "Step 9",
      detail: "Quiz winners are awarded with bonus qzetos as reward in their bonus qzeto wallet instantly once quiz is over"
   },
   {
      icon: Rewardimg,
      heading: "Step 10",
      detail: "Quiz schedulers also get rewarded with bonus qzetos as per the rule mentioned in quiz scheduling page"
   },
   {
      icon: Convertrewardimg,
      heading: "Step 11",
      detail: "Convert bonus qzetos to real qzetos from my walet tab and redeem it for  rewards"
   },
]



const SheduleQuiz = () => {
   return (
      <div>
         <div className='flex flex-wrap gap-4 justify-center'>
            {
               data.map((val, index) => (
                  <WorkCard
                     key={index}
                     Details={val.detail}
                     Heading={val.heading}
                     IconImg={val.icon}
                  />
               ))
            }
         </div>
      </div>
   )
}

export default SheduleQuiz