import { Box, Container, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core"
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContactImage from "../../assets/staticdata/contactiamge.jpg"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import emailIcon from "../../assets/staticdata/email.png"
import phoneIcon from "../../assets/staticdata/phone.png"
import addressIcon from "../../assets/staticdata/address.png"
import contactusTop from "../../assets/staticdata/contactusTop.jpeg"
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'
import axios from '../../utils/myAxios'
const Heading = ({ text }) => <h1 className='mt-5 font-bold text-xl '>{text}</h1>
const MyLink = ({ text, link }) => <a target={`_blank`} className='text-blue-600 underline ' href={link}>{text}</a>
const MyParagraph = ({ children }) => <p className='text-[16px] leading-8 mt-5 '>{children}</p>


const MyBold = ({ text }) => <span className='text-lg font-extrabold '>{text}</span>
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: "5px",


    }
  },
  form: {
    display: "flex"

  }


}))

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  margin: '10px 0px',
  width: "80%",
  padding: '12px 0px',
  border: '1px solid',
  lineHeight: 1.5,
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: 'white',
  backgroundColor: '#233C77',
  borderColor: '#233C77',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#233C77',
    borderColor: '#233C77',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#233C77',
    borderColor: '#233C77',
  },
  '&:focus': {

  },
});
const ContactUs = () => {
  const p = useParams()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  })
  const handlechange = (e) => {
    //console.log(e.target.value)
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    //console.log(formData)
    const form = await axios.post('/contactus', formData)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])
  const classes = useStyles();
  return (
    <div className='!bg-white'>
      <NavBar />
      <img
        src={contactusTop}
        className='w-full h-auto object-cover md:mt-[4rem]'
      />
      <Container>

        <Typography pt={10} variant='h4' textAlign="center" sx={{ color: "black" }}>Contact Us</Typography>
        <div className='w-full flex justify-center'>
          <div className='w-auto flex justify-evenly'>
            <div className=' eachContact'>
              <div className='contactImg'>
                <img src={emailIcon} width="100%" alt='' />
              </div>
              <div className='conatctTitle'>
                <p>info@quizzingbox.com</p>
              </div>
            </div>
            <div className='eachContact'>
              <div className='contactImg'>
                <img src={addressIcon} width="100%" alt='' />
              </div>
              <div className='conatctTitle'>
                <p>Quizlabs Technolgy Private Limited
                  <br></br>SY.NO- 73/1,73/2A,74(P) & 81<br></br>
                  Bommenahalli, Bidarahalli, Hobli,<br></br>
                  Bengaluru, Karnataka 562129, India</p>
              </div>
            </div>
          </div>
        </div>
        <Grid container my={5}>
          <Grid mt={matches ? 10 : 0} item md={6} xs={12}>

            <form className={classes.root} onSubmit={handleSubmit}>
              <TextField id="outlined-basic"
                label="First Name"
                variant="outlined"
                name="firstName"
                onChange={handlechange}
              />
              <TextField id="outlined-basic"
                label="Last Name"
                variant="outlined"
                name="lastName"
                onChange={handlechange}
              />

              <TextField id="outlined-basic"
                label="Email"
                variant="outlined"
                name='email'
                onChange={handlechange}

              />
              <TextField id="outlined-basic"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                name='message'
                onChange={handlechange}

              />

              <BootstrapButton type='submit'>Send Message</BootstrapButton>
            </form>



          </Grid>
          <Grid item md={6} xs={12}>
            <img width="100%" src={ContactImage} alt="" />

          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  )
}

export default ContactUs