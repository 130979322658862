import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
//

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import BugReportIcon from '@mui/icons-material/BugReport';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { logoutAction } from '../../actions/auth.actions';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import PaymentOutLinedIcon from '@mui/icons-material/PaymentOutlined';
import { Button } from '@mui/material';



function stringAvatar(name) {
    return {
        sx: {
            bgcolor: '#00459E',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function BackgroundLetterAvatars() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { auth } = useSelector(state => state)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = async () => {
        await dispatch(logoutAction())
        navigate('/')
    }
    return (
        <div className='relative flex items-center'  >
            <Tooltip title="Account settings">
                <Avatar
                    onClick={handleClick}
                    src={auth.data.user.profileImage}
                    sx={{ width: 40, height: 40 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    className='cursor-pointer  ring ring-bgblue ring-opacity-50 ring-offset-2'
                />
            </Tooltip>
            <span className="animate-ping absolute bottom-0 right-0 h-2 w-2 rounded-full bg-green-500 "></span>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate('/profile')}>
                    <Avatar /> Profile
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate('/user/subscriptions')}>
                    <ListItemIcon>
                        <CardMembershipIcon fontSize="small" />
                    </ListItemIcon>
                    My Subscriptions
                </MenuItem>
                
                <Divider />

                <MenuItem onClick={()=>navigate("/static/faqs")}>
                    <ListItemIcon>
                        <HelpIcon fontSize="small" />
                    </ListItemIcon>
                    Help
                </MenuItem>
                <Divider />

                <MenuItem onClick={()=>navigate("/reportbug")}>
                    <ListItemIcon>
                        <BugReportIcon fontSize="small" />
                    </ListItemIcon>
                    Report A bug
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            

            </Menu>
        </div>
    );
}
