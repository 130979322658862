export const globalConstants = {
    ALERT: "ALERT",
    QUEZETO_AUTH_TOKEN: "QUEZETO_AUTH_TOKEN"
}
export const authConstants = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    SIGNUP: "SIGNUP",
    VARIFY_USER_TOKEN: "VARIFY_USER_TOKEN",
    EXTRAS_LOGIN_DATA: "EXTRAS_LOGIN_DATA",
    EXTRAS_KEEP_KEEP_USER_LOGIN_TOKEN: "EXTRAS_KEEP_KEEP_USER_LOGIN_TOKEN",
    HANDLE_AUTENTICATING: "HANDLE_AUTENTICATING",
    GET_USER_STATIC_TABS: 'GET_USER_STATIC_TABS'
}
export const roles = {

}
export const questionsConstants = {
    QUESTION_CATEGORIES: "QUESTION_CATEGORIES",
    UPDATE_QUESTIONS_ARRAY: 'UPDATE_QUESTIONS_ARRAY'
}
export const quizzSchedulerConstants = {
    GET_ALL_SHEDULED_QUIZZEZ: "GET_ALL_SHEDULED_QUIZZEZ",
    QUIZZ_UNREGISTER_USER: "QUIZZ_UNREGISTER_USER",
    QUIZZ_REGISTER_USER: "QUIZZ_REGISTER_USER",
    UPDATE_QUIZZ_ARRAY: "UPDATE_QUIZZ_ARRAY"
}
export const walletConstants = {
    GET_WALLET: "GET_WALLET",
    DETECT_MONEY: 'DETECT_MONEY',
    ADD_MONEY: "ADD_MONEY"
}

export const subjectConstants = {
    GET_ALL_SUBJECTS: "GET_ALL_SUBJECTS",
    CREATE_SUBJECT: "CREATE_SUBJECT",
    DELETE_SUBJECT: "DELETE_SUBJECT",
    UPDATE_SUBJECT: "UPDATE_SUBJECT"
}

export const questionsCategoryConstants = {
    MULTIPLE_QUESTIONS: 'MULTIPLE_QUESTIONS',
    TRUE_FALSE: 'TRUE_FALSE',
    FILL_IN_THE_BLANKS: 'FILL_IN_THE_BLANKS',
    MATCH_THE_PAIR: "MATCH_THE_PAIR",
    SEQUENCING_QUIZ: "SEQUENCING_QUIZ",
    PARAGRAPH_ORDERING_QUIZ: "PARAGRAPH_ORDERING_QUIZZ",
    IDENTIFY_IMAGE_VIDEO_OR_VOICE: "IDENTIFY_IMAGE_VIDEO_OR_VOICE",
    SPIN_A_WHEEL_QUIZ: "SPIN_A_WHEEL_QUIZZ",
    ELIMINATOR_QIZ: "ELIMINATOR_QUIZZ",
    MIX_TYPE_QUIZ: "MIX_TYPE_QUIZ",
    CROSS_WORDS: "CROSS_WORDS",
    DRAD_DROP_FILL_UP_THE_BLANKS: 'DRAG_DROP_FILL_UP_THE_BLANKS',
    CLOSED_ENDED: "CLOSED_ENDED",
    SENTENCE_SEQUENCE: "SENTENCE_SEQUENCE",
    SORTING_CLASSIFICATION: "SORTING_CLASSIFICATION",
    DRAG_DROP_VISUAL_SIMULATING: "DRAG_DROP_VISUAL_SIMULATING",
    TIC_TAC_TOE: "TIC_TAC_TOE"
}
export const extrasConstancts = {
    SHOW_BUY_QZETO_MODAL: "SHOW_BUY_QZETO_MODAL",
    IS_BOUGHT_SCHOOL_QUIZ_SUBSCRIPTION: "IS_BOUGHT_SCHOOL_QUIZ_SUBSCRIPTION",
    SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION: "SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION",
    SHOW_SIGN_IN_MODAL: "SHOW_SIGN_IN_MODAL",
    SHOW_SIGN_UP_MODAL: "SHOW_SIGN_UP_MODAL",
    SHOW_FORGOT_PASSWORD_MODAL: "SHOW_FORGOT_PASSWORD_MODAL",
    SHOW_SUBMISSION_MODAL: "SHOW_SUBMISSION_MODAL",
    SHOW_BUY_INFO_MODAL: "SHOW_BUY_INFO_MODAL",
    HIDE_BUY_INFO_MODAL: "HIDE_BUY_INFO_MODAL",
}
export const lifeLinesConstants = {
    ADD_LIFE_LINES: "ADD_LIFE_LINES",
    FIFTY_FIFTY: "FIFTY_FIFTY",
    SHOW_CORRECT_ANSWER: "SHOW_CORRECT_ANSWER",
    SKIP_QUESTION: "SKIP_QUESTION",
    MAKE_TRUE_TO_FALSE: "MAKE_TRUE_TO_FALSE",
    SHOW_LIFE_LINES: "SHOW_LIFE_LINES",
    SELECT_WHICH_LIFE_LINES_TO_SHOW: "SELECT_WHICH_LIFE_LINES_TO_SHOW",
}
export const showBuyInfoModalEnums = {
    QZETOS: "QZETOS",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
}
export const alphabetsConstants = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
export const backend_url = 'https://quizzingboxbe.herokuapp.com/api'
export const fron_end_url_server = 'https://clever-tartufo-669172.netlify.app'

