import { authConstants, extrasConstancts, lifeLinesConstants } from "../actions/appConstants";
import { homeModalConstants } from "../actions/extras.actions";

const inititalState = {
    show: true,
    lifeLines: {
        fiftyfifty: 0,
        showCorrectAnswer: 0,
        skipQuestion: 0,
    },
    clickedFiftyFifty: false,
    clickedShowCorrectAnswer: false,
    clickedSkipQuestion: false,

    showFifityFiftyLife: false,
    showCorrectAnswerLife: false,
    showSkipQuestionLife: false,
}
const extras = (state = inititalState, action) => {
    switch (action.type) {
        case lifeLinesConstants.ADD_LIFE_LINES:
            state = {
                ...state,
                lifeLines: action.payload
            }
            break;
        case lifeLinesConstants.FIFTY_FIFTY:
            state = {
                ...state,
                lifeLines: {
                    ...state.lifeLines,
                    fiftyfifty: state.lifeLines.fiftyfifty - 1
                },
                clickedShowCorrectAnswer: false,
                clickedFiftyFifty: true,
                clickedSkipQuestion: false,
            }
            break;
        case lifeLinesConstants.SHOW_CORRECT_ANSWER:
            state = {
                ...state,
                lifeLines: {
                    ...state.lifeLines,
                    showCorrectAnswer: state.lifeLines.showCorrectAnswer - 1
                },
                clickedShowCorrectAnswer: true,
                clickedFiftyFifty: false,
                clickedSkipQuestion: false,
            }
            break;
        case lifeLinesConstants.SKIP_QUESTION:
            state = {
                ...state,
                lifeLines: {
                    ...state.lifeLines,
                    skipQuestion: state.lifeLines.skipQuestion - 1
                },
                clickedShowCorrectAnswer: false,
                clickedFiftyFifty: false,
                clickedSkipQuestion: true,
            }
            break;
        case lifeLinesConstants.MAKE_TRUE_TO_FALSE:
            state = {
                ...state,
                clickedFiftyFifty: false,
                clickedSkipQuestion: false,
                clickedShowCorrectAnswer: false,
            }
            break;
        case lifeLinesConstants.SHOW_LIFE_LINES:
            state = {
                ...state,
                show: action.payload,
            }
            break;
        case lifeLinesConstants.SELECT_WHICH_LIFE_LINES_TO_SHOW:
            state = {
                ...state,
                ...action.payload,
            }
            break;
    }
    return state;
}
export default extras;