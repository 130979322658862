import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { deleteQuestion } from '../../../actions/question.actions';
import { createSubscriptionAction, deleteSubscriptionAction, updateSubscription } from '../../../actions/subscription.actions';
import axios from '../../../utils/myAxios'

function TableModal({ open, setopen, setqquestions }) {
    const initial_state = {
        name: '',
        price: 1,
        amount: 1,
        quizzes : 1, 
        quizzithon : 1,
        quizmaster_pro : 1, 
        ai_questions : 1
    }
    const [updatedData, setupdatedData] = React.useState(initial_state)
    const dispatch = useDispatch()
    const handleClose = () => {
        setopen(false)
    };
    const handleOnChange = (e) => {
        const { value, name } = e.target
        setupdatedData({
            ...updatedData,
            [name]: value
        })
    }
    const handleCreate = () => {
        axios.post('addQzetosPlan' , updatedData).then(res => {
            if(res.status === 200){
                setopen(false)
                setqquestions(res.data.plans)
            }
        }
        )
        .catch(err => console.log(err))
    }
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true}
            maxWidth={'md'}>
            {/* <DialogTitle>Qzeto Plan</DialogTitle> */}
            <h1 className='w-full text-center text-2xl pt-10'>Qzeto Plan</h1>

            <div className='py-5 px-10 w-full'>
                <div>
                    <h1 className='my-2 font-medium text-gray-500'>Title</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.name}
                        onChange={handleOnChange}
                        name='name'
                    />
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-3'>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>No of Quizzes</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizzes}
                            onChange={handleOnChange}
                            name='quizzes'
                            type='number'
                            min={1}

                        />
                    </div>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>Quizmaster Quizzes</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizmaster_pro}
                            onChange={handleOnChange}
                            name='quizmaster_pro'
                            type='number'
                            min={1}

                        />
                    </div>
                </div>
                <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-4 my-3'>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>AI Questions</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.ai_questions}
                            onChange={handleOnChange}
                            name='ai_questions'
                            type='number'
                            min={1}

                        />
                    </div>
                    <div>
                        <h1 className='my-2 font-medium text-gray-500'>Quizzithons</h1>
                        <input
                            className='outline-none w-full border border-black p-2 rounded-md'
                            value={updatedData.quizzithon}
                            onChange={handleOnChange}
                            name='quizzithon'
                            type='number'
                            min={1}

                        />
                    </div>
                </div>
                <div className='my-3'>
                    <h1 className='my-2 font-medium text-gray-500'>Qzetos</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.amount}
                        onChange={handleOnChange}
                        name='amount'
                        type='number'
                        min={1}
                    />
                </div>
                <div className='my-3'>
                    <h1 className='my-2 font-medium text-gray-500'>Price</h1>
                    <input
                        className='outline-none w-full border border-black p-2 rounded-md'
                        value={updatedData.price}
                        onChange={handleOnChange}
                        name='price'
                        type='number'
                        min={1}

                    />
                </div>
                
                <div className='flex items-center justify-end space-x-3 mt-5'>
                    <Button onClick={handleCreate} variant='contained' color='primary'>Create</Button>
                    <Button onClick={handleClose} variant='contained' color='secondary'>Cancel</Button>
                </div>
            </div>

        </Dialog>
    );
}

export default TableModal;