import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'
const Careers = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const p = useParams()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])
  return (
    <div className='!bg-white'>
      <NavBar />
      <Typography pt={10} variant={matches ? "h3" : "h4"} textAlign='center' sx={{ color: "black" }}>Careers</Typography>

      <Typography mt={5} px={matches ? 10 : 0} variant={matches ? "h4" : "h5"} textAlign={matches ? "left" : "center"} sx={{ color: "black" }}>Lorem ipsum dolor sit amet.
      </Typography>
      <Typography mt={5} px={matches ? 10 : 0} variant='subtitle1' textAlign='left' sx={{ color: "black" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </Typography>

      <Typography mt={5} px={matches ? 10 : 0} variant={matches ? "h4" : "h5"} textAlign={matches ? "left" : "center"} sx={{ color: "black" }}>Lorem ipsum dolor sit amet.
      </Typography>
      <Typography mt={5} px={matches ? 10 : 0} variant='subtitle1' textAlign='left' sx={{ color: "black" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </Typography>


      <Typography mt={5} px={matches ? 10 : 0} variant={matches ? "h4" : "h5"} textAlign={matches ? "left" : "center"} sx={{ color: "black" }}>Lorem ipsum dolor sit amet.
      </Typography>
      <Typography mt={5} px={matches ? 10 : 0} variant='subtitle1' textAlign='left' sx={{ color: "black" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </Typography>
      <Footer />
    </div>
  )
}

export default Careers