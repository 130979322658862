import React, { useState, useEffect } from 'react'
import AddQuestions from '../components/QuestionBankComponents/AddQuestions/AddQuestions'
import QuestionsTable from '../components/QuestionBankComponents/QuestionsTable/QuestionsTable'
import Layout from './../components/Layout/Layout'
//icons
import { MdAttachment } from 'react-icons/md'
import AddIcon from '@mui/icons-material/Add';
//icons
import { useLocation, useNavigate } from 'react-router-dom'
import QuestionMarkWithModal from '../components/uiComponents/questionMarkWithModal/QuestionMarkWithModal';
//icons...
import { useDispatch, useSelector } from 'react-redux'

import { Chip } from '@mui/material'
import axios from '../utils/myAxios'
import { globalConstants } from '../actions/appConstants'
const QuestionBank = () => {
    const [loading, setloading] = useState(true)
    const [userManageMentCall, setuserManageMentCall] = useState(null)
    const [numberOfQuestions, setnumberOfQuestions] = useState(null)
    const [questionsLengthData, setquestionsLengthData] = useState({
        category: '',
        subject: ""
    })
    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const { question, subjects, auth } = useSelector(s => s)
    const [showAddQuestions, setshowAddQuestions] = useState(false)
    const [showQuestinData, setshowQuestinData] = useState(null)
    useEffect(() => {
        if (showQuestinData) {
            setshowAddQuestions(true)
        }
    }, [showQuestinData])
    //

    useEffect(async () => {
        if (questionsLengthData.subject.trim() && questionsLengthData.category.trim()) {
            try {
                const res = await axios.get(`/getnumberofquestions/basedon/${questionsLengthData.category}/${questionsLengthData.subject}/${auth.data.user._id}`)
                setnumberOfQuestions(res.data.questions)
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
    }, [questionsLengthData.category, questionsLengthData.subject])
    //
    //check if user management want to see user questions
    useEffect(async () => {
        if (state?.userManagement) {
            // console.log('state?.userManagement==>', state?.userManagement)
            await setuserManageMentCall(state?.userManagement)
            setloading(false)
        } else {
            setloading(false)
        }
    }, [state])
    //check if user management want to see user questions
    // console.log('userManageMentCall===>', userManageMentCall)
    return (
        <Layout>
            {
                loading ?
                    <div>loading...</div>
                    :
                    <>
                        <div className='flex flex-wrap items-center justify-between  md:gap-0 gap-4 mb-2 bg-white p-3 my__rounded'>
                            <div className='flex items-center gap-2 flex-wrap'>
                                <h1 className='text-bgblue font-bold'>Question Bank</h1>
                                {
                                    userManageMentCall === null &&
                                    <div className='flex items-center gap-2 flex-wrap'>
                                        <select
                                            value={questionsLengthData.category} onChange={(e) => setquestionsLengthData({ ...questionsLengthData, category: e.target.value })}
                                            className='my__select'>
                                            <option value=''>Select Categories</option>
                                            {
                                                question.questionsCategories.map((val, index) => (
                                                    <option key={index} value={val._id}>{val.name.replace(/_/g, ' ')}</option>
                                                ))
                                            }
                                        </select>
                                        <select
                                            value={questionsLengthData.subject} onChange={(e) => setquestionsLengthData({ ...questionsLengthData, subject: e.target.value })}
                                            className='my__select'>
                                            <option value=''>Select Subject</option>
                                            {
                                                subjects.subjects.map((val, index) => (
                                                    <option value={val.name} key={index}>{val.name}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            numberOfQuestions !== null &&
                                            <Chip label={numberOfQuestions} variant="contained" color="primary" />
                                        }
                                    </div>
                                }

                            </div>
                            <div className=' flex items-center space-x-3'>
                                <div className='bg-mydarkgreen flex items-center p-3 rounded-full space-x-2 cursor-pointer' onClick={() => {
                                    setshowQuestinData(null)
                                    setshowAddQuestions(!showAddQuestions)
                                }}>
                                    <AddIcon className='bg-white text-mylightgreen rounded-full' />
                                    <span className='text-white text-xs'>{`${showAddQuestions ? 'Show Questions' : 'Add Questions'}`}</span>
                                </div>
                                <div className='bg-mydarkgreen flex items-center p-3 rounded-full space-x-2 cursor-pointer'
                                    onClick={() => navigate('/importexcelsheets')}
                                >
                                    <MdAttachment size={'1.5rem'} className='bg-white text-mylightgreen rounded-full' />
                                    <span className='text-white text-xs'> Upload ExcelSheets</span>
                                </div>
                                {
                                    showAddQuestions &&
                                    <QuestionMarkWithModal />
                                }
                            </div>
                        </div>
                        {
                            showAddQuestions
                                ?
                                <AddQuestions setshowQuestinData={setshowQuestinData} showQuestinData={showQuestinData} setshowAddQuestions={setshowAddQuestions} />
                                :
                                <QuestionsTable
                                    userManageMentCall={userManageMentCall}
                                    showQuestinData={setshowQuestinData}
                                    questionsLengthData={questionsLengthData}
                                    />
                        }
                    </>
            }


        </Layout>
    )
}

export default QuestionBank