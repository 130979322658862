import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Slider from "react-slick";

import HeroImg from '../../../assets/staticdata/heroImg1.png'
import Ply from '../../../assets/staticdata/ply.png'
import Trusted from '../../../assets/staticdata/trusted.png'
import Test from '../../../assets/staticdata/test.png'
import Winner from '../../../assets/staticdata/winner.png'
import QuestionMark from '../../../assets/staticdata/question-mark.png'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { extrasConstancts } from '../../../actions/appConstants';
import { useDispatch, useSelector } from 'react-redux';
import Banner2 from '../../../assets/staticdata/banners/banner2.jpg';
import Banner3 from '../../../assets/staticdata/banners/banner3.jpg';
import TopBanner from './TopBanner';
import axios from "../../../utils/myAxios"
// const settings = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 2000,
//     autoplaySpeed: 3000,
//     pauseOnFocus: true,
// };

// const BootstrapButton = styled(Button)({
//     boxShadow: 'none',
//     textTransform: 'none',
//     fontSize: 16,
//     padding: '6px 12px',
//     border: '1px solid',
//     lineHeight: 1.5,
//     color: 'white',
//     backgroundColor: '#F89820',
//     borderColor: '#F89820',
//     fontFamily: [
//         '-apple-system',
//         'BlinkMacSystemFont',
//         '"Segoe UI"',
//         'Roboto',
//         '"Helvetica Neue"',
//         'Arial',
//         'sans-serif',
//         '"Apple Color Emoji"',
//         '"Segoe UI Emoji"',
//         '"Segoe UI Symbol"',
//     ].join(','),
//     '&:hover': {
//         backgroundColor: '#F89820',
//         borderColor: '#F89820',
//         boxShadow: 'none',
//     },
//     '&:active': {
//         boxShadow: 'none',
//         backgroundColor: '#F89820',
//         borderColor: '#F89820',
//     },
//     '&:focus': {

//     },
// });
// const RequestButton = styled(Button)({
//     boxShadow: 'none',
//     textTransform: 'none',
//     fontSize: 16,
//     padding: '6px 52px',
//     border: '1px solid',
//     lineHeight: 1.5,
//     color: 'white',
//     backgroundColor: 'black',
//     borderColor: 'black',
//     fontFamily: [
//         '-apple-system',
//         'BlinkMacSystemFont',
//         '"Segoe UI"',
//         'Roboto',
//         '"Helvetica Neue"',
//         'Arial',
//         'sans-serif',
//         '"Apple Color Emoji"',
//         '"Segoe UI Emoji"',
//         '"Segoe UI Symbol"',
//     ].join(','),
//     '&:hover': {
//         backgroundColor: 'black',
//         opacity: "80%",
//         borderColor: 'black',
//         boxShadow: 'none',
//     },
//     '&:active': {
//         boxShadow: 'none',
//         backgroundColor: 'black',
//         borderColor: 'black',
//     },
//     '&:focus': {

//     },
// });
// const sliderData = [
//     {
//         img: HeroImg,
//     },
//     {
//         img: Banner2
//     },
//     {
//         img: Banner3
//     }
// ]

const Hero = () => {
    const [data, setData] = useState()
    useEffect(() => {
        axios.get('/getdata')
            .then((body) => setData(body.data))
            .catch(err => err)

    }, []);
    const navigate = useNavigate()
    const { auth } = useSelector(s => s)
    const [openPopUp, setOpenPopUp] = useState(false)
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const dispatch = useDispatch()
    return (
        <>
            <TopBanner />
            {/* <div className=' px-5 py-0'>
                <div className='flex items-center justify-center'>
                    <Container className='!flex gap-4'  >
                        <Grid py={matches ? 5 : 0} container spacing={5}>
                            <Grid sx={{ display: "flex", alignItems: "center" }} item md={6} xs={12}>
                                <Box>
                                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <Typography variant={matches ? "h3" : "h5"} textAlign='left' sx={{ color: '#800000', fontSize: 'bold' }}>
                                            Easy and intuitive online quizzing platform
                                        </Typography>
                                        <Typography my={3} variant='subtitle1' textAlign='left' sx={{ color: '#324161' }}>
                                            Quizzingbox platform offers unlimited number of quizzes with opportunity of winning rewards every single minute which make it a preferred choice for millions of happy users all around the globe
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Stack direction='row' spacing={2}>
                                            {
                                                auth.authenticate ?
                                                    <BootstrapButton
                                                        onClick={() => navigate('/dashboard')}
                                                        color="inherit"
                                                    >
                                                        Dashboard
                                                    </BootstrapButton>
                                                    :
                                                    <BootstrapButton
                                                        onClick={() => dispatch({
                                                            type: extrasConstancts.SHOW_SIGN_UP_MODAL,
                                                            payload: true
                                                        })}
                                                        color="inherit"
                                                        className='!rounded-2xl'
                                                    >
                                                        Get Started
                                                    </BootstrapButton>
                                            }

                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className=' '>
                                    <Slider {...settings}>
                                        {
                                            sliderData.map((val, index) => (
                                                <img key={index} className='heroimg' src={val.img} alt='' />
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div> */}
            <div>
                <Container>
                    <Typography className='text-yellow-500 mt-5' variant={matches ? "h3" : "h5"} textAlign='center' sx={{ color: "#800000", fontWeight: '400', marginTop: "30px", fontSize: 'bold' }}>Our Key Features</Typography>
                    <div className='all-fields'>

                        <div style={{ backgroundColor: "#00459E" }} className='each-field'>
                            <Box className="field-icon-box"><img className="field-icon" src={Trusted} alt="" /></Box>
                            <div className='field-text'>
                                <Typography sx={{ fontSize: "16px", textAlign: 'center' }} variant='h5'>Trusted across the globe</Typography>
                                <Typography sx={{ fontSize: "22px", paddingTop: "5px" }} variant='h4'>2.8 Crore</Typography>

                            </div>
                        </div>
                        <div style={{ backgroundColor: "#282C46" }} className='each-field'>
                            <Box className="field-icon-box"><img className="field-icon" src={Winner} alt="" /></Box>
                            <div className='field-text'>
                                <Typography sx={{ fontSize: "16px"  , textAlign : "center"}} variant='h5'>Quiz Scheduled</Typography>
                                <Typography sx={{ fontSize: "22px", paddingTop: "5px" }} variant='h4'>{data?.quiz >22 ? data?.quiz : 22}</Typography>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#00459E" }} className='each-field'>
                            <Box className="field-icon-box"><img className="field-icon" src={Test} alt="" /></Box>
                            <div className='field-text'>
                                <Typography sx={{ fontSize: "16px" , textAlign : "center" }} variant='h5'>Quiz Played</Typography>
                                <Typography sx={{ fontSize: "22px", paddingTop: "5px" }} variant='h4'>{data?.gamers > 67 ? data?.gamers : 67}</Typography>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#282C46" }} className='each-field'>
                            <Box className="field-icon-box"><img className="field-icon" src={QuestionMark} alt="" /></Box>
                            <div className='field-text'>
                                <Typography sx={{ fontSize: "16px", textAlign: 'center' }} variant='h5'>Prize distributed</Typography>
                                <Typography sx={{ fontSize: "22px", paddingTop: "5px" , textAlign : 'center'}} variant='h4'>120,000 qzetos</Typography>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Hero
