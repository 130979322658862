import { Box, Container, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core"
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContactImage from "../../assets/staticdata/contactiamge.jpg"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import emailIcon from "../../assets/staticdata/email.png"
import phoneIcon from "../../assets/staticdata/phone.png"
import addressIcon from "../../assets/staticdata/address.png"
import contactusTop from "../../assets/staticdata/contactusTop.jpeg"
import { useParams } from 'react-router-dom';
import NavBar from '../staticComponents/navBar/NavBar';
import Footer from '../staticComponents/footer/Footer';
import './../static.css'
import axios from '../../utils/myAxios'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const Heading = ({ text }) => <h1 className='mt-5 font-bold text-xl '>{text}</h1>
const MyLink = ({ text, link }) => <a target={`_blank`} className='text-blue-600 underline ' href={link}>{text}</a>
const MyParagraph = ({ children }) => <p className='text-[16px] leading-8 mt-5 '>{children}</p>


const MyBold = ({ text }) => <span className='text-lg font-extrabold '>{text}</span>
const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: "5px",


    }
  },
  form: {
    display: "flex"

  }


}))

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  margin: '10px 0px',
  width: "80%",
  padding: '12px 0px',
  border: '1px solid',
  lineHeight: 1.5,
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: 'white',
  backgroundColor: '#233C77',
  borderColor: '#233C77',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#233C77',
    borderColor: '#233C77',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#233C77',
    borderColor: '#233C77',
  },
  '&:focus': {

  },
});
const ReportBug = () => {
  const { auth } = useSelector(s => s)
  const navigate = useNavigate()
  const p = useParams()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: auth?.data?.user?.email_number,
    message: ""
  })
  useEffect(() => {
    console.log(auth.data.user.email_number)
    setFormData({
      firstName: "",
      lastName: "",
      email: auth.data.user.email_number,
      message: ""
    })
  }, [auth])
  const handlechange = (e) => {
    //console.log(e.target.value)
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    //console.log(formData)
    const form = await axios.post('/contactus', formData)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [p])
  const classes = useStyles();

  return (
    <>
      {auth.authenticate ? <div className='!bg-white'>
        <NavBar />
        <img
          src={contactusTop}
          className='w-full h-auto object-cover md:mt-[4rem]'
        />
        <Container>

          <Typography pt={10} variant='h4' textAlign="center" sx={{ color: "black" }}>Report Bug</Typography>
          <MyParagraph>
            At quizzingbox we listen to our users and try to proactively solve them.
            If you have any suggestion how we can improve ourselves or found any bug, please provide the details of the bug in the below form. Our team will review the bug and try fix the problem immedietelly.
            And oh yeah you will be rewarded with bonus qzetos with every bug reported which you can convert to real qzetos and redeem.
          </MyParagraph>
          <Grid container my={2}>
            <Grid mt={matches ? 10 : 0} item md={6} xs={12}>

              <form className={classes.root} onSubmit={handleSubmit}>
                <TextField id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  onChange={handlechange}
                />
                <TextField id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  onChange={handlechange}
                />

                <TextField id="outlined-basic"
                  variant="outlined"
                  name='email'
                  // onChange={handlechange}
                  disabled={true}
                  value={formData.email}

                />
                <TextField id="outlined-basic"
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  name='message'
                  onChange={handlechange}

                />

                <BootstrapButton type='submit'>Report Bug</BootstrapButton>
              </form>



            </Grid>
            <Grid item md={6} xs={12}>
              <img width="100%" src={ContactImage} alt="" />

            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div> : navigate('/')}
    </>
  )
}

export default ReportBug