import React from 'react'
import UserScheduledQuizezC from '../components/userScheduledQuizez/UserScheduledQuizezC'
import Layout from './../components/Layout/Layout'
const UserScheduledQuizez = () => {
    return (
        <Layout>
            <UserScheduledQuizezC />
        </Layout>
    )
}

export default UserScheduledQuizez;