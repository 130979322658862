import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { extrasConstancts, globalConstants } from '../../actions/appConstants'
import axios from '../../utils/myAxios'
//icons
import { FaChalkboardTeacher } from 'react-icons/fa'
//icons
import { getWalletAction } from '../../actions/wallet.actions'
const SchoolQuizComponent = ({ screen, mobile }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { auth, extras } = useSelector(s => s)
    const [subscribed, setsubscribed] = useState(false)
    useEffect(() => {
        const getData = async () => {
            try {
                const res = await axios.get(`/check/ifuserhasboughtsubscription/${auth.data.user._id}`)
                if (res.data.bought) {
                    setsubscribed(true)
                    dispatch({
                        type: extrasConstancts.IS_BOUGHT_SCHOOL_QUIZ_SUBSCRIPTION,
                        payload: true
                    })
                }
            } catch (error) {
                dispatch({
                    type: globalConstants.ALERT,
                    payload: { error: error.message }
                })
            }
        }
        if (auth.authenticate) {
            getData()
        }
    }, [auth.authenticate])
    useEffect(() => {
        const disTributePrizezAndGetWallet = async () => {
            await axios.post(`/cronjobs/ditrubte/or/refund/qzetos`)
            await axios.post(`/cronjobs/distribute/quizzithon/prizez`)
            dispatch(getWalletAction(auth.data.user._id))
            //console.log('distribute the prizzes')
        }
        if (auth.authenticate) {
            disTributePrizezAndGetWallet()
        }
    }, [auth.authenticate])
    return (
        // <div className={`${mobile ? 'w-full' : "w-max"} p-2`}>
        //     {
        //         subscribed || extras.boughtSchoolQuizSubscription
        //             ?
        //             <div
        //                 onClick={() => navigate('/quizmaster-pro')}
        //                 className={`px-3 flex items-center justify-center space-x-2 bg-mydarkgreen rounded-2xl py-1 cursor-pointer text-white ${screen ? 'lg:flex hidden' : ""}${mobile ? 'lg:hidden ' : ""} `}>
        //                 <FaChalkboardTeacher />
        //                 <span>Quizmaster-Pro</span>
        //             </div>
        //             :
        //             <div
        //                 onClick={() => dispatch({
        //                     type: extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION,
        //                     payload: true
        //                 })}
        //                 className={`px-3 flex items-center justify-center space-x-2 bg-mydarkgreen rounded-2xl py-1.5 cursor-pointer text-white ${screen ? 'lg:flex hidden' : ""}${mobile ? 'lg:hidden ' : ""} `
        //                 } >
        //                 <FaChalkboardTeacher />
        //                 <span>Become A Quiz Master</span>
        //             </div>
        //         // <button
        //         //     onClick={() => dispatch({
        //         //         type: extrasConstancts.SHOW_BUY_SCHOOL_QUIZ_SUBSCRIPTION,
        //         //         payload: true
        //         //     })}
        //         //     className={`w-full rounded-full bg-mydarkgreen text-white text-sm py-1.5 px-3 ${screen ? 'lg:inline hidden' : ""}${mobile ? 'lg:hidden w-full' : ""}`}>Become A Quiz Master</button>
        //     }
        // </div >
        <></>
    )
}

export default SchoolQuizComponent