import React from 'react'
import Layout from './../components/Layout/Layout'
const NotAuthorized = () => {
    return (
      <Layout>
          Not authorized......
      </Layout>
    )
}

export default NotAuthorized