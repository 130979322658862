import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import tw from 'twin.macro'
// import { getAllQuizzez } from '../../../actions/quizzSchedule.actions'
import QuizzCard from '../DashBoardComponets/QuizCards/QuizzCard'
import axios from '../../utils/myAxios'
import { globalConstants } from '../../actions/appConstants'
import TestingCard from '../DashBoardComponets/QuizCards/TesingCard'
import NotFoundDataUi from '../uiComponents/NotFoundDataUi'
const QuizCards = ({ type: TYPE, cat, sub }) => {
    const [open, setopen] = useState(false)
    const [laoding, setlaoding] = useState(false)
    const { auth, question } = useSelector(s => s)
    const dispatch = useDispatch()
    const [queries, setqueries] = useState({
        page: 1,
        limit: 10,
        category: '',
        sortBy: '',
        type: TYPE
    })
    const [questions, setquestions] = useState([])
    const [totalLength, settotalLength] = useState(0)
    const { page, limit, category, sortBy, type } = queries
    const handlePagination = (e, currentPage) => {
        setqueries({
            ...queries,
            page: currentPage,
        })
    }
    useEffect(() => {
        const getData = async () => {
            try {
                setlaoding(true)
                const res = await axios.post(`/testing/getall/quizzez?sortBy=${queries.sortBy}&category=${cat ? cat : ""}&limit=${queries.limit}&page=${queries.page}&type=${type}&subject=${sub}`)
                if (res.status === 200) {
                    //console.log('questions==>', res.data)
                    setquestions(res.data.quizzez)
                    const l = Math.ceil(Number(res.data.totalLength) / Number(limit))
                    settotalLength(l)
                }
                setlaoding(false)
            } catch (error) {
                setlaoding(false)
                if (error.response.data.msg) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.msg }
                    })
                } else if (error.response?.data?.error) {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.response.data.error }
                    })
                }
                else {
                    dispatch({
                        type: globalConstants.ALERT,
                        payload: { error: error.message }
                    })
                }
            }
        }
        getData()
    }, [page, limit, sortBy, type])
    //
    const handleUpdatedQuizz = (updatedQuizz) => {
        //console.log('updated quizz', updatedQuizz)
        const updatedQuestions = questions.map(q => q._id === updatedQuizz._id ? updatedQuizz : q)
        //console.log('new ', updatedQuestions)
        setquestions(updatedQuestions)
    }
    return (
        <>
            <div className='flex lg:flex-row flex-col lg:items-center justify-end my-2 lg:space-y-0 space-y-2 mt-8'>
                {/* <div className='flex lg:flex-row flex-col lg:space-x-2 lg:space-y-0 space-y-2 '>
                    <select value={category} onChange={(e) => setqueries({ ...queries, category: e.target.value })}
                        className='my__select'
                    >
                        <option value=''>Select categories</option>
                        {
                            question.questionsCategories.map((val, index) => (
                                <option key={index} value={val._id}>{val.name.replace(/_/g, ' ')}</option>
                            ))
                        }
                    </select>
                </div> */}
                <div className='flex'>
                    <select value={sortBy} onChange={(e) => setqueries({ ...queries, sortBy: e.target.value })}
                        className='my__select w-full md:w-auto'
                    >
                        <option value=''>Sort by</option>
                        <option value='high'>High-to-Low</option>
                        <option value='low'>Low-to-High</option>
                    </select>
                </div>
            </div>
            {
                laoding ?
                    <div className='w-full flex items-center justify-center'>
                        <CircularProgress />
                    </div>
                    :
                    questions.length > 0 ?
                        <>
                            <div className=''>
                                <CardContainer>
                                    <div className='grid lg:grid-cols-3 grid-cols-1  gap-4 mt-10'>
                                        {
                                            questions.map((val, index) => (
                                                // <TestingCard />
                                                <QuizzCard
                                                    index={index}
                                                    handleUpdatedQuizz={handleUpdatedQuizz}
                                                    quizz={val} key={index} user={auth.data.user._id} />
                                            ))
                                        }
                                    </div>
                                </CardContainer>
                                <div className='flex justify-center px-5 pt-5'>
                                    <Pagination page={queries.page} onChange={handlePagination} count={totalLength} color="primary" />
                                </div>
                            </div>
                            <AdsContainer>
                            </AdsContainer>
                        </>
                        :
                        <NotFoundDataUi
                            text={`No quiz available`}
                        />
                // <div className='flex items-center justify-center text-bgblue font-bold mt-10 tracking-widest'>No quiz available...</div>
            }

        </ >
    )
}
const AdsContainer = styled.div`
${tw`col-span-1`}
`
const CardContainer = styled.div`
${tw`
lg:col-span-7 col-span-8 
`}
`
const Container = styled.div`
${tw`grid grid-cols-8 mt-10`}
`
export default QuizCards