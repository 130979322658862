import { quizzSchedulerConstants } from "../actions/appConstants";

const inititalState = {
    quizzez: []
}


const quizzSchedule = (state = inititalState, action) => {
    switch (action.type) {
        case quizzSchedulerConstants.GET_ALL_SHEDULED_QUIZZEZ:
            state = {
                ...state,
                quizzez: action.payload
            }
            break;
        case quizzSchedulerConstants.QUIZZ_UNREGISTER_USER:
            state = {
                ...state,
                quizzez: state.quizzez.map(val => {
                    if (val._id === action.payload.quizzId) {
                        const toReturn = {
                            ...val,
                            registerdUsers: val.registerdUsers.filter(v => v.user !== action.payload.user)
                        }
                        return toReturn
                    }
                    return val;
                })
            }
            break;
        case quizzSchedulerConstants.QUIZZ_REGISTER_USER:
            state = {
                ...state,
                quizzez: state.quizzez.map(val => {
                    if (val._id === action.payload.quizzId) {
                        const toReturn = {
                            ...val,
                            registerdUsers: [
                                ...val.registerdUsers,
                                { user: action.payload.user }
                            ]
                        }
                        return toReturn
                    }
                    return val;
                })
            }
            break;
    }
    return state;
}
export default quizzSchedule;