import React, {useState} from 'react'
import axios from '../../utils/myAxios'
function FAQTypesModal({setTypeOpen}) {
    const [type, setType] = useState()
    const handleChange = (e)=>{
        const {value, name} = e.target
        setType({
            ...type,
            [name]: value
        })
    }
    const handleSubmit = ()=>{
        axios.post('/addType', type).then(res => {
            console.log(res.data)
            setTypeOpen(false)
        })
    }
  return (
    <div className=' p-3 flex flex-col w-full bg-white rounded-md'>
        {/* <div className=''>
            <label htmlFor="">FAQ category </label>
            <select name="" id="" className='w-full h-9 rounded-lg border'>
                <option value="">Test</option>
                <option value=""></option>
                <option value=""></option>
                <option value=""></option>
                <option value=""></option>
            </select>
        </div>
        <div className=''>
            <label htmlFor="">Question</label>
            <input type="text" className='w-full h-9 rounded-lg border' />
        </div> */}
        <div className=''>
            <label htmlFor="">Category</label>
            <input type="text" className='w-full h-9 rounded-lg border' name = "name" onChange={(e)=>handleChange(e)} />
        </div>
        <div className='w-full flex justify-center py-5 gap-2'>
            <button className='px-2 h-9 rounded-full border bg-red-400 text-white hover:text-red-400 hover:bg-white' onClick={()=>setTypeOpen(false)}>Cancel</button>
            <button className='px-2 h-9 rounded-full border bg-green-400 text-white hover:text-green-400 hover:bg-white' onClick={handleSubmit}>Add Category</button>
        </div>
    </div>
  )
}

export default FAQTypesModal