import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { questionsCategoryConstants } from '../../../../actions/appConstants'

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAnswers } from '../../../playQuizComponents/dragDropFillUpTheBlanks/textConverters';
import NotFoundDataUi from '../../../uiComponents/NotFoundDataUi';
const Items = ({ index }) => {
    return (
        <div className='border border-black  p-3 rounded-2xl '>
            <h1 className=''>
                <span className='font-semibold'>QNO.{index + 1}:  </span>
                This is the uqwekrj sdhfoidsajfp oihipoah pfdisahof oiashdfpoashdfpasodhf posd
            </h1>
            <h1 className='text-bgblue'>
                <span className='font-semibold'>Answer:  </span>
                this is the answer of the above questioon...
            </h1>
        </div>
    )
}
const Question = ({
    correct,
    incorrect,
    val
}) => {
    return (
        <div className={`relative border ${correct ? "border-green-500 bg-green-100" : "border-red-500 bg-red-100"
            }  p-5 rounded-xl`}>
            {
                correct ?
                    <CheckCircleIcon className='!absolute text-green-500 top-2 right-2' />
                    :
                    <CancelIcon className='!absolute text-red-500 top-2 right-2' />
            }
            <h1 className='font-bold'>
                <span className='mr-3'>Question:</span>{val.statement}
            </h1>
            {
                val.questionCategory?.name === questionsCategoryConstants.MATCH_THE_PAIR &&
                val.collumns.c.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val?.questionCategory?.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val?.questionCategory?.name === questionsCategoryConstants.SEQUENCING_QUIZ ||
                    val?.questionCategory?.name === questionsCategoryConstants.PARAGRAPH_ORDERING_QUIZ)
                &&
                val.collumns.b.map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                Object.values(val?.forCrossWord.across).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.answer}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.CROSS_WORDS)
                &&
                Object.values(val?.forCrossWord?.down).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.answer}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.DRAD_DROP_FILL_UP_THE_BLANKS)
                &&
                getAnswers(val.forDragDropFillUpBlanks).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING)
                &&
                Object.values(val?.forDragDropVisualSimulating?.correct).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.name}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.DRAG_DROP_VISUAL_SIMULATING)
                &&
                Object.values(val?.forDragDropVisualSimulating?.incorrect).map((_c, _i) => (
                    <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                        {_c?.name}
                    </h1>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.SORTING_CLASSIFICATION)
                &&
                Object.values(val?.forSortingClassificationType).map((_c, _i) => (
                    <div>
                        <h1 className='font-bold mt-1'>{_c.name} :</h1>
                        {
                            _c?.values.map((val, index) => (
                                <h1 key={index} className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                                    {val}
                                </h1>
                            ))
                        }
                    </div>
                ))
            }
            {
                (val.questionCategory?.name === questionsCategoryConstants.FILL_IN_THE_BLANKS ||
                    val.questionCategory?.name === questionsCategoryConstants.IDENTIFY_IMAGE_VIDEO_OR_VOICE ||
                    val.questionCategory?.name === questionsCategoryConstants.MULTIPLE_QUESTIONS ||
                    val.questionCategory?.name === questionsCategoryConstants.TRUE_FALSE ||
                    val.questionCategory?.name === questionsCategoryConstants.CLOSED_ENDED ||
                    val.questionCategory?.name === questionsCategoryConstants.SENTENCE_SEQUENCE
                ) &&
                <h1 className='text-bgblue mt-3'><span className='font-bold mr-3 '>Answer:</span>
                    {val.correctAnswer}
                </h1>
            }
        </div>
    )
}
const KnowledgeBank = ({ quizz }) => {
    const [questionsArray, setquestionsArray] = useState([])
    //console.log('quizz===>', quizz)
    useEffect(() => {
        if (quizz) {
            if (quizz.category.name === questionsCategoryConstants.MIX_TYPE_QUIZ) {
                setquestionsArray(quizz.forMixTypeQuestions)
            } else if (quizz.category.name === questionsCategoryConstants.ELIMINATOR_QIZ) {
                setquestionsArray(quizz.eliminatedQuiz.questions)
            } else if (quizz.category.name === questionsCategoryConstants.TIC_TAC_TOE) {
                setquestionsArray(quizz.forMixTypeQuestions)
            } else if (quizz.category.name === questionsCategoryConstants.SPIN_A_WHEEL_QUIZ) {
                let totals = []
                quizz.spinWheal.forEach((v) => {
                    totals = [...totals, ...v.data]
                })
                if (totals.length > 0) {
                    setquestionsArray(totals)
                }
            } else {
                setquestionsArray(quizz.questions)
            }
        }
    }, [quizz])
    return (
        <Cont>
            <div className='grid grid-cols-1 gap-4'>
                {
                    questionsArray.length > 0 ?
                        questionsArray.map((val, index) => (
                            <Question
                                key={index}
                                val={val}
                                correct={true}
                            />
                        ))
                        :
                        <NotFoundDataUi
                            text={`No Questions Available.`}
                        />
                    // <h1>No Questions Available...</h1>
                }
            </div>
        </Cont>
    )
}
const Cont = styled.div`
${tw``}
`
export default KnowledgeBank